import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-js';
import { fetchPortfolioGroup } from '../../../../store/actions/portfolioGroupAction';
import { addAllocation, updateAllocationDetails } from '../../../../store/actions/AllocationAction';
import { Textarea } from '@/components/ui/textarea'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import ReactSelect from 'react-select'
import {
    components,
} from 'react-select';
import { Input } from '@/components/ui/input'
import { fetchAllPortfolios } from '../../../../store/actions/portfolioAction';
import { FromDatePicker } from '../../../../components/ui/DatePickerNew';
import { Checkbox } from '../../../../components/ui/checkbox';
const AddAllocationModel = (props) => {
    const dispatch = useDispatch()
    const { setDataFetched, setAllocationData, editAllocationData, show, setSelectedAllocation, currentPage, perPage } = props;
    const { portfolios, PortfolioGroup, PortfolioGroupDetails, allPortfolio } = useSelector(state => state)
    const [error, setError] = useState({});
    const [checkedData, setCheckedData] = useState([]);

    const [portfolioGroupOption, setPortfolioGroupOption] = useState({ value: null, label: 'All' })
    const [fetchPortfolios, setFetchPortfolios] = useState(null)
    const [formData, setFormData] = useState({
        assetName: "",
        description: "",
        portfolio: null,
        startDate: null,
        endDate: null
    })


    const portfolioGroupOptions = PortfolioGroup && PortfolioGroup?.length >= 1 ? [{ value: null, label: 'All' }, ...PortfolioGroup?.map((item) => {
        return { value: item?._id, label: item?.name, slug: item?.slug }
    })] : []

    useEffect(() => {
        portfolioGroupOption !== null && portfolioGroupOption?.value !== null && dispatch(fetchPortfolioGroup(portfolioGroupOption?.slug))
    }, [portfolioGroupOption])
    useEffect(() => {
        if (PortfolioGroupDetails && portfolioGroupOption?.value !== null) {
            const filteredArray = PortfolioGroupDetails.filter(item => (item !== undefined && item.portfolio !== null));
            setFetchPortfolios(filteredArray)
        }
    }, [PortfolioGroupDetails])

    useEffect(() => {
        if (show === true) {
            dispatch(fetchAllPortfolios())
        } else {
            setCheckedData([])
        }
    }, [show])

    useEffect(() => {
        if (editAllocationData !== null) {
            // Update the formData state based on the selected allocation
            const getData = portfolios.find((item) => {
                return item?.portfolio?._id === editAllocationData.portfolio
            })


            setFormData({
                id: editAllocationData.id,
                // portfolio: portfolios.find((item) => {
                //     return item?.portfolio?._id === editAllocationData.portfolio
                // }),
                // portfolio: { value: editAllocationData.portfolio, label: editAllocationData.portfolioName, slug: '' },
                portfolio: editAllocationData.portfolioOptions,
                assetName: editAllocationData.name,
                description: editAllocationData.description,
                startDate: new Date(editAllocationData.startDate),
                endDate: new Date(editAllocationData.endDate),
            });

            // setPortfolioGroupOption();


            // const portfolioGroupEditOptions = portfolioGroupOptions?.length > 0 && portfolioGroupOptions?.filter((pg) => pg?.value === editAllocationData?.id)
            const editPortfolioGroup = portfolioGroupOptions?.length > 0 && portfolioGroupOptions?.filter((pg) => pg?.value === editAllocationData?.portfolioGroup)
            if (editPortfolioGroup?.length > 0 && Array.isArray(editPortfolioGroup)) {

                setPortfolioGroupOption(editPortfolioGroup[0])
            }

            if ((editAllocationData?.portfolioOptions?.length) === (allPortfolio?.records?.length)) {
                let Ids = []
                editAllocationData?.portfolioOptions?.map((ea) => Ids.push(ea?._id));
                Ids.push("all")
                setCheckedData(Ids);
            } else {
                let Ids = []
                editAllocationData?.portfolioOptions?.map((ea) => Ids.push(ea?._id));
                setCheckedData(Ids);

            }



            setSelectedAllocation(null)
        } else {
            setFormData({
                assetName: "",
                description: "",
                portfolio: null,
                startDate: null,
                endDate: null
            })
            setPortfolioGroupOption({ value: null, label: 'All' })
            setError({})
            setSelectedAllocation(null)
        }
    }, [show])


    // useEffect(() => {
    //     if (portfoliosOptions?.length - 1 === checkedData?.length) {
            
    //         setCheckedData((prev) => ["all", ...prev])
    //     }
    // }, [checkedData])





    // const portfoliosOptions = portfolios && portfolios?.length >= 1 ? portfolios?.map((item) => {
    //     return { value: item.id, label: item.name }
    // }) : []

    // const portfolioGroupOptions = PortfolioGroup && PortfolioGroup?.length >= 1 ? [{ value: null, label: 'All' }, ...PortfolioGroup?.map((item) => {
    //     return { value: item?._id, label: item?.name, slug: item?.slug }
    // })] : []

    const portfolioOptionsData = fetchPortfolios !== null && fetchPortfolios?.length >= 1 ? fetchPortfolios?.map((item) => {

        return { value: item?.portfolio?._id, label: item?.portfolio?.name, slug: item?.portfolio?.slug }
    }) : []

    // const portfoliosOptions = (portfolioGroupOption === null || portfolioGroupOption.value === null) ? allPortfolio?.records && allPortfolio?.records?.length >= 1 ? allPortfolio?.records?.map((item) => {
    //     return { value: item?.id, label: item?.name, slug: item?.slug }
    // }) : [] : portfolioOptionsData.filter(item => item !== null);
    const portfoliosOptions = (
        portfolioGroupOption === null ||
        portfolioGroupOption.value === null
    ) ? (
        allPortfolio?.records && allPortfolio?.records?.length >= 1 ?
            [
                ...allPortfolio.records.map((item) => ({
                    value: item?.id,
                    label: item?.name,
                    slug: item?.slug
                }))
            ] :
            []
    ) :
        // portfolioOptionsData.filter(item => item !== null);
        [
            { value: 'all', label: 'All' }, // Add 'All' option
            ...portfolioOptionsData.filter(item => item !== null)
        ]

    useEffect(() => {
        // console.log('portfoliosOptions', portfoliosOptions)
        if ((checkedData?.length + 1) === (portfoliosOptions.length)) {
            setCheckedData([...checkedData, 'all'])
        } 
    }, [portfoliosOptions])

    useEffect(() => {
        if (!editAllocationData && portfoliosOptions?.length && formData?.portfolio === null) {
            // setFormData({ ...formData, portfolio: portfoliosOptions[0] })
        }
    }, [portfoliosOptions, formData.portfolio])

    useEffect(() => {
        // if (portfoliosOptions && portfoliosOptions?.length) {
        //     setFormData({ ...formData, portfolio: portfoliosOptions[0] })
        // } else {
        setFormData({ ...formData, portfolio: null })
        // }

    }, [portfolioGroupOption])

    const onChangePortfoliosOption = (event) => {
        const gerObj = JSON.parse(event)
        setFormData({ ...formData, portfolio: gerObj })
        setError({})
    }
    const onChangePortfolioGroupOptions = (event) => {
        const gerObj = JSON.parse(event)

        setPortfolioGroupOption(gerObj)
        setCheckedData([])
        setError({})
    }
    // const onChangeHandel = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    //     setError({})
    // }


    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        // Clear the error if the value is non-negativ
        // Use setFormData to update other fields
        setFormData({ ...formData, [name]: value });
        setError({})
    };


    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        // if (!formData.id && portfolioGroupOption === null) {
        //     errors['portfolioGroup'] = 'Please select portfolio group';
        //     isValid = false;
        // }
        if (!formData.id && formData.portfolio === null) {
            errors['Portfolios'] = 'Please select a portfolio';
            isValid = false;
        }
        if (formData.assetName === '') {
            errors['assetName'] = 'Please enter Name';
            isValid = false;
        }
        // if (formData.description === '') {
        //     errors['description'] = 'Please enter description';
        //     isValid = false;
        // }
        if (formData.portfolio === null) {
            errors['subClass'] = 'Please select portfolio';
            isValid = false;
        }
        if (formData.startDate === null) {
            errors['startDate'] = 'Please select Start Date';
            isValid = false;
        }
        if (formData.endDate === null) {
            errors['endDate'] = 'Please select End Date';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const onSave = (e) => {
        if (handleValidation()) {
            const allItems = ["all", "All"]
            if (formData.id) {
                dispatch(updateAllocationDetails({
                    id: formData.id,
                    portfolio: checkedData?.length > 0 && checkedData?.filter((cd) => !allItems?.includes(cd)),
                    // group: portfolioGroupOptions?.length > 0 && Array.isArray(portfolioGroupOptions) ? portfolioGroupOptions[0]?.value : null,
                    name: formData.assetName,
                    portfolioGroup: portfolioGroupOption !== null ? portfolioGroupOption?.value : null,
                    description: formData.description,
                    startDate: moment(formData.startDate).format('MM/DD/YYYY'),
                    endDate: moment(formData.endDate).format('MM/DD/YYYY')
                }, setAllocationData, setDataFetched, currentPage, perPage))
            } else {
                dispatch(addAllocation({
                    portfolio: checkedData?.length > 0 && checkedData?.filter((cd) => !allItems?.includes(cd)),
                    name: formData.assetName,
                    portfolioGroup: portfolioGroupOption !== null ? portfolioGroupOption?.value : null,
                    description: formData.description,
                    startDate: moment(formData.startDate).format('MM/DD/YYYY'),
                    endDate: moment(formData.endDate).format('MM/DD/YYYY')
                }, setAllocationData, setDataFetched, currentPage, perPage))
            }
            setFormData({
                assetName: "",
                description: "",
                portfolio: null,
                startDate: null,
                endDate: null
            })
            props.onHide();
        }

    }

    // const onCheckedCustomFieldBox = (props) => {
    //     const { data } = props;
    //     setCheckedData((prev) => {
    //         const alreadyExit = prev?.length ? prev.filter((item) => {
    //             if (item?.value === data?.value) {
    //                 return true;
    //             }
    //             return false;
    //         }) : null
    //         if (alreadyExit?.length) {
    //             return prev.filter((item) => {
    //                 if (item?.value === data?.value) {
    //                     return false;
    //                 }
    //                 return true;
    //             })
    //         } else {
    //             return [...prev, data]
    //         }

    //     })
    // }

    const onCheckedCustomFieldBox = (props) => {
        const { data } = props;

        // If "All" option is selected, select all other options

        if (data.value === 'all') {

            // If "All" option is already selected, clear all selections
            if (checkedData?.length === portfoliosOptions?.length) {
                setCheckedData([]);
            } else {
                // Select all other options
                setCheckedData(portfoliosOptions.map(option => option.value));
            }
        } else {
            // Toggle selection for other options
            setCheckedData(prev => {
                let newData;

                const alreadyExists = prev.some(item => item === data.value);

                if (alreadyExists) {
                    const alreadyExistsData = prev.filter(item => item !== data.value);
                    newData =  alreadyExistsData
                } else {
                    newData =  portfolioGroupOption?.value !== null ? [...prev, data?.value] : [data?.value];
                    // return [...prev, data?.value];
                }

                return newData?.filter((aed) => aed !== "all")
            });
            // setCheckedData(prev => {
            //     let updateData
            //     const alreadyExists = prev.some(item => item === data.value);

            //     if (alreadyExists) {
            //         const alreadyExistsData = prev.filter(item => item !== data.value);
            //         updateData = alreadyExistsData
            //     } else {
            //         updateData = portfolioGroupOption?.value !== null ? [...prev, data?.value] : [data?.value];
            //         // return [...prev, data?.value];
            //     }
            //     return updateData.filter(item => item !== 'all') 
            // });
        }
    };

    useEffect(() => {
        setFormData({ ...formData, portfolio: checkedData })
    }, [checkedData])


    const OptionCustomField = ({ children, ...props }) => {
        const { onCheckedCustomFieldBox } = props.selectProps;

        //props?.data?.value === "all" && !checkedData?.includes("all") && portfoliosOptions?.length - 1 === checkedData?.length ? true :
        const checkedCondition = checkedData?.includes(props.data.value)
        
        return (
            <components.Control {...props}>
                <div className="flex p-2 align-middle justify-start items-center text-sm w-full" onClick={() => onCheckedCustomFieldBox(props)}>
                    <span className="me-2">
                        <Checkbox
                            checked={checkedCondition}
                            aria-label="Select row"
                            // rounded-[4px]
                            className="translate-y-[2px] rounded-[4px] border-black " />
                    </span>
                    {children}
                </div>
            </components.Control>
        );
    };

    return (
        <>
            <Dialog open={show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[70%] gap-0 p-0">
                    <DialogHeader className="p-5 border-b border-borderV1">
                        <DialogTitle className="text-start">{formData.id ? 'Update Allocation' : 'Create Allocation'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body p-5">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-14">
                            <div className='flex gap-6 flex-col'>
                                {/* !formData.id && before adding the portfolioGroup in update */}
                                {<div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Portfolio Group
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangePortfolioGroupOptions(e)}
                                            defaultValue={portfolioGroupOption?.value}
                                        >
                                            <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'

                                                    placeholder="Select Portfolio Group"
                                                >
                                                    {portfolioGroupOption !== null ? portfolioGroupOption?.label : "Select Portfolio Group"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        portfolioGroupOptions && portfolioGroupOptions.length > 0 ? portfolioGroupOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        {
                                            error['portfolioGroup'] && <div className={` ${error['portfolioGroup'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['portfolioGroup'] ? error['portfolioGroup'] : null}</div>
                                        }
                                    </div>
                                </div>}
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Portfolios<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        {/* <Select
                                            onValueChange={(e) => onChangePortfoliosOption(e)}
                                            defaultValue={!editAllocationData && formData.portfolio}
                                            isDisabled={formData.id}
                                        >
                                            <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Portfolio"
                                                    isDisabled={formData.id}
                                                >
                                                    {formData.portfolio !== null ? formData.portfolio?.label : "Select Portfolio"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        portfoliosOptions && portfoliosOptions.length > 0 ? portfoliosOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select> */}
                                        <ReactSelect
                                            components={{ Option: OptionCustomField }}
                                            isSearchable={false}
                                            inputValue=""
                                            closeMenuOnSelect={false}
                                            value={null}
                                            className='w-full h-[34px] text-[13px] !rounded-[15px] !text-black multi_react_select'
                                            // hideSelectedOptions={true}

                                            onCheckedCustomFieldBox={onCheckedCustomFieldBox}
                                            placeholder='Portfolio'
                                            name="Portfolio"
                                            options={portfoliosOptions}
                                            styles={{
                                                menuList: (base) => ({
                                                    ...base,
                                                    fontSize: "10px",
                                                    overflow: "auto",
                                                    maxHeight: "180px",
                                                    lineHeight: '1rem',
                                                    fontWeight: 500,
                                                    borderRadius: '0',
                                                    backgroundColor: 'transparent'

                                                }),
                                                menu: (base) => ({
                                                    ...base,
                                                    overflow: "auto",
                                                    margin: 0,
                                                    padding: 0,
                                                    width: '100%',
                                                    borderRadius: '0',

                                                }),
                                                // input: (base) => ({
                                                //     ...base,
                                                //     width: '30%',
                                                // }),
                                                // container: (base) => ({
                                                //     ...base,
                                                //     width: '150px',
                                                // }),
                                            }}
                                        />
                                        {
                                            error['Portfolios'] && <div className={` ${error['Portfolios'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['Portfolios'] ? error['Portfolios'] : null}</div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Start Date:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        {/* <DatePicker className="w-full h-[34px] text-[13px] rounded-[12px]" onDateSelect={(date) => onChangeHandel({ target: { name: 'startDate', value: date } })} /> */}
                                        <FromDatePicker onDateSelect={(date) => onChangeHandel({ target: { name: 'startDate', value: date } })}
                                            placeholderText='YYYY-MM-DD'
                                            value={formData.startDate}
                                            selectedFromDate={formData.startDate}
                                        />
                                        {
                                            error['startDate'] && <div className={`${error['startDate'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['startDate'] ? error['startDate'] : null}</div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            End Date:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        {/* <DatePicker className="w-full h-[34px] text-[13px] rounded-[12px]" onDateSelect={(date) => onChangeHandel({ target: { name: 'endDate', value: date } })} /> */}
                                        <FromDatePicker onDateSelect={(date) => onChangeHandel({ target: { name: 'endDate', value: date } })}
                                            placeholderText='YYYY-MM-DD'
                                            minDate={formData.startDate}
                                            value={formData.endDate}
                                            selectedFromDate={formData.startDate}
                                        />
                                        {
                                            error['endDate'] && <div className={`${error['endDate'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['endDate'] ? error['endDate'] : null}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-6 flex-col'>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Name:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input
                                            className={`form-control w-full h-[34px] text-[13px] rounded-[12px] shadow-none ${error['assetName'] && 'is-invalid'}`} name='assetName' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.assetName} />
                                        {
                                            error['assetName'] && <div className={`${error['assetName'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['assetName'] ? error['assetName'] : null}</div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Description:
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Textarea
                                            className={`form-control w-full text-[13px] rounded-[12px] shadow-none ${error['description'] && 'is-invalid'}`} name='description' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" rows="3" placeholder="Description" value={formData.description} />
                                        {
                                            error['description'] && <div className={`${error['description'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['description'] ? error['description'] : null}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap-2 p-5"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AddAllocationModel
