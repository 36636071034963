import { allocationsMonitorActionType } from '../../constants';

const AllocationsMonitorReducer = (state = [], action) => {
    switch (action.type) {
        case allocationsMonitorActionType.FETCH_ALLOCATIONS_MONITOR:
            return action.payload;
        case allocationsMonitorActionType.FETCH_ALLOCATION_MONITOR:
            return action.payload;
        case allocationsMonitorActionType.ADD_ALLOCATION_MONITOR:
            return action.payload;
        case allocationsMonitorActionType.UPDATE_ALLOCATION_MONITOR:
            return action.payload;
        case allocationsMonitorActionType.DELETE_ALLOCATION_MONITOR:
            return action.payload;
        default:
            return state;
    }
};
export default AllocationsMonitorReducer