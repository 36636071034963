import { allocationsActionType } from '../../constants';

const AllocationHistoryReducer = (state = [], action) => {
    switch (action.type) {
        case allocationsActionType.HISTORY_ALLOCATION:
            return action.payload;
        default:
            return state;
    }
};
export default AllocationHistoryReducer