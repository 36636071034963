import React, { useEffect, useState } from 'react'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table"
import { Badge, generateBadgeVariant } from '../ui/badge'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchAllocationHistory } from '../../store/actions/AllocationHistory'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { dateOrTimeConvert } from '../../shared/common/dateFormate'
// import { dateOrTimeConvert } from '../../shared/common/dateFormate'

const data = [
    {
        id: "1",
        date: "Feb 26, 2024, 6:56 PM",
        action: "Created",
        details: {
            name: "Test Name1",
            Description: "Allocation Created"
        },
    },
    {
        id: "1",
        date: "Feb 26, 2024, 6:56 PM",
        action: "Created",
        details: {
            name: "Test Name1",
            Description: "Allocation Created"
        },
    },
    {
        id: "1",
        date: "Feb 26, 2024, 6:56 PM",
        action: "Created",
        details: {
            name: "Test Name1",
            Description: "Allocation Created"
        },
    },
    {
        id: "1",
        date: "Feb 26, 2024, 6:56 PM",
        action: "Created",
        details: {
            name: "Test Name1",
            Description: "Allocation Created"
        },
    },
    {
        id: "1",
        date: "Feb 26, 2024, 6:56 PM",
        action: "Created",
        details: {
            name: "Test Name1",
            Description: "Allocation Created"
        },
    },
]



export const columns = [
    {
        accessorKey: "id",
        header: "Id",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("id")}</div>
        ),
    },
    {
        accessorKey: "date",
        header: "Date",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("date")}</div>
        ),
    },
    {
        accessorKey: "action",
        header: "Action",
        cell: ({ row }) => {
            const variant = generateBadgeVariant(row.getValue("action"))
            return <Badge variant={variant} className={""}>{row.getValue("action")}</Badge>
        },
    },
    {
        accessorKey: "details",
        header: "Details",
        cell: ({ row }) => {
            const name = row.getValue("details").name
            const description = row.getValue("details").Description
            return <div className="capitalize">
                <h6 className='mb-1'><span className='text-textV1'>Name :</span> {name}</h6>
                <h6><span className='text-textV1'>Description :</span> {description}</h6>
            </div>
        },
    },
]

const AllocationHistoryTableTable = () => {
    const { allocationHistory } = useSelector(state => state)
    const dispatch = useDispatch()
    const location = useLocation();
    const receivedData = location.state?.updateData;
    const [fetchedData, setFetchedData] = useState([])
    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState(
        []
    )
    const [columnVisibility, setColumnVisibility] =
        useState({})
    const [rowSelection, setRowSelection] = useState({})

    // my Code
    const [history, setHistory] = useState([])
    useEffect(() => {
        // const getData = JSON.parse(localStorage.getItem('allocationDetails'))
        if (receivedData !== undefined && receivedData !== null) {
            dispatch(fetchAllocationHistory(receivedData.id))
            setFetchedData(receivedData)
        }
    }, [])


    useEffect(() => {
        if (!_.isEmpty(allocationHistory)) {
            setHistory(allocationHistory)
        } else {
            setHistory([])
        }
    }, [allocationHistory])

    const data = history?.length >= 1 ? history.map((event, i) => {
        return ({
            id: i + 1,
            date: dateOrTimeConvert(event.date),
            action: event.action,
            details: event.details
        })
    }) : []

    const columns = [
        {
            accessorKey: "id",
            header: "Id",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("id")}</div>
            ),
        },
        {
            accessorKey: "date",
            header: "Date",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("date")}</div>
            ),
        },
        {
            accessorKey: "action",
            header: "Action",
            cell: ({ row }) => {
                const variant = generateBadgeVariant(row.getValue("action"))
                return <Badge variant={variant} className={""}>{row.getValue("action")}</Badge>
            },
        },
        {
            accessorKey: "details",
            header: "Details",
            cell: ({ row }) => {
                return <div className="capitalize">
                    {row.original?.details.name ? <h6 className='mb-1'><span className='text-textV1'>Name :</span> {row.original?.details.name}</h6> : ''}
                    {row.original?.details.description ? <h6><span className='text-textV1'>Description :</span> {row.original?.details.description}</h6> : ''}
                </div>
            },
        },
    ]

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
            pagination: {
                pageIndex: 0,
                pageSize: data.length,
            }
        },
    })

    return (
        <div className="w-full max-h-[740px] overflow-y-auto" >
            <div className="flex items-center pb-4 gap-4 justify-between">
                <h3 className='text-lg font-semibold leading-none tracking-tight'>History</h3>
                <Input
                    placeholder="Search filter...."
                    value={data.length ? (table?.getColumn("id")?.getFilterValue() || "") : ''}
                    onChange={(event) =>
                        data.length && table?.getColumn("id")?.setFilterValue(event.target.value)
                    }
                    className="h-8 w-full sm:w-[150px] lg:w-[250px]"
                />
            </div>
            <div className="rounded-sm border">
                <Table className="min-w-[800px]">
                    <TableHeader>
                        {table?.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table?.getRowModel().rows?.length ? (
                            table?.getRowModel().rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    data-state={row.getIsSelected() && "selected"}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell className="text-xs" key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default AllocationHistoryTableTable