import { strategyActionType } from "../../constants";


const StrategyReducer = (state = [], action) => {
    switch (action.type) {
        case strategyActionType.FETCH_STRATEGY:
            return action.payload;
        case strategyActionType.FETCH_STRATEGY:
            return action.payload;
        case strategyActionType.ADD_STRATEGY:
            return action.payload;
        case strategyActionType.UPDATE_STRATEGY:
            return action.payload;
        case strategyActionType.DELETE_STRATEGY:
            return action.payload;
        default:
            return state;
    }
};
export default StrategyReducer