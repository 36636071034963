import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { SettingBtnContextNew } from "../MultipleViewSetting";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { fetchCustomColumnsViews } from "../../../../store/actions/CustomColumnsViewsAction";
import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import IconExchange from "../../../../components/svg/IconExchange";
import { Button } from "@/components/ui/button";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import { addTost } from "../../../../store/actions/toastAction";
import { toastifyType } from "../../../../constants";
import IconDrag from "../../../../components/svg/IconDrag";
const SELECTED_FIELD = "selected";

const MyCustomItemOne = (props) => {
  const { dataItem, index, selected, handleItemClick } = props;
  return (
    <Draggable draggableId={dataItem._id} index={index}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            left: "auto !important",
            top: "auto !important",
          }}
          onClick={(e) =>
            handleItemClick(e, dataItem, "primary", "activeFilled")
          }
          className={`h-[30px] px-3 flex items-center mb-1 last:mb-0 bg-white hover:bg-[#d7ebff] group justify-start ${snapshot.isDragging && "bg-[#d7ebff] dragging"}`}
        >
         <span className="text-xs text-textV1 group-hover:text-theme group-[.dragging]:text-theme flex items-center gap-2">
            <span><IconDrag className="w-3 h-3"/></span> {dataItem.name}
          </span>
        </li>
      )}
    </Draggable>
  );
};

const MyCustomItemTwo = (props) => {
  const { dataItem, index, handleItemClick } = props;

  return (
    <Draggable draggableId={dataItem._id} index={index}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            left: "auto !important",
            top: "auto !important",
          }}
          onClick={(e) =>
            handleItemClick(e, dataItem, "activeFilled", "primary")
          }
          className={`h-[30px] px-3 flex items-center mb-1 last:mb-0 bg-white hover:bg-[#d7ebff] group justify-start ${snapshot.isDragging && "bg-[#d7ebff] dragging"}`}
        >
          <span className="text-xs text-textV1 group-hover:text-theme group-[.dragging]:text-theme flex items-center gap-2">
            <span><IconDrag className="w-3 h-3"/></span> {dataItem.name}
          </span>
        </li>
      )}
    </Draggable>
  );
};

const MultipleViewFilterColWithPackage = (props) => {
  const {
    state,
    setState,
    sectionType,
    setTotalPage,
    currentPage,
    setCurrentPage,
    totalRecords,
    setTotalRecords,
    perPage,
    setPerPage,
    searchValue,
    setSearchValue,
    totalPage,
  } = React.useContext(SettingBtnContextNew);
  const {
    setData,
    data,
    isResat,
    activeList,
    newActiveList,
    setNewActiveList,
    editColumns,
    setEditColumns,
    editData,
    setEditData,
    isUpdateSate,
    setIsUpdateSate,
    addColViewRow,
    setAddColViewRow,
  } = props;
  const { customColumnsView, columnsViewDetails } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  // const [state, setState] = useState({
  //     primary: [],
  //     activeFilled: [],
  //     activeName: ''
  // });

  useEffect(() => {
    setState({
      primary: customColumnsView.records ? customColumnsView.records : [],
      activeFilled: activeList?.columns ? activeList.columns : [],
      activeName: "",
    });
  }, [customColumnsView.records, activeList, columnsViewDetails, isUpdateSate]);

  useEffect(() => {
    setState({
      primary: customColumnsView.records ? customColumnsView.records : [],
      activeFilled: [],
      activeName: "",
    });
  }, [isResat]);

  const handleItemClick = (event, dataItem, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.name === dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleDragEnd = (result) => {
    if (activeList?.length === 0) {
      dispatch(
        addTost({
          message: "please select the lists!",
          type: toastifyType.SHOW_ERROR_MESSAGE,
        })
      );
    } else {
      if (!result.destination) return;

      const { source, destination } = result;

      setState((prevState) => {
        const updatedState = { ...prevState };

        const sourceList = updatedState[source.droppableId];
        const destinationList = updatedState[destination.droppableId];

        const [removed] = sourceList.splice(source.index, 1);
        destinationList.splice(destination.index, 0, removed);

        setNewActiveList({
          ...newActiveList,
          columns: updatedState.activeFilled,
        });
        setEditColumns({
          ...editColumns,
          ...activeList,
          columns: updatedState.activeFilled,
        });
        const updateData = data?.length
          ? data.map((item) => {
              if (item?._id == activeList?._id && activeList?.columns) {
                return { ...item, columns: updatedState.activeFilled };
              } else {
                return { ...item };
              }
            })
          : [];
        setData(updateData);
        const getObjId = state.activeFilled.map((item) => item._id);
        dispatch(
          fetchCustomColumnsViews(
            sectionType,
            currentPage,
            perPage,
            searchValue,
            setTotalRecords,
            setTotalPage,
            getObjId
          )
        );
        return updatedState;
      });
    }
  };

  // Pagination functions
  const indexOfLastItem = currentPage * 10;
  const indexOfFirstItem = indexOfLastItem - 10;
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="filds-card h-[335px]  w-[calc(47.5%-6px)] border border-borderV1">
        <div className="card-head py-1.5 h-[30px] flex items-center justify-center border-b border-borderV1 px-3">
          <h6 className="text-center text-xs text-textV1">Fields</h6>
        </div>
        <div className="border-b border-borderV1 flex items-center justify-center  px-3 h-[30px]">
          <div className="relative w-full">
            <span className="absolute top-1/2 start-0 -translate-y-1/2">
              <Search className="w-4 h-4 text-textV1" />
            </span>
            <Input
              className="px-3 !ps-6 border-none h-full py-0 text-xs"
              placeholder="Search Fields"
              type="text"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setCurrentPage(1);
                setIsUpdateSate((prevState) => true);
                const getObjId = state.activeFilled.map((item) => item._id);
                dispatch(
                  fetchCustomColumnsViews(
                    sectionType,
                    currentPage,
                    perPage,
                    e.target.value,
                    setTotalRecords,
                    setTotalPage,
                    getObjId
                  )
                );
              }}
            />
          </div>
        </div>
        <div className="card-body h-[calc(100%-60px)] overflow-hidden bg-backgroundV1">
          <Droppable
            droppableId="primary"
            isDropDisabled={activeList?.length === 0}
          >
            {(provided) => (
              <ul
                className="h-full overflow-y-auto p-1 overflow-x-hidden"
                ref={provided.innerRef}
                {...provided.droppableProps}
                {...provided.droppableProps}
              >
                {state.primary?.map((item, index) => (
                  <MyCustomItemOne
                    key={item.code}
                    dataItem={item}
                    index={index}
                    selected={item[SELECTED_FIELD]}
                    handleItemClick={handleItemClick}
                  />
                ))}
              </ul>
            )}
          </Droppable>
        </div>
        <div className="flex justify-between mt-1">
          <span
            className="none sm:inline-block mt-2 text-textV1"
            style={{ fontSize: "12px" }}
          >
            {indexOfFirstItem + 1} <span className="text-600">to</span>{" "}
            {Math.min(indexOfLastItem, totalRecords)}{" "}
            <span className="text-600">of</span> {totalRecords}{" "}
            <span className="text-600">Items</span>
          </span>
          <div className="flex items-center space-x-1">
            <Button
              variant="outline"
              className="h-6 w-6 p-0 border border-borderV1 text-xs rounded-[4px] text-textV1"
              onClick={() => {
                setCurrentPage((prev) => Math.max(prev - 1, 1));
                setIsUpdateSate((prevState) => true);
              }}
              disabled={totalRecords <= 10 || currentPage === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button variant="outline" className="h-6 w-6 text-xs rounded-[4px] p-0 border border-borderV1 text-textV1" disabled={true}>
              {currentPage}
            </Button>
            <Button
              variant="outline"
              className="h-6 w-6 p-0 border border-borderV1 text-xs rounded-[4px] text-textV1"
              onClick={() => {
                setCurrentPage((prev) =>
                  Math.min(prev + 1, Math.ceil(totalRecords / perPage))
                );
                setIsUpdateSate((prevState) => true);
              }}
              disabled={totalRecords <= 10 || currentPage === totalPage}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
      <span className="w-[calc(5%-6px)] flex items-center justify-center">
        <IconExchange className="w-6 h-6 text-textV2" />
      </span>
      <div className="filds-card h-[335px] overflow-hidden border  w-[calc(47.5%-6px)] border-borderV1">
        <div className="card-head py-1.5 border-b h-[30px] flex items-center justify-center px-3 border-borderV1">
          <h6 className="text-center text-xs text-textV1">View Fields</h6>
        </div>
        <div className="card-body h-[calc(100%-30px)] overflow-hidden bg-backgroundV1">
          <Droppable
            droppableId="activeFilled"
            isDropDisabled={activeList?.length === 0}
          >
            {(provided) => (
              <ul
                className="h-full overflow-y-auto p-1 overflow-x-hidden"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {state.activeFilled?.map((item, index) => (
                  <MyCustomItemTwo
                    key={item._id}
                    dataItem={item}
                    index={index}
                    selected={item[SELECTED_FIELD]}
                    handleItemClick={handleItemClick}
                  />
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default MultipleViewFilterColWithPackage;

// useEffect(() => {
// let storeId = [];
// let storeIdTwo = [];
// let viewId = [];
// let primaryViewId = [];
// columnsViewDetails?.columns?.map((item) => {
//     storeId.push(item.code);
// });

// let primaryData = (activeList?.columns && customColumnsView.records)
//     ? isFirstTime ?
//     customColumnsView.records.filter((field) => !storeId.includes(field.code)).map((field) => ({ ...field, selected: false })) :
//     customColumnsView.records.filter((field) => !storeId.includes(field.code) || !storeIdTwo.includes(field.code)).map((field) => ({ ...field, selected: false })) :
//     customColumnsView.records ?
//     customColumnsView.records.filter((field) => field._id).map((field) => ({ ...field, selected: false })) :
//     [];

// let activeFilledData = (activeList?.columns && customColumnsView.records)
//     ? activeList?.columns.filter((field) => storeId.includes(field.code)).slice()?.sort((a, b) => a.index - b.index)?.map((field) => ({ ...field, selected: true }))
//     : [];

// state.activeFilled?.map((item) => {
//     viewId.push(item._id);
// });

// state.primary?.map((item) => {
//     primaryViewId.push(item._id);
// });

// let updateViewData = state.primary.filter((item) => !viewId.includes(item._id))
// let updateViewDataTwo = state.activeFilled.filter((item) => !primaryViewId.includes(item._id))
// setState({
//     primary: customColumnsView.records ? customColumnsView.records : [],
//     activeFilled: activeList?.columns ? activeList.columns : [],
//     draggedItem: null,
// });

// if ((state.activeName === activeList?.name) && (state.activeName !== undefined) && (activeList?.name !== undefined) && (isUpdateSate)) {
//     setState((prevState) => {
//         return {
//             ...prevState,
//             primary: primaryData.filter((item) => !viewId.includes(item._id)),
//             activeFilled: prevState.activeFilled.length ? prevState.activeFilled : activeFilledData,
//             activeName: prevState.activeName
//         };
//     });
//     setIsUpdateSate((prevState) => true)
//     setFirstTime((prevState) => false)
// } else {
//     setState({
//         primary: primaryData,
//         activeFilled: activeFilledData,
//         activeName: activeList?.name
//     });
//     setFirstTime((prevState) => true)
// }

// }, [customColumnsView.records, activeList, columnsViewDetails, isUpdateSate])
