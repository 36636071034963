import { apiBaseURL, adminTypeActionType, toastifyType } from "../../../constants";
import { setLoading } from "../loadingAction";
import apiConfig from '../../../config/apiConfig';
import { addTost } from "../toastAction";
import { metaDataAction } from "../metaDataAction";


export const fetchAdminTypes = (filter) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.get(apiBaseURL.ADMIN_TYPE + `?page=${filter.page}&perPage=${filter.perPage}`);
        dispatch({ type: adminTypeActionType.FETCH_TYPES, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: filter.perPage, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};


export const addAdminTypes = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.ADMIN_TYPE, data);
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchAdminTypes({page:1,perPage:10}));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const deleteAdminTypes = (id) => async (dispatch) => {
    try {

        dispatch(setLoading(true));
        const response = await apiConfig.delete(apiBaseURL.ADMIN_TYPE + "/" + id)
        // const response = await apiConfig.delete(apiBaseURL.TYPES + '/' + id);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchAdminTypes({page:1,perPage:10}));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};


export const updateAdminTypes = (data) => async (dispatch) => {
    try {

        dispatch(setLoading(true));
        const response = await apiConfig.put(apiBaseURL.ADMIN_TYPE, data);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchAdminTypes({page:1,perPage:10}));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};