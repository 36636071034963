import apiConfig from '../../config/apiConfig';
import { apiBaseURL, financePriceActionType, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { metaDataAction } from './metaDataAction';
import { addTost } from './toastAction';

export const fetchFinancePrice = (currentPage, perPage) => async (dispatch) => {
    // dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.FINANCE + '/assets' + '?page=' + currentPage + '&perPage=' + perPage)
        dispatch({ type: financePriceActionType.FETCH_FINANCE_PRICE_V2, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: perPage, totalItems: response?.data?.data?.totalRecords }))
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const getFinancePrice = (slug, currentPage, perPage, startDate, endDate, type) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.FINANCE + '/assets/' + slug + '/prices' + '?page=' + currentPage + '&perPage=' + perPage + '&startDate=' + startDate + '&endDate=' + endDate + '&type=' + type)
        dispatch({ type: financePriceActionType.FETCH_FINANCE_PRICE_V2, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: perPage, totalItems: response?.data?.data?.totalRecords }))
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const exportFinancePrice = (slug, currentPage, perPage, startDate, endDate, type) => async (dispatch) => {

    try {
        const response = await apiConfig.get(apiBaseURL.FINANCE + '/assets/' + slug + '/prices' + '?page=' + currentPage + '&perPage=' + perPage + '&startDate=' + startDate + '&endDate=' + endDate + '&type=' + type)
        // Check if the response has a downloadUrl
        const downloadUrl = response.data?.data?.downloadUrl;
        if (downloadUrl) {
            // Use the fetch API to download the file
            const fileResponse = await fetch(downloadUrl);
            const blob = await fileResponse.blob();

            // Create a temporary URL for the blob
            const blobUrl = URL.createObjectURL(blob);

            // Create a hidden anchor element
            const a = document.createElement('a');
            a.href = blobUrl;
            a.download = slug;  // You can set the desired filename here
            document.body.appendChild(a);

            // Trigger a click on the anchor to start the download
            a.click();

            // Remove the anchor element
            document.body.removeChild(a);

            // Release the object URL
            URL.revokeObjectURL(blobUrl);
        }
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}