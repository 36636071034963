import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
// import Index from "../pages/dashboard/portfolio/index.js";
// import Index from "../pages/dashboard/portfolioMain/index.js";
import { Tokens } from "../constants/index.js";
import { route } from "./route.js";

export default function Router() {
    const token = localStorage.getItem(Tokens.ADMIN_TOKEN);
    const admin = localStorage.getItem("admin");
    const ActualAdminData = JSON.parse(admin)
    // console.log(ActualAdminData)
    const prepareRoutes = () => {

        let filterRoutes = [];
        route.forEach((route) => {
            if (route.permission === ActualAdminData?.role) {
                filterRoutes.push(route)
            }
        });
        return filterRoutes;
    };

    const routes = prepareRoutes();
    return (
        <>
            {ActualAdminData?.role === 'user' ? <Routes>
                {routes.map((route, index) => {
                    return route.ele ? (
                        <Route key={index} exact={true} path={route.path} element={token ? route.ele : <Navigate replace to={"/login"} />} />
                    ) : (null)
                })}
                <Route path='*' element={<Navigate replace to={"/"} />} />
            </Routes> :
                <Routes>
                    {routes.map((route, index) => {
                        return route.ele ? (
                            <Route key={index} exact={true} path={route.path} element={token ? route.ele : <Navigate replace to={"/login"} />} />
                        ) : (null)
                    })}
                    <Route path='*' element={<Navigate replace to={"/admin"} />} />
                </Routes>}

            {/* {ActualAdminData?.role === 'user' ? <Routes>
                {routes.map((route, index) => {
                    return route.ele ? (
                        <Route key={index} exact={true} path={route.path} element={token ? route.ele : <Navigate replace to={"/login"} />} />
                    ) : (null)
                })}
                <Route path='*' element={<Navigate replace to={"/"} />} />
            </Routes> :
                <Routes>
                    {routes.map((route, index) => {
                        return route.ele ? (
                            <Route key={index} exact={true} path={route.path} element={token ? route.ele : <Navigate replace to={"/login"} />} />
                        ) : (null)
                    })}
                    <Route path='*' element={<Navigate replace to={"/login"} />} />
                </Routes>
            } */}
        </>
    )

}
