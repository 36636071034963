import React, { createContext, useEffect, useState } from 'react'
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import AllocationMonitoringContent from "../../../../shared/common/portfolio/tabs-content/AllocationMonitoringContent";
import PortfolioGroupContent from "../portfolio-group-tabs-Contents/PortfolioGroupContent";
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addTost } from '../../../../store/actions/toastAction';
import { toastifyType } from '../../../../constants';

const PortfolioGroupTabs = (props) => {
    const { allPortfolios, getPortfolio, groupSelectedId, PortfolioGroupColumnsViews, selectedId } = props;

    const dispatch = useDispatch();

    const { slug } = useParams();
    const { userAnalytic, strategy } = useSelector(state => state)
    const [addAssetsModel, setAddAssetsModel] = useState(false)
    const [showAssetsRemoval, setShowAssetsRemoval] = useState(false)
    const [addAssetsAccordion, setAddAssetsAccordion] = useState(false)
    const [fetchAssetData, setFetchAssetData] = useState({})
    const [updateId, setUpdateId] = useState(null)
    const [allAnalytics, setAllAnalytics] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [portfolioAssetId, setPortfolioAssetId] = useState(null)


    // useEffect(() => {
    //   if (!_.isEmpty(fetchAssetData)) {
    //   dispatch(fetchUserAnalytics())
    //   }
    // }, [])



    useEffect(() => {
        if (!_.isEmpty(fetchAssetData)) {
            // setIsLoading(true)
            // dispatch(fetchGroupAnalyticsData(fetchAssetData?._id, setIsLoading))
        }
    }, [fetchAssetData])

    useEffect(() => {
        if(selectedId?.length === 0){
            setFetchAssetData({})
        }
    }, [selectedId])

    useEffect(() => {
        setFetchAssetData({})
        setUpdateId(null)
    }, [slug])

    useEffect(() => {
        setAllAnalytics(userAnalytic)
    }, [userAnalytic])

    const onShowAssets = (value, portfolioId) => {
        setAddAssetsModel(true)
        setPortfolioAssetId(portfolioId)
    }

    const onHideAssets = () => {
        setAddAssetsModel(false)
    }

    const onShowAssetsAccordion = () => {
        setAddAssetsAccordion(!addAssetsAccordion)
    }

    const onShowAssetsRemovalAccordion = () => {
        if (_.isEmpty(fetchAssetData)) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
        } else {
            setShowAssetsRemoval(!showAssetsRemoval)
        }
    }

    const onCheckBtn = (data) => {
        setFetchAssetData(data)
        setUpdateId(data?.asset_id)
    }

    return (
        <section className='p-2 md:px-5'>
            <Tabs defaultValue="Asset" className="w-[full]">
                <div className="mb-4">
                    <TabsList className="flex items-center rounded-[12px] w-fit bg-[#DEDEDE] p-1">
                        <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Asset">Asset</TabsTrigger>
                        <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Allocation-Monitoring">Allocation Monitoring</TabsTrigger>
                    </TabsList>
                </div>
                <TabsContent value="Asset" className="m-0">
                    <PortfolioGroupContent show={addAssetsModel} fetchAssetData={fetchAssetData} allAnalytics={allAnalytics} updateId={updateId} onCheckBtn={onCheckBtn} groupSelectedId={groupSelectedId} setIsLoading={setIsLoading} isLoading={isLoading} PortfolioGroupColumnsViews={PortfolioGroupColumnsViews} selectedId={selectedId}/>
                </TabsContent>
                <TabsContent value="Allocation-Monitoring">
                {/* // Portfolio group */}
                    <AllocationMonitoringContent 
                    PortfolioName={null}
                        getPortfolio={getPortfolio}
                        groupSelectedId={groupSelectedId}
                        addAssetsAccordion={null}
                        isGroup={true} />
                </TabsContent>
            </Tabs>
        </section>
    )
}


export default PortfolioGroupTabs