import { subClassActionType } from '../../constants';

const subClassReducer = (state = [], action) => {
    switch (action.type) {
        case subClassActionType.FETCH_SUB_CLASSES:
            return action.payload;
        case subClassActionType.FETCH_SUB_CLASS:
            return action.payload;
        case subClassActionType.ADD_SUB_CLASS:
            return action.payload;
        case subClassActionType.UPDATE_SUB_CLASS:
            return action.payload;
        case subClassActionType.DELETE_SUB_CLASS:
            return action.payload;
        default:
            return state;
    }
};
export default subClassReducer