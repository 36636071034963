import { classActionType } from '../../constants';

const classReducer = (state = [], action) => {
    switch (action.type) {
        case classActionType.FETCH_CLASSES:
            return action.payload;
        case classActionType.FETCH_CLASS:
            return action.payload;
        case classActionType.ADD_CLASS:
            return action.payload;
        case classActionType.UPDATE_CLASS:
            return action.payload;
        case classActionType.DELETE_CLASS:
            return action.payload;
        default:
            return state;
    }
};
export default classReducer