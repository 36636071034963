import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import Creatable from "react-select/creatable";
import makeAnimated from 'react-select/animated';
import { fetchGoals, fetchIndicatorSource, fetchIndicatorSourceCategories, fetchIndicators, fetchSeries, fetchTarget, fetchTargetIndicators } from '../../../../store/actions/analyticsAction';
import { addUserAnalytics, addUserUnitedNationsAnalytics } from '../../../../store/actions/userAnalyticsAction';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
const animatedComponents = makeAnimated();

const AnalyticsModel = (props) => {
    const { show, onHide, saveData } = props;
    const dispatch = useDispatch();
    const { isLoading, analytics } = useSelector(state => state);
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({
        sourceName: null,
        indicatorCategoryName: null,
        indicators: null,
        goals: null,
        targets: null,
        targetIndicators: null,
        series: null
    });

    useEffect(() => {
        if (show === false) {
            setFormData({
                sourceName: null,
                indicatorCategoryName: null,
                indicators: null,
                goals: null,
                targets: null,
                targetIndicators: null,
                series: null
            });
            setError({});
        }
    }, [show])



    useEffect(() => {
        if (formData.sourceName && formData.sourceName?.value) {
            dispatch(fetchIndicatorSourceCategories(formData.sourceName?.value))
        }
    }, [formData.sourceName])


    useEffect(() => {
        if (formData.goals && formData.goals?.value) {
            dispatch(fetchTarget(formData.goals?.value))
        }
    }, [formData.goals])

    useEffect(() => {
        if (formData.targets && formData.targets?.value) {
            dispatch(fetchTargetIndicators(formData.targets?.value))
        }
    }, [formData.targets])

    useEffect(() => {
        if (formData.indicatorCategoryName && formData.indicatorCategoryName?.value) {
            dispatch(fetchIndicators(formData.indicatorCategoryName?.value))
        }
    }, [formData.indicatorCategoryName])


    useEffect(() => {
        if (formData.targetIndicators && formData.targetIndicators?.value) {
            dispatch(fetchSeries(formData.targetIndicators?.value))
        }
    }, [formData.targetIndicators])

    const IndicatorSourceOptions = analytics && analytics?.indicatorSource?.length >= 1 ? analytics?.indicatorSource.map((item) => {
        return { value: item._id, label: item.sourceName }
    }) : []

    const indicatorSourceCategoryOptions = analytics && analytics?.indicatorSourceCategory?.length >= 1 ? analytics?.indicatorSourceCategory.map((item) => {
        return { value: item._id, label: item.indicatorCategoryName }
    }) : []

    const indicatorGoals = analytics && analytics?.goals?.length >= 1 ? analytics?.goals.map((item) => {
        return { value: item.id, label: item.code + '. ' + item.title }
    }) : []

    const indicatorTargets = analytics && analytics?.targets?.length >= 1 ? analytics?.targets.map((item) => {
        return { value: item.id, label: item.code + '. ' + item.title }
    }) : []

    const indicatorOptions = analytics && analytics?.indicator?.length >= 1 ? analytics?.indicator.map((item) => {
        return { value: item._id, label: item.indicatorName }
    }) : []

    const groupedIndicatorOptionsOptions = [
        {
            // label: 'World Bank:',
            options: indicatorOptions,
        },
    ];

    const groupStyles = {
        color: 'white',
        padding: '5px 5px',
        display: 'flex',
        fontWeight: '700',
        fontSize: '15px'
    };

    const indicatorSeparatorStyle = {
        alignSelf: 'stretch',
        marginBottom: 8,
        marginTop: 8,
        width: 1,
        cursor: 'pointer'
    };

    const IndicatorSeparator = ({
        innerProps,
    }) => {
        return <span style={indicatorSeparatorStyle} {...innerProps} />;
    };


    const GroupHeading = (props) => (
        <div style={groupStyles}>
            <components.GroupHeading {...props} />
        </div>
    );

    const targetIndicatorsOptions = analytics && analytics?.targetIndicators?.length >= 1 ? analytics?.targetIndicators.map((item) => {
        return { value: item.id, label: item.code + '. ' + item.description }
    }) : []


    const seriesOptions = analytics && analytics?.series?.length >= 1 ? analytics?.series.map((item) => {
        return { value: item._id, label: item.description }
    }) : []

    const groupedOptions = [
        {
            label: 'World Bank:',
            // options: indicatorOptions,
        },
        {
            label: 'United Nations (SDGs):',
            // options: unitedNationsOptions,
        },
    ];
    const transformedData = analytics?.series?.flatMap((series) => {
        if (series.dimensions.length) {
            return series.dimensions.map((dimension) => {
                return {
                    label: `${series.description} / ${dimension.description}`,
                    value: dimension._id,
                    dimensionId: dimension._id,
                    seriesId: series._id,
                };
            });
        } else {
            return { value: series._id, label: series.description, seriesId: series._id, }
        }
    });

    const onChangeIndicatorSourceOption = (event) => {
        setFormData({ ...formData, sourceName: event })
        setError({})
    }
    const onChangeIndicatorSourceCategoryOption = (event) => {
        setFormData({ ...formData, indicatorCategoryName: event })
        setError({})
    }

    const onChangeIndicatorGoalsOption = (event) => {
        setFormData({ ...formData, goals: event })
        setError({})
    }

    const onChangeIndicatorTargetOption = (event) => {
        setFormData({ ...formData, targets: event })
        setError({})
    }
    const onChangeIndicatorOption = (event) => {
        setFormData({ ...formData, indicators: event })
        setError({})
    }

    const onChangeTargetIndicatorsOptionsOption = (event) => {
        setFormData({ ...formData, targetIndicators: event })
        setError({})
    }

    const onChangeSeriesOptionsOption = (event) => {
        setFormData({ ...formData, series: event })
        setError({})
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.sourceName === null) {
            errors['sourceName'] = 'Please Select Indicator Source';
            isValid = false;
        }
        if (formData.indicatorCategoryName === null) {
            errors['indicatorCategoryName'] = 'Please Select Indicator Source category';
            isValid = false;
        }
        if (formData?.indicators?.length === 0) {
            errors['indicator'] = 'Please Select Indicator';
            isValid = false;
        } else {
            if (formData?.indicators === null) {
                errors['indicator'] = 'Please Select Indicator';
                isValid = false;
            }
        }
        setError(errors);
        return isValid;
    };


    const handleValidatio1 = () => {
        let errors = {};
        let isValid = true;
        if (formData.goals === null) {
            errors['goals'] = 'Please Select goals';
            isValid = false;
        }
        if (formData.targets === null) {
            errors['targets'] = 'Please Select targets';
            isValid = false;
        }
        if (formData?.targetIndicators === null) {
            errors['targetIndicators'] = 'Please Select Indicator';
            isValid = false;
        }
        if (formData?.series?.length === 0) {
            errors['series'] = 'Please Select series';
            isValid = false;
        } else {
            if (formData?.series === null) {
                errors['series'] = 'Please Select series';
                isValid = false;
            }
        }
        setError(errors);
        return isValid;
    };

    const prepareData = (formData) => {
        const data = {
            // assetId: updateId,
            source: formData.sourceName && formData.sourceName?.value,
            // indicatorCategory: formData.indicatorCategoryName && formData.indicatorCategoryName?.value,
            indicators: formData.indicators && formData.indicators.map((item) => item.value),
        }
        return data
    }

    const prepareData1 = (formData) => {
        const data = {
            // assetId: updateId,
            source: formData.sourceName && formData.sourceName?.value,
            // goal: formData.goals && formData.goals?.value,
            // target: formData.targets && formData.targets?.value,
            // indicators: formData.targetIndicators && formData.targetIndicators.map((item) => item.value),
            indicators: [formData.targetIndicators && formData.targetIndicators?.value],
            series: formData.series && formData.series.map((item) => {
                if (item.dimensionId) {
                    return {
                        seriesId: item.seriesId,
                        dimensionId: item.dimensionId
                    }
                } else {
                    return {
                        seriesId: item.seriesId,
                    }
                }
            }),
        }
        return data
    }
    const onSave = () => {
        if (formData.sourceName?.label === "World Bank") {
            if (handleValidation()) {
                const data = prepareData(formData)
                saveData(data, formData.sourceName?.label)
                onHide()
            }
        } else {
            if (handleValidatio1()) {
                const data = prepareData1(formData)
                saveData(data, formData.sourceName?.label)
                onHide()
            }
        }

    }


    return (
        <>
            <Dialog open={show} onOpenChange={onHide}>
                <DialogContent className="sm:max-w-[40%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{'Add Analytics'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-1 gap-5">
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    Select Indicator Source:<span style={{ color: 'red' }}>*</span>
                                </Label>
                                <Select
                                    onValueChange={(e) => onChangeIndicatorSourceOption(JSON.parse(e))}
                                    defaultValue={IndicatorSourceOptions[0]}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue
                                            className='text-textV1'
                                            placeholder="Select Indicator Source"
                                        >
                                            {formData.sourceName !== null ? formData.sourceName?.label : "Select Indicator Source"}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {
                                                IndicatorSourceOptions && IndicatorSourceOptions.length > 0 ? IndicatorSourceOptions.map((item, index) => {
                                                    return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                            }
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                                <div className={error['sourceName'] ? "text-red text-[12px]" : ''}>{error['sourceName'] ? error['sourceName'] : null}</div>
                            </div>
                            {formData.sourceName?.label === "World Bank" &&
                                <>
                                    <div className="form-group">
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select Indicator Source Category:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Select
                                            onValueChange={(e) => onChangeIndicatorSourceCategoryOption(JSON.parse(e))}
                                            disabled={formData.sourceName === null}
                                        >
                                            <SelectTrigger className="w-full">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Indicator Source"
                                                >
                                                    {formData.indicatorCategoryName !== null ? formData.indicatorCategoryName?.label : "Select Indicator Source"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        indicatorSourceCategoryOptions && indicatorSourceCategoryOptions.length > 0 ? indicatorSourceCategoryOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['indicatorCategoryName'] ? "text-red text-[12px]" : ''}>{error['indicatorCategoryName'] ? error['indicatorCategoryName'] : null}</div>
                                    </div>

                                    <div className={`form-group  ${formData.indicatorCategoryName === null ? 'cursor-not-allowed' : ''}`}>
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select Indicator:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Creatable
                                            className={`form-select rounded-sm  border-0  p-0`}
                                            classNamePrefix="select"
                                            placeholder='Select Indicator'
                                            name="All"
                                            value={formData.indicators}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            isDisabled={formData.indicatorCategoryName === null}
                                            onChange={onChangeIndicatorOption}
                                            options={groupedIndicatorOptionsOptions}
                                            components={{GroupHeading, IndicatorSeparator }}
                                            styles={{
                                                groupHeading: (base) => ({
                                                    color: 'black',
                                                }),
                                                menuList: (base) => ({
                                                    fontSize: '12px',
                                                    overflow: 'auto',
                                                    maxHeight: '260px'
                                                }),
                                                option: (base) => ({
                                                    ...base,
                                                    cursor: 'pointer',
                                                })

                                            }}
                                        />
                                        {/* <Creatable
                                            className={`form-select rounded-sm  border-0  p-0`}
                                            classNamePrefix="select"
                                            placeholder='Select Indicator'
                                            name="All"
                                            value={formData.indicators}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            isDisabled={formData.indicatorCategoryName === null}
                                            onChange={onChangeIndicatorOption}
                                            options={groupedIndicatorOptionsOptions}
                                            components={{ GroupHeading, IndicatorSeparator }}
                                            styles={{
                                                groupHeading: (base) => ({
                                                    color: 'black',
                                                }),
                                                menuList: (base) => ({
                                                    fontSize: '12px',
                                                    overflow: 'auto',
                                                    maxHeight: '260px'
                                                }),
                                                option: (base) => ({
                                                    ...base,
                                                    cursor: 'pointer',
                                                    width: '100%'
                                                }),
                                                // valueContainer: (base) => ({
                                                //     ...base,
                                                //     display: 'flex',
                                                //     flexWrap: 'nowrap',
                                                //     overflowX: 'auto',
                                                //     width: '100%'
                                                // })
                                            }}
                                        /> */}
                                        <div className={error['indicator'] ? "text-red text-[12px]" : ''}>{error['indicator'] ? error['indicator'] : null}</div>
                                    </div>
                                </>
                            }

                            {formData.sourceName?.label === "United Nations (SDGs)" &&
                                <>
                                    <div className="form-group">
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select Goals:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Select
                                            onValueChange={(e) => onChangeIndicatorGoalsOption(JSON.parse(e))}
                                            defaultValue={indicatorGoals[0]}
                                            disabled={formData.sourceName === null}
                                        >
                                            <SelectTrigger className="w-full">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Goals"
                                                >
                                                    {formData.goals !== null ? formData.goals?.label : "Select Goals"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        indicatorGoals && indicatorGoals.length > 0 ? indicatorGoals.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['goals'] ? "text-red text-[12px]" : ''}>{error['goals'] ? error['goals'] : null}</div>
                                    </div>
                                    <div className="form-group">
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select Targets:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Select
                                            onValueChange={(e) => onChangeIndicatorTargetOption(JSON.parse(e))}
                                            defaultValue={indicatorTargets[0]}
                                            disabled={formData.goals === null}
                                        >
                                            <SelectTrigger className="w-full">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Target"
                                                >
                                                    {formData.targets !== null ? formData.targets?.label : "Select Target"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        indicatorTargets && indicatorTargets.length > 0 ? indicatorTargets.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['targets'] ? "text-red text-[12px]" : ''}>{error['targets'] ? error['targets'] : null}</div>
                                    </div>
                                    <div className="form-group">
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select Indicator:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Select
                                            onValueChange={(e) => onChangeTargetIndicatorsOptionsOption(JSON.parse(e))}
                                            defaultValue={indicatorTargets[0]}
                                            disabled={formData.targets === null}
                                        >
                                            <SelectTrigger className="w-full">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Indicator"
                                                >
                                                    {formData.targetIndicators !== null ? formData.targetIndicators?.label : "Select Indicator"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        targetIndicatorsOptions && targetIndicatorsOptions.length > 0 ? targetIndicatorsOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['targetIndicators'] ? "text-red text-[12px]" : ''}>{error['targetIndicators'] ? error['targetIndicators'] : null}</div>
                                    </div>
                                    <div className={`form-group  ${formData.targetIndicators === null ? 'cursor-not-allowed' : ''}`}>
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select Series:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Creatable
                                            className={`form-select rounded-sm  border-0  p-0`}
                                            classNamePrefix="select"
                                            placeholder='Select Series'
                                            name="All"
                                            value={formData.series}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            isDisabled={formData.targetIndicators === null}
                                            onChange={onChangeSeriesOptionsOption}
                                            options={transformedData}
                                            components={{ GroupHeading, IndicatorSeparator }}
                                            styles={{
                                                groupHeading: (base) => ({
                                                    color: 'black',
                                                }),
                                                menuList: (base) => ({
                                                    fontSize: '12px',
                                                    overflow: 'auto',
                                                    maxHeight: '260px',
                                                }),
                                                option: (base) => ({
                                                    ...base,
                                                    cursor: 'pointer',
                                                })

                                            }}
                                        />
                                        <div className={error['series'] ? "text-red text-[12px]" : ''}>{error['series'] ? error['series'] : null}</div>
                                    </div>
                                </>}
                        </div>
                    </div>
                    <DialogFooter className={"!justify-end !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" size="sm" className="min-w-[100px]" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AnalyticsModel