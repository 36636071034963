import axios from 'axios';
import axiosInterceptor from './axiosInterceptor';
import { environment } from './environment';

const wampServer = `${environment.SERVER_URL}/api/`;
const axiosApi = axios.create({
    baseURL: wampServer,
});

axiosInterceptor.setupInterceptors(axiosApi, false, false);
export default axiosApi;
