import { allocationsActionType } from '../../constants';

const allocationsReducer = (state = [], action) => {
    switch (action.type) {
        case allocationsActionType.FETCH_ALLOCATIONS:
            return action.payload;
        case allocationsActionType.FETCH_ALLOCATION:
            return action.payload;
        case allocationsActionType.ADD_ALLOCATION:
            return action.payload;
        case allocationsActionType.UPDATE_ALLOCATION:
            return action.payload;
        case allocationsActionType.DELETE_ALLOCATION:
            return action.payload;
        default:
            return state;
    }
};
export default allocationsReducer