import React, { useState } from 'react'
import DashboardHeader from './DashboardHeader'
import { Outlet } from 'react-router-dom'
import DashboardSidebar from './DashboardSidebar'
import Backdrop from '../../components/common/Backdrop'
import Breadcrumbs from '../../components/common/Breadcrumbs'

const DashboardMaster = ({ children }) => {
    const [toogleMiniSidebar, setToogleMiniSidebar] = useState(false);
    const [toogleResponsiveSidebar, setToogleResponsiveSidebar] = useState(false);
    return (
        <div className='dashboard-layout bg-backgroundV1 min-h-screen'>
            <DashboardHeader toogleResponsiveSidebar={toogleResponsiveSidebar} setToogleResponsiveSidebar={setToogleResponsiveSidebar} toogleMiniSidebar={toogleMiniSidebar} />
            <DashboardSidebar setToogleResponsiveSidebar={setToogleResponsiveSidebar} toogleResponsiveSidebar={toogleResponsiveSidebar} setToogleMiniSidebar={setToogleMiniSidebar} toogleMiniSidebar={toogleMiniSidebar} />
            <main className={`dashboard-layout-main transition-all duration-300 ${toogleMiniSidebar ? "lg:ps-[80px]" : "lg:ps-[260px]"} pt-[56px] min-h-[inherit]`}>
                <div className="dashboard-pages-wrapper">
                    <section className='p-2 md:px-5'>
                        <Breadcrumbs/>
                    </section>
                    {children}
                </div>
            </main>
            <Backdrop onClose={() => setToogleResponsiveSidebar(false)} show={toogleResponsiveSidebar} />
        </div>
    )
}

export default DashboardMaster 