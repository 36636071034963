import { Tokens, apiBaseURL, authActionType, toastifyType } from '../../../constants';
import { environment } from "../../../config/environment"
import { setLoading } from '../loadingAction';
import { addTost } from '../toastAction';
import apiConfig from '../../../config/apiConfig';

export const login = (user) => async (dispatch) => {
  try {

    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.ADMIN_LOGIN, user);
    localStorage.setItem(Tokens.ADMIN_TOKEN, (response.data.data.token));
    localStorage.setItem(Tokens.ADMIN, JSON.stringify(response.data.data.user));
    localStorage.setItem(Tokens.ADMIN_ROLE, (response.data.data.user.role));
    dispatch({ type: authActionType.LOGIN_ADMIN, payload: response.data.data });
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));

    // Redirect to a different page after successful login
    
    const userInfo = localStorage.getItem(Tokens.ADMIN);
    const UserData = JSON.parse(userInfo);

    if (UserData?.role === 'user') {
      window.location.href = environment.BASE_URL + "/";
    } else {
      window.location.href = environment.BASE_URL + "/admin";

    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};


export const logout = (navigate) => async (dispatch) => {
  // await apiConfig.post(apiBaseURL.USER_LOGIN).then((response) => {
  const response = await apiConfig.get(apiBaseURL.LOGOUT);
  localStorage.removeItem(Tokens.ADMIN_TOKEN)
  localStorage.removeItem(Tokens.ADMIN)
  localStorage.removeItem(Tokens.ADMIN_ROLE);

  dispatch({ type: authActionType.LOGOUT, payload: response.data.data });
  dispatch(setLoading(false))
  navigate('/login')
};
