import { sentryActionType } from "../../constants";


const SentryReducer = (state = {}, action) => {
    switch (action.type) {
        case sentryActionType.FETCH_SENTRYS:
            return {asset: action.payload.field, tData: action.payload.field_details, outlier_key: action.payload.outlier_key, dataset_title:  action.payload.dataset_title};
        // case sentryActionType.FETCH_SENTRY:
        //     return action.payload;
        // case sentryActionType.ADD_SENTRY:
        //     return action.payload;
        // case sentryActionType.UPDATE_SENTRY:
        //     return action.payload;
        // case sentryActionType.DELETE_SENTRY:
        //     return action.payload;
        default:
            return state;
    }
};
export default SentryReducer