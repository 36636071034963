import { viewsActionType } from '../../constants';

const CustomColumnsViewReducer = (state = [], action) => {
    switch (action.type) {
        case viewsActionType.CUSTOM_FIELD:
            return action.payload;
        default:
            return state;
    }
};
export default CustomColumnsViewReducer
