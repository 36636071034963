import React, { useContext, useEffect, useState } from 'react'
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header'
import { useSelector } from 'react-redux'
import { ByodContext } from '../BYOD'
import { useDispatch } from 'react-redux'
import { addManuallyData, fetchCustomFieldValue, fetchCustomFieldValues } from '../../../../store/actions/BYODAction'
import { dateConvert } from '../../../../shared/common/dateFormate'
import { DataTableWithCustomPagination } from '../../../../shared/common/table-components/tasks/components/data-table-without-pagination'
import { Filters } from '../../../../constants'
import PreviewModel from '../models/update/updatePreviewData/PreviewModel'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../../components/ui/dropdown'
import { DotsHorizontalIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons'
import { Button } from '../../../../components/ui/button'
const data = [
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
]


export const columns = [
    {
        accessorKey: "assetId",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Asset Id" />
        ),
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("assetId")}</div>
        ),
        enableSorting: true,
    },
    {
        accessorKey: "value",
        header: "Value",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("value")}</div>
        ),
    },
    {
        accessorKey: "startDate",
        header: "Start Date",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("startDate")}</div>
        ),
    },
    {
        accessorKey: "endDate",
        header: "End Date",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("endDate")}</div>
        ),
    },
    {
        accessorKey: "comment",
        header: "Comment",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("comment")}</div>
        ),
    },
    {
        accessorKey: "description",
        header: "Description",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("description")}</div>
        ),
    },
]

const PreviewContent = (props) => {
    const { keyName, isUpload, setIsUpload } = props;
    const { BYODDataValues } = useSelector(state => state)
    const content = useContext(ByodContext)
    const getField = content?.getField
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    // action 
    const [modalShow, setModalShow] = useState(false);
    const [fetchData, setFetchData] = useState(null);
    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)
    const getkeyName = getField.group === "Issuers" ? 'issuerId' : 'assetId'
    useEffect(() => {
        // debugger
        // if (keyName === "preview" && isUpload === true) {
        //     // dispatch(fetchCustomFieldValue(getField.id, currentPage, perPage, setIsLoading))
        //     dispatch(fetchCustomFieldValues(null, currentPage, perPage, setIsLoading))
        //     setIsUpload(true)
        // } else {
        setData([])
        setIsUpload(false)
        if (getField?.id && keyName === "preview") {
            dispatch(fetchCustomFieldValue(getField.id, currentPage, perPage, setIsLoading))
        }
        // }
    }, [currentPage, perPage, keyName, getField])


    useEffect(() => {
        setTotalRecords(BYODDataValues?.UPLOADVALUE?.totalRecords)
        setTotalPages(Math.ceil(BYODDataValues?.UPLOADVALUE?.totalRecords / perPage))
    }, [BYODDataValues?.UPLOADVALUE]);

    useEffect(() => {

        if (BYODDataValues?.UPLOADVALUE?.records?.length) {
            const prepareData = BYODDataValues?.UPLOADVALUE?.records && BYODDataValues?.UPLOADVALUE?.records?.length ?
                BYODDataValues?.UPLOADVALUE?.records.map((item, index) => {
                    return {
                        _id: item._id,
                        [getkeyName]: item[getkeyName] ? item[getkeyName] : '-',
                        endDate: item.endDate !== null ? dateConvert(item.endDate) : '-',
                        startDate: item.startDate !== null ? dateConvert(item.startDate) : '-',
                        value: item.value ? item.value : '-',
                        comment: item.comment ? item.comment : '-',
                        description: item.description ? item.description : '-',
                        issuerId: item.issuerId ? item.issuerId : '-',
                        assetId: item.assetId ? item.assetId : '-',
                        // issuerId: item.issuerId ? item.issuerId : '-'
                    }
                }) : []
            setData(prepareData)
        } else {
            setData([])
        }
    }, [BYODDataValues?.UPLOADVALUE])

    const handelDelete = (data) => {
        // dispatch(deleteClass(data.id));
    };

    const handelEdit = (data) => {
        setModalShow(true);
        setFetchData(data);
    };

    const columns = [
        {
            accessorKey: getkeyName,
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={getkeyName === 'assetId' ? "Asset Id" : "Issuer Id"} />
            ),
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue(getkeyName)}</div>
            ),
            enableSorting: true,
        },
        // {
        //     accessorKey: "issuerId",
        //     header: ({ column }) => (
        //         <DataTableColumnHeader column={column} title="Issuer Id" />
        //     ),
        //     cell: ({ row }) => (
        //         <div className="capitalize">{row.getValue("issuerId")}</div>
        //     ),
        //     enableSorting: true,
        // },
        {
            accessorKey: "value",
            header: "Value",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("value")}</div>
            ),
        },
        {
            accessorKey: "startDate",
            header: "Start Date",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("startDate")}</div>
            ),
        },
        {
            accessorKey: "endDate",
            header: "End Date",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("endDate")}</div>
            ),
        },
        {
            accessorKey: "comment",
            header: "Comment",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("comment")}</div>
            ),
        },
        {
            accessorKey: "description",
            header: "Description",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("description")}</div>
            ),
        },
        {
            accessorKey: "action",
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => handelEdit(row?.original)}><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Edit</DropdownMenuItem>

                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => handelDelete(row?.original)}><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /> Remove</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        },
    ]
    const handleSaveData = (data) => {
        let prepareData = {
            _id: fetchData._id,
            Value: data.value,
            StartDate: data.startDate,
            EndDate: data.endDate,
            Comment: data.comment,
            Description: data.description,
        }
        if (fetchData && fetchData?.assetId !== '-') {
            prepareData = { ...prepareData, AssetID: fetchData?.assetId }
        } else {
            prepareData = { ...prepareData, IssuerID: fetchData?.issuerId }
        }

        dispatch(addManuallyData(getField.id, prepareData, null, null, null)).then((res) => {
            if (res !== undefined) {
                setCurrentPage(Filters.PAGE)
                setPerPage(Filters.OBJ.perPage)
                setModalShow(false)
                dispatch(fetchCustomFieldValue(getField.id, 1, 10, setIsLoading))
            }
        })
    }

    return (
        <div className="">
            <DataTableWithCustomPagination data={data} columns={columns} isCollViewHide={true} filterName={'assetId'} isRight={true} loadData={isLoading}
                setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}
            />
            <PreviewModel
                show={modalShow}
                onHide={() => setModalShow(false)}
                fetchData={fetchData}
                handleSaveData={handleSaveData}
                getField={getField}
                noValidate
            />
        </div>
    )
}

export default PreviewContent