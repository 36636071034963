
import { apiBaseURL } from '../../../../constants';
import apiConfig from '../../../../config/apiConfig';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
const ReSearchNoteCustomSelect = (props) => {
    const { onReSearchNoteCustomSelect, isDisabled, formData } = props;
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hseMore, setHseMore] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isFirst, setIsFirst] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    // useEffect(() => {
    //     // setTimeout(() => {
    //         setHseMore(true)
    //         fetchData(searchVal);
    //     //   }, 300);
    // }, [formData?.asset, formData?.issuer]);

    useEffect(() => {
        if (searchVal !== '') {
            setPage(1);
            setHseMore(true);
            setData([]);
            fetchData(searchVal);
            setIsFirst(true)
        }
    }, [searchVal]);

    const fetchData = async () => {
        try {
            if (hseMore && !isDisabled) {
                setLoading(true);
                const response = await apiConfig.get(apiBaseURL.RESEARCH_NOTES + "/asset-issuer-custom-fields", {
                    params: {
                        assetId: (formData?.asset !== null && formData?.issuer === null) ? '' : formData?.asset?.value,
                        issuerId: (formData?.asset === null && formData?.issuer !== null) ? formData?.issuer?.value : '',
                        // page: page,
                        // perPage: 15,
                        name: searchVal
                    }
                });
                const options = response.data?.data?.records?.length >= 1
                    ? response.data?.data?.records?.map((item) => {
                        return { value: item._id, label: item.name, slug: item.description };
                    })
                    : []
                setData(options);
                // setData(prevData => [...prevData, ...options]);
                // setHseMore(false)
                // setHseMore(response.data?.data?.totalPage === 0 ? false : page === response.data?.data?.totalPage ? false : response.data?.data?.records?.length === 0 ? false : true)
                setPage(prevPage => prevPage + 1);
                // setPage(pageNum + 1); // Always increment page number when fetching more data
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching assets:", error);
        }

    };

    const handleSelectItem = (selectedOption, action) => {
        if (action.action === "select-option") {
            setSelectedItem(selectedOption);
            onReSearchNoteCustomSelect(selectedOption, null)

        }
        if (action.action === "remove-value") {
            setSelectedItem(selectedOption);
            onReSearchNoteCustomSelect(selectedOption, action.removedValue.value)
        }
        if (action.action === "clear") {
            setSelectedItem([]);
            onReSearchNoteCustomSelect([], null)
        }
        // setSelectedItem(item);
        // onReSearchNoteCustomSelect(item)
        // You can perform any other action when an item is selected
    };
    // handleScroll
    // h-[113px] overflow-auto
    return (
        <div className="custom-dropdown " onScroll={null}>
            <Select
                options={data}
                value={formData?.customFields?.length > 0 ? formData?.customFields : []}
                className="react_selector"
                onChange={(selectedOption, action) => handleSelectItem(selectedOption, action)}
                onInputChange={(value) => {
                    if (value === '' && isFirst) {
                        setPage(1);
                        setHseMore(true);
                        setData([]);
                        fetchData('');
                    } else {
                        setSearchVal(value)
                    }
                }}
                isDisabled={isDisabled}
                isMulti
                isLoading={loading}
                // onMenuScrollToBottom={() => {setHseMore(true); fetchData(searchVal)}}
                // onScroll={() => {setHseMore(true); fetchData(searchVal)}}
                // onMenuClose={() => {
                //     setPage(1);
                //     setHseMore(true);
                //     setData([]);
                // }}
                onMenuOpen={() => { setHseMore(true); fetchData(searchVal) }}
                placeholder={formData?.customFields?.length > 0 && formData?._id ? formData?.customFields[0]?.label : "Select a Custom Field"}
                styles={{
                    groupHeading: (base) => ({
                        color: "black",
                    }),
                    menuList: (base) => ({
                        fontSize: "0.75rem",
                        overflow: "auto",
                        maxHeight: "180px",
                        lineHeight: '1rem',
                        fontWeight: 500,
                        borderRadius: '5px',
                    }),
                    container: (base, { isSelected, isFocused }) => ({
                        ...base,
                        ":focus": {
                            fontSize: "0.75rem",
                        }
                    }),
                    option: (base, { isSelected, isFocused }) => ({
                        ...base,
                        cursor: "pointer",
                        borderRadius: '5px',
                        margin: "0px 5px",
                        backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
                        color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
                        ":hover": {
                            backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
                        },
                        ":focus": {
                            fontSize: "0.75rem",
                        }
                    }),
                }}
            />
        </div>
    );
};

export default ReSearchNoteCustomSelect;

