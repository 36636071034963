import { subTypeActionType } from '../../constants';

const subTypeReducer = (state = [], action) => {
    switch (action.type) {
        case subTypeActionType.FETCH_SUBTYPES:
            return action.payload;
        case subTypeActionType.ADD_SUBTYPES:
            return action.payload;
        case subTypeActionType.DELETE_SUBTYPES:
            return action.payload;
        case subTypeActionType.UPDATE_SUBTYPES:
            return action.payload;
        case subTypeActionType.FETCH_SUBTYPE:
            return action.payload;
        default:
            return state;
    }
};
export default subTypeReducer