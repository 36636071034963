import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { fetchPortfolioGroup } from '../../../store/actions/portfolioGroupAction';
import { fetchAllPortfolios, updatePortfolioFromGroup } from '../../../store/actions/portfolioAction';
import { dateConvert } from '../../../shared/common/dateFormate';
import { fetchStrategy } from '../../../store/actions/strategyAction';
import { addTost } from '../../../store/actions/toastAction';
import { toastifyType } from '../../../constants';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown.jsx';
import { Button } from '@/components/ui/button.jsx';
import { DotsHorizontalIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';
import DataTableColumnHeader from '../../../shared/common/table-components/tasks/components/data-table-column-header';
import { DataTableNew } from '../../../shared/common/table-components/tasks/components/data-table';
import IconAdd from '../../../components/svg/IconAdd';
import { Checkbox } from "@/components/ui/checkbox";
import CreatePortfolioModel from './models/CreatePortfolioModel';
import PortfolioGroupTabs from './portfolio-group-tabs/PortfolioGroupTabs';
import { fetchPortfolioGroupInvestedAssetColumn } from '../../../store/actions/portfolioscolumnAction';
import Card from '../../../components/common/Card';
import Breadcrumbs from '../../../components/common/Breadcrumbs';


const PortfolioGroup = () => {
    const { PortfolioGroup, PortfolioGroupDetails, PortfolioGroupColumnsViews } = useSelector((state) => state);
    const { slug } = useParams();
    const dispatch = useDispatch();
    const [getPortfolio, setGetPortfolio] = useState(null)
    const [modalShow, setModalShow] = useState(false);
    const [groupModalShow, setGroupModalShow] = useState(false);
    const [fetchData, setFetchData] = useState(null);
    const [fetchDataGroup, setFetchDataGroup] = useState(null);
    const [fetchId, setFetchId] = useState(null);
    const [allPortfolios, setAllPortfolios] = useState([]);
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedId, setSelectedId] = useState([]);


    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const [perPage, setPerPage] = useState(10); // Default per page value

    useEffect(() => {
        // fetchInvestedAsset
        dispatch(fetchStrategy('all'))
        // dispatch(fetchPortfolioGroups())

        // 6-5-24 new change
        dispatch(fetchAllPortfolios())
    }, [dispatch])

    const onChange = (filter) => {
        dispatch(fetchPortfolioGroup(slug, filter))
    }
    useEffect(() => {
        // 6-5-24 new change
        // dispatch(fetchPortfolioGroupInvestedAssetColumn('portfolioGroupInvestedAsset'))
    }, [])

    useEffect(() => {
        if (PortfolioGroupDetails) {
            // Remove undefined values and portfolio not a null
            const filteredArray = PortfolioGroupDetails.filter(item => (item !== undefined && item.portfolio !== null));
            setAllPortfolios(filteredArray);
        }
    }, [PortfolioGroupDetails])


    // delete portfolio
    const handelDelete = (data) => {
        // let payloadData = { categories: [] };
        // payloadData.categories.push(data.slug);
        const deleteData = {
            portfolioGroupMappingId: data.id,
            name: data.Portfolios_Name,
            portfolioGroupId: null,
        }
        dispatch(updatePortfolioFromGroup(data.data.portfolio.slug, deleteData, currentPage, data.data.portfolioGroup.slug));
    };

    // edit portfolio
    const handelEdit = (data) => {
        setModalShow(true);
        setFetchData(data.data);
        // setFetchId(data.id);
        setFetchId(data.data?.portfolio?.slug);
    };


    // Pagination functions
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

    const onPageChange = (item) => {
        if (item?.portfolioGroup?.slug && item?.portfolio?.slug) {
            navigate('/portfolio/' + item?.portfolioGroup?.slug + "/" + item?.portfolio?.slug)
            // navigate('/portfolio/' + item?.portfolio?.slug)
        } else {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'URL not found!' }))
        }
    }


    // all modal close 
    const modelClose = () => {
        setModalShow(false);
        setFetchData(null);
        setFetchId(null);
        setGroupModalShow(false);
        setFetchDataGroup(null);
    }

    // get portfolio and group by params navigate budget name
    const location = useLocation();
    const resultArray = location.pathname.split('/').filter(Boolean);
    const [group, setGroup] = useState(null)
    useEffect(() => {
        if (PortfolioGroup) {
            if (resultArray.length === 3) {
                const findGroup = PortfolioGroup.find((item) => item?.slug === resultArray[1])
                setGroup(findGroup)
            } else {
                const findGroup = PortfolioGroup.find((item) => item?.slug === resultArray[1])
                setGroup(findGroup)
            }
        }
    }, [resultArray])

    // on change check box value
    const onCheckBtn = (e, item, value, isAll) => {
        // old 
        if (!isAll) {
            setGetPortfolio(item.data)
            if (value) {
                setSelectedItems([...selectedItems, item.data]);
                setSelectedId([...selectedId, item.data.portfolio._id])
            } else {
                setSelectedItems(selectedItems.filter((selected) => selected._id !== item.data._id));
                setSelectedId(selectedId.filter((id) => id !== item.data.portfolio._id));
            }
        } else {
            if (value) {
                setGetPortfolio(item.find((items) => items.data)?.data)
                setSelectedItems(item.map((items) => items.data));
                setSelectedId(item.map((items) => items.data.portfolio._id))
            } else {
                setSelectedItems([]);
                setSelectedId([]);
            }
        }
        // new  13-5-24
        // if (!isAll) {
        //     setGetPortfolio(item.data)
        //     if (value) {
        //         setSelectedItems([item.data]);
        //         setSelectedId([item.data.portfolio._id])
        //     } else {
        //         setSelectedItems(selectedItems.filter((selected) => selected._id !== item.data._id));
        //         setSelectedId(selectedId.filter((id) => id !== item.data.portfolio._id));
        //     }
        // } else {
        //     if (value) {
        //         setGetPortfolio(item.find((items) => items.data)?.data)
        //         setSelectedItems(item.map((items) => items.data));
        //         setSelectedId(item.map((items) => items.data.portfolio._id))
        //     } else {
        //         setSelectedItems([]);
        //         setSelectedId([]);
        //     }
        // }

    }



    const data = Array.isArray(allPortfolios) && allPortfolios.length > 0 ? allPortfolios.map((item, index) => {
        return {
            id: item._id,
            data: item,
            indexNum: indexOfFirstItem + index + 1,
            Portfolios_Name: item?.portfolio && Object.keys(item?.portfolio).length !== 0 ? item?.portfolio.name : 'N/A',
            Portfolios_Created_Date: item?.portfolio && Object.keys(item?.portfolio).length !== 0 ? dateConvert(item?.portfolio.createdAt) : 'N/A',
        }
    }) : []


    // select all default 
    useEffect(() => {
        if (allPortfolios?.length) {
            setGetPortfolio(allPortfolios?.map((items) => items)[0])
            setSelectedItems(allPortfolios?.map((items) => items));
            setSelectedId(allPortfolios?.map((items) => items.portfolio._id))
        } else {
            setGetPortfolio(null)
            setSelectedItems([]);
            setSelectedId([])
        }
    }, [allPortfolios])

    // console.log("selectedItems=======", selectedItems)
    // console.log("selectedId==========", selectedId)
    // console.log("getPortfolio==========", getPortfolio)
    // console.log("data", data)

    const columns = [
        {
            id: "select",
            header: ({ table }) => (
                <>
                    <Checkbox
                        checked={selectedId?.length && selectedItems?.length ||
                            table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate")
                        }
                        disabled={true}
                        onCheckedChange={(value) => { table.toggleAllPageRowsSelected(!!value); onCheckBtn(null, table.options.data, value, true) }}
                        aria-label="Select all"
                        className="translate-y-[2px] border-black rounded-[4px]"
                    />
                </>
            ),
            cell: ({ row }) => (
                <Checkbox
                    // checked={row.getIsSelected()}
                    checked={selectedId.includes(row.original?.data?.portfolio?._id)}
                    // disabled={true}
                    onCheckedChange={(value) => { row.toggleSelected(!!value); onCheckBtn(null, row.original, value, false) }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "index",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.indexNum}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "Portfolios_Name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Portfolios Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center text-[#2487EE] hover:underline cursor-pointer' onClick={(e) => { onPageChange(row?.original?.data); }}>{row?.original?.Portfolios_Name}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "Portfolios_Created_Date",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Portfolios Created Date" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.Portfolios_Created_Date}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "actions",
            enableHiding: false,
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => handelEdit(row?.original)}> <span className='w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]'><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> </span> Edit Portfolio</DropdownMenuItem>
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => handelDelete(row?.original)}> <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /></span> Remove Portfolio From Portfolio Group</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        }
    ]

    const rightHeaderButtons = [
        {
            icon: <IconAdd className="w-4 h-4 text-white transition duration 200" />,
            text: 'Add Portfolio',
            onClick: () => {
                setModalShow(true);
                // 6-5-24 old change
                //  dispatch(fetchAllPortfolios()) 
            },
            variant: '',
            additionalClass: ""
        }

    ]

    return (
        <DashboardMaster>
            <section className='p-2 md:px-5'>
                <Card>
                    <DataTableNew data={data} columns={columns} onChange={onChange} isOldHeaderHide={true} isRightHeaderButtons={true} rightHeaderButtons={rightHeaderButtons} isRightNew={true} isRightSearch={true} filterName={'Portfolios_Name'} />
                </Card>
            </section>
            {selectedItems.length ? <PortfolioGroupTabs allPortfolios={allPortfolios} getPortfolio={getPortfolio} groupSelectedId={selectedId} PortfolioGroupColumnsViews={PortfolioGroupColumnsViews} selectedId={selectedId} /> : ''}
            <CreatePortfolioModel
                show={modalShow}
                onHide={() => modelClose()}
                fetchData={fetchData}
                fetchId={fetchId}
                PortfolioGroup={PortfolioGroup}
                noValidate
                currentPage={currentPage}
                slug={slug}
                group={group}
                setCurrentPage={setCurrentPage}
            />
        </DashboardMaster>
    )
}

export default PortfolioGroup
