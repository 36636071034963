import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { fetchTypes } from '../../../../store/actions/typeAction';
import { addSubTypes, updateSubTypes } from '../../../../store/actions/subTypeAction';
import { fetchAdminTypes } from '../../../../store/actions/admin/typeAction';
import { addAdminSubTypes, updateAdminSubTypes } from '../../../../store/actions/admin/subTypeAction';

const AdminSubTypeModel = (props) => {
    const { show, onHide, fetchData, fetchId } = props
    const dispatch = useDispatch()
    const { types } = useSelector(state => state)
    const [subTypes, setSubTypes] = useState({
        name: '',
        type: null
    })
    const [error, setError] = useState({});
    useEffect(() => {
        dispatch(fetchAdminTypes())
    }, [dispatch])

    useEffect(() => {
        if (show === false) {
            setSubTypes({
                name: '',
                type: null
            })
        }
    }, [show])

    useEffect(() => {
        if (fetchId !== null) {
            setSubTypes({
                name: fetchData && fetchData?.name,
                type: fetchData && fetchData?.type ? { value: fetchData?.data?.type?._id, label: fetchData?.data?.type?.name  } : null
            })
        }
    }, [fetchData])
  
    const typeOptions = types?.records && Array.isArray(types?.records) && types?.records?.map((item) => {
        return { value: item?._id, label: item?.name }
    })

    const handleSelect = (event) => {
        const typeOption = JSON.parse(event)
        setSubTypes({
                ...subTypes,
                type: typeOption,
            });
    
        setError({})
    }

    const onChangeHandel = (e) => {

        setSubTypes({ ...subTypes, name: e.target.value })
        setError({})
    }
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (subTypes.subTypeName === '') {
            errors['subType'] = 'Please enter Sub Type ';
            isValid = false;
        }
        if (subTypes.type === null) {
            errors['type'] = 'Please select Type';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };
    const onSave = () => {
        if (handleValidation()) {
            if (fetchId !== null) {
                dispatch(updateAdminSubTypes({
                    id: fetchId,
                    type: subTypes?.type.value,
                    name: subTypes?.name
                }))
            } else {
                dispatch(addAdminSubTypes({
                    type: subTypes?.type?.value,
                    name: subTypes?.name
                }))
            }
            onHide()
        }
    }

    return (
        <Dialog open={show} onOpenChange={onHide}>
            <DialogContent className="sm:max-w-[460px] gap-10">
                <DialogHeader>
                    <DialogTitle className="text-center">{fetchId && fetchData ? "Edit Sub Type" : "Add Sub Type"}</DialogTitle>
                </DialogHeader>
                <div className="dialog-body">
                    <div className="grid grid-cols-1 gap-5">
                        <div className="form-group">
                            <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                            Select Type:<span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Select
                                onValueChange={(e) => handleSelect(e)}
                                defaultValue={fetchId !== null ? subTypes.type : subTypes.type}
                            // value={fetchId !== null ? subTypes.type?.id ? subTypes.type?.id : "" : subTypes.type?.id}
                            >
                                <SelectTrigger className="w-full">
                                    <SelectValue
                                        className='text-textV1'
                                        placeholder="Select Type"
                                    >
                                        {fetchId !== null ? subTypes.type?.label : subTypes.type?.label ? subTypes.type?.label : "Select Type"}
                                        {/* {fetchId !== null? subTypes.categoryOption?.label ? subTypes.categoryOption?.label : "" : subTypes.categoryOption?.label} */}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {
                                            typeOptions && typeOptions.length > 0 ? typeOptions.map((item, index) => {
                                                return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                            }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                        }
                                    </SelectGroup>
                                </SelectContent>
                            </Select>

                            <div className={` ${error['type'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['type'] ? error['type'] : null}</div>
                        </div>
                        <div className="form-group">
                            <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                            Sub Type Name:<span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Input
                                className={`form-control shadow-none ${error['subType'] && 'is-invalid'}`}
                                id="name" type="text" name="subType" placeholder="Sub Type " onChange={(e) => onChangeHandel(e)} value={subTypes.name} />

                            <div className={`${error['subType'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['subType'] ? error['subClass'] : null}</div>
                        </div>
                    </div>
                </div>
                <DialogFooter className={"!justify-center !gap-2"}>
                    <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                    <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default AdminSubTypeModel