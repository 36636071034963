
import React, { useState, useEffect } from "react";
import { cn } from "../../lib/utils"
import { ChevronsLeft, ChevronsRight } from "lucide-react"
import DatePicker from "tailwind-datepicker-react"
import { CalendarIcon } from "@radix-ui/react-icons";

export function FromDatePicker(props) {
  const { onDateSelect, className, disabled, placeholderText, mainClassName, selectedFromDate, minDate, value, } = props;
  const [show, setShow] = useState(false)
  const [date, setDate] = useState()

  const options = {
    autoHide: true,
    todayBtn: false,
    clearBtn: false,
    maxDate: new Date("2030-01-01"),
    minDate: minDate || new Date("1600-01-01"), // Update minDate dynamically
    // disabledDates: (date) => {return selectedFromDate === null ? [] : date < selectedFromDate},
    disabledDates: [],
    theme: {
      background: "bg-backgroundV2 rounded-sm p-2 w-[216px] !shadow-md",
      todayBtn: "",
      clearBtn: "hidden",
      icons: "",
      text: "hello !rounded-[4px]  font-500 text-gray-700 !text-xs w-full h-6 flex items-center justify-center",
      disabledText: "text-gray-200 font-500 !rounded-[4px] !text-xs w-full h-6 flex items-center justify-center pointer-events-none line-through",
      input: "inline-flex items-center gap-2 whitespace-nowrap outline-none group/btn rounded-sm transition-colors disabled:pointer-events-none disabled:opacity-50 bg-transparent px-3 py-2 text-xs w-[160px] md:w-[100%] justify-start text-left font-normal border border-borderV1 hover:border-borderV1 hover:text-theme text-muted-foreground",
      inputIcon: "",
      selected: "bg-themeV2 hover:!bg-[#2487EE] !text-white",
    },
    icons: {
      prev: () => <span><ChevronsLeft className='w-4 h-4' /></span>,
      next: () => <span><ChevronsRight className='w-4 h-4' /></span>,
    },
    datepickerClassNames: "custome-datepicker",
    defaultDate: value  ? value : null,
    language: "en",
    weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: placeholderText || "Select Date",
    inputDateFormatProp: {
      day: "numeric",
      month: "numeric",
      year: "numeric"
    }
  }
  useEffect(() => {
    if(value === null){
      options.defaultDate = null;
      options.inputPlaceholderProp = "Select Date";
    }
  }, [value])
  useEffect(() => {
    // Update minDate dynamically when selectedFromDate changes
    if (selectedFromDate) {
      options.minDate = selectedFromDate;
    }
  }, [selectedFromDate]);
  const handleChange = (selectedDate) => {
    onDateSelect(selectedDate)
  }
  const handleClose = (state) => {
    setShow(state)
  }

  return (
    <div className={cn('relative main-date-picker-wrapper min-w-[160px]', mainClassName)}>
      <DatePicker options={options}  classNames={className ? className : ''} onChange={disabled ? null : handleChange} show={disabled ? false : show} setShow={handleClose} />
    </div>
  )
}
