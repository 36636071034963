import React from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import PortfolioTab from './portfolio-tabs/PortfolioTab'

const Portfolio = () => {
  return (
    <DashboardMaster>
      <PortfolioTab />
    </DashboardMaster>
  )
}

export default Portfolio
