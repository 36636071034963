import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchInvestedAsset } from "../../../../store/actions/portfolioTransactionAction";
import { fetchGroupAnalyticsData } from "../../../../store/actions/analyticsAction";
import { dateConvert } from "../../../../shared/common/dateFormate";
import {
  DotsHorizontalIcon,
  Pencil2Icon,
  PlusIcon,
} from "@radix-ui/react-icons";
import DataTableColumnHeader from "../../../../shared/common/table-components/tasks/components/data-table-column-header";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown.jsx";
import { Button } from "@/components/ui/button.jsx";
import { DataTableNew } from "../../../../shared/common/table-components/tasks/components/data-table";
import { Checkbox } from "@/components/ui/checkbox";
import PortfolioGroupSubTabs from "../portfolio-group-sub-tebs/PortfolioGroupSubTabs";
import { DataTableWithCustomPagination } from "../../../../shared/common/table-components/tasks/components/data-table-without-pagination";
import { Filters, toastifyType } from "../../../../constants";
import InvestAssetActionModel from "../../../../shared/common/portfolio/models/InvestAssetActionModel";
import AssetUpdateModel from "../../../../shared/common/portfolio/models/AssetUpdateModel";
import Card from "../../../../components/common/Card";
import Divider from "../../../../components/common/Divider";
import { addTost } from "../../../../store/actions/toastAction";
import { ThreeDots } from "react-loader-spinner";

const PortfolioGroupContent = (props) => {
  const {
    fetchAssetData,
    allAnalytics,
    updateId,
    onCheckBtn,
    groupSelectedId,
    setIsLoading,
    isLoading,
    selectedId,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const [assets, setAssets] = useState([]);
  const {
    PortfolioGroup,
    portfolios,
    investedAsset,
    updateState,
    PortfolioGroupColumnsViews,
  } = useSelector((state) => state);
  // const [isLoading, setIsLoading] = useState(false)
  const resultArray = location.pathname.split("/").filter(Boolean);
  const [group, setGroup] = useState(null);
  const [PortfolioName, setPortfolioName] = useState(null);
  const [portfolioGroupId, setPortfolioGroupId] = useState([]);
  const [codeName, setCodeName] = useState([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(Filters.PAGE);
  const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
  const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords);
  const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages);

  // useEffect(() => {
  //     if (groupSelectedId?.length || updateState) {
  //         dispatch(fetchInvestedAsset(setIsLoading, groupSelectedId, currentPage, perPage, 'portfolioGroupInvestedAsset'))
  //     }
  // }, [groupSelectedId, currentPage, updateState])

  // const onChange = (filter) => {
  //     if (groupSelectedId?.length || updateState) {
  //         dispatch(fetchInvestedAsset(setIsLoading, groupSelectedId, filter.page, filter.perPage, 'portfolioGroupInvestedAsset'))
  //     }
  // }

  useEffect(() => {
    if (groupSelectedId?.length || updateState) {
      setIsLoading(true);
      dispatch(
        fetchInvestedAsset(
          setIsLoading,
          groupSelectedId,
          currentPage,
          perPage,
          "portfolioGroupInvestedAsset"
        )
      );
    }
  }, [groupSelectedId, updateState, currentPage, perPage]);

  useEffect(() => {
    setTotalRecords(investedAsset.totalRecords);
    setTotalPages(Math.ceil(investedAsset.totalRecords / perPage));
  }, [investedAsset]);

  useEffect(() => {
    setAssets(investedAsset);
    // setTotalRecords(investedAsset.totalRecords)
  }, [investedAsset]);

  useEffect(() => {
    if (PortfolioGroupColumnsViews?.data) {
      setCodeName(
        PortfolioGroupColumnsViews?.data.map((item) => {
          return { code: item.code, name: item.name };
        })
      );
    }
  }, [PortfolioGroupColumnsViews?.data]);

  useEffect(() => {
    if (PortfolioGroup) {
      if (resultArray.length === 3) {
        const findGroup = PortfolioGroup.find(
          (item) => item?.slug === resultArray[1]
        );
        const findPortfolio = portfolios.find(
          (item) => item?.portfolio?.slug === resultArray[2]
        );
        setGroup(findGroup);
        setPortfolioName(findPortfolio);
      } else {
        const findPortfolio = portfolios.find(
          (item) => item?.portfolio?.slug === resultArray[0]
        );
        setGroup(null);
        setPortfolioName(findPortfolio);
      }
    }
  }, [resultArray]);

  useEffect(() => {
    if (PortfolioName?.portfolio?._id) {
      setPortfolioGroupId([PortfolioName?.portfolio?._id]);
    }
  }, [PortfolioName]);

  const [isShowModel, setIsShowModel] = useState(false);
  const [isAssetShowModel, setIsAssetShowModel] = useState(false);
  const [fetchInvestData, setFetchInvestData] = useState(null);
  const [fetchAssetDatas, setFetchAssetDatas] = useState(null);
  const showModal = (items) => {
    setIsShowModel(true);
    setFetchInvestData(items);
  };
  const showAssetModal = (items) => {
    setIsAssetShowModel(true);
    setFetchAssetDatas(items);
  };

  useEffect(() => {
    if (!isShowModel) {
      setFetchInvestData(null);
    }
  }, [isShowModel]);

  const onAdd = (row) => {
    showModal(row);
  };

  const onEdit = (row) => {
    showAssetModal(row);
  };

  const [clickTransactionsTable, setClickTransactionsTable] = useState(false);
  const [clickAnalyticTable, setClickAnalyticTable] = useState(false);
  // useEffect(() => {
  //     if (clickAnalyticTable && !_.isEmpty(fetchAssetData)) {
  //         setIsLoadingData(true)
  //         dispatch(fetchGroupAnalyticsData(fetchAssetData?._id, setIsLoadingData))
  //     }
  // }, [fetchAssetData, clickAnalyticTable])

  const data = groupSelectedId?.length
    ? assets !== null && codeName?.length
      ? assets?.records
        ? assets?.records.map((item) => {
          return {
            ...item,
            data: item,
            id: item?.assetId ? item?.assetId : "N/A",
            name: item?.assetName ? item?.assetName : "-",
            class: item?.class ? item?.class : "",
            subClass: item?.subClass ? item?.subClass : "",
            Strategy: item?.strategy ? item?.strategy : "N/A",
            description: item?.description ? item?.description : "",
            countryName: item?.country?.countryName
              ? item?.country?.countryName
              : "",
            adjClose: item?.adjClose ?? 0,
            purchasePrice: item?.averagePurchasePrice
              ? item?.averagePurchasePrice?.toFixed(2)
              : "",
            purchaseDate: item?.purchaseDate
              ? dateConvert(item?.purchaseDate)
              : "",
            quantity: item?.totalQuantity
              ? item?.totalQuantity?.toFixed(2)
              : "",
            Value:
              item?.averagePurchasePrice * item?.totalQuantity
                ? (item?.averagePurchasePrice * item?.totalQuantity)?.toFixed(
                  2
                )
                : "",
          };
        })
        : []
      : []
    : [];
  // Function to map myCodeName array to column objects
  const mapCodeNameToColumns = () => {
    return [
      {
        id: "select",
        header: ({ table }) => <></>,
        cell: ({ row }) => (
          <Checkbox
            checked={row.original?.data?._id === fetchAssetData?._id}
            onCheckedChange={(value) => {
              row.toggleSelected(!!value);
              handleRowSelected(row?.original);
            }}
            aria-label="Select row"
            className="translate-y-[2px] border-black rounded-[4px]"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      ...codeName.map((code) => {
        if (code.code === "action") {
          return {
            id: "actions",
            enableHiding: false,
            header: (
              <span className="flex items-center justify-end">Action</span>
            ),
            cell: ({ row }) => {
              return (
                <div className="flex items-center justify-end">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" className="p-0">
                        <span className="sr-only">Open menu</span>
                        <DotsHorizontalIcon
                          strokeWidth={"2.2"}
                          className="h-5 w-5 text-textV2"
                        />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem
                        className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                        onClick={() => onAdd(row?.original)}
                      >
                        <PlusIcon
                          strokeWidth={"2.2"}
                          className="text-green-500 w-4 h-4"
                        />{" "}
                        Add Quantity
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`}
                        onClick={() => onEdit(row?.original)}
                      >
                        <Pencil2Icon
                          strokeWidth={"2.2"}
                          className="text-themeV2 w-4 h-4"
                        />{" "}
                        Edit Strategy
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              );
            },
          };
        } else {
          if (code.code === "adjClose") {
            return {
              accessorKey: code.name, // Ensure that the column ID matches 'code.name'
              header: ({ column }) => (
                // Header content for the regular data column
                <DataTableColumnHeader column={column} title={code.name} />
              ),
              cell: ({ row }) => {
                // console.log(row?.original?.adjClose !== "Fetching" && row?.original?.adjClose?.toFixed(2))
                return (
                  <div>
                    {row?.original?.adjClose === "Fetching" ? (
                      <>
                        <span className="text-themeV2 text-2xl font-600">
                          <ThreeDots
                            visible={true}
                            height="35"
                            width="35"
                            color="#001F3D"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </span>
                      </>
                    ) : (
                      <span className="">{row.original?.adjClose !== 0 ? row.original?.adjClose?.toFixed(2) : 0}</span>
                    )}
                  </div>
                )
              },
              enableSorting: true,
            };
          } else {
            return {
              accessorKey: code.name, // Ensure that the column ID matches 'code.name'
              header: ({ column }) => (
                // Header content for the regular data column
                <DataTableColumnHeader column={column} title={code.name} />
              ),
              cell: ({ row }) => (
                // Cell content for the regular data column
                <span className="flex items-center">
                  {typeof row?.original[code.code] === "number"
                    ? parseFloat(row.original[code.code])?.toFixed(2)
                    : row?.original[code.code] === 0
                      ? 0
                      : row?.original[code.code]
                        ? row.original[code.code]
                        : "-"}
                </span>
              ),
              enableSorting: true,
            };
          }
        }
      }),
    ];
  };

  const columns = mapCodeNameToColumns();

  const handleRowSelected = async (rows) => {
    // Handle row selection
    (await rows.data) && onCheckBtn(rows.data);
    // await rows.length && onCheckBtn(rows[0].data)
  };

  const [keyValue, setKeyValue] = useState("Transactions-maintenance");
  const handleTabChange = (value) => {
    if (_.isEmpty(fetchAssetData)) {
      dispatch(
        addTost({
          type: toastifyType.SHOW_ERROR_MESSAGE,
          message: "Please Select Asset!",
        })
      );
    } else {
      setKeyValue(value);
    }
  };

  return (
    <>
      <Card className="bg-white">
        <div className={`${fetchAssetData?._id ? "!pb-0" : ""} `}>
          <DataTableWithCustomPagination
            hasSettingBtn={true}
            loadData={isLoading}
            sectionType={"portfolioGroupInvestedAsset"}
            isGroup={true}
            data={data}
            columns={columns}
            filterName={"Name"}
            setPerPage={setPerPage}
            perPage={perPage}
            totalRecords={totalRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
        {fetchAssetData?._id ? (
          <>
            <Divider className={"my-4 border-borderV1"} />
            <PortfolioGroupSubTabs
              // AssetTransactionsTable
              clickTransactionsTable={null}
              fetchAssetData={fetchAssetData}
              groupSelectedId={groupSelectedId}
              isGroup={true}
              // AssetAnalytic , UnitedNations
              handleTabChange={handleTabChange}
              keyValue={keyValue}
              // any
              selectedId={selectedId}
              // reporting
              allAnalytics={allAnalytics}
              updateId={updateId}
            />{" "}
          </>
        ) : (
          ""
        )}
        <InvestAssetActionModel
          // portfolio
          show={isShowModel}
          onHide={() => setIsShowModel(false)}
          fetchInvestData={fetchInvestData}
          PortfolioName={PortfolioName}
          isInvestQuantity={true}
          fetchData={null}
          groupSelectedId={groupSelectedId}
          isGroup={true}
        />
        <AssetUpdateModel
          show={isAssetShowModel}
          onHide={() => setIsAssetShowModel(false)}
          fetchAssetDatas={fetchAssetDatas}
          PortfolioName={PortfolioName}
          isInvestQuantity={true}
        />
      </Card>
    </>
  );
};

export default PortfolioGroupContent;
