import React, { useEffect, useState } from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchFinancePrice } from '../../../store/actions/PricesAction'
import { Filters } from '../../../constants'
import DataTableColumnHeader from '../../../shared/common/table-components/tasks/components/data-table-column-header'
import { DataTableNew } from '../../../shared/common/table-components/tasks/components/data-table'
import { Card } from '../../../components/ui/Card'
import { ThreeDots } from 'react-loader-spinner'
const Prices = () => {
  const dispatch = useDispatch()
  const { financePrice, metaData } = useSelector(state => state)
  const [priceData, setPriceData] = useState([]);
  const navigate = useNavigate()

  // useEffect(() => {
  //     dispatch(fetchFinancePrice(currentPage, perPage));
  // }, [currentPage, perPage, dispatch]);

  const onChange = (filter) => {
    dispatch(fetchFinancePrice(filter.page, filter.perPage));
  }

  useEffect(() => {
    if (financePrice?.records?.length) {
      setPriceData(financePrice?.records);
    }
  }, [financePrice?.records]);

  useEffect(() => {
    if (financePrice.isCurrentPriceInUpdate) {
      const refreshTimeInMinutes = financePrice.refreshTime;
      const refreshTimeInSeconds = refreshTimeInMinutes * 1000;
      // Using setTimeout
      setTimeout(() => {
        // setTimeout(() => {
        // Your code to be executed after the refresh time
        dispatch(fetchFinancePrice(metaData.pageNumber, metaData.pageSize));
      }, refreshTimeInSeconds); // Multiply by 1000 to convert seconds to milliseconds

      // timeoutId()
      // Cleanup the timeout when the component unmounts
      // return () => clearTimeout(timeoutId);
    }
  }, [financePrice.isCurrentPriceInUpdate, priceData, dispatch]);

  const navigatePage = (item) => {
    // window.location.href = '/prices/' + item?.symbol
    navigate('/data/prices/' + item?.symbol)
  }


  const data = priceData && priceData.length ? priceData.map((data) => {
    return {
      id: data._id,
      Name: data.assetName,
      price: data.adjClose,
      type: data.type,
      subType: data.subType,
      symbol: data.symbol,
    }
  }) : []

  const columns = [
    {
      accessorKey: "Name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.Name}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Symbol",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Symbol" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center text-[#2487EE] hover:underline cursor-pointer' onClick={(e) => { navigatePage(row?.original); }} >{row?.original?.symbol ?? "-"}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Type",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Type" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.type}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Sub Type",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Sub Type" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.subType}</span>
      },
      enableSorting: true,

    },
    // {
    //   accessorKey: "Adj Close",
    //   header: ({ column }) => (
    //     <DataTableColumnHeader column={column} title="Adj Close" />
    //   ),
    //   cell: ({ row }) => {
    //     return <span className='flex items-center'>{row?.original?.price === 'Fetching' ? <ThreeDots
    //     visible={true}
    //     height="25"
    //     width="25"
    //     color="#001F3D"
    //     radius="9"
    //     ariaLabel="three-dots-loading"
    //     wrapperStyle={{}}
    //     wrapperClass=""
    //     /> : row?.original?.price}</span>
    //   },
    //   enableSorting: true,

    // },

  ];

  return (
    <DashboardMaster>
      <section className='p-2 md:px-5'>
        <Card className={'p-2 md:px-5 bg-white shadow-[rgba(99, 99, 99, 0.2) 0px 2px 8px 0px]'}>
          <DataTableNew data={data} columns={columns} onChange={onChange} filterName={'Name'} isCollViewHide={true} isSearchHide={true} />
        </Card>
      </section>
    </DashboardMaster >
  )
}

export default Prices
