import React from 'react'

const IconDrag = (props) => {
  return (
<svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M36.8716 21.9674L33.2678 25.9474C31.6216 27.7674 28.7991 25.2943 30.4878 23.4299L31.6122 22.1874H21.8747V31.6111L23.1172 30.4868C24.9522 28.8224 27.4747 31.6011 25.6347 33.2674L21.6566 36.8693C21.2004 37.275 20.6112 37.4993 20.0007 37.4997C19.3901 37.5 18.8007 37.2764 18.3441 36.8711L14.3647 33.2674C12.5222 31.5986 15.0484 28.8236 16.8822 30.4874L18.1247 31.6118V22.188H8.38719L9.51219 23.4299C11.2059 25.3011 8.36094 27.7486 6.73094 25.9474L3.12969 21.9693C2.72437 21.513 2.50035 20.924 2.5 20.3137C2.49965 19.7034 2.72301 19.1141 3.12781 18.6574L6.73156 14.6774C8.39406 12.8399 11.1772 15.3555 9.51156 17.1955L8.38719 18.4368H18.1247V8.38802L16.8822 9.5124C15.0478 11.1761 12.5241 8.39802 14.3647 6.73177L18.3428 3.1299C18.799 2.72415 19.3882 2.49986 19.9987 2.49951C20.6092 2.49917 21.1987 2.7228 21.6553 3.12802L25.6347 6.73177C27.5078 8.42802 24.9141 11.1411 23.1172 9.51177L21.8747 8.38802V18.4368H31.6122L30.4872 17.1955C28.8247 15.358 31.6034 12.8361 33.2684 14.6774L36.8697 18.6555C37.2752 19.1118 37.4993 19.701 37.4996 20.3114C37.5 20.9219 37.2765 21.5106 36.8716 21.9674Z" fill="currentcolor"/>
</svg>
  )
}

export default IconDrag