import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { components } from "react-select";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import Creatable from "react-select/creatable";
import { fetchPortfolioGroup } from "../../../../store/actions/portfolioGroupAction";
import { fetchUserAnalytics } from "../../../../store/actions/userAnalyticsAction";
import { FromDatePicker } from "../../../../components/ui/DatePickerNew";
import { chartTypeOld, chartType } from "../../../../static/static";
// import { FromDatePicker } from "../../../../components/ui/fromDatePicker";
// import { ToDatePicker } from "../../../../components/ui/toDatePicker";
const ReportingModel = (props) => {
  const { save, formData, setFormData, setPortfolioSlug, isReports } = props;
  const {
    assetsDetails,
    portfolios,
    userAnalytic,
    PortfolioGroup,
    PortfolioGroupDetails,
    allPortfolio,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [charts, setCharts] = useState("");
  const [chartsTypes, setChartsTypes] = useState(null);
  const [displayCharts, setDisplayCharts] = useState(null);
  const [startFormDate, setStartFormDate] = useState(null);
  const [startToDate, setStartToDate] = useState(null);
  const [checkbox1Checked, setCheckbox1Checked] = useState(true);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [allUserAnalytics, setAllUserAnalytics] = useState([]);
  const [unitedNations, setUnitedNations] = useState([]);
  const [worldBankId, setWorldBankId] = useState("");
  const [unitedNationId, setUnitedNationId] = useState("");
  const [error, setError] = useState({});
  const [allAnalytics, setAllAnalytics] = useState([]);
  const [portfolioGroupOption, setPortfolioGroupOption] = useState({
    value: null,
    label: "All",
  });
  const [fetchPortfolios, setFetchPortfolios] = useState(null);

  useEffect(() => {
    dispatch(fetchUserAnalytics());
  }, []);
  useEffect(() => {
    setAllAnalytics(userAnalytic);
  }, [userAnalytic]);
  const handleCheckbox1Change = () => {
    setCheckbox1Checked(!checkbox1Checked);
    setCheckbox2Checked(false); // Uncheck checkbox2 when checkbox1 is checked
    setFormData({ ...formData, isAutoChart: !checkbox1Checked });
    setError({});
  };

  const handleCheckbox2Change = () => {
    setCheckbox2Checked(!checkbox2Checked);
    setCheckbox1Checked(false); // Uncheck checkbox1 when checkbox2 is checked
    setFormData({ ...formData, isAutoChart: !checkbox1Checked });
    setError({});
  };
  useEffect(() => {
    if (props.show === false) {
      setStartFormDate(null);
      setStartToDate(null);
      setPortfolioGroupOption({ value: null, label: "All" });
      setFetchPortfolios(null);
      setError({});
    }
  }, [props.show]);

  useEffect(() => {
    portfolioGroupOption !== null &&
      portfolioGroupOption.value !== null &&
      dispatch(fetchPortfolioGroup(portfolioGroupOption?.slug));
  }, [portfolioGroupOption]);

  useEffect(() => {
    if (PortfolioGroupDetails) {
      const filteredArray = PortfolioGroupDetails.filter(
        (item) => item !== undefined && item.portfolio !== null
      );
      setFetchPortfolios(filteredArray);
    }
  }, [PortfolioGroupDetails]);

  useEffect(() => {
    if (allAnalytics?.records?.length) {
      const worldBank = Array.isArray(allAnalytics?.records)
        ? allAnalytics?.records?.find((item) => item.source === "World Bank")
        : null;
      const worldBankIndicators = worldBank ? worldBank.indicators : [];
      const getSourceId = worldBank ? worldBank.sourceId : "";
      setWorldBankId(getSourceId);
      setAllUserAnalytics(worldBankIndicators);
    }
  }, [allAnalytics]);

  useEffect(() => {
    if (allAnalytics?.records?.length) {
      const unitedNation = Array.isArray(allAnalytics?.records)
        ? allAnalytics?.records?.find((item) => item.source === "United Nations (SDGs)")
        : null;
      const unitedNationIndicators = unitedNation
        ? unitedNation.indicators
        : [];
      const getSourceId = unitedNation ? unitedNation.sourceId : "";
      setUnitedNationId(getSourceId);
      setUnitedNations(unitedNationIndicators);
    }
  }, [allAnalytics]);

  const indicatorOptions =
    allUserAnalytics && allUserAnalytics?.length >= 1
      ? allUserAnalytics?.map((item, index) => {
        return {
          value: item.analyticId,
          label: item?.indicatorName,
          uniqId: index,
          item: item,
          fixLabel: "World Bank",
        };
      })
      : [];

  const unitedNationsOptions =
    unitedNations && unitedNations?.length >= 1
      ? unitedNations?.map((item, index) => {
        return {
          value: item.series?._id || null,
          label: ` ${item?.description || ""} ${item.series?.description ? "|" : ""
            }  ${item.series?.description || ""} ${item?.dimension ? "(" + item?.dimension?.description + ")" : ""
            }`,
          uniqId: index,
          item: item,
          fixLabel: "United Nations (SDGs)",
        };
      })
      : [];

  const groupedOptions = [
    {
      label: "World Bank:",
      options: indicatorOptions,
    },
    {
      label: "United Nations (SDGs):",
      options: unitedNationsOptions,
    },
  ];

  const handleValidation = () => {
    let errors = {};
    let isValid = true;

    // if (isReports && portfolioGroupOption === null) {
    //     errors['portfolioGroup'] = 'Please select portfolio group';
    //     isValid = false;
    // }
    if (isReports && formData.portfolio === null) {
      errors["portfolio"] = "Please select a portfolio";
      isValid = false;
    }

    if (formData.indicators === null) {
      errors["analytic"] = "Please select an analytic";
      isValid = false;
    }

    if (isReports && assetOptions.length === 0 && formData.assets === null) {
      errors["asset"] = "Please select an asset";
      isValid = false;
    }

    if (formData.chartType === "") {
      errors["chartType"] = "Please select a chart type";
      isValid = false;
    }

    if (formData.from === "") {
      errors["from"] = "Please select a valid from date";
      isValid = false;
    }

    if (formData.to === "") {
      errors["to"] = "Please select a valid to date";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  const onClickSave = () => {
    let worldBankIndicators = [];
    let unitedNationsIndicators = [];
    let analytics = [];
    if (handleValidation()) {
      if (formData.indicators) {
        // Define an array to store matching objects
        const matchingObjects = [];
        // Iterate through the groupedOptions array
        for (const group of groupedOptions) {
          for (const option of group.options) {
            // Iterate through the formData.indicators array
            for (const indicator of formData.indicators) {
              // Check if the uniqId values match
              if (
                option.uniqId === indicator.uniqId &&
                option.fixLabel === indicator.fixLabel
              ) {
                // If they match, add both objects to the matchingObjects array
                matchingObjects.push(indicator);
              }
            }
          }
        }

        if (matchingObjects.length) {
          matchingObjects.map((item) => {
            if (item.fixLabel === "World Bank") {
              worldBankIndicators.push(item.item?.analyticId || null);
            }
          });
          matchingObjects.map((item) => {
            if (item.fixLabel === "United Nations (SDGs)") {
              unitedNationsIndicators.push({
                analyticId: item.item?.analyticId || null,
                seriesId: item.item?.series?._id || null,
                dimensionId: item.item?.dimension?._id || null,
              });
            }
          });
          const worldBankAnalytics = {
            sourceId: worldBankId,
            indicators: worldBankIndicators,
          };
          const unitedNationsAnalytics = {
            sourceId: unitedNationId,
            indicators: unitedNationsIndicators,
          };
          if (
            worldBankAnalytics.indicators.length &&
            !unitedNationsAnalytics.indicators.length
          ) {
            analytics.push(worldBankAnalytics);
          } else if (
            unitedNationsAnalytics.indicators.length &&
            !worldBankAnalytics.indicators.length
          ) {
            analytics.push(unitedNationsAnalytics);
          } else {
            analytics = [worldBankAnalytics, unitedNationsAnalytics];
          }
        }
      } else {
        allUserAnalytics.map((item) => {
          worldBankIndicators.push(item?.analyticId || null);
        });
        unitedNations.map((item) => {
          unitedNationsIndicators.push({
            analyticId: item?.analyticId || null,
            seriesId: item?.series?._id || null,
            dimensionId: item?.dimension?._id || null,
          });
        });
        const worldBankAnalytics = {
          sourceId: worldBankId,
          indicators: worldBankIndicators,
        };
        const unitedNationsAnalytics = {
          sourceId: unitedNationId,
          indicators: unitedNationsIndicators,
        };
        analytics = [worldBankAnalytics, unitedNationsAnalytics];
      }
      save(
        charts,
        setCheckbox1Checked,
        setCheckbox2Checked,
        analytics,
        assetOptions
      );
    }
  };

  const chartTypeOptions =
    chartType && chartType?.length >= 1
      ? chartType?.map((item) => {
        return { value: item.id, label: item.label };
      })
      : [];

  const chartTypeOldOptions =
    chartTypeOld && chartTypeOld?.length >= 1
      ? chartTypeOld?.map((item) => {
        return { value: item.id, label: item.label };
      })
      : [];

  const groupedChartOptions = [
    {
      label: "Chart Type:",
      options: isReports ? chartTypeOptions : chartTypeOldOptions,
    },
  ];

  const portfolioGroupOptions =
    PortfolioGroup && PortfolioGroup?.length >= 1
      ? [
        { value: null, label: "All" },
        ...PortfolioGroup?.map((item) => {
          return { value: item?._id, label: item?.name, slug: item?.slug };
        }),
      ]
      : [];

  // const PortfolioOptions = allPortfolio && allPortfolio?.length >= 1 ? allPortfolio?.map((item) => {
  //     return { value: item?.id, label: item?.name }
  // }) : []

  const portfolioOptionsData =
    fetchPortfolios !== null && fetchPortfolios?.length >= 1
      ? fetchPortfolios?.map((item) => {
        return {
          value: item?.portfolio?._id,
          label: item?.portfolio?.name,
          slug: item?.portfolio?.slug,
        };
      })
      : [];

  const portfolioOptions =
    (portfolioGroupOption === null || portfolioGroupOption.value === null)
      ? allPortfolio && allPortfolio?.records?.length >= 1
        ? allPortfolio?.records.map((item) => {
          return { value: item?.id, label: item?.name, slug: item?.slug };
        })
        : []
      : portfolioOptionsData.filter((item) => item !== null);
  // console.log('null==========>', portfolios, portfolioOptions, portfolioGroupOption)
  useEffect(() => {
    // if (portfolioOptions && portfolioOptions?.length) {
    //     setFormData({ ...formData, portfolio: portfolioOptions[0] })
    //     setPortfolioSlug(portfolioOptions[0])
    // } else {
    //     setFormData({ ...formData, portfolio: null })
    // }
    setFormData({ ...formData, portfolio: null });
  }, [portfolioGroupOption]);

  // useEffect(() => {
  //     if (portfolioOptions && portfolioOptions?.length) {
  //         setFormData({ ...formData, portfolio: portfolioOptions[0] })
  //         setPortfolioSlug(portfolioOptions[0])
  //     } else {
  //         setFormData({ ...formData, portfolio: null })
  //     }
  //     setFormData({ ...formData, portfolio: null })
  // }, [portfolioGroupOption])

  // // Use a Set to keep track of unique values
  // const uniqueValues = new Set();
  // // Filter the array to keep only unique objects based on the 'value' property
  // const portfolioOptions = portfolioGetOptions.filter(obj => {
  //     const isUnique = !uniqueValues.has(obj.value);
  //     uniqueValues.add(obj.value);
  //     return isUnique;
  // });

  const assetOptions =
    assetsDetails && assetsDetails?.records?.length >= 1
      ? assetsDetails?.records.map((item) => {
        return { value: item?.asset?._id, label: item?.asset?.assetName };
      })
      : [];
  const groupedAssetOptions = [
    {
      label: "",
      options: assetOptions,
    },
  ];
  useEffect(() => {
    if (portfolios && portfolios?.length) {
      // setFormData({ ...formData, portfolio: portfolioOptions[0], chartType: chartType[0].label })
      setFormData({ ...formData, chartType: chartType[0].label });
      setPortfolioSlug(portfolioOptions[0]);
      setChartsTypes(isReports ? chartTypeOptions[0] : chartTypeOldOptions[0]);
    }
  }, []);

  const onChangePortfolioGroupOptions = (event) => {
    const getObj = JSON.parse(event);
    setFormData({ ...formData, portfolio: null });
    setPortfolioGroupOption(getObj);
    setError({});
  };

  const onChangeIndicatorOption = (event) => {
    setFormData({ ...formData, indicators: event });
    setError({});
  };

  const onChangeAssetOption = (event) => {
    // const getObj = JSON.parse(event)
    // Otherwise, set the selected assets.

    setFormData({ ...formData, assets: event });
    setError({});
  };

  const onChangeChartOption = (event) => {
    const getObj = JSON.parse(event);
    setCharts(getObj.label);
    setFormData({ ...formData, chartType: getObj.label });
    setChartsTypes(getObj);
    setError({});
  };
  const onChangeDisplayChartOption = (event) => {
    // setFormData({ ...formData, chartType: event.label })
    setDisplayCharts(event);
    setError({});
  };
  const onChangePortfolioOption = (event) => {
    const getObj = JSON.parse(event);
    setFormData({ ...formData, portfolio: getObj });
    setError({});
  };

  const groupStyles = {
    color: "white",
    padding: "5px 5px",
    display: "flex",
    fontWeight: "700",
    fontSize: "15px",
  };

  const GroupHeading = (props) => (
    <div style={groupStyles}>
      <components.GroupHeading {...props} />
    </div>
  );

  const groupStyles2 = {
    color: "black",
    padding: "1px 2px",
    display: "flex",
  };

  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    cursor: "pointer",
    color: "#172B4D",
    display: "inline-block",
    margin: "2px",
    fontSize: 12,
    color: "black",
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "10px",
    textAlign: "center",
  };

  const [tableType, setTableType] = useState("horizontalTable");

  const onChangeTable = (value) => {
    setTableType(value);
    setFormData({ ...formData, tableType: value });
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles2}>
      <span
        style={groupBadgeStyles}
        className={`${tableType === "horizontalTable" && "bg-info text-white"
          } `}
        onClick={() => onChangeTable("horizontalTable")}
      >
        Horizontal Exposure Table
      </span>
      <span
        style={groupBadgeStyles}
        className={`${tableType === "verticalTable" && "bg-info text-white"} `}
        onClick={() => onChangeTable("verticalTable")}
      >
        Vertical Exposure Table
      </span>
    </div>
  );

  const handleFromDateChange = (date) => {
    setStartFormDate(date);
    // If the current "To" date is less than the selected "From" date, update it
    if (startToDate < date) {
      setStartToDate(date);
      setFormData({ ...formData, to: date });
    }
    setFormData({ ...formData, from: date });
  };
  const handleToDateChange = (date) => {
    setStartToDate(date);
    setFormData({ ...formData, to: date });
  };

  return (
    <>
      <Dialog open={props.show} onOpenChange={props.onHide} className="p-2">
        <DialogContent className="xl:max-w-[60%] sm:max-w-[70%] gap-6 z-50">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-center">
              {"Add Reporting Chart"}
            </DialogTitle>
          </DialogHeader>
          <div className="dialog-body">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              {isReports && (
                <div className="form-group grid grid-cols-12 items-center gap-1">
                  <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                    <Label
                      htmlFor="name"
                      className="text-[13px] text-textV2 font-500 cursor-pointer"
                    >
                      portfolio group:
                    </Label>
                  </div>
                  <div className="field-wrapper col-span-8">
                    <Select
                      onValueChange={(e) => onChangePortfolioGroupOptions(e)}
                      defaultValue={portfolioGroupOptions[0]}
                    >
                      <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                        <SelectValue
                          className="text-textV1"
                          placeholder="Select portfolio group"
                        >
                          {portfolioGroupOption?.label
                            ? portfolioGroupOption?.label
                            : "Select portfolio group"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {portfolioGroupOptions &&
                            portfolioGroupOptions.length > 0 ? (
                            portfolioGroupOptions.map((item, index) => {
                              return (
                                <SelectItem
                                  key={index}
                                  className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                                  value={JSON.stringify(item)}
                                >
                                  {item?.label}
                                </SelectItem>
                              );
                            })
                          ) : (
                            <SelectItem
                              disabled
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                              value={"No option"}
                            >
                              {"No option"}
                            </SelectItem>
                          )}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    {error["portfolioGroup"] && (
                      <div
                        className={` ${error["portfolioGroup"] ? "text-red" : ""
                          } px-2 py-1 text-sm`}
                      >
                        {error["portfolioGroup"]
                          ? error["portfolioGroup"]
                          : null}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {isReports && (
                <div className="form-group grid grid-cols-12 items-center gap-1">
                  <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                    <Label
                      htmlFor="name"
                      className="text-[13px] text-textV2 font-500 cursor-pointer"
                    >
                      portfolio:<span style={{ color: "red" }}>*</span>
                    </Label>
                  </div>
                  <div className="field-wrapper col-span-8">
                    <Select
                      onValueChange={(e) => onChangePortfolioOption(e)}
                      defaultValue={
                        formData.portfolio !== null ? formData.portfolio : null
                      }
                    >
                      <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                        <SelectValue
                          className="text-textV1"
                          placeholder="Select portfolio"
                        >
                          {formData.portfolio !== null
                            ? formData.portfolio.label
                            : "Select portfolio"}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {portfolioOptions && portfolioOptions.length > 0 ? (
                            portfolioOptions.map((item, index) => {
                              return (
                                <SelectItem
                                  key={index}
                                  className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                                  value={JSON.stringify(item)}
                                >
                                  {item?.label}
                                </SelectItem>
                              );
                            })
                          ) : (
                            <SelectItem
                              disabled
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                              value={"No option"}
                            >
                              {"No option"}
                            </SelectItem>
                          )}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                    <div
                      className={` ${error["portfolio"] ? "text-red" : ""
                        } px-2 py-1 text-sm`}
                    >
                      {error["portfolio"] ? error["portfolio"] : null}
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group grid grid-cols-12 items-center gap-1">
                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                  <Label
                    htmlFor="username"
                    className="text-[13px] text-textV2 font-500 cursor-pointer"
                  >
                    Select Analytic:<span style={{ color: "red" }}>*</span>
                  </Label>
                </div>
                <div className="field-wrapper col-span-8">
                  <Creatable
                    className={`form-select rounded-[12px]  ${error["analytic"] ? "is-invalid border-1" : "border-0"
                      }  p-0`}
                    classNamePrefix="select rounded-[12px]"
                    name="All"
                    value={formData.indicators}
                    isMulti
                    onChange={onChangeIndicatorOption}
                    options={groupedOptions}
                    components={{ GroupHeading }}
                    styles={{
                      groupHeading: (base) => ({
                        color: "black",
                      }),
                      menuList: (base) => ({
                        fontSize: "12px",
                        overflow: "auto",
                        maxHeight: "350px",
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: "pointer",
                      }),
                    }}
                  />
                  <div
                    className={` ${error["analytic"] ? "text-red" : ""
                      } px-2 py-1 text-sm`}
                  >
                    {error["analytic"] ? error["analytic"] : null}
                  </div>
                </div>
              </div>
              <div className="form-group grid grid-cols-12 items-center gap-1">
                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                  <Label
                    htmlFor="name"
                    className="text-[13px] text-textV2 font-500 cursor-pointer"
                  >
                    Display Type:<span style={{ color: "red" }}>*</span>
                  </Label>
                </div>
                <div className="field-wrapper col-span-8">
                  <Select
                    onValueChange={(e) => onChangeChartOption(e)}
                    defaultValue={chartTypeOptions[0]}
                  >
                    <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                      <SelectValue
                        className="text-textV1"
                        placeholder="Select Chart Type"
                      >
                        {chartsTypes !== null
                          ? chartsTypes.label
                          : "Select Chart Type"}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {chartTypeOptions && chartTypeOptions.length > 0 ? (
                          chartTypeOptions.map((item, index) => {
                            return (
                              <SelectItem
                                key={index}
                                className="py-2 px-3 hover:bg-hover_color cursor-pointer"
                                value={JSON.stringify(item)}
                              >
                                {item?.label}
                              </SelectItem>
                            );
                          })
                        ) : (
                          <SelectItem
                            disabled
                            className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                            value={"No option"}
                          >
                            {"No option"}
                          </SelectItem>
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <div
                    className={` ${error["chartsTypes"] ? "text-red" : ""
                      } px-2 py-1 text-sm`}
                  >
                    {error["chartsTypes"] ? error["chartsTypes"] : null}
                  </div>
                </div>
              </div>
              <div className="form-group grid grid-cols-12 items-center gap-1">
                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                  <Label
                    htmlFor="username"
                    className="text-[13px] text-textV2 font-500 cursor-pointer"
                  >
                    From:<span style={{ color: "red" }}>*</span>
                  </Label>
                </div>
                <div className="field-wrapper col-span-8">
                  <FromDatePicker onDateSelect={(date) => {
                    setFormData({ ...formData, from: date });
                    setStartFormDate(date);
                  }}
                    placeholderText='YYYY-MM-DD'
                    selectedFromDate={startFormDate}
                  />
                  {/* <FromDatePicker
                    className="!w-full rounded-[12px]"
                    placeholder="dd-mm-yyyy"
                    selected={startFormDate}
                    selectsStart
                    dateFormat={"yyyy-MM-dd"}
                    startDate={startFormDate}
                    endDate={startToDate}
                    isFrom={true}
                    onDateSelect={(date) => {
                      setFormData({ ...formData, from: date });
                      setStartFormDate(date);
                    }}
                  /> */}
                  <div
                    className={` ${error["from"] ? "text-red" : ""
                      } px-2 py-1 text-sm`}
                  >
                    {error["from"] ? error["from"] : null}
                  </div>
                </div>
                {/* <DatePicker onDateSelect={(date) => { setFormData({ ...formData, from: date }); setStartFormDate(date) }} className="!w-full" /> */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                                        <DatePicker
                                            label="From Year"
                                            views={["year"]}
                                            value={startFormDate}
                                            onChange={handleFromDateChange}
                                            placement="bottom-end"
                                        />
                                         
                                    </DemoContainer>
                                </LocalizationProvider> */}
              </div>
              <div className="form-group grid grid-cols-12 items-center gap-1">
                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                  <Label
                    htmlFor="username"
                    className="text-[13px] text-textV2 font-500 cursor-pointer"
                  >
                    To:<span style={{ color: "red" }}>*</span>
                  </Label>
                </div>
                <div className="field-wrapper col-span-8">
                  <FromDatePicker onDateSelect={(date) => {
                    setFormData({ ...formData, to: date });
                    setStartToDate(date);
                  }}
                    placeholderText='YYYY-MM-DD'
                    minDate={startFormDate}
                    selectedFromDate={startFormDate}
                  />
                  {/* <ToDatePicker
                    className="!w-full rounded-[12px]"
                    placeholder="dd-mm-yyyy"
                    dateFormat={"yyyy-MM-dd"}
                    selected={startToDate}
                    selectsEnd
                    disabled={startFormDate === null}
                    startDate={startFormDate}
                    endDate={startToDate}
                    minDate={startFormDate}
                    isTo={true}
                    onDateSelect={(date) => {
                      setFormData({ ...formData, to: date });
                      setStartToDate(date);
                    }}
                  /> */}
                  {/* <DatePicker onDateSelect={(date) => { setFormData({ ...formData, to: date }); setStartToDate(date) }} className="!w-full" /> */}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                                        <DatePicker
                                            label="To Year"
                                            views={["year"]}
                                            value={startToDate}
                                            onChange={handleToDateChange}
                                            minDate={startFormDate} // To year selection starts from selected From year
                                            placement="bottom-end"
                                        />
                                    </DemoContainer>
                                </LocalizationProvider> */}
                  <div
                    className={` ${error["to"] ? "text-red" : ""
                      } px-2 py-1 text-sm`}
                  >
                    {error["to"] ? error["to"] : null}
                  </div>
                </div>
              </div>
              {isReports && (
                <div className="form-group grid grid-cols-12 items-center gap-1">
                  <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                    <Label
                      htmlFor="name"
                      className="text-[13px] text-textV2 font-500 cursor-pointer"
                    >
                      Select Asset:
                      {/* <span style={{ color: 'red' }}>*</span> */}
                    </Label>
                  </div>
                  <div className="field-wrapper col-span-8">
                    <Creatable
                      className={`form-select rounded-sm  ${error["asset"] ? "is-invalid border-1" : "border-0"
                        }  p-0`}
                      classNamePrefix="select rounded-[12px]"
                      name="All"
                      value={formData.portfolio !== null ? formData.assets : null}
                      isMulti
                      onChange={onChangeAssetOption}
                      options={
                        formData.portfolio !== null
                          ? assetOptions && assetOptions.length
                            ? groupedAssetOptions
                            : []
                          : []
                      }
                      components={{ GroupHeading }}
                      styles={{
                        groupHeading: (base) => ({
                          color: "black",
                        }),
                        menuList: (base) => ({
                          fontSize: "12px",
                          overflow: "auto",
                          maxHeight: "350px",
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: "pointer",
                        }),
                      }}
                    />
                    {/* <Select
                                    onValueChange={(e) => onChangeAssetOption(e)}
                                    defaultValue={formData.portfolio !== null ? formData.assets : null}
                                >
                                    <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                                        <SelectValue
                                            className='text-textV1'
                                            placeholder="Select Asset"
                                        >
                                            {formData.portfolio !== null ? formData.assets?.label ? formData.assets?.label : "Select Asset" : "Select Asset"}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {
                                                formData.portfolio !== null ? assetOptions && assetOptions.length > 0 ? assetOptions.map((item, index) => {
                                                    return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem> : ''
                                            }
                                        </SelectGroup>
                                    </SelectContent>
                                </Select> */}
                    <div
                      className={` ${error["asset"] ? "text-red" : ""
                        } px-2 py-1 text-sm`}
                    >
                      {error["asset"] ? error["asset"] : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* {chartsTypes && chartsTypes.label !== 'Horizontal Exposure Table' && chartsTypes.label !== 'Vertical Exposure Table' &&
                            <div className='grid grid-cols-2 gap-5'>
                                <div className='form-group grid grid-cols-12 items-center gap-1 '>
                                    <Label className="form-label  text-start col-3" for="inputEmail3">Start Range:</Label>
                                    <div className='flex'>
                                        <div className="form-group grid grid-cols-12 items-center gap-1">
                                            <Checkbox id="terms" onCheckedChange={handleCheckbox1Change} checked={checkbox1Checked} />
                                            <label
                                                htmlFor="terms"
                                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ms-2"
                                            >
                                                Auto
                                            </label>
                                        </div>
                                        <div className="form-group grid grid-cols-12 items-center gap-1 ms-3">
                                            <Checkbox id="terms" onCheckedChange={handleCheckbox2Change} checked={checkbox2Checked} />
                                            <label
                                                htmlFor="terms"
                                                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ms-2"
                                            >
                                                Start From 0
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>} */}
          </div>
          <DialogFooter className={"!justify-center !gap-2 p-5"}>
            <Button
              type="submit"
              size="sm"
              className="min-w-[100px] h-[34px]"
              onClick={onClickSave}
            >
              Save
            </Button>
            <Button
              type="submit"
              size="sm"
              className="min-w-[100px] h-[34px]"
              variant={"outline"}
              onClick={props.onHide}
            >
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportingModel;
