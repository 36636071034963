
import { apiBaseURL, sentryActionType, toastifyType } from "../../constants";
import apiConfigSecond from '../../config/apiConfigSecond';
import { setLoading } from "./loadingAction";
import { addTost } from "./toastAction";


// export const addSentry = (payload) => async (dispatch) => {
//     dispatch(setLoading(true))
//     try {
//       const response = await apiConfigSecond.get(apiBaseURL.ANOMALY_DATA_ANALYSIS + `?id=${payload.id}&field_id=${payload.field_id}&start_date=${payload.start_date}&end_date=${payload.end_date}&group=${payload.group}&model=${payload.model}&dataset_key=${payload.dataset_key}&dataset_value=${payload.dataset_value}&display=${payload.display}`)
//       dispatch({ type: sentryActionType.FETCH_SENTRYS, payload: response.data });
//       dispatch(setLoading(false))
//     } catch (error) {
//       if(error?.response?.status == 500){
//         dispatch(setLoading(false))
//         dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Data Not Found.' }))
//       } else {
//         dispatch(setLoading(false))
//         dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
//       }
//     }
//   }

export const addSentry = (payload) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const response = await apiConfigSecond.post(apiBaseURL.ANOMALY_DATA_ANALYSIS, payload)
    dispatch({ type: sentryActionType.FETCH_SENTRYS, payload: response.data });
    dispatch(setLoading(false))
  } catch (error) {
    if(error?.response?.status == 500){
      dispatch(setLoading(false))
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Data Not Found.' }))
      dispatch({ type: sentryActionType.FETCH_SENTRYS, payload: [] })
    } else {
      dispatch(setLoading(false))
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
  }
}