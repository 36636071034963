import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { useLocation } from 'react-router-dom';
import { fetchInvestedAssetById } from '../../../../store/actions/portfolioTransactionHistoryAction';
import { dateConvert } from '../../dateFormate';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown.jsx';
import { Button } from '@/components/ui/button.jsx';
import { DotsHorizontalIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';
import DataTableColumnHeader from '../../table-components/tasks/components/data-table-column-header';
// import { DataTableNew } from '../../table-components/tasks/components/data-table';
import InvestAssetActionModel from '../models/InvestAssetActionModel';
import AssetDeleteModel from '../models/AssetDeleteModel';
import { DataTableWithCustomPagination } from '../../table-components/tasks/components/data-table-without-pagination';
import { Filters } from '../../../../constants';
import { Badge, generateBadgeVariant } from '../../../../components/ui/badge';

const AssetTransactionsTableContent = (props) => {
  const { clickTransactionsTable, fetchAssetData, groupSelectedId, isGroup, setIsLoadingMain, selectedId } = props;
  const { investedAssetHistory, portfolios, PortfolioGroup } = useSelector(state => state)
  const { id } = useParams();
  const [FetchInvestedAsset, setFetchInvestedAsset] = useState([])
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)

  // pagination
  const [currentPage, setCurrentPage] = useState(Filters.PAGE);
  const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
  const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
  const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)

  useEffect(() => {
    setFetchInvestedAsset([])
  }, [id])


  useEffect(() => {
    if (!_.isEmpty(fetchAssetData) ) {
      setFetchInvestedAsset([])
      setIsLoading(true)
      dispatch(fetchInvestedAssetById(setIsLoading, fetchAssetData?._id, {page: currentPage, perPage: perPage}))
    }
  }, [fetchAssetData, currentPage, perPage])

useEffect(() => {
  if(selectedId?.length === 0 && isGroup) {
    setFetchInvestedAsset([])
  }
}, [selectedId])

  useEffect(() => {
    setTotalRecords(investedAssetHistory?.totalRecords)
    setTotalPages(Math.ceil(investedAssetHistory?.totalRecords / perPage))
  }, [investedAssetHistory]);

  // const onChange = (filter) => {
    // if (!_.isEmpty(fetchAssetData)) {
    //   setFetchInvestedAsset([])
    //   setIsLoading(true)
    //   dispatch(fetchInvestedAssetById(setIsLoading, fetchAssetData?._id, filter))
    // }
  // }

  useEffect(() => {
    if (!_.isEmpty(fetchAssetData) && investedAssetHistory) {
      setFetchInvestedAsset(investedAssetHistory?.records)
      // setTotalRecords(investedAssetHistory?.totalRecords)
    }
  }, [investedAssetHistory])

  // Pagination functions
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;

  const onEdit = (row) => {
    showModal(fetchAssetData, row)
  }

  const onRemove = (row) => {
    setIsShowModelTow(true);
    setFetchInvestData(fetchAssetData);
    setFetchData(row)
  }

  const data = Array.isArray(FetchInvestedAsset) ? FetchInvestedAsset.map((item, index) => {
    return {
      index: indexOfFirstItem + index + 1,
      id: item._id ? item._id : 'N/A',
      date: item?.purchaseDate ? dateConvert(item?.purchaseDate) : item?.date ? dateConvert(item?.date) : 'N/A',
      linkedTransactions: item?.linkedLedgerIds?.length ? item?.linkedLedgerIds?.filter(item => item !== null).join(', ') : 'N/A' ? 'N/A' : 'N/A',
      price: item?.price ? parseFloat(item?.price)?.toFixed(2) : item?.price ? parseFloat(item?.price)?.toFixed(2) : 0,
      quantity: item?.quantity ? item?.quantity : item?.sellQuantity ? item?.sellQuantity : 0,
      ledgerId: item?.ledgerId ? item?.ledgerId : 'N/A',
      type: item?.type ? item?.type : 'N/A',
      Value: item?.quantity && item?.price ? parseFloat(item?.price * item?.quantity)?.toFixed(2) : 0,
    }
  }) : []

  const column = [
    {
      name: 'Id',
      selector: row => row.index,
      sortable: true,
    },
    {
      name: 'Purchase Date',
      selector: row => row.PurchaseDate,
      sortable: true,
    },
    {
      name: 'Purchase Price',
      selector: row => row.purchasePrice,
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: row => row.quantity,
      sortable: true,
    },
    {
      name: 'Value',
      selector: row => row.Value,
      sortable: true,
    },
    {
      name: 'Action',
      allowOverflow: true,
      selector: row => (<div className='ms-5'>
        {/* <PopoverDropDown isEdit={true} isRemove={true} onEdit={onEdit} onRemove={onRemove} row={row} removeName={"Remove Transaction"} editName={'Edit Transaction'} /> */}
      </div>),
      button: true,
    }
  ]

  const columns = [
    {
      accessorKey: "Id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Id" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.index}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "ledgerId",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ledger Id" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.ledgerId}</span>
      },
      enableSorting: true,

    },
    {
      // const variant = generateBadgeVariant(row.getValue("status"))
      // return <Badge variant={variant} className={"capitalize "}>{row.getValue("status")}</Badge>
      accessorKey: "type",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Type" />
      ),
      cell: ({ row }) => {
        const variant = generateBadgeVariant(row?.original?.type)
        return <Badge variant={variant} className={"capitalize "}>{row?.original?.type}</Badge>
      },
      enableSorting: true,

    },

    {
      accessorKey: "linkedTransactions",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Linked Ledger Ids" />
      ),
      cell: ({ row }) => {
        return <span title={row?.original?.linkedTransactions} className='line-clamp-1'>{row?.original?.linkedTransactions === '' ? 'N/A' : row?.original?.linkedTransactions }</span>
      },
      enableSorting: true,

    },
    
    {
      accessorKey: "date",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Date" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.date}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "price",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Price" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.price}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Quantity",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Quantity" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.quantity}</span>
      },
      enableSorting: true,

    }, {
      accessorKey: "Value",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Value" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.Value}</span>
      },
      enableSorting: true,

    },
    {
      id: "actions",
      enableHiding: false,
      header: <span className="flex items-center justify-end">Action</span>,
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => onEdit(row?.original)}><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Edit Transaction</DropdownMenuItem>

                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => onRemove(row?.original)}><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /> Remove Transaction</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    }
  ]

  const location = useLocation();
  const resultArray = location.pathname.split('/').filter(Boolean);
  const [PortfolioName, setPortfolioName] = useState(null)
  const [isShowModel, setIsShowModel] = useState(false)
  const [isShowModelTow, setIsShowModelTow] = useState(false)
  const [fetchInvestData, setFetchInvestData] = useState(null)
  const [fetchData, setFetchData] = useState(null)
  const showModal = (items, item) => {
    setIsShowModel(true)
    setFetchInvestData(items)
    setFetchData(item)
  }

  useEffect(() => {
    if (!isShowModel) {
      setFetchInvestData(null)
    }
  }, [isShowModel])

  useEffect(() => {
    if (PortfolioGroup) {
      if (resultArray.length === 3) {
        const findPortfolio = portfolios.find((item) => item?.portfolio?.slug === resultArray[2]);
        setPortfolioName(findPortfolio);
      } else {
        const findPortfolio = portfolios.find((item) => item?.portfolio?.slug === resultArray[0]);
        setPortfolioName(findPortfolio);
      }
    }
  }, [resultArray]);


  return (
    <div className='p-3 md:p-5 !pt-0'>
    <DataTableWithCustomPagination data={data} columns={columns} filterName={'PurchaseDate'} loadData={isLoading}
    setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}
    />
      <InvestAssetActionModel
        // portfolio 
        show={isShowModel}
        onHide={() => setIsShowModel(false)}
        fetchInvestData={fetchInvestData}
        PortfolioName={PortfolioName}
        isInvestQuantity={null}
        fetchData={fetchData}
        groupSelectedId={groupSelectedId}
        isGroup={isGroup}
        setIsLoading={setIsLoading}
      />
      <AssetDeleteModel
        show={isShowModelTow}
        onHide={() => setIsShowModelTow(false)}
        fetchInvestData={fetchInvestData}
        PortfolioName={PortfolioName}
        fetchData={fetchData}
        fetchAssetData={fetchAssetData} setIsLoading={setIsLoadingMain}
      />
    </div>
  )
}

export default AssetTransactionsTableContent