import React, { useState } from 'react';
import Papa from 'papaparse';
import html2canvas from 'html2canvas'
import DataTableColumnHeader from '../../table-components/tasks/components/data-table-column-header';
import Card from '@/components/common/Card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Button } from '@/components/ui/button'
import { Download, Trash, DownloadIcon } from 'lucide-react'
import { numberWithTowDecimal } from '../../numberFormate';

const HorizontalExposureTable = ({ item, deleteChart, handleDownloads, Index, isPortfolio }) => {
    const headers = item?.datasets[0];
    const tableData = item?.datasets.slice(1); // Table data rows

    const [downloading, setDownloading] = useState(false);
    const [progress, setProgress] = useState(0);


    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState(
        []
    )
    const [columnVisibility, setColumnVisibility] = useState({})
    const [rowSelection, setRowSelection] = useState({})


    const ans = tableData.map(row => {
        const obj = {};
        headers.forEach((header, index) => {
            if (header === "Asset") {
                obj[header] = row[index].assetName;
            } else {
                if (typeof row[index].value === 'string' && !isNaN(row[index].value)) {
                    obj[header] = numberWithTowDecimal(row[index].value);
                } else {
                    obj[header] = row[index].value;
                }
            }
        });
        return obj;
    });

    const data = ans.map((item) => {
        return {
            asset: item.Asset || 'N/A',
            ...Object.entries(item)
                .filter(([key]) => key !== 'Asset')
                .reduce((acc, [year, value]) => {
                    acc[year] = value || 0;
                    return acc;
                }, {}),
        };
    });

    //   const columns = [
    //     {
    //       accessorKey: 'asset',
    //       header: ({ column }) => (
    //         <DataTableColumnHeader column={column} title="Asset" />
    //       ),
    //       cell: ({ row }) => (
    //         <span className='flex items-center'>{row?.original?.asset}</span>
    //       ),
    //       enableSorting: true,
    //     },
    //     ...Object.keys(ans[0])
    //       .filter((key) => key !== 'Asset')
    //       .map((header) => ({
    //         accessorKey: header,
    //         header: ({ column }) => (
    //           <DataTableColumnHeader column={column} title={header} />
    //         ),
    //         cell: ({ row }) => (
    //           <span className='flex items-center'>{row?.original?.[header]}</span>
    //         ),
    //         enableSorting: true,
    //       })),
    //   ];

    const columns = [
        ...(isPortfolio
            ? []
            : [
                {
                    accessorKey: 'asset',
                    header: ({ column }) => (
                        <DataTableColumnHeader column={column} title="Asset" />
                    ),
                    cell: ({ row }) => (
                        <span className='flex items-center'>{row?.original?.asset}</span>
                    ),
                    enableSorting: true,
                },
            ]),
        ...Object.keys(ans[0])
            .filter((key) => key !== 'Asset')
            .map((header) => ({
                accessorKey: header,
                header: ({ column }) => (
                    <DataTableColumnHeader column={column} title={header} />
                ),
                cell: ({ row }) => (
                    <span className='flex items-center'>{row?.original?.[header]}</span>
                ),
                enableSorting: true,
            })),
    ];

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    })

    const downloadCSV = (item) => {
        setDownloading(true); // Start downloading
        setProgress(0); // Reset progress
        let csvFormant = []
        const headerData = []
        const rowData = []
        headers.map((item, index) => {
            headerData.push(item)
        })
        if (headerData.length) {
            csvFormant.push(headerData)
        }
        // rows.map((item, index) => {
        //     rowData.push(item?.assetName ? item?.assetName : item?.value && item?.value)

        // })
        const getData = tableData.map((rowData, rowIndex) => (
            rowData.map((cellData, cellIndex) => (cellIndex === 0 ? cellData.assetName : cellData.value))
        ))
        let update;
        if (getData.length) {
            update = [...csvFormant, ...getData]
            // csvFormant.push(rowData)
        }

        // Convert data to CSV format
        const csv = Papa.unparse(update);

        // Create a data URI
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvURL = window.URL.createObjectURL(csvData);

        // Simulate progress with a timeout
        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
        }, 500);

        // Create a download link
        const a = document.createElement('a');
        a.href = csvURL;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        clearInterval(interval);
        setDownloading(false); // Finish downloading
        setProgress(100); // Set progress to 100%
    };


    function generateSixDigitNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }

    const handleDownloadTableImage = async (Index) => {
        setDownloading(true); // Start downloading
        setProgress(0); // Reset progress

        const tableId = 'table_' + Index;
        const table = document.getElementById(tableId);

        if (!table) {
            console.error(`Table element with ID '${tableId}' not found.`);
            setDownloading(false); // Finish downloading
            setProgress(100); // Set progress to 100% to avoid blocking further downloads
            return;
        }

        try {
            // Use html2canvas to capture the table as an image
            const canvas = await html2canvas(table);

            // Create a download link
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            const randomNumber = generateSixDigitNumber();
            link.download = `${randomNumber}-table.png`;
            link.click();
        } catch (error) {
            console.error('Error capturing table as image:', error);
        }

        // Simulate progress with a timeout
        setTimeout(() => {
            setDownloading(false); // Finish downloading
            setProgress(100); // Set progress to 100%
        }, 500); // You can adjust the timeout as needed
    };



    return (
        <div className="table-content mb-6">
            <Card className={"border border-borderV1 p-3 md:p-3 mb-6"}>
                <div className="heading mb-4 flex items-center justify-between gap-4">
                    <div className="">
                        <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>{item?.portfolioName}</span></h5>
                        <p className='text-xs text-textV1 font-500'>Horizontal Exposure Table</p>
                    </div>
                    <div className="items-center gap-2 md:flex hidden relative">
                        <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => downloadCSV(item?.datasets)}>
                            <span><DownloadIcon className='w-4 h-4' /></span>
                        </Button>
                        {/* {true && (<Progress animated value={progress} className=' absolute z-[9] top-14 right-[10px] h-[5px] w-[75px]' />)} */}
                        <Button variant="outline" size="sm" className="w-8 h-8 p-0 !border-red hover:bg-[#F5F5F5]" onClick={() => deleteChart(item.chartId)} >
                            <span><Trash className='w-4 h-4 !text-red' /></span>
                        </Button>
                        {/* <Button variant="outline" size="sm" className="min-w-[100px]" onClick={() => downloadCSV(item?.datasets)}>
                        <span><Download className='w-4 h-4' /></span> Download
                    </Button>
                    {downloading && (
                        <div className="progress-container">
                            <Progress value={progress} label={`${progress}%`}/>
                        </div>
                    )}
                    <Button variant="outline" size="sm" className="min-w-[100px]" onClick={() => deleteChart(item.chartId)} >
                        <span><Trash className='w-4 h-4' /></span>  Delete
                    </Button> */}
                    </div>
                    <div className="items-center gap-2  md:hidden flex">
                        <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => downloadCSV(item?.datasets)}>
                            <span><Download className='w-4 h-4' /></span>
                        </Button>
                        <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => deleteChart(item.chartId)} >
                            <span><Trash className='w-4 h-4' /></span>
                        </Button>
                    </div>
                </div>
                <div className="table-wrapper ">
                    <div className="rounded-sm border  overflow-hidden">
                        <Table className="min-w-[1000px] isTable css-13aj3tc-MuiChartsSurface-root">
                            <TableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id} className="">
                                        {headerGroup.headers.map((header) => {
                                            return (
                                                <TableHead className="bg-backgroundV1" key={header.id}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                </TableHead>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {table.getRowModel().rows?.length ? (
                                    table.getRowModel().rows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            data-state={row.getIsSelected() && "selected"}
                                        >
                                            {row.getVisibleCells().map((cell) => (
                                                <TableCell className="text-xs" key={cell.id}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={columns.length}
                                            className="h-24 text-center"
                                        >
                                            No results.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default HorizontalExposureTable;


// const axiosServer = axios.create({
//     baseURL: wampServer,
// });

// // Add a request interceptor
// axiosServer.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

// // Add a response interceptor
// axiosServer.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     return Promise.reject(error);
//   });

// const actionFn = () => async (dispatch) => {
//     const response = axiosServer.get()
//     dispatch({type: 'All', payload: response})
// }

// const reducer = (state = {}, action) =>{
//     switch (key) {
//         case action.type:
            
//             return action.payload;
    
//         default:
//             return state;
//     }
// }

// const indexReducer = combineREDUCER({
//     data: reducer,
// })

// const store = createStore(indexReducer, applyMiddleware(thunk))

// const normal = () => {
//     <Provider store={store}>
//         hello
//     </Provider>
// }


/////////////////
// const data = [
//     [
//         "Asset",
//         "2010 - Access to electricity, rural (% of rural population)"
//     ],
//     [
//         "abc",
//         10
//     ],
//     [
//         "abcd",
//         100
//     ]
// ];
