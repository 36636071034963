import React from 'react'

const IconMaintanance = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2001_446)">
    <g clipPath="url(#clip1_2001_446)">
    <path d="M20 40C31.0286 40 40 31.0286 40 20C40 8.97142 31.0286 0 20 0C8.97142 0 0 8.97142 0 20C0 31.0286 8.97142 40 20 40ZM20 2.85714C29.4572 2.85714 37.1428 10.5429 37.1428 20C37.1428 29.4572 29.4572 37.1428 20 37.1428C10.5429 37.1428 2.85714 29.4572 2.85714 20C2.85714 10.5429 10.5429 2.85714 20 2.85714Z" fill="currentcolor"/>
    <path d="M11.4288 18.5718H28.5716C28.9506 18.5718 29.3138 18.4212 29.5818 18.1533C29.8498 17.8854 30.0002 17.5221 30.0002 17.1432C30.0002 16.7643 29.8498 16.4009 29.5818 16.133C29.3138 15.8651 28.9506 15.7146 28.5716 15.7146H14.0988L15.4745 13.6503C15.6848 13.3351 15.7612 12.9492 15.687 12.5777C15.6128 12.2061 15.394 11.8792 15.0788 11.6689C14.7636 11.4586 14.3777 11.3822 14.0061 11.4564C13.6345 11.5306 13.3076 11.7494 13.0974 12.0646L10.2402 16.3503C10.0967 16.5655 10.0142 16.8155 10.0017 17.0739C9.98914 17.3322 10.047 17.5891 10.169 17.8171C10.291 18.0452 10.4726 18.2358 10.6945 18.3687C10.9164 18.5016 11.1702 18.5718 11.4288 18.5718ZM11.4288 24.286H25.903L24.526 26.3504C24.4218 26.5064 24.3494 26.6814 24.313 26.8656C24.2766 27.0496 24.2766 27.239 24.3134 27.423C24.3502 27.607 24.4228 27.782 24.5272 27.9378C24.6316 28.0938 24.7656 28.2276 24.9216 28.3318C25.2368 28.542 25.6228 28.6184 25.9944 28.5442C26.366 28.47 26.6928 28.2512 26.903 27.936L29.7602 23.6504C29.9032 23.435 29.9852 23.185 29.9976 22.927C30.0098 22.6688 29.952 22.4122 29.83 22.1842C29.7082 21.9564 29.5268 21.7658 29.3052 21.6328C29.0836 21.4998 28.83 21.4294 28.5716 21.4288H11.4288C11.0499 21.4288 10.6865 21.5794 10.4186 21.8474C10.1507 22.1152 10.0002 22.4786 10.0002 22.8574C10.0002 23.2364 10.1507 23.5998 10.4186 23.8676C10.6865 24.1356 11.0499 24.286 11.4288 24.286Z" fill="currentcolor"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_2001_446">
    <rect width="40" height="40" fill="white"/>
    </clipPath>
    <clipPath id="clip1_2001_446">
    <rect width="40" height="40" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default IconMaintanance