
import { apiBaseURL, researchActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const getAllReSearchNotes = ({ page, perPage }) => async (dispatch) => {

    try {
        dispatch(setLoading(true));
        const response = await apiConfig.get(apiBaseURL.RESEARCH_NOTES + "?page=" + page + "&perPage=" + perPage ?? 10)
        dispatch({
            type: researchActionType.FETCH_RESEARCH_NOTES,
            payload: response.data?.data,
        });
        dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
        // return response?.data
    } catch (error) {

        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};
export const AddReSearchNotes = (payload) => async (dispatch) => {

    try {
        dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.RESEARCH_NOTES, payload).then((res) => {

            if (res?.data?.success) {
                dispatch({
                    type: researchActionType.ADD_RESEARCH_NOTES,
                    payload: res.data?.data,
                });
            }
        });

        dispatch(getAllReSearchNotes({ page: 1, perPage: 10 }))

        dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
        // return response?.data
    } catch (error) {

        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};
export const EditReSearchNotes = (payload) => async (dispatch) => {

    try {
        dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.RESEARCH_NOTES, payload).then((res) => {

            if (res?.data?.success) {
                dispatch({
                    type: researchActionType.EDIT_RESEARCH_NOTES,
                    payload: res.data?.data,
                });
            }
        });

        dispatch(getAllReSearchNotes({ page: 1, perPage: 10 }))
        dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
        // return response?.data
    } catch (error) {

        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};
export const RemoveReSearchNotes = (id, metaData) => async (dispatch) => {

    try {
        dispatch(setLoading(true));
        const response = await apiConfig.delete(apiBaseURL.RESEARCH_NOTES + "/" + id)
        dispatch({
            type: researchActionType.DELETE_RESEARCH_NOTES,
            payload: response.data,
        });
        metaData && dispatch(getAllReSearchNotes({ page: metaData?.pageNumber, perPage: metaData?.pageSize }))
        // dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
        // return response?.data
    } catch (error) {

        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};