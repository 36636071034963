import React, { createContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { Card, CardContent, CardFooter } from '@/components/ui/Card';
import { Button } from '@/components/ui/button';
import Loading, { IconLoading } from '../../../shared/common/Loading.js';
import DashboardMaster from '../../../layout/dashboard/DashboardMaster.jsx';
import { DataTableNew } from '../../../shared/common/table-components/tasks/components/data-table.jsx';
import IconAdd from '../../../components/svg/IconAdd.jsx';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../components/ui/dropdown.jsx';
import { DotsHorizontalIcon, Pencil2Icon } from '@radix-ui/react-icons';
import { Checkbox } from '../../../components/ui/checkbox.jsx';
import DataTableColumnHeader from '../../../shared/common/table-components/tasks/components/data-table-column-header.jsx';
import { X, TrashIcon, EyeIcon } from 'lucide-react';
import AddResearchNotesModal from './models/AddResearchNotesModal.jsx';
import { getAllReSearchNotes, RemoveReSearchNotes } from '../../../store/actions/ResearchNoteAction.js';
import Alert from '../../../components/common/Alert.jsx';
import { AddNoteContent, getOneReSearchNoteContent } from '../../../store/actions/researchNoteContentAction.js';
import CustomFieldTable from './customField/CustomFieldTable.jsx';
import CustomFieldTagName from './customField/CustomFieldTagName.jsx';
import { v4 as uuidv4 } from 'uuid';
import { removeFiles, uploadFiles } from '../../../store/actions/uploadFilesAction.js';
import { Label } from '../../../components/ui/label.jsx';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import Divider from '../../../components/common/Divider.jsx';
import ReSearchNoteCustomSelect from './selector/ReSearchCustomField.jsx';
import PreviewEditor from './previewContent/PreviewEditor.jsx';
import { formatCreatedAt } from '../../../shared/common/dateFormate.js';

export const editorContext = createContext(null)
export const tagContext = createContext(null)
const ResearchNotes = (props) => {
    const { isLoading } = props;
    const { researchNotes, metaData } = useSelector(state => state)
    const dispatch = useDispatch();
    const [defaultNote, setDefaultNote] = useState('')
    const [defaultTitle, setDefaultTitle] = useState('')
    const [liveUpdateData, setLiveUpdateData] = useState(false)
    const [updatedCustomData, setUpdatedCustomData] = useState(null)
    const [contentLoad, setContentLoad] = useState(false);
    const [deleteModel, setDeleteModel] = useState(false);
    const [deleteNoteId, setDeleteNoteId] = useState(null);
    const [editData, setEditData] = useState(null)
    const [notes, setNotes] = useState([])
    const [modalShow, setModalShow] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    // Update state to handle multiple files
    const [attachmentFiles, setAttachmentFiles] = useState([]);

    const [contentNote, setContentNote] = useState({
        title: '',
        note: '',
    })
    const [errors, setErrors] = useState({
        title: '',
        note: '',
    });

    useEffect(() => {
        // modalShow === false && setEditData(null)
    }, [modalShow])

    const handleTermsChange = (content) => {
        setContentNote(inputs => ({ ...inputs, note: content }))
    }
    const handleTitleChange = (content) => {
        setContentNote(inputs => ({ ...inputs, title: content }))
    }
    const onChange = (filters) => {
        dispatch(getAllReSearchNotes({ page: filters?.page, perPage: filters?.perPage }))
    }

    useEffect(() => {
        researchNotes?.records?.length > 0 && setNotes(researchNotes?.records)
    }, [researchNotes])


    const handleCheckboxChange = (rowId) => {
        setSelectedId(rowId);
    };

    // canvas editor handle 
    const [canvasData, setCanvasData] = useState([]);
    const [keyValue, setKeyValue] = useState('create');
    const [isChangeTab, setIsChangeTab] = useState(false)
    const [changeNotes, setChangeNotes] = useState('')


    const handleTabChange = (value) => {
        setKeyValue(value)
    }

    const defaultData = () => {
        setChangeNotes('')
        setIsChangeTab(false)
    }


    useEffect(() => {
        window.onload = () => {
            defaultData();
        };

        return () => {
            window.onload = () => {
                defaultData();
            };
        }
    }, []);


    useEffect(() => {
        if (selectedId === null && changeNotes === '') {
            setChangeNotes(selectedId);
        }
    }, [selectedId])


    useEffect(() => {
        // console.log('selectEd Id ================>', selectedId, '===========', changeNotes)
        if (selectedId === null && changeNotes === '') {
            setChangeNotes(selectedId);
        }
        if (selectedId !== null) {
            setKeyValue('create')
            if (canvasData?.length && (changeNotes !== '' && changeNotes !== null)) {
                const createPrepareData = {
                    // researchNoteId: changeNotes !== '' && changeNotes !== null ? changeNotes : selectedId,
                    researchNoteId: changeNotes,
                    structure: canvasData?.length ? JSON.stringify(canvasData) : ''
                }
                dispatch(AddNoteContent(createPrepareData, null)).then(() => {
                    setChangeNotes('')
                    setIsChangeTab(false)
                })
            }

        }
    }, [selectedId])

    useEffect(() => {
        // console.log('selectedId', selectedId)
        // console.log('selectedId',  changeNotes)
        // debugger
        if (!isChangeTab) {
            setIsChangeTab(true)
            setChangeNotes(selectedId)
        } else {
            if (selectedId) {
                const createPrepareData = {
                    // researchNoteId: changeNotes !== '' && changeNotes !== null ? changeNotes : selectedId,
                    researchNoteId: selectedId,
                    structure: canvasData?.length ? JSON.stringify(canvasData) : ''
                }
                if (canvasData?.length) {
                    dispatch(AddNoteContent(createPrepareData, null)).then((res) => {
                        if (res?.data?.data?.structure !== "" && keyValue === 'create') {
                            setCanvasData((prev) => {
                                return JSON.parse(res?.data?.data?.structure)
                            })
                            let updateData = {}
                            setEditData((prev) => {
                                updateData = {
                                    ...prev,
                                    structure: JSON.parse(res?.data?.data?.structure)
                                };
                                return updateData
                            })
                        }
                    })
                    setChangeNotes(selectedId)
                } else {
                    setChangeNotes(selectedId)
                    // isChangeTab && dispatch(AddNoteContent(createPrepareData, null))
                }
            }


        }
    }, [keyValue])
    // canvas editor handle end

    useEffect(() => {
        if (selectedId) {
            setContentLoad(true)
            dispatch(getOneReSearchNoteContent({ id: selectedId })).then((res) => {
                if (res?.data?.researchNote) {
                    setEditData({
                        ...editData,
                        tags: res?.data?.tags,
                        // customFields: res?.data?.customFields, 
                        contentId: res?.data?._id,
                        attachmentsData: res?.data?.attachments,
                        customFieldRecordData: res?.data?.customFieldRecordData,
                        content: res?.data?.content,
                        title: res?.data?.title,
                        customFields: res?.data?.customFields,
                        structure: res?.data?.structure ? JSON.parse(res?.data?.structure) : ''
                    })
                }
                if (res?.data?.structure !== '' && res?.data?.structure !== undefined) {
                    setCanvasData((prev) => {
                        return JSON.parse(res.data.structure)
                    })
                }
                if (res?.data?.customFieldRecordData) {
                    setUpdatedCustomData(res.data.customFieldRecordData)
                } else {
                    setUpdatedCustomData(null)
                }
                if (res?.data?.content) {
                    setDefaultNote(res.data.content)
                    setDefaultTitle(res.data.title)
                    setContentNote({
                        id: res.data.researchNote,
                        note: res.data.content,
                        title: res.data.title
                    })
                } else {
                    setContentNote({
                        note: ''
                    })
                }
                setContentLoad(false)
            })
        }

    }, [selectedId])

    const modules = {
        toolbar: [
            // [{ 'header': 1 }, { 'header': 2 }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            // [{ color: ['red', 'black', 'blue'] }, { background: ['red', 'black', 'blue'] }],
            // [{ script:  "sub" }, { script:  "super" }],
            // ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
    ];

    const onSubmit = (event) => {
        event.preventDefault();
        let support = (function () {
            if (!window.DOMParser) return false;
            let parser = new DOMParser();
            try {
                parser.parseFromString('x', 'text/html');
            } catch (err) {
                return false;
            }
            return true;
        })();
        let textToHTML = function (str) {
            if (support) {
                let parser = new DOMParser();
                let doc = parser.parseFromString(str, 'text/html');
                return doc.body.innerText
            }
        };
        const notes = textToHTML(contentNote.note)
        const titles = textToHTML(contentNote.title)
        // if (notes === "" || notes.trim().length === 0) {
        //     setErrors({ contentNote: "Please enter content note" })
        // } else if (titles === "" || titles.trim().length === 0) {
        //     setErrors({ title: "Please enter title" })
        // } else {
        if (!contentNote?.id) {
            const prepareData = {
                researchNoteId: selectedId,
                content: notes,
                title: titles,
            }
            setContentLoad(true)
            dispatch(AddNoteContent(prepareData, setContentLoad)).then((res) => {
                if (res?.data?.data) {
                    if (res.data?.data.researchNote) {
                        let updateData = {}
                        setEditData((prev) => {
                            updateData = {
                                ...prev,
                                contentId: res?.data?.data?._id,
                                content: res?.data?.data?.content,
                                title: res?.data?.data?.title,
                            };
                            return updateData
                        })
                    }
                }
            })
            setDefaultNote('')
        } else {
            const prepareData = {
                researchNoteId: contentNote?.id,
                content: contentNote.note,
                title: contentNote.title
            }
            setContentLoad(true)
            dispatch(AddNoteContent(prepareData, setContentLoad)).then((res) => {
                if (res?.data?.data) {
                    if (res.data?.data.researchNote) {
                        let updateData = {}
                        setEditData((prev) => {
                            updateData = {
                                ...prev,
                                contentId: res?.data?.data?._id,
                                content: res?.data?.data?.content,
                                title: res?.data?.data?.title,
                            };
                            return updateData
                        })
                    }
                }
            })
            setDefaultNote('')
        }
        // setSelectedId(null)
        setErrors({
            note: "",
            title: "",
        })
        setContentNote({
            note: contentNote.note,
            title: contentNote.title
        })
        setDefaultNote('')

        // }
    }

    const onEdit = (row) => {
        setModalShow(true);
        setSelectedId(null)
        setEditData(row)
    }

    const handleContinue = () => {
        dispatch(RemoveReSearchNotes(deleteNoteId, metaData))

    }

    const onRemove = (row) => {
        setDeleteModel(true)
        setDeleteNoteId(row?._id)
        setSelectedId(null)
        setEditData(null)
    }

    const handleCancel = (row) => {
        setDeleteModel(false)
    }

    const records = []
    const data = notes?.length ? notes?.map((item, index) => {

        return {
            ...item,
            id: index + 1,
            _id: item._id,
            description: item.description ? item.description : '',
            assetId: item?.asset?.assetId ? item?.asset?.assetId : '-',
            name: item.name ? item.name : '',
            issuerId: item.issuer?.issuerId ? item.issuer?.issuerId : '',
            tag: item?.tags?.length > 0 ? item?.tags : "-"
        }
    }) : [];



    // console.log(selectedId)

    const columns = [
        {
            id: 'select',
            header: ({ table }) => (
                // Header content for the 'select' column
                <>
                    {/* Add any header content if needed */}
                </>
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original._id === selectedId}
                    onCheckedChange={(value) => {
                        if (value) {
                            setErrors({
                                note: '',
                            })
                            handleCheckboxChange(row.original._id);
                            const { tags, contentId, attachmentsData, customFieldRecordData, content, customFields } = row.original;
                            setEditData({
                                ...row.original,
                                tags,
                                contentId,
                                attachmentsData,
                                customFieldRecordData,
                                content,
                                customFields
                            });
                        } else {
                            setErrors({
                                note: '',
                            })
                            setSelectedId(null);
                            setEditData(null)
                        }
                    }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "description",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Description" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.description}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "assetId",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Asset Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.assetId}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "issuerId",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Issuer Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.issuerId}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "tag",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Tag" />
            ),
            cell: ({ row }) => {
                return <span className={`flex items-center`} title={row?.original?.tag === "-" ? "-" : row?.original?.tag?.length > 3 ? `${row?.original?.tag?.join(",")}` : row?.original?.tag?.join(",")}>
                    
                        {row?.original?.tag === "-" ? "-" : row?.original?.tag?.length > 3 ? `${row?.original?.tag?.slice(0, 3)?.join(",")}...` : row?.original?.tag?.join(",")}
                </span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "createdAt",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Created At" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{formatCreatedAt(row?.original?.createdAt)}</span>
            },
            enableSorting: true,

        },

        {
            id: "actions",
            enableHiding: false,
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `}
                                    onClick={() => onEdit(row?.original)}
                                > <span className='w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]'><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> </span> Edit </DropdownMenuItem>
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `}
                                    onClick={() => onRemove(row?.original)}
                                > <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /></span> Remove </DropdownMenuItem>

                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        },
    ]

    const rightHeaderButtons = [
        {
            icon: <IconAdd className="w-4 h-4 " />,
            text: 'Add Research Notes',
            onClick: () => { setSelectedId(null); setEditData(null); setModalShow(!modalShow) },
            variant: '',
            additionalClass: ""
        },
        // Add more button configurations as needed
    ];



    const prepareData = (filesWithIds, editData, getFile) => {
        const formData = new FormData();
        if (editData?.contentId) {
            if (filesWithIds) {
                // Append any additional data
                filesWithIds.forEach((file, index) => {
                    formData.append("files", file.file);
                });
                formData.append('action', 'add')
            } else {
                formData.append('filename', getFile)
                formData.append('action', 'remove')
            }
            formData.append('id', editData?.contentId)
            formData.append('type', 'researchNoteContent')
        }
        return formData;
    };


    // Function to handle file change
    const handleFileChange = (event) => {
        const files = event.target.files;
        const filesWithIds = Array.from(files).map(file => ({
            id: uuidv4(),
            file: file
        }));
        setAttachmentFiles([...attachmentFiles, ...filesWithIds]);
        const inputElement = document.getElementById('attachmentInput');
        if (inputElement) {
            inputElement.value = '';
        }
        if (editData?.contentId) {
            dispatch(uploadFiles(prepareData(filesWithIds, editData, null))).then((res) => {
                if (res?.data?.data?.attachments) {
                    setEditData({ ...editData, attachmentsData: res.data.data.attachments })
                }
            });
        } else {
            const createPrepareData = {
                researchNoteId: editData?._id,
                content: ""
            }
            dispatch(AddNoteContent(createPrepareData, null)).then((res) => {
                if (res.data?._id || res.data?.data?._id) {
                    let updateData = {}
                    setEditData((prev) => {
                        updateData = { ...prev, contentId: res.data.data._id }
                        return updateData
                    })
                    dispatch(uploadFiles(prepareData(filesWithIds, updateData, null))).then((res) => {

                        if (res?.data?.data?.attachments) {
                            setEditData((prev) => {
                                updateData = { ...prev, ...updateData, attachmentsData: res.data.data.attachments }
                                return updateData
                            })
                        }
                    });
                }
            })
        }

    };


    // view document handel 
    const viewFile = (getFile) => {
        const token = localStorage.getItem('auth_token')
        window.open(process.env.REACT_APP_SERVER_URL + `/api/view-file?filename=${getFile}&type=researchNoteContent&authToken=${JSON.parse(token)}`, '_blank')
        // dispatch(viewDocumentFiles(getFile, 'researchNoteContent')).then((res) => {
        //     if (res?.data) {
        //         window.open("/view/file/" + getFile, '_blank');
        //     }
        // })
    }

    // remove document handel
    const removeFile = (getFile) => {

        const findsFilesWithIds = attachmentFiles.filter(file => file.id !== getFile.id)
        setAttachmentFiles(findsFilesWithIds);
        editData?.contentId && dispatch(removeFiles(prepareData(null, editData, getFile))).then((res) => {
            if (res.data.data.attachments) {
                let updateData = {}
                setEditData((prev) => {
                    updateData = { ...prev, attachmentsData: res.data.data.attachments }
                    return updateData
                })
            }
        });
    }



    // custom Fields handle 
    const [allCustomRecode, setAllCustomRecode] = useState([])
    const [formData, setFormData] = useState({
        customFields: null
    });
    const onReSearchNoteCustomSelect = (value, id) => {
        setFormData({ ...formData, customFields: value })
        // debugger
        let createPrepareData = id !== null ? {
            researchNoteId: editData?._id,
            customFields: value?.length ? value.map((item) => item.value) : [],
            customFieldRecordData: editData?.customFieldRecordData?.length && id !== null ? editData?.customFieldRecordData?.filter((item) => item?.customField._id !== id)?.map((item) => item._id) : []
            // customFieldRecordData: allCustomRecode.length && id !== null ? allCustomRecode.filter((item) => item?.id !== id)?.map((item) => item.id) : []

        } : {
            researchNoteId: editData?._id,
            customFields: value?.length ? value.map((item) => item.value) : []
        }
        dispatch(AddNoteContent(createPrepareData, null)).then((res) => {
            if (res?.data?.success || res?.success) {
                if (selectedId) {
                    dispatch(getOneReSearchNoteContent({ id: selectedId })).then((res) => {
                        if (res?.data?.researchNote) {
                            let updateData = {}
                            setEditData((prev) => {
                                updateData = {
                                    ...prev, tags: res?.data?.tags,
                                    // customFields: res?.data?.customFields, 
                                    contentId: res?.data?._id,
                                    attachmentsData: res?.data?.attachments,
                                    customFieldRecordData: res?.data?.customFieldRecordData,
                                    content: res?.data?.content,
                                    title: res?.data?.title,
                                    customFields: res?.data?.customFields,
                                };
                                return updateData
                            })
                        }
                        if (res?.data?.customFieldRecordData) {
                            setUpdatedCustomData((prev) => {
                                let updateData = prev !== null ? res.data.customFieldRecordData : res.data.customFieldRecordData
                                return updateData
                            })
                        } else {
                            setUpdatedCustomData(null)
                        }
                        if (res?.data?.content || res?.data?.title) {
                            setDefaultNote(res.data.content)
                            setDefaultTitle(res.data.title)
                            setContentNote({
                                id: res.data.researchNote,
                                note: res.data.content,
                                title: res.data.title
                            })
                        } else {
                            setContentNote({
                                note: '',
                                title: '',
                            })
                        }
                    })
                }
            }
        })



    }
    useEffect(() => {
        if (editData?._id) {
            if (editData?.customFields !== undefined) {
                setFormData({
                    customFields: editData?.customFields?.length > 0 && editData?.customFields?.map((cField) => {
                        return { label: cField.name, value: cField._id }
                    })
                })
            } else {
                setFormData({
                    customFields: null
                })
            }

        }
    }, [editData])
    // custom Fields handle end

    // console.log('editData]]]]]]]]]]]]]]]]]]', editData)

    // add and update tags
    const addUpdateTags = (prepareAddNoteData, etc) => {
        dispatch(AddNoteContent(prepareAddNoteData, null)).then((res) => {
            if (res?.data?.data?.tags) {
                let updateData = {}
                setEditData((prev) => {
                    updateData = { ...prev, tags: res?.data?.data?.tags }
                    return updateData
                })
            }
        })
    }

    // console.log('canvasData=============>', canvasData)

    return (
        <DashboardMaster>
            <section className='p-2  md:px-5'>
                <Card className="bg-white my-3 ">
                    <CardContent className="p-5">
                        <DataTableNew data={data} columns={columns} isCollViewHide={true} onChange={onChange} filterName={'name'} isRight={true}
                            isRightNew={true}
                            isOldHeaderHide={true}
                            isRightSearch={true}
                            isRightHeaderButtons={true}
                            rightHeaderButtons={rightHeaderButtons}

                        />
                    </CardContent>
                </Card>
                <AddResearchNotesModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    editData={editData}
                    setEditData={setEditData}
                />

                {editData?._id && selectedId && <Tabs defaultValue={keyValue} value={keyValue} onValueChange={handleTabChange} className="w-[full] mt-3">
                    <div className="mb-4">
                        <TabsList className="flex items-center rounded-[12px] w-fit bg-[#DEDEDE] p-1">
                            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="create">Add Content</TabsTrigger>
                            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="preview">Structure Content</TabsTrigger>
                        </TabsList>
                    </div>
                    <TabsContent value="create" >
                        <Card className="bg-white  ">
                            {selectedId && <Card className="bg-white rounded-br-none rounded-bl-none shadow-none border-0">
                                {contentLoad ? <CardContent className='pt-5 pb-2'><Loading /></CardContent> : <CardContent className='p-5'>
                                    <div className='col-12 terms-box terms-box-title'>
                                        {/* <label className='form-label'>Note:<span className="required" /> </label> */}
                                        <ReactQuill theme="snow" formats={formats} value={contentNote.title} placeholder='Add Title Here....' modules={modules} onChange={handleTitleChange} />
                                        <span
                                            className='text-red d-block fw-400 text-[10px] fs-small mt-2'>{errors['title'] ? errors['title'] : null}</span>
                                    </div>
                                    <div className='col-12 terms-box'>
                                        {/* <label className='form-label'>Note:<span className="required" /> </label> */}
                                        <ReactQuill theme="snow" formats={formats} value={contentNote.note} placeholder='Add Content Here....' modules={modules} onChange={handleTermsChange} />
                                        <span
                                            className='text-red d-block fw-400 text-[10px] fs-small mt-2'>{errors['contentNote'] ? errors['contentNote'] : null}</span>
                                    </div>
                                    <div className='flex flex-wrap align-middle items-center justify-start'>
                                        <div className="font-[sans-serif] max-w-md mt-2 relative w-[100%] me-3">
                                            <Label className="text-sm text-black mb-2 block">Attachment:</Label>
                                            {/* Modify input element to accept multiple files */}
                                            <input
                                                type="file"
                                                className="w-full text-black text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-black rounded"
                                                id="attachmentInput"
                                                // Add multiple attribute to accept multiple files
                                                multiple
                                                // Handle file change
                                                onChange={handleFileChange}
                                            />
                                            <p className="text-xs text-gray-400 mt-2">PNG, JPG, SVG, WEBP, and GIF are Allowed.</p>
                                        </div>
                                        {editData?.attachmentsData?.length ? editData?.attachmentsData.map((item, index) => {
                                            return (
                                                <div key={index} className='flex align-middle justify-between w-[13%] border border-blue-300 rounded px-1 py-2.5 mt-2.5 me-3 shadow-sm'>
                                                    <span className='line-clamp-1 text-sm text-theme'> {item?.slice(item?.indexOf('-') + 1) || item?.name?.slice(item?.name?.indexOf('-') + 1)}</span>
                                                    <div className='flex'>
                                                        <span className='cursor-pointer text-sm' onClick={() => viewFile(item)}><EyeIcon className='text-blue-500 w-5 h-5 text-sm' /></span>
                                                        <span className='cursor-pointer text-sm' onClick={() => removeFile(item)}><X className='text-red w-5 h-5 text-sm' /></span>
                                                    </div>
                                                </div>
                                            )
                                        }) : ''}
                                    </div>
                                    {/* old UI BOTTOM IN COMMIT  */}
                                    <div className='col-12 pt-sm-0 pt-3 '>
                                        <CardFooter className='ps-0 pb-0'>
                                            <Button type="submit" className="min-w-[100px] me-3" disabled={contentNote.note === defaultNote && contentNote.title === defaultTitle} onClick={(e) => onSubmit(e)} ><span>{isLoading ? <IconLoading height={15} width={15} /> : ""}</span>Save</Button>
                                            {/* <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={null}>Cancel</Button> */}
                                        </CardFooter>
                                    </div>
                                </CardContent>}
                            </Card>}
                            <Divider className="border-borderV1 my-3" />
                            {selectedId && <Card className="bg-white rounded-none shadow-none border-0 mt-3">
                                {contentLoad ? <CardContent className='p-3'><Loading /></CardContent> : <CardContent className='pt-2 pb-0 '>
                                    {editData?._id && <div className="form-group grid grid-cols-12 items-center gap-1">
                                        <div className="lable-wrapper  col-span-12">
                                            <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                                Custom Fields:<span style={{ color: 'red' }}>*</span>
                                            </Label>
                                        </div>
                                        <div className="field-wrapper col-span-12">
                                            {/* isDisabled={(!editData?.asset && !editData?.issuer)} */}
                                            <ReSearchNoteCustomSelect onReSearchNoteCustomSelect={onReSearchNoteCustomSelect} formData={{ ...formData, asset: { value: editData?.asset?._id }, issuer: { value: editData?.issuer?._id } }} />
                                            {/* <div className={error['customFields'] ? "text-red text-[10px]" : ''}>{error['customFields'] ? error['customFields'] : null}</div> */}
                                        </div>
                                    </div>}

                                    <CustomFieldTable editData={editData} contentLoad={contentLoad} setAllCustomRecode={setAllCustomRecode} updatedCustomData={updatedCustomData} setEditData={setEditData} setUpdatedCustomData={setUpdatedCustomData} />
                                </CardContent>}
                            </Card>
                            }
                            <Divider className="border-borderV1 my-3" />
                            {selectedId && <Card className="bg-white rounded-se-none rounded-ss-none shadow-none border-0 mt-3">
                                {contentLoad ? <CardContent className='p-3'><Loading /></CardContent> : <CardContent className='pt-2 pb-0 '>
                                    <tagContext.Provider value={{ addUpdateTags }}>
                                        <CustomFieldTagName editData={editData} />
                                    </tagContext.Provider>
                                </CardContent>}
                            </Card>}
                        </Card>
                    </TabsContent>
                    <TabsContent value="preview">
                        <editorContext.Provider value={{ keyValue, canvasData, setCanvasData, editData }}>
                            <PreviewEditor />
                        </editorContext.Provider>

                    </TabsContent>
                </Tabs>}
                <Alert title="Are you absolutely sure?" description="This action cannot be undone. This will permanently delete your Research Note data from our servers." handleClickContinue={handleContinue} handleClickCancel={handleCancel} showAlertDialog={deleteModel} setShowAlertDialog={setDeleteModel} />
            </section>
        </DashboardMaster>
    )

};


const mapStateToProps = (state) => {
    const { isLoading } = state;
    return { isLoading }
};


export default connect(mapStateToProps, {})(ResearchNotes);



// <div className="font-[sans-serif] max-w-md mt-2 relative">
//                             <Label className="text-sm text-black mb-2 block">Attachment:</Label>
//                             {/* Modify input element to accept multiple files */}
//                             <input
//                                 type="file"
//                                 className="w-full text-black text-sm bg-white border file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:bg-gray-100 file:hover:bg-gray-200 file:text-black rounded"
//                                 id="attachmentInput"
//                                 // Add multiple attribute to accept multiple files
//                                 multiple
//                                 // Handle file change
//                                 onChange={handleFileChange}
//                             />
//                             {/* {attachmentFiles.length ? <span className='absolute top-9 bottom-0 right-3 cursor-pointer' onClick={() => {
//                                 setAttachmentFiles([]);
//                                 // Reset the input element by setting its value to an empty string
//                                 const inputElement = document.getElementById('attachmentInput');
//                                 if (inputElement) {
//                                     inputElement.value = '';
//                                 }
//                             }}><X className='text-red' /></span> : ''} */}
//                             <p className="text-xs text-gray-400 mt-2">PNG, JPG, SVG, WEBP, and GIF are Allowed.</p>
//                             {/* {attachmentFiles.length ? attachmentFiles.map((item, index) => {
//                                 return (
//                                     <div key={index} className='flex justify-between'>
//                                         <span> {item?.file?.name || item?.name}</span><span className='cursor-pointer' onClick={() => removeFile(item)}><X className='text-red' /></span>
//                                     </div>
//                                 )
//                             }) : ''} */}
//                             {editData?.attachmentsData?.length ? editData?.attachmentsData.map((item, index) => {
//                                 return (
//                                     <div key={index} className='flex justify-between w-[65%]'>
//                                         <span className='line-clamp-1 text-sm text-theme'> {item?.slice(item?.indexOf('-') + 1) || item?.name?.slice(item?.name?.indexOf('-') + 1)}</span>
//                                         <div className='flex'>
//                                             <span className='cursor-pointer text-sm' onClick={() => viewFile(item)}><EyeIcon className='text-green-400 w-5 h-5 text-sm' /></span>
//                                             <span className='cursor-pointer text-sm' onClick={() => removeFile(item)}><X className='text-red w-5 h-5 text-sm' /></span>
//                                         </div>
//                                     </div>
//                                 )
//                             }) : ''}
//                         </div>