import React, { useContext } from 'react'
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import IconMaintanance from '../../../../components/svg/IconMaintanance';
import EnterDataContents from '../tabs-2/EnterDataContents';
import IconChart from '../../../../components/svg/IconChart';
import UploadCustomDataContent from '../tabs-2/UploadCustomDataContent';
import { ByodContext } from '../BYOD';

const BYODTabs = (props) => {
    const {getField, activeKey, setActiveKey} = useContext(ByodContext)

    const handleTabChange = (value) => {
        setActiveKey(value)
    }
    
    return (
        <div className="">
            <div className="sub-tabs-wrapper">
                <Tabs defaultValue={activeKey} value={activeKey} className="w-[full]" onValueChange={handleTabChange}>
                    {/* <Tabs defaultValue={'Asset Details'} className="w-[full]" > */}
                    <div className="mb-6">
                        <TabsList className="inline-flex items-center hidden-scroll rounded-none justify-start bg-[unset] shadow-none p-0 gap-8 overflow-y-auto border-b border-[#C9C9C9]">
                            {<TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Enter Data"><span><IconMaintanance className="w-5 h-5" /></span> Enter Data</TabsTrigger>}
                            {/* <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Upload Custom Data" ><span><IconChart className="w-5 h-5" /></span> Upload Custom Data</TabsTrigger> */}
                        </TabsList>
                    </div>
                    <TabsContent value="Enter Data" className="m-0">
                        <EnterDataContents />
                    </TabsContent>
                    <TabsContent value="Upload Custom Data">
                        {/* <UploadCustomDataContent/> */}
                    </TabsContent>
                </Tabs>
            </div>
        </div>
    )
}

export default BYODTabs