import axios from 'axios';
import axiosInterceptor from './axiosInterceptor';
import { environment } from './environment';

const analysisServerApi = `${environment.ANALYSIS_SERVER_URL}/api/`;

export const axiosApiSecond = axios.create({
    baseURL: analysisServerApi,
});


axiosInterceptor.setupInterceptors(axiosApiSecond, false, false);
export default axiosApiSecond;
