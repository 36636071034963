import React, { createContext, useEffect, useState } from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import ReportingMain from './ReportingMain';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchUserAnalytics } from '../../../store/actions/userAnalyticsAction';
import { fetchPortfolioGroups } from '../../../store/actions/portfolioGroupAction';
import { fetchAllPortfolios } from '../../../store/actions/portfolioAction';
import { addTost } from '../../../store/actions/toastAction';
import { toastifyType } from '../../../constants';
import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { Card } from '../../../components/ui/Card';

export const AssentContext = createContext(null);
const Reporting = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { isLoading, userAnalytic, portfolios } = useSelector(state => state)
  const [addAssetsModel, setAddAssetsModel] = useState(false)
  const [showAssetsRemoval, setShowAssetsRemoval] = useState(false)
  const [addAssetsAccordion, setAddAssetsAccordion] = useState(false)
  const [fetchAssetData, setFetchAssetData] = useState({})
  const [updateId, setUpdateId] = useState(null)
  const [allAnalytics, setAllAnalytics] = useState([])
  const [updateCss, setUpdateCss] = useState(false);
  useEffect(() => {
    dispatch(fetchUserAnalytics())
    // dispatch(fetchPortfoliosAll())
    dispatch(fetchPortfolioGroups())
    dispatch(fetchAllPortfolios())
  }, [])


  useEffect(() => {
    setUpdateCss(true)
  }, [])
  useEffect(() => {
    setFetchAssetData({})
    setUpdateId(null)
  }, [slug])

  useEffect(() => {
    setAllAnalytics(userAnalytic)
  }, [userAnalytic])

  const onShowAssets = () => {
    setAddAssetsModel(true)
  }

  const onHideAssets = () => {
    setAddAssetsModel(false)
  }

  const onShowAssetsAccordion = () => {
    setAddAssetsAccordion(!addAssetsAccordion)
  }

  const onShowAssetsRemovalAccordion = () => {
    if (_.isEmpty(fetchAssetData)) {
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
    } else {
      setShowAssetsRemoval(!showAssetsRemoval)
    }
  }

  const onCheckBtn = (e, data) => {
    setFetchAssetData(data)
    setUpdateId(data?.id)
  }
  return (
    <DashboardMaster>
      <AssentContext.Provider value={{ portfolios, onShowAssets, onShowAssetsAccordion, onCheckBtn, onShowAssetsRemovalAccordion, fetchAssetData, showAssetsRemoval, updateId, allAnalytics }}>
        <section className='p-2 md:px-5'>
          <Card className={'p-2 md:py-5 md:px-5 bg-white shadow-[rgba(99, 99, 99, 0.2) 0px 2px 8px 0px]'}>
            <ReportingMain />
          </Card>
        </section>
      </AssentContext.Provider>
    </DashboardMaster>
  )
}

export default Reporting