
import { apiBaseURL, tenorActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
// import apiConfigForIR from '../../config/apiConfigForIR'
import apiConfig from '../../config/apiConfig'
import { addTost } from "./toastAction";


export const getTenorByCurve = (curveId) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.FINANCE + "/tenors?curve="+curveId)
        dispatch({ type: tenorActionType.FETCH_TENOR, payload: response.data?.data });
        // dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage || 1, pageSize: 10, totalItems: response?.data?.data?.totalRecords }))
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}
