import React from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import Card from '../../../components/common/Card'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/ui/tabs'
import AllocationHistoryTableTable from '../../../components/common/AllocationHistoryTableTable'
import AllocationMainTableClonedUiRow from '../../../components/common/AllocationMainTableClonedUiRow'

const AllocationDetails = () => {
  return (
    <DashboardMaster>
      <section className='p-2 md:p-5 !pt-0'>
        <Tabs defaultValue="Asset Allocations" className="w-[full] mt-3">
          <div className="mb-4">
            <TabsList className="flex items-center rounded-[12px] w-fit bg-[#DEDEDE] p-1">
              <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Asset Allocations">Asset Allocations</TabsTrigger>
              <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Actions">Actions</TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="Asset Allocations">
            <AllocationMainTableClonedUiRow />
          </TabsContent>
          <TabsContent value="Actions" >
            <Card>
              <AllocationHistoryTableTable />
            </Card>
          </TabsContent>
        </Tabs>
      </section>
    </DashboardMaster>
  )
}

export default AllocationDetails