import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import AdminClassContent from "./setting-tabs-Contents/ClassContent"
import AdminSubClassContent from "./setting-tabs-Contents/SubClassContent"
import AdminTypeContent from "./setting-tabs-Contents/TypeContent"
import AdminSubTypeContent from "./setting-tabs-Contents/SubTypeContent"

const AdminSettingTabs = () => {
  return (
      <Tabs defaultValue="Class" className="w-[full]">
        <div className="px-3 md:px-5">
          <TabsList className="flex items-center rounded-[12px] w-fit bg-[#DEDEDE] p-1 max-w-[600px] w-full">
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Class">Class</TabsTrigger>
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Sub-Class">Sub Class</TabsTrigger>
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Type">Type</TabsTrigger>
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Sub-Type">Sub Type</TabsTrigger>
            {/* <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Strategy">Strategy</TabsTrigger> */}
          </TabsList>
        </div>
        <TabsContent value="Class">
          <AdminClassContent />
        </TabsContent>
        <TabsContent value="Sub-Class">
          <AdminSubClassContent />
        </TabsContent>
        <TabsContent value="Type">
          <AdminTypeContent />
        </TabsContent>
        <TabsContent value="Sub-Type">
          <AdminSubTypeContent />
        </TabsContent>
        {/* <TabsContent value="Strategy">
          <StrategyContent />
        </TabsContent> */}
      </Tabs>
  )
}


export default AdminSettingTabs