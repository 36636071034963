import React, { createContext, useEffect, useState } from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { deleteCustomField, fetchCustomFields, getCustomField } from '../../../store/actions/BYODAction'
import { Card, CardContent } from '@/components/ui/Card'
import DataTableColumnHeader from '../../../shared/common/table-components/tasks/components/data-table-column-header'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown.jsx';
import { Button } from '@/components/ui/button.jsx';
import { DotsHorizontalIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';
import IconAdd from '../../../components/svg/IconAdd'
import { Checkbox } from "@/components/ui/checkbox";
import { DataTableNew } from '../../../shared/common/table-components/tasks/components/data-table'
import Divider from '../../../components/common/Divider'
import CreateBYODModel from './models/create-model/createValue/CreateBYODModel'
import Alert from '../../../components/common/Alert'
import BYODTabs from './tabs/BYODTabs'
import UploadCustomDataContent from './tabs-2/UploadCustomDataContent'
import IconChart from '../../../components/svg/IconChart'
import UpdateBYODModel from './models/update/updateValue/UpdateBYODModel'
export const ByodContext = createContext(null);
const BYOD = () => {
  const { metaData, BYODData } = useSelector((state) => state);
  const dispatch = useDispatch()
  const [modalShow, setModalShow] = React.useState(false);
  const [records, setRecords] = useState([])
  const [editData, setEditData] = useState(null);
  const [deleteModel, setDeleteModel] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [activeKey, setActiveKey] = useState('Upload Custom Data')
  // useEffect(() => {
  //   dispatch(fetchCustomFields())a
  // }, [currentPage])
  const [getField, setGetField] = useState(null)
  const onChange = (filter) => {
    setGetField(null)
    dispatch(fetchCustomFields(filter))
  }

  useEffect(() => {
    if (getField !== null && BYODData?.records?.length) {
      const updateData = BYODData.records.find((item => item._id === getField._id))
      if(updateData){
        setGetField({...updateData, id: updateData._id})
      }
    }
    BYODData?.records && setRecords(BYODData.records)
  }, [BYODData?.records])

  useEffect(() => {
    if (!modalShow) {
      setEditData(null)
    }
  }, [modalShow])


  const onEdit = async (row) => {
    const data = await dispatch(getCustomField(row.id))
    setEditData(data.data)
    setModalShow(!modalShow)
  }
  const onRemove = (row) => {
    setDeleteModel(!deleteModel);
    setIsDelete(row);
  }
  const handleCancel = () => {
    setDeleteModel(false);
    setIsDelete(null);
  }

  const onClickUserRemove = () => {
    dispatch(deleteCustomField(isDelete?.id))
    setDeleteModel(false);
  };
  const handleContinue = () => {
    dispatch(deleteCustomField(isDelete?.id))
    dispatch(fetchCustomFields({ page: metaData.pageNumber, perPage: metaData.pageSize }))
    setDeleteModel(false);
    setIsDelete(null);
  }




  const handleRowSelected = async (rows) => {
    // Handle row selection
    await rows ? setGetField(rows) : setGetField(null)
  };


  const data = records.length ? records.map((item) => {
    return {
      id: item._id,
      _id: item._id,
      code: item.code ? item.code : '',
      group: item.group ? item.group : '-',
      Name: item.name ? item.name : '',
      description: item.description ? item.description : '',
      type: item.type ? item.type : '',
      subType: item.subType ? item.subType : ''
    }
  }) : []
  const columns = [
    {
      id: 'select',
      header: ({ table }) => (
        // Header content for the 'select' column
        <>
          {/* Add any header content if needed */}
        </>
      ),
      cell: ({ row }) => (
        // Cell content for the 'select' column
        <Checkbox
          checked={row.original?.id === getField?.id}
          onCheckedChange={(value) => { row.toggleSelected(!!value); handleRowSelected(row?.original); setActiveKey('Enter Data') }}
          aria-label="Select row"
          className="translate-y-[2px] border-black rounded-[4px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "Name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.Name}</span>
      },
      enableSorting: true,
    },
    {
      accessorKey: "group",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Group" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.group}</span>
      },
      enableSorting: true,
    },
    {
      accessorKey: "Code",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Code" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.code}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Description",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Description" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.description}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Type",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Type" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.type}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "subType",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="subType" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.subType ? row?.original?.subType : '-'}</span>
      },
      enableSorting: true,
    },
    {
      id: "actions",
      enableHiding: false,
      header: <span className="flex items-center justify-end">Action</span>,
      cell: ({ row }) => {
        return (
          <div className="flex items-center justify-end">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `} onClick={() => onEdit(row?.original)}> <span className='w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]'><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> </span> Edit Field</DropdownMenuItem>
                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `} onClick={() => onRemove(row?.original)}> <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /></span> Remove Field</DropdownMenuItem>

              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )
      },
    },
  ]

  const rightHeaderButtons = [
    {
      icon: <IconAdd className="w-4 h-4 " />,
      text: 'Define New Field',
      onClick: () => setModalShow(!modalShow),
      variant: '',
      additionalClass: ""
    },
    // Add more button configurations as needed
  ];





  return (
    <DashboardMaster>
      <section className='p-2 md:px-5'>
        <Card className='my-0 md:my-0 md:p-0 p-0 bg-white'>
          <div className="main-table-wrapper">
            <div className="">
              <div className={`p-3 md:p-5 ${getField?.id ? '' : ''} `}>
                <DataTableNew data={data} columns={columns} isCollViewHide={true} onChange={onChange} filterName={'Name'} isRight={true}

                  isRightNew={true}
                  isOldHeaderHide={true}
                  isRightSearch={true}
                  isRightHeaderButtons={true}
                  rightHeaderButtons={rightHeaderButtons}

                />
                {editData?._id ? <UpdateBYODModel
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  editData={editData}
                  setEditData={setEditData}
                /> : <CreateBYODModel
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  editData={editData}
                  setEditData={setEditData}
                />
                }
                <Alert title="Are you absolutely sure?" description="This action cannot be undone. This will permanently delete your BYOD data from our servers." handleClickContinue={handleContinue} handleClickCancel={handleCancel} showAlertDialog={deleteModel} setShowAlertDialog={setDeleteModel} />
              </div>
              <div className="sub-tabs-content-wrapper">
                {getField?.id ?
                  <div className="">
                    <Divider className={"border-borderV1"} />
                    <div className='p-3 md:p-5'>
                      <ByodContext.Provider value={{ getField: getField, activeKey, setActiveKey }}>
                        <BYODTabs />
                      </ByodContext.Provider>
                    </div>
                  </div>
                  : ''}
              </div>

            </div>
          </div>
        </Card>
        <div className='py-5'>
          <Card className="p-5 bg-white w-full">
            <span className="!bg-transparent pt-0 p-2 border-b  border-b-theme !text-theme font-600 data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 w-[190px]  data-[state=active]:before:start-0 flex  before:start-1/2  before:transition-all before:duration-300 gap-2" value="Upload Custom Data" ><span><IconChart className="w-5 h-5 text-theme font-600" /></span> Upload Custom Data</span>
            <div className='py-5'>
              <UploadCustomDataContent />
            </div>

          </Card>
        </div>
      </section>
    </DashboardMaster>
  )
}

export default BYOD
