import React, { useEffect, useState } from 'react'
import AllocationMonitoringTable from '../tables/AllocationMonitoringTable'
import { fetchAllocationColumn } from '../../../../store/actions/portfolioscolumnAction';
import { useDispatch } from 'react-redux';


const AllocationMonitoringContent = (props) => {
  const { PortfolioName, addAssetsAccordion, getPortfolio, groupSelectedId, isGroup } = props
  const dispatch = useDispatch();
  const [isClick, setIsClick] = useState(false)
  const [unAllocations, setUnAllocations] = useState([])
  useEffect(() => {
    if (isClick) {
      dispatch(fetchAllocationColumn('allocationMonitor'));
    }
  }, [dispatch])

  return (
    <>
      {isGroup ? <AllocationMonitoringTable PortfolioName={null} isClick={isClick} unAllocations={unAllocations} setUnAllocations={setUnAllocations} isGroupAssetMonitor={true} getPortfolio={getPortfolio} groupSelectedId={groupSelectedId} sectionType={'portfolioGroupAllocationMonitor'} isGroup={isGroup} /> :
        <AllocationMonitoringTable PortfolioName={PortfolioName} isClick={isClick} unAllocations={unAllocations} setUnAllocations={setUnAllocations} isGroupAssetMonitor={false} getPortfolio={null} groupSelectedId={null} sectionType={'allocationMonitor'} isGroup={isGroup}/>
      }
    </>
  )
}

export default AllocationMonitoringContent