import React from 'react'
import { cn } from '../../lib/utils'

const Card = ({children,className}) => {
  return (
    <div className={cn('bg-backgroundV2 p-3 md:p-5 rounded-sm',className)}>
         {children}
    </div>
  )
}

export default Card