
import { analyticsDataActionType, apiBaseURL, classActionType, indicatorActionType, indicatorSourceActionType, indicatorSourceCategoryActionType,seriesActionType,toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";


export const fetchIndicatorSource = (hideLoading) => async (dispatch) => {
    !hideLoading && dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.INDICATOR_SOURCE)
        dispatch({ type: indicatorSourceActionType.FETCH_INDICATOR_SOURCES, payload: response.data?.data });
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchIndicatorSourceCategories = (id) => async (dispatch) => {
    // dispatch(setLoading(true))
    try {
        // const response = await apiConfig.get(apiBaseURL.INDICATOR_SOURCE+'/'+id+'/categories')
        const response = await apiConfig.get(apiBaseURL.INDICATOR_SOURCE_CATEGORIES)
        dispatch({ type: indicatorSourceCategoryActionType.FETCH_INDICATOR_SOURCES_CATEGORIES, payload: response.data?.data});
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchIndicators = (id) => async (dispatch) => {
    // dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.INDICATOR_SOURCE_CATEGORIES+'/'+id+'/indicators')
        dispatch({ type: indicatorActionType.FETCH_INDICATORS, payload: response.data?.data?.indicators});
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const addCategorie  = (data) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.CATEGORIES, data)
        dispatch({ type: classActionType.ADD_CLASS, payload: response.data?.data });
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const fetchAnalyticsData = (ID, setIsLoadingData) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.USER_ANALYTICS + '/latest-value/' + ID);
        dispatch({ type: analyticsDataActionType.FETCH_ANALYTICS_DATAS, payload: response.data?.data });
        setIsLoadingData && setIsLoadingData(false)
        dispatch(setLoading(false))
    } catch (error) {
        setIsLoadingData && setIsLoadingData(false)
        dispatch(setLoading(false))
        if (error.response) {
            // If the error has a response from the API
            const errorMessage = error.response.data.message;
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: errorMessage }));
        } else {
            // If there's no specific error message in the response
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }));
        }
    }
}
export const fetchGroupAnalyticsData = (ID, setIsLoading, filter, analytic, setTotalRecords, setTotalPages, setPerPage, setCurrentPage) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.USER_ANALYTICS + '/latest-value/' + ID + `?source=${analytic}&page=${filter.page}&perPage=${filter.perPage}`);
        dispatch({ type: analyticsDataActionType.FETCH_ANALYTICS_DATAS, payload: response.data?.data?.records || response.data?.data });
        setTotalRecords && setTotalRecords(response.data?.data?.totalRecords)
        setTotalPages && setTotalPages(response.data?.data?.totalPage)
        setCurrentPage && setCurrentPage(response.data?.data?.currentPage)
        // dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: response.data?.data?.pageSize || 10, totalItems: response.data?.data?.totalRecords }))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        if (error.response) {
            // If the error has a response from the API
            const errorMessage = error.response.data.message;
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: errorMessage }));
            setIsLoading && setIsLoading(false)
        } else {
            // If there's no specific error message in the response
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }));
            setIsLoading && setIsLoading(false)
        }
    }
}

export const fetchGoals = () => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.UNITED_NATIONS+'/goals')
        dispatch({ type: indicatorSourceCategoryActionType.FETCH_INDICATOR_GOALS, payload: response.data?.data });
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const fetchTarget = (id) => async (dispatch) => {
    // dispatch(setLoading(true))

    try {
        const response = await apiConfig.get(apiBaseURL.UNITED_NATIONS+'/goals/'+id)
        dispatch({ type: indicatorSourceCategoryActionType.FETCH_INDICATOR_TARGET, payload: response.data?.data?.targets});
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const fetchTargetIndicators = (id) => async (dispatch) => {
    // dispatch(setLoading(true))

    try {
        const response = await apiConfig.get(apiBaseURL.UNITED_NATIONS+'/targets/'+id)
        dispatch({ type: indicatorSourceCategoryActionType.FETCH_TARGET_INDICATORS, payload: response.data?.data?.indicators});
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const fetchSeries = (id) => async (dispatch) => {
    // dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.UNITED_NATIONS+ '/indicators/'+ id)
        dispatch({ type: seriesActionType.FETCH_SERIES, payload: response.data?.data?.series});
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}