import * as React from "react"
import { cn } from "../../lib/utils"


const Input = React.forwardRef(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex py-2.5 px-4 h-[42px]  w-full rounded-sm border border-borderV1 text-textV2 bg-transparent text-sm  outline-none disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
