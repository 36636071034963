import React, { useState } from 'react';
import Papa from 'papaparse';
import html2canvas from 'html2canvas'
import DataTableColumnHeader from '../../table-components/tasks/components/data-table-column-header';
import Card from '@/components/common/Card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Button } from '@/components/ui/button'
import { Download, Trash, DownloadIcon } from 'lucide-react'
import { Progress } from "@/components/ui/progress"
import { numberWithTowDecimal } from '../../numberFormate';

const VerticalExposureTable = ({ item, deleteChart, handleDownloads, Index, isPortfolio }) => {
    const headers = item?.datasets[0];
    const tableData = item?.datasets.slice(1); // Table data rows
    const [downloading, setDownloading] = useState(false);
    const [progress, setProgress] = useState(0);

    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState(
        []
    )
    const [columnVisibility, setColumnVisibility] = useState({})
    const [rowSelection, setRowSelection] = useState({})

    const data = tableData.map((rowData, index) => {
        const dataObject = { index: index + 1 }; // Assuming index is unique for each row

        headers.forEach((header, columnIndex) => {
            if (header === "Analytic") {
                dataObject[header] = rowData[columnIndex]?.analyticName || ''; // Assuming "value" is the key for the cell data
            } else if (header === "Asset") {
                dataObject[header] = rowData[columnIndex]?.assetName || 'N/A'; // Assuming "value" is the key for the cell data
            } else {
                if (typeof rowData[columnIndex]?.value === 'string' && !isNaN(rowData[columnIndex]?.value)) {
                    dataObject[header] = numberWithTowDecimal(rowData[columnIndex]?.value);
                } else {
                    dataObject[header] = rowData[columnIndex]?.value;
                }
                // dataObject[header] = rowData[columnIndex]?.value || 0; // Assuming "value" is the key for the cell data
            }
        });
        return dataObject;
    });

    // const columns = headers.map((header) => ({
    //             accessorKey: header,
    //             header: ({ column }) => (
    //                 <DataTableColumnHeader column={column} title={header} />
    //             ),
    //             cell: ({ row }) => (
    //                 <span className='flex items-center'>{row?.original[header]}</span>
    //             ),
    //             enableSorting: true,
    //         }));
    const columns = headers
        .filter((header) => !(isPortfolio && header === "Asset")) // Exclude "Asset" column if isPortfolio is true
        .map((header) => ({
            accessorKey: header,
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title={header} />
            ),
            cell: ({ row }) => (
                <span className='flex items-center'>{row?.original[header]}</span>
            ),
            enableSorting: true,
        }));

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    })

    const downloadCSV = (data) => {
        setDownloading(true); // Start downloading
        setProgress(0); // Reset progress
        const csvFormat = [];
        const headerData = [];
        const rowData = [];
        const columnWidths = [];

        // Prepare header data and initialize column widths
        headers.forEach((header, index) => {
            headerData.push(header);
            columnWidths[index] = header.length;
        });

        if (headerData.length) {
            csvFormat.push(headerData);
        }

        // Calculate maximum column widths based on data content
        tableData.forEach((rowDatas) => {
            rowDatas.forEach((cellData, cellIndex) => {
                const content = cellIndex === 0 ? cellData.assetName !== null ? cellData.assetName : '' : cellIndex === 1 ? cellData.analyticName : cellData.value;
                if (content?.length > columnWidths[cellIndex]) {
                    columnWidths[cellIndex] = content.length;
                }
            });
        });

        // Prepare data rows with adjusted column widths
        tableData.forEach((rowDatas) => {
            rowData.push(
                rowDatas.map((cellData, cellIndex) => {
                    const content = cellIndex === 0 ? cellData.assetName : cellIndex === 1 ? cellData.analyticName : cellData.value;
                    // Adjust column width in CSV format by adding spaces
                    const paddedContent = content + ' '.repeat(columnWidths[cellIndex] - content?.length);
                    return paddedContent;
                })
            );
        });

        if (rowData.length) {
            for (let index = 0; index < rowData.length; index++) {
                csvFormat.push(rowData[index]);
            }
        }

        // Convert data to CSV format
        const csv = Papa.unparse(csvFormat);

        // Create a data URI
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvURL = window.URL.createObjectURL(csvData);

        // Simulate progress with a timeout
        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
        }, 500);

        // Create a download link
        const a = document.createElement('a');
        a.href = csvURL;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        clearInterval(interval);
        setDownloading(false); // Finish downloading
        setProgress(100); // Set progress to 100%
    };

    function generateSixDigitNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }


    const handleDownloadTableImage = async (Index) => {
        setDownloading(true); // Start downloading
        setProgress(0); // Reset progress

        const tableId = 'table_' + Index;
        const table = document.getElementById(tableId);

        if (!table) {
            console.error(`Table element with ID '${tableId}' not found.`);
            setDownloading(false); // Finish downloading
            setProgress(100); // Set progress to 100% to avoid blocking further downloads
            return;
        }

        try {
            // Use html2canvas to capture the table as an image
            const canvas = await html2canvas(table);

            // Create a download link
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            const randomNumber = generateSixDigitNumber();
            link.download = `${randomNumber}-table.png`;
            link.click();
        } catch (error) {
            console.error('Error capturing table as image:', error);
        }

        // Simulate progress with a timeout
        setTimeout(() => {
            setDownloading(false); // Finish downloading
            setProgress(100); // Set progress to 100%
        }, 500); // You can adjust the timeout as needed
    };

    return (
        <>
            <div className="table-content mb-6">
                <Card className={"border border-borderV1 p-3 md:p-3 mb-6"}>
                    <div className="heading mb-4 flex items-center justify-between gap-4">
                        <div className="">
                            <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>{item?.portfolioName}</span></h5>
                            <p className='text-xs text-textV1 font-500'>Vertical Exposure Table</p>
                        </div>
                        <div className="items-center gap-2 md:flex hidden">
                            <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => downloadCSV(item?.datasets)}>
                                <span><DownloadIcon className='w-4 h-4' /></span>
                            </Button>
                            {downloading && (
                                <div className="progress-container">
                                    <Progress value={progress} label={`${progress}%`} />
                                </div>
                            )}
                            <Button variant="outline" size="sm" className="w-8 h-8 p-0 !border-red hover:bg-[#F5F5F5]" onClick={() => deleteChart(item.chartId)} >
                                <span><Trash className='w-4 h-4 !text-red' /></span>
                            </Button>

                            {/* <Button variant="outline" size="sm" className="min-w-[100px]" onClick={() => downloadCSV(item?.datasets)}>
                                <span><Download className='w-4 h-4' /></span> Download
                            </Button> */}

                            {/* <Button variant="outline" size="sm" className="min-w-[100px]" >
                                <span><Trash className='w-4 h-4' /></span>  Delete
                            </Button> */}
                        </div>
                        <div className="items-center gap-2  md:hidden flex">
                            <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => downloadCSV(item?.datasets)}>
                                <span><Download className='w-4 h-4' /></span>
                            </Button>
                            <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => deleteChart(item.chartId)} >
                                <span><Trash className='w-4 h-4' /></span>
                            </Button>
                        </div>
                    </div>
                    <div className="table-wrapper">
                        <div className="rounded-sm border  overflow-hidden">
                            <Table className="min-w-[1000px] isTable css-13aj3tc-MuiChartsSurface-root">
                                <TableHeader>
                                    {table.getHeaderGroups().map((headerGroup) => (
                                        <TableRow key={headerGroup.id} className="">
                                            {headerGroup.headers.map((header) => {
                                                return (
                                                    <TableHead className="bg-backgroundV1" key={header.id}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                    </TableHead>
                                                )
                                            })}
                                        </TableRow>
                                    ))}
                                </TableHeader>
                                <TableBody>
                                    {table.getRowModel().rows?.length ? (
                                        table.getRowModel().rows.map((row) => (
                                            <TableRow
                                                key={row.id}
                                                data-state={row.getIsSelected() && "selected"}
                                            >
                                                {row.getVisibleCells().map((cell) => (
                                                    <TableCell className="text-xs" key={cell.id}>
                                                        {flexRender(
                                                            cell.column.columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={columns.length}
                                                className="h-24 text-center"
                                            >
                                                No results.
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    );
}

export default VerticalExposureTable;
