import { saleTransactionActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { PortfolioTransactionActionType, apiBaseURL } from "../../constants";
import { fetchInvestedAssetById } from "./portfolioTransactionHistoryAction";
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";

export const fetchInvestedAsset = (setIsLoading, id, currentPage, perPage, section) => async (dispatch) => {
    // dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIO_TRANSACTION + '/invested-assets?page='+currentPage+'&perPage='+perPage+'&section='+section,id)
        dispatch({ type: PortfolioTransactionActionType.FETCH_INVESTED_ASSETS, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: response.data?.data?.pageSize || 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false) 
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchSellAsset = (setIsLoading, id, currentPage, perPage, section, setTotalRecords, setTotalPages, setPerPage, setCurrentPage) => async (dispatch) => {
    // dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIO_TRANSACTION + '/invested-assets?page='+currentPage+'&perPage='+perPage+'&section='+section,id)
        dispatch({ type: saleTransactionActionType.FETCH_INVESTED_ASSETS_SALE, payload: response.data?.data });
        setTotalRecords && setTotalRecords(response.data?.data?.totalRecords)
        setTotalPages && setTotalPages(response.data?.data?.totalPages)
        setCurrentPage && setCurrentPage(response.data?.data?.currentPage)
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false) 
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}



export const updateInvestedAssetStrategy = (data, id, fetchAssetDataId, section, setIsLoading) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIO_TRANSACTION + '/invested-assets/update-strategy', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        // dispatch(fetchInvestedAssetById(null, fetchInvestAssetId))
        dispatch(fetchInvestedAsset(setIsLoading, id, 1, 10, section))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const addInvestedAsset = (data, id, fetchInvestDataId, section, setIsLoading) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIO_TRANSACTION + '/add', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchInvestedAsset(setIsLoading, id, 1, 10, section))
        // dispatch(fetchInvestedAssetById(null, fetchInvestAssetId))
    } catch (error) {
        dispatch(setLoading(false))
        const { response } = error
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: response?.data?.message }))
    }
}


export const addInvestedAssetSell = (data) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIO_TRANSACTION + '/sell-invested-asset', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        const { response } = error
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: response?.data?.message }))
    }
}


export const updateInvestedAsset = (data, id, fetchInvestAssetId, section, setIsLoading) => async (dispatch) => {
    // dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIO_TRANSACTION + '/update', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchInvestedAssetById(setIsLoading, fetchInvestAssetId))
        dispatch(fetchInvestedAsset(null, id, 1, 10, section))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const removeInvestedAsset = (data, id, fetchInvestAssetId, section, setIsLoading) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIO_TRANSACTION + '/remove', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchInvestedAsset(null, [id], 1, 10, section))
        dispatch(fetchInvestedAssetById(setIsLoading, fetchInvestAssetId))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}