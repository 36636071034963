import React, { useContext } from "react";
import { CanvasContext } from "../CanvasContainer";

const DividerElement = (props) => {
  const { content, id, isReadOnly, dimension } = props;
  const { actions } = useContext(CanvasContext);
  return (
    <>
      <div
        className="ql-editor"
        style={{
          background: 'black',
          padding: 0,

        }}
      ></div>
    </>
  );
};

export default DividerElement;
