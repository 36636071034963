import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/components/ui/button.jsx';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { addClass, updateClass } from '../../../../store/actions/classAction';
import { IconLoading } from '../../../../shared/common/Loading';
import { addAdminClass, updateAdminClass } from '../../../../store/actions/admin/classAction';

const AdminClassModel = (props) => {
    const { show, onHide, fetchData, fetchId } = props
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state)
    const [ctName, setCtName] = useState({
        name: ''
    })
    const [error, setError] = useState({});

    useEffect(() => {
        if (show === false) {
            setCtName({
                name: ''
            })
        }
    }, [show])

    useEffect(() => {
        if (fetchId !== null) {
            setCtName({
                name: fetchData && fetchData?.name,
            })
        }
    }, [fetchData, fetchId])

    const onChangeHandel = (e) => {
        setCtName({ ...ctName, name: e.target.value })
        setError({})
    }
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (ctName.name === '') {
            errors['Class'] = 'Please enter Class Name';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };
    const onSave = () => {
        if (handleValidation()) {
            if (fetchId !== null) {
                dispatch(updateAdminClass(fetchId, ctName))
            } else {
                dispatch(addAdminClass(ctName))
            }

        }
        onHide()
    }


    return (
        <>
            <Dialog open={show} onOpenChange={onHide}>
                <DialogContent className="sm:max-w-[460px] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{fetchId ? 'Edit class' : 'Add class'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-1 gap-5">
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    Class Name:<span style={{ color: 'red' }}>*</span>
                                </Label>
                                <Input
                                    className={`form-control shadow-none ${error['Class'] && 'is-invalid'}`}
                                    id="name" type="text" name="Class" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.name} />
                                <div className={` ${error['Class'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['Class'] ? error['Class'] : null}</div>
                            </div>
                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" className="min-w-[100px]" onClick={onSave} ><span>{isLoading ? <IconLoading height={15} width={15} /> : ""}</span>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AdminClassModel