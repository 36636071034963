import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteSubClass, fetchSubClass } from '../../../../store/actions/subClassAction';
import { fetchClass } from '../../../../store/actions/classAction';
import { DataTableNew } from '../../../../shared/common/table-components/tasks/components/data-table';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown.jsx';
import { Button } from '@/components/ui/button.jsx';
import { DotsHorizontalIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header';
import SubClassModel from '../models/SubClassModel';
import IconAdd from '../../../../components/svg/IconAdd';
import { Card } from '@/components/ui/Card';

const SubClassContent = () => {
    const dispatch = useDispatch()
    const { classData, subClassData } = useSelector(state => state)
    const [modalShow, setModalShow] = useState(false);
    const [allClass, setAllClass] = useState([])
    const [fetchData, setFetchData] = useState(null)
    const [fetchId, setFetchId] = useState(null)
    const [allSubClass, setAllSubClass] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items to display per page

    useEffect(() => {
        dispatch(fetchClass('all'))
    }, [dispatch])

    const onChange = (filters) => {
        dispatch(fetchSubClass(filters))
    }

    useEffect(() => {
        setAllClass(classData?.records)
        setAllSubClass(subClassData?.records)
    }, [classData, subClassData])

    useEffect(() => {
        setAllSubClass(subClassData?.records); // Make sure subClassData is an array
    }, [subClassData]);



    const handelDelete = (data) => {
        // let payloadData = { subClassData: [] }
        // payloadData.subClassData.push(data.id)
        dispatch(deleteSubClass(data.id))
    }

    const handelEdit = (data) => {
        setModalShow(true)
        setFetchData(data)
        setFetchId(data.id)
        dispatch(fetchClass())
    }

    // Pagination functions
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const data = Array.isArray(allSubClass) && allSubClass.length > 0 ? allSubClass.map((item, index) => {
        return {
            index: indexOfFirstItem + index + 1,
            data: item,
            id: item.id,
            name: item.name,
            class: item.class.name
        }
    }) : []

    const columns = [
        {
            accessorKey: "index",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.index}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "class",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="class" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.class}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Sub Class" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.name}</span>
            },
            enableSorting: true,

        },
        {
            id: "actions",
            enableHiding: false,
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => handelEdit(row?.original)}><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Edit</DropdownMenuItem>

                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => handelDelete(row?.original)}><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /> Remove</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        }
    ]
    const rightHeaderButtons = [
        {
          icon: <IconAdd className="w-4 h-4 text-white transition duration 200" />,
          text: 'Add Sub Class',
          onClick: () => { setModalShow(true); setFetchId(null); setFetchData(null) },
          variant: '',
          additionalClass: ""
        },
        // Add more button configurations as needed
      ];

    return (
        <>
            <section className='p-2 md:px-5'>
                <Card className={'p-2 md:px-5 bg-white shadow-[rgba(99, 99, 99, 0.2) 0px 2px 8px 0px]'}>
                    <DataTableNew data={data} columns={columns} onChange={onChange} filterName={'name'} 
                     isRightNew={true}
                     isOldHeaderHide={true}
                     isRightSearch={true}
                     isRightHeaderButtons={true}
                     rightHeaderButtons={rightHeaderButtons}
                    />
                    <SubClassModel show={modalShow}
                        onHide={() => setModalShow(false)} allClass={allClass}
                        fetchData={fetchData}
                        fetchId={fetchId}
                    />
                </Card>
            </section>
        </>
    )
}

export default SubClassContent