import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/components/ui/button.jsx';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table.jsx';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { IconLoading } from '../../../../../../shared/common/Loading';
import { FromDatePicker } from '../../../../../../components/ui/DatePickerNew';
import ValueSelect from '../../../selector/ValueSelect';

const PreviewModel = (props) => {
    const { show, onHide, fetchData, getField, handleSaveData } = props
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state)
    const [ctName, setCtName] = useState({
        _id: null,
        assetId: null,
        issuerId: null,
        value: 0,
        comment: '',
        description: '',
        endDate: null,
        startDate: null,
    })
    const [error, setError] = useState({});

    // useEffect(() => {
    //     if (show === false) {
    //         setCtName({
    //             _id: null,
    //             assetId: null,
    //             issuerId: null,
    //             value: 0,
    //             comment: '',
    //             description: '',
    //             endDate: null,
    //             startDate: null,
    //         })
    //     }
    // }, [show])
    useEffect(() => {
        if (fetchData !== null) {
            setCtName({
                _id: fetchData && fetchData?._id,
                assetId: fetchData && fetchData?.assetId !== '-' ? fetchData?.assetId : null,
                issuerId: fetchData && fetchData?.issuerId !== '-' ? fetchData?.issuerId : null,
                value: fetchData && fetchData?.value,
                Value: fetchData && fetchData?.value,
                comment: fetchData && fetchData?.comment !== '-' ? fetchData?.comment : '',
                description: fetchData && fetchData?.description !== '-' ? fetchData?.description : '',
                endDate: fetchData && fetchData?.endDate !== '-' ? new Date(fetchData && fetchData?.endDate) : null,
                startDate: fetchData && fetchData?.startDate !== '-' ? new Date(fetchData && fetchData?.startDate) : null,
            })
        }
    }, [fetchData])

    // console.log('getField', getField)
    // console.log('fetchData', fetchData)
    // console.log('ctName', ctName)


    const onChangeHandel = (e) => {
        setCtName({ ...ctName, [e.target.name]: e.target.value })
        setError({})
    }
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (ctName.value === 0 || ctName.value === '') {
            errors['value'] = 'Please enter value';
            isValid = false;
        }
        if (ctName.endDate === null) {
            errors['endDate'] = 'Please select End Date';
            isValid = false;
        }
        if (ctName.startDate === '') {
            errors['startDate'] = 'Please select Start Date';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };
    const onSave = () => {
        if (handleValidation()) {
            handleSaveData(ctName)
        }
    }

    const handleDateChange = (date, id, field) => {
        if (field === 'startDate') {
            setCtName({ ...ctName, [`startDate`]: date });
        } else {
            setCtName({ ...ctName, [`endDate`]: date });
        }
    }

    const onValueSelect = (event) => {
        setCtName({ ...ctName, [`value`]: event.label });
    }

    return (
        <>
            <Dialog open={show} onOpenChange={onHide}>
                <DialogContent className="sm:max-w-[70%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{'Edit Custom Data'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">

                        <Table className="min-w-[1000px]" tableContainerClassName="!overflow-visible">
                            <TableHeader>
                                <TableRow className="bg-[#F9F9F9]">
                                    <TableHead >
                                        {getField?.group} ID
                                    </TableHead>
                                    <TableHead >
                                        value
                                    </TableHead>
                                    <TableHead >
                                        Start Date
                                    </TableHead>
                                    <TableHead >
                                        End Date
                                    </TableHead>
                                    <TableHead >
                                        comment
                                    </TableHead>
                                    <TableHead >
                                        description
                                    </TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="p-2">
                                        <Input
                                            className={`form-control shadow-none h-[35px] ${error['Class'] && 'is-invalid'}`} readOnly
                                            id="name" type="text" name="Class" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.assetId !== null ? ctName.assetId : ctName.issuerId} />
                                    </TableCell>
                                    <TableCell className="p-2">
                                        {(getField.type === "Ordinal" || getField.type === "Categorical") ?
                                            <ValueSelect readOnly={false} editData={getField} row={ctName} isUpdate={true} id={fetchData?._id} onValueSelect={onValueSelect} />
                                            : <Input
                                                className={`form-control shadow-none h-[35px] ${error['value'] && 'is-invalid'}`}
                                                id="value" type="value" name="value" placeholder="value" onChange={(e) => onChangeHandel(e)} value={ctName.value} />}

                                        <div className={` ${error['value'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['value'] ? error['value'] : null}</div>
                                    </TableCell>
                                    <TableCell className="p-2">
                                        <FromDatePicker onDateSelect={(date) => handleDateChange(date, ctName._id, 'startDate')}
                                            placeholderText='YYYY-MM-DD'
                                            value={ctName.startDate}
                                            selectedFromDate={ctName.startDate} mainClassName={'body-table'}
                                        />
                                        <div className={` ${error['startDate'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['startDate'] ? error['startDate'] : null}</div>
                                    </TableCell>
                                    <TableCell className="p-2">
                                        <FromDatePicker onDateSelect={(date) => handleDateChange(date, ctName._id, 'endDate')}
                                            placeholderText='YYYY-MM-DD'
                                            value={ctName.endDate}
                                            minDate={ctName.startDate}
                                            selectedFromDate={ctName.startDate}
                                        />
                                        <div className={` ${error['endDate'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['endDate'] ? error['endDate'] : null}</div>
                                    </TableCell>
                                    <TableCell className="p-2">
                                        <Input
                                            className={`form-control shadow-none h-[35px] ${error['comment'] && 'is-invalid'}`}
                                            id="comment" type="text" name="comment" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.comment} />
                                        <div className={` ${error['comment'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['comment'] ? error['comment'] : null}</div>
                                    </TableCell>
                                    <TableCell className="p-2">
                                        <Input
                                            className={`form-control shadow-none h-[35px] ${error['description'] && 'is-invalid'}`}
                                            id="description" type="text" name="description" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.description} />
                                        <div className={` ${error['description'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['description'] ? error['description'] : null}</div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        {/* <div className="grid grid-cols-2 xxl:grid-cols-6 gap-5">
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    {ctName.assetId !== null ? 'Asset Id' : 'Issuer Id'}

                                </Label>
                                <Input
                                    className={`form-control shadow-none h-[35px] ${error['Class'] && 'is-invalid'}`} readOnly
                                    id="name" type="text" name="Class" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.assetId !== null ? ctName.assetId : ctName.issuerId} />
                            </div>
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    Value:<span style={{ color: 'red' }}>*</span>
                                </Label>
                                {(getField.type === "Ordinal" || getField.type === "Categorical") ?
                                    <ValueSelect readOnly={false} editData={getField} row={ctName} isUpdate={true} id={fetchData?._id} onValueSelect={onValueSelect} />
                                    : <Input
                                        className={`form-control shadow-none h-[35px] ${error['value'] && 'is-invalid'}`}
                                        id="value" type="value" name="value" placeholder="value" onChange={(e) => onChangeHandel(e)} value={ctName.value} />}

                                <div className={` ${error['value'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['value'] ? error['value'] : null}</div>
                            </div>
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    Start Date:<span style={{ color: 'red' }}>*</span>
                                </Label>
                                <FromDatePicker onDateSelect={(date) => handleDateChange(date, ctName._id, 'startDate')}
                                    placeholderText='YYYY-MM-DD'
                                    value={ctName.startDate}
                                    selectedFromDate={ctName.startDate} mainClassName={'body-table'}
                                />
                                <div className={` ${error['startDate'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['startDate'] ? error['startDate'] : null}</div>
                            </div>
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    End Date:<span style={{ color: 'red' }}>*</span>
                                </Label>
                                <FromDatePicker onDateSelect={(date) => handleDateChange(date, ctName._id, 'endDate')}
                                    placeholderText='YYYY-MM-DD'
                                    value={ctName.endDate}
                                    minDate={ctName.startDate}
                                    selectedFromDate={ctName.startDate}
                                />
                                <div className={` ${error['endDate'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['endDate'] ? error['endDate'] : null}</div>
                            </div>
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    Comment:
                                </Label>
                                <Input
                                    className={`form-control shadow-none h-[35px] ${error['comment'] && 'is-invalid'}`}
                                    id="comment" type="text" name="comment" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.comment} />
                                <div className={` ${error['comment'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['comment'] ? error['comment'] : null}</div>
                            </div>
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    Description:
                                </Label>
                                <Input
                                    className={`form-control shadow-none h-[35px] ${error['description'] && 'is-invalid'}`}
                                    id="description" type="text" name="description" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.description} />
                                <div className={` ${error['description'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['description'] ? error['description'] : null}</div>
                            </div>
                        </div> */}
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" className="min-w-[100px]" onClick={onSave} ><span>{isLoading ? <IconLoading height={15} width={15} /> : ""}</span>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default PreviewModel