
import { analyticReportsActionType, apiBaseURL, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";


export const fetchAnalyticReports = (data, setChartData, chartData, charts, setIsLoading, id) => async (dispatch) => {

    try {
        const response = await apiConfig.get(apiBaseURL.REPORTS + '?analytics=' + data.analytics + '&asset=' + data.asset + '&from=' + data.from + '&to=' + data.to)
        if (id) {
            const updateData = chartData.map((item) => {
                if (item.id === id) {
                    item.id = id;
                    item.value = data.analytics;
                    item.data = response.data?.data;
                    item.type = charts;
                    item.isAutoChart = data.isAutoChart
                }
                return item
            })
            setChartData(updateData)
        } else {
            setChartData([...chartData, { id: Math.floor(100000 + Math.random() * 900000), value: data.analytics, type: charts, data: response.data?.data, isAutoChart: data.isAutoChart }])
        }
        dispatch(setLoading(false))
        setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchAnalyticReportsTow = (data) => async (dispatch) => {

    try {
        const response = await apiConfig.get(apiBaseURL.REPORTS + '?analytics=' + data.analytics + '&asset=' + data.asset + '&from=' + data.from + '&to=' + data.to)
        // const { data } = response.data;
        dispatch({ type: analyticReportsActionType.FETCH_ANALYTIC_REPORTS_TOW, payload: response.data?.data });
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}