import { assetsDetailsV2ActionType } from '../../constants';

const assetsDetailsV2Reducer = (state = [], action) => {
    switch (action.type) {
        case assetsDetailsV2ActionType.FETCH_ASSETS_DETAILS_V2:
            return action.payload;
        case assetsDetailsV2ActionType.FETCH_ASSETS_DETAIL_V2:
            return action.payload;
        case assetsDetailsV2ActionType.ADD_ASSETS_DETAIL_V2:
            return action.payload;
        case assetsDetailsV2ActionType.UPDATE_ASSETS_DETAIL_V2:
            return action.payload;
        case assetsDetailsV2ActionType.DELETE_ASSETS_DETAIL_V2:
            return action.payload;
        default:
            return state;
    }
};
export default assetsDetailsV2Reducer