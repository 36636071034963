import { issuerActionType } from '../../constants';

const issuerHistoryReducer = (state = [], action) => {
    switch (action.type) {
        case issuerActionType.FETCH_ISSUER_HISTORY:
            return action.payload;
        default:
            return state;
    }
};
export default issuerHistoryReducer