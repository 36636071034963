import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const ViewDocument = () => {
    const [documentUrl, setDocumentUrl] = useState('');
    const { displayDoc } = useSelector(state => state);

    useEffect(() => {
        fetchDocument(displayDoc);
    }, [displayDoc]);

    const fetchDocument = async (displayDoc) => {
        try {
            // Fetch the image data from the API
            
            const response = await fetch(`/view/file/${displayDoc}`);
            if (!response.ok) {
                throw new Error('Failed to fetch document');
            }
            // Convert the image data to blob
            const blob = await response.blob();
            // Create an object URL from the blob
            const url = URL.createObjectURL(blob);
            // Set the document URL state
            setDocumentUrl(url);
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };



    return (
        <div>
            ViewDocument
        </div>
    );
};

export default ViewDocument;
