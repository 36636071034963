
const serverUrl = process.env.REACT_APP_SERVER_URL
const serverUrlForIR = process.env.REACT_APP_SERVER_URL_IR_STRUCTURE
const analysisServerUrl = process.env.REACT_APP_ANALYSIS_SERVER_URL

export const environment = {
    SERVER_URL: serverUrl ,
    SERVER_URL_FOR_IR_STRUCTURE:serverUrlForIR,
    ANALYSIS_SERVER_URL: analysisServerUrl ,
    BASE_URL: window.location.protocol + '//' + window.location.hostname + ':' + window.location.port,
};

// http://192.168.29.152:3003/api
// 'https://app.sustainabilityyield.com'