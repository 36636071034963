import React, { useEffect, useState } from 'react'
import {
    DotsHorizontalIcon,
    Pencil2Icon,
    PlusIcon,
} from "@radix-ui/react-icons"
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"

import { Button } from "../ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../ui/dropdown"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table"
import IconAdd from '../svg/IconAdd'
import { Badge, generateBadgeVariant } from '../ui/badge'
import { Link, useNavigate } from 'react-router-dom'
import DataTablePagination from '../../shared/common/table-components/tasks/components/data-table-pagination'
import { Filters, apiBaseURL, toastifyType } from '../../constants'
import { Minus, Package, TrashIcon, CopyPlus } from 'lucide-react'
import { useSelector } from 'react-redux'
import { archiveAllocation, authorizeAllocate, cloneAllocation, deleteAllocation, fetchAllocations, fetchAllocationsDetails } from '../../store/actions/AllocationAction'
import { addTost } from '../../store/actions/toastAction'
import { fetchAllocationHistory } from '../../store/actions/AllocationHistory'
import { useDispatch } from 'react-redux'
import { fetchStrategy, fetchStrategyAll } from '../../store/actions/strategyAction'
import { fetchPortfolioGroups } from '../../store/actions/portfolioGroupAction'
import { dateConvert } from '../../shared/common/dateFormate'
import apiConfig from '../../config/apiConfig';
import _ from 'lodash'
import moment from 'moment-js'
import AddAllocationModel from '../../pages/dashboard/allocation/model/AddAllocationModel'
import Alert from './Alert'
import Loading from '../../shared/common/Loading'
import { fetchAllPortfolios } from '../../store/actions/portfolioAction'
import CloneAllocationModel from '../../pages/dashboard/allocation/model/CloneAllocationModel'

const data5 = [
    {
        portfolio: "Portfolio One 1",
        name: "Apple Inc(AAPL)",
        description: "Apple Inc(AAPL)",
        start: "Jan 4, 2024",
        end: "Jan 2, 2024",
        status: "Unauthorize",
    },
    {
        portfolio: "Portfolio One 1",
        name: "Apple Inc(AAPL)",
        description: "Apple Inc(AAPL)",
        start: "Jan 4, 2024",
        end: "Jan 2, 2024",
        status: "Authorize",
    },
    {
        portfolio: "Portfolio One 1",
        name: "Apple Inc(AAPL)",
        description: "Apple Inc(AAPL)",
        start: "Jan 4, 2024",
        end: "Jan 2, 2024",
        status: "Authorize",
    },
    {
        portfolio: "Portfolio One 1",
        name: "Apple Inc(AAPL)",
        description: "Apple Inc(AAPL)",
        start: "Jan 4, 2024",
        end: "Jan 2, 2024",
        status: "Unauthorize",
    },
    {
        portfolio: "Portfolio One 1",
        name: "Apple Inc(AAPL)",
        description: "Apple Inc(AAPL)",
        start: "Jan 4, 2024",
        end: "Jan 2, 2024",
        status: "Authorize",
    },
    {
        portfolio: "Portfolio One 1",
        name: "Apple Inc(AAPL)",
        description: "Apple Inc(AAPL)",
        start: "Jan 4, 2024",
        end: "Jan 2, 2024",
        status: "Authorize",
    },
]



export const columns5 = [
    {
        accessorKey: "portfolio",
        header: "Portfolio",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("portfolio")}</div>
        ),
    },
    {
        accessorKey: "Name",
        header: "Name",
        cell: ({ row }) => (
            <Link to={"/allocation/test"} className="flex items-center text-[#2487EE] hover:underline cursor-pointer">{row.getValue("Name")}</Link>
        ),
    },
    {
        accessorKey: "description",
        header: "Description",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("description")}</div>
        ),
    },
    {
        accessorKey: "start",
        header: "Start Date",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("start")}</div>
        ),
    },
    {
        accessorKey: "end",
        header: "End Date",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("end")}</div>
        ),
    },
    {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
            const variant = generateBadgeVariant(row.getValue("status"))
            return <Badge variant={variant} className={""}>{row.getValue("status")}</Badge>
        },
    },
    {
        id: "actions",
        enableHiding: false,
        header: <span className="flex items-center justify-end">Action</span>,
        cell: ({ row }) => {
            return (
                <div className="flex items-center justify-end">
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="p-0">
                                <span className="sr-only">Open menu</span>
                                <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" ><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Edit</DropdownMenuItem>
                            <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" ><Minus strokeWidth={"2.2"} className='text-red w-4 h-4' /> Unauthorize</DropdownMenuItem>
                            <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" ><Package strokeWidth={"2.2"} className='text-yellow-600 w-4 h-4' /> Archive</DropdownMenuItem>
                            <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`}><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /> Remove</DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            )
        },
    },
]

const AllocationInitialTable = () => {
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)


    // my code 
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { isLoading, allocation, allocationHistory, strategy, allocationsDetails, metaData, allPortfolio, } = useSelector(state => state)
    const [modalShow, setModalShow] = useState(false);
    const [allocationData, setAllocationData] = useState([])
    const [allocationsDetailsData, setAllocationsDetailsData] = useState([])
    const [selectedRowId, setSelectedRowId] = useState({})
    const [fetchData, setFetchData] = useState(false)
    const [data, setData] = useState([]);
    const [dataFetched, setDataFetched] = useState(false);
    const [cloneModel, setCloneModel] = useState(false);

    // const [pending, setPending] = useState(true);
    const [isClickPage, setIsClickPage] = React.useState(false);
    const [selectedAllocation, setSelectedAllocation] = useState(null);


    useEffect(() => {
        dispatch(fetchAllocations(setAllocationData, currentPage, perPage))
    }, [currentPage, perPage, dispatch]);

    useEffect(() => {
        setTotalRecords(metaData.totalItems)
        setTotalPages(Math.ceil(metaData.totalItems / metaData.pageSize))
    }, [metaData]);

    useEffect(() => {
        setSelectedRowId({})
        setDataFetched(false)
        dispatch(fetchStrategyAll('all', Filters.OBJ))
        dispatch(fetchPortfolioGroups())
        dispatch(fetchAllPortfolios())
    }, [])

    useEffect(() => {
        if (!_.isEmpty(selectedRowId)) {
            dispatch(fetchAllocationsDetails(selectedRowId.id, setAllocationsDetailsData, setDataFetched))
        }
    }, [fetchData])

    useEffect(() => {
        if (allocationData?.totalRecords) {
            setTotalRecords(allocationData.totalRecords)
        }
    }, [allocationData])


    const fetchAllAsset = async (slug) => {
        let data;
        if (slug) {
            try {
                const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '/' + slug + apiBaseURL.ASSETS_DETAILS);
                if (response.data?.data) {
                    data = response.data?.data;
                }
            } catch (error) {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }));
            }
        }
        return data;
    }


    // strategy 
    const strategyOptions = strategy && strategy?.records?.length ? [{ value: null, label: 'None' }, ...strategy?.records.map((items) => {
        return { value: items._id, label: items.name }
    })] : []

    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
    };

    const deleteUserClick = () => {
        const data = {
            allocationId: isDelete.id,
            action: 'remove'
        }
        dispatch(deleteAllocation(data, setAllocationData, setDataFetched, currentPage, perPage))
        onClickDeleteModel(false);
    };

    const archiveClick = (isArchive) => {
        const data = {
            allocationId: isArchive.id,
            action: 'archived'
        }
        dispatch(archiveAllocation(data, setAllocationData, setDataFetched, currentPage, perPage))
    };

    // portfolios 
    const portfolioOptions = allPortfolio && allPortfolio?.records?.length >= 1
        ? allPortfolio?.records.map((item) => {
            return { value: item?.id, label: item?.name, slug: item?.slug };
        }) : []

    useEffect(() => {
        if (dataFetched && isClickPage) {
            // setPending(true)
            const fetchData = async () => {
                const datas = await Promise.all(allocationsDetailsData.map(async (item) => {

                    // const allAsset = await fetchAllAsset(item.portfolio.slug);

                    // Extract the required data and transform it into the desired format
                    // asset by portfolio
                    const allocatedAssets = item.allocatedAssets.length ? item.allocatedAssets.map((asset) => ({
                        id: asset?.asset?._id,
                        label: asset?.asset?.assetName,
                    })) : [];

                    // all allocations by all allocations
                    // get One By One allocations
                    const getData = allocation?.records?.length && allocation?.records.filter((items) => items._id === item._id)

                    // Allocations By Parents Allocated Assets map
                    const updateData = item && item.allocatedAssets ? item.allocatedAssets.map((allocated) => {
                        // Allocations Portfolio By Authorise Assets

                        const getAsset = item.authorizeAssets && item.authorizeAssets && item.authorizeAssets.find((items => items?.asset?._id === allocated?.asset))
                        const getAssetByPortfolio = item.authorizeAssets && item.authorizeAssets && item.authorizeAssets.filter((items => items?.portfolio === allocated?.portfolio?._id))
                        const prepareAssetData = getAssetByPortfolio.length ? getAssetByPortfolio.map((item) => {
                            return { label: item.asset.assetName, value: item.asset._id, data: item }
                        }) : []
                        // const filteredAssets = datas[0]?.allSelectPortfolio !== null ? datas[0]?.allAsset.filter(asset => datas[0]?.allSelectPortfolio.includes(asset.data.portfolio)) : datas[0]?.allAsset;
                        return {
                            ...allocated,
                            getAssetByPortfolio: prepareAssetData,
                            authorizeAssets: item.authorizeAssets,
                            strategy: allocated.strategy ? strategyOptions.filter((item) => item.value === allocated.strategy)[0] : { value: null, label: 'None' },
                            subClass: allocated.subClass,
                            asset: { value: getAsset?.asset?._id, label: getAsset?.asset?.assetName, data: getAsset }, errorMessage: null,
                            errorTALoverMessage: null,
                            errorLoverMessage: null,
                            errorUpperMessage: null,
                            errorTAUpperMessage: null,
                            validateLoverTolerance: [],
                            validateTALoverTolerance: [],
                            validateUpperTolerance: [],
                            validateTAUpperTolerance: [], isLock: allocated.allocatedSubAssets.length ? true : false,
                            TA: allocated.SAA + allocated.TAA,
                            portfolioOptions: portfolioOptions, // new add
                            portfolioOpSelected: allocated?.portfolio ? allocated?.portfolio : null,
                            allocatedSubAssets: allocated.allocatedSubAssets.length ? allocated.allocatedSubAssets.map((allocatedSubAssetsItem) => {
                                const getSubAsset = item.authorizeAssets && item.authorizeAssets && item.authorizeAssets.find((items => items?.asset?._id === allocatedSubAssetsItem?.asset))

                                return {
                                    id: allocatedSubAssetsItem._id,
                                    isSubAssetAllocation: true,
                                    class: allocated.class,
                                    subClass: allocatedSubAssetsItem.subClass,
                                    getAssetByPortfolio: prepareAssetData,
                                    type: "Fixed Weight",
                                    SAA: allocatedSubAssetsItem.SAA,
                                    strategy: allocatedSubAssetsItem.strategy ? strategyOptions.filter((item) => item.value === allocatedSubAssetsItem.strategy)[0] : { value: null, label: 'None' },
                                    asset: { value: getSubAsset?.asset?._id, label: getSubAsset?.asset?.assetName, data: getSubAsset?.asset },
                                    actualAllocation: allocatedSubAssetsItem.SAA,
                                    SAALT: allocatedSubAssetsItem.SAALT,
                                    SAAUT: allocatedSubAssetsItem.SAAUT,
                                    TAA: allocatedSubAssetsItem.TAA,
                                    TA: allocatedSubAssetsItem.SAA + allocatedSubAssetsItem.TAA,
                                    TALT: allocatedSubAssetsItem.TALT,
                                    TAUT: allocatedSubAssetsItem.TAUT,
                                    errorMessage: null,
                                    errorTALoverMessage: null,
                                    errorLoverMessage: null,
                                    errorUpperMessage: null,
                                    errorTAUpperMessage: null,
                                    validateLoverTolerance: [],
                                    validateTALoverTolerance: [],
                                    validateUpperTolerance: [],
                                    validateTAUpperTolerance: [],
                                    // portfolioOptions: portfolioOptions, // new add
                                }
                            }) : []
                        }
                    }) : []

                    const assetOptions = item.authorizeAssets && item.authorizeAssets?.length >= 1 ? item.authorizeAssets?.map((item) => {
                        return { value: item?.asset?._id, label: item?.asset?.assetName, data: item }
                    }) : []
                    // console.log('updateData', updateData)
                    return {
                        id: item._id,
                        name: item.name,
                        description: getData[0].description,
                        startDate: moment(getData[0].startDate).format('MM/DD/YYYY'),
                        endDate: moment(getData[0].endDate).format('MM/DD/YYYY'),
                        portfolio: getData[0]?.portfolio?._id,
                        portfolioSlug: getData[0]?.portfolio?.slug,
                        portfolioName: getData[0]?.portfolio?.name,
                        status: getData[0]?.status,
                        allAsset: assetOptions,
                        allStrategy: strategyOptions,
                        allSelectPortfolio: item?.portfolio ? item?.portfolio : null,
                        getAssetByPortfolio: [],
                        authorizeAssets: item.authorizeAssets,
                        // portfolioOptions: portfolioOptions, // new add
                        isLock: false,
                        errorTALoverMessage: null,
                        errorLoverMessage: null,
                        errorUpperMessage: null,
                        errorTAUpperMessage: null,
                        validateLoverTolerance: [],
                        validateTALoverTolerance: [],
                        validateUpperTolerance: [],
                        validateTAUpperTolerance: [],
                        errorMessage: null,
                        allocatedAssets: allocatedAssets,
                        updateData: updateData,
                        isAuthorize: getData[0]?.isAuthorize
                    };
                }));
                // setData(datas);
                // setPending(false);
                // old 9-5-24
                // let updateData = data.map((items) => {
                //     if (items?.id === datas[0]?.id) {
                //         return datas[0]
                //     } else {
                //         return items
                //     }
                // })
                // new 9-5-24
                let updateData = data.map((items) => {

                    if (items?.id === datas[0]?.id) {
                        if (datas[0]?.updateData) {
                            // multi portfolioOpSelected 
                            // const updateData =  datas[0]?.updateData.map((item) => {return{...item, portfolioOptions: items.portfolioOptions, portfolioOpSelected: items.portfolioOpSelected}})
                            // single portfolioOpSelected 
                            // const filteredAssets = datas[0]?.allSelectPortfolio !== null ? datas[0]?.allAsset.filter(asset => datas[0]?.allSelectPortfolio.includes(asset.data.portfolio)) : datas[0]?.allAsset;
                            const updateData = datas[0]?.updateData.map((item) => {
                                return { ...item, portfolioOptions: items.portfolioOptions, portfolioOpSelected: item?.portfolioOpSelected ? item?.portfolioOpSelected : null }
                            })
                            // return {...datas[0], updateData: updateData, portfolioOptions: items.portfolioOptions, portfolioOpSelected: items.portfolioOpSelected}
                            return { ...datas[0], updateData: updateData, portfolioOptions: items.portfolioOptions }
                        } else {
                            // return {...datas[0], portfolioOptions: items.portfolioOptions, portfolioOpSelected: items.portfolioOpSelected}
                            return { ...datas[0], portfolioOptions: items.portfolioOptions, portfolioOpSelected: null }
                        }

                    } else {
                        return items
                    }
                })
                setData(updateData)
                setDataFetched(false); // Set the flag to indicate data has been fetched
                setIsClickPage(false)
                const getUpdatedData = updateData.filter((item) => item.id === selectedRowId?.id)
                navigate('/allocation/' + getUpdatedData[0]?.name.trim().toLowerCase().replace(/\s+/g, '-'), { state: { updateData: getUpdatedData?.length ? getUpdatedData[0] : {}, strategyOptions, selectedName: getUpdatedData[0] } })
            };
            setTimeout(() => {
                fetchData();
            }, 0);
        }
    }, [dataFetched]); // Include dataFetched in the dependencies

    useEffect(() => {
        if (allocationData?.records) {
            // setPending(true)
            const prepareData = allocationData.records?.length ? allocationData.records.map((item) => {
                // debugger
                return {
                    id: item._id,
                    name: item.name,
                    description: item.description,
                    startDate: moment(item.startDate).format('MM/DD/YYYY'),
                    endDate: moment(item.endDate).format('MM/DD/YYYY'),
                    portfolio: item?.portfolio?._id,
                    portfolioGroup: item?.portfolioGroup,
                    portfolioSlug: item?.portfolio?.length ? item?.portfolio?.map(item => item.slug).join(", ") : '',
                    // portfolioSlug: item?.portfolio?.slug,
                    portfolioName: item?.portfolio?.length ? item?.portfolio?.map(item => item.name).join(", ") : '',
                    // portfolioName: item?.portfolio?.name,

                    // multi option   11-5-24
                    // portfolioOpSelected: item?.portfolio?.length ? ['All', ...item?.portfolio?.map(item => item._id)] : [], // new add 9-5-24
                    // portfolioOptions: [{
                    //     name: 'All',
                    //     slug: 'All',
                    //     _id: 'All'
                    // }, ...item?.portfolio], // new add 9-5-24

                    // single option   11-5-24
                    portfolioOpSelected: null, // new add 9-5-24
                    portfolioOptions: item?.portfolio, // new add 9-5-24


                    status: item.status,
                    allStrategy: strategyOptions,
                    isLock: false,
                    errorTALoverMessage: null,
                    errorLoverMessage: null,
                    errorUpperMessage: null,
                    errorTAUpperMessage: null,
                    validateLoverTolerance: [],
                    validateTALoverTolerance: [],
                    validateUpperTolerance: [],
                    validateTAUpperTolerance: [],
                    errorMessage: null,
                    isAuthorize: item.isAuthorize
                }
            }) : []
            setData(prepareData)
            // setPending(false);
        }

    }, [allocationData])

    const handleContinue = () => {
        const data = {
            allocationId: isDelete.id,
            action: 'remove'
        }
        dispatch(deleteAllocation(data, setAllocationData, setDataFetched, currentPage, perPage))
        onClickDeleteModel(false);
    }

    const handleCancel = () => {
        setDeleteModel(false);
        setIsDelete(null);
    }


    const navigateAllocationDetailsPage = (data) => {
        setFetchData(!fetchData);
        setSelectedRowId(data);
        setIsClickPage(true)
    }

    const onClone = (data) => {
        // dispatch(cloneAllocation(data?.id, setAllocationData, setDataFetched, currentPage, perPage))
        setSelectedAllocation(data);
        setCloneModel(true)
    }

    const columns = [
        {
            accessorKey: "Portfolio",
            header: "Portfolio",
            cell: ({ row }) => (
                <div className="capitalize line-clamp-1" title={row.original?.portfolioName} >{row.original?.portfolioName}</div>
            ),
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (
                <div onClick={() => navigateAllocationDetailsPage(row.original)} className="flex items-center text-[#2487EE] hover:underline cursor-pointer">{row.original?.name}</div>
            ),
        },
        {
            accessorKey: "Description",
            header: "Description",
            cell: ({ row }) => (
                <div className="capitalize">{row.original?.description}</div>
            ),
        },
        {
            accessorKey: "Start Date",
            header: "Start Date",
            cell: ({ row }) => (
                <div className="capitalize">{dateConvert(row.original?.startDate)}</div>
            ),
        },
        {
            accessorKey: "End Date",
            header: "End Date",
            cell: ({ row }) => (
                <div className="capitalize">{dateConvert(row.original?.endDate)}</div>
            ),
        },
        {
            accessorKey: "status",
            header: "Status",
            cell: ({ row }) => {
                const variant = generateBadgeVariant(row.getValue("status"))
                return <Badge variant={variant} className={"capitalize "}>{row.getValue("status")}</Badge>
            },
        },

        {
            id: "actions",
            enableHiding: false,
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0 w-8 h-6 hover:bg-themeV2/10">
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => handleEdit(row.original)} > <span className='w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]'><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /></span> Edit</DropdownMenuItem>
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => onClone(row.original)} > <span className='w-5 h-5 bg-blue-600/10 flex items-center justify-center rounded-[4px]'><CopyPlus strokeWidth={"2.2"} className='text-black w-4 h-4' /> </span> Clone</DropdownMenuItem>
                                {row.original?.status === 'unAuthorise' || row.original?.status === 'unAuthorised' ? <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => authorizeCallBack(row.original?.id, 'authorised')} >  <span className='w-5 h-5 bg-green-400/10 flex items-center justify-center rounded-[4px]'><Minus strokeWidth={"2.2"} className='text-green-400 w-4 h-4' /></span> Authorise</DropdownMenuItem> : ''}
                                {row.original?.status === 'authorise' || row.original?.status === 'authorised' ? <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => authorizeCallBack(row.original?.id, 'unAuthorised')} > <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'> <Minus strokeWidth={"2.2"} className='text-red w-4 h-4' /></span> Unauthorize</DropdownMenuItem> : ''}
                                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onclick={() => archiveClick(row.original)} > <span className='w-5 h-5 bg-yellow-600/10 flex items-center justify-center rounded-[4px]'><Package strokeWidth={"2.2"} className='text-yellow-600 w-4 h-4' /> </span> Archive</DropdownMenuItem>
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => onClickDeleteModel(row.original)} > <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'> <TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /></span> Remove</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        }
    ];
    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState([])
    const [columnVisibility, setColumnVisibility] = useState({})
    const [rowSelection, setRowSelection] = useState({})
    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    })

    const handleEdit = (allocation) => {

        setSelectedAllocation(allocation);
        setModalShow(true)
    };


    const [isOpen, setIsOpen] = useState(false)
    const [isId, setIsId] = useState(null)
    const handelClick = (data) => {
        if (isId === data.id) {
            setIsOpen(!isOpen)
            setIsId(data.id)
            !isOpen && dispatch(fetchAllocationHistory(data.id))
        } else {
            setIsOpen(true)
            setIsId(data.id)
            dispatch(fetchAllocationHistory(data.id))
        }

    }

    const authorizeCallBack = (id, type) => {
        const data = {
            allocationId: id,
            action: type
        }
        // authorizeAllocate
        dispatch(authorizeAllocate(data, setAllocationData, setDataFetched, currentPage, perPage))

    }



    return (
        <>
            <div className="w-full">
                <div className="flex items-center justify-between mb-4 gap-4">
                    <Button variant="" size="sm" onClick={() => setModalShow(true)}>
                        <IconAdd className="w-5 h-5" />
                        Add Allocation
                    </Button>

                    <Input
                        placeholder="Search filter..."
                        value={data.length ? (table?.getColumn("name")?.getFilterValue() || "") : ''}
                        onChange={(event) =>
                            data.length && table?.getColumn("name")?.setFilterValue(event.target.value)
                        }
                        className="h-8 w-full sm:w-[150px] lg:w-[250px]"
                    />
                </div>
                <div className="mb-4">
                    <Table className="border-separate border-spacing-y-2 table-has-border-space">
                        <TableHeader className="">
                            {table?.getHeaderGroups().map((headerGroup) => (
                                <TableRow key={headerGroup.id || headerGroup._id} className="bg-[#F9F9F9] border-[#C4CEDC]">
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <TableHead className=" border first:rounded-tl-sm last:rounded-tr-sm  text-xs" key={header.id}>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </TableHead>
                                        )
                                    })}
                                </TableRow>
                            ))}
                        </TableHeader>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    <Loading />
                                </TableCell>
                            </TableRow> : table?.getRowModel().rows?.length ? (
                                table?.getRowModel().rows.map((row) => (
                                    <TableRow
                                        className="shadow-table-row"
                                        key={row.id || row._id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell className="text-xs border py-2" key={cell.id || cell._id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div className="">
                    <DataTablePagination table={table} setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                </div>
            </div>
            <AddAllocationModel
                show={modalShow}
                onHide={() => setModalShow(false)}
                setDataFetched={setDataFetched}
                setAllocationData={setAllocationData}
                allocationData={allocationData}
                editAllocationData={selectedAllocation}
                setSelectedAllocation={setSelectedAllocation}
                currentPage={currentPage}
                perPage={perPage}
            />
            <CloneAllocationModel
                show={cloneModel}
                onHide={() => setCloneModel(false)}
                setDataFetched={setDataFetched}
                cloneId={data?.id}
                setAllocationData={setAllocationData}
                allocationData={allocationData}
                editAllocationData={selectedAllocation}
                setSelectedAllocation={setSelectedAllocation}
                currentPage={currentPage}
                perPage={perPage}
            />
            <Alert title="Are you absolutely sure?" description="This action cannot be undone. This will permanently delete your Allocation data from our servers." handleClickContinue={handleContinue} handleClickCancel={handleCancel} showAlertDialog={deleteModel} setShowAlertDialog={setDeleteModel} />
        </>
    )
}

export default AllocationInitialTable