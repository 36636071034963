import React from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import SettingTabs from './setting-tabs/SettingTabs'

const Setting = () => {
  return (
    <DashboardMaster>
      <section className='py-3 md:py-5'>
        <SettingTabs />
      </section>
    </DashboardMaster>
  )
}

export default Setting