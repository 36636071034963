import React from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'

const Profile = () => {
  return (
    <DashboardMaster>
      Profile
    </DashboardMaster>
  )
}

export default Profile
