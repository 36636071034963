import { Settings, NotebookIcon } from "lucide-react";
import IconAccount from "../components/svg/IconAccount";
import IconAllocation from "../components/svg/IconAllocation";
import IconData from "../components/svg/IconData";
import IconNews from "../components/svg/IconNews";
import IconPortfolio from "../components/svg/IconPortfolio";
import IconReporting from "../components/svg/IconReporting";
import IconSetting from "../components/svg/IconSetting";
import IconAsset from "../components/svg/IconAsset";
import IconAnalytics from "../components/svg/IconAnalytics";
import IconPrices from "../components/svg/IconPrices";
import IconByod from "../components/svg/IconByod";
import IconProfile from "../components/svg/IconProfile";
import IconIssuer from "../components/svg/IconIssuer";
import IconSentry from "../components/svg/IconSentry";
import IconResearchNote from "../components/svg/iconResearchNote";
import chroma from 'chroma-js';
import IconAnalysis from "../components/svg/IconAnalysis";
export const dashboardNavigation = [
  { id: "1", link: "Portfolio", href: "/", icon: <IconPortfolio className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  { id: "2", link: "Allocation", href: "/allocation", icon: <IconAllocation className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  { id: "3", link: "Reporting", href: "/reporting", icon: <IconReporting className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  { id: "4", link: "News", href: "/news", icon: <IconNews className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  { id: "5", link: "Analysis", href: "/analysis", icon: <IconAnalysis className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  { id: "6", link: "Sentry", href: "/sentry", icon: <IconSentry className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  { id: "7", link: "Research Notes", href: "/research-notes", icon: <IconResearchNote className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  {
    id: "8", link: "Data", href: "/data/asset", icon: <IconData className="w-5 h-5 text-white group-[.active]:text-themeV2" />,
    hasChild: true, childrenNavigation: [
      { link: "Assets", href: "/data/asset", icon: <IconAsset className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
      { link: "Issuers", href: "/data/issuer", icon: <IconIssuer className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
      { link: "Analytics", href: "/data/analytics", icon: <IconAnalytics className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
      { link: "Prices", href: "/data/prices", icon: <IconPrices className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
      { link: "BYOD", href: "/data/BYOD", icon: <IconByod className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
    ]
  },
  {
    id: "9", link: "Account", href: "/account/profile", icon: <IconAccount className="w-5 h-5 text-white group-[.active]:text-themeV2" />,
    hasChild: true, childrenNavigation: [
      { link: "Profile", href: "/account/profile", icon: <IconProfile strokeWidth={"2.2"} className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
      { link: "Setting", href: "/account/setting", icon: <Settings strokeWidth={"2.2"} className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
    ]

  },
  //  {link:"Setting",href:"/setting",icon:<Settings strokeWidth={"2.2"} className="w-5 h-5 text-white group-[.active]:text-themeV2"/>},
]

export const adminDashboardNavigation = [
  { id: "1", link: "User", href: "/admin", icon: <IconProfile className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
  {
    id: "2", link: "Account", href: "/account/admin/profile", icon: <IconAccount className="w-5 h-5 text-white group-[.active]:text-themeV2" />,
    hasChild: true, childrenNavigation: [
      { link: "Setting", href: "/admin/account/setting", icon: <Settings strokeWidth={"2.2"} className="w-5 h-5 text-white group-[.active]:text-themeV2" /> },
    ]

  },
  //  {link:"Setting",href:"/setting",icon:<Settings strokeWidth={"2.2"} className="w-5 h-5 text-white group-[.active]:text-themeV2"/>},
]

export const colorOptions = [
  { value: 'ocean', label: '#Ocean', color: '#00B8D9' },
  { value: 'blue', label: '#Blue', color: '#0052CC' },
  { value: 'purple', label: '#Purple', color: '#5243AA' },
  { value: 'red', label: '#Red', color: '#FF5630' },
  { value: 'orange', label: '#Orange', color: '#FF8B00' },
  { value: 'yellow', label: '#Yellow', color: '#FFC400' },
  { value: 'green', label: '#Green', color: '#36B37E' },
  { value: 'forest', label: '#Forest', color: '#00875A' },
  { value: 'slate', label: '#Slate', color: '#253858' },
  { value: 'silver', label: '#Silver', color: '#666666' },
];
// export const colorOptions = [
//     { value: 'ocean', label: '#Ocean', color: '#00B8D9', isFixed: true },
//     { value: 'blue', label: '#Blue', color: '#0052CC', isDisabled: true },
//     { value: 'purple', label: '#Purple', color: '#5243AA' },
//     { value: 'red', label: '#Red', color: '#FF5630', isFixed: true },
//     { value: 'orange', label: '#Orange', color: '#FF8B00' },
//     { value: 'yellow', label: '#Yellow', color: '#FFC400' },
//     { value: 'green', label: '#Green', color: '#36B37E' },
//     { value: 'forest', label: '#Forest', color: '#00875A' },
//     { value: 'slate', label: '#Slate', color: '#253858' },
//     { value: 'silver', label: '#Silver', color: '#666666' },
// ];

// Given color array
export const colorArray = ["#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6", "#E6B333", "#3366E6",
  "#999966", "#809980", "#E6FF80", "#1AFF33", "#999933", "#FF3380", "#CCCC00", "#66E64D", "#4D80CC", "#FF4D4D", "#99E6E6", "#6666FF",
  '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
  '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
  '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
  '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
  '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
  '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
  '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
  '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
  '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'].filter(color => {
    // Calculate the luminance of each color using chroma-js
    const luminance = chroma(color).luminance();
    // You can adjust the luminance threshold based on your preference
    return luminance < 0.2; // Adjust this threshold as needed
  });


export const data = [
  {
    id: "1",
    portfolioGroupName: "Portfolio Group One-1",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-1",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "2",
    portfolioGroupName: "Portfolio Group One-2",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-2",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "1",
    portfolioGroupName: "Portfolio Group One-1",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-1",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "2",
    portfolioGroupName: "Portfolio Group One-2",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-2",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "1",
    portfolioGroupName: "Portfolio Group One-1",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-1",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "2",
    portfolioGroupName: "Portfolio Group One-2",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-2",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "1",
    portfolioGroupName: "Portfolio Group One-1",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-1",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "2",
    portfolioGroupName: "Portfolio Group One-2",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-2",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "1",
    portfolioGroupName: "Portfolio Group One-1",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-1",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "2",
    portfolioGroupName: "Portfolio Group One-2",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-2",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "1",
    portfolioGroupName: "Portfolio Group One-1",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-1",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "2",
    portfolioGroupName: "Portfolio Group One-2",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-2",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "1",
    portfolioGroupName: "Portfolio Group One-1",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-1",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
  {
    id: "2",
    portfolioGroupName: "Portfolio Group One-2",
    portfolioCreatedDate: "Jan 4, 2024",
    portfolioGroupName2: "Portfolio Group One-2",
    portfolioCreatedDate2: "Jan 4, 2024",
  },
];

export const groupBYODOp = [
  {
    Value: 1,
    label: 'Assets'
  },
  {
    Value: 2,
    label: 'Issuers'
  },
]

export const typeBYODOp = [
  {
    value: 1,
    label: 'Quantitative'
  },
  {
    Value: 2,
    label: 'Ordinal'
  },
  {
    Value: 3,
    label: 'Categorical'
  }
]
export const subTypeBYODOp = [
  {
    value: 1,
    label: 'Numeric'
  },
  {
    Value: 2,
    label: 'Text'
  },
]

export const assetAnalyticOp = [
  {
    Value: 1,
    label: 'World Bank'
  },
  {
    Value: 2,
    label: 'United Nations (SDGs)'
  },
]

export const financialType = [
  { id: 'all', label: 'All' },
  {
    id: 'EQUITY',
    label: 'Equity'
  },
  // {
  //     id: 3,
  //     label: 'Indices'
  // },
  {
    id: 'ETF',
    label: 'ETF'
  },
  {
    id: 'MutualFund',
    label: 'Mutual Fund'
  },
  {
    id: 'bond',
    label: 'Bond'
  },
]

export const modelSentry = [
  {
    id: 1,
    label: 'KNN (k-Nearest Neighbors Detector)',
    symbol: 'KNN'
  },
  {
    id: 2,
    label: 'KPCA (Kernel Principal Component Analysis (KPCA) Outlier Detector)',
    symbol: 'KPCA'
  },
  {
    id: 3,
    label: 'COPOD (Copula Based Outlier Detector (COPOD))',
    symbol: 'COPOD'
  },
  {
    id: 4,
    label: 'ECOD (Unsupervised Outlier Detection Using Empirical Cumulative Distribution Functions (ECOD))',
    symbol: 'ECOD'
  },
  {
    id: 5,
    label: 'CBLOF (Clustering Based Local Outlier Factor (CBLOF))',
    symbol: 'CBLOF'
  },
  {
    id: 6,
    label: 'COF (Connectivity-Based Outlier Factor (COF) Algorithm)',
    symbol: 'COF'
  },
  {
    id: 7,
    label: 'AutoEncoder (Using Auto Encoder with Outlier Detection)',
    symbol: 'AutoEncoder'
  },
  {
    id: 8,
    label: 'AutoEncoderTorch (Using AutoEncoder with Outlier Detection (PyTorch))',
    symbol: 'AutoEncoderTorch'
  },
]

export const dateSetPrice = [
  {
    id: 1,
    label: 'Price - Adj Open',
    symbol: 'adjOpen'
  },
  {
    id: 2,
    label: 'Price - Adj Close',
    symbol: 'adjClose'
  },
  {
    id: 3,
    label: 'Price - Adj High',
    symbol: 'adjHigh'
  },
  {
    id: 4,
    label: 'Price - Adj Low',
    symbol: 'adjLow'
  },
  {
    id: 5,
    label: 'Price - Open',
    symbol: 'open'
  },
  {
    id: 6,
    label: 'Price - Close',
    symbol: 'close'
  },
  {
    id: 7,
    label: 'Price - High',
    symbol: 'high'
  },
  {
    id: 8,
    label: 'Price - Low',
    symbol: 'low'
  },
  {
    id: 9,
    label: 'Price - BYOD',
    symbol: 'BYOD'
  }
]

export const displayAnalysisType = [
  {
    id: 1,
    label: 'Outliers'
  },
  {
    id: 2,
    label: 'All'
  },
]

export const chartType = [
  {
    id: 1,
    label: 'Horizontal Exposure Table'
  },
  {
    id: 2,
    label: 'Vertical Exposure Table'
  },
  {
    id: 3,
    label: 'Line Chart'
  },
  {
    id: 4,
    label: 'Bar Chart'
  },
  {
    id: 5,
    label: 'Pie Chart'
  },
  {
    id: 6,
    label: 'Doughnut Chart'
  },
  {
    id: 7,
    label: 'PolarArea Chart'
  },
  {
    id: 8,
    label: 'Radar Chart'
  },
  {
    id: 9,
    label: 'Scatter Chart'
  },
  // {
  //     id: 10,
  //     label: 'Bubble Chart'
  // }
]

export const chartTypeOld = [
  {
    id: 1,
    label: 'Line Chart'
  },
  {
    id: 2,
    label: 'Bar Chart'
  },
  {
    id: 3,
    label: 'Pie Chart'
  },
  {
    id: 4,
    label: 'Doughnut Chart'
  },
  {
    id: 5,
    label: 'PolarArea Chart'
  },
  {
    id: 6,
    label: 'Radar Chart'
  },
  {
    id: 7,
    label: 'Scatter Chart'
  },
  {
    id: 8,
    label: 'Bubble Chart'
  }
]


export const statusType = [
  {
    id: 1,
    label: 'No'
  },
  {
    id: 2,
    label: 'Yes'
  },
]


export const frequencyOptions = [
  {
    value: "12M",
    label: 'Annual'
  },
  {
    value: "6M",
    label: 'Semi Annual'
  },

]

export const settlementDaysOptions = [
  {
    label: 0,
    value: 0
  },
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
]