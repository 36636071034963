import { issuerActionType } from '../../constants';

const issuerReducer = (state = [], action) => {
    switch (action.type) {
        case issuerActionType.FETCH_ISSUER:
            return action.payload;
        case issuerActionType.FETCH_ISSUER:
            return action.payload;
        case issuerActionType.ADD_ISSUER:
            return action.payload;
        case issuerActionType.UPDATE_ISSUER:
            return action.payload;
        case issuerActionType.DELETE_ISSUER:
            return action.payload;
        default:
            return state;
    }
};
export default issuerReducer