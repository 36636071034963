import React, { useEffect, useState } from 'react'
import { Card, CardContent } from '../../../components/ui/Card'
import { DataTableNew } from '../../../shared/common/table-components/tasks/components/data-table'
import { fetchUsers, verifyAdmin, changeUserStatus } from '../../../store/actions/admin/userAction';
import { DataTableWithCustomPagination } from '../../../shared/common/table-components/tasks/components/data-table-without-pagination';
import { Filters } from '../../../constants';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '../../../components/ui/checkbox';
import DataTableColumnHeader from '../../../shared/common/table-components/tasks/components/data-table-column-header';
import DataTableColumn from '../../../shared/common/table-components/tasks/components/data-table-column';
import { Badge, generateBadgeVariant } from '../../../components/ui/badge';
import moment from 'moment-js';

const AdminUserData = () => {

    const dispatch = useDispatch();
    const { user, isLoading } = useSelector((state) => state);
    const [modalShow, setModalShow] = useState(false);
    const [fetchData, setFetchData] = useState(null);
    const [fetchId, setFetchId] = useState(null);
    const [allUsers, setAllUsers] = useState(user);

    const [checkedData, setCheckedData] = useState([])
    const [getUser, setGetUser] = useState(null);

    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)

    useEffect(() => {
        dispatch(fetchUsers(currentPage, setAllUsers))
    }, [currentPage, perPage]);

    useEffect(() => {
        setTotalRecords(user.totalRecords)
        setTotalPages(Math.ceil(user.totalRecords / perPage))
    }, [user]);



    const onCheckBtn = (data) => {
        setGetUser(data)
        setCheckedData([data.id])
        // if (checkedData.includes(data.id)) {
        //     setCheckedData(checkedData.filter(id => id !== data.id));
        // } else {
        //     // If the checkbox is unchecked, remove the id from checkedData
        //     setCheckedData([...checkedData, data.id]);
        // }
    }

    const handleVerifyUserStatus = () => {
        getUser && dispatch(changeUserStatus('active', [getUser.id]))
    }

    const handleDeactivateUserStatus = () => {
        getUser && dispatch(changeUserStatus('inactive', [getUser.id]))
    }

    const data = user?.records && user?.records?.length ? user?.records.map((item) => {
        return {
            id: item.id ? item.id : '-',
            email: item.email ? item.email : '-',
            accountStatus: item.status ? item.status :'-',
            status: item.loginDate ? "Logged In" : "Logged Out",
            role: item.role ? item.role : '-',
            createdAt: item.createdAt ? item.createdAt : '-',
            loginDate: item.loginDate ? item.loginDate : '-',
            logoutDate: item.logoutDate ? moment(item.logoutDate).format('DD-MM-YYYY, HH:mm:ss') : moment(item.loginDate).format('DD-MM-YYYY, HH:mm:ss'),
            loginCount: item.loginCount ? item.loginCount : 0,
        }
    }) : []


    const columns = [
        {
            id: 'select',
            header: ({ table }) => (
                // Header content for the 'select' column
                <>
                    {/* Add any header content if needed */}
                </>
            ),
            cell: ({ row }) => (
                // Cell content for the 'select' column
                <Checkbox
                    checked={checkedData.includes(row?.original.id)}
                    onCheckedChange={(value) => { row.toggleSelected(!!value); onCheckBtn(row?.original) }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "email",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Email" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.email}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "role",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Role" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.role}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "accountStatus",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Account Status" />
            ),
            cell: ({ row }) => {
                const variant = generateBadgeVariant(row?.original.accountStatus)
                return <Badge variant={variant} className={""}>{row?.original.accountStatus}</Badge>
            },
        },
        {
            accessorKey: "loginCount",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Login Count" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.loginCount}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "status",
            header: "Status",
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.status}</span>
            },
        },
        {
            accessorKey: "logoutDate",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Login/Logout Date" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.logoutDate}</span>
            },
            enableSorting: true,
        },

    ]

    const rightHeaderButtons = [
        // {
        //     icon: "",
        //     text: 'Verify Users',
        //     onClick: () => { handleVerifyUserStatus() },
        //     variant: '',
        //     additionalClass: ""
        // },
        {
            icon: "",
            text: 'Activate Users',
            onClick: () => { handleVerifyUserStatus() },
            variant: '',
            additionalClass: ""
        },
        {
            icon: "",
            text: 'Deactivate Users',
            onClick: () => { handleDeactivateUserStatus() },
            variant: '',
            additionalClass: ""
        },
    ]
    console.log('user?.records', user?.records)

    return (
        <section className='p-2 md:px-5'>
            <Card className='my-0 md:my-0 md:p-0 p-0 bg-white'>
                <div className="main-table-wrapper">
                    <div className={`p-3 md:p-5 `}>
                        <DataTableWithCustomPagination
                            isRightNew={true}
                            isOldHeaderHide={true}
                            isRightHeaderButtons={true}
                            rightHeaderButtons={rightHeaderButtons}
                            loadData={isLoading}
                            data={data}
                            columns={columns}
                            filterName={'email'}
                            setPerPage={setPerPage}
                            perPage={perPage}
                            totalRecords={totalRecords}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </Card>
        </section>

    )
}

export default AdminUserData
