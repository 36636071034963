
import { apiBaseURL, customFieldsDataType, toastifyType,} from "../../constants";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";

export const fetchCustomFieldsData = (id, currentPage, perPage) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.CUSTOM_FIELD_VALUE + '/' + id + '?page=' + currentPage + '&perPage=' + perPage)
        dispatch({ type: customFieldsDataType.FETCH_CUSTOM_DATA, payload: response.data?.data });
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}
export const addCustomFieldValue = (data) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.CUSTOM_FIELD_VALUE + '/add-update', data);

        return response.data
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    }
};


export const deleteCustomFieldValue = (payload) => async (dispatch) => {
    try {
        const response = await apiConfig.delete(apiBaseURL.CUSTOM_FIELD_VALUE, { data: payload });
        return response.data
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    }
};
