import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../../../components/ui/button';
import { Trash2 } from 'lucide-react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { deleteUserAnalytics, fetchUserAnalyticsWithPagination } from '../../../../store/actions/userAnalyticsAction';
import { DataTableWithCustomPagination } from '../../../../shared/common/table-components/tasks/components/data-table-without-pagination';
import Alert from '../../../../components/common/Alert';
import { AnalyticsContext } from '../Analytics';


const UnitedNationAnalyticsTable = (props) => {
    const { dataId, } = props;
    const { currentPage, setCurrentPage, perPage, setPerPage, totalRecords, setTotalRecords, totalPages, setTotalPages } = useContext(AnalyticsContext);
    const isAnalytics = true;
    const dispatch = useDispatch();
    const { userAnalytic } = useSelector(state => state);
    const [sourceId, setSourceId] = useState('');
    const [source, setSource] = useState('');
    const [allUserAnalytics, setAllUserAnalytics] = useState([]);
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    // http://192.168.29.152:3003/api/analytics/latest-value/65d433067665718abf4d5029?source=64f719efea1b0161311fe2ea&page=1&perPage=10
    // dispatch(fetchGroupAnalyticsData(fetchAssetData?._id, setIsLoadingData, { page: currentPage, perPage: perPage }, selectedOp?.value ? selectedOp?.value : '',

    useEffect(() => {
        if (dataId) {
            // dispatch(fetchGroupAnalyticsData({ page: currentPage, perPage: perPage }, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
            dispatch(fetchUserAnalyticsWithPagination({ page: currentPage, perPage: perPage }, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
        }
    }, [dataId, currentPage, perPage])

    useEffect(() => {
        if (userAnalytic) {
            const worldBank = Array.isArray(userAnalytic?.records) ? userAnalytic.records.flatMap((item) => item.indicators) : null;
            const worldBankSourceId = Array.isArray(userAnalytic?.records) ? userAnalytic?.records[0]?.sourceId : null;
            const worldBankSource = Array.isArray(userAnalytic?.records) ? userAnalytic?.records[0]?.source : null;
            const unitedNationIndicators = worldBank?.length ? worldBank : [];
            // const getSourceId = worldBank ? worldBank.sourceId : '';
            setSourceId(worldBankSourceId)
            setSource(worldBankSource)
            setAllUserAnalytics(unitedNationIndicators)
        }
    }, [userAnalytic])


    const handelDelete = (e, data) => {
        setDeleteModel(!deleteModel);
        setIsDelete(data);
    };

    const handleCancel = () => {
        setDeleteModel(false);
        setIsDelete(null);
    }

    const handleContinue = () => {
        const deleteData = source === "World Bank" ? {
            sourceId: sourceId,
            analyticId: isDelete.analyticId,
        } : {
            sourceId: sourceId,
            analyticId: isDelete.analyticId,
            seriesId: isDelete?.series?._id ? isDelete?.series?._id : ''
        }
        dispatch(deleteUserAnalytics(deleteData, isAnalytics, { page: currentPage, perPage: perPage }, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
        setDeleteModel(false)
        setIsDelete(null);
    }

    // Pagination functions
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

    const data = allUserAnalytics && allUserAnalytics.length >= 1 ? allUserAnalytics.map((item, index) => {
        return { id: indexOfFirstItem + index + 1, ...item }
    }) : []


    const columns = source === "World Bank" ? [
        {
            accessorKey: "id",
            header: "Id",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("id")}</div>
            ),
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (
                <div className="capitalize">{row.original?.indicatorName ? row.original?.indicatorName : row?.original?.code + '. ' + row?.original?.description}</div>
            ),
        },
        {
            accessorKey: "category",
            header: "Category",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.indicatorCategory?.indicatorCategoryName ? row?.original?.indicatorCategory?.indicatorCategoryName : '-'}</div>
            ),
        },
        {
            accessorKey: "source",
            header: "Source",
            cell: ({ row }) => (
                <div className="capitalize">{source}</div>
            ),
        },
        {
            accessorKey: "status",
            header: "Status",
            cell: ({ row }) => (
                <div className="capitalize ">
                    <Button variant="outline" className="w-6 h-6 border-none flex-shrink-0 p-0 hover:bg-[#F5F5F5]" onClick={(e) => handelDelete(null, row.original)}>
                        <Trash2 className='w-5 h-5 text-red' />
                    </Button>
                </div>
            ),
        },
    ] : [
        {
            accessorKey: "id",
            header: "Id",
            cell: ({ row }) => (
                <div className="capitalize">{row.original.id}</div>
            ),
        },
        {
            accessorKey: "series",
            header: "Series",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.series?.description || '-'}</div>
            ),
        },
        {
            accessorKey: "name",
            header: "Name",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.code + '. ' + row?.original?.description}</div>
            ),
        },
        {
            accessorKey: "target",
            header: "Target",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.target?.code + '. ' + row?.original?.target?.title}</div>
            ),
        },
        {
            accessorKey: "goal",
            header: "Goal",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.goal?.code + '. ' + row?.original?.goal?.title}</div>
            ),
        },
        {
            accessorKey: "source",
            header: "Source",
            cell: ({ row }) => (
                <div className="capitalize">{"United Nations (SDGs)"}</div>
            ),
        },
        {
            accessorKey: "action",
            header: "Action",
            cell: ({ row }) => (
                <div className="capitalize">
                    <Button variant="outline" className="w-6 h-6 border-none flex-shrink-0 p-0 hover:bg-[#F5F5F5]" onClick={(e) => handelDelete(null, row?.original)}>
                        <Trash2 className='w-5 h-5 text-red' />
                    </Button>
                </div>
            ),
        },
    ]


    return (
        <div className="table-wrapper">
            <DataTableWithCustomPagination data={data} columns={columns} filterName={'Name'} isRight={true} isCollViewHide={true}
                setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            <Alert title="Are you absolutely sure?" description="This action cannot be undone. This will permanently delete your World Bank data from our servers." handleClickContinue={handleContinue} handleClickCancel={handleCancel} showAlertDialog={deleteModel} setShowAlertDialog={setDeleteModel} />
        </div>
    )
}

export default UnitedNationAnalyticsTable
