import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-js'
import { updateInvestedAssetStrategy } from '../../../../store/actions/portfolioTransactionAction'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'

const AssetUpdateModel = (props) => {
    const { fetchAssetDatas, PortfolioName, isInvestQuantity, fetchData } = props;
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    const { slug } = useParams();
    const { strategy } = useSelector(state => state)
    const [error, setError] = useState({});
    const [updateId, setUpdateId] = useState(null)

    useEffect(() => {
        if (fetchAssetDatas) {
            setData(fetchAssetDatas)
        }
    }, [fetchAssetDatas])

    const [formData, setFormData] = useState({
        purchasePrice: '',
        quantity: '',
        purchaseDate: null
    })

    useEffect(() => {
        setFormData({
            purchasePrice: '',
            quantity: '',
            purchaseDate: null
        })
    }, [slug])

    // strategy 
    const [strategyOp, setStrategyOp] = useState(null)
    const strategyOptions = strategy && strategy?.records?.length ? [{ value: null, label: 'None' }, ...strategy?.records.map((items) => {
        return { value: items._id, label: items.name }
    })] : []
    const onChangeStrategyOp = (obj) => {
        const getObj = JSON.parse(obj)
        setStrategyOp(getObj)
        setError({ ...error, ['strategy']: '' });
    }

    useEffect(() => {
        if (!_.isEmpty(fetchData) && !_.isEmpty(fetchAssetDatas)) {
            setFormData({
                purchasePrice: fetchData?.price,
                quantity: fetchData?.quantity,
                purchaseDate: fetchData ? moment(fetchData?.purchaseDate).format('YYYY-MM-DD') : new Date
            })
            setUpdateId(fetchData?._id)
            if (fetchAssetDatas?.strategy) {
                const getStrategy = strategyOptions.filter((items) => items.label === fetchAssetDatas.strategy)
                setStrategyOp(getStrategy.length ? getStrategy[0] : { value: null, label: 'None' })
            } else {
                setStrategyOp({ value: null, label: 'None' })
            }
            setError({})
        } else {
            if (!_.isEmpty(fetchAssetDatas)) {
                setFormData({
                    assetId: fetchAssetDatas?.assetId,
                    purchasePrice: fetchAssetDatas?.averageprice,
                    quantity: fetchAssetDatas?.totalQuantity,
                    // purchasePrice: 0,
                    // quantity: 1,
                    purchaseDate: moment(new Date).format('YYYY-MM-DD')
                })
                setUpdateId(fetchAssetDatas?._id)
                if (fetchAssetDatas?.strategy) {
                    const getStrategy = strategyOptions.filter((items) => items.label === fetchAssetDatas.strategy)
                    setStrategyOp(getStrategy.length ? getStrategy[0] : { value: null, label: 'None' })
                } else {
                    setStrategyOp({ value: null, label: 'None' })
                }
                setError({})
            }
        }

    }, [fetchAssetDatas, fetchData])


    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError({})
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (formData.purchasePrice === '') {
            errors['purchasePrice'] = 'Please enter price';
            isValid = false;
        }
        if (formData.purchaseDate === '') {
            errors['purchaseDate'] = 'Please select purchaseDate';
            isValid = false;
        }
        if (formData.quantity === '') {
            errors['quantity'] = 'Please enter quantity';
            isValid = false;
        }
        // if (strategyOp === null) {
        //     errors['strategy'] = 'Please select strategy';
        //     isValid = false;
        // }
        setError(errors);
        return isValid;
    };

    const handleEmptyState = () => {
        setFormData({
            purchasePrice: '',
            quantity: '',
            purchaseDate: null
        })
    }


    const onSave = (e) => {
        if (handleValidation()) {
            if (!_.isEmpty(fetchAssetDatas)) {
                const data = {
                    strategyId: strategyOp?.value ? strategyOp?.value : null,
                    investedAssetId: fetchAssetDatas?._id,
                }
                dispatch(updateInvestedAssetStrategy(data, [PortfolioName?.portfolio?._id], fetchAssetDatas._id, 'investedAsset', null))
                props.onHide()
                handleEmptyState();
            }
        }
    }


    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[50%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{isInvestQuantity ? 'Edit Strategy' : 'Invest Asset'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div>
                            <h5>Asset:</h5>

                            <div className="grid grid-cols-2 gap-5">
                                <div className="grid grid-cols-1 gap-5">
                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            ID:
                                        </Label>
                                        <Input
                                            className={`form-control shadow-none`}
                                            id="basic-form-dob" disabled={true} name='removalDate' type="text" value={formData?.assetId ? formData?.assetId : 'N/A'} />
                                    </div>
                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            CLASS:
                                        </Label>
                                        <Input
                                            className={`form-control shadow-none`}
                                            id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssetDatas && fetchAssetDatas?.class} />
                                    </div>
                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            DESCRIPTION
                                        </Label>
                                        <Input
                                            className={`form-control shadow-none`}
                                            id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssetDatas && fetchAssetDatas?.description} />
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-5">
                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            NAME:
                                        </Label>
                                        <Input
                                            className={`form-control shadow-none`}
                                            id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssetDatas && fetchAssetDatas?.assetName} />
                                    </div>



                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            SUB CLASS:
                                        </Label>
                                        <Input
                                            className={`form-control shadow-none`}
                                            id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssetDatas && fetchAssetDatas?.subClass} />
                                    </div>



                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            COUNTRY
                                        </Label>
                                        <Input
                                            className={`form-control shadow-none`}
                                            id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssetDatas && fetchAssetDatas?.country?.countryName} />
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-2 mt-2.5 gap-5">
                                <div className="grid grid-cols-1 gap-5">
                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            Purchase Price:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            disabled className={`form-control shadow-none ${error['purchasePrice'] && 'is-invalid'}`} name='purchasePrice' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="number" value={formData.purchasePrice} />

                                        <div className={`${error['purchasePrice'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['purchasePrice'] ? error['purchasePrice'] : null}</div>
                                    </div>

                                    <div className="form-group">
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select strategy
                                        </Label>
                                        <Select
                                            onValueChange={(e) => onChangeStrategyOp(e)}
                                            defaultValue={strategyOp}
                                        // value={fetchId !== null ? SubName.classOption?.id ? SubName.classOption?.id : "" : SubName.classOption?.id}
                                        >
                                            <SelectTrigger className="w-full">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select strategy"
                                                >
                                                    {strategyOp?.label ? strategyOp?.label : "Select strategy"}
                                                    {/* {fetchId !== null? SubName.classOption?.label ? SubName.classOption?.label : "" : SubName.classOption?.label} */}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        strategyOptions && strategyOptions.length > 0 ? strategyOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>

                                        <div className={` ${error['strategy'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['strategy'] ? error['strategy'] : null}</div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 gap-5">
                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            Purchase Date:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            disabled className={`form-control shadow-none ${error['purchaseDate'] && 'is-invalid'}`} name='purchaseDate' onChange={(e) => onChangeHandel(e)} id="basic-form-dob" type="date" value={formData.purchaseDate} />

                                        <div className={`${error['purchaseDate'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['purchaseDate'] ? error['purchaseDate'] : null}</div>
                                    </div>

                                    <div className="form-group">
                                        <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                            Purchase Quantity:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                        <Input
                                            disabled className={`form-control shadow-none ${error['quantity'] && 'is-invalid'}`} id="inputEmail3" name='quantity' onChange={(e) => onChangeHandel(e)} type="number" value={formData.quantity} />

                                        <div className={`${error['quantity'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['quantity'] ? error['quantity'] : null}</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default AssetUpdateModel