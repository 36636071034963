import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog'
import { Button } from '../ui/button'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { Textarea } from '../ui/textarea'

import { useDispatch } from "react-redux"
import { addPortfolioGroup, updatePortfolioGroup } from '../../store/actions/portfolioGroupAction'

const AddPortfolioGroupModal = ({ addPortfolioGroupModalOpen, setAddPortfolioGroupModalOpen, fetchData, fetchId, slug, setFetchData, setFetchId }) => {


    const dispatch = useDispatch();
    const [portfolioGroupData, setPortfolioGroupData] = useState({
        name: "",
        description: "",
        // portfolioName: null
    });
    const [error, setError] = useState({})

    useEffect(() => {
        if (fetchId) {
            if (fetchData?.portfolioGroup) {
                setPortfolioGroupData({
                    name: fetchData && fetchData?.portfolioGroup ? Object.keys(fetchData?.portfolioGroup).length !== 0 ? fetchData?.portfolioGroup?.name : '' : '',
                    description: fetchData && fetchData?.portfolioGroup ? Object.keys(fetchData?.portfolioGroup).length !== 0 ? fetchData?.portfolioGroup?.description : '' : '',
                    id: fetchData && Object.keys(fetchData?.portfolioGroup).length !== 0 ? fetchData?.portfolioGroup?._id : '',
                    // portfolioName: fetchData?.portfolios?.name ? fetchData?.portfolios?.name : null
                });
            } else {
                setPortfolioGroupData({
                    name: fetchData && fetchData?.portfolioGroup ? Object.keys(fetchData?.portfolioGroup).length !== 0 ? fetchData?.portfolioGroup?.name : '' : '',
                    description: fetchData && fetchData?.portfolioGroup ? Object.keys(fetchData?.portfolioGroup).length !== 0 ? fetchData?.portfolioGroup?.description : '' : '',
                    // portfolioName: fetchData?.portfolios?.name ? fetchData?.portfolios?.name : null
                })
            }

        }
    }, [fetchData, fetchId]);

    useEffect(() => {
        if (!addPortfolioGroupModalOpen) {
            setPortfolioGroupData({
                name: "",
                description: "",
                // portfolioName: null
            })
            setFetchData && setFetchData(null); 
            setFetchId && setFetchId(null);
        } else {
            if(!fetchId || fetchId === null){
                setPortfolioGroupData({
                    name: "",
                    description: "",
                    // portfolioName: null
                })
                setFetchData && setFetchData(null); 
                setFetchId && setFetchId(null);
            }
            
        }
    }, [addPortfolioGroupModalOpen])

    // useEffect(() => {
    //     setPortfolioGroupData({
    //         ...portfolioGroupData,
    //         name: fetchData?.portfolioGroup?.name,
    //         description: fetchData?.portfolioGroup?.description
    //     })
    // }, [fetchId])

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (portfolioGroupData.name === '') {
            errors['name'] = 'Please enter Name';
            isValid = false;
        }
        if (portfolioGroupData.description.length <= 3) {
            errors['description'] = 'Please enter at least 4 characters';
            isValid = false;
        }
        if (portfolioGroupData.description === "") {
            errors['description'] = 'Please enter Description';
            isValid = false;
        }
        // if (fetchId && portfolioGroupData.portfolioName !== null && portfolioGroupData.portfolioName === '') {
        //     errors['portfolioName'] = 'Please enter portfolio name';
        //     isValid = false;
        // }

        setError(errors);
        return isValid;
    };

    const handleSave = () => {
        if (handleValidation()) {
            if (fetchId) {
                // const data = {
                //     portfolioGroupMappingId: portfolioGroupData.id,
                //     name: portfolioGroupData.portfolioName,
                //     portfolioGroupId: portfolioGroupData !== null ? portfolioGroupData.portfolioGroup === null ? null : portfolioGroupData.portfolioGroup : null,
                // };
                dispatch(updatePortfolioGroup(fetchId, portfolioGroupData, 1, slug))
            } else {
                // const data = {
                //     name: portfolioGroupData.portfolioName,
                //     portfolioGroupId: portfolioGroupData.portfolioGroup,
                // };
                dispatch(addPortfolioGroup(portfolioGroupData, 1, slug))
                setPortfolioGroupData({
                    description: "",
                    name: ""
                })
            }
            setPortfolioGroupData({
                name: "",
                description: "",
                // portfolioName: null
            }); setError({})
            setFetchData && setFetchData(null); 
            setFetchId && setFetchId(null);
            setAddPortfolioGroupModalOpen(false);
        }
    };

    const onChangeHandel = (e) => {
        setPortfolioGroupData({ ...portfolioGroupData, [e.target.name]: e.target.value })
        setError({})
    }


    return (
        <Dialog open={addPortfolioGroupModalOpen} onOpenChange={() => setAddPortfolioGroupModalOpen(false)}>
            <DialogContent className="sm:max-w-[460px] gap-10">
                <DialogHeader>
                    <DialogTitle className="text-center">{fetchId ? "Edit Portfolio Group" : "Add Portfolio Group"}</DialogTitle>
                </DialogHeader>
                <div className="dialog-body">
                    <div className="grid grid-cols-1 gap-4 ">
                        {/* {(fetchId) && (portfolioGroupData.portfolioName !== null) && <div className="form-group">
                            <Label htmlFor="portfolioName" className="block mb-2.5 text-textV1">
                                Portfolio Name <span className='text-red'>*</span>
                            </Label>
                            <Input id="portfolioName" name="portfolioName" value={portfolioGroupData.portfolioName} placeholder="Portfolio Name" autoComplete="off" onChange={(e) => onChangeHandel(e)} />
                            <div className={` ${error['portfolioName'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['portfolioName'] ? error['portfolioName'] : null}</div>
                        </div>} */}
                        <div className="form-group">
                            <Label htmlFor="name" className="block mb-2.5 text-textV1">
                                Group Name <span className='text-red'>*</span>
                            </Label>
                            <Input id="name" name="name" value={portfolioGroupData.name} placeholder="Name" autoComplete="off" onChange={(e) => onChangeHandel(e)} />
                            <div className={` ${error['name'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['name'] ? error['name'] : null}</div>
                        </div>
                        <div className="form-group">
                            <Label htmlFor="username" className="block mb-2.5 text-textV1">
                                Group Description
                            </Label>
                            <Textarea id="description" name="description" value={portfolioGroupData.description} autoComplete="off" placeholder="Description" onChange={(e) => onChangeHandel(e)} />
                            <div className={` ${error['description'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['description'] ? error['description'] : null}</div>
                        </div>
                    </div>
                </div>
                <DialogFooter className={"!justify-center !gap-2"}>
                    <Button type="submit" className="min-w-[100px]" onClick={(e) => handleSave(e)}>Save</Button>
                    <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={() => {
                        setAddPortfolioGroupModalOpen(false); setPortfolioGroupData({
                            name: "",
                            description: "",
                            portfolioName: null
                        }); setError({});
                        setFetchData && setFetchData(null); 
            setFetchId && setFetchId(null);
                    }}>Cancle</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default AddPortfolioGroupModal