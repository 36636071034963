import { adminTypeActionType } from '../../../constants';

const adminTypeReducer = (state = [], action) => {
    switch (action.type) {
        case adminTypeActionType.FETCH_TYPES:
            return action.payload;
        case adminTypeActionType.ADD_TYPES:
            return action.payload;
        case adminTypeActionType.DELETE_TYPES:
            return action.payload;
        case adminTypeActionType.UPDATE_TYPES:
            return action.payload;
        default:
            return state;
    }
};
export default adminTypeReducer