import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'

import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import BuyAsset from './BuyAsset';
import SellAsset from './SellAsset';
import IconMaintanance from '../../../../components/svg/IconMaintanance';
import IconAsset from '../../../../components/svg/IconAsset';
import AuthAsset from './AuthAsset';
import { Filters } from '../../../../constants';
import { fetchStrategyAll } from '../../../../store/actions/strategyAction';
import { fetchInvestInAssetDetails } from '../../../../store/actions/AssetDetailsAction';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchSellAsset } from '../../../../store/actions/portfolioTransactionAction';
// import IconMaintanance from '../../../../components/svg/IconMaintanance';
// import IconAsset from '../../../../components/svg/IconAsset';
// import AuthAsset from './AuthAsset';

const AssetDetailsModel = (props) => {
    // old 
    const { portfolioAssetId, strategy, setIsLoading, isGroup, show, portfolioGroupId, setPortfolioGroupId } = props;

    // buy asset And AuthAsset
    const dispatch = useDispatch()
    const { slug } = useParams();
    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(5); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)

    // 6-5-24 new change
    useEffect(() => {
        dispatch(fetchStrategyAll('all'))
    }, [])

    // 6-5-24 old change
    // useEffect(() => {
    //     show && dispatch(fetchStrategyAll('all'))
    // }, [show])

     // 6-5-24 new change
    useEffect(() => {
        slug && dispatch(fetchInvestInAssetDetails(setIsLoading, slug, setTotalRecords, setTotalPages, currentPage, perPage))
    }, [slug, currentPage, perPage]);

     // 6-5-24 old change
    // useEffect(() => {
    //     show && dispatch(fetchInvestInAssetDetails(setIsLoading, slug, setTotalRecords, setTotalPages, currentPage, perPage))
    // }, [show, currentPage, perPage]);
    // buy asset And AuthAsset End 



    // Sell Asset 
    // const [portfolioGroupId, setPortfolioGroupId] = useState([]);
    // pagination
    const [currentPageTow, setCurrentPageTow] = useState(Filters.PAGE);
    const [perPageTow, setPerPageTow] = useState(5); // Default per page value
    const [totalRecordsTow, setTotalRecordsTow] = useState(Filters.OBJ.totalRecords)
    const [totalPagesTow, setTotalPagesTow] = useState(Filters.OBJ.totalPages)


    useEffect(() => {
        portfolioGroupId.length && dispatch(fetchSellAsset(null, portfolioGroupId, currentPageTow, perPageTow, 'investedAsset', setTotalRecordsTow, setTotalPagesTow, setPerPageTow, setCurrentPageTow))
    }, [currentPageTow, perPageTow, portfolioAssetId])
    // Sell Asset End 

    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide} className='pt-4 pe-2 pb-2 ps-2'>
                <DialogContent className="sm:max-w-[90%] max-h-[95vh] overflow-auto gap-2 pt-4 pe-3 pb-2 ps-3" >
                    <DialogHeader className="mb-1">
                        <DialogTitle className="text-center">{isGroup ? 'Add Assets' : 'Asset Actions'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <Tabs defaultValue="Asset" className="w-[full] mt-3">
                            <div className="mb-3">
                                <TabsList className="flex items-center rounded-[12px] w-fit bg-[#DEDEDE] p-1">
                                    <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0 data-[state=active]:!bg-white data-[state=active]:!text-black" value="Asset">Buy Asset</TabsTrigger>
                                    <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0 data-[state=active]:!bg-white data-[state=active]:!text-black" value="Allocation-Monitoring">Sell Asset</TabsTrigger>
                                </TabsList>
                            </div>
                            <TabsContent value="Asset" >
                                <BuyAsset portfolioAssetId={portfolioAssetId} strategy={strategy} setIsLoading={setIsLoading} isGroup={isGroup} hideModel={props.onHide} show={show}
                                    currentPage={currentPage} setCurrentPage={setCurrentPage}
                                    perPage={perPage} setPerPage={setPerPage}
                                    totalRecords={totalRecords} setTotalRecords={setTotalRecords}
                                    totalPages={totalPages} setTotalPages={setTotalPages}
                                />
                            </TabsContent>
                            <TabsContent value="Allocation-Monitoring">
                                <Tabs defaultValue="Sell-Asset" className="w-[full] mt-3">
                                    <div className="mb-4">
                                        <TabsList className="inline-flex items-center hidden-scroll rounded-none justify-start bg-[unset] shadow-none p-0 gap-8 overflow-y-auto border-b border-[#C9C9C9]">
                                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6c7b90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Sell-Asset" ><span><IconAsset className="w-4 h-4" /></span>Invested Assets</TabsTrigger>
                                            <TabsTrigger className="!bg-transparent  pt-0 active:!text-white !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Auth-Asset"><span><IconMaintanance className="w-4 h-4" /></span> Authorised Assets</TabsTrigger>
                                        </TabsList>
                                    </div>
                                    <TabsContent value="Auth-Asset" className="m-0">
                                        <AuthAsset portfolioAssetId={portfolioAssetId} strategy={strategy} setIsLoading={setIsLoading} isGroup={isGroup} hideModel={props.onHide} show={show}
                                            currentPage={currentPage} setCurrentPage={setCurrentPage}
                                            perPage={perPage} setPerPage={setPerPage}
                                            totalRecords={totalRecords} setTotalRecords={setTotalRecords}
                                            totalPages={totalPages} setTotalPages={setTotalPages}
                                        />
                                    </TabsContent>
                                    <TabsContent value="Sell-Asset" className="m-0">
                                        <SellAsset hideModel={props.onHide}
                                            currentPage={currentPageTow} setCurrentPage={setCurrentPageTow}
                                            perPage={perPageTow} setPerPage={setPerPageTow}
                                            totalRecords={totalRecordsTow} setTotalRecords={setTotalRecordsTow}
                                            totalPages={totalPagesTow} setTotalPages={setTotalPagesTow}
                                            portfolioGroupId={portfolioGroupId} setPortfolioGroupId={setPortfolioGroupId}
                                        />
                                    </TabsContent>
                                </Tabs>
                                {/* <SellAsset hideModel={props.onHide} /> */}
                            </TabsContent>
                        </Tabs>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default AssetDetailsModel