import React, { useEffect, useState } from 'react'
import { CheckboxNew } from '../ui/CheckboxNew'
import { Input } from '../ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'
import { Button } from '../ui/button'
import { ChevronRight, Divide, Lock, Minus, Pen, PlusIcon, SquarePen } from 'lucide-react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordian'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown'
import { Trash } from 'lucide-react'
import Card from './Card'
import IconAdd from '../svg/IconAdd'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addAllocate } from '../../store/actions/AllocationAction'
import AllocationSubTableClonedUiRow from './AllocationSubTableClonedUiRow'
import ReactSelect, {
    components,
} from 'react-select'
import { Checkbox } from '../ui/checkbox'

const AllocationMainTableClonedUiRow = () => {
    const location = useLocation();
    const data = location.state?.updateData;
    console.log('data===========================>', data)
    const strategyOptions = location.state?.strategyOptions;
    const selectedName = location.state?.selectedName;
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [hasActionTaken, setHasActionTaken] = useState(false);
    // setAllocationData, setDataFetched

    const dispatch = useDispatch();

    // const getData = allocation.length && allocation.filter((item) => item._id === data.id)
    const [allocatedAssetsData, setAllocatedAssetsData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isError, setIsError] = useState(false);
    const [rowLock, setRowLock] = useState(false)

    // validation state
    const [error, setError] = useState({});
    const [totalAssetAllocation, setTotalAssetAllocation] = useState([])
    const [totalTa, setTotalTa] = useState([])
    const [totalTaa, setTotalTaa] = useState([])
    const [totalAssetSubAllocation, setTotalAssetSubAllocation] = useState([])
    const [totalTAA, setTotalTAA] = useState([])
    const [classAssetAllocation, setClassAssetAllocation] = useState([])
    const [subClassAssetAllocation, setSubClassAssetAllocation] = useState([])
    const [subAssetAllocation, setSubAssetAllocation] = useState([])
    const [somStrategyError, setSomStrategyError] = useState([])
    const [subSomStrategyError, setSubSomStrategyError] = useState([])
    // console.log('allStrategy', data)
    // console.log('allocatedAssetsData', allocatedAssetsData)

    // const assetOptions = data && data.allAsset?.length >= 1 ? data.allAsset?.map((item) => {
    //     return { value: item?.asset?._id, label: item?.asset?.assetName, data: item?.asset }
    // }) : []

    //     const [isOpen, setIsOpen] = useState(false)
    //     const handelClick = (data) => {
    //         setIsOpen(!isOpen)
    //         dispatch(fetchAllocationHistory(data.id))
    //    }

    //    useEffect(() => {
    //         console.log('isOpen<===============', isOpen)
    //    }, [])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (data?.updateData === undefined) {
            setIsLoading(true)
        } else {
            const startLoad = setTimeout(() => {
                setIsLoading(false)
            }, 1000);
        }
    }, [data?.updateData])

    useEffect(() => {
        if (isError === false) {
            setAllocatedAssetsData(data?.updateData !== undefined ? data?.updateData : [])
        }
    }, [data])

    const handleAddRow = () => {
        // const unmatchedAssets = data.allAsset.filter(assetOption => {
        //     return !allocatedAssetsData.some(item => item.asset?.value === assetOption?.value);
        // });
        const newRow = {
            allocation: data.id,
            isSubAssetAllocation: false,
            class: '',
            subClass: '',
            type: "Fixed Weight",
            SAA: 0,
            asset: null,
            strategy: { value: null, label: 'None' },
            SAALT: 0,
            isLock: false,
            SAAUT: 0,
            TAA: 0,
            TA: 0,
            TALT: 0,
            TAUT: 0,
            errorMessage: null,
            errorTALoverMessage: null,
            errorLoverMessage: null,
            errorUpperMessage: null,
            errorTAUpperMessage: null,
            validateLoverTolerance: [],
            validateTALoverTolerance: [],
            validateUpperTolerance: [],
            validateTAUpperTolerance: [],
            portfolioOptions: data.portfolioOptions,
            getAssetByPortfolio: [],
            authorizeAssets: data.authorizeAssets,
            // MULTI OP 
            // portfolioOpSelected: data?.portfolioOptions?.length ? ['All', ...data?.portfolioOptions?.map(item => item._id)] : [], // new add 9-5-24
            // single OP 
            portfolioOpSelected: null, // new add 11-5-24
        };
        setAllocatedAssetsData([...allocatedAssetsData, newRow]);

    };

    const handleAddAllocations = (e, index, item) => {
        const updatedAssetsData = [...allocatedAssetsData];
        handleUpdateRow(index, 'asset', null);
        handleUpdateRow(index, 'strategy', null);
        if (updatedAssetsData[index]) {
            const newRow = {
                allocation: data.id,
                isSubAssetAllocation: false,
                class: item.class?.length !== 0 ? item.class : (item.asset !== null || item.asset?.value !== undefined) ? item.asset.data.class : '',
                // subClass: item.subClass?.length !== 0 ? item.subClass : (item.asset !== null || item.asset?.value !== undefined) ? item.asset.data.subClass.name : '',
                subClass: item.subClass,
                type: "Fixed Weight",
                SAA: 0,
                asset: item.asset ? item.asset?.value === undefined ? null : item.asset : null,
                strategy: { value: null, label: 'None' },
                actualAllocation: 0,
                SAALT: 0,
                isLock: false,
                SAAUT: 0,
                TAA: 0,
                TA: 0,
                TALT: 0,
                TAUT: 0,
                errorMessage: null,
                errorTALoverMessage: null,
                errorLoverMessage: null,
                errorUpperMessage: null,
                errorTAUpperMessage: null,
                validateLoverTolerance: [],
                validateTALoverTolerance: [],
                validateUpperTolerance: [],
                validateTAUpperTolerance: [],
                portfolioOptions: data.portfolioOptions,
                getAssetByPortfolio: item?.getAssetByPortfolio,
                // multi op 
                // portfolioOpSelected: data?.portfolioOptions?.length ? ['All', ...data?.portfolioOptions?.map(item => item._id)] : [], // new add 9-5-24
                // single op 
                portfolioOpSelected: null, // new add 11-5-24
            };

            if (!updatedAssetsData[index].allocatedSubAssets) {
                updatedAssetsData[index].allocatedSubAssets = [];
            }

            updatedAssetsData[index].allocatedSubAssets.push(newRow);

            setSubClassAssetAllocation([])
            setAllocatedAssetsData(updatedAssetsData);
        }
    }

    const handleLock = (e, index, item) => {
        const updatedAssetsData = [...allocatedAssetsData];
        if (updatedAssetsData[index]) { // Check if the item at the specified index exists
            updatedAssetsData[index]['isLock'] = !updatedAssetsData[index]['isLock'];
            setAllocatedAssetsData(updatedAssetsData);

            // Validate total asset allocation in real-time
            // const totalAssetAllocationError = calculateTotalAssetAllocation();
            // const totalLowerToleranceError = calculateTotalLowerTolerance();
            // const totalUpperToleranceError = calculateTotalUpperTolerance();

            // Accumulate error messages
            let errors = {};
            setError(errors);

        }
        setRowLock(!rowLock)
    }

    const handleRemoveSelectedRows = () => {
        const updatedAssetsData = allocatedAssetsData.filter((item, index) => !selectedRows.includes(index));
        setAllocatedAssetsData(updatedAssetsData);
        setSelectedRows([]);
    };

    const handleRemoveSelectedSubRows = (index, item) => {
        const updatedAssetsData = allocatedAssetsData.map((item, parentIndex) => {
            if (item?.allocatedSubAssets && index === parentIndex) {
                const updatedSubAssets = item?.allocatedSubAssets.filter((subItem, subIndex) => {
                    // Check if the sub-row is in the selectedSubRows array
                    const isSelected = selectedSubRows.some(selectedItem => (
                        selectedItem.sub === subIndex && selectedItem.parents === parentIndex
                    ));
                    return !isSelected;
                });
                if (updatedSubAssets.length) {
                    return { ...item, allocatedSubAssets: updatedSubAssets };
                } else {
                    // If there are no remaining sub-assets, set allocatedSubAssets to null and unlock the parent
                    return { ...item, allocatedSubAssets: null, isLock: false };
                }
            } else {
                return item;
            }
        });

        setAllocatedAssetsData(updatedAssetsData);
        setSelectedSubRows([]);
    };


    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        let isValidForm = false;
        var getAssetError = []


        // const updatedAssetsData = [...allocatedAssetsData];
        // const getSomeError = validateDuplicateValues(updatedAssetsData);

        allocatedAssetsData.forEach((item, index) => {
            if (item.allocatedSubAssets && item.allocatedSubAssets.length >= 1) {
                // Your logic when allocatedSubAssets has items
                item.allocatedSubAssets.map((subItem, subIndex) => {
                    if (subItem.asset === null || subItem.asset.value === undefined) {
                        getAssetError.push({ parent: index, sub: subIndex })
                        return true
                    }
                })
                // console.log(`allocatedSubAssets length for item ${item._id}: ${item.allocatedSubAssets.length}`);
            } else {
                // if (item.errorMessage !== null) {
                //     isValidForm = true;
                //     handleUpdateRow(index, 'errorMessage', 'This assets are already allocated');
                //     isValid = false;
                // }
            }
        });
        setSubAssetAllocation(getAssetError)

        var classError = []
        var subClassError = []
        var subSubClassError = []
        const validationClassErrors = allocatedAssetsData.map((item, index) => {
            if (item.class.length === 0) {
                classError.push(index)
                return true
            }
            return null
        });
        const classValidationErrors = validationClassErrors.some(error => error !== null);
        if (classValidationErrors) {
            setClassAssetAllocation(classError)
            setIsError(true)
            isValid = false;
        } else (
            setClassAssetAllocation([])
        )
        const validationSubClassErrors = allocatedAssetsData.map((item, index) => {
            if (item.allocatedSubAssets?.length) {
                const data = item.allocatedSubAssets.map((items, subindex) => {
                    if (items.subClass.length === 0) {
                        subSubClassError.push({ parent: index, sub: subindex })
                        return subindex
                    }
                })
                return subClassError = data
            }
            return null
        });

        const subClassValidationErrors = validationSubClassErrors.flat().some(error => error !== null && error !== undefined);
        if (subClassValidationErrors) {
            setSubClassAssetAllocation([...subSubClassError])
            setIsError(true)
            isValid = false;
        } else {
            setSubClassAssetAllocation([])
        }

        // const totalLowerToleranceError = calculateTotalLowerTolerance();
        // const totalUpperToleranceError = calculateTotalUpperTolerance();
        const totalAssetAllocationError = calculateTotalAssetAllocation();
        const validationError = validateTolerance(allocatedAssetsData);
        const validationErrorTA = validateToleranceTA(allocatedAssetsData);
        const totalTAError = calculateTotalTA();
        const totalTAAError = calculateTotalTAA()

        const validationErrors = allocatedAssetsData.map((item, index) => validateTotalAssetAllocation(item, index));
        const validationTAErrors = allocatedAssetsData.map((item, index) => validateTotalTA(item, index));
        const validationTAAErrors = allocatedAssetsData.map((item, index) => validateTotalTAA(item, index));
        const validationSubErrors = allocatedAssetsData.map((item, index) => validateSubTotalAssetAllocation(item.allocatedSubAssets, index));
        const validationSubTAErrors = allocatedAssetsData.map((item, index) => validateSubTotalTAUT(item.allocatedSubAssets, index));
        setTotalAssetAllocation(validationErrors)
        setTotalTa(validationTAErrors)
        setTotalTaa(validationTAAErrors)
        setTotalAssetSubAllocation(validationSubErrors)
        setTotalTAA(validationSubTAErrors)
        // Check if there are any validation errors

        const hasValidationErrors = validationErrors.some(error => error !== null);
        const hasValidationTAErrors = validationTAErrors.some(error => error !== null);
        const hasValidationTAAErrors = validationTAAErrors.some(error => error !== null);
        const hasValidationSubErrors = validationSubErrors.flat().some(error => error !== null);
        const hasValidationSubTSErrors = validationSubTAErrors.flat().some(error => error !== null);
        if (hasValidationErrors) {
            setIsError(true)
            isValid = false;
        }
        if (hasValidationTAErrors) {
            setIsError(true)
            isValid = false;
        }
        if (hasValidationTAAErrors) {
            setIsError(true)
            isValid = false;
        }
        if (hasValidationSubErrors) {
            setIsError(true)
            isValid = false;
        }
        if (hasValidationSubTSErrors) {
            setIsError(true)
            isValid = false;
        }

        if (validationError?.length) {
            validationError.map((item) => {
                if (item !== null) {
                    setIsError(true)
                    isValidForm = false
                    isValid = false;
                }
            })
        }
        if (validationErrorTA?.length) {
            validationErrorTA.map((item) => {
                if (item !== null) {
                    setIsError(true)
                    isValidForm = false
                    isValid = false;
                }
            })
        }
        if (getAssetError.length) {
            setIsError(true)
            isValidForm = false
            isValid = false;
        }
        // if (isValidForm) {
        //     errors['allAsset'] = 'Your selected assets are already allocated';
        //     setIsError(true)
        //     isValidForm = false
        //     isValid = false;
        // }
        if (totalAssetAllocationError.length) {
            errors['SAA'] = 'Total asset SAA must be 100%';
            setIsError(true)
            isValid = false;
        }
        if (totalTAError.length) {
            errors['TA'] = 'Total TA must be 100%';
            setIsError(true)
            isValid = false;
        }
        if (totalTAAError.length) {
            errors['TAA'] = 'Total TAA must be 0%';
            setIsError(true)
            isValid = false;
        }
        // if (getSomeError.duplicateErrors.length) {
        //     setIsError(true)
        //     isValid = false;
        // }
        // if (getSomeError.duplicateSubErrors.length) {
        //     setIsError(true)
        //     isValid = false;
        // }
        // if (somStrategyError.length) {
        //     setIsError(true)
        //     isValid = false;
        // }
        // if (subSomStrategyError.length) {
        //     setIsError(true)
        //     isValid = false;
        // }
        // console.log('error classValidationErrors===>', classValidationErrors)
        // console.log('error subClassValidationErrors===>', subClassValidationErrors)
        // console.log('error hasValidationErrors===>', hasValidationErrors)
        // console.log('error hasValidationSubErrors===>', hasValidationSubErrors)
        // console.log('error validationError===>', validationError?.length)
        // console.log('error isValidForm===>', isValidForm)
        // console.log('error  totalAssetAllocationError.length===>',  totalAssetAllocationError.length)
        // if (totalLowerToleranceError) {
        //     errors['LowerTolerance'] = 'Total asset Lower Tolerance must be 100%';
        //     setIsError(true)
        //     isValid = false;
        // }
        // if (totalUpperToleranceError) {
        //     errors['UpperTolerance'] = 'Total asset Upper Tolerance must be 100%';
        //     setIsError(true)
        //     isValid = false;
        // }

        // Lower Tolerance
        setError(errors);
        return isValid;
    };


    const handleSave = () => {
        var indicesWithNullAsset = indicesWithNullAsset = allocatedAssetsData.map((item, index) => {
            if (item.allocatedSubAssets && item.allocatedSubAssets.length >= 1) {
                // Your logic when allocatedSubAssets has items
                handleUpdateRow(index, 'errorMessage', null);
                // console.log(`allocatedSubAssets length for item ${item._id}: ${item.allocatedSubAssets.length}`);
            } else {
                return (item.asset === null || item.asset.value === undefined ? index : undefined)
            }
        }).filter(index => index !== undefined);
        if (indicesWithNullAsset.length >= 1) {
            indicesWithNullAsset.forEach((item) => {
                handleUpdateRow(item, 'errorMessage', 'Please Select Assets');
            })
        } else {
            if (!allocatedAssetsData.length > 0) {
                const prepareData = {
                    allocation: data.id,
                    allocatedAssets: []
                }
                // console.log('frist====>', prepareData)
                dispatch(addAllocate(prepareData, null, null));

                setSelectedRows([]);
            } else if (handleValidation()) {

                setIsError(false)
                // console.log('allocatedAssetsData', allocatedAssetsData)
                const prepareData = {
                    allocation: data.id,
                    allocatedAssets: allocatedAssetsData.map((item) => {
                        const { portfolioOpSelected, getAssetByPortfolio, authorizeAssets, portfolioOptions, errorMessage, isLock, errorLoverMessage, errorUpperMessage, validateLoverTolerance, validateTALoverTolerance, errorTALoverMessage, errorTAUpperMessage, validateTAUpperTolerance, validateUpperTolerance, TA, ...newItem } = item;

                        if (item.asset?.value !== undefined && item.asset !== null) {
                            return {
                                ...newItem, portfolio: portfolioOpSelected !== null ? portfolioOpSelected?._id : null, strategy: item.strategy?.value, asset: item.asset.value, type: "Fixed Weight", class: item?.asset?.data?.class, subClass: item?.asset?.data?.subClass
                                , allocatedSubAssets: item.allocatedSubAssets
                                    ? item.allocatedSubAssets.map((subItem) => ({
                                        id: subItem?.id ? subItem.id : '',
                                        strategy: subItem.strategy?.value,
                                        asset: subItem.asset.value,
                                        subClass: subItem.subClass?.length ? subItem.subClass : subItem.asset?.data.subClass,
                                        type: subItem.type,
                                        SAALT: subItem.SAALT,
                                        SAAUT: subItem.SAAUT,
                                        SAA: subItem.SAA,
                                        TAA: subItem.TAA,
                                        // portfolio: subItem.portfolioOpSelected !== null ? subItem.portfolioOpSelected?._id : null,
                                        // TA: subItem.TA,
                                        TALT: subItem.TALT,
                                        TAUT: subItem.TAUT,
                                    }))
                                    : [],
                                isSubAssetAllocation: item.allocatedSubAssets?.length ? true : false
                            };
                        } else {
                            return {
                                ...newItem, portfolio: portfolioOpSelected !== null ? portfolioOpSelected?._id : null, strategy: item.strategy?.value, asset: null, type: "Fixed Weight", class: item?.class, subClass: item?.subClass
                                , allocatedSubAssets: item.allocatedSubAssets
                                    ? item.allocatedSubAssets.map((subItem) => ({
                                        id: subItem?.id ? subItem.id : '',
                                        asset: subItem.asset.value,
                                        strategy: subItem.strategy?.value,
                                        subClass: subItem.subClass?.length ? subItem.subClass : subItem.asset?.data.subClass,
                                        type: subItem.type,
                                        SAALT: subItem.SAALT,
                                        SAAUT: subItem.SAAUT,
                                        SAA: subItem.SAA,
                                        TAA: subItem.TAA,
                                        // portfolio: subItem.portfolioOpSelected !== null ? subItem.portfolioOpSelected?._id : null,
                                        // TA: subItem.TA,
                                        TALT: subItem.TALT,
                                        TAUT: subItem.TAUT,
                                    }))
                                    : [],
                                isSubAssetAllocation: item.allocatedSubAssets?.length ? true : false
                            };
                        }
                    })
                };
                // console.log('prepareData', prepareData)
                // console.log('second====>', prepareData)


                function validateAssetForStrategy(allocatedAssets) {
                    const allAllocatedAsset = allocatedAssets.map((allocatedAsset, index) => {

                        if (!allocatedAsset.isSubAssetAllocation) {
                            return { ...allocatedAsset, indexNum: index }
                        } else {
                            let formatAllocatedAubAsset = allocatedAsset.allocatedSubAssets.map((allocatedSubAsset, subIndex) => {
                                return { ...allocatedSubAsset, parents: index, sub: subIndex }
                            })

                            // formatAllocatedAubAsset = formatAllocatedAubAsset.filter(x => Boolean(x))
                            return formatAllocatedAubAsset

                        }
                    })

                    let flattenAllocatedAssets = allAllocatedAsset.flat(Infinity)

                    flattenAllocatedAssets = flattenAllocatedAssets.filter(x => Boolean(x))

                    const filterSameStrategyAllocatedAsset = findObjectsWithSameStrategy(flattenAllocatedAssets)

                    return filterSameStrategyAllocatedAsset
                }

                function findObjectsWithSameStrategy(objects) {
                    const assetStrategyCount = {}; // to store the count of each asset and strategy combination
                    const result = [];

                    // Count occurrences of each asset and strategy combination
                    objects.forEach((obj) => {
                        const asset = obj.asset || null;
                        const strategy = obj.strategy || null;
                        const key = `${asset}-${strategy}`;
                        assetStrategyCount[key] = (assetStrategyCount[key] || 0) + 1;
                    });

                    // Filter objects with asset and strategy count greater than 1
                    objects.forEach((obj) => {
                        const asset = obj.asset;
                        const strategy = obj.strategy;
                        const key = `${asset}-${strategy}`;
                        if (assetStrategyCount[key] > 1) {
                            result.push(obj);
                            // assetStrategyCount[key] -= 1; // Decrement count to keep track of all duplicates
                        }
                    });

                    return result;
                }

                const duplicateAssets = validateAssetForStrategy(prepareData.allocatedAssets)


                // if (duplicateSubErrors.length > 0) {
                //     setSubSomStrategyError(duplicateSubErrors.map((error) => ({ parent: error.parent, sub: error.sub })));
                //     // Optionally, you can handle errors here (e.g., set an error state)
                // }

                // if (duplicateErrors.length > 0) {
                //     const getIndex = duplicateErrors.map((error) => error.index)
                //     setSomStrategyError(getIndex);
                //     // Optionally, you can handle errors here (e.g., set an error state)
                // }


                if (duplicateAssets.length) {
                    let getDuplicateAssetsSub = duplicateAssets.map((item => {
                        if (typeof item.sub !== undefined || typeof item.sub !== null) {
                            return ({ parent: item.parents, sub: item.sub })
                        }
                    }))
                    let getDuplicateAssets = duplicateAssets.map((item => {
                        if (typeof item.indexNum !== undefined || typeof item.indexNum !== null) {
                            return item.indexNum
                        }
                        return undefined
                    }))
                    setSubSomStrategyError(getDuplicateAssetsSub.filter(items => Boolean(items)))
                    setSomStrategyError(getDuplicateAssets.filter(item => item !== undefined))
                } else {
                    dispatch(addAllocate(prepareData, null, null));
                    setSomStrategyError([])
                    setSubSomStrategyError([])
                    setSelectedRows([]);
                    setSelectedSubRows([])
                }
            }
        }

    };

    const handleUpdateRow = (index, field, value, isError) => {
        const updatedAssetsData = [...allocatedAssetsData];
        // setSubSomStrategyError([])
        // setSomStrategyError([])
        if (updatedAssetsData[index]) { // Check if the item at the specified index exists

            if (field === 'class' && updatedAssetsData[index].allocatedSubAssets?.length) {
                updatedAssetsData[index].allocatedSubAssets.map((item, subIndex) => {
                    handleUpdateSubRows(index, subIndex, field, value)
                })
            }
            // if(field === 'subClass'){
            //     setSubClassAssetAllocation([])
            // }
            if (field === 'subClass' && updatedAssetsData[index].allocatedSubAssets?.length) {
                updatedAssetsData[index].allocatedSubAssets.map((item, subIndex) => {
                    handleUpdateSubRows(index, subIndex, field, value)
                })
            }
            if (field === 'subClass') {
                setSubClassAssetAllocation([])
            }
            if (!isError) {
                updatedAssetsData.map((item, index) => {
                    updatedAssetsData[index]['errorLoverMessage'] = null;
                    updatedAssetsData[index]['validateLoverTolerance'] = [];
                    updatedAssetsData[index]['errorUpperMessage'] = null;
                    updatedAssetsData[index]['validateUpperTolerance'] = [];
                    updatedAssetsData[index]['errorTALoverMessage'] = null;
                    updatedAssetsData[index]['validateTALoverTolerance'] = [];
                    updatedAssetsData[index]['errorTAUpperMessage'] = null;
                    updatedAssetsData[index]['validateTAUpperTolerance'] = [];
                    if (item.allocatedSubAssets) {
                        item.allocatedSubAssets.map((item, subIndex) => {
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorLoverMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorUpperMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateLoverTolerance'] = [];
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateUpperTolerance'] = [];
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorTALoverMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorTAUpperMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateTALoverTolerance'] = [];
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateTAUpperTolerance'] = [];
                        })
                    }
                })
            }



            updatedAssetsData[index][field] = value;
            setAllocatedAssetsData(updatedAssetsData);

            // if(field === 'SAA'|| field === 'TAA'){
            //     updatedAssetsData[index]['TA'] = updatedAssetsData[index]['SAA'] + Number(updatedAssetsData[index]['TAA'])
            // }
            // Validate total asset allocation in real-time
            // const totalAssetAllocationError = calculateTotalAssetAllocation();
            // const totalLowerToleranceError = calculateTotalLowerTolerance();
            // const totalUpperToleranceError = calculateTotalUpperTolerance();

            if (field === 'strategy' || field === 'asset') {
                // Validate for duplicate asset and strategy values
                // const duplicateErrors = validateDuplicateValues(updatedAssetsData);

                // if (duplicateErrors.length > 0) {
                //     console.error("Validation Errors:");
                //     duplicateErrors.forEach((error) => {
                //         console.error(error.message);
                //         setSomStrategyError((prevErrors) => [...prevErrors, error.index]);
                //     });
                //     // Optionally, you can handle errors here (e.g., set an error state)
                // } else {
                //     console.log("No validation errors found.");
                //     // Continue with your logic when no validation errors are present
                // }
            }
            // Accumulate error messages
            setTotalAssetAllocation([])
            setTotalTaa([])
            setTotalTa([])
            let errors = {};
            setError(errors);

        }
    };

    // console.log('somStrategyError', somStrategyError)
    // console.log('somStrategyError', subSomStrategyError)

    const handleUpdateSubRows = (index, rowIndex, field, value, isError) => {
        const updatedAssetsData = [...allocatedAssetsData];
        // setSubSomStrategyError([])
        // setSomStrategyError([])
        if (field === 'class' && updatedAssetsData[index].allocatedSubAssets && updatedAssetsData[index].allocatedSubAssets[rowIndex].asset?.data?.class) {
            updatedAssetsData[index].allocatedSubAssets[rowIndex].asset.data.class = value
        }
        if (field === 'subClass' && updatedAssetsData[index].allocatedSubAssets && updatedAssetsData[index].allocatedSubAssets[rowIndex].asset?.data?.subClass) {
            updatedAssetsData[index].allocatedSubAssets[rowIndex].asset.data.subClass = value
        }
        if (field === 'subClass') {
            setSubClassAssetAllocation([])
            updatedAssetsData[index][field] = ""
        }
        if (updatedAssetsData[index].allocatedSubAssets && updatedAssetsData[index].allocatedSubAssets[rowIndex]) {
            if (!isError) {
                updatedAssetsData.map((item, index) => {
                    updatedAssetsData[index]['errorLoverMessage'] = null;
                    updatedAssetsData[index]['validateLoverTolerance'] = [];
                    updatedAssetsData[index]['errorUpperMessage'] = null;
                    updatedAssetsData[index]['validateUpperTolerance'] = [];
                    updatedAssetsData[index]['errorTALoverMessage'] = null;
                    updatedAssetsData[index]['validateTALoverTolerance'] = [];
                    updatedAssetsData[index]['errorTAUpperMessage'] = null;
                    updatedAssetsData[index]['validateTAUpperTolerance'] = [];
                    if (item.allocatedSubAssets) {
                        item.allocatedSubAssets.map((item, subIndex) => {
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorLoverMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorUpperMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateLoverTolerance'] = [];
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateUpperTolerance'] = [];
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorTALoverMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['errorTAUpperMessage'] = null;
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateTALoverTolerance'] = [];
                            updatedAssetsData[index].allocatedSubAssets[subIndex]['validateTAUpperTolerance'] = [];
                        })
                    }
                })
            }
            updatedAssetsData[index].allocatedSubAssets[rowIndex][field] = value;
            setAllocatedAssetsData(updatedAssetsData);
            if (field === 'strategy' || field === 'asset') {
                // Validate for duplicate asset and strategy values
                // const duplicateErrors = validateDuplicateValues(updatedAssetsData);

                // if (duplicateErrors.length > 0) {
                //     console.error("Validation Errors:");
                //     duplicateErrors.forEach((error) => {
                //         console.error(error.message);
                //         setSomStrategyError((prevErrors) => [...prevErrors, error.index]);
                //     });
                //     // Optionally, you can handle errors here (e.g., set an error state)
                // } else {
                //     console.log("No validation errors found.");
                //     // Continue with your logic when no validation errors are present
                // }
            }

        } else {
            return false;
        }
        if (field === 'SAA' && updatedAssetsData[index].isLock === false) {
            const sum = updatedAssetsData[index].allocatedSubAssets.reduce(
                (total, items) => total + items.SAA, 0);
            updatedAssetsData[index].SAA = sum;
            updatedAssetsData[index].TA = updatedAssetsData[index].SAA + updatedAssetsData[index].TAA;
        }
        if (field === 'TAA' && updatedAssetsData[index].isLock === false) {
            const sum = updatedAssetsData[index].allocatedSubAssets.reduce(
                (total, items) => Number(total) + Number(items.TAA), 0);
            updatedAssetsData[index].TAA = Number(sum);
            updatedAssetsData[index].TA = updatedAssetsData[index].SAA + updatedAssetsData[index].TAA;
        }

        // Accumulate error messages
        setTotalAssetAllocation([])
        setTotalTaa([])
        setTotalTa([])
        let errors = {};
        setError(errors);

    };

    const onChangeAssetOption = (selectedOption, { action }, index) => {

        // const isClassAlreadySelected = allocatedAssetsData.filter((item, indexNum) => indexNum === index)[0]
        const isClassAlreadySelected = allocatedAssetsData.some(
            (item, i) => i === index && item.class?.length === 0
        );
        const isSubClassAlreadySelected = allocatedAssetsData.some(
            (item, i) => i === index && item.subClass?.length === 0
        );
        if (action === 'select-option') {
            const assetData = selectedOption ? selectedOption : null;
            // Check if the selected asset is already allocated in another row
            const isAssetAlreadySelected = allocatedAssetsData.some(
                (item, i) => i !== index && item.asset?.value === assetData?.value
            );

            allocatedAssetsData.map((item, index) => {
                handleUpdateRow(index, 'errorMessage', null);
            })

            if (isAssetAlreadySelected) {
                handleUpdateRow(index, 'asset', assetData);
                if (isClassAlreadySelected) {
                    handleUpdateRow(index, 'class', assetData.data.class);
                }
                if (isSubClassAlreadySelected) {
                    handleUpdateRow(index, 'subClass', assetData.data.subClass);
                }
            } else {
                handleUpdateRow(index, 'asset', assetData);
                if (isClassAlreadySelected) {
                    handleUpdateRow(index, 'class', assetData.data.class);
                }
                if (isSubClassAlreadySelected) {
                    handleUpdateRow(index, 'subClass', assetData.data.subClass);
                }
                handleUpdateRow(index, 'errorMessage', null);
            }
        } else if (action === 'clear') {
            handleUpdateRow(index, 'asset', null);
        }
    };


    const onChangeAssetSubOption = (selectedOption, { action }, index, subRowIndex) => {
        if (action === 'select-option') {
            const assetData = selectedOption ? selectedOption : null;
            setSubAssetAllocation([])
            // Check if the selected asset is already allocated in another row
            const isAssetAlreadySelected = allocatedAssetsData.some(
                (item, i) => i !== index && item.asset?.value === assetData?.value
            );

            const updatedAssetsData = [...allocatedAssetsData];
            if (updatedAssetsData[index].allocatedSubAssets[subRowIndex] && updatedAssetsData[index].allocatedSubAssets[subRowIndex]['class'].length !== 0) {
                assetData.data.class = updatedAssetsData[index].allocatedSubAssets[subRowIndex]['class']
            }
            if (updatedAssetsData[index].allocatedSubAssets[subRowIndex] && updatedAssetsData[index].allocatedSubAssets[subRowIndex]['subClass'].length !== 0) {
                assetData.data.subClass = updatedAssetsData[index].allocatedSubAssets[subRowIndex]['subClass']
            }
            if (isAssetAlreadySelected) {
                handleUpdateSubRows(index, subRowIndex, 'asset', assetData);
            } else {
                handleUpdateSubRows(index, subRowIndex, 'asset', assetData);
                handleUpdateSubRows(index, subRowIndex, 'errorMessage', null);
            }
        } else if (action === 'clear') {
            handleUpdateSubRows(index, subRowIndex, 'asset', null);
        }
    };


    const onChangeStrategySubOption = (selectedOption, { action }, index, subRowIndex) => {
        if (action === 'select-option') {
            setSomStrategyError([])
            setSubSomStrategyError([])
            const strategyData = selectedOption ? selectedOption : null;
            // Check if the selected asset is already allocated in another row
            const isAssetAlreadySelected = allocatedAssetsData.some(
                (item, i) => i !== index && item.strategy?.value === strategyData?.value
            );
            if (isAssetAlreadySelected) {
                handleUpdateSubRows(index, subRowIndex, 'strategy', strategyData);
            } else {
                handleUpdateSubRows(index, subRowIndex, 'strategy', strategyData);
                handleUpdateSubRows(index, subRowIndex, 'errorMessage', null);
            }
        } else if (action === 'clear') {
            handleUpdateSubRows(index, subRowIndex, 'strategy', null);
        }
    };

    const onChangeStrategyOption = (selectedOption, { action }, index) => {
        if (action === 'select-option') {
            setSomStrategyError([])
            setSubSomStrategyError([])
            const strategyData = selectedOption ? selectedOption : null;

            // Check if the selected asset is already allocated in another row
            const isAssetAlreadySelected = allocatedAssetsData.some(
                (item, i) => i !== index && item.strategy?.value === strategyData?.value
            );

            allocatedAssetsData.map((item, index) => {
                handleUpdateRow(index, 'errorMessage', null);
            })

            if (isAssetAlreadySelected) {
                handleUpdateRow(index, 'strategy', strategyData);
            } else {
                handleUpdateRow(index, 'strategy', strategyData);
                handleUpdateRow(index, 'errorMessage', null);
            }
        } else if (action === 'clear') {
            handleUpdateRow(index, 'strategy', null);
        }
    };

    const handleToggleRow = (index) => {
        // Check if the row is already selected, and toggle its selection

        const updatedSelectedRows = selectedRows.includes(index)
            ? selectedRows.filter((rowIndex) => rowIndex !== index)
            : [...selectedRows, index];

        setSelectedRows(updatedSelectedRows);
    };

    const [selectedSubRows, setSelectedSubRows] = useState([]);
    const handleToggleSubRow = (index, subIndex) => {
        // Check if the row is already selected
        const isRowSelected = selectedSubRows.some(item => item.sub === subIndex && item.parents === index);

        // If the row is selected, remove it; otherwise, add it
        const updatedSelectedRows = isRowSelected
            ? selectedSubRows.filter(item => !(item.sub === subIndex && item.parents === index))
            : [...selectedSubRows, { sub: subIndex, parents: index }];

        setSelectedSubRows(updatedSelectedRows);
    };



    // // authorizeAllocate
    // const authorize = (type) => {
    //     dispatch(authorizeAllocate(data.id, type, setAllocationData, setDataFetched))
    // }

    const calculateTotalAssetAllocation = () => {
        const total = allocatedAssetsData.reduce((sum, item) => sum + item.SAA, 0);
        return total !== 100 ? 'Total SAA must be 100%' : '';
    };

    const calculateTotalTA = () => {
        const total = allocatedAssetsData.reduce((sum, item) => Number(sum) + Number(item.TA), 0);
        return total !== 100 ? 'Total TA must be 100%' : '';
    };

    const calculateTotalTAA = () => {
        const total = allocatedAssetsData.reduce((sum, item) => {
            let TAA = Number(item.TAA) || 0
            return Number(sum) + TAA
        }, 0);
        return total !== 0 ? 'Total TA must be 0%' : '';
    };

    const validateTotalAssetAllocation = (data, index) => {
        const isLock = data.isLock;
        const mainAssetAllocation = data.SAA;

        if (data.type === "Fixed Weight" && data.allocatedSubAssets?.length) {
            const subAssetAllocations = data.allocatedSubAssets?.map(subAsset => subAsset.SAA);
            const sumSubAssetAllocations = subAssetAllocations?.reduce((acc, value) => Number(acc) + Number(value), 0);

            if (mainAssetAllocation !== sumSubAssetAllocations) {
                return index;
            }
        }

        return null; // No validation error
    };

    const validateTotalTA = (data, index) => {
        const isLock = data.isLock;
        const mainAssetAllocation = Number(data.TA) || 0;

        if (data.type === "Fixed Weight" && data.allocatedSubAssets?.length) {
            const subAssetAllocations = data.allocatedSubAssets?.map(subAsset => subAsset.TA);
            const sumSubAssetAllocations = subAssetAllocations?.reduce((acc, value) => Number(acc) + Number(value), 0);

            if (mainAssetAllocation !== sumSubAssetAllocations) {
                return index;
            }
        }

        return null; // No validation error
    };
    const validateTotalTAA = (data, index) => {
        const isLock = data.isLock;
        const mainAssetAllocation = Number(data.TAA) || 0;

        if (data.type === "Fixed Weight" && data.allocatedSubAssets?.length) {
            const subAssetAllocations = data.allocatedSubAssets?.map(subAsset => subAsset.TAA);
            const sumSubAssetAllocations = subAssetAllocations?.reduce((acc, value) => Number(acc) + Number(value), 0);

            if (mainAssetAllocation !== sumSubAssetAllocations) {
                return index;
            }
        }

        return null; // No validation error
    };

    const validateSubTotalTAUT = (data, index) => {
        // Assuming data is an array of objects
        if (data?.length) {
            return data.map((item, subIndex) => {
                const { TA, TALT, TAUT } = item;
                var errorStore = []
                if (TALT > TA && TAUT < TA) {
                    errorStore.push(subIndex)
                    handleUpdateSubRows(index, subIndex, 'errorTALoverMessage', 'TALT should be less than TA', true)
                    handleUpdateSubRows(index, subIndex, 'validateTALoverTolerance', errorStore, true)
                    handleUpdateSubRows(index, subIndex, 'errorTAUpperMessage', 'TAUT should be more than TA', true)
                    handleUpdateSubRows(index, subIndex, 'validateTAUpperTolerance', errorStore, true)
                    return {
                        ...item,
                        validateTALoverTolerance: errorStore,
                        validateTAUpperTolerance: errorStore,
                    };
                }
                if (TALT > TA) {
                    errorStore.push(subIndex)
                    handleUpdateSubRows(index, subIndex, 'errorTALoverMessage', 'TALT should be less than TA', true)
                    handleUpdateSubRows(index, subIndex, 'validateTALoverTolerance', errorStore, true)
                    return {
                        ...item,
                        validateTALoverTolerance: errorStore,
                    };
                } else {
                    handleUpdateSubRows(index, subIndex, 'errorTALoverMessage', null, true)
                    handleUpdateSubRows(index, subIndex, 'validateTALoverTolerance', [], true)
                }

                if (TAUT < TA) {
                    errorStore.push(subIndex)
                    handleUpdateSubRows(index, subIndex, 'errorTAUpperMessage', 'TAUT should be more than TAA', true)
                    handleUpdateSubRows(index, subIndex, 'validateTAUpperTolerance', errorStore, true)
                    return {
                        ...item,
                        validateTAUpperTolerance: errorStore,
                    };
                } else {
                    handleUpdateSubRows(index, subIndex, 'errorTAUpperMessage', null, true)
                    handleUpdateSubRows(index, subIndex, 'validateTAUpperTolerance', [], true)
                }

                //  If no validation error is found, return null or an empty string
                return null;

            });
        }

        return null
    }


    const validateSubTotalAssetAllocation = (data, index) => {
        // Assuming data is an array of objects
        if (data?.length) {
            return data.map((item, subIndex) => {
                const { SAA, SAALT, SAAUT } = item;
                var errorStore = []
                if (SAALT > SAA && SAAUT < SAA) {
                    errorStore.push(subIndex)
                    handleUpdateSubRows(index, subIndex, 'errorLoverMessage', 'SAALT should be less than SAA', true)
                    handleUpdateSubRows(index, subIndex, 'validateLoverTolerance', errorStore, true)
                    handleUpdateSubRows(index, subIndex, 'errorUpperMessage', 'SAAUT should be more than SAA', true)
                    handleUpdateSubRows(index, subIndex, 'validateUpperTolerance', errorStore, true)
                    return {
                        ...item,
                        validateLoverTolerance: errorStore,
                        validateUpperTolerance: errorStore,
                    };
                }
                if (SAALT > SAA) {
                    errorStore.push(subIndex)
                    handleUpdateSubRows(index, subIndex, 'errorLoverMessage', 'SAALT should be less than SAA', true)
                    handleUpdateSubRows(index, subIndex, 'validateLoverTolerance', errorStore, true)
                    return {
                        ...item,
                        validateLoverTolerance: errorStore,
                    };
                } else {
                    handleUpdateSubRows(index, subIndex, 'errorLoverMessage', null, true)
                    handleUpdateSubRows(index, subIndex, 'validateLoverTolerance', [], true)
                }

                if (SAAUT < SAA) {
                    errorStore.push(subIndex)
                    handleUpdateSubRows(index, subIndex, 'errorUpperMessage', 'SAAUT should be more than SAA', true)
                    handleUpdateSubRows(index, subIndex, 'validateUpperTolerance', errorStore, true)
                    return {
                        ...item,
                        validateUpperTolerance: errorStore,
                    };
                } else {
                    handleUpdateSubRows(index, subIndex, 'errorUpperMessage', null, true)
                    handleUpdateSubRows(index, subIndex, 'validateUpperTolerance', [], true)
                }

                //  If no validation error is found, return null or an empty string
                return null;

            });
        }

        return null
    }



    const validateTolerance = (data) => {
        // Assuming data is an array of objects
        return data.map((item, index) => {
            const { SAA, SAALT, SAAUT } = item;
            var errorStore = []
            if (SAALT > SAA && SAAUT < SAA) {
                errorStore.push(index)
                handleUpdateRow(index, 'errorLoverMessage', 'SAALT should be less than SAA', true);
                handleUpdateRow(index, 'validateLoverTolerance', errorStore, true);
                handleUpdateRow(index, 'errorUpperMessage', 'SAAUT should be more than SAA', true);
                handleUpdateRow(index, 'validateUpperTolerance', errorStore, true);
                return {
                    ...item,
                    validateLoverTolerance: errorStore,
                    validateUpperTolerance: errorStore,
                };
            }
            if (SAALT > SAA) {
                errorStore.push(index)
                handleUpdateRow(index, 'errorLoverMessage', 'SAALT should be less than SAA', true);
                handleUpdateRow(index, 'validateLoverTolerance', errorStore, true);
                return {
                    ...item,
                    validateLoverTolerance: errorStore,
                };
            } else {
                handleUpdateRow(index, 'errorLoverMessage', null, true);
                handleUpdateRow(index, 'validateLoverTolerance', [], true);
            }

            if (SAAUT < SAA) {
                errorStore.push(index)
                handleUpdateRow(index, 'errorUpperMessage', 'SAAUT should be more than SAA', true);
                handleUpdateRow(index, 'validateUpperTolerance', errorStore, true);
                return {
                    ...item,
                    validateUpperTolerance: errorStore,
                };
            } else {
                handleUpdateRow(index, 'errorUpperMessage', null, true);
                handleUpdateRow(index, 'validateUpperTolerance', [], true);
            }

            //  If no validation error is found, return null or an empty string
            return null;

        });
    };

    const validateToleranceTA = (data) => {
        // Assuming data is an array of objects
        return data.map((item, index) => {
            const { TA, TALT, TAUT } = item;
            var errorStore = []
            if (TALT > TA && TAUT < TA) {
                errorStore.push(index)
                handleUpdateRow(index, 'errorTALoverMessage', 'TALT should be less than TA', true);
                handleUpdateRow(index, 'validateTALoverTolerance', errorStore, true);
                handleUpdateRow(index, 'errorTAUpperMessage', 'TAUT should be more than TA', true);
                handleUpdateRow(index, 'validateTAUpperTolerance', errorStore, true);
                return {
                    ...item,
                    validateTALoverTolerance: errorStore,
                    validateTAUpperTolerance: errorStore,
                };
            }
            if (TALT > TA) {
                errorStore.push(index)
                handleUpdateRow(index, 'errorTALoverMessage', 'TALT should be less than TA', true);
                handleUpdateRow(index, 'validateTALoverTolerance', errorStore, true);
                return {
                    ...item,
                    validateTALoverTolerance: errorStore,
                };
            } else {
                handleUpdateRow(index, 'errorTALoverMessage', null, true);
                handleUpdateRow(index, 'validateTALoverTolerance', [], true);
            }

            if (TAUT < TA) {
                errorStore.push(index)
                handleUpdateRow(index, 'errorTAUpperMessage', 'TAUT should be more than TA', true);
                handleUpdateRow(index, 'validateTAUpperTolerance', errorStore, true);
                return {
                    ...item,
                    validateTAUpperTolerance: errorStore,
                };
            } else {
                handleUpdateRow(index, 'errorTAUpperMessage', null, true);
                handleUpdateRow(index, 'validateTAUpperTolerance', [], true);
            }

            //  If no validation error is found, return null or an empty string
            return null;
        });
    };


    const subTotalSAA = allocatedAssetsData?.reduce((acc, item) => acc + item.SAA, 0);
    // Define styles based on the totalAllocation value
    const totalSAAClass = subTotalSAA === 100 ? 'text-green-600' : subTotalSAA > 100 ? 'text-red' : 'text-yellow-600';


    const maxSAA = 100;
    const remainingSAA = maxSAA - subTotalSAA;


    const subTotalTAA = allocatedAssetsData?.reduce((acc, item) => Number(acc) + Number(item.TAA), 0);
    // Define styles based on the totalAllocation value
    const totalTAAClass = subTotalTAA === 0 ? 'text-green-600' : subTotalTAA > 0 ? 'text-red' : 'text-yellow-600';
    const maxTAA = 0;
    const remainingTAA = Number(maxTAA) - Number(subTotalTAA);


    const subTotalTA = allocatedAssetsData?.reduce((acc, item) => acc + item.TA, 0);
    // Define styles based on the totalAllocation value
    const totalTAClass = subTotalTA === 100 ? 'text-green-600' : subTotalTA > 100 ? 'text-red' : 'text-yellow-600';
    const maxTA = 100;
    const remainingTA = maxTA - subTotalTA;

    const remainingSAAClass = remainingSAA === 0 ? 'text-green-600' : remainingSAA > 0 ? 'text-red' : 'text-yellow-600';
    const remainingTAAClass = remainingTAA === 0 ? 'text-green-600' : remainingTAA > 0 ? 'text-red' : 'text-yellow-600';
    const remainingTAClass = remainingTA === 0 ? 'text-green-600' : remainingTA > 0 ? 'text-red' : 'text-yellow-600';

    // multi select portfolio 
    const onCheckedPortfoliosBox = (props, selectedOp, options, indexNumber) => {
        const updatedAssetsData = [...allocatedAssetsData]; // Assuming allocatedAssetsData is defined somewhere

        // Check if the props.data exists and its _id is 'All'
        const isAllSelected = props?.data?._id === 'All';

        // Get the default portfolioOpSelected based on the condition
        const getDefaultPortfolioOpSelected = updatedAssetsData[indexNumber]['portfolioOpSelected'];

        if (isAllSelected) {
            let getOp;
            if (getDefaultPortfolioOpSelected.includes('All')) {
                getOp = [];
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOp, false);
            } else {
                getOp = options?.map((item) => item?._id);
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOp, false);
            }
            return getOp;
        } else {
            const removeAllOp = options.filter((item) => item._id !== 'All');
            // If not 'All', filter options and get the selected _id
            const selectedOption = removeAllOp?.find(option => option._id === props?.data?._id);

            const prepareOp = getDefaultPortfolioOpSelected.filter((item) => item !== 'All');
            var getOps;
            const alreadyExit = prepareOp?.find(option => option === selectedOption?._id);
            if (alreadyExit) {
                getOps = getDefaultPortfolioOpSelected.includes('All') ? prepareOp.filter(op => op === alreadyExit) : prepareOp.filter(op => op !== alreadyExit);
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOps, false);
            } else {
                if (prepareOp.length === removeAllOp.length) {
                    getOps = [selectedOption._id];
                } else {
                    getOps = [...prepareOp, selectedOption._id];
                }
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOps, false);
            }
            return getOps
        }
    }

    const onCheckedSubPortfoliosBox = (props, selectedOp, options, indexNumber, subRowIndex) => {
        const updatedAssetsData = [...allocatedAssetsData]; // Assuming allocatedAssetsData is defined somewhere

        // Check if the props.data exists and its _id is 'All'
        const isAllSelected = props?.data?._id === 'All';

        // Get the default portfolioOpSelected based on the condition
        const getDefaultPortfolioOpSelected = updatedAssetsData[indexNumber]['portfolioOpSelected'];

        if (isAllSelected) {
            let getOp;
            if (getDefaultPortfolioOpSelected.includes('All')) {
                getOp = [];
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOp, false);
            } else {
                getOp = options?.map((item) => item?._id);
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOp, false);
            }
            return getOp;
        } else {
            const removeAllOp = options.filter((item) => item._id !== 'All');
            // If not 'All', filter options and get the selected _id
            const selectedOption = removeAllOp?.find(option => option._id === props?.data?._id);

            const prepareOp = getDefaultPortfolioOpSelected.filter((item) => item !== 'All');
            var getOps;
            const alreadyExit = prepareOp?.find(option => option === selectedOption?._id);
            if (alreadyExit) {
                getOps = getDefaultPortfolioOpSelected.includes('All') ? prepareOp.filter(op => op === alreadyExit) : prepareOp.filter(op => op !== alreadyExit);
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOps, false);
            } else {
                if (prepareOp.length === removeAllOp.length) {
                    getOps = [selectedOption._id];
                } else {
                    getOps = [...prepareOp, selectedOption._id];
                }
                // Update the row with the new selected options
                handleUpdateRow(indexNumber, 'portfolioOpSelected', getOps, false);
            }
            return getOps
        }
    }

    const OptionPortfolios = ({ children, ...props }) => {
        const { onCheckedPortfoliosBox, selectedOp, indexNumber, options } = props.selectProps;
        return (
            <components.Control {...props}>
                <div className="flex p-2 align-middle justify-start items-center text-sm w-full" onClick={() => onCheckedPortfoliosBox(props, selectedOp, options, indexNumber)}>
                    <span className="me-2"><Checkbox
                        checked={selectedOp?.length ? selectedOp?.includes(props?.data?._id) : false}
                        aria-label="Select row"
                        // 
                        className="translate-y-[2px] border-black rounded-[4px]" /></span>
                    {/* {children} */}
                    {props?.data?.name}
                </div>
            </components.Control>
        );
    };
    // multi select portfolio end

    // single select portfolio 
    const SingleOptionPortfolios = (data, action, index) => {
        const updatedAssetsData = [...allocatedAssetsData];
        const getAssetByPortfolio = updatedAssetsData[index].authorizeAssets && updatedAssetsData[index].authorizeAssets && updatedAssetsData[index].authorizeAssets.filter((items => items?.portfolio === data._id))
        const prepareAssetData = getAssetByPortfolio?.length ? getAssetByPortfolio.map((item) => {
            return { label: item.asset.assetName, value: item.asset._id, data: item }
        }) : []
        // change all getAssetByPortfolio by sub asset
        const updateAllocatedSubAssets = updatedAssetsData[index]?.allocatedSubAssets?.length ? updatedAssetsData[index].allocatedSubAssets.map((items) => {
            return { ...items, asset: null, getAssetByPortfolio: prepareAssetData }
        }) : updatedAssetsData[index].allocatedSubAssets
        // change parents options 
        updatedAssetsData[index]['portfolioOpSelected'] = { _id: data._id, label: data.name };
        updatedAssetsData[index]['asset'] = null;
        updatedAssetsData[index]['getAssetByPortfolio'] = prepareAssetData;
        updatedAssetsData[index]['allocatedSubAssets'] = updateAllocatedSubAssets;


        setAllocatedAssetsData(updatedAssetsData);

    }
    const SingleOptionPortfoliosSub = (data, action, index, subIndex) => {
        const updatedAssetsData = [...allocatedAssetsData];
        updatedAssetsData[index].allocatedSubAssets[subIndex]['portfolioOpSelected'] = { _id: data._id, label: data.name };
        setAllocatedAssetsData(updatedAssetsData);

    }
    // single select portfolio end


    console.log('allocatedAssetsData========================>', allocatedAssetsData)

    return (

        <Card>
            <div className="allocation-tablelike-ui-wrapper">
                <div className="allocation-table-cloned-action flex items-center justify-start gap-3 mb-4">
                    {/* bg-green-500 border-[bg-green-500] shadow-sm text-white */}
                    <Button variant="" size="sm" className="min-w-[100px] " onClick={handleAddRow}>
                        <span><IconAdd className="w-4 h-4" /></span>
                        Add Target
                    </Button>
                    {/* bg-rose-500 border-[bg-rose-500] shadow-sm text-white */}
                    <Button variant="outline" size="sm" className="min-w-[100px] !border-red !text-red hover:bg-[#F5F5F5]" onClick={() => handleRemoveSelectedRows()}>
                        <span><Trash strokeWidth={"2.2"} className='w-4 h-4' /></span>
                        Delete
                    </Button>
                    {/* bg-blue-500 border-[bg-blue-500] shadow-sm text-white */}
                    <Button variant="outline" size="sm" className="min-w-[100px] hover:bg-[#F5F5F5]" onClick={() => handleSave()}>
                        Save
                    </Button>
                </div>
                {/* update  */}
                {/*    overflow-visible */}
                <div className=" pb-2 mb-4 overflow-x-auto overflow-y-auto">
                    <div className="min-w-[1260px]">
                        <div className="allocation-table-cloned-header mb-3">
                            {/* overflow-hidden */}
                            <div className="flex items-center gap-2 border bg-[#F9F9F9] border-[#C4CEDC] p-1.5 overflow-hidden">
                                <div className="w-[4%] py-1.5  flex items-center justify-center">
                                </div>
                                <div className="w-[10%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>Portfolio</h5>
                                </div>
                                <div className="w-[10%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>Class</h5>
                                </div>
                                <div className="w-[10%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>Sub Class</h5>
                                </div>
                                <div className="w-[10%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>Asset</h5>
                                </div>
                                <div className="w-[10%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>Strategy</h5>
                                </div>
                                <div className="w-[12%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>Allocation Type</h5>
                                </div>
                                <div className="w-[6.50%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>SAA%</h5>
                                </div>
                                <div className="w-[7%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>SAA LT%</h5>
                                </div>
                                <div className="w-[7%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>SAA UT%</h5>
                                </div>
                                <div className="w-[7%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>TAA%</h5>
                                </div>
                                <div className="w-[6.50%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>TA%</h5>
                                </div>
                                <div className="w-[6.50%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>TLT%</h5>
                                </div>
                                <div className="w-[6.50%] py-1">
                                    <h5 className='text-xs text-textV1 text-center'>TUT%</h5>
                                </div>
                                <div className="w-[6.50%] py-1.5 px-1.5">
                                    <h5 className='text-xs text-textV1 text-end'>Actions</h5>
                                </div>
                            </div>
                        </div>
                        {/* {console.log('allocatedAssetsData', allocatedAssetsData)} */}
                        {isLoading ? '' : allocatedAssetsData.map((item, index) => (
                            <div className="allocation-table-cloned-body mb-3 last:mb-0" key={item.id || item._id}>
                                {/* overflow-hidden */}
                                <div className={` border border-[#D7D7D7]  overflow-hidden rounded-sm ${item.allocatedSubAssets?.length ? "bg-backgroundV1" : ""}`}>
                                    {/* overflow-hidden */}
                                    <div className={`${selectedRows.includes(index) ? "bg-backgroundV1" : ""} p-1.5 flex items-center gap-2 overflow-hidden `}>
                                        <div className="w-[4%]  flex items-center justify-center">
                                            <CheckboxNew checked={selectedRows.includes(index)} onCheckedChange={() => handleToggleRow(index)} className="w-5 h-5 rounded-[6px] bg-white" />
                                        </div>
                                        {/* portfolio portions  */}
                                        <div className={`w-[10%]  ${!item.allocatedSubAssets?.length ? '' : 'lockInput '}`}>
                                            {/* {!item.allocatedSubAssets?.length ? */}
                                            {/* <> */}
                                            {/* <ReactSelect
                                                        components={{ Option: OptionPortfolios }}
                                                        isSearchable={false}
                                                        selectedOp={item?.portfolioOpSelected}
                                                        indexNumber={index}
                                                        inputValue=""
                                                        value={null}
                                                        closeMenuOnSelect={false}
                                                        // hideSelectedOptions={true}
                                                        onCheckedPortfoliosBox={onCheckedPortfoliosBox}
                                                        placeholder='Select Portfolio'
                                                        className='Portfolio_multi_select'
                                                        name="Portfolio"
                                                        options={item?.portfolioOptions}
                                                        styles={{
                                                            menuList: (base) => ({
                                                                ...base,
                                                                fontSize: "10px",
                                                                overflow: "auto",
                                                                maxHeight: "180px",
                                                                lineHeight: '1rem',
                                                                fontWeight: 500,
                                                                borderRadius: '0',
                                                                backgroundColor: 'transparent'

                                                            }),
                                                            menu: (base) => ({
                                                                ...base,
                                                                overflow: "auto",
                                                                margin: 0,
                                                                padding: 0,
                                                                width: '200px',
                                                                borderRadius: '0',

                                                            }),
                                                            input: (base) => ({
                                                                ...base,
                                                                width: '40%',
                                                            }),
                                                            container: (base) => ({
                                                                ...base,
                                                                width: '150px',
                                                            }),
                                                        }}
                                                    /> */}

                                            <Select
                                                onValueChange={(e) => SingleOptionPortfolios(JSON.parse(e), { action: 'select-option' }, index)}
                                                defaultValue={item?.portfolioOpSelected !== null ? item.portfolioOpSelected : null}
                                            >
                                                <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-3 bg-white text-start" >
                                                    <SelectValue className='truncate text-start' >
                                                        {item?.portfolioOpSelected !== null ? item?.portfolioOpSelected?.name : 'Select Portfolio....'}
                                                    </SelectValue>
                                                </SelectTrigger>
                                                <SelectContent side="bottom" className="min-w-[100px]">
                                                    {item?.portfolioOptions?.map((items) => (
                                                        <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items?._id} value={JSON.stringify(items)}>
                                                            {items?.name}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            {/* <div className={error['allAsset'] || item.errorMessage !== null ? "text-red text-[10px]" : ''}>{error['allAsset'] || item.errorMessage !== null ? error['allAsset'] || item.errorMessage : null}</div> */}
                                            {/* </> */}
                                            {/* : <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" readOnly />} */}
                                        </div>

                                        <div className="w-[10%]">
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" name='class' placeholder='class' value={item?.class ? item?.class : item?.asset?.data?.class} onChange={(e) => {
                                                if (item.asset?.data === undefined || item.asset === null) {
                                                    setClassAssetAllocation([])
                                                    handleUpdateRow(index, 'class', e.target.value);
                                                } else {
                                                    setClassAssetAllocation([])
                                                    const updatedAssetData = { ...item.asset.data };
                                                    updatedAssetData.class = e.target.value;
                                                    handleUpdateRow(index, 'class', e.target.value);
                                                    handleUpdateRow(index, 'asset', {
                                                        value: item.asset.value,
                                                        label: item.asset.label,
                                                        data: updatedAssetData,
                                                    });
                                                }
                                            }} />
                                            {classAssetAllocation.includes(index) && <div className="text-red text-[10px]">Class Name Is Empty</div>}
                                        </div>
                                        <div className="w-[10%]">
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" name='subClass' placeholder='subClass' value={item?.subClass ? item?.subClass : ''} style={{ cursor: "initial" }} onChange={(e) => {
                                                if (item.asset?.data === undefined || item.asset === null) {
                                                    handleUpdateRow(index, 'subClass', e.target.value);
                                                } else {
                                                    const updatedAssetData = { ...item.asset.data };
                                                    updatedAssetData.subClass = e.target.value;
                                                    handleUpdateRow(index, 'subClass', e.target.value);
                                                    handleUpdateRow(index, 'asset', {
                                                        value: item.asset.value,
                                                        label: item.asset.label,
                                                        data: updatedAssetData,
                                                    });
                                                }
                                            }} />
                                        </div>

                                        <div className={`w-[10%]  ${!item.allocatedSubAssets?.length ? '' : 'lockInput '}`}>
                                            {!item.allocatedSubAssets?.length && item.getAssetByPortfolio?.length ?
                                                <>
                                                    <Select onValueChange={(e) => onChangeAssetOption(JSON.parse(e), { action: 'select-option' }, index)}
                                                        defaultValue={item?.asset !== null ? item.asset : null}

                                                    >
                                                        <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
                                                            <SelectValue className='truncate'>
                                                                {item?.asset !== null ? item.asset?.label : 'Select....'}
                                                            </SelectValue>
                                                        </SelectTrigger>
                                                        <SelectContent side="bottom" className="min-w-[100px]">
                                                            {/* {data.allAsset.map((items) => (
                                                                <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items.value} value={JSON.stringify(items)}>
                                                                    {items.label}
                                                                </SelectItem>
                                                            ))} */}
                                                            {item.getAssetByPortfolio.map((items) => (
                                                                <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items.value} value={JSON.stringify(items)}>
                                                                    {items.label}
                                                                </SelectItem>
                                                            ))}
                                                        </SelectContent>
                                                    </Select>  </>
                                                : <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" readOnly placeholder={item.getAssetByPortfolio?.length ? ' ' : 'Not Option'} />}
                                            <div className={error['allAsset'] || item.errorMessage !== null ? "text-red text-[10px]" : ''}>{error['allAsset'] || item.errorMessage !== null ? error['allAsset'] || item.errorMessage : null}</div>
                                        </div>
                                        <div className={`w-[10%]  ${!item.allocatedSubAssets?.length ? '' : 'lockInput '} `}>
                                            {!item.allocatedSubAssets?.length ? <>
                                                <Select onValueChange={(e) => onChangeStrategyOption(JSON.parse(e), { action: 'select-option' }, index)} defaultValue={item.strategy}>
                                                    <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
                                                        <SelectValue className='truncate'>
                                                            {item.strategy?.label}
                                                        </SelectValue>
                                                    </SelectTrigger>
                                                    <SelectContent side="bottom" className="">
                                                        {data.allStrategy.map((items) => (
                                                            <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items.value} value={JSON.stringify(items)}>
                                                                {items.label}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectContent>
                                                </Select>
                                                <div className={somStrategyError.includes(index) ? "text-red text-[10px]" : ''}>{somStrategyError.includes(index) ? 'The selected asset and strategy already exist.' : null}</div>
                                            </> : <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" readOnly />}
                                        </div>
                                        <div className="w-[12%]">
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" readOnly={true} name='allocationType' placeholder='allocationType' value={'Fixed Weight'} onChange={(e) => {
                                                // const updatedAssetData = { ...item.asset.data };
                                                // updatedAssetData.subCategory.subCategoryName = e.target.value;
                                                handleUpdateRow(index, 'allocationType', e.target.value);
                                                // handleUpdateRow(index, 'asset', {
                                                //     value: item.asset.value,
                                                //     label: item.asset.label,
                                                //     data: updatedAssetData,
                                                // });
                                            }} />
                                        </div>
                                        <div className={`w-[6.50%] ${item.isLock ? 'lockInput' : ""}`}>
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" type="number" readOnly={item.isLock} name="SAA"
                                                placeholder="SAA"
                                                aria-label="SAA"
                                                value={item.SAA}
                                                onChange={(e) => {
                                                    // Validate the input value to be within the range of 0 to 100
                                                    let newValue = parseInt(e.target.value, 10);
                                                    if (isNaN(newValue)) {
                                                        // If the value is not a number, set it to 0
                                                        newValue = 0;
                                                    } else {
                                                        // Ensure the value is within the range of 0 to 100
                                                        newValue = Math.min(100, Math.max(0, newValue));
                                                    }
                                                    handleUpdateRow(index, 'SAA', Number(newValue));
                                                    handleUpdateRow(index, 'TA', Number(newValue) + Number(item.TAA));
                                                }} />
                                            {error['SAA'] ? (<div className={error['SAA'] ? "text-red text-[10px]" : ''}>{error['SAA'] ? error['SAA'] : null}</div>) : totalAssetAllocation.includes(index) ? (
                                                <div className="text-red text-[10px]">{'Total SAA must be 100%'}</div>
                                            ) : null}
                                        </div>
                                        <div className="w-[7%]">
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" type="number" name="SAALT"
                                                placeholder="SAALT"
                                                aria-label="SAALT"
                                                value={item.SAALT}
                                                onChange={(e) => {
                                                    // Validate the input value to be within the range of 0 to 100
                                                    let newValue = parseInt(e.target.value, 10);
                                                    if (isNaN(newValue)) {
                                                        // If the value is not a number, set it to 0
                                                        newValue = 0;
                                                    } else {
                                                        // Ensure the value is within the range of 0 to 100
                                                        newValue = Math.min(100, Math.max(0, newValue));
                                                    }
                                                    handleUpdateRow(index, 'SAALT', Number(newValue));
                                                    handleUpdateRow(index, 'errorLoverMessage', null);
                                                    handleUpdateRow(index, 'validateLoverTolerance', []);
                                                }} />
                                            <div className={item.validateLoverTolerance.includes(index) ? "text-red text-[10px]" : ''}>{item.validateLoverTolerance.includes(index) ? item.errorLoverMessage : null}</div>
                                        </div>
                                        <div className="w-[7%]">
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" type="number" name="SAAUT"
                                                style={{ cursor: "initial" }}
                                                placeholder="SAAUT"
                                                aria-label="SAAUT"
                                                value={item.SAAUT}
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => {
                                                    // Validate the input value to be within the range of 0 to 100
                                                    let newValue = parseInt(e.target.value, 10);
                                                    if (isNaN(newValue)) {
                                                        // If the value is not a number, set it to 0
                                                        newValue = 0;
                                                    } else {
                                                        // Ensure the value is within the range of 0 to 100
                                                        newValue = Math.min(100, Math.max(0, newValue));
                                                    }
                                                    handleUpdateRow(index, 'SAAUT', Number(newValue));
                                                    handleUpdateRow(index, 'errorUpperMessage', null);
                                                    handleUpdateRow(index, 'validateUpperTolerance', []);
                                                }} />
                                            <div className={item.validateUpperTolerance.includes(index) ? "text-red text-[10px]" : ''}>{item.validateUpperTolerance.includes(index) ? item.errorUpperMessage : null}</div>
                                        </div>
                                        <div className={`w-[7%]  ${item.isLock ? 'lockInput' : ""}`}>
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" type="number" readOnly={item.isLock}
                                                name="TAA"
                                                placeholder="TAA"
                                                aria-label="TAA"
                                                value={item.TAA}
                                                onChange={(e) => {
                                                    // Validate the input value to be within the range of 0 to 100
                                                    // let newValue = parseInt(e.target.value, 10);
                                                    let newValue = e.target.value
                                                    if (isNaN(newValue)) {
                                                        // If the value is not a number, set it to 0
                                                        // newValue = 0;
                                                    } else {
                                                        // Ensure the value is within the range of 0 to 100
                                                        // newValue = Math.min(100, Math.max(0, newValue));
                                                    }
                                                    // Check if the entered value is a valid number
                                                    // if (/^-?\d*\.?\d*$/.test(newValue)) {
                                                    //     // If it's a valid number, update the state
                                                    //     handleUpdateRow(index, 'TAA', newValue);
                                                    //     handleUpdateRow(index, 'TA', Number(item.SAA) + Number(newValue));
                                                    // } else {
                                                    //     // If it's not a valid number, don't update the state
                                                    //     // You may also want to display an error message or handle it differently
                                                    //     console.log("Invalid input. Please enter a valid number.");
                                                    // }
                                                    handleUpdateRow(index, 'TAA', newValue);
                                                    handleUpdateRow(index, 'TA', Number(item.SAA) + Number(newValue));
                                                }} />

                                            {error['TAA'] ? (<div className={error['TAA'] ? "text-red text-[10px]" : ''}>{error['TAA'] ? error['TAA'] : null}</div>) : totalTaa.includes(index) ? (
                                                <div className="text-red text-[10px]">{'Total TAA must be 0%'}</div>
                                            ) : null}
                                        </div>
                                        <div className={`w-[6.50%] ${item.isLock ? 'lockInput' : ""}`}>
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" type="number"
                                                readOnly={true}
                                                name="TA"
                                                placeholder="TA"
                                                aria-label="TA"
                                                value={item.TA}
                                                onChange={(e) => {
                                                    // Validate the input value to be within the range of 0 to 100
                                                    let newValue = parseInt(e.target.value, 10);
                                                    if (isNaN(newValue)) {
                                                        // If the value is not a number, set it to 0
                                                        newValue = 0;
                                                    } else {
                                                        // Ensure the value is within the range of 0 to 100
                                                        newValue = Math.min(100, Math.max(0, newValue));
                                                    }
                                                    handleUpdateRow(index, 'TA', Number(newValue));
                                                }} />
                                            {error['TA'] ? (<div className={error['TA'] ? "text-red text-[10px]" : ''}>{error['TA'] ? error['TA'] : null}</div>) : totalTa.includes(index) ? (
                                                <div className="text-red text-[10px]">{'Total TA must be 100%'}</div>
                                            ) : null}
                                        </div>
                                        {/* TA LT%  */}
                                        <div className="w-[6.50%]">
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" type="number" name="TALT"
                                                // readOnly={item.isLock}
                                                style={{ cursor: "initial" }}
                                                placeholder="TALT"
                                                aria-label="TALT"
                                                value={item.TALT}
                                                aria-describedby="basic-addon2"
                                                onChange={(e) => {
                                                    // Validate the input value to be within the range of 0 to 100
                                                    let newValue = parseInt(e.target.value, 10);
                                                    if (isNaN(newValue)) {
                                                        // If the value is not a number, set it to 0
                                                        newValue = 0;
                                                    } else {
                                                        // Ensure the value is within the range of 0 to 100
                                                        newValue = Math.min(100, Math.max(0, newValue));
                                                    }
                                                    handleUpdateRow(index, 'TALT', Number(newValue));
                                                    handleUpdateRow(index, 'errorTALoverMessage', null);
                                                    handleUpdateRow(index, 'validateTALoverTolerance', []);
                                                }} />
                                            <div className={item?.validateTALoverTolerance?.includes(index) ? "text-red text-[10px]" : ''}>{item?.validateTALoverTolerance?.includes(index) ? item.errorLoverMessage : null}</div>
                                        </div>
                                        {/* TA UT%  */}
                                        <div className="w-[6.50%]">
                                            <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" type="number" name="TAUT"
                                                // readOnly={item.isLock}
                                                placeholder="TAUT"
                                                aria-label="TAUT"
                                                value={item.TAUT}
                                                onChange={(e) => {
                                                    // Validate the input value to be within the range of 0 to 100
                                                    let newValue = parseInt(e.target.value, 10);
                                                    if (isNaN(newValue)) {
                                                        // If the value is not a number, set it to 0
                                                        newValue = 0;
                                                    } else {
                                                        // Ensure the value is within the range of 0 to 100
                                                        newValue = Math.min(100, Math.max(0, newValue));
                                                    }
                                                    handleUpdateRow(index, 'TAUT', Number(newValue));
                                                    handleUpdateRow(index, 'errorTAUpperMessage', null);
                                                    handleUpdateRow(index, 'validateTAUpperTolerance', []);
                                                }} />
                                            <div className={item?.validateTAUpperTolerance?.includes(index) ? "text-red text-[10px]" : ''}>{item?.validateTAUpperTolerance?.includes(index) ? item.errorTAUpperMessage : null}</div>
                                        </div>
                                        {/* action */}
                                        <div className="w-[6.50%] px-1.5 flex items-center justify-end">
                                            {
                                                !item.allocatedSubAssets?.length ?
                                                    (
                                                        <Button variant="outline" className="!border-theme hover:bg-[#F5F5F5]" size="sm" onClick={(e) => handleAddAllocations(e, index, item)}>
                                                            <Divide className="h-4 w-4 !text-theme" />
                                                        </Button>
                                                    ) :
                                                    (
                                                        <DropdownMenu>
                                                            <DropdownMenuTrigger asChild>
                                                                <Button variant="outline" className="!border-theme hover:bg-[#F5F5F5]" size="sm">
                                                                    <SquarePen strokeWidth={"2.2"} className="h-4 w-4 !text-theme" />
                                                                </Button>
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent align="end">
                                                                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={(e) => handleAddAllocations(e, index, item)}> <span className='w-5 h-5 bg-green-500/10 flex items-center justify-center rounded-[4px]'><PlusIcon strokeWidth={"2.2"} className='text-green-500 w-4 h-4' /> </span>  Add Sub Allocation</DropdownMenuItem>
                                                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => handleRemoveSelectedSubRows(index, item)}> <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'><Minus strokeWidth={"2.2"} className='text-red w-4 h-4' /> </span>  Remove Sub Allocation</DropdownMenuItem>
                                                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={(e) => handleLock(e, index, item)}> <span className='w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]'><Lock strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> </span> {!item.isLock ? "Lock Allocation" : "Unlock Allocation"}</DropdownMenuItem>
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                    )
                                            }
                                        </div>
                                    </div>
                                    {
                                        item?.allocatedSubAssets?.length ?
                                            <>
                                                <div className="">
                                                    <Accordion defaultValue={null} type="single" collapsible className="w-full p-1.5 pb-0">
                                                        <AccordionItem value="item-1" className="border-none p-0 accordian-custom">
                                                            <AccordionTrigger className="border-none p-0 pb-2" hasIcon={false}>
                                                                <div className="allocation-table-cloned-header w-full">
                                                                    <div className="flex items-center gap-2 overflow-hidden">
                                                                        <div className="w-[4%] py-1.5  flex items-center justify-center">
                                                                            <ChevronRight className='w-4 h-4 icon-chev transition-transform duration-300' />
                                                                        </div>
                                                                        <div className="w-[10%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'></h5>
                                                                        </div>
                                                                        <div className="w-[10%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>Class</h5>
                                                                        </div>
                                                                        <div className="w-[10%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>Sub Class</h5>
                                                                        </div>
                                                                        <div className="w-[10%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>Asset</h5>
                                                                        </div>
                                                                        <div className="w-[10%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>Strategy</h5>
                                                                        </div>
                                                                        <div className="w-[12%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>Allocation Type</h5>
                                                                        </div>
                                                                        <div className="w-[6.50%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>SAA%</h5>
                                                                        </div>
                                                                        <div className="w-[7%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>SAA LT%</h5>
                                                                        </div>
                                                                        <div className="w-[7%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>SAA UT%</h5>
                                                                        </div>
                                                                        <div className="w-[7%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>TAA%</h5>
                                                                        </div>
                                                                        <div className="w-[6.50%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>TA%</h5>
                                                                        </div>
                                                                        <div className="w-[6.50%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>TLT%</h5>
                                                                        </div>
                                                                        <div className="w-[6.50%] py-1">
                                                                            <h5 className='text-xs text-textV1 text-center'>TUT%</h5>
                                                                        </div>
                                                                        <div className="w-[6.50%] py-1.5 px-1.5">
                                                                            <h5 className='text-xs text-textV1 text-end'>Allocation</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </AccordionTrigger>
                                                            <AccordionContent className="px-0 border-t-0 pt-0">
                                                                <AllocationSubTableClonedUiRow item={item} index={index} totalTaa={totalTaa} totalTa={totalTa} subSomStrategyError={subSomStrategyError} onChangeStrategySubOption={onChangeStrategySubOption} strategyOptions={strategyOptions} subAssetAllocation={subAssetAllocation} totalAssetSubAllocation={totalAssetSubAllocation} totalTAA={totalTAA} totalAssetAllocation={totalAssetAllocation} selectedRows={selectedSubRows} subClassAssetAllocation={subClassAssetAllocation} handleToggleRow={handleToggleSubRow} error={error} onChangeAssetOption={onChangeAssetSubOption} data={data} handleUpdateRow={handleUpdateRow} handleUpdateSubRows={handleUpdateSubRows} allocatedAssetsData={allocatedAssetsData} onCheckedSubPortfoliosBox={onCheckedSubPortfoliosBox} SingleOptionPortfoliosSub={SingleOptionPortfoliosSub} />
                                                            </AccordionContent>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </div>
                                            </>
                                            : ''
                                    }
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                {/* update end  */}
                <div className="flex items-center justify-between">
                    <div className="">
                        <ul>
                            <li className='flex items-center gap-1 text-sm mb-2 last:mb-0'>
                                <span>SAA% : </span><span className={totalSAAClass}>{subTotalSAA}%</span>
                            </li>
                            <li className='flex items-center gap-1 text-sm mb-2 last:mb-0'>
                                <span>TAA% : </span><span className={totalTAAClass}>{subTotalTAA}%</span>
                            </li>
                            <li className='flex items-center gap-1 text-sm mb-2 last:mb-0'>
                                <span>TA% : </span><span className={totalTAClass}>{subTotalTA}%</span>
                            </li>
                        </ul>
                    </div>
                    <div className="">
                        <ul>
                            <li className='flex items-center gap-1 text-sm mb-2 last:mb-0'>
                                <span>Remaining SAA% : </span><span className={remainingSAAClass}>{remainingSAA}%</span>
                            </li>
                            <li className='flex items-center gap-1 text-sm mb-2 last:mb-0'>
                                <span>Remaining  TAA% : </span><span className={remainingTAAClass}>{remainingTAA}%</span>
                            </li>
                            <li className='flex items-center gap-1 text-sm mb-2 last:mb-0'>
                                <span>Remaining  TA% : </span><span className={remainingTAClass}>{remainingTA}%</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </Card>


    )
}

export default AllocationMainTableClonedUiRow

// select
// onChange={null}
// value={selectedOption.value || ''}
// options={options}
// placeholder={"Select an option"}
// const handleInputChange = (e) => {
//     setSelectedOption(e);
// };
/*
useEffect(()=>{
    if(!selectedOption){
        return;
        }
        let newAllocations= [...allocations];
        newAllocations[index].allocation_type= selectedOption.label;
        props.setAllocations(newAllocations);
        },[selectedOption])*/
// </s>
/*
*
*   Add allocation type to the table row
* 
>


{/* <Select/> */
// onValueChange={(e) => onChangeAssetOption(JSON.parse(e), { action: 'select-option' }, index)}
// defaultValue={item?.selectedPortFolio !== null ? item.selectedPortFolio : null}

// >
{/* <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white hidden" >
        <SelectValue className='truncate' > */}
{/* {item?.selectedPortFolio !== null ? item?.selectedPortFolio?.label : 'Select Portfolio....'} */ }
{/* {'Select Portfolio....'} */ }
{/* </SelectValue>
    </SelectTrigger>
    <SelectContent side="bottom" className="min-w-[100px]">
        {item?.portfolioOptions?.map((items) => (
            <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items?._id} value={JSON.stringify(items)}>
                {items?.name}
            </SelectItem>
        ))}
    </SelectContent>
</Select>  <div className={error['allAsset'] || item.errorMessage !== null ? "text-red text-[10px]" : ''}>{error['allAsset'] || item.errorMessage !== null ? error['allAsset'] || item.errorMessage : null}</div> */}

// <div className="allocation-table-cloned-body">
// <div className={`mb-2 last:mb-0 border border-[#D7D7D7] ${hasActionTaken ? "bg-backgroundV1" : ""}`}>
//     <div className={`${checked ? "bg-backgroundV1" : ""} p-1.5 flex items-center gap-2 overflow-hidden`}>
//         <div className="w-[4%]  flex items-center justify-center">
//             <CheckboxNew checked={checked} onCheckedChange={setChecked} className="w-5 h-5 rounded-[6px] bg-white" />
//         </div>
//         <div className="w-[10%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" value="office building Data123" />
//         </div>
//         <div className="w-[10%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" value="office building Data123" />
//         </div>
//         <div className="w-[10%]">
//             <Select >
//                 <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
//                     <SelectValue placeholder={"PortFolio Name"} className='truncate' />
//                 </SelectTrigger>
//                 <SelectContent side="bottom" className="min-w-[100px]">
//                     {[10, 20, 30, 40, 50].map((pageSize) => (
//                         <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={pageSize} value={`${pageSize}`}>
//                             {pageSize}
//                         </SelectItem>
//                     ))}
//                 </SelectContent>
//             </Select>
//         </div>
//         <div className="w-[10%]">
//             <Select >
//                 <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
//                     <SelectValue placeholder={"PortFolio Name"} className='truncate' />
//                 </SelectTrigger>
//                 <SelectContent side="bottom" className="">
//                     {[10, 20, 30, 40, 50].map((pageSize) => (
//                         <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={pageSize} value={`${pageSize}`}>
//                             {pageSize}
//                         </SelectItem>
//                     ))}
//                 </SelectContent>
//             </Select>
//         </div>
//         <div className="w-[12%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" value="office building Data123" />
//         </div>
//         <div className="w-[6.50%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//         </div>
//         <div className="w-[7%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//         </div>
//         <div className="w-[7%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//         </div>
//         <div className="w-[7%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//         </div>
//         <div className="w-[6.50%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//         </div>
//         <div className="w-[6.50%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//         </div>
//         <div className="w-[6.50%]">
//             <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//         </div>
//         <div className="w-[6.50%] px-1.5 flex items-center justify-end">
//             {
//                 !hasActionTaken ?
//                     (
//                         <Button variant="outline" size="sm" onClick={() => setHasActionTaken(true)}>
//                             <Divide className='w-4 h-4' />
//                         </Button>
//                     ) :
//                     (
//                         <DropdownMenu>
//                             <DropdownMenuTrigger asChild>
//                                 <Button variant="outline" className="" size="sm">
//                                     <Pen strokeWidth={"2.2"} className="h-4 w-4" />
//                                 </Button>
//                             </DropdownMenuTrigger>
//                             <DropdownMenuContent align="end">
//                                 <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"><PlusIcon strokeWidth={"2.2"} className='text-green-500 w-4 h-4' /> Add Sub Allocation</DropdownMenuItem>
//                                 <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`}><Minus strokeWidth={"2.2"} className='text-red w-4 h-4' /> Remove Sub Allocation</DropdownMenuItem>
//                                 <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`}><Lock strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Lock Allocation</DropdownMenuItem>
//                             </DropdownMenuContent>
//                         </DropdownMenu>
//                     )
//             }
//         </div>
//     </div>
//     {
//         hasActionTaken && (
//             <div className="">
//                 <Accordion defaultValue='item-1' type="single" collapsible className="w-full p-1.5 pb-0">
//                     <AccordionItem value="item-1" className="border-none p-0 accordian-custom">
//                         <AccordionTrigger className="border-none p-0 pb-2" hasIcon={false}>
//                             <div className="allocation-table-cloned-header w-full">
//                                 <div className="flex items-center gap-2 overflow-hidden">
//                                     <div className="w-[4%] py-1.5  flex items-center justify-center">
//                                         <ChevronRight className='w-4 h-4 icon-chev transition-transform duration-300' />
//                                     </div>
//                                     <div className="w-[10%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>Class</h5>
//                                     </div>
//                                     <div className="w-[10%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>Sub Class</h5>
//                                     </div>
//                                     <div className="w-[10%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>Asset</h5>
//                                     </div>
//                                     <div className="w-[10%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>Strategy</h5>
//                                     </div>
//                                     <div className="w-[12%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>Allocation Type</h5>
//                                     </div>
//                                     <div className="w-[6.50%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>SAA%</h5>
//                                     </div>
//                                     <div className="w-[7%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>SAA LT%</h5>
//                                     </div>
//                                     <div className="w-[7%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>SAA UT%</h5>
//                                     </div>
//                                     <div className="w-[7%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>TAA%</h5>
//                                     </div>
//                                     <div className="w-[6.50%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>TA%</h5>
//                                     </div>
//                                     <div className="w-[6.50%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>TLT%</h5>
//                                     </div>
//                                     <div className="w-[6.50%] py-1">
//                                         <h5 className='text-xs text-textV1 text-center'>TUT%</h5>
//                                     </div>
//                                     <div className="w-[6.50%] py-1.5 px-1.5">
//                                         <h5 className='text-xs text-textV1 text-end'>Allocation</h5>
//                                     </div>
//                                 </div>
//                             </div>
//                         </AccordionTrigger>
//                         <AccordionContent className="px-0">
//                             <div className="">
//                                 <div className={`${checked ? "bg-backgroundV1" : ""} flex items-center gap-2 overflow-hidden`}>
//                                     <div className="w-[4%]  flex items-center justify-center">
//                                         <CheckboxNew checked={checked2} onCheckedChange={setChecked2} className="w-5 h-5 rounded-[6px] bg-white" />
//                                     </div>
//                                     <div className="w-[10%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" value="office building Data123" />
//                                     </div>
//                                     <div className="w-[10%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" value="office building Data123" />
//                                     </div>
//                                     <div className="w-[10%]">
//                                         <Select
//                                             value={``}
//                                             onValueChange={(value) => { "" }}
//                                         >
//                                             <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
//                                                 <SelectValue placeholder={"PortFolio Name"} className='truncate' />
//                                             </SelectTrigger>
//                                             <SelectContent side="bottom" className="min-w-[100px]">
//                                                 {[10, 20, 30, 40, 50].map((pageSize) => (
//                                                     <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={pageSize} value={`${pageSize}`}>
//                                                         {pageSize}
//                                                     </SelectItem>
//                                                 ))}
//                                             </SelectContent>
//                                         </Select>
//                                     </div>
//                                     <div className="w-[10%]">
//                                         <Select
//                                             value={``}
//                                             onValueChange={(value) => { "" }}
//                                         >
//                                             <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
//                                                 <SelectValue placeholder={"PortFolio Name"} className='truncate' />
//                                             </SelectTrigger>
//                                             <SelectContent side="bottom" className="">
//                                                 {[10, 20, 30, 40, 50].map((pageSize) => (
//                                                     <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={pageSize} value={`${pageSize}`}>
//                                                         {pageSize}
//                                                     </SelectItem>
//                                                 ))}
//                                             </SelectContent>
//                                         </Select>
//                                     </div>
//                                     <div className="w-[12%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" value="office building Data123" />
//                                     </div>
//                                     <div className="w-[6.50%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                     <div className="w-[7%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                     <div className="w-[7%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                     <div className="w-[7%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                     <div className="w-[6.50%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                     <div className="w-[6.50%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                     <div className="w-[6.50%]">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                     <div className="w-[6.50%] px-1.5 flex items-center justify-end">
//                                         <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
//                                     </div>
//                                 </div>
//                             </div>
//                         </AccordionContent>
//                     </AccordionItem>
//                 </Accordion>
//             </div>
//         )
//     }
// </div>
// </div>