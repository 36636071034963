import React from 'react'
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import AssetTransactionsTableContent from '../../../../shared/common/portfolio/tabs-content/AssetTransactionsTableContent';
import AssetAnalyticContent from '../../../../shared/common/portfolio/tabs-content/AssetAnalyticContent';
import UnitedNationsContent from '../../../../shared/common/portfolio/tabs-content/UnitedNationsContent';
import IconMaintanance from '../../../../components/svg/IconMaintanance';
import IconAsset from '../../../../components/svg/IconAsset';
import IconReportingNew from '../../../../components/svg/IconReportingNew';
import AssetReportingContent from '../../../../shared/common/portfolio/tabs-content/AssetReportingContent';

const PortfolioGroupSubTabs = (props) => {
    const {
        // AssetTransactionsTable 
        clickTransactionsTable, fetchAssetData, groupSelectedId, isGroup,
        // AssetAnalytic , UnitedNations
        handleTabChange, keyValue,
        // any 
        selectedId, 
        // reporting 
        allAnalytics, updateId

    } = props;

    return (
        <Tabs defaultValue={keyValue} className="w-[full]" onValueChange={handleTabChange}>
            <div className="mb-8 px-3 md:px-5 !pt-0">
                <TabsList className="inline-flex items-center hidden-scroll rounded-none justify-start bg-[unset] shadow-none p-0 gap-8 overflow-y-auto border-b border-[#C9C9C9]">
                    <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Transactions-maintenance"><span><IconMaintanance className="w-4 h-4"/></span> Transactions maintenance</TabsTrigger>
                    <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset-Analytic" ><span><IconAsset className="w-4 h-4"/></span> Asset Analytic</TabsTrigger>
                    <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset-Reporting"><span><IconReportingNew className="w-4 h-4"/></span> Asset Reporting</TabsTrigger>
                </TabsList>
            </div>
            <TabsContent value="Transactions-maintenance" className="m-0">
                <AssetTransactionsTableContent clickTransactionsTable={clickTransactionsTable} fetchAssetData={fetchAssetData} groupSelectedId={groupSelectedId} isGroup={isGroup} selectedId={selectedId}/>
            </TabsContent>
            <TabsContent value="Asset-Analytic" className="m-0">
                <AssetAnalyticContent fetchAssetData={fetchAssetData} keyValue={keyValue} selectedId={selectedId}/>
                {/* <UnitedNationsContent fetchAssetData={fetchAssetData} keyValue={keyValue} /> */}
            </TabsContent>
            <TabsContent value="Asset-Reporting" className="m-0">
              <AssetReportingContent allAnalytics={allAnalytics} updateId={updateId} fetchAssetData={fetchAssetData} isPortfolio={false}/>
            </TabsContent>
        </Tabs>
    )
}


export default PortfolioGroupSubTabs