import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment-js';
import { Checkbox } from "@/components/ui/checkbox";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { addAssetsV2Details, fetchInvestInAssetDetails } from '../../../../store/actions/AssetDetailsAction';
import { addTost } from '../../../../store/actions/toastAction';
import { Filters, toastifyType } from '../../../../constants';
import { addInvestedAsset } from '../../../../store/actions/portfolioTransactionAction';
import DataTableColumnHeader from '../../table-components/tasks/components/data-table-column-header';
import { FromDatePicker } from '../../../../components/ui/DatePickerNew';
import { fetchStrategyAll } from '../../../../store/actions/strategyAction';
import { DataTableWithCustomPagination } from '../../table-components/tasks/components/data-table-without-pagination';


const BuyAsset = (props) => {
    const { portfolioAssetId, strategy, setIsLoading, isGroup, hideModel, show,
        currentPage, setCurrentPage,
        perPage, setPerPage,
        totalRecords, setTotalRecords,
        totalPages, setTotalPages
     } = props;
    const [error, setError] = useState({});
    const [fetchAssetItem, setFetchAssetItem] = useState({})
    const dispatch = useDispatch()
    const { assetsDetails } = useSelector(state => state)
    const [assets, setAssets] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const { slug } = useParams();
    const [itemsPerPage] = useState(5); // Number of items to display per page


    // pagination
    // const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    // const [perPage, setPerPage] = useState(5); // Default per page value
    // const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    // const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)


    const currentDate = new Date();
    const [formData, setFormData] = useState({
        purchasePrice: '',
        quantity: '',
        purchaseDate: null
    })

    useEffect(() => {
        // show && setIsLoading(true)
        // show && dispatch(fetchStrategyAll('all'))
        // show && dispatch(fetchAssetsDetails(setIsLoading, slug, currentPage))
        setFormData({
            purchasePrice: 0,
            quantity: 1,
            purchaseDate: currentDate
        })
        setError({})
        setFetchAssetItem({})
        setStrategyOp({ value: null, label: 'None' })
    }, [show])

    // const onChange = (filter) => {
    //     show && dispatch(fetchAssetsDetails(setIsLoading, slug, filter.page))
    // }

    // useEffect(() => {
    //     show && dispatch(fetchInvestInAssetDetails(setIsLoading, slug, setTotalRecords, setTotalPages, currentPage, perPage))
    // }, [show, currentPage, perPage]);

    useEffect(() => {
        assetsDetails?.records && setAssets(assetsDetails?.records)
    }, [assetsDetails])

    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        // Check if the entered value is negative
        if (name === 'purchasePrice' && parseFloat(value) < 0) {
            setError({ ...error, [name]: 'Value cannot be negative' });
            return;
        }
        if (name === 'quantity' && parseFloat(value) < 0) {
            setError({ ...error, [name]: 'Value cannot be negative' });
            return;
        }
        // Clear the error if the value is non-negative
        setError({ ...error, [name]: '' });

        if (name === 'purchaseDate') {
            // Use setFormData to update the date value
            setFormData({ ...formData, [name]: value });
        } else {
            // Use setFormData to update other fields
            setFormData({ ...formData, [name]: value });
        }
    };

    // strategy 
    const [strategyOp, setStrategyOp] = useState({ value: null, label: 'None' })
    const strategyOptions = strategy && strategy?.records?.length ? [{ value: null, label: 'None' }, ...strategy?.records?.map((items) => {
        return { value: items._id, label: items.name }
    })] : []


    const onChangeStrategyOp = (obj) => {
        const getObj = JSON.parse(obj)
        setStrategyOp(getObj)
        setError({ ...error, ['strategy']: '' });
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.purchasePrice === '' || formData.purchasePrice === 0 || formData.purchasePrice === '0') {
            errors['purchasePrice'] = 'Please enter a valid price';
            isValid = false;
        }
        if (formData.purchaseDate === null) {
            errors['purchaseDate'] = 'Please select purchase Date';
            isValid = false;
        }
        if (formData.quantity === '' || parseFloat(formData.quantity) <= 0) {
            errors['quantity'] = 'Please enter quantity';
            isValid = false;
        }
        // if (strategyOp === null) {
        //     errors['strategy'] = 'Please select strategy';
        //     isValid = false;
        // }
        setError(errors);
        return isValid;
    };

    const onCheckBtn = (e, data) => {
        setFetchAssetItem(data)
        if (data?.isPublicAsset) {
            setFormData({ ...formData, purchasePrice: data?.adjClose })
        } else {
            setFormData({ ...formData, purchasePrice: 0 })
        }
    }

    // Pagination functions
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const data = assets.length ? assets.map((asset) => {
        return { ...asset }
    }) : []


    const columns = [
        {
            id: "select",
            header: ({ table }) => (
                <>

                </>
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.original?._id === fetchAssetItem?._id}
                    onCheckedChange={(value) => { row.toggleSelected(!!value); onCheckBtn(null, row?.original) }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "assetId",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="AssetId" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.asset?.assetId ? row?.original?.asset?.assetId : 'N/A'}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "assetName",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.asset?.assetName ? row?.original?.asset?.assetName : 'N/A'}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "class",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Class" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.class ? row?.original?.class : 'N/A'}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "SubClass",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Sub Class" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.subClass ? row?.original?.subClass : 'N/A'}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "description",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Description" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.asset?.description ? row?.original?.asset?.description : 'N/A'}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "countryName",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Country" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.country?.countryName ? row?.original?.country?.countryName : 'N/A'}</span>
            },
            enableSorting: true,

        }
    ]


    const onSave = (e) => {
        if (handleValidation()) {
            if (_.isEmpty(fetchAssetItem)) {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select one Asset' }))
            } else {
                if (isGroup) {
                    dispatch(addAssetsV2Details({
                        // operationType: 'addToPortfolio',
                        asset: fetchAssetItem && fetchAssetItem?.asset?._id,
                        portfolio: slug,
                        purchasePrice: Number(formData.purchasePrice),
                        quantity: Number(formData.quantity),
                        purchaseDate: moment(formData.purchaseDate).format('MM/DD/YYYY'),
                    }, 'portfolioGroupInvestedAsset'))
                } else {
                    dispatch(addInvestedAsset({
                        action: "invest", // invest add
                        // operationType: 'addToPortfolio',
                        strategyId: strategyOp?.value ? strategyOp?.value : null,
                        //     // transactionId: fetchAssetItem && fetchAssetItem?._id,
                        assetId: fetchAssetItem && fetchAssetItem?.asset?._id,
                        //     // investedAssetId : fetchAssetItem && fetchAssetItem?.asset?._id,
                        //     // investedAssetId : fetchAssetItem && fetchAssetItem?._id,
                        //     // portfolio: slug,
                        portfolioId: fetchAssetItem.portfolio,
                        price: Number(formData.purchasePrice),
                        quantity: Number(formData.quantity),
                        type: 'Buy',
                        date: moment(formData.purchaseDate).format('MM/DD/YYYY'),
                    }, [portfolioAssetId], null, 'investedAsset', setIsLoading))
                }
                setFormData({
                    purchasePrice: '',
                    quantity: '',
                    purchaseDate: ""
                })
                setStrategyOp(null)
                hideModel();
            }
        }
    }


    return (
        <div>
            <div className="grid grid-cols-1 gap-5 mb-5">
                {/* <DataTableNew data={data} columns={columns} onChange={onChange} filterName={'assetName'} /> */}
                <DataTableWithCustomPagination data={data} columns={columns} filterName={'assetName'}
                    setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
            </div>

            <div className="grid grid-cols-2 gap-5 ">
                <div className="form-group">
                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                        Purchase Price:<span style={{ color: 'red' }}>*</span>
                    </Label>
                    {isGroup ? <Input className={`form-control py-2 h-[34px] shadow-none ${error['purchasePrice'] && 'is-invalid'}`} min={0} name='purchasePrice' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="number" value={formData.purchasePrice} /> :
                        formData.purchasePrice === "Fetching" ?
                            {/* <Lottie animationData={animationData} style={{
                    height: '50px', width: '100px', position: "absolute",
                    top: "-14px",
                    left: "0px"
                }} /> */}
                            : <Input className={`form-control py-2 h-[34px] shadow-none ${error['purchasePrice'] && 'is-invalid'}`} min={0} name='purchasePrice' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="number" value={formData.purchasePrice} />
                    }
                    <div className={error['purchasePrice'] ? "text-red" : ''}>{error['purchasePrice'] ? error['purchasePrice'] : null}</div>
                </div>
                <div className="form-group ">
                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                        Purchase Date:<span style={{ color: 'red' }}>*</span>
                    </Label>
                    <FromDatePicker onDateSelect={(date) => onChangeHandel({ target: { name: 'purchaseDate', value: date } })}
                        placeholderText='YYYY-MM-DD'
                        selectedFromDate={formData.purchaseDate}
                        value={formData.purchaseDate}
                    />
                    {/* <DatePicker
    className={'w-full w-100'}
    customInput={<CustomInputFormData />}
    placeholderText="MM/DD/YYYY"
    selected={formData.purchaseDate}
    value={formData.purchaseDate}
    renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
    }) => (
        <div
            className='w-full'
            style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
            }}
        >
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
            </button>
            <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
            >
                {years.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                    changeMonth(months.indexOf(value))
                }
            >
                {months.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>

            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
            </button>
        </div>
    )}
    onChange={(date) => onChangeHandel({ target: { name: 'purchaseDate', value: date } })}
/> */}
                    <div className={error['purchaseDate'] ? "text-red" : ''}>{error['purchaseDate'] ? error['purchaseDate'] : null}</div>
                </div>
                <div className='form-group'>
                    <Label htmlFor="PurchaseQuantity" className="block mb-2.5 text-textV1 font-500">Purchase Quantity:<span style={{ color: 'red' }}>*</span></Label>


                    <Input className={`form-control py-2 h-[34px] shadow-none ${error['quantity'] && 'is-invalid'}`} id="inputEmail3" name='quantity' onChange={(e) => onChangeHandel(e)} type="number" value={formData.quantity} />
                    <div className={error['quantity'] ? "text-red" : ''}>{error['quantity'] ? error['quantity'] : null}</div>

                </div>
                {!isGroup && <div className="form-group">
                    <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                        Select strategy
                    </Label>
                    <Select
                        onValueChange={(e) => onChangeStrategyOp(e)}
                        defaultValue={strategyOp}
                    // value={fetchId !== null ? SubName.classOption?.id ? SubName.classOption?.id : "" : SubName.classOption?.id}
                    >
                        <SelectTrigger className="w-full text-sm h-[34px] shadow-none border-borderV1">
                            <SelectValue
                                className='text-textV1'
                                placeholder="Select strategy"
                            >
                                {strategyOp?.label ? strategyOp?.label : "Select strategy"}
                                {/* {fetchId !== null? SubName.classOption?.label ? SubName.classOption?.label : "" : SubName.classOption?.label} */}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    strategyOptions && strategyOptions.length > 0 ? strategyOptions.map((item, index) => {
                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>

                    <div className={` ${error['strategy'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['strategy'] ? error['strategy'] : null}</div>
                </div>}
            </div>

            <div className='flex justify-end items-center space-x-3 pt-3'>
                <div className='col-auto'>
                    <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                </div>
                <div className='col-auto'>
                    <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={hideModel} >Cancel</Button>
                </div>
            </div>
        </div>
    )
}

export default BuyAsset
