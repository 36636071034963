import { researchNoteContentTagType } from '../../constants';

const researchNotesContentTagReducer = (state = [], action) => {
    switch (action.type) {

        case researchNoteContentTagType.FETCH_RESEARCH_NOTE_TAGS:
            return action.payload;
        case researchNoteContentTagType.ADD_RESEARCH_NOTE_TAGS:
            return action.payload;
        case researchNoteContentTagType.DELETE_RESEARCH_NOTE_TAGS:
            return action.payload;
        default:
            return state;
    }
};
export default researchNotesContentTagReducer