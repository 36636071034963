import {  saleTransactionActionType } from '../../constants';

const saleTransactionReducer = (state = [], action) => {
    switch (action.type) {
        case saleTransactionActionType.FETCH_INVESTED_ASSETS_SALE:
            return action.payload;
        default:
            return state;
    }
};
export default saleTransactionReducer