import { researchActionType } from '../../constants';

const researchNoteContentReducers = (state = [], action) => {
    
    switch (action.type) {
        
        case researchActionType.ADD_RESEARCH_NOTES:
            return action.payload;
        case researchActionType.FETCH_RESEARCH_NOTES:
            return action.payload;
        case researchActionType.EDIT_RESEARCH_NOTES:
            return action.payload;
        case researchActionType.DELETE_RESEARCH_NOTES:
            return action.payload;
        default:
            return state;
    }
};
export default researchNoteContentReducers