import { apiBaseURL, typeActionType, toastifyType, Filters } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const fetchTypes = (include, filter, isLoading) => async (dispatch) => {
    try {
        isLoading !== false && dispatch(setLoading(true));
        
        // Construct the query string based on the properties of the filter object
        const queryParams = new URLSearchParams();
        if (include) queryParams.append('type', include);
        if (filter?.page) queryParams.append('page', filter?.page);
        if (filter?.perPage) queryParams.append('perPage', filter?.perPage);
        const queryString = queryParams.toString();

        const response = await apiConfig.get(apiBaseURL.TYPES + '?' + queryString);
        
        dispatch({ type: typeActionType.FETCH_TYPES, payload: response.data?.data });
        
        // Dispatch metadata action if filter is provided
        filter && dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const addTypes = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.TYPES, data);
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchTypes('', Filters.OBJ));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const deleteTypes = (id) => async (dispatch) => {
    try {

        dispatch(setLoading(true));
        const response = await apiConfig.delete(apiBaseURL.TYPES + "/" + id)
        // const response = await apiConfig.delete(apiBaseURL.TYPES + '/' + id);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchTypes('', Filters.OBJ));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};


export const updateTypes = (data) => async (dispatch) => {
    try {

        dispatch(setLoading(true));
        const response = await apiConfig.put(apiBaseURL.TYPES, data);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchTypes('', Filters.OBJ));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};