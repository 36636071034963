import React from 'react'

const IconSentry = (props) => {
    return (
        <svg
            version="1.0"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            // width="520.000000pt"
            // height="520.000000pt"
            viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet"
            fill="none"
        >
            <g
                filter="url(#filter0_d_787_4028)"
                transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            // fill="#000000"
            // stroke="none"
            >
                <path d="M2703 5100 c-146 -52 -295 -173 -357 -290 -76 -142 -108 -161 -296
-171 -234 -13 -358 -67 -521 -229 -243 -241 -392 -582 -493 -1130 -61 -333
-76 -587 -76 -1325 0 -551 1 -571 20 -603 11 -18 34 -41 52 -52 32 -20 54 -20
1369 -20 l1336 0 33 23 c19 12 43 37 54 56 18 31 19 53 19 321 0 314 -5 353
-56 460 -34 70 -141 184 -368 393 -65 59 -150 150 -192 205 l-76 99 85 17
c101 20 145 15 206 -23 97 -61 217 -120 274 -135 153 -39 322 -5 436 90 60 50
262 294 296 359 25 45 27 60 27 160 0 99 -3 115 -27 165 -15 30 -49 76 -75
101 -26 26 -311 256 -634 512 -322 256 -602 483 -622 506 -69 77 -80 110 -86
267 -6 131 -8 143 -33 181 -52 80 -175 106 -295 63z m13 -450 c26 -112 95
-229 191 -322 26 -26 316 -260 643 -521 465 -370 596 -479 597 -498 2 -18 -22
-53 -95 -139 -53 -63 -113 -125 -131 -137 -64 -43 -130 -25 -306 81 -122 75
-271 98 -428 67 -137 -27 -233 1 -362 105 -87 70 -126 88 -183 81 -83 -9 -142
-77 -142 -161 0 -28 6 -62 13 -76 8 -14 123 -170 258 -345 251 -329 319 -405
573 -640 169 -156 169 -158 174 -377 l4 -168 -1117 0 -1117 0 5 568 c3 411 9
607 20 713 68 642 230 1093 472 1317 91 84 140 103 303 113 155 9 209 22 302
69 80 40 173 134 219 219 33 62 80 125 88 117 2 -2 11 -32 19 -66z" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" strokeMiterlimit="100"
                />
                <path d="M1029 938 c-29 -19 -62 -78 -211 -373 -164 -325 -177 -354 -177 -405
-1 -65 22 -110 71 -140 33 -20 54 -20 1688 -20 1634 0 1655 0 1688 20 49 30
72 75 71 140 0 51 -13 80 -177 405 -149 295 -182 354 -211 373 l-34 22 -1337
0 -1337 0 -34 -22z m2624 -453 c42 -85 77 -157 77 -160 0 -3 -598 -5 -1330 -5
-731 0 -1330 2 -1330 5 0 3 35 75 77 160 l78 155 1175 0 1175 0 78 -155z" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" strokeMiterlimit="100"
                />
            </g>
        </svg>



    )
}

export default IconSentry

