import React, { createContext, useEffect, useState } from 'react'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import PortfolioContent from '../portfolio-tabs-contents/PortfolioContent';
import AllocationMonitoringContent from '../../../../shared/common/portfolio/tabs-content/AllocationMonitoringContent';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import { fetchStrategy } from '../../../../store/actions/strategyAction';
import { addTost } from '../../../../store/actions/toastAction';
import { toastifyType } from '../../../../constants';
import AssetDetailsModel from '../../../../shared/common/portfolio/models/AssetDetailsModel';
import PortfolioSubTabs from '../portfolio-sub-tebs/PortfolioSubTabs';
import { Card } from '../../../../components/ui/Card';
import Divider from '../../../../components/common/Divider';

const PortfolioTab = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { userAnalytic, strategy } = useSelector(state => state)
  const [addAssetsModel, setAddAssetsModel] = useState(false)
  const [showAssetsRemoval, setShowAssetsRemoval] = useState(false)
  const [addAssetsAccordion, setAddAssetsAccordion] = useState(false)
  const [fetchAssetData, setFetchAssetData] = useState({})
  const [updateId, setUpdateId] = useState(null)
  const [allAnalytics, setAllAnalytics] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [portfolioAssetId, setPortfolioAssetId] = useState(null)
  const [portfolioGroupId, setPortfolioGroupId] = useState([]);
  // useEffect(() => {
  //   if (!_.isEmpty(fetchAssetData)) {
  //   dispatch(fetchUserAnalytics())
  //   }
  // }, [])

  useEffect(() => {
    dispatch(fetchStrategy('all'))
  }, [dispatch])



  useEffect(() => {
    setFetchAssetData({})
    setUpdateId(null)
  }, [slug])

  useEffect(() => {
    setAllAnalytics(userAnalytic)
  }, [userAnalytic])

  const onShowAssets = (value, portfolioId) => {
    setAddAssetsModel(true)
    setPortfolioAssetId(portfolioId)
  }

  const onHideAssets = () => {
    setAddAssetsModel(false)
  }

  const onShowAssetsAccordion = () => {
    setAddAssetsAccordion(!addAssetsAccordion)
  }

  const onShowAssetsRemovalAccordion = () => {
    if (_.isEmpty(fetchAssetData)) {
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
    } else {
      setShowAssetsRemoval(!showAssetsRemoval)
    }
  }

  const onCheckBtn = (data) => {
    setFetchAssetData(data)
    setUpdateId(data.asset_id)
  }

  const [keyValue, setKeyValue] = useState('Transactions-maintenance')
  const handleTabChange = (value) => {
    setKeyValue(value)
  }

  return (
    <>
      <section className='p-2 md:px-5'>
        <Tabs defaultValue="Asset" className="w-[full] mt-3">
          <div className="mb-4">
            <TabsList className="flex items-center rounded-[12px] w-fit bg-[#DEDEDE] p-1">
              <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Asset">Assets</TabsTrigger>
              <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Allocation-Monitoring">Allocation Monitoring</TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="Asset" >
            <Card className='my-0 md:my-0 md:p-0 p-0 bg-white'>
              <div className={`p-3 md:p-5 ${fetchAssetData?._id ? '!pb-0' : ''} `}>
                <PortfolioContent show={addAssetsModel} fetchAssetData={fetchAssetData} setIsLoading={setIsLoading} allAnalytics={allAnalytics} updateId={updateId} onShowAssets={onShowAssets} onCheckBtn={onCheckBtn} onShowAssetsRemovalAccordion={onShowAssetsRemovalAccordion} isLoading={isLoading} addAssetsAccordion={addAssetsAccordion} isGroup={false} portfolioGroupId={portfolioGroupId} setPortfolioGroupId={setPortfolioGroupId} />
              </div>

              {fetchAssetData?._id ? <>
                <Divider className="border-borderV1 my-5" />
                <PortfolioSubTabs
                  // AssetTransactionsTable 
                  clickTransactionsTable={null} fetchAssetData={fetchAssetData} groupSelectedId={null} isGroup={false} setIsLoadingMain={setIsLoading}
                  // AssetAnalytic , UnitedNations
                  handleTabChange={handleTabChange} keyValue={keyValue}
                  // reporting
                  allAnalytics={allAnalytics} updateId={updateId} /> </> : ''}
            </Card>
          </TabsContent>
          <TabsContent value="Allocation-Monitoring">
            <AllocationMonitoringContent />
          </TabsContent>
          <AssetDetailsModel
            // portfolio  
            show={addAssetsModel}
            onHide={() => onHideAssets(false)}
            portfolioAssetId={portfolioAssetId}
            strategy={strategy}
            setIsLoading={setIsLoading}
            isGroup={false}
            portfolioGroupId={portfolioGroupId} setPortfolioGroupId={setPortfolioGroupId}
          />
        </Tabs>
      </section>
    </>
  )
}


export default PortfolioTab