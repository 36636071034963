import { chartDataActionType } from '../../constants';

const chartDataReducer = (state = [], action) => {
    switch (action.type) {
        case chartDataActionType.FETCH_CHART_DATA:
            return action.payload;
        default:
            return state;
    }
};
export default chartDataReducer