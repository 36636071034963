import React, { useEffect, useState } from 'react'
import { Label } from '../ui/label'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../ui/select'
import Card from './Card'
import Divider from './Divider'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordian'
import SimpleNewsItemLinkUi from './SimpleNewsItemLinkUi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { fetchPortfolioGroup, fetchPortfolioGroups } from '../../store/actions/portfolioGroupAction'
import { fetchNews } from '../../store/actions/newsAction'
import Loading from '../../shared/common/Loading'

const NewsContentCard = () => {
    const dispatch = useDispatch()
    const { news, isLoading, PortfolioGroupDetails, PortfolioGroup, allPortfolio } = useSelector(state => state)
    const [allNews, setAllNews] = useState([])
    const [portfolioGroupOption, setPortfolioGroupOption] = useState({ value: null, label: 'All' })
    const [fetchPortfolios, setFetchPortfolios] = useState(null)
    const [formData, setFormData] = useState({
        portfolio: null
    })

    useEffect(() => {
        // dispatch(fetchPortfolios())
        dispatch(fetchPortfolioGroups())
    }, [])

    useEffect(() => {
        portfolioGroupOption !== null && portfolioGroupOption?.value !== null && dispatch(fetchPortfolioGroup(portfolioGroupOption?.slug))
    }, [portfolioGroupOption])

    useEffect(() => {
        if (PortfolioGroupDetails && portfolioGroupOption?.value !== null) {
            const filteredArray = PortfolioGroupDetails.filter(item => (item !== undefined && item.portfolio !== null));
            setFetchPortfolios(filteredArray)
        }
    }, [PortfolioGroupDetails])

    // const portfolioOptions = portfolios && portfolios?.length >= 1 ? portfolios?.map((item) => {
    //   return { value: item?.id, label: item?.name, slug: item.slug }
    // }) : []

    const portfolioGroupOptions = PortfolioGroup && PortfolioGroup?.length >= 1 ? [{ value: null, label: 'All' }, ...PortfolioGroup?.map((item) => {
        return { value: item?._id, label: item?.name, slug: item?.slug }
    })] : []


    const portfolioOptionsData = fetchPortfolios !== null && fetchPortfolios?.length >= 1 ? fetchPortfolios?.map((item) => {
        return { value: item?.portfolio?._id, label: item?.portfolio?.name, slug: item?.portfolio?.slug }
    }) : []
    
    const portfolioOptions = (portfolioGroupOption !== null && portfolioGroupOption?.label === "All") ? allPortfolio  && allPortfolio?.records?.length >= 1 ? allPortfolio?.records?.map((item) => {
        return { value: item?.id, label: item?.name, slug: item?.slug }
    }) : [] : portfolioOptionsData.filter(item => item !== null);

    useEffect(() => {
        if (formData.portfolio !== null) {
            dispatch(fetchNews(formData.portfolio?.slug))
        } else {
            if (portfolioOptions !== undefined) {
                dispatch(fetchNews(''))
                // dispatch(fetchNews(portfolioOptions && portfolioOptions[0].slug))
            }
        }

    }, [formData.portfolio])

    useEffect(() => {
        setAllNews(news)
    }, [news])

    const onChangePortfolioGroupOptions = (event) => {
        setPortfolioGroupOption(event)
    }

    const onChangePortfolioOption = (event) => {
        setFormData({ ...formData, portfolio: event })
    }
    return (
        <Card className={"p-0 md:p-0 mb-4 last:mb-0"}>
            <div className="secection-part p-3 md:p-5 !pb-0">
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    <div className="flex flex-col gap-6">
                        <div className="form-group grid grid-cols-12 items-center gap-1">
                            <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                    Portfolio Group
                                </Label>
                            </div>
                            <div className="field-wrapper col-span-8">
                                <Select
                                    onValueChange={(e) => onChangePortfolioGroupOptions(JSON.parse(e))}
                                    defaultValue={portfolioGroupOption}
                                >
                                    <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                                        <SelectValue placeholder="Select Portfolio Group">
                                            {portfolioGroupOption?.label ? portfolioGroupOption?.label : "Select Portfolio Group"}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup >
                                            {
                                                portfolioGroupOptions && portfolioGroupOptions.length > 0 ? portfolioGroupOptions.map((item, index) => {
                                                    return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                            }
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className="flex flex-col gap-6">
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Portfolios
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Select
                                        onValueChange={(e) => onChangePortfolioOption(JSON.parse(e))}
                                        defaultValue={formData.portfolio}
                                    >
                                        <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                                            <SelectValue placeholder="Select Portfolio">
                                                {formData.portfolio !== null ? formData.portfolio?.label : "Select Portfolio"}
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup >
                                                {
                                                    portfolioOptions && portfolioOptions.length > 0 ? portfolioOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Divider className={"my-md:5 my-3 border-borderV1"} />
            <div className="news-accordian-part p-3 md:p-5 !pt-0">
                <h3 className='text-textV2 text-[13px] font-500 my-6'>World News: Top International Headlines</h3>
                {isLoading ? <Loading /> : <div className="country-wise-news-accordin-wrapper">
                    <Accordion type="single" collapsible className="w-full rounded-none">
                        {allNews && allNews.length >= 1 && allNews.map((item, index) => {
                            return (
                                <AccordionItem value={`item-${index}`} className="rounded-none border-none mb-3 last:mb-0">
                                    <AccordionTrigger className="text-[13px] text-themeV2Dark font-500 capitalize px-4 py-3 shadow-accordian relative z-10 bg-backgroundV2">{item?.countryName}</AccordionTrigger>
                                    <AccordionContent outsideWrappeclassName="!px-2  !animate-none" className="border-none p-0">
                                        <div className="bg-[#FBFBFB] shadow-common">
                                            <ul class="list-group">
                                                {item?.results?.map((result, index) => {
                                                    return (
                                                        <SimpleNewsItemLinkUi key={Math.random() * index} sectionName={result?.sectionName} webTitle={result?.webTitle} href={result?.webUrl} />
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </AccordionContent>
                                </AccordionItem>
                            )
                        })}
                    </Accordion>
                </div>}
            </div>
        </Card>
    )
}

export default NewsContentCard



{/* <AccordionItem value="item-1" className="rounded-none border-none mb-3 last:mb-0">
                            <AccordionTrigger className="text-[13px] text-themeV2Dark font-500 capitalize px-4 py-3 shadow-accordian relative z-10 bg-backgroundV2">Canada</AccordionTrigger>
                            <AccordionContent outsideWrappeclassName="!px-2  !animate-none" className="border-none p-0">
                                <div className="bg-[#FBFBFB] shadow-common">
                                    <ul>
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                    </ul>
                                </div>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-1" className="rounded-none border-none mb-3 last:mb-0">
                            <AccordionTrigger className="text-[13px] text-themeV2Dark font-500 capitalize px-4 py-3 shadow-accordian relative z-10 bg-backgroundV2">Canada</AccordionTrigger>
                            <AccordionContent outsideWrappeclassName="!px-2  !animate-none" className="border-none p-0">
                                <div className="bg-[#FBFBFB] shadow-common pt-4">
                                    <ul>
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                        <SimpleNewsItemLinkUi />
                                    </ul>
                                </div>
                            </AccordionContent>
                        </AccordionItem> */}