import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Checkbox } from "@/components/ui/checkbox"
import { Oval } from 'react-loader-spinner';
import { Textarea } from '../../../../components/ui/textarea';
import { CheckboxNew } from '../../../../components/ui/CheckboxNew';
import { AssentContext } from '../Asset';
import { financialType, frequencyOptions, settlementDaysOptions } from '../../../../static/static';
import { fetchSubCategorie } from '../../../../store/actions/subClassAction';
import { fetchSubType } from '../../../../store/actions/subTypeAction';
import { financeSearch } from '../../../../store/actions/SearchFinancialAssetAction';
import { addAssets, updateAssets } from '../../../../store/actions/AssetAction';
import { fetchClass } from '../../../../store/actions/classAction';
import { Filters } from '../../../../constants';
import { fetchTypes } from '../../../../store/actions/typeAction';
import Loading from '../../../../shared/common/Loading';
import { assetByFetchIssuer } from '../../../../store/actions/issuerAction';
import { DatePickerCustomInput } from '../../../../components/ui/DatePickerCustomInput';
import { getCurveByCountryCode } from '../../../../store/actions/CurveAction';
import { getTenorByCurve } from '../../../../store/actions/tenorAction';
import CreatableSelect from 'react-select/creatable'

const AssetContent = (props) => {
    const { currentPage, setCurrentPage } = props;
    const { fetchAssetData, setFetchAssetData, onCheckBtn } = useContext(AssentContext);
    const dispatch = useDispatch()
    const { slug } = useParams()
    const { classData, countries, subClassData, types, subTypes, financeSearchData, curve, tenor, assets } = useSelector(state => state)
    const [updateId, setUpdateId] = useState(null)
    const [error, setError] = useState({});
    const [financialOp, setFinancialOp] = useState({ value: 1, label: 'All' })
    const [suggestedAssetNames, setSuggestedAssetNames] = useState(null);
    const [suggestedAssetId, setSuggestedAssetId] = useState(null);
    const [frequency, setFrequency] = useState(null);
    const [settlementDays, setSettlementDays] = useState(null);
    const [date, setDate] = useState(null)
    const [activeCurve, setActiveCurve] = useState(null);
    const [activeTenor, setActiveTenor] = useState(null);
    const [maturityDate, setMaturityDate] = useState(null)
    const [allCurves, setAllCurves] = useState([])
    const [allTenor, setAllTenor] = useState([])
    const [curveOptions, setCurveOptions] = useState([])
    const [tenorOptions, setTenorOptions] = useState([])
    const [allFinanceSearchData, setAllFinanceSearchData] = useState([])
    const [isDisabledIn, setIsDisabledIn] = useState(false)
    const [isLoad, setIsLoad] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isShowTag, setIsShowTag] = useState(true)
    const [save, setSave] = useState(false)
    const [fetchAssetId, setFetchAssetId] = useState(null)
    const [formData, setFormData] = useState({
        class: null,
        assetName: "",
        description: "",
        country: null,
        subClass: null,
        type: null,
        subType: null,
        Symbol: '',
        exchangeCountry: null,
        isPublicAsset: false,
        issuer: '',
        faceAmount: 0,
        couponRate: 0,
        // purchasePrice: '',
        // quantity: '',
        // purchaseDate: ""
    });


    // ISSUER Search HANDEL
    const [isLoadIssuer, setIsLoadIssuer] = useState(false);
    const [isDisabledIssuer, setIsDisabledIssuer] = useState(false);
    const [issuerData, setIssuerData] = useState([]);
    const [issuerSearch, setIssuerSearch] = useState('');
    const [selectedIssuer, setSelectedIssuer] = useState(false);


    useEffect(() => {
        setAllCurves(curve?.length > 0 && curve)
    }, [curve])

    useEffect(() => {

        setAllTenor(tenor?.records?.length > 0 && tenor?.records)
    }, [tenor?.records])


    // useEffect(() => {
    //     if (save) {
    //         debugger
    //         const data = assets && assets.length >= 1 ? assets.map((item, index) => {
    //             return { id: item._id, Name: item.assetName ? item.assetName : '-', adjClose: item?.adjClose ?? 0, ...item }
    //         }) : []

    //         console.log(fetchAssetId)

    //         setSave(false)
    //     }
    // }, [save])


    useEffect(() => {

        if (Object.keys(fetchAssetData).length > 0) {
            setIsLoading(true)
            // const name = fetchAssetData?.assetName;

            // Using regular expression to extract text inside parentheses
            // const matches = name?.match(/\((.*?)\)/);

            // Check if there is a match and extract the value
            // const result = matches ? matches[1] : '';


            setTimeout(() => {
                setFormData({
                    class: fetchAssetData?.class?._id !== undefined ? { label: fetchAssetData?.class?.name, value: fetchAssetData?.class?._id } : null,
                    assetName: fetchAssetData?.assetName ? fetchAssetData?.assetName : fetchAssetData.isPublicAsset ? `(${fetchAssetData.symbol})` : fetchAssetData.symbol,
                    description: fetchAssetData?.description,
                    country: fetchAssetData.isPublicAsset ? { label: fetchAssetData?.country?.countryName, value: fetchAssetData?.country?._id } : { label: fetchAssetData?.country.countryName, value: fetchAssetData?.country?._id },
                    subClass: fetchAssetData?.subClass?._id ? { label: fetchAssetData?.subClass?.name, value: fetchAssetData?.subClass?._id } : null,
                    type: fetchAssetData?.type?._id !== undefined ? { label: fetchAssetData?.type?.name, value: fetchAssetData?.type?._id } : null,
                    subType: fetchAssetData?.subType?._id ? { label: fetchAssetData?.subType?.name, value: fetchAssetData?.subType?._id } : null,
                    Symbol: fetchAssetData.isPublicAsset === true ? fetchAssetData.symbol : fetchAssetData.symbol,
                    isPublicAsset: fetchAssetData.isPublicAsset,
                    exchangeCountry: Object.keys(fetchAssetData?.exchangeCountry)?.length > 0 ? { label: fetchAssetData?.exchangeCountry?.country, value: fetchAssetData?.exchangeCountry?._id } : null,
                    issuer: fetchAssetData?.issuer?._id ? fetchAssetData?.issuer?._id : '',
                    faceAmount: fetchAssetData.publicAsset?.faceAmount ? fetchAssetData.publicAsset?.faceAmount : 0,
                    couponRate: fetchAssetData.publicAsset?.couponRate ? fetchAssetData.publicAsset?.couponRate : 0,

                    // purchasePrice: fetchAssetData?.purchasePrice,
                    // quantity: fetchAssetData?.quantity,
                    // purchaseDate: moment(fetchAssetData?.purchaseDate).format('YYYY-MM-DD')
                })
                setMaturityDate(fetchAssetData.publicAsset?.maturityDate ?? null)
                setDate(fetchAssetData.publicAsset?.issueDate ? new Date(fetchAssetData.publicAsset?.issueDate) : null)
                setFrequency(frequencyOptions.filter(item => item.value === fetchAssetData.publicAsset?.frequency)[0])
                setSettlementDays(settlementDaysOptions.filter(item => item.value === fetchAssetData.publicAsset?.settlementDays)[0]);



            }, 100);


            if (fetchAssetData?.issuer?._id) {
                setIsDisabledIssuer(true)
                setIssuerData([])
                setIssuerSearch(fetchAssetData?.issuer?.name)
                setSelectedIssuer(true)
            } else {
                setIsDisabledIssuer(false)
                setIssuerData([])
                setIssuerSearch('')
                setSelectedIssuer(false)
            }
            const fid = fetchAssetData?.isPublicAsset ? financialType?.filter(item => item?.id?.toLocaleLowerCase() === fetchAssetData?.publicAsset?.instrument?.toLocaleLowerCase())[0] : null


            if (fid) {
                setFinancialOp({ value: fid.id, label: fid.label })
            }
            setIsShowTag(false)
            fetchAssetData?.publicAsset && setSuggestedAssetId(fetchAssetData?.publicAsset?._id)
            fetchAssetData.isPublicAsset && setIsDisabledIn(true)
            fetchAssetData.isPublicAsset && setIsLoad(false)
            setUpdateId(fetchAssetData?.id)
            setError({})
        } else {
            setFormData({
                class: null,
                assetName: "",
                description: "",
                country: null,
                subClass: null,
                type: null,
                subType: null,
                Symbol: '',
                isPublicAsset: false,
                exchangeCountry: null,
                issuer: '',
                faceAmount: 0,
                couponRate: 0,
                // purchasePrice: '',
                // quantity: '',
                // purchaseDate: ""
            })
            setUpdateId(null)
        }
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
    }, [fetchAssetData]);

    console.log(fetchAssetData)

    useEffect(() => {
        const activeCurveValue = allCurves?.length > 0 && allCurves?.filter((curve) => curve?._id === fetchAssetData.publicAsset?.curve)[0];
        activeCurveValue && setActiveCurve({
            value: activeCurveValue?._id,
            label: activeCurveValue?.name,
            slug: activeCurveValue?.slug
        })
    }, [allCurves])

    useEffect(() => {

        const activeTenorValue = allTenor?.length > 0 && allTenor?.filter((tenure) => tenure?._id === fetchAssetData.publicAsset?.tenor)[0]
        activeTenorValue !== null && setActiveTenor({
            value: activeTenorValue?._id,
            label: activeTenorValue?.maturity,
            slug: activeTenorValue?.slug,
            month: activeTenorValue?.month
        })
    }, [allTenor])



    useEffect(() => {
        if (issuerSearch.length >= 1 && !selectedIssuer) {
            setIsLoadIssuer(true)
            dispatch(assetByFetchIssuer(setIsLoadIssuer, setIssuerData, issuerSearch))
        } else {
            setIssuerData([])
        }
    }, [issuerSearch])

    const onSelectIssuer = (value) => {
        setFormData({ ...formData, issuer: value._id })
        setIssuerSearch(value.name)
        setIsDisabledIssuer(true)
        setSelectedIssuer(true)
        setIssuerData([])
    }
    const removeIssuer = () => {
        setFormData({ ...formData, issuer: '' })
        setIssuerSearch('')
        setIsDisabledIssuer(false)
        setIsLoadIssuer(false)
        setSelectedIssuer(false)
        setIssuerData([])

    }
    // ISSUER Search HANDEL END

    // financial option change then reselect country again
    useEffect(() => {
        if (financialOp !== null) {
            setFormData({
                ...formData,
                country: null
            });
            setActiveCurve(null);
            setActiveTenor(null);
            setDate(null);
            setFrequency(null);
            setSettlementDays(null);
            setAllCurves([])
            setAllTenor([])
        }
    }, [financialOp])



    // new curve code start


    useEffect(() => {
        if (financialOp?.value === "bond" && formData?.country !== null) {

            const selectedCountry = countries?.length > 0 && countries?.filter((country) => country?._id === formData?.country?.value)
            const countryId = selectedCountry?.length > 0 ? selectedCountry[0]?._id : ""

            dispatch(getCurveByCountryCode(countryId))
        }
    }, [formData?.country])
    //curve code end

    // tenor code start

    useEffect(() => {

        if (activeCurve !== null) {
            if (financialOp?.value === "bond") {

                dispatch(getTenorByCurve(activeCurve?.value))
            }
        }
    }, [activeCurve?.value])
    // tenor code end



    useEffect(() => {
        setFormData({
            class: null,
            assetName: "",
            description: "",
            country: null,
            subClass: null,
            type: null,
            subType: null,
            Symbol: '',
            isPublicAsset: false,
            exchangeCountry: null,
            issuer: '',
            faceAmount: 0,
            couponRate: 0,
            // purchasePrice: '',
            // quantity: '',
            // purchaseDate: ""
        })
        setUpdateId(null)
    }, [slug])

    // useEffect(() => {
    //     dispatch(fetchCountries())
    //     dispatch(fetchCategories())
    //     // dispatch(fetchSubCategories())
    // }, [dispatch])





    useEffect(() => {
        if (formData.class !== null && formData.class !== undefined) {
            dispatch(fetchSubCategorie(formData?.class?.value, 'all'))
        }
    }, [formData.class, dispatch])
    useEffect(() => {
        if (formData.type !== null && formData.type !== undefined) {
            dispatch(fetchSubType(formData?.type?.value, 'all'))
        }
    }, [formData.type, dispatch])


    useEffect(() => {
        if (formData.isPublicAsset && formData.assetName?.length >= 2 && formData.country?.value) {
            function removeContentInRoundBracketsAndAfter(inputString) {
                // Use a regular expression to match content within round brackets, everything after an opening parenthesis, and everything after a period or comma
                const result = inputString.replace(/\([^)]*\)|\(.*/g, '').replace(/[.,].*/g, '');

                return result;
            }

            const data = {
                name: removeContentInRoundBracketsAndAfter(formData.assetName?.trim()),
                country: formData.country?.value,
                instrument: financialOp.value,
                exchange: ''
            }
            !isDisabledIn && setIsLoad(true)
            !isDisabledIn && dispatch(financeSearch(data))
        }
    }, [formData.assetName])

    useEffect(() => {
        if (financeSearchData) {
            setAllFinanceSearchData(financeSearchData)
        }
    }, [financeSearchData])

    useEffect(() => {
        if (allFinanceSearchData && allFinanceSearchData.length >= 1) {
            // Transform the response to include both name and symbol
            const suggestions = allFinanceSearchData.map(item => ({
                label: `${item.name} (${item.symbol})`,
                value: item._id,
                country: item.country
            }));
            setSuggestedAssetNames(suggestions);
            setIsLoad(false)
        } else {
            setSuggestedAssetNames(null);
            setIsLoad(false)
        }
    }, [allFinanceSearchData])

    const financialOptions = financialType && financialType.length >= 1 ? financialType.map((item) => {
        return { value: item.id, label: item.label }
    }) : []

    const countriesOptions = countries && countries.length >= 1 && countries.map((item) => {
        return { value: item._id, label: item.countryName }
    })
    const categoryOptions = classData && classData?.records?.length >= 1 ? classData?.records?.map((item) => {
        return { value: item.id, label: item.name }
    }) : []

    const subCategoriesOptions = subClassData && subClassData?.subClasses
        && subClassData?.subClasses
            ?.length >= 1 ? subClassData?.subClasses
                ?.map((item) => {
                    return { value: item._id, label: item.name }
                }) : []
    const typeOptions = types && types?.records?.length >= 1 ? types?.records?.map((item) => {
        return { value: item.id, label: item.name }
    }) : []

    const subTypeOptions = subTypes && subTypes?.subTypes && subTypes?.subTypes?.length >= 1 ? subTypes?.subTypes?.map((item) => {
        return { value: item._id, label: item.name }
    }) : []


    // curve options 
    useEffect(() => {
        const curveOptions = allCurves?.length > 0 && allCurves?.map((curve) => {
            return {
                value: curve?._id,
                label: curve?.name,
                slug: curve?.slug,
            }
        })
        setCurveOptions(curveOptions)
    }, [allCurves])


    useEffect(() => {
        if (activeTenor !== null) {
            const dateObj = new Date()
            const maturityDate = new Date(dateObj.setMonth(dateObj.getMonth() + activeTenor?.month))
            setMaturityDate(maturityDate)
        }
    }, [activeTenor?.value])



    //tenor options
    useEffect(() => {
        const tenorOptions = allTenor?.length > 0 && allTenor?.map((tenor) => {
            return {
                value: tenor?._id,
                label: tenor?.maturity,
                month: tenor?.month
            }
        })
        setTenorOptions(tenorOptions)
    }, [allTenor])


    const onChangeFinancialOption = (event) => {
        setFinancialOp(event)
    }

    const onChangeCountriesOption = (event) => {
        setFormData({ ...formData, country: event })
        setError({})
    }
    const onChangeExChangeCountriesOption = (event) => {
        setFormData({ ...formData, exchangeCountry: event })
        setError({})
    }
    const onChangeClassOption = (event) => {
        setFormData({ ...formData, class: event })
        setError({})
    }
    const onChangeTypeOption = (event) => {
        setFormData({ ...formData, type: event })
        setError({})
    }
    const onChangeSubClassOption = (event) => {
        setFormData({ ...formData, subClass: event })
        setError({})
    }
    const onChangeSubTypeOption = (event) => {
        setFormData({ ...formData, subType: event })
        setError({})
    }

    // onChange curve 
    const onChangeCurveOption = (e) => {
        // set curve value to active curve
        setActiveCurve(e)
    }

    // onChange tenor 
    const onChangeTenorOption = (e) => {
        // set tenor value to active tenor
        setActiveTenor(e)
    }

    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        if (name === 'assetName' && suggestedAssetNames) {
            setIsShowTag(true)
        }
        setFormData({ ...formData, [name]: value });
        setError({})
    }

    //  onChange Maturity date 
    const handleMaturityDateChange = (date) => {
        setMaturityDate(date)
    }

    // onChange settlementDays 
    const onChangeSettlementDaysOption = (e) => {
        // set tenor value to active tenor
        setSettlementDays(e)
    }
    //  onChange date 
    const handleDateChange = (date) => {
        setDate(date)
    }
    // onChange frequency 
    const onChangeFrequencyOption = (e) => {
        // set curve value to active curve
        setFrequency(e)
    }   

    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (formData.assetName === '') {
            errors['assetName'] = 'Please enter Name';
            isValid = false;
        }
        if (!formData.isPublicAsset && formData.Symbol === '') {
            errors['Symbol'] = 'Please enter Symbol Name';
            isValid = false;
        }
        if (!formData.isPublicAsset && formData.Symbol.includes(' ')) {
            errors['Symbol'] = 'Please remove space after save now';
            isValid = false;
        }
        if (formData.class === null) {
            errors['class'] = 'Please select class';
            isValid = false;
        }
        if (financialOp?.value === "bond" && formData.country === null) {
            errors['country'] = 'Please select country';
            isValid = false;
        }
        if (formData.exchangeCountry === null && financialOp?.value !== "bond" && formData.isPublicAsset) {
            errors['exchangeCountry'] = 'Please select exchangeCountry';
            isValid = false;
        }
        // if (formData.description === '') {
        //     errors['description'] = 'Please enter description';
        //     isValid = false;
        // }
        // if (formData.purchasePrice === '') {
        //     errors['purchasePrice'] = 'Please enter price';
        //     isValid = false;
        // }
        // if (formData.purchaseDate === '') {
        //     errors['purchaseDate'] = 'Please select purchaseDate';
        //     isValid = false;
        // }
        // if (formData.quantity === '') {
        //     errors['quantity'] = 'Please enter quantity';
        //     isValid = false;
        // }
        if (formData.subClass === null) {
            errors['subClass'] = 'Please select subClass';
            isValid = false;
        }
        if (formData.type === null) {
            errors['type'] = 'Please select type';
            isValid = false;
        }
        if (formData.subType === null) {
            errors['subType'] = 'Please select subType';
            isValid = false;
        }
        if (formData.issuer === "") {
            errors['Issuer'] = 'Please select Issuer';
            isValid = false;
        }

        if (formData?.isPublicAsset && financialOp?.value === "bond" && (formData.faceAmount === 0 || formData.faceAmount < 0)) {
            errors['faceAmount'] = 'Please enter face amount';
            isValid = false;
        }

        if (formData?.isPublicAsset && financialOp?.value === "bond" && (formData.couponRate === 0 || formData.couponRate < 0)) {
            errors['couponRate'] = 'Please enter coupon rate';
            isValid = false;
        }

        if (formData?.isPublicAsset && financialOp?.value === "bond" && settlementDays === null) {
            errors['settlementDays'] = 'Please select settlement days';
            isValid = false;
        }
        if (formData?.isPublicAsset && financialOp?.value === "bond" && frequency === null) {
            errors['frequency'] = 'Please select frequency';
            isValid = false;
        }
        if (formData?.isPublicAsset && financialOp?.value === "bond" && maturityDate === null) {
            errors['maturityDate'] = 'Please select maturity date';
            isValid = false;
        }



        setError(errors);
        return isValid;
    };



    const handleEmptyState = () => {
        setFormData({
            class: null,
            assetName: "",
            description: "",
            country: null,
            subClass: null,
            type: null,
            subType: null,
            Symbol: '',
            isPublicAsset: false,
            exchangeCountry: null,
            issuer: ''
            // purchasePrice: '',
            // quantity: '',
            // purchaseDate: ""
        })
    }
    const handleOnChange = (event) => {
        setFormData({ ...formData, isPublicAsset: !formData.isPublicAsset })
        // setIsCheck(event.target.checked);
        if (event === false) {
            setFormData({ ...formData, assetName: '', Symbol: '', exchangeCountry: null, isPublicAsset: false })
            setIsDisabledIn(false)
            setSuggestedAssetId(null)
            setIsLoad(false)
        }
    }
    const prepareData = (formData) => {
        const countryId = formData?.isPublicAsset && financialOp?.value === "bond" ? formData?.country?.value ?? "" : formData?.isPublicAsset ? formData?.exchangeCountry?.value : formData?.country?.value
        const data = formData?.isPublicAsset && financialOp?.value === "bond" ?
            {
                asset: fetchAssetData?._id,
                class: formData.class.value,
                subClass: formData.subClass.value,
                type: formData.type.value,
                subType: formData.subType.value,
                isPublicAsset: formData?.isPublicAsset,
                // publicAsset: suggestedAssetId !== null ? suggestedAssetId : null,
                assetName: suggestedAssetId !== null ? formData.assetName : formData.assetName,
                description: formData.description,
                country: countryId,
                issuer: formData.issuer !== '' ? formData.issuer : null,
                symbol: formData.Symbol?.toLocaleUpperCase().replace(/\s/g, ''),
                faceAmount: formData.faceAmount,
                couponRate: formData.couponRate,
                frequency: frequency?.value,
                settlementDays: settlementDays?.value,
                maturityDate: new Date(maturityDate).setHours(0, 0, 0, 0),
                curve: activeCurve?.value,
                tenor: activeTenor?.value,
                issueDate: new Date(date).setHours(0, 0, 0, 0),
                financialInstrument: financialOp?.value
            }

            : formData?.isPublicAsset ? {
                // purchasePrice: Number(formData.purchasePrice),
                // quantity: Number(formData.quantity),
                // purchaseDate: moment(formData.purchaseDate).format('MM/DD/YYYY')
                asset: fetchAssetData._id,
                // operationType: 'update',
                class: formData.class.value,
                publicAsset: suggestedAssetId !== null ? suggestedAssetId : null,
                assetName: formData.isPublicAsset ? formData.assetName : null,
                // assetName: formData.assetName,
                description: formData.description,
                country: countryId,
                subClass: formData.subClass.value,
                financialInstrument: financialOp?.value || null,
                type: formData.type.value,
                subType: formData.subType.value,
                isPublicAsset: formData.isPublicAsset,
                issuer: formData.issuer !== '' ? formData.issuer : null,
                symbol: formData.Symbol?.toLocaleUpperCase().replace(/\s/g, '')
            } : {
                asset: fetchAssetData._id,
                // operationType: 'update',
                class: formData.class.value,
                publicAsset: suggestedAssetId !== null ? suggestedAssetId : null,
                assetName: formData.isPublicAsset ? null : formData.assetName,
                // assetName: formData.assetName,
                description: formData.description,
                country: countryId,
                subClass: formData.subClass.value,
                type: formData.type.value,
                subType: formData.subType.value,
                isPublicAsset: formData.isPublicAsset,
                issuer: formData.issuer !== '' ? formData.issuer : null,
                symbol: formData.Symbol?.toLocaleUpperCase().replace(/\s/g, '')
            }

        return data
    }

    const onSave = (e) => {
        if (handleValidation()) {
            if (!_.isEmpty(fetchAssetData)) {
                const fetchId = fetchAssetData?._id
                dispatch(updateAssets(prepareData(formData), null, currentPage, setCurrentPage))
                // setFetchAssetId(fetchAssetData?._id)
                // setFetchAssetData({});
                // handleEmptyState();


                // setTimeout(() => {
                //     setSave(true)
                // }, 0);
                // const filterdData = 
                // onCheckBtn()
            } else {
                // dispatch(addAssetsDetails(prepareData(formData)))
                // handleEmptyState();
            }
        }
    }

    const getSuggestedData = (e, data) => {
        const name = data.label;

        // Using regular expression to extract text inside parentheses
        const matches = name.match(/\((.*?)\)/);

        // Check if there is a match and extract the value
        const result = matches ? matches[1] : '';

        setFormData({ ...formData, assetName: data.label, Symbol: result, exchangeCountry: data.country })
        setSuggestedAssetId(data.value)


        // setIsCheck(false)
        setIsShowTag(false)
        setIsDisabledIn(true)
        setIsLoad(false)
    }

    const removeName = () => {
        setFormData({ ...formData, assetName: '', Symbol: '', exchangeCountry: null })
        setIsDisabledIn(false)
        setSuggestedAssetId(null)
        setIsShowTag(true)

    }

    return (
        <>
            {isLoading ? <Loading /> : <div className=''>
                <div className="dialog-body">
                    <div className="grid grid-cols-2 mb-6">
                        <div className="form-group grid grid-cols-12 items-center gap-1">
                            <div className="lable-wrapper col-span-4">
                                <Label htmlFor="existingPortfolio" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                    Public Asset
                                </Label>
                            </div>
                            <div className="field-wrapper col-span-8">
                                <Checkbox disabled checked={formData.isPublicAsset} value={formData.isPublicAsset} onCheckedChange={handleOnChange} className="w-4 h-4  rounded-[4px] bg-white border-black" />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-14">
                        <div className="flex gap-6 flex-col">
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Country<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                {/* <div className='field-wrapper col-span-8'>
                                    <Select
                                        onValueChange={(e) => onChangeCountriesOption(JSON.parse(e))}
                                        defaultValue={countriesOptions[0]?.value}
                                        disabled={financialOp?.value === "bond" ? false : formData.isPublicAsset ? true : false}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder={formData.country?.label || "Select Country"}
                                                disabled={financialOp?.value === "bond" ? false : formData.isPublicAsset ? true : false}
                                            >
                                                {formData?.country?.label || "Select Country"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    countriesOptions && countriesOptions.length > 0 ? countriesOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['country'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['country'] ? error['country'] : null}</div>
                                </div> */}

                                <div className="field-wrapper col-span-8">
                                    {financialOp?.value === "bond" ? <CreatableSelect options={countriesOptions} defaultValue={formData.country} value={formData?.country?.value} placeholder={formData?.country?.label || "select country"} onChange={onChangeCountriesOption} classNamePrefix={"react_select"} className='countrySelect' isOptionSelected={(option, selectedOptions) => {
                                        // Custom logic to determine if an option is selected
                                        return option.value === formData?.country.value;
                                    }}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value} />
                                        : <Select
                                            onValueChange={(e) => onChangeCountriesOption(JSON.parse(e))}
                                            defaultValue={countriesOptions[0]}
                                            disabled={financialOp?.value === "bond" ? false : formData.isPublicAsset ? true : false}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Country"
                                                    disabled={financialOp?.value === "bond" ? false : formData.isPublicAsset ? true : false}
                                                >
                                                    {financialOp?.value === "bond" ? formData.country !== null ? formData.country?.label : "Select Country" : formData.isPublicAsset ? "Select Country" : formData.country !== null ? formData.country?.label : "Select Country"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        countriesOptions && countriesOptions.length > 0 ? countriesOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>}
                                    <div className={error['country'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['country'] ? error['country'] : null}</div>
                                </div>




                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Exchange Country<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className='field-wrapper col-span-8'>
                                    <Select
                                        onValueChange={(e) => onChangeExChangeCountriesOption(JSON.parse(e))}
                                        defaultValue={countriesOptions[0]?.value}
                                        disabled={financialOp?.value === "bond" ? true : !formData.isPublicAsset ? true : false}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV2 text-[13px]'
                                                placeholder="Select Exchange Country"
                                                disabled={financialOp?.value === "bond" ? true : !formData.isPublicAsset ? true : false}

                                            >
                                                {/* {!formData.isPublicAsset ? 'select...' : formData.country !== null ? formData.country?.label : "Select Exchange Country"} */}

                                                {!formData.isPublicAsset ? "Select Exchange Country" : formData.exchangeCountry !== null ? formData.exchangeCountry?.label : "Select Exchange Country"}
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    countriesOptions && countriesOptions.length > 0 ? countriesOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['exchangeCountry'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['exchangeCountry'] ? error['exchangeCountry'] : null}</div>
                                </div>
                            </div>
                            <div className='form-group grid grid-cols-12 items-center gap-1'>
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Name<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <div className="col-sm-9" >
                                        <div className='relative'>
                                            <Input className={`form-control h-10 text-[13px] rounded-[12px] shadow-none fs-13 px-2 ${error['assetName'] && 'is-invalid'}`} autoComplete={'off'} disabled={financialOp?.value === "bond" ? false : formData.country === null || suggestedAssetId} name='assetName' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.assetName} />
                                            <div className={error['assetName'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['assetName'] ? error['assetName'] : null}</div>
                                            {isLoad && <div className="absolute" style={{ top: '10px', right: '5px', width: '15px', height: '15px' }} role="status">
                                                <Oval
                                                    visible={true}
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    ariaLabel="oval-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>}
                                            {financialOp?.value === "bond" ? false : !isLoad && formData.isPublicAsset && isDisabledIn && <button disabled={formData.country === null || suggestedAssetId} className={`absolute ${financialOp?.value === "bond" ? false : formData.country === null || suggestedAssetId ? "cursor-not-allowed" : ""}`} style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} onClick={() => removeName()}>
                                                <span className=""><FontAwesomeIcon className='text-red' icon={faXmark} /></span>
                                            </button>}
                                        </div>
                                        <div className='w-100'>
                                            {isShowTag && formData.isPublicAsset && formData.assetName.length >= 2 && suggestedAssetNames?.length && <ul className='suggested_list shadow'>
                                                {suggestedAssetNames.map((item) => {
                                                    return (
                                                        <li className='text-[12px] font-semibold' key={item.label} onClick={(e) => getSuggestedData(e, item)}>{item.label}</li>
                                                    )
                                                })}
                                            </ul>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Symbol{!formData.isPublicAsset && <span style={{ color: 'red' }}>*</span>}
                                    </Label>
                                </div>
                                <div className='field-wrapper col-span-8'>
                                    <Input className={`form-control h-10 text-[13px] rounded-[12px] shadow-none fs-13 px-2  ${error['Symbol'] && 'is-invalid'}`} autoComplete={'off'} disabled={financialOp?.value === "bond" ? false : formData.country === null || formData.isPublicAsset ? true : false} name='Symbol' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.Symbol ? formData?.Symbol?.toLocaleUpperCase()?.replace(/\s/g, '') : ''} />
                                    <div className={error['Symbol'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['Symbol'] ? error['Symbol'] : null}</div>
                                </div>
                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Issuer
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <div className='relative'>
                                        <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none`} autoComplete={'off'} disabled={isDisabledIssuer} name='issuer' placeholder='Issuer' onChange={(e) => setIssuerSearch(e.target.value)} id="inputEmail3" type="text" value={issuerSearch} />
                                        {/* <div className={error['assetName'] ? "invalid-feedback text-[12px] text-red text-xs text-red mt-1" : ''}>{error['assetName'] ? error['assetName'] : null}</div> */}
                                        {isLoadIssuer && <div className="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status">
                                            <Oval
                                                visible={true}
                                                height="15"
                                                width="15"
                                                color="#4fa94d"
                                                ariaLabel="oval-loading"
                                                wrapperStyle={{}}
                                                wrapperclassName=""
                                            />
                                        </div>}
                                        {!isLoadIssuer && isDisabledIssuer && <div className="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status" onClick={() => removeIssuer()}>
                                            <span className=""><FontAwesomeIcon className='text-red' icon={faXmark} /></span>
                                        </div>}
                                    </div>

                                    <div className='w-100'>
                                        {issuerData?.records?.length ? (<ul className='suggested_list shadow'>
                                            {issuerData?.records?.map((item) => {
                                                return (
                                                    <li className='text-[12px] font-semibold' key={item.issuerId} onClick={(e) => onSelectIssuer(item)}>{item.name}</li>
                                                )
                                            })}
                                        </ul>) : ''}
                                    </div>
                                    <div className={error['Issuer'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['Issuer'] ? error['Issuer'] : null}</div>
                                </div>
                            </div>

                            {/* curve input here */}
                            {financialOp?.value === "bond" && <div className={` ${formData.isPublicAsset ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                <div className="label-wrapper col-span-4 ">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Curve:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Select
                                        onValueChange={(e) => onChangeCurveOption(JSON.parse(e))}
                                        defaultValue={activeCurve !== null && activeCurve?.value}
                                        disabled={financialOp?.value === "bond" ? false : formData.country === null}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Exchange"
                                                disabled={financialOp?.value === "bond" ? false : formData.country === null}
                                            >
                                                {activeCurve !== null ? activeCurve?.label : "Select Curve"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    curveOptions && curveOptions.length > 0 ? curveOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>}
                            {/* Issue Date here */}

                            {financialOp?.value === "bond" && <div className={` ${formData.isPublicAsset ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                <div className=" label-wrapper col-span-4">
                                    <Label htmlFor="issueDate" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Issue Date:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <DatePickerCustomInput
                                        onDateSelect={(date) => handleDateChange(date)}
                                        placeholderText='YYYY-MM-DD'
                                        value={date}
                                    />
                                </div>
                            </div>}
                            {/* maturity in tenor select here */}
                            {financialOp?.value === "bond" && <div className={` ${formData.isPublicAsset ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                <div className=" label-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Maturity In Tenure:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Select
                                        onValueChange={(e) => onChangeTenorOption(JSON.parse(e))}
                                        defaultValue={activeTenor !== null && activeTenor?.value}
                                        disabled={financialOp?.value === "bond" && activeCurve !== null ? false : true}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Maturity"
                                                disabled={financialOp?.value === "bond" && activeCurve !== null ? false : true}
                                            >
                                                {activeTenor !== null ? activeTenor?.label : "Select Maturity"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    tenorOptions && tenorOptions.length > 0 ? tenorOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>}

                            {/* maturity in date  here */}
                            {financialOp?.value === "bond" && <div className={` ${formData.isPublicAsset ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                <div className=" label-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Maturity In Date:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <DatePickerCustomInput
                                        onDateSelect={(date) => handleMaturityDateChange(date)}
                                        placeholderText='YYYY-MM-DD'
                                        value={maturityDate}
                                    />
                                </div>
                            </div>}

                            {/* description textarea here */}
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Description
                                        {/* <span style={{ color: 'red' }}>*</span> */}
                                    </Label>
                                </div>
                                <div className='field-wrapper col-span-8'>
                                    <Textarea className={`form-control shadow-none fs-13 px-2 ${error['description'] && 'is-invalid'}`} disabled={formData.country === null} name='description' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" rows="3" placeholder="Description" value={formData.description} />
                                    <div className={error['description'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['description'] ? error['description'] : null}</div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-6 flex-col">
                            <div className={` ${formData.isPublicAsset ? "form-group grid grid-cols-12 items-center gap-1" : "hidden"}`}>
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Financial Instrument
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Select
                                        onValueChange={(e) => onChangeFinancialOption(JSON.parse(e))}
                                        defaultValue={financialOp}
                                        disabled
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Financial Instrument"
                                            >
                                                {financialOp !== null ? financialOp?.label : "Financial Instrument"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    financialOptions && financialOptions.length > 0 ? financialOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Class<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className='field-wrapper col-span-8'>
                                    <Select
                                        onValueChange={(e) => onChangeClassOption(JSON.parse(e))}
                                        defaultValue={categoryOptions && categoryOptions[0]}
                                        disabled={formData.country === null}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Class"
                                                disabled={formData.country === null}
                                            >
                                                {formData.class !== null ? formData.class?.label : 'Select Class'}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    categoryOptions && categoryOptions.length > 0 ? categoryOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['class'] ? "invalid-feedback text-[12px] text-red  " : ''}>{error['class'] ? error['class'] : null}</div>
                                </div>
                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Sub Class<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className='field-wrapper col-span-8'>
                                    <Select
                                        onValueChange={(e) => onChangeSubClassOption(JSON.parse(e))}
                                        defaultValue={subCategoriesOptions && subCategoriesOptions[0]}
                                        disabled={formData.class === null || formData.class === undefined && true}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Sub Class"
                                                disabled={formData.class === null || formData.class === undefined && true}
                                            >
                                                {formData.subClass !== null ? formData.subClass?.label : "Select Sub Class"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    subCategoriesOptions && subCategoriesOptions.length > 0 ? subCategoriesOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['subClass'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['subClass'] ? error['subClass'] : null}</div>
                                </div>
                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className='field-wrapper col-span-8'>
                                    <Select
                                        onValueChange={(e) => onChangeTypeOption(JSON.parse(e))}
                                        defaultValue={typeOptions && typeOptions[0]}
                                        disabled={!formData.country}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Type"
                                                disabled={!formData.country}
                                            >
                                                {formData.type !== null ? formData.type?.label : "Select Type"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    typeOptions && typeOptions.length > 0 ? typeOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['type'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['type'] ? error['type'] : null}</div>
                                </div>
                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Sub Type<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className='field-wrapper col-span-8'>
                                    <Select
                                        onValueChange={(e) => onChangeSubTypeOption(JSON.parse(e))}
                                        defaultValue={subTypeOptions && subTypeOptions[0]}
                                        disabled={formData.type === null || formData.type === undefined && true}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Sub Type"
                                                disabled={formData.type === null || formData.type === undefined && true}
                                            >
                                                {formData.subType !== null ? formData.subType?.label : "Select Sub Type"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    subTypeOptions && subTypeOptions.length > 0 ? subTypeOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['subType'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['subType'] ? error['subType'] : null}</div>
                                </div>
                            </div>



                            {/* Frequency select here */}
                            {financialOp?.value === "bond" && <div className={` ${formData.isPublicAsset ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                <div className=" label-wrapper col-span-4">
                                    <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Frequency:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Select
                                        onValueChange={(e) => onChangeFrequencyOption(JSON.parse(e))}
                                        defaultValue={frequency !== null && frequency?.value}
                                        disabled={financialOp?.value === "bond" ? false : true}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Frequency"
                                                disabled={financialOp?.value === "bond" ? false : true}
                                            >
                                                {frequency !== null ? frequency?.label : "Select Frequency"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    frequencyOptions && frequencyOptions.length > 0 ? frequencyOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['frequency'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['frequency'] ? error['frequency'] : null}</div>
                                </div>
                            </div>}

                            {/* settlementDays input here */}

                            {financialOp?.value === "bond" && <div className={` ${formData.isPublicAsset ? "form-group grid grid-cols-12 items-center gap-1 " : "hidden"}`}>
                                <div className=" label-wrapper col-span-4">
                                    <Label htmlFor="settlementDays" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        settlementDays:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Select
                                        onValueChange={(e) => onChangeSettlementDaysOption(JSON.parse(e))}
                                        defaultValue={settlementDays !== null && settlementDays?.value}

                                        disabled={financialOp?.value === "bond" ? false : true}
                                    >
                                        <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]" >
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select settlementDays"
                                                disabled={financialOp?.value === "bond" ? false : true}
                                            >
                                                {settlementDays !== null ? settlementDays?.label : "Select settlementDays"}

                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    settlementDaysOptions && settlementDaysOptions.length > 0 ? settlementDaysOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <div className={error['settlementDays'] ? "invalid-feedback text-[12px] text-red" : ''}>{error['settlementDays'] ? error['settlementDays'] : null}</div>
                                </div>
                            </div>}

                            {/* coupon rate input here */}
                            {formData.isPublicAsset && financialOp?.value === "bond" && <div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Coupon Rate: <span style={{ color: 'red' }}>*</span>
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input
                                            type="number"
                                            className={`form-control shadow-none fs-13 px-2 ${error['couponRate'] && 'is-invalid'}`} disabled={formData.country === null} name='couponRate' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" placeholder=" Coupon Rate.." value={formData.couponRate} />
                                        {/* <div className={error['description'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['description'] ? error['description'] : null}</div> */}
                                        <div className={error['couponRate'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['couponRate'] ? error['couponRate'] : null}</div>
                                    </div>
                                </div>
                            </div>}
                            {/* face amount input here */}
                            {formData.isPublicAsset && financialOp?.value === "bond" && <div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Face Amount: <span style={{ color: 'red' }}>*</span>
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input
                                            type="number"
                                            className={`form-control shadow-none fs-13 px-2 ${error['faceAmount'] && 'is-invalid'}`} disabled={formData.country === null} name='faceAmount' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" rows="3" placeholder=" Face Amount.." value={formData.faceAmount} />
                                        {/* <div className={error['description'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['description'] ? error['description'] : null}</div> */}
                                        <div className={error['faceAmount'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['faceAmount'] ? error['faceAmount'] : null}</div>
                                    </div>
                                </div>
                            </div>}


                        </div>
                    </div>
                </div>
                <div className="!justify-center !gap-2 m-auto text-center my-10">
                    <Button type="submit" className="min-w-[100px] me-3" onClick={onSave}>Save</Button>
                    <Button type="submit" className="min-w-[100px] h-10" variant={"outline"} onClick={() => null}>Cancel</Button>
                </div>
            </div>}
        </>
    )
}

export default AssetContent
