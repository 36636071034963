import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/ui/table"
import Loading from '../../Loading'
import React from 'react'
import { cn } from "../../../../lib/utils"

const AllocationMonitoringDataTable = (props) => {
    const { isLoading, allocationsData, codeName } = props;

    const updateCodeName = [
        {
            code: "portfolio",
            name: "Portfolio",
            description: "Portfolio"
        },
        ...codeName]


    // new code 
    const getColorCode = (percentage) => {
        switch (true) {
            case parseFloat(percentage.MVP) < parseFloat(percentage.TALT):
                return 'bg-[#fff3e2] text-textV2'; // Amber color for less than 50%
            case parseFloat(percentage.MVP) > parseFloat(percentage.TAUT):
                return 'bg-[#ffe3e3] text-textV2'; // Red color for more than 50%
            default:
                return 'bg-white text-textV2'; // Gray color for others
        }
    };
    // new code end 

    return (
        <div className="rounded-sm border border-borderV1 overflow-hidden">
            {isLoading ? <Loading /> : allocationsData?.length ? <Table className="min-w-[1000px]">
                <TableHeader>
                    <TableRow>
                        {updateCodeName.map((code) => (
                            <TableHead key={code.code} title={code.description} className="text-xs">
                                {code.name}
                            </TableHead>
                        ))}
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {allocationsData.map((codeItem, rowIndex) => (
                        <React.Fragment key={codeItem._id}>
                            {/* Render main row */}
                            <TableRow className={'border-b border-theme'}>
                                {updateCodeName.map((value, colIndex) => (
                                    <TableCell key={`${codeItem._id}-${value.code}`} className={cn(getColorCode(codeItem), `${((colIndex === 0 || colIndex === 1) && (value.code === 'portfolio' || value.code === 'class'))  ? 'border-r border-borderV1' : ''} text-xs`)}
                                        rowSpan={(colIndex === 0 || colIndex === 1) && (value.code === 'portfolio' || value.code === 'class') && codeItem.allocatedSubAssets.length > 0 ? codeItem.allocatedSubAssets.length + 1 : 1}
                                    >
                                        {typeof codeItem[value.code] === 'number' ? parseFloat(codeItem[value.code].toFixed(2)) : codeItem[value.code]}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {codeItem.allocatedSubAssets.map((subAsset, index) => (
                                <TableRow
                                    key={`${codeItem._id}-sub-${index}`}
                                    className={index === codeItem.allocatedSubAssets.length - 1 ? 'border-b border-theme' : ''}
                                >
                                    {updateCodeName.map((value, colIndex) => (
                                        <TableCell key={`${codeItem._id}-sub-${index}-${value.code}`} className={cn(getColorCode(subAsset), `${colIndex === 0 && value.code === 'portfolio' ? 'hidden' : ''} ${colIndex === 1 && value.code === 'class' ? 'hidden' : ''} text-xs`)}>
                                            {typeof subAsset[value.code] === 'number' ? parseFloat(subAsset[value.code].toFixed(2)) : subAsset[value.code]}
                                        </TableCell>
                                    ))}
                                    {/* Render additional columns for sub-asset data */}
                                    {/* Add more columns as needed */}
                                </TableRow>
                            ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table> : <div className='grid grid-cols-1 gap-5 text-center p-5'>
                <div> No Data Available </div>
            </div>}
        </div>
    )
}

export default AllocationMonitoringDataTable