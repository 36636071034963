import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addTypes, updateTypes } from '../../../../store/actions/typeAction';
import { Button } from '@/components/ui/button.jsx';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';


const TypeModel = (props) => {
    const { show, onHide, fetchData, fetchId } = props
    const dispatch = useDispatch()
    const [typeName, setTypeName] = useState({
        name: ''
    });
    const [error, setError] = useState({});

    useEffect(() => {
        if (show === false) {
            setTypeName({
                name: ''
            })
        }
    }, [show])

    useEffect(() => {
        if (fetchId !== null) {
            setTypeName({
                name: fetchData && fetchData?.name,
            })
        }
    }, [fetchData, fetchId])

    const onChangeHandel = (e) => {
        setTypeName({ ...typeName, name: e.target.value })
        setError({})
    }
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (typeName.name === '') {
            errors['typeName'] = 'Please enter Type Name';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };
    const onSave = () => {
        if (handleValidation()) {
            if (fetchId !== null) {
                const updateData = { id: fetchId, name: typeName.name }
                dispatch(updateTypes(updateData))
            } else {
                dispatch(addTypes(typeName))
            }
            onHide()
        }
    }

    return (
        <>
        <Dialog open={show} onOpenChange={onHide}>
            <DialogContent className="sm:max-w-[460px] gap-10">
                <DialogHeader>
                    <DialogTitle className="text-center">{fetchId ? 'Edit Type' : 'Add Type'}</DialogTitle>
                </DialogHeader>
                <div className="dialog-body">
                <div className="grid grid-cols-1 gap-5">
                        <div className="form-group">
                            <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                            Type Name:<span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Input
                                className={`form-control shadow-none ${error['typeName'] && 'is-invalid'}`}
                                id="name" type="text" name="typeName" placeholder="Type Name" onChange={(e) => onChangeHandel(e)} value={typeName.name} />
                            <div className={` ${error['typeName'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['typeName'] ? error['typeName'] : null}</div>
                        </div>
                    </div>
                </div>
                <DialogFooter className={"!justify-center !gap-2"}>
                    <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                    <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
        </>
    )
}

export default TypeModel