import React, { useState } from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../../components/ui/tabs'
import AddManulDataTable from '../tabs-content/AddManulDataTable'
import PreviewContent from '../tabs-content/PreviewContent'

const EnterDataContents = () => {
  const [key, setKey] = useState("manual-data")
  const [isUpload, setIsUpload] = useState(false)
  const handleTabChange = (value) => {
    setKey(value)
  }
  return (
    <div className='enter-manual-data-content'>
      <Tabs defaultValue={key} value={key}  className="w-[full]" onValueChange={handleTabChange}>
        <div className="tabs-wrapper mb-6">
          <TabsList className="inline-flex shadow-none p-0 w-[240px]">
            <TabsTrigger className="px-5 py-2 flex-grow w-full !text-[#6C7B90] data-[state=active]:!text-theme border border-[#DADFD8] rounded-none data-[state=active]:border-theme data-[state=active]:!bg-[#F0F6FC] rounded-tl-[4px] rounded-bl-[4px]" value="manual-data">Enter Manually</TabsTrigger>
            <TabsTrigger className="px-5 py-2 flex-grow w-full !text-[#6C7B90] data-[state=active]:!text-theme border border-[#DADFD8] rounded-none data-[state=active]:border-theme data-[state=active]:!bg-[#F0F6FC] rounded-tr-[4px] rounded-br-[4px]" value="preview" > Preview</TabsTrigger>
          </TabsList>
        </div>
        <div className="">
          <TabsContent value="manual-data" className="m-0">
                <AddManulDataTable setKey={setKey} isUpload={isUpload} setIsUpload={setIsUpload}/>
          </TabsContent>
          <TabsContent value="preview">
              <PreviewContent keyName={key} isUpload={isUpload} setIsUpload={setIsUpload}/>
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}

export default EnterDataContents