import { assetsAuthorizeActionType } from '../../constants';

const assetsAuthorizeV2Reducer = (state = [], action) => {
    switch (action.type) {
        case assetsAuthorizeActionType.FETCH_ASSETS_AUTHORIZE_V2:
            return action.payload;
        case assetsAuthorizeActionType.FETCH_ASSETS_AUTHORIZE_V2:
            return action.payload;
        case assetsAuthorizeActionType.ADD_ASSETS_AUTHORIZE_V2:
            return action.payload;
        case assetsAuthorizeActionType.UPDATE_ASSETS_AUTHORIZE_V2:
            return action.payload;
        case assetsAuthorizeActionType.DELETE_ASSETS_AUTHORIZE_V2:
            return action.payload;
        default:
            return state;
    }
};
export default assetsAuthorizeV2Reducer