import { curveActionType } from '../../constants';

const curveReducer = (state = {}, action) => {
    switch (action.type) {
        case curveActionType.FETCH_CURVES:
            return action.payload;
        default:
            return state;
    }
};
export default curveReducer