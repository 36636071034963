import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog'
import { Button } from '../ui/button'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../ui/select'
import { addPortfolio, updatePortfolio } from '../../store/actions/portfolioAction'
import { Filters } from '../../constants'

const AddPortfolioModal = (props) => {
    const { show, onHide, fetchData, fetchId, PortfolioGroup, slug, setFetchData, setFetchId } = props
    const dispatch = useDispatch();
    const [ctName, setCtName] = useState({
        name: '',
        group: null
    })
    const [error, setError] = useState({});

    useEffect(() => {
        if (show === false) {
            setCtName({
                name: '',
                group: null
            })
            setFetchData && setFetchData(null);
            setFetchId && setFetchId(null);
            setError({})
        } else {
            if(!fetchId || fetchId === null){
                setCtName({
                    name: '',
                    group: null
                })
                setFetchData && setFetchData(null);
                setFetchId && setFetchId(null);
                setError({})
            }
        }
    }, [show])

    useEffect(() => {
        if (fetchId !== null) {
            setCtName({
                id: fetchData && fetchData?._id ? fetchData?._id : null,
                name: fetchData && fetchData?.portfolios ? Object.keys(fetchData?.portfolios).length !== 0 ? fetchData?.portfolios?.name : '' : fetchData?.portfolio ? Object.keys(fetchData?.portfolio).length !== 0 ? fetchData?.portfolio?.name : '' : '',
                group: fetchData && fetchData?.portfolioGroup ? Object.keys(fetchData?.portfolioGroup).length !== 0 ? { value: fetchData?.portfolioGroup?._id, label: fetchData?.portfolioGroup?.name } : null : null,
            })
        }
    }, [fetchData, fetchId])

    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (ctName.name === '') {
            errors['name'] = 'Please enter name';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };
    const onSave = () => {
        if (handleValidation()) {
            if (fetchId !== null) {
                const data = {
                    portfolioGroupMappingId: ctName.id,
                    name: ctName.name,
                    portfolioGroupId: ctName !== null ? ctName.group?.value === null ? null : ctName.group?.value : null
                }
                dispatch(updatePortfolio(fetchId, data, Filters.OBJ, slug))
            } else {
                const data = {
                    name: ctName.name,
                    portfolioGroupId: ctName !== null ? ctName.group?.value === null ? null : ctName.group?.value : null
                }
                dispatch(addPortfolio(data, Filters.OBJ, slug))
            }
            setCtName({
                name: '',
                group: null
            })
            setFetchData && setFetchData(null);
            setFetchId && setFetchId(null);
            setError({})
            onHide()
        }
    }

    const PortfolioGroupOptions = PortfolioGroup && PortfolioGroup.length >= 1 ? [{ value: null, label: 'None' }, ...PortfolioGroup.map((item) => ({
        value: item?._id,
        label: item?.name,
    })),
    ]
        : [];

    const handleSelect = (event) => {
        const portfolioGroupOption = JSON.parse(event)
        setCtName((prevState) => {
            return {
                ...prevState,
                group: portfolioGroupOption,
            };
        });
        setError({})
    }


    return (
        <Dialog open={show} onOpenChange={() => onHide(false)}>
            <DialogContent className="sm:max-w-[460px] gap-10">
                <DialogHeader>
                    <DialogTitle className="text-center">{fetchId && fetchData ? "Edit Portfolio" : "Add Portfolio"}</DialogTitle>
                </DialogHeader>
                <div className="dialog-body">
                    <div className="grid grid-cols-1 gap-5">
                        <div className="form-group">
                            <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                Select Portfolio Group
                            </Label>
                            <Select
                                onValueChange={(e) => handleSelect(e)}
                                defaultValue={ctName.group !== null ? ctName.group : ctName.group}
                            >
                                <SelectTrigger className="w-full">
                                    <SelectValue
                                        className='text-textV1'
                                        placeholder="Select Class"
                                    >
                                        {ctName.group !== null ? ctName.group?.label : "Select Class"}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {
                                            PortfolioGroupOptions && PortfolioGroupOptions.length > 0 ? PortfolioGroupOptions.map((item, index) => {
                                                return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                            }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                        }
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                            <div className={` ${error['portfolioGroup'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['portfolioGroup'] ? error['portfolioGroup'] : null}</div>
                        </div>
                        <div className="form-group">
                            <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                Portfolio Name <span className='text-red'>*</span>
                            </Label>
                            <Input
                                id="name"
                                placeholder="Portfolio Name"
                                name="name"
                                defaultValue={ctName.name}
                                onChange={(e) => setCtName({
                                    ...ctName, name: e.target.value
                                })}
                            />
                            <div className={`${error['name'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['name'] ? error['name'] : null}</div>
                        </div>
                    </div>
                </div>
                <DialogFooter className={"!justify-center !gap-2"}>
                    <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                    <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={() => {
                        onHide(false); setCtName({
                            name: '',
                            group: null
                        });
                        setError({});
                        setFetchData && setFetchData(null);
                        setFetchId && setFetchId(null);
                    }}>Cancel</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default AddPortfolioModal