import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { deleteTypes, fetchTypes } from '../../../../store/actions/typeAction';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown.jsx';
import { Button } from '@/components/ui/button.jsx';
import { DotsHorizontalIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header';
import { DataTableNew } from '../../../../shared/common/table-components/tasks/components/data-table';
import TypeModel from '../models/TypeModel';
import IconAdd from '../../../../components/svg/IconAdd';
import { Card } from '@/components/ui/Card';

const TypeContent = () => {
    const dispatch = useDispatch();
    const { types } = useSelector((state) => state);

    const [modalShow, setModalShow] = useState(false);
    const [fetchData, setFetchData] = useState(null);
    const [fetchId, setFetchId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // Number of items to display per page

    const onChange = (filter) => {
        dispatch(fetchTypes('', filter))
    }

    const handelDelete = (data) => {
        // let payloadData = { types: [] };
        // payloadData.types.push(data.id);
        dispatch(deleteTypes(data.id));
    };

    const handelEdit = (data) => {
        setModalShow(true);
        setFetchData(data);
        setFetchId(data.id);
        // const updateData = {data,id}
    };
    // Pagination functions
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;


    const data = Array.isArray(types?.records) && types?.records.length > 0 ? types?.records.map((item, index) => {
        return {
            index: indexOfFirstItem + index + 1,
            id: item.id,
            name: item.name
        }
    }) : []

    const columns = [
        {
            accessorKey: "index",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.index}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Type" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.name}</span>
            },
            enableSorting: true,

        },
        {
            id: "actions",
            enableHiding: false,
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => handelEdit(row?.original)}><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Edit</DropdownMenuItem>

                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`} onClick={() => handelDelete(row?.original)}><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /> Remove</DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        }
    ]

    const rightHeaderButtons = [
        {
          icon: <IconAdd className="w-4 h-4 text-white transition duration 200" />,
          text: 'Add Type',
          onClick: () => setModalShow(true),
          variant: '',
          additionalClass: ""
        },
        // Add more button configurations as needed
      ];

    return (
        <>
            <section className='p-2 md:px-5'>
                <Card className={'p-2 md:px-5 bg-white shadow-[rgba(99, 99, 99, 0.2) 0px 2px 8px 0px]'}>
                    <DataTableNew data={data} columns={columns} onChange={onChange} filterName={'name'} 
                     isRightNew={true}
                     isOldHeaderHide={true}
                     isRightSearch={true}
                     isRightHeaderButtons={true}
                     rightHeaderButtons={rightHeaderButtons}
                    />
                    <TypeModel
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        fetchData={fetchData}
                        fetchId={fetchId}
                        noValidate
                    />
                </Card>
            </section>
        </>
    )
}

export default TypeContent