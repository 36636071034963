import React, { useContext, useEffect, useState } from 'react'
import { AssentContext } from '../Asset';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import _ from 'lodash';
import moment from 'moment-js';
import { removeAssetsDetails } from '../../../../store/actions/AssetAction';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Checkbox } from "@/components/ui/checkbox"
import { Textarea } from '../../../../components/ui/textarea';
import { DatePicker } from '../../../../components/ui/datepicker'
import { FromDatePicker } from '../../../../components/ui/DatePickerNew';
import { statusType } from '../../../../static/static';
import { useSelector } from 'react-redux';

const AssetRemovalContent = () => {
    const { fetchAssetData, setFetchAssetData, setAssetsData, assetsData } = useContext(AssentContext);
    const { metaData } = useSelector(state => state);
    const dispatch = useDispatch()
    const { slug } = useParams();
    const [updateId, setUpdateId] = useState(null)
    const [error, setError] = useState({});
    const [statusTypes, setStatusTypes] = useState(null)
    const [formData, setFormData] = useState({
        removalDate: '',
        reason: '',
        notes: ''
    })

    useEffect(() => {
        setFormData({
            removalDate: '',
            reason: '',
            notes: ''
        })
        setUpdateId(null)
    }, [slug])


    useEffect(() => {
        if (fetchAssetData && fetchAssetData.status === 'removed') {
            setStatusTypes(statusTypeOptions[1])
        } else {
            setStatusTypes(statusTypeOptions[0])
        }
    }, [fetchAssetData])

    useEffect(() => {
        if (!_.isEmpty(fetchAssetData) && statusTypes !== null) {
            if (statusTypes.label === 'Yes' && fetchAssetData.status === 'removed' && fetchAssetData.removeReason !== null) {
                setFormData({
                    removalDate: moment(new Date(fetchAssetData.removeDate)).format('YYYY-MM-DD'),
                    reason: fetchAssetData.removeReason ? fetchAssetData.removeReason : '',
                    notes: fetchAssetData.removeNote ? fetchAssetData.removeNote : ''
                })
            } else {
                setFormData({
                    removalDate: moment(new Date()).format('YYYY-MM-DD'),
                    reason: '',
                    notes: ''
                })
            }
            setUpdateId(fetchAssetData?._id)
            setError({})

        }

    }, [statusTypes])

    const statusTypeOptions = statusType && statusType?.length >= 1 ? statusType?.map((item) => {
        return { value: item.id, label: item.label }
    }) : []




    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
        setError({})
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        // if (formData.removalPrice === '') {
        //     errors['removalPrice'] = 'Please enter Removal Price';
        //     isValid = false;
        // }
        // if (formData.removalQuantity === '') {
        //     errors['removalQuantity'] = 'Please select Removal Quantity';
        //     isValid = false;
        // }
        if (statusTypes?.label === 'Yes' && formData.removalDate === '') {
            errors['removalDate'] = 'Please select Removal Date';
            isValid = false;
        }
        if (formData.reason === '') {
            errors['reason'] = 'Please enter Reason';
            isValid = false;
        }
        // if (formData.notes === '') {
        //     errors['notes'] = 'Please enter Notes';
        //     isValid = false;
        // }
        setError(errors);
        return isValid;
    };

    const onChangeChartOption = (event) => {
        setStatusTypes(event)
        setError({})
    }

    const handleEmtyState = () => {
        setFormData({
            // removalPrice: '',
            // removalQuantity: '',
            removalDate: moment(new Date).format('YYYY-MM-DD'),
            reason: '',
            notes: ''
        })
    }

    const prepareData = (formData) => {
        let data;
        if (statusTypes?.label === 'Yes') {
            data = {
                assetId: updateId,
                remove: 'Yes',
                // removePrice: formData.removalPrice,
                removeDate: moment(formData.removalDate).format('MM/DD/YYYY'),
                // removeReason: formData.reason,
                reason: formData.reason,
                // removeNote: formData.notes,
                note: formData.notes
            }
        } else {
            data = {
                assetId: updateId,
                remove: 'No',
                // removePrice: formData.removalPrice,
                // removeDate: moment(formData.removeDate).format('MM/DD/YYYY'),
                reason: formData.reason,
                note: formData.notes
            }
        }

        return data
    }

    const onSave = (e) => {
        if (handleValidation()) {
            if (!_.isEmpty(fetchAssetData) && updateId) {
                dispatch(removeAssetsDetails(prepareData(formData), updateId, setAssetsData, metaData))
                setFetchAssetData({});
                handleEmtyState();
            } else {
                // dispatch(addAssetsDetails(prepareData(formData)))
                // handleEmtyState();
            }
        }
    }
    return (
        <div className=''>
            <div className="dialog-body">
                <div className="grid grid-cols-2 gap-14">
                    <div className="flex gap-6 flex-col">
                        <div className="form-group grid grid-cols-12 items-center gap-1">
                            <div className="lable-wrapper col-span-4">
                                <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                    Remove:
                                </Label>
                            </div>
                            <div className='field-wrapper col-span-8'>
                                <Select
                                    onValueChange={(e) => onChangeChartOption(JSON.parse(e))}
                                    defaultValue={fetchAssetData && fetchAssetData.status === 'removed' ? statusTypeOptions[1] : statusTypeOptions[0]}

                                >
                                    <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                        <SelectValue
                                            className='text-textV1'
                                            placeholder="Select Remove"

                                        >
                                            {statusTypes !== null ? statusTypes?.label : "Select Remove"}

                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {
                                                statusTypeOptions && statusTypeOptions.length > 0 ? statusTypeOptions.map((item, index) => {
                                                    return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                            }
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                        <div className="form-group grid grid-cols-12 items-center gap-1">
                            <div className="lable-wrapper col-span-4">
                                <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                    Remove Date:{statusTypes?.label === 'Yes' && <span style={{ color: 'red' }}>*</span>}
                                </Label>
                            </div>
                            <div className='field-wrapper col-span-8'>
                                <FromDatePicker onDateSelect={(date) => { setFormData({ ...formData, removalDate: date }); setError({}) }}
                                disabled={statusTypes?.label === 'No' && true}
                                name='removalDate' value={formData.removalDate}
                                    placeholderText='YYYY-MM-DD'
                                    selectedFromDate={formData.removalDate}
                                />
                                {/* <DatePicker disabled={statusTypes?.label === 'No' && true} className='w-[100%]' onDateSelect={(date) => { setFormData({ ...formData, removalDate: date }); setError({}) }} name='removalDate' value={formData.removalDate} /> */}
                                {/* <input disabled={statusTypes?.label === 'No' && true} className={`form-control shadow-none ${error['removalDate'] && 'is-invalid'}`} id="basic-form-dob" name='removalDate' type="date" value={formData.removalDate} onChange={(e) => onChangeHandel(e)} /> */}
                                {statusTypes?.label === 'No' && <div className={error['removalDate'] ? "text-red" : ''}>{error['removalDate'] ? error['removalDate'] : null}</div>}
                            </div>
                        </div>
                        <div className="form-group grid grid-cols-12 items-center gap-1">
                            <div className="lable-wrapper col-span-4">
                                <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                    Reason:<span style={{ color: 'red' }}>*</span>
                                </Label>
                            </div>
                            <div className='field-wrapper col-span-8'>
                                <Textarea className={`form-control shadow-none`} id="basic-form-textarea" name='reason' rows="3" value={formData.reason} placeholder="Description" onChange={(e) => onChangeHandel(e)}></Textarea>
                                <div className={error['reason'] ? "text-red" : ''}>{error['reason'] ? error['reason'] : null}</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-6 flex-col">
                        <div className="form-group grid grid-cols-12 items-center gap-1">
                            <div className="lable-wrapper col-span-4">
                                <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                    Notes:
                                </Label>
                            </div>
                            <div className='field-wrapper col-span-8'>
                                <Textarea className={`form-control shadow-none`} id="basic-form-textarea" name='notes' rows="3" value={formData.notes} placeholder="Description" onChange={(e) => onChangeHandel(e)}></Textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="!justify-center !gap-2 m-auto text-center my-10">
                <Button type="submit" className="min-w-[100px] me-3" onClick={onSave}>Save</Button>
                <Button type="submit" className="min-w-[100px] h-10" variant={"outline"} onClick={() => null}>Cancel</Button>
            </div>
        </div>
    )
}

export default AssetRemovalContent
