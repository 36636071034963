import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { financeSearch } from '../../../../store/actions/SearchFinancialAssetAction';
import { addAssets } from '../../../../store/actions/AssetAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Oval } from 'react-loader-spinner';
import { Textarea } from '../../../../components/ui/textarea';
import { addIssuer } from '../../../../store/actions/issuerAction';
const IssuerModel = (props) => {
    const dispatch = useDispatch()
    const { setAssetsData } = props;
    const { countries, issuerData, financeSearchData } = useSelector(state => state)
    const [error, setError] = useState({});
    const [suggestedAssetNames, setSuggestedAssetNames] = useState(null);
    const [suggestedAssetId, setSuggestedAssetId] = useState(null);
    const [allFinanceSearchData, setAllFinanceSearchData] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [isShowTag, setIsShowTag] = useState(true)
    const [isDisabledIn, setIsDisabledIn] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        country: null,
        Symbol: '',
        parentIssuerOp: null
    })

    useEffect(() => {
        if (props.show === false) {
            setSuggestedAssetNames(null)
            setSuggestedAssetId(null)
            setIsCheck(false)
            setIsLoad(false)
            setIsDisabledIn(false)
            setIsShowTag(true)
            setFormData({
                name: "",
                description: "",
                country: null,
                Symbol: '',
                parentIssuerOp: null
            })
        }
    }, [props.show])


    useEffect(() => {
        if (formData.name.length >= 2) {
            function removeContentInRoundBracketsAndAfter(inputString) {
                // Use a regular expression to match content within round brackets, everything after an opening parenthesis, and everything after a period or comma
                const result = inputString.replace(/\([^)]*\)|\(.*/g, '').replace(/[.,].*/g, '');

                return result;
            }

            const data = {
                name: removeContentInRoundBracketsAndAfter(formData.name?.trim()),
                country: formData.country?.value ? formData.country.value : '',
                instrument: '',
                exchange: ''
            }
            setIsLoad(true)
            // dispatch(financeSearch(data))
        }
    }, [formData.name])

    useEffect(() => {
        if (financeSearchData) {
            setAllFinanceSearchData(financeSearchData)
        }
    }, [financeSearchData])

    useEffect(() => {
        if (allFinanceSearchData && allFinanceSearchData.length >= 1) {
            // Transform the response to include both name and symbol
            const suggestions = allFinanceSearchData.map(item => ({
                label: `${item.name ? item.name : ''} (${item.symbol ? item.symbol : ''}) | ${item.exchange?.name ? item.exchange.name : ''}`,
                value: item._id,
                country: item.country
            }));
            setSuggestedAssetNames(suggestions);
            setIsLoad(false)
        } else {
            setSuggestedAssetNames(null);
            setIsLoad(false)
        }
    }, [allFinanceSearchData])



    const countriesOptions = countries && countries.length >= 1 ? countries.map((item) => {
        return { value: item._id, label: item.countryName }
    }) : []

    const issuerTypesOptions = issuerData && Array.isArray(issuerData.records) ? issuerData?.records.map((item) => {
        return { value: item._id, label: item.name }
    }) : []

    const onChangeCountriesOption = (event) => {
        setFormData({ ...formData, country: event })
        setError({})
    }
    const onChangeParentIssuerOpOption = (event) => {
        setFormData({ ...formData, parentIssuerOp: event })
        setError({})
    }

    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError({})
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (formData.name === '') {
            errors['name'] = 'Please enter Name';
            isValid = false;
        }
        if (formData.Symbol === '') {
            errors['Symbol'] = 'Please enter Short code';
            isValid = false;
        } else if (formData.Symbol.length > 8) {
            errors['Symbol'] = 'Short code must be capitalized and have a maximum length of 8 characters';
            isValid = false;
        } else if (!/^[a-zA-Z0-9]+$/.test(formData.Symbol)) {
            errors['Symbol'] = 'Short code must contain only letters and numbers';
            isValid = false;
        }
        if (formData.country === null) {
            errors['country'] = 'Please select country';
            isValid = false;
        }
        // if (formData.description === '') {
        //     errors['description'] = 'Please enter description';
        //     isValid = false;
        // }
        setError(errors);
        return isValid;
    };



    const onSave = (e) => {
        if (handleValidation()) {
            dispatch(addIssuer({
                _id: null, // if _id exist then it update and if _id not exist
                name: formData.name,
                description: formData.description,
                country: formData.country !== null ? formData.country.value : null,
                shortCode: formData.Symbol?.toLocaleUpperCase().replace(/\s/g, ''),
                parentIssuer: formData.parentIssuerOp !== null ? formData.parentIssuerOp.value : null
            }, setAssetsData, 1, null))
            setSuggestedAssetNames(null)
            setSuggestedAssetId(null)
            setIsCheck(false)
            setFormData({
                name: "",
                description: "",
                country: null,
                Symbol: '',
                parentIssuerOp: null
            })
            props.onHide();
        }

    }

    const getSuggestedData = (e, data) => {
        const name = data.label;

        // Using regular expression to extract text inside parentheses
        const matches = name.match(/\((.*?)\)/);

        // Check if there is a match and extract the value
        const result = matches ? matches[1] : '';

        setFormData({ ...formData, name: data.label, Symbol: result})
        setSuggestedAssetId(data.value)


        // setIsCheck(false)
        setIsShowTag(false)
        setIsDisabledIn(true)
        setIsLoad(false)
    }

    const removeName = () => {
        setFormData({ ...formData, name: '', Symbol: '',})
        setIsDisabledIn(false)
        setSuggestedAssetId(null)
        setIsShowTag(true)

    }


    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[40%] gap-0 p-0">
                    <DialogHeader className="p-5 border-b border-borderV1">
                        <DialogTitle className="text-start">{'Add Issuer'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body p-5">

                        <div className="grid grid-cols-1 gap-14">
                            <div className="flex gap-6 flex-col">


                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Name:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <div className='relative'>
                                            <Input className={`w-full h-10 text-[13px] rounded-[12px] form-control shadow-none  ${error['name'] && 'is-invalid'}`} autoComplete={'off'} disabled={false} placeholder={'Name'} name='name' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="text" value={formData.name} />
                                            <div className={error['name'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['name'] ? error['name'] : null}</div>
                                            {/* {isLoad && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status">
                                                <Oval
                                                    visible={true}
                                                    height="15"
                                                    width="15"
                                                    color="#4fa94d"
                                                    ariaLabel="oval-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </div>} */}
                                            {/* {!isLoad && isDisabledIn && <div class="absolute" style={{ top: '8px', right: '5px', width: '15px', height: '15px' }} role="status" onClick={() => removeName()}>
                                                <span className=""><FontAwesomeIcon className='text-red' icon={faXmark} /></span>
                                            </div>} */}
                                        </div>

                                        {/* <div className='w-100'>
                                            {isShowTag && formData.name.length >= 2 && suggestedAssetNames?.length && (<ul className='suggested_list shadow'>
                                                {suggestedAssetNames.map((item) => {
                                                    return (
                                                        <li className='text-[12px] font-semibold' key={item.label} onClick={(e) => getSuggestedData(e, item)}>{item.label}</li>
                                                    )
                                                })}
                                            </ul>)}
                                        </div> */}

                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Short Code:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input className={`w-full h-10 text-[13px] rounded-[12px] ${error['Symbol'] && 'is-invalid'}`} autoComplete={'off'} name='Symbol' onChange={(e) => onChangeHandel(e)} placeholder={'Symbol'} id="inputEmail3" type="text" value={formData.Symbol.toLocaleUpperCase().replace(/\s/g, '')} />

                                        <div className={error['Symbol'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['Symbol'] ? error['Symbol'] : null}</div>
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Country:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeCountriesOption(JSON.parse(e))}
                                            defaultValue={countriesOptions[0]}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Country"
                                                >
                                                    {isCheck ? null : formData.country !== null ? formData.country?.label : "Select Country"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        countriesOptions && countriesOptions.length > 0 ? countriesOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['country'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['country'] ? error['country'] : null}</div>
                                    </div>
                                </div>



                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Parent Issuer:
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeParentIssuerOpOption(JSON.parse(e))}
                                            defaultValue={formData.parentIssuerOp !== null ? formData.parentIssuerOp : null}
                                        >
                                            <SelectTrigger className="w-full h-10 text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Parent Issuer"
                                                >
                                                    {formData.parentIssuerOp !== null ? formData.parentIssuerOp?.label : "Select Parent Issuer"}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        issuerTypesOptions && issuerTypesOptions.length > 0 ? issuerTypesOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        {/* <div className={error['subType'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['subType'] ? error['subType'] : null}</div> */}
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Description:
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Textarea
                                            className={`form-control shadow-none fs-13 px-2 ${error['description'] && 'is-invalid'}`} name='description' onChange={(e) => onChangeHandel(e)} id="basic-form-textarea" rows="3" placeholder="Description" value={formData.description} />
                                        {/* <div className={error['description'] ? "invalid-feedback text-xs text-red mt-1" : ''}>{error['description'] ? error['description'] : null}</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap-2 p-5"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default IssuerModel
