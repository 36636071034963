import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { modelSentry } from "../../../../static/static";
// import { fetchAssetsDetails }  '../../../../store/actions/AssetAction';
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { FromDatePicker } from "../../../../components/ui/DatePickerNew";
import moment from "moment-js";
import { addSentry } from "../../../../store/actions/sentryAction";
import AssetSelect from "../selector/AssetSelect";
import SelectDataSelect from "../selector/SelectDataSelect";
const CreateAnalysis = (props) => {
  const { show, onHide, setIsChecked, setIsCheckDisabled } = props;
  const { } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [startFormDate, setStartFormDate] = useState(null);
  const [startToDate, setStartToDate] = useState(null);
  const [isCheck, setIsCheck] = useState(false);
  const [assetIssuerId, setAssetIssuerId] = useState(null);

  const [formData, setFormData] = useState({
    assets: null,
        model: null,
        from: "",
        to: "",
        dataSet: null,
  });
  
  useEffect(() => {
    if (!show) {
      setFormData({
        assets: null,
        model: null,
        from: "",
        to: "",
        dataSet: null,
      })
      setIsCheck(false)
    }
  }, [show]);

  const onSelectAsset = (value) => {
    setFormData({...formData, assets: value, dataSet: null})
    setAssetIssuerId(value)
  }

  const onSelectIData = (value) => {
    setFormData({...formData, dataSet: value})
  }

  const onChangModelSentryOptions = (value) => {
    setFormData({ ...formData, model: value });
    setError({});
  };

  // const modelSentryOptions =
  //   modelSentry && modelSentry?.length >= 1
  //     ? modelSentry?.map((item) => {
  //       return { value: item.id, label: item.label, symbol: item.symbol };
  //     })
  //     : [];
  const modelSentryOptions = [{ value: 'Daily Returns', label: 'Daily Returns' }]
      

  const handleValidation = () => {
    let errors = {};
    let isValid = true;

    if (formData.assets === null && !isCheck) {
      errors["asset"] = "Please select an asset";
      isValid = false;
    }
    if (formData.model === null) {
      errors["modelType"] = "Please select a Model type";
      isValid = false;
    }
    if (formData.from === "") {
      errors["from"] = "Please select a valid from date";
      isValid = false;
    }
    if (formData.to === "") {
      errors["to"] = "Please select a valid to date";
      isValid = false;
    }
    if (formData.dataSet === null) {
      errors["dataSet"] = "Please select a Data Set";
      isValid = false;
    }
    setError(errors);
    return isValid;
  };


  const prepareData = () => {
    // ?&start_date=2023-1-1&end_date=2024-1-1&group=Assets&model=KNN&dataset_key=Price - Close&dataset_value=close&display=all
   const data = {
    id: assetIssuerId !== null ? assetIssuerId?.value : null,
    field_id: formData?.dataSet !== null ? formData?.dataSet?.fieldId === null ? '' : formData?.dataSet?.fieldId : '',
    start_date: startFormDate !== null ? moment(startFormDate).format("YYYY-MM-DD") : '',
    end_date: startToDate !== null ? moment(startToDate).format("YYYY-MM-DD") : '',
    group: formData?.dataSet !== null ? formData?.dataSet?.group : '',
    model: formData?.model !== null ? formData?.model?.symbol : '',
    dataset_key: formData?.dataSet !== null ? formData?.dataSet?.label : '',
    dataset_value: formData?.dataSet !== null ? formData?.dataSet?.value : '',
    display: 'all'
   }
    return data
  }
  const onClickSave = () => {
    if (handleValidation()) {
      const data = prepareData();
      setIsChecked(false)
      localStorage.setItem('runAnalysis', JSON.stringify({ ...data, table: formData.table, scatterChart: formData.scatterChart, timeSeriesChart: formData.timeSeriesChart }))
      dispatch(addSentry(data))
      setIsCheck(false)
      setIsCheckDisabled(false)
      onHide()
    }
  };


  return (
    <Dialog open={show} onOpenChange={onHide} className="p-2">
      <DialogContent className="xl:max-w-[60%] sm:max-w-[70%] gap-6 z-50">
        <DialogHeader className="mb-4">
          <DialogTitle className="text-center">{"Run Analysis"}</DialogTitle>
        </DialogHeader>
        <div className="dialog-body">
          <div className="grid grid-cols-1 lg:grid-cols-1 mb-5 gap-5">
           
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper lg:col-span-2 col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Assets:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper lg:col-span-10  col-span-8">
                <AssetSelect onSelectAsset={onSelectAsset} isDisabled={isCheck}/>
                {error["asset"] && (
                  <div
                    className={` ${error["asset"] ? "text-red text-[10px]" : ""
                      } px-2 py-1`}
                  >
                    {error["asset"] ? error["asset"] : null}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="username"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  From:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <FromDatePicker
                  onDateSelect={(date) => {
                    setFormData({ ...formData, from: date });
                    setStartFormDate(date);
                  }}
                  placeholderText="YYYY-MM-DD"
                  selectedFromDate={startFormDate}
                />
                <div
                  className={` ${error["from"] ? "text-red text-[10px]" : ""
                    } px-2 py-1`}
                >
                  {error["from"] ? error["from"] : null}
                </div>
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="username"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  To:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <FromDatePicker
                  onDateSelect={(date) => {
                    setFormData({ ...formData, to: date });
                    setStartToDate(date);
                  }}
                  placeholderText="YYYY-MM-DD"
                  minDate={startFormDate}
                  selectedFromDate={startFormDate}
                />
                <div
                  className={` ${error["to"] ? "text-red text-[10px]" : ""
                    } px-2 py-1`}
                >
                  {error["to"] ? error["to"] : null}
                </div>
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  select analytics:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <Select
                  onValueChange={(e) =>
                    onChangModelSentryOptions(JSON.parse(e))
                  }
                  defaultValue={formData.model}
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Model"
                    >
                      {formData.model !== null
                        ? formData.model.label
                        : "Select analytics"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {modelSentryOptions && modelSentryOptions.length > 0 ? (
                        modelSentryOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <div
                  className={` ${error["modelType"] ? "text-red text-[10px]" : ""
                    } px-2 py-1`}
                >
                  {error["modelType"] ? error["modelType"] : null}
                </div>
              </div>
            </div>
            <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Select Data:<span style={{ color: "red" }}>*</span>
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <SelectDataSelect onSelectIData={onSelectIData} isDisabled={formData.assets === null && formData.issuer === null} id={formData.group === false ? formData.assets?.value : formData.issuer?.value} 
                group={formData.group === false ? 'Assets' : 'Issuers'} value={formData.dataSet}
                />
                <div
                  className={` ${error["dataSet"] ? "text-red text-[10px]" : ""
                    } px-2 py-1 `}
                >
                  {error["dataSet"] ? error["dataSet"] : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogFooter className={"!justify-center !gap-2 p-5"}>
          <Button
            type="submit"
            size="sm"
            className="min-w-[100px] h-[34px]"
            onClick={onClickSave}
          >
            Save
          </Button>
          <Button
            type="submit"
            size="sm"
            className="min-w-[100px] h-[34px]"
            variant={"outline"}
            onClick={onHide}
          >
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateAnalysis;
{/* <div className="form-group grid grid-cols-12 items-center gap-1">
              <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                <Label
                  htmlFor="name"
                  className="text-[13px] text-textV2 font-500 cursor-pointer"
                >
                  Display:
                </Label>
              </div>
              <div className="field-wrapper col-span-8">
                <Select
                  onValueChange={(e) =>
                    onChangDisplayAnalysisOptions(JSON.parse(e))
                  }
                  defaultValue={formData.display}
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Date Set Price"
                    >
                      {formData.display !== null
                        ? formData.display.label
                        : "Select Date Set Price"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {displayAnalysisOptions && displayAnalysisOptions.length > 0 ? (
                        displayAnalysisOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div> */}
{/* <Select
                  onValueChange={(e) =>
                    onChangDateSetPriceOptions(JSON.parse(e))
                  }
                  defaultValue={formData.dataSet}
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Data"
                    >
                      {formData.dataSet !== null
                        ? formData.dataSet.label
                        : "Select Data"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {dateSetPriceOptions && dateSetPriceOptions.length > 0 ? (
                        dateSetPriceOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select> */}
{/* <Select
                  onValueChange={(e) => onChangAssetOptions(JSON.parse(e))}
                  defaultValue={
                    formData.assets !== null ? formData.assets : null
                  }
                >
                  <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                    <SelectValue
                      className="text-textV1"
                      placeholder="Select Assets"
                    >
                      {formData.assets?.label
                        ? formData.assets?.label
                        : "Select Assets"}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {assetOptions && assetOptions.length > 0 ? (
                        assetOptions.map((item, index) => {
                          return (
                            <SelectItem
                              key={index}
                              className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                              value={JSON.stringify(item)}
                            >
                              {item?.label}
                            </SelectItem>
                          );
                        })
                      ) : (
                        <SelectItem
                          disabled
                          className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                          value={"No option"}
                        >
                          {"No option"}
                        </SelectItem>
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select> */}