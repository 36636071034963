import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers';
import DownloadChart from '../../shared/DownloadChart';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, LineChart, ToolboxComponent, SVGRenderer]);

export default function BasicLineChart(props) {
    const { analyticReports, updateId, isAutoChart, isReports, index, refEl } = props;
    const [chartData, setChartData] = React.useState([]);

    React.useEffect(() => {
        if (updateId === null && isReports === null) {
            setChartData([]);
        } else {
            setChartData(analyticReports);
        }
    }, [analyticReports]);

    let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
    let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];

    const labels = dateLabels ? dateLabels : '';
    let data;

    if (isReports) {
        data = {
            labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
            datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
                return {
                    ...item,
                    borderColor: item.backgroundColor,
                    borderWidth: 1,
                };
            }) : [],
        };
    } else {
        data = {
            labels,
            datasets: [
                {
                    label: labels,
                    data: chartValues,
                    fill: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 159, 64, 0.5)',
                        'rgba(255, 205, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 0, 223, 0.5)',
                    ],
                    borderColor: [
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(255, 0, 223)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
    }

    const transformedData = data.datasets.map((dataset) => ({
        id: dataset.label,
        color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
        data: dataset.data.map((value, index) => ({
            x: data.labels[index],
            y: parseInt(Number(value)),
        })),
    }));

    const transformedChartData = transformedData.flatMap(entry =>
        entry.data.map(dataPoint => ({
            name: `${entry.id} - ${dataPoint.x}`,
            value: parseInt(Number(dataPoint.y))
        }))
    );

    const option = {
        toolbox: {
            show: true,
            orient: 'vertical',
            showTitle: 'true',
            confine: 'true',
            textStyle: {
              overflow: 'breakAll',
              width: 40,
            },
        },
         tooltip: {
            trigger: 'axis',
            axis: 'auto',
            axisPointer: { type: 'shadow', width: '10px' },
            displayMode: 'single',
            renderMode: 'auto',
            displayMode: 'single',
            renderMode: 'auto',
            // Confining tooltip to the body
            confine: true,
            align: 'center',
            backgroundColor: '#001F3D',
            appendToBody: true,
            textStyle: {
                fontSize: 12, // Adjust the font size as needed
                color: '#FFFFFF',
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                maxWidth: '30%',
                overflow: 'truncate',
                width: 40,
            },
        },
        legend: {
            type: 'scroll',
            // orient: 'vertical',
            right: 0,
            top: 0,
            left: 0,
            // bottom: 20,
        },
        xAxis: [{
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            data: data.labels
        }],
        yAxis: [{
            type: 'value',
            // name: 'Value',
            position: 'left'
        }],
        // toolbox: {
        //     feature: {
        //         saveAsImage: { show: true, title: 'save' },
        //         type: 'png'
        //         // magicType: { show: true, type: ['line', 'bar'] },
        //         // dataView: { show: true, readOnly: false },
        //         // restore: { show: true }
        //     },
        //     top: 20
        // },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        series: transformedData.map(dataset => ({
            name: dataset.id,
            type: 'line',
            smooth: true,
            data: dataset.data.map(dataPoint => dataPoint.y)
        }))
    };


    return (
        <>
            {data.datasets.length && data.labels.length ?
                <>
                    <DownloadChart chartRef={refEl}  chartData={data} chartId={"echarts-chart" + index} echarts={echarts} />
                    <ReactEChartsCore
                        className={"echarts-chart" + index  + ' ' + 'css-13aj3tc-MuiChartsSurface-root'}
                        ref={refEl}
                        echarts={echarts}
                        style={{ width: '100%', height: '100%' }}
                        option={option}
                    />
                </> : <> <div>No Data Available</div>  </>}

        </>

    );
}
// =====================================================

// import * as React from 'react';
// import { LineChart } from '@mui/x-charts/LineChart';
// import DownloadChart from '../../shared/DownloadChart';

// export default function BasicLineChart(props) {
//     const { analyticReports, updateId, isAutoChart, isReports, refEl } = props;
//     const [chartData, setChartData] = React.useState([])

//     React.useEffect(() => {
//         if (updateId === null && isReports === null) {
//             setChartData([])
//         } else {
//             setChartData(analyticReports)
//         }
//     }, [analyticReports])

//     // const chartRef = useRef(null)

//     // Sample data for the chart
//     let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
//     let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];

//     const labels = dateLabels ? dateLabels : '';
//     let data

//     if (isReports) {

//         data = {
//             labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
//             datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
//                 return {
//                     ...item, borderColor: item.backgroundColor,
//                     borderWidth: 1
//                 }
//             }) : []
//         }
//     } else {
//         data = {
//             labels,
//             datasets: [
//                 {
//                     label: labels,
//                     data: chartValues,
//                     fill: true,
//                     backgroundColor: [
//                         'rgba(255, 99, 132, 0.5)',
//                         'rgba(255, 159, 64, 0.5)',
//                         'rgba(255, 205, 86, 0.5)',
//                         'rgba(75, 192, 192, 0.5)',
//                         'rgba(54, 162, 235, 0.5)',
//                         'rgba(153, 102, 255, 0.5)',
//                         'rgba(255, 0, 223, 0.5)'
//                     ],
//                     borderColor: [
//                         'rgb(255, 99, 132)',
//                         'rgb(255, 159, 64)',
//                         'rgb(255, 205, 86)',
//                         'rgb(75, 192, 192)',
//                         'rgb(54, 162, 235)',
//                         'rgb(153, 102, 255)',
//                         'rgb(255, 0, 223)'
//                     ],
//                     borderWidth: 1
//                 }
//             ],
//         };
//     }

//     return (
//         <>
//             {data.datasets.length && data.labels.length ?
//                 <>
//                     <DownloadChart chartData={data} chartRef={refEl} />
//                     <LineChart
//                         ref={refEl}
//                         xAxis={[{ data: data.labels }]}
//                         series={data.datasets.map((dataset, index) => ({
//                             data: dataset.data ? dataset?.data?.map(Number) : [2, 5.5, 2, 8.5, 1.5, 5],
//                             // label: dataset?.label ? dataset?.label : '',

//                         }))}
//                         width={500}
//                         height={300}
//                     />
//                      </> : <> <div>No Data Available</div>  </>}

//         </>
//     );
// }


// =====================================================


// import React from 'react';
// import { ResponsiveLine } from '@nivo/line';
// import DownloadChart from '../../shared/DownloadChart';

// export default function BasicLineChart(props) {
//     const { analyticReports, updateId, isAutoChart, isReports, refEl } = props;
//     const [chartData, setChartData] = React.useState([]);

//     React.useEffect(() => {
//         if (updateId === null && isReports === null) {
//             setChartData([]);
//         } else {
//             setChartData(analyticReports);
//         }
//     }, [analyticReports]);

//     let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
//     let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];

//     const labels = dateLabels ? dateLabels : '';
//     let data;

//     if (isReports) {
//         data = {
//             labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
//             datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
//                 return {
//                     ...item,
//                     borderColor: item.backgroundColor,
//                     borderWidth: 1,
//                 };
//             }) : [],
//         };
//     } else {
//         data = {
//             labels,
//             datasets: [
//                 {
//                     label: labels,
//                     data: chartValues,
//                     fill: true,
//                     backgroundColor: [
//                         'rgba(255, 99, 132, 0.5)',
//                         'rgba(255, 159, 64, 0.5)',
//                         'rgba(255, 205, 86, 0.5)',
//                         'rgba(75, 192, 192, 0.5)',
//                         'rgba(54, 162, 235, 0.5)',
//                         'rgba(153, 102, 255, 0.5)',
//                         'rgba(255, 0, 223, 0.5)',
//                     ],
//                     borderColor: [
//                         'rgb(255, 99, 132)',
//                         'rgb(255, 159, 64)',
//                         'rgb(255, 205, 86)',
//                         'rgb(75, 192, 192)',
//                         'rgb(54, 162, 235)',
//                         'rgb(153, 102, 255)',
//                         'rgb(255, 0, 223)',
//                     ],
//                     borderWidth: 1,
//                 },
//             ],
//         };
//     }

//     // console.log('data.datasets', data.datasets)
//     // console.log('data.labels', data.labels)
//     const transformedData = data.datasets.map((dataset) => ({
//         id: dataset.label,
//         color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
//         data: dataset.data.map((value, index) => ({
//             x: data.labels[index],
//             y: Number(value),
//         })),
//     }));
//     const transformedLabel = data.datasets.map((dataset, datasetIndex) => dataset.label);
//     // console.log(transformedData);
//     // {
//     //     "id": "ADVANTAGE ENERGY LTD | Canada | Agricultural land (% of land area).3",
//     //     "index": 78,
//     //     "serieId": "ADVANTAGE ENERGY LTD | Canada | Agricultural land (% of land area)",
//     //     "serieColor": "#8c564b",
//     //     "x": 66.32142857142857,
//     //     "y": 280,
//     //     "color": "transparent",
//     //     "borderColor": "#8c564b",
//     //     "data": {
//     //         "x": "2010",
//     //         "y": 6.50040878514409,
//     //         "xFormatted": "2010",
//     //         "yFormatted": "6.50040878514409"
//     //     }
//     // }
//     return (
//         <>
//             {data.datasets.length && data.labels.length ? (
//                 <>
//                     <DownloadChart chartData={data} chartRef={refEl} />
//                     <ResponsiveLine
//                         ref={refEl}
//                         data={transformedData}
//                         // margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
//                         margin={{ top: 10, right: 110, bottom: 50, left: 50 }}
//                         xScale={{ type: 'point' }}
//                         yScale={{
//                             type: 'linear',
//                             min: 'auto',
//                             max: 'auto',
//                             stacked: false,
//                             reverse: false
//                         }}
//                         curve="monotoneX"
//                         yFormat=" >-.2f"
//                         axisTop={null}
//                         axisRight={null}
//                         axisLeft={{
//                             tickSize: 5,
//                             tickPadding: 5,
//                             tickRotation: 0,
//                             legend: 'count',
//                             legendOffset: -40,
//                             legendPosition: 'middle',
//                             truncateTickAt: 0
//                         }}
//                         colors={{ scheme: 'category10' }}
//                         pointSize={10}
//                         pointColor={{ theme: 'background' }}
//                         pointBorderWidth={2}
//                         pointBorderColor={{ from: 'serieColor' }}
//                         pointLabelYOffset={-12}
//                         enableTouchCrosshair={true}
//                         borderRadius={4}
//                         borderWidth={1}
//                         enableLabel={true}
//                         useMesh={true}
//                         tooltip={(point) => (
//                             <div className='bg-white shadow-md p-2'>
//                                 <span style={{ backgroundColor: point?.point?.serieColor, color: point?.point?.serieColor }}>...</span> <span>{point?.point?.serieId}</span> - <span>{point?.point?.data?.xFormatted}</span> : <strong>{parseInt(Number(point?.point?.data?.yFormatted))}</strong>
//                             </div>
//                         )}

//                         // legends={[
//                         //     {
//                         //         anchor: 'top-left',
//                         //         direction: 'column',
//                         //         justify: false,
//                         //         translateX: -28,
//                         //         translateY: -97,
//                         //         itemsSpacing: 0,
//                         //         itemDirection: 'left-to-right',
//                         //         itemWidth: 80,
//                         //         itemHeight: 20,
//                         //         itemOpacity: 0.75,
//                         //         symbolSize: 12,
//                         //         symbolShape: 'circle',
//                         //         symbolBorderColor: 'rgba(0, 0, 0, .5)',
//                         //         effects: [
//                         //             {
//                         //                 on: 'hover',
//                         //                 style: {
//                         //                     itemBackground: 'rgba(0, 0, 0, .03)',
//                         //                     itemOpacity: 1
//                         //                 }
//                         //             }
//                         //         ]
//                         //     }
//                         // ]}
//                         legends={[
//                             {
//                                 anchor: 'bottom-right',
//                                 direction: 'column',
//                                 justify: false,
//                                 translateX: 100,
//                                 translateY: 0,
//                                 itemsSpacing: 0,
//                                 itemDirection: 'left-to-right',
//                                 itemWidth: 80,
//                                 itemHeight: 20,
//                                 itemOpacity: 0.75,
//                                 symbolSize: 12,
//                                 symbolShape: 'circle',
//                                 symbolBorderColor: 'rgba(0, 0, 0, .5)',
//                                 effects: [
//                                     {
//                                         on: 'hover',
//                                         style: {
//                                             itemBackground: 'rgba(0, 0, 0, .03)',
//                                             itemOpacity: 1
//                                         }
//                                     }
//                                 ]
//                             }
//                         ]}

//                     />
//                 </>
//             ) : (
//                 <>
//                     <div>No Data Available</div>
//                 </>
//             )}
//         </>
//     );
// }


{/* <LineChart
                        ref={refEl}
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                        series={[
                            {
                                data: [2, 5.5, 2, 8.5, 1.5, 5],
                            },
                        ]}
                        width={500}
                        height={300}
                    /> */}