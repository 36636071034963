
import React from 'react'
import AdminSettingTabs from './SettingTabs'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'

const AdminSettings = () => {
    return (
        <>
            <DashboardMaster>
                <section className='py-3 md:py-5'>
                    <AdminSettingTabs />
                </section>
            </DashboardMaster>
        </>
    )
}

export default AdminSettings