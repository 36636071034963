import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { addSubClass, updateSubClass } from '../../../../store/actions/subClassAction';
import { addAdminSubClass, updateAdminSubClass } from '../../../../store/actions/admin/subClassAction';
import { fetchAdminClasses } from '../../../../store/actions/admin/classAction';
import { useSelector } from 'react-redux';

const AdminSubClassModel = (props) => {
    const { show, onHide, allClass, fetchData,classData, fetchId } = props
    const dispatch = useDispatch()
    const [SubName, setSubName] = useState({
        name: '',
        classOption: null
    })
    const [error, setError] = useState({});

   

    useEffect(() => {
        if (show === false) {
            setSubName({
                name: '',
                classOption: null
            })
        }
    }, [show])

    useEffect(() => {
        if (fetchId !== null) {
            setSubName({
                name: fetchData && fetchData?.name,
                classOption: fetchData && fetchData?.class ? { value: fetchData?.data?.class?._id, label: fetchData?.data?.class?.name } : null
            })
        }
    }, [fetchData])

    
    const classOptions = classData?.records && classData?.records?.length > 0 && classData?.records?.map((item) => {
        return { value: item.id, label: item.name }
    })

    

    // const onChangeCategoryOption = (event) => {
    //     setSubName({ ...SubName, classOption: event })
    //     setError({})
    // }

    const handleSelect = (event) => {
        const classOption = JSON.parse(event)
        setSubName((prevState) => {
            return {
                ...prevState,
                classOption: classOption,
            };
        });
        setError({})
    }

    const onChangeHandel = (e) => {
        setSubName({ ...SubName, name: e.target.value })
        setError({})
    }
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (SubName.name === '') {
            errors['subClass'] = 'Please enter Sub Class Name';
            isValid = false;
        }
        if (SubName.classOption === null) {
            errors['class'] = 'Please select Class Name';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };
    const onSave = () => {
        if (handleValidation()) {
            if (fetchId !== null) {
                dispatch(updateAdminSubClass(fetchId, {
                    class: SubName?.classOption?.value,
                    name: SubName?.name
                }))
            } else {
                dispatch(addAdminSubClass({
                    class: SubName?.classOption?.value,
                    name: SubName?.name
                }))
            }
            onHide()
        }
    }

    return (
        <Dialog open={show} onOpenChange={onHide}>
            <DialogContent className="sm:max-w-[460px] gap-10">
                <DialogHeader>
                    <DialogTitle className="text-center">{fetchId && fetchData ? "Edit Sub Class" : "Add Sub Class"}</DialogTitle>
                </DialogHeader>
                <div className="dialog-body">
                    <div className="grid grid-cols-1 gap-5">
                        <div className="form-group">
                            <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                Select Portfolio Group
                            </Label>
                            <Select
                                onValueChange={(e) => handleSelect(e)}
                                defaultValue={fetchId !== null ? SubName.classOption : SubName.classOption}
                            // value={fetchId !== null ? SubName.classOption?.id ? SubName.classOption?.id : "" : SubName.classOption?.id}
                            >
                                <SelectTrigger className="w-full">
                                    <SelectValue
                                        className='text-textV1'
                                        placeholder="Select Class"
                                    >
                                        {fetchId !== null ? SubName.classOption?.label : SubName.classOption?.label ? SubName.classOption?.label : "Select Class"}
                                        {/* {fetchId !== null? SubName.classOption?.label ? SubName.classOption?.label : "" : SubName.classOption?.label} */}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {
                                            classOptions && classOptions.length > 0 ? classOptions.map((item, index) => {
                                                return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                            }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                        }
                                    </SelectGroup>
                                </SelectContent>
                            </Select>

                            <div className={` ${error['class'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['class'] ? error['class'] : null}</div>
                        </div>
                        <div className="form-group">
                            <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                Sub Class Name:<span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Input
                                className={`form-control shadow-none ${error['subClass'] && 'is-invalid'}`}
                                id="name" type="text" name="subClass" placeholder="Sub Class Name" onChange={(e) => onChangeHandel(e)} value={SubName.name} />

                            <div className={`${error['subClass'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['subClass'] ? error['subClass'] : null}</div>
                        </div>
                    </div>
                </div>
                <DialogFooter className={"!justify-center !gap-2"}>
                    <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                    <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default AdminSubClassModel