
import { apiBaseURL, researchActionType, researchNoteContentActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const getOneReSearchNoteContent = ({ id }) => async (dispatch) => {

    try {
        // dispatch(setLoading(true)); research-notes-content
        const response = await apiConfig.get(apiBaseURL.RESEARCH_NOTES + "-content/" + id)
        // dispatch({
        //     type: researchNoteContentActionType.FETCH_ONE_RESEARCH_NOTE,
        //     payload: response.data?.data,
        // });
        // dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
        return response?.data
    } catch (error) {

        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};
export const AddNoteContent = (payload, setContentLoad) => async (dispatch) => {
    
    try {
        // dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.RESEARCH_NOTES_CONTENT, payload)
        // dispatch({
        //     type: researchNoteContentActionType.FETCH_ONE_RESEARCH_NOTE,
        //     payload: response.data?.data,
        // });
        // dispatch(metaDataAction({ pageNumber: response?.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
        setContentLoad && setContentLoad(false)
        return response
    } catch (error) {

        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};