import React, { useContext, useEffect, useRef, useState } from "react";
import { CanvasContext } from "../CanvasContainer";
// import ImageIcon from './../asset/images/image_icon.png'
// import PDF_Icon from './../asset/images/pdficon.png'
// import TextIcon from './../asset/images/text.png'
// import Export_Icon from './../asset/images/export_icon.png'
// import Import_Icon from './../asset/images/import_icon.png'
// import Vertical from './../asset/images/vertical.png'
// import Horizontal from './../asset/images/horizontal.png'
import { Button } from "../../../../../../components/ui/button";
import { Checkbox } from "../../../../../../components/ui/checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../../../../../components/ui/dropdown.jsx';
import { DownloadIcon, MoveHorizontal, MoveVertical } from "lucide-react";
import { v4 as uuidv4 } from 'uuid';
import Select, {
  components,
} from 'react-select';
import Divider from "../../../../../../components/common/Divider.jsx";
import { getAdjustedDimenstions, getImageDimensions } from "../helper/initialImages.js";
export const sizeList = [
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "72px"
];

export const fontList = [
  "Arial",
  "Arial Black",
  "Arial Unicode MS",
  "Calibri",
  "Cambria",
  "Cambria Math",
  "Candara",
  `Segoe UI, wf_segoe-ui_normal, helvetica, arial, sans-serif`,
  "Comic Sans MS",
  "Consolas",
  "Constantia",
  "Corbel",
  "Courier New",
  "Georgia",
  "Lucida Sans Unicode",
  "Tahoma",
  "Times New Roman",
  "Trebuchet MS",
  "Verdana"
];



const Toolbar = ({ isEditEnable, onExport, onImport }) => {
  const { actions, state } = useContext(CanvasContext);
  const uploadRef = useRef(null);
  const addElement = (type, data) => {
    actions?.addElement(type, data);
  };

  const triggerUpload = () => {
    const element = uploadRef?.current;
    if (element) {
      element.click();
    }
  };

  const handleCheckboxChange = (value, isTrue) => {
    if (value === 'TEXT' && !isTrue) {
      actions.setCanvasData([
        ...state.canvasData.filter((data) => {
          if (data.type === 'TEXT' && value === 'TEXT') {
            return false;
          }
          return true;
        })
      ]);
      actions?.setIsCheckedTitle(false)
    }
    if (value === "CONTENT" && !isTrue) {
      actions.setCanvasData([
        ...state.canvasData.filter((data) => {
          if (data.type === "CONTENT" && value === "CONTENT") {
            return false;
          }
          return true;
        })
      ]);
      actions?.setIsCheckedContent(false)
    }
    if (state?.editData?.title !== undefined && state?.editData?.title !== null && value === 'TEXT' && isTrue) {
      actions?.setIsCheckedTitle(true)
      addElement("TEXT", { title: state?.editData?.title })
    }
    if (state?.editData?.content !== undefined && state?.editData?.content !== null && value === 'CONTENT' && isTrue) {
      actions?.setIsCheckedContent(true)
      addElement("CONTENT", { content: state?.editData?.content })
    }
  }

  const [attachmentsOp, setAttachmentsOp] = useState([])
  const [customFieldsOp, setCustomFieldsOp] = useState([]);
  const [tagOp, setTagOp] = useState([]);

  // Attachment, CustomField, tags
  useEffect(() => {
    // Attachment,
    if (state?.editData?.attachmentsData?.length) {
      // const getAllAttachment = state?.canvasData?.length ? state?.canvasData?.filter((item) => item.type === 'ATTACHMENT') : null
      const getAllAttachment = state?.canvasData?.length ? state?.canvasData?.filter((item) => item.type === 'ATTACHMENTIMAGE') : null
      if (getAllAttachment !== null) {
        const options = state?.editData?.attachmentsData.map((item) => {
          if (item.includes('.png') || item.includes('.jpg') || item.includes('.jpeg') || item.includes('.webp')) {
            const getOldOp = getAllAttachment?.find((items) => items?.attachmentSavingData?.id === item?.split("-")[0])
            return { label: item?.slice(item?.indexOf('-') + 1), value: uuidv4(), id: item?.split("-")[0], items: item, isSelect: getOldOp?.attachmentSavingData?.isSelect ? getOldOp?.attachmentSavingData?.isSelect : false }
          }
        })
        const prepareDataOption = options.filter((item) => item !== undefined && item !== null)
        setAttachmentsOp(prepareDataOption)
      } else {
        const options = state?.editData?.attachmentsData.map((item) => {
          if (item.includes('.png') || item.includes('.jpg') || item.includes('.jpeg') || item.includes('.webp')) {
            return { label: item?.slice(item?.indexOf('-') + 1), value: uuidv4(), id: item?.split("-")[0], items: item, isSelect: false }
          }
        })
        const prepareDataOption = options.filter((item) => item !== undefined && item !== null)
        setAttachmentsOp(prepareDataOption)
      }
    } else {
      setAttachmentsOp([])
    }
    // CustomField,
    if (state?.editData?.customFieldRecordData?.length) {
      const getAllAttachment = state?.canvasData?.length ? state?.canvasData?.filter((item) => item.type === 'CUSTOMFIELD') : null
      if (getAllAttachment !== null) {
        const options = state?.editData?.customFieldRecordData.map((item) => {
          const getOldOp = getAllAttachment?.find((items) => items?.customFiledSavingData?.id === item._id)
          return { label: `${item.customField.name}`, value: item._id, id: item._id, isSelect: getOldOp?.customFiledSavingData?.isSelect ? getOldOp?.customFiledSavingData?.isSelect : false, item: item }
        })
        setCustomFieldsOp(options)
      } else {
        const options = state?.editData?.customFieldRecordData.map((item) => {
          return { label: `${item.customField.name}`, value: item._id, id: item._id, isSelect: false, item: item }
        })
        setCustomFieldsOp(options)
      }
    } else {
      setCustomFieldsOp([])
    }
    // tags
    if (state?.editData?.tags?.length) {
      const options = state?.editData?.tags.map((item) => {
        return { label: item, value: uuidv4(), id: item, isSelect: false }
      })
      setTagOp(options)
    } else {
      setTagOp([])
    }
  }, [state?.editData])

  // update Attachment option when delete
  useEffect(() => {
    if (state.isUpdateAttachment !== null) {
      setAttachmentsOp((prev) => {
        const update = prev.map((item) => {
          if (item.id === state?.isUpdateAttachment?.attachmentSavingData?.id) {
            return { ...item, isSelect: false }
          } {
            return item
          }
        })
        return update
      })
      actions.setIsUpdateAttachment(null)
    }
  }, [state.isUpdateAttachment])

  // update CustomField option when delete
  useEffect(() => {
    if (state.isUpdateCustomField !== null) {
      setCustomFieldsOp((prev) => {
        const update = prev.map((item) => {
          if (item.id === state?.isUpdateCustomField?.customFiledSavingData?.id) {
            return { ...item, isSelect: false }
          } {
            return item
          }
        })
        return update
      })
      actions.setIsUpdateCustomField(null)
    }
  }, [state.isUpdateCustomField])

  // Attachment
  const onCheckedAttachmentBox = async (props) => {
    const alreadyExit = state?.canvasData?.length ? state?.canvasData.filter((item) => {
      if (item?.attachmentSavingData?.id === props?.data?.id) {
        return true
      }
      return false
    }) : null
    if (alreadyExit?.length) {
      const updateAttachmentsOp = attachmentsOp.map((item) => {
        if (item.id === props.data.id) {
          return { ...item, isSelect: item.isSelect ? false : true }
        } else {
          return { ...item };
        }
      })
      setAttachmentsOp(updateAttachmentsOp)
      actions.setCanvasData([
        ...state.canvasData.filter((data) => {
          if (data?.attachmentSavingData?.id === props.data.id) {
            return false;
          }
          return true;
        })
      ]);
    } else {
      const updateAttachmentsOp = attachmentsOp.map((item) => {
        if (item.id === props.data.id) {
          return { ...item, isSelect: true }
        } else {
          return { ...item };
        }
      })
      setAttachmentsOp(updateAttachmentsOp)
      // background:#e6e6e6;

      const fideUpdateData = updateAttachmentsOp.find((item) => item.id === props.data.id)
      if (!fideUpdateData.label.includes('.png') && !fideUpdateData.label.includes('.jpg') && !fideUpdateData.label.includes('.jpeg')) {
        const prepareData = {
          ...fideUpdateData,
          label: `<p  style='padding:3px 2px'>${fideUpdateData.label}</p>`
        }
        // Create a temporary div element
        const tempDiv = document.createElement('div');
        // Set its inner HTML to the content
        tempDiv.innerHTML = prepareData.label;
        // Set CSS styles to ensure accurate measurement
        tempDiv.style.position = 'absolute';
        // Append the temporary div to the body
        document.body.appendChild(tempDiv);
        // Get width and height
        const width = tempDiv.children[0].offsetWidth;
        const height = tempDiv.children[0].offsetHeight;
        // Remove the temporary div from the DOM
        document.body.removeChild(tempDiv);
        actions.addAttachmentsElement({ ...prepareData, type: 'ATTACHMENTIMAGE', isImage: false, width, height })
      } else {

        const token = localStorage.getItem('auth_token')
        const base64Url = process.env.REACT_APP_SERVER_URL + `/api/view-file?filename=${fideUpdateData?.items}&type=researchNoteContent&authToken=${token}`;
        const imageDimensions = await getImageDimensions(base64Url);
        const encodeUrl = (url) => {
          return url
              .replace(/ /g, '%20')
              .replace(/\(/g, '%28')
              .replace(/\)/g, '%29');
      }
        const { calcWidth, calcHeight } = getAdjustedDimenstions(
          imageDimensions?.nw,
          imageDimensions?.nh,
          150
        );
        const prepareData = {
          ...fideUpdateData,
          content: encodeUrl(base64Url) || "",
          dimension: {
            width: `${calcWidth || 0}`,
            height: `${calcHeight || 0}`
          },
          isImage: true,
        }
        actions.addAttachmentsElement({ ...prepareData, isImage: true, type: 'ATTACHMENTIMAGE' })
      }
    }

  }
  // CustomField
  const onCheckedCustomFieldBox = (props) => {
    const alreadyExit = state?.canvasData?.length ? state?.canvasData.filter((item) => {
      if (item?.customFiledSavingData?.id === props?.data?.id) {
        return true;
      }
      return false;
    }) : null
    if (alreadyExit?.length) {
      const updateAttachmentsOp = customFieldsOp.map((item) => {
        if (item.id === props.data.id) {
          return { ...item, isSelect: item.isSelect ? false : true }
        } else {
          return { ...item };
        }
      })
      setCustomFieldsOp(updateAttachmentsOp)
      actions.setCanvasData([
        ...state.canvasData.filter((data) => {
          if (data?.customFiledSavingData?.id === props.data.id) {
            return false;
          }
          return true;
        })
      ]);
    } else {
      const updateCustomFieldsOp = customFieldsOp.map((item) => {
        if (item.id === props.data.id) {
          return { ...item, isSelect: item.isSelect ? false : true }
        } else {
          return { ...item };
        }
      })
      setCustomFieldsOp(updateCustomFieldsOp)
      // background:#e6e6e6;
      const fideUpdateData = updateCustomFieldsOp.find((item) => item.id === props.data.id)
      const prepareData = {
        ...fideUpdateData,
        label: `<p style='padding:3px 2px'>${fideUpdateData.label}: ${fideUpdateData.item.value}</p>`
      }
      // Create a temporary div element
      const tempDiv = document.createElement('div');
      // Set its inner HTML to the content
      tempDiv.innerHTML = prepareData.label;
      // Set CSS styles to ensure accurate measurement
      tempDiv.style.position = 'absolute';
      // Append the temporary div to the body
      document.body.appendChild(tempDiv);
      // Get width and height
      const width = tempDiv.children[0].offsetWidth;
      const height = tempDiv.children[0].offsetHeight;
      // Remove the temporary div from the DOM
      document.body.removeChild(tempDiv);
      actions.addCustomFieldElement({ ...prepareData, width, height })
    }
  }
  // Tags
  const onCheckedTagsBox = (props) => {
    // console.log('value, props', props)
    const alreadyExit = state?.canvasData?.length ? state?.canvasData.filter((item) => {
      if (item?.customTagSavingData?.id === props.data.id) {
        return true
      }
      return false
    }) : null
    if (alreadyExit?.length) {
      const updateAttachmentsOp = tagOp.map((item) => {
        if (item.id === props.data.id) {
          return { ...item, isSelect: item.isSelect ? false : true }
        } else {
          return { ...item };
        }
      })
      setTagOp(updateAttachmentsOp)
      actions.setCanvasData([
        ...state.canvasData.filter((data) => {
          if (data?.customTagSavingData?.id === props.data.id) {
            return false;
          }
          return true;
        })
      ]);
    } else {
      const updateTagsOp = tagOp.map((item) => {
        if (item.id === props.data.id) {
          return { ...item, isSelect: item.isSelect ? false : true }
        } else {
          return { ...item };
        }
      })
      setTagOp(updateTagsOp)
      // background:#e6e6e6;
      const fideUpdateData = updateTagsOp.find((item) => item.id === props.data.id)
      const prepareData = {
        ...fideUpdateData,
        label: `<p style='border-radius:5px;padding:3px 5px;'>${fideUpdateData.label}</p>`
      }
      // Create a temporary div element
      const tempDiv = document.createElement('div');
      // Set its inner HTML to the content
      tempDiv.innerHTML = prepareData.label;
      // Set CSS styles to ensure accurate measurement
      tempDiv.style.position = 'absolute';
      // Append the temporary div to the body
      document.body.appendChild(tempDiv);
      // Get width and height
      const width = tempDiv.children[0].offsetWidth;
      const height = tempDiv.children[0].offsetHeight;
      // Remove the temporary div from the DOM
      document.body.removeChild(tempDiv);
      actions.addTagsElement({ ...prepareData, width, height })
    }
  }
  // Attachment
  const OptionAttachments = ({ children, ...props }) => {
    const { onCheckedAttachmentBox } = props.selectProps;
    return (
      <components.Control {...props}>
        <div className="flex p-2 align-middle justify-start items-center text-sm w-full" onClick={() => onCheckedAttachmentBox(props)}>
          <span className="me-2"><Checkbox
            checked={props?.data?.isSelect}
            aria-label="Select row"
            // rounded-[4px]
            className="translate-y-[2px] border-black " /></span>
          {children}
        </div>
      </components.Control>
    );
  };
  // CustomField
  const OptionCustomField = ({ children, ...props }) => {
    const { onCheckedCustomFieldBox } = props.selectProps;
    return (
      <components.Control {...props}>
        <div className="flex p-2 align-middle justify-start items-center text-sm w-full" onClick={() => onCheckedCustomFieldBox(props)}>
          <span className="me-2"><Checkbox
            checked={props?.data?.isSelect}
            aria-label="Select row"
            // rounded-[4px]
            className="translate-y-[2px] border-black " /></span>
          {children}
        </div>
      </components.Control>
    );
  };
  // Tags
  const OptionTags = ({ children, ...props }) => {
    const { onCheckedTagsBox } = props.selectProps;
    return (
      <components.Control {...props}>
        <div className="flex p-2 align-middle justify-start items-center text-sm w-full" onClick={() => onCheckedTagsBox(props)}>
          <span className="me-2"><Checkbox
            checked={props?.data?.isSelect}
            aria-label="Select row"
            // rounded-[4px]
            className="translate-y-[2px] border-black " /></span>
          {children}
        </div>
      </components.Control>
    );
  };
  // multi Tags
  const onMultiselectTags = (value, options, type) => {
    // console.log("onMultiselectTags =>", { value, options, state, actions })
    if (type === 'TAG' && !value) {
      actions.setCanvasData([
        ...state.canvasData.filter((data) => {
          if (data.type === 'TAG' && type === 'TAG') {
            return false;
          }
          return true;
        })
      ]);
      actions?.setEnableTag(false)
    }
    if (options.length !== 0 && value) {
      const labels = options.map(item => item.label).join(", ");
      const prepareValues = { label: labels, value: uuidv4(), id: uuidv4(), isSelect: true }
      actions.setEnableTag(true)
      const prepareData = {
        ...prepareValues,
        label: `<p  style='padding:3px 2px'>${prepareValues.label}</p>`
      }
      // Create a temporary div element
      const tempDiv = document.createElement('div');
      // Set its inner HTML to the content

      tempDiv.innerHTML = prepareData.label;
      // Set CSS styles to ensure accurate measurement
      tempDiv.style.position = 'absolute';
      // Append the temporary div to the body
      document.body.appendChild(tempDiv);
      // Get width and height
      const width = tempDiv.children[0].offsetWidth;
      const height = tempDiv.children[0].offsetHeight;
      // Remove the temporary div from the DOM
      document.body.removeChild(tempDiv);
      actions.addTagsElement({ ...prepareData, width, height })
    }
  }

  // console.log("state =>", state)
  return (
    <div className="items-center ps-3 pe-0 shadow-sm  rounded-sm border border-gray-400 flex bg-white justify-between flex-wrap">
      <div className="flex items-center align-middle">
        <div className="font-semibold text-sm me-3">Contents: </div>
        {isEditEnable && (
          <div id="toolbar">
            <select className="ql-font">
              {fontList.map((font) => (
                <option value={font}>{font}</option>
              ))}
            </select>
            <select className="ql-size">
              {sizeList.map((size) => (
                <option value={size}>{size}</option>
              ))}
            </select>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <select className="ql-align" />
            <select className="ql-color" />
            <select className="ql-background" />
          </div>
        )}
        {!isEditEnable && <div className="toolbar-item text-sm" title="Text">
          {/* onClick={() => addElement("TEXT")}  */}
          <div className="flex justify-between align-middle items-center font-semibold" >
            <span className="me-2"><Checkbox
              checked={state?.isCheckedTitle}
              // disabled={state?.isCheckedTitle}
              onCheckedChange={(value) => {
                handleCheckboxChange("TEXT", value);
              }}
              aria-label="Select row"
              className="translate-y-[2px] border-black  rounded-[4px]" /></span>Title
            {/* <span className="flex"><img src={TextIcon} alt="textIcon" className="h-5 w-5" /></span> */}
          </div>
          {/* Text */}
        </div>}
        {!isEditEnable && <div className="toolbar-item text-sm" title="Content">
          {/* onClick={() => addElement("TEXT")}  */}
          <div className="flex justify-between align-middle items-center font-semibold">
            <span className="me-2"><Checkbox
              checked={state?.isCheckedContent}
              // disabled={state?.isCheckedContent}
              onCheckedChange={(value) => {
                handleCheckboxChange("CONTENT", value);
              }}
              aria-label="Select row"
              className="translate-y-[2px] border-black  rounded-[4px]" /></span>Content
            {/* <span className="flex"><img src={TextIcon} alt="textIcon" className="h-5 w-5" /></span> */}
          </div>
          {/* Text */}
        </div>}
        <div className="toolbar-item text-sm w-[100%] justify-center align-middle items-center">

          {tagOp.length ?
            <div className="flex py-2 align-middle justify-start items-center text-sm w-full text-nowrap"
            >
              <span className="me-2">
                <Checkbox
                  checked={state.enableTag}
                  // disabled={state?.enableTag}
                  aria-label="Select row"
                  onCheckedChange={(value) => {
                    onMultiselectTags(value, tagOp, 'TAG');
                  }}
                  className="translate-y-[2px] border-black rounded-[4px]" /></span>
            </div>
            : ''}
          {tagOp.length ? <span className="font-semibold text-nowrap">Tag </span> : ''}
          {/* {tagOp.length ? tagOp.map((item) => {
            return (
              <div className="flex p-2 align-middle justify-start items-center text-sm w-full text-nowrap" onClick={() => onCheckedTagsBox({ data: item })}>
                <span className="me-2">
                  <Checkbox
                    checked={item?.isSelect}
                    aria-label="Select row"
                    // rounded-[4px]
                    className="translate-y-[2px] border-black rounded-[4px]" /></span>
                {item.label}
              </div>)
          }) : ''} */}
        </div>
        <div className="toolbar-item text-sm w-[100%]">
          <Select
            components={{ Option: OptionAttachments }}
            isSearchable={false}
            inputValue=""
            value={null}
            closeMenuOnSelect={false}
            // hideSelectedOptions={true}
            onCheckedAttachmentBox={onCheckedAttachmentBox}
            placeholder='Attachments'
            name="attachments"
            className="multi_react_select"
            options={attachmentsOp}
            styles={{
              menuList: (base) => ({
                ...base,
                fontSize: "10px",
                overflow: "auto",
                maxHeight: "180px",
                lineHeight: '1rem',
                fontWeight: 500,
                borderRadius: '0',
                backgroundColor: 'transparent'

              }),
              menu: (base) => ({
                ...base,
                overflow: "auto",
                margin: 0,
                padding: 0,
                width: '300px',
                borderRadius: '0',

              }),
              input: (base) => ({
                ...base,
                width: '40%',
              }),
              container: (base) => ({
                ...base,
                width: '150px',
              }),
            }}
          />
        </div>
        <div className="toolbar-item text-sm w-[100%]">
          <Select
            components={{ Option: OptionCustomField }}
            isSearchable={false}
            inputValue=""
            closeMenuOnSelect={false}
            value={null}
            // hideSelectedOptions={true}
            className="multi_react_select"
            onCheckedCustomFieldBox={onCheckedCustomFieldBox}
            placeholder='CustomField'
            name="customField"
            options={customFieldsOp}
            styles={{
              menuList: (base) => ({
                ...base,
                fontSize: "10px",
                overflow: "auto",
                maxHeight: "180px",
                lineHeight: '1rem',
                fontWeight: 500,
                borderRadius: '0',
                backgroundColor: 'transparent'

              }),
              menu: (base) => ({
                ...base,
                overflow: "auto",
                margin: 0,
                padding: 0,
                width: '300px',
                borderRadius: '0',

              }),
              input: (base) => ({
                ...base,
                width: '30%',
              }),
              container: (base) => ({
                ...base,
                width: '150px',
              }),
            }}
          />
        </div>
        <div className="toolbar-item text-sm w-[100%] !hidden">
          <Select
            components={{ Option: OptionTags }}
            isSearchable={false}
            inputValue=""
            value={null}
            // hideSelectedOptions={true}
            className="multi_react_select"
            onCheckedTagsBox={onCheckedTagsBox}
            placeholder='Tags'
            name="Tags"
            options={tagOp}
            styles={{
              menuList: (base) => ({
                ...base,
                fontSize: "10px",
                overflow: "auto",
                maxHeight: "180px",
                lineHeight: '1rem',
                fontWeight: 500,
                borderRadius: '0',
                backgroundColor: 'transparent'

              }),
              menu: (base) => ({
                ...base,
                overflow: "auto",
                margin: 0,
                padding: 0,
                width: '300px',
                borderRadius: '0',

              }),
              input: (base) => ({
                ...base,
                width: '30%',
              }),
              container: (base) => ({
                ...base,
                width: 'fit-content',
              }),
            }}
          />
        </div>
        <div>
          {/* <Button size="sm" className="min-w-[98px] h-full rounded-ss-none rounded-es-none rounded-se-sm rounded-ee-sm border-none" onClick={() => actions?.addDividerElement()}> */}
          <Button size="sm" className="min-w-[98px] h-full border-none me-3" onClick={() => actions?.addDividerElement(false)}>
            <span><MoveVertical className="w-4 h-4" /></span>Seperator
          </Button>
        </div>
        <div>
          {/* <Button size="sm" className="min-w-[98px] h-full rounded-ss-none rounded-es-none rounded-se-sm rounded-ee-sm border-none" onClick={() => actions?.addDividerElement()}> */}
          <Button size="sm" className="min-w-[98px] h-full border-none me-3" onClick={() => actions?.addDividerElement(true)}>
            <span><MoveHorizontal className="w-4 h-4" /></span>Seperator
          </Button>
        </div>
      </div>
      <div className="flex items-center align-middle flex-wrap">
        {/* <div className="toolbar-item text-sm" onClick={() => addElement("IMAGE")} title="Upload Image">
        <img src={IMAGEICON} alt="textIcon" className="h-5 w-5" />
        Upload Image
      </div> */}
        {/* <div className="toolbar-item text-sm" title="Image">
          <Button onClick={() => actions?.htmlToJpeg()} className='p-3 h-10 shadow-sm'><span>  <img src={ImageIcon} alt="textIcon" className="h-5 w-5" /></span><span>Image</span></Button>
          Upload Image
        </div> */}
        {/* <div className="toolbar-item text-sm" title="PDF">
        <Button onClick={() => actions?.toPDF()} className='p-3 h-10 shadow-sm'><span> <img src={PDF_Icon} alt="textIcon" className="h-7 w-7" /></span><span>PDF</span></Button>
        Upload Image
      </div> */}
        <div className="toolbar-item text-sm hidden" title="Export">
          {/* <Button onClick={() => onExport("IMAGE")} className='p-3 h-8 shadow-sm bg-green-400'><span><img src={Export_Icon} alt="textIcon" className="h-5 w-5" /></span><span>Export</span></Button> */}
          {/* Export */}
        </div>
        <div className="toolbar-item hidden" title="Import">
          {/* <Button onClick={triggerUpload} className='p-3 h-8 shadow-sm bg-rose-400'><span><img src={Import_Icon} alt="textIcon" className="h-5 w-5" /></span><span>Import</span></Button> */}
          <input
            type="file"
            accept=".json"
            onChange={onImport}
            style={{ display: "none" }}
            ref={uploadRef}
          />
        </div>
        <div>
          {/* <Button size="sm" className="min-w-[98px] h-full rounded-ss-none rounded-es-none rounded-se-sm rounded-ee-sm border-none" onClick={() => actions?.addDividerElement()}> */}
          {/* <Button size="sm" className="min-w-[98px] h-full border-none me-3" onClick={() => actions?.addDividerElement()}>
            <span><DivideIcon className="w-4 h-4" /></span>Add Divider
          </Button> */}
        </div>
        <div>
          <DropdownMenu  >
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="p-0 h-full">
                <span className="sr-only h-full">Open menu</span>
                {/* <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 mt-1 text-textV2" /> */}
                {/* <DotsVerticalIcon strokeWidth={"2.2"} className="h-5 w-5 mt-1 text-textV2" /> */}

                {/* <Button size="sm" className="min-w-[98px] h-full rounded-ss-none rounded-es-none rounded-se-sm rounded-ee-sm border-none"> */}
                <Button size="sm" className="min-w-[98px] h-full border-none me-3">
                  <span><DownloadIcon className="w-4 h-4" /></span>Download
                </Button>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className='border'>
              {/* <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `}
                onClick={() => actions?.updateLayout('Horizontal')}
              > <span>  <img src={Horizontal} alt="textIcon" className="h-5 w-5" /></span><span>Horizontal</span></DropdownMenuItem>
              <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `}
                onClick={() => actions?.updateLayout('Vertical')}
              > <span>  <img src={Vertical} alt="textIcon" className="h-5 w-5" /></span><span>Vertical</span></DropdownMenuItem> */}
              <DropdownMenuItem className={`text-xs flex items-center justify-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `}
                onClick={() => actions?.toPDF()}
              >
                {/* <span> <img src={PDF_Icon} alt="textIcon" className="h-6 w-6" /></span> */}
                <span>PDF </span></DropdownMenuItem>
              <Divider className={'border'} />
              <DropdownMenuItem className={`text-xs flex items-center justify-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `}
                onClick={() => actions?.htmlToJpeg()}
              >
                {/* <span>  <img src={ImageIcon} alt="textIcon" className="h-5 w-5" /></span> */}
                <span>Image </span></DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>

  );
}

export default Toolbar



// <div className="toolbar-container">
//   {/* <div className="toolbar-item" onClick={() => addElement("TEXT")}>
//     T
//   </div> */}
//   {/* <div className="toolbar-item">Size</div>
//   <div className="toolbar-item">B</div>
//   <div className="toolbar-item">I</div>
//   <div className="toolbar-item">U</div> */}
//   <select
//     className="ql-header"
//     defaultValue={""}
//     onChange={(e) => e.persist()}
//   >
//     <option value="1"></option>
//     <option value="2"></option>
//     <option selected></option>
//   </select>
//   <button className="ql-bold"></button>
//   <button className="ql-italic"></button>
//   <select className="ql-color">
//     <option value="red"></option>
//     <option value="green"></option>
//     <option value="blue"></option>
//     <option value="orange"></option>
//     <option value="violet"></option>
//     <option value="#d0d1d2"></option>
//     <option selected></option>
//   </select>
// </div>