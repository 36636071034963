import { viewDocFileActionType } from '../../constants';

const viewDocFilesReducer = (state = [], action) => {
    switch (action.type) {
        case viewDocFileActionType.VIEW_DOC_FILE:
            return action.payload;
        default:
            return state;
    }
};
export default viewDocFilesReducer