import { AdminUserActionType } from "../../../constants";

const adminUserReducer = (state = [], action) => {
    switch (action.type) {
        case AdminUserActionType.FETCH_USERS:
            return action.payload;
        case AdminUserActionType.FETCH_USER:
            return action.payload;
        case AdminUserActionType.ADD_USER:
            return action.payload;
        case AdminUserActionType.UPDATE_USER:
            return action.payload;
        case AdminUserActionType.DELETE_USER:
            return action.payload;
        case AdminUserActionType.VERIFY_USER:
            return action.payload;
        default:
            return state;
    }
};
export default adminUserReducer