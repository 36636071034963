import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import IconFile from '../svg/IconFile'

const FileUploader = ({ onChange }) => {
    const onDrop = useCallback((acceptedFiles) => {
        onChange(acceptedFiles)
    }, [])
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop })
    return (
        <div {...getRootProps()}>
            {/* <input {...getInputProps()} type='file' id='dropzone-file' className='hidden' /> */}
            <label
                htmlFor='dropzone-file'
                className={`flex flex-col items-center justify-center  p-8 w-full h-full rounded-sm cursor-pointer bg-transparent border border-borderV1 hover:border-theme border-dashed hover:bg-[#F0F6FC] ${isDragActive ? "bg-[#F0F6FC]" : "bg-[#F8F8F8]"}`}>
                <div className='flex flex-col items-center justify-center'>
                    <IconFile className='h-10 w-10 text-[#626D6D] mb-3' />
                    <div className="mb-1">
                        <h4 className='text-sm text-textV2 font-600 mb-1'>Browse Documents</h4>
                        <p className='mb-2 text-xs text-[#626D6D]'>
                            or Drag and Drop File
                        </p>
                    </div>
                    <h6 className='text-xs text-[#626D6D]'>
                        Supports: .CSV and .SVG files
                    </h6>
                </div>
                {acceptedFiles && acceptedFiles[0] ? (
                    <div className='max-w-xs bg-transparent border mt-4 border-theme flex items-center rounded-sm overflow-hidden outline-none'>
                        <div className='px-3 py-2 h-full text-xs truncate text-textV1'>
                            {acceptedFiles[0].name}
                        </div>
                    </div>
                ) : null}
                {/* <input
                    {...getInputProps()}
                    type='file'
                    id='dropzone-file'
                    className='hidden'
                    onChange={onChange}
                /> */}
            </label>
        </div>
    )
}

export default React.memo(FileUploader);