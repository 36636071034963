import React, { createContext, useEffect, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DashboardMaster from '../../../layout/dashboard/DashboardMaster';

import { fetchCountries } from '../../../store/actions/countriesAction';
import { fetchClass } from '../../../store/actions/classAction';
import { addTost } from '../../../store/actions/toastAction';
import { toastifyType } from '../../../constants';
import AssetTable from './tables/AssetTable';
import { fetchTypes } from '../../../store/actions/typeAction';
import { Card } from '../../../components/ui/Card';
export const AssentContext = createContext(null);

const Index = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { isLoading, userAnalytic, assetsAuthorize } = useSelector(state => state)
  const [addAssetsModel, setAddAssetsModel] = useState(false)
  const [showAssetsRemoval, setShowAssetsRemoval] = useState(false)
  const [addAssetsAccordion, setAddAssetsAccordion] = useState(false)
  const [fetchAssetData, setFetchAssetData] = useState({})
  const [updateId, setUpdateId] = useState(null)
  const [allAnalytics, setAllAnalytics] = useState([])
  // const [assetsData, setAssetsData] = useState([]);

  //   useEffect(() => {
  //     dispatch(fetchAssetsDetails())
  // }, [dispatch])

  useEffect(() => {
    // dispatch(fetchCountries())
    // dispatch(fetchClass('all'))
    // dispatch(fetchTypes('all'))
    // dispatch(fetchAllPortfolios())
    // dispatch(fetchSubCategories())
  }, [dispatch])

  useEffect(() => {
    setFetchAssetData({})
    setUpdateId(null)
  }, [slug])

  
  useEffect(() => {
    dispatch(fetchCountries(false))
    dispatch(fetchClass('all', null, false))
    dispatch(fetchTypes('all', null, false))
  },[])

  useEffect(() => {
    setAllAnalytics(userAnalytic)
  }, [userAnalytic])

  const onShowAssets = () => {
    setAddAssetsModel(true)
  }

  const onHideAssets = () => {
    setAddAssetsModel(false)
  }

  const onShowAssetsAccordion = () => {
    setAddAssetsAccordion(!addAssetsAccordion)
  }

  const onShowAssetsRemovalAccordion = () => {
    if (_.isEmpty(fetchAssetData)) {
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
    } else {
      setShowAssetsRemoval(!showAssetsRemoval)
    }
  }

  const onCheckBtn = (data) => {
    setFetchAssetData(data)
    setUpdateId(data?.id)
    
  }

 
  
  return (
    <DashboardMaster>
      <AssentContext.Provider value={{ showAssetsRemoval, setUpdateId, setFetchAssetData, onShowAssets, onShowAssetsAccordion, onCheckBtn, onShowAssetsRemovalAccordion, fetchAssetData, showAssetsRemoval, updateId, allAnalytics }}>
        <section className='p-2 md:px-5'>
          <Card className='my-0 md:my-0 md:p-0 p-0 bg-white'>
            <div className="main-table-wrapper">
               <AssetTable/>
            </div>
          </Card>
        </section>
      </AssentContext.Provider>
    </DashboardMaster>
  )
}

export default Index