import { typeActionType } from '../../constants';

const typeReducer = (state = [], action) => {
    switch (action.type) {
        case typeActionType.FETCH_TYPES:
            return action.payload;
        case typeActionType.ADD_TYPES:
            return action.payload;
        case typeActionType.DELETE_TYPES:
            return action.payload;
        case typeActionType.UPDATE_TYPES:
            return action.payload;
        default:
            return state;
    }
};
export default typeReducer