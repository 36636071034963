import React from 'react'
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import IconMaintanance from '../../../../components/svg/IconMaintanance';
import IconReportingNew from '../../../../components/svg/IconReportingNew';
import IssuerContent from '../tabs-content/IssuerContent';
import IssuerRemovalContent from '../tabs-content/IssuerRemovalContent';
import IssuerActionsContent from '../tabs-content/IssuerActionsContent';

const IssuerTabs = (props) => {
    const { keyValue, handleTabChange } = props;
    return (
        <div className="">
            <div className="sub-tabs-wrapper">
            <Tabs defaultValue={keyValue} value={keyValue} className="w-[full]" onValueChange={handleTabChange}>
                {/* <Tabs defaultValue={'Asset Details'} className="w-[full]" > */}
                    <div className="mb-8">
                        <TabsList className="inline-flex items-center hidden-scroll rounded-none justify-start bg-[unset] shadow-none p-0 gap-8 overflow-y-auto border-b border-[#C9C9C9]">
                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset Details"><span><IconMaintanance className="w-4 h-4" /></span> Issuer Details</TabsTrigger>
                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset Removal Details"><span><IconReportingNew className="w-4 h-4" /></span> Issuer Removal Details</TabsTrigger>
                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset Actions"><span><IconReportingNew className="w-4 h-4" /></span> Issuer Actions</TabsTrigger>
                        </TabsList>
                    </div>
                    <TabsContent value="Asset Details" className="m-0">
                        <IssuerContent />
                    </TabsContent>
                   
                    <TabsContent value="Asset Removal Details">
                        <IssuerRemovalContent />
                    </TabsContent>
                    <TabsContent value="Asset Actions">
                        <IssuerActionsContent />
                    </TabsContent>
                </Tabs> 
            </div>
        </div>
    )
}

export default IssuerTabs