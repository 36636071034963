import apiConfig from '../../config/apiConfig';
import { apiBaseURL, toastifyType, viewsActionType } from '../../constants';
import { setLoading } from './loadingAction';
import { fetchCustomColumnsViews } from './CustomColumnsViewsAction';
import { addTost } from './toastAction';


export const fetchColumnsViews = (sectionName, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.VIEWS + '/' + sectionName + '?page=' + currentPageTwo + '&perPage=' + perPageTwo)
        dispatch({ type: viewsActionType.FETCH_VIEWS, payload: response.data?.data });
        setTotalRecordsTwo && setTotalRecordsTwo(response.data?.data?.totalRecords)
        setTotalPageTow && setTotalPageTow(response.data?.data?.totalPage)
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchColumnsView = (sectionName, setLoading, setTotalRecords, setTotalPage) => async (dispatch) => {
    // dispatch(setLoading(true))
    // setLoading && setLoading(true)
    try {
        const response = await apiConfig.get(apiBaseURL.VIEWS + '/' + sectionName + '/details')
        dispatch({ type: viewsActionType.FETCH_VIEW, payload: response.data?.data });
        setLoading && setLoading(false)

        const getObjId = response.data?.data.columns.length ? response.data?.data?.columns?.map((item) => item._id) : []
        dispatch(fetchCustomColumnsViews(response.data?.data?.section, 1, 10, '', setTotalRecords, setTotalPage, getObjId))
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
        setLoading && setLoading(false)
    }
}


export const addColumnsViews = (sectionName, data, type, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.VIEWS, data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        type === 'add' && dispatch(fetchColumnsViews(sectionName, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow))
    } catch (error) {

        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message || error?.response?.data?.message }))
    }
}

export const deleteColumnsViews = (sectionName, data, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.VIEWS + '/config', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchColumnsViews(sectionName, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow))
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const activeColumnsViews = (sectionName, data, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.VIEWS + '/config', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchColumnsViews(sectionName, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow))
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

