import { assetsFinanceSearchActionType } from '../../constants';

const financeSearchReducer = (state = [], action) => {
    switch (action.type) {
        case assetsFinanceSearchActionType.FETCH_FINANCE_SEARCH_V2:
            return action.payload;
        default:
            return state;
    }
};
export default financeSearchReducer