import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { useDispatch } from 'react-redux'
import { addCustomFieldValue, fetchCustomFieldsData } from '../../../../../../store/actions/customFieldValue'

const UpdateValueModel = (props) => {
    const { updatedId, shortCodeName, data, setData, addDataIds, setAddDataIds, fetchData, setFetchData, setCurrentPage } = props;
    const dispatch = useDispatch()
    const [error, setError] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        Value: "",
    })

    useEffect(() => {
        if (fetchData !== null) {
            setFormData({
                name: fetchData.name,
                Value: fetchData.value,
            })
        }
    }, [fetchData])

    useEffect(() => {
        if (props.show === false) {
            setFormData({
                name: "",
                Value: "",
            })
            setFetchData(null)
            setError({})
        }
    }, [props.show])

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.name === '') {
            errors['Name'] = 'Please enter name';
            isValid = false;
        }

        if (formData.Value === '') {
            errors['Value'] = 'Please enter value';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };

    const onSave = () => {
        if (handleValidation()) {
            if (fetchData === null) {
                let newShortCode = generateShortCode();
                while (isShortCodeExists(newShortCode)) {
                    newShortCode = generateShortCode();
                }
                const dataS = {
                    customField: updatedId,
                    name: formData.name,
                    value: formData.Value,
                    code: newShortCode
                }
                dispatch(addCustomFieldValue(dataS)).then(() => {
                    dispatch(fetchCustomFieldsData(updatedId, 1, 5))
                })
                setFetchData(null)
            } else {
                const dataS = {
                    customField: updatedId,
                    _id: fetchData._id,
                    name: formData.name,
                    value: formData.Value,
                    code: fetchData.code
                }
                dispatch(addCustomFieldValue(dataS)).then(() => {
                    dispatch(fetchCustomFieldsData(updatedId, 1, 5))
                })
                setFetchData(null)
            }
            // const newData = [...data, dataS];
            // setData(newData);
            props.onHide();
        }
    }

    const generateShortCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let shortCode = shortCodeName;

        // Generate three random characters and numbers
        for (let i = 0; i < 3; i++) {
            shortCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return shortCode;
    };

    // Function to check if the shortcode already exists in data
    const isShortCodeExists = (shortcode) => {
        return data.some(item => item.shortCode === shortcode);
    }

    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[40%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{"Add Value"}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-5">
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                    <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Name:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Input className={`form-control shadow-none h-[34px] text-sm px-2  ${error['Name'] && 'is-invalid'}`} placeholder="name" autoComplete={'off'} name='Name' onChange={(e) => setFormData({ ...formData, name: e.target.value })} id="inputEmail3" type="text" value={formData.name} />
                                    <div className={error['Name'] ? "text-red text-[10px]" : ''}>{error['Name'] ? error['Name'] : null}</div>
                                </div>
                            </div>
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                    <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Value:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-8">
                                    <Input className={`form-control shadow-none h-[34px] text-sm px-2  ${error['Value'] && 'is-invalid'}`} placeholder="value" autoComplete={'off'} type='number' name='Value' onChange={(e) => setFormData({ ...formData, Value: e.target.value })} id="inputEmail3" value={formData.Value} />
                                    <div className={error['Value'] ? "text-red text-[10px]" : ''}>{error['Value'] ? error['Value'] : null}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default UpdateValueModel
