


import { useState, useEffect, useRef } from 'react';

const useDebounce = (value, delay, resetOnEmpty = false) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const latestValue = useRef(value);

    useEffect(() => {
        latestValue.current = value;

        const handler = setTimeout(() => {
            setDebouncedValue(latestValue.current);
        }, delay);

        // Cleanup function to ensure the correct behavior
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    useEffect(() => {
        if (resetOnEmpty && latestValue.current === '' && debouncedValue !== '') {
            setDebouncedValue('');
        }
    }, [resetOnEmpty, debouncedValue]);

    return debouncedValue;
};

export default useDebounce;
