import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addTost } from '../../../../store/actions/toastAction';
import { Filters, toastifyType } from '../../../../constants';
import _ from 'lodash'
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header';
import { Badge, generateBadgeVariant } from '../../../../components/ui/badge';
import { DataTableNew } from '../../../../shared/common/table-components/tasks/components/data-table';
import IconAdd from '../../../../components/svg/IconAdd';
import { Checkbox } from "@/components/ui/checkbox";
import { TrashIcon } from 'lucide-react'
import Divider from '../../../../components/common/Divider';
import { IconAuthorise } from '../../../../components/svg/IconAuthorise';
import IconUnauthorise from '../../../../components/svg/IconUnauthorise';
import { ThreeDots } from 'react-loader-spinner';
import { authorize, fetchAssetsDetails, unAuthorizeAssetsDetails } from '../../../../store/actions/AssetAction';
import IssuerModel from '../models/IssuerModel';
import IssuerTabs from '../tabs/IssuerTabs';
import { IssuerContext } from '../Issuer';
import { fetchIssuer } from '../../../../store/actions/issuerAction';
import DataTableColumn from '../../../../shared/common/table-components/tasks/components/data-table-column';
import useDebounce from './useDebounce';
const IssuerTable = () => {
    const { onCheckBtn, fetchAssetData, onShowAssetsRemovalAccordion, showAssetsRemoval, setFetchAssetData, setUpdateId } = useContext(IssuerContext);
    const dispatch = useDispatch();
    const { assetsV2, issuerData, metaData } = useSelector(state => state);
    const [modalShow, setModalShow] = React.useState(false);
    const [assetsData, setAssetsData] = useState([]);
    const [assets, setAssets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addAssetsModel, setAddAssetsModel] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    // useEffect(() => {
    //     dispatch(fetchAssetsDetails(null, setAssetsData, currentItems))
    // }, [])


    // useEffect(() => {
    //     setIsLoading(true)
    //     dispatch(fetchAssetsDetails(setIsLoading, setAssetsData, currentPage, perPage))
    // }, [currentPage, perPage])

    const onChange = (filter) => {
        setIsLoading(true)
        // dispatch(fetchAssetsDetails(setIsLoading, setAssetsData, filter.page, filter.perPage))
        dispatch(fetchIssuer(setIsLoading, setAssetsData, filter.page, filter.perPage, searchValue))
    }
    const debouncedText = useDebounce(searchValue, 500);
    useEffect(() => {
        setIsLoading(true)
        debouncedText.length && dispatch(fetchIssuer(setIsLoading, setAssetsData, 1, metaData.pageSize, debouncedText))
    }, [debouncedText])

    const onSearch = (value) => {
        setSearchValue(value)
    }

    useEffect(() => {
        if (assetsData.records) {
            setAssets(assetsData.records)
        }
    }, [assetsData])

    useEffect(() => {
        setAssetsData(issuerData)
    }, [issuerData])

    useEffect(() => {
        if (assetsData.isCurrentPriceInUpdate) {
            const refreshTimeInMinutes = assetsData.refreshTime;
            const refreshTimeInSeconds = refreshTimeInMinutes * 1000;
            // Using setTimeout
            const fetchData = setTimeout(() => {
                // Your code to be executed after 5 minutes
                dispatch(fetchIssuer(null, setAssetsData, metaData?.pageNumber, metaData.pageSize));
            }, refreshTimeInSeconds); // Multiply by 1000 to convert seconds to milliseconds

            // return () => clearTimeout(fetchData)
        }
    }, [assetsData])

    const save = (singlePortfolio) => {
        const data = {
            assetId: fetchAssetData._id,
            portfoliosIds: singlePortfolio.map((item) => item.id)
        }
        if (fetchAssetData && fetchAssetData._id) {
            // setAssets([])
            setAssetsData([])
            setFetchAssetData({})
            setUpdateId(null)
            dispatch(authorize(data, data.assetId, setAssetsData, Filters.OBJ.page, Filters.OBJ.perPage))
            setModalShow(false)
            dispatch(fetchAssetsDetails(null, setAssetsData, Filters.OBJ.page, Filters.OBJ.perPage))
        }

    }

    const onShowAssets = () => {
        setAddAssetsModel(true)
    }

    const onHideAssets = () => {
        setAddAssetsModel(false)
    }

    // const handelDelete = (e, data) => {
    //     // let payloadData = { categories: [] };
    //     // payloadData.categories.push(data.slug);
    //     // dispatch(deletePortfolio(data.slug));
    // };

    const handelDelete = (e, data) => {
        // let payloadData = { categories: [] };
        // payloadData.categories.push(data.slug);
        // dispatch(deletePortfolio(data.slug));
    };

    const handelEdit = (e, data, id) => {
        // setModalShow(true);
        // setFetchData(data);
        // setFetchId(id);
    };
    const [unAuthorizeModalShow, setUnAuthorizeModalShow] = React.useState(false);
    const onUnAuthorize = (singlePortfolio) => {
        const data = {
            assetId: fetchAssetData._id,
            portfoliosIds: singlePortfolio.map((item) => item.id)
        }
        if (fetchAssetData && fetchAssetData._id) {
            setAssetsData([])
            setFetchAssetData({})
            setUpdateId(null)
            dispatch(unAuthorizeAssetsDetails(data, data.assetId, setAssetsData, Filters.OBJ.page, Filters.OBJ.perPage))
            setUnAuthorizeModalShow(false)
            // dispatch(fetchAssetsDetails(null, setAssetsData))
        }

    }

    const data = assets && assets.length >= 1 ? assets.map((item, index) => {
        return { id: item._id, Name: item.name ? item.name : '-', ...item }
    }) : []

    // 'removed'
    // 'inactive'
    // 'active'

    const columns = [
        {
            id: 'select',
            header: ({ table }) => (
                // Header content for the 'select' column
                <>
                    {/* Add any header content if needed */}
                </>
            ),
            cell: ({ row }) => (
                // Cell content for the 'select' column
                <Checkbox
                    checked={row.original?._id === fetchAssetData?._id}
                    onCheckedChange={(value) => { row.toggleSelected(!!value); handleRowSelected(row?.original) }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "Id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.issuerId}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.Name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Code",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Code" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.code ? row?.original?.code : '-'}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Country",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Country" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.country}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Status",
            header: "Status",
            cell: ({ row }) => {
                const variant = generateBadgeVariant(row?.original.status)
                return <Badge variant={variant} className={""}>{row?.original.status}</Badge>
            },
        },
        // {
        //     accessorKey: "Symbol",
        //     header: ({ column }) => (
        //         <DataTableColumnHeader column={column} title="Symbol" />
        //     ),
        //     cell: ({ row }) => {
        //         return <span className='flex items-center'>{row?.original?.symbol ? row?.original?.symbol : '-' }</span>
        //     },
        //     enableSorting: true,
        // },
        {
            accessorKey: "Description",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Description" />
            ),
            cell: ({ row }) => {
                return <DataTableColumn title={row?.original?.description ? row?.original?.description : '-'} name={row?.original?.description ? row?.original?.description : '-'} />
            },
            enableSorting: true,
        },

        
       
       
       
        {
            accessorKey: "Parent Issuer",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Parent Issuer" />
            ),
            cell: ({ row }) => {
                return <DataTableColumn title={row?.original?.parentIssuer ? row?.original?.parentIssuer : '-'} name={row?.original?.parentIssuer ? row?.original?.parentIssuer : '-'} />
            },
            enableSorting: true,
        },
        
    ]

    const handleRowSelected = async (rows) => {
        // Handle row selection
        // await onCheckBtn(rows[0])
        await rows && onCheckBtn(rows)
    };



    const [keyValue, setKeyValue] = useState('Asset Details')
    const handleTabChange = (value) => {
        if (_.isEmpty(fetchAssetData)) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please Select Asset!' }))
        } else {

            setKeyValue(value)
        }
    }


    const rightHeaderButtons = [
        {
            icon: <IconAdd className="w-4 h-4 " />,
            text: 'Add Issuer',
            onClick: () => { onShowAssets(setAssetsData); setFetchAssetData({}) },
            variant: '',
            additionalClass: ""
        }

    ]


    const leftHeaderButtons = [
        // {
        //     icon: <TrashIcon className="w-4 h-4 text-red transition duration 200" />,
        //     text: 'Remove Selected',
        //     onClick: () => { onShowAssetsRemovalAccordion(); setKeyValue('Asset Removal Details') },
        //     variant: "outline",
        //     additionalClass: "rounded-[6px] h-8 lg:flex group !border-red !text-red"
        // },

    ]


    return (
        <div className="">
            <div className={`p-3 md:p-5 ${fetchAssetData?._id ? '' : ''} `}>
                <DataTableNew data={data} columns={columns} isCollViewHide={false} loadData={isLoading} onChange={onChange} filterName={'Name'}
                    isRightNew={true}
                    isOldHeaderHide={true}
                    isRightSearch={true}
                    isRightHeaderButtons={true}
                    rightHeaderButtons={rightHeaderButtons}
                    isLeftHeaderButtons={true}
                    leftHeaderButtons={leftHeaderButtons}
                    isCustomSearch={true}
                    onSearch={onSearch}
                    isRight={true} />
                <IssuerModel
                    show={addAssetsModel}
                    onHide={() => onHideAssets(false)}
                    setAssetsData={setAssetsData}
                />
            </div>
            <div className="sub-tabs-content-wrapper">
                {fetchAssetData?._id ?
                    <div className="">
                        <Divider className={"border-borderV1"} />
                        <div className='p-3 md:p-5'>
                            <IssuerTabs keyValue={keyValue} handleTabChange={handleTabChange} />
                        </div>
                    </div>
                    : ''}
            </div>
        </div>
    )
}

export default IssuerTable
