import { Tokens, errorMessage } from '../constants';
import { environment } from './environment'

export default {
    setupInterceptors: (axios, isTokens = false, isFormData = false) => {
        axios.interceptors.request.use((config) => {
            if (isTokens) {
                return config;
            }
            let isToken = localStorage.getItem(Tokens.ADMIN_TOKEN);
            if (isToken) {
                config.headers['Authorization'] = `Bearer ${(isToken)}`;
                // config.headers['ngrok-skip-browser-warning'] = true;
            }
            if (!isToken) {
                if (!window.location.href.includes('login')
                    && !window.location.href.includes('register')) {
                    window.location.href = environment.BASE_URL + "/";
                }
                return config;
            }
            if (isFormData) {
                config.headers['Content-Type'] = 'multipart/form-data';
            }
            config.headers['ngrok-skip-browser-warning'] = true;
            return config;
        },
            (error) => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            response => successHandler(response),
            error => errorHandler(error)
        );
        const errorHandler = (error) => {
            
            if (error.response.status === 401
                || error.response.data.message === errorMessage.TOKEN_NOT_PROVIDED
                || error.response.data.message === errorMessage.TOKEN_INVALID
                || error.response.data.message === errorMessage.TOKEN_INVALID_SIGNATURE
                || error.response.data.message === errorMessage.TOKEN_EXPIRED) {
                localStorage.removeItem(Tokens.ADMIN_TOKEN);
                window.location.href = environment.BASE_URL + '/login';
                // window.location.href = environment.BASE_URL + '/';
            } else if (error.response.status === 403 || error.response.status === 404) {
                    // window.location.href = environment.BASE_URL + '/editor';
                    // window.location.href = environment.BASE_URL + '/';
            } else if (error.response.status === 500) {
                return Promise.reject({ ...error })
            } else {
                return Promise.reject({ ...error })
            }
        };
        const successHandler = (response) => {
            return response;
        };
    }
};
