
import { apiBaseURL, assetsDetailsActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";
import { fetchPortfolioGroupsAsset } from "./portfolioGroupAction";
import { metaDataAction } from "./metaDataAction";


export const fetchInvestInAssetDetails = (setIsLoading, id, setTotalRecords, setTotalPages, currentPage, perPage) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '/' + id + '/authorise-assets?page='+currentPage+ '&perPage='+perPage)
        dispatch({ type: assetsDetailsActionType.FETCH_ASSETS_DETAILS, payload: response.data?.data });
        setTotalRecords(response.data?.data?.totalRecords || response.data.totalRecords)
        setTotalPages(Math.ceil((response.data?.data?.totalItems || response.data?.data?.totalRecords) / (perPage || 10)))
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchAssetsDetails = (setIsLoading, id, currentPage) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '/' + id + '/authorise-assets?page='+currentPage+ '&perPage='+5)
        dispatch({ type: assetsDetailsActionType.FETCH_ASSETS_DETAILS, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: 5, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchAssetsReportingDetails = (setIsLoading, id, from) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '/' + id + '/authorise-assets?from='+from)
        dispatch({ type: assetsDetailsActionType.FETCH_ASSETS_DETAILS, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: 5, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchAssetsPortfolio = (setIsLoading, id) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '/' + id + '/added-assets')
        dispatch({ type: assetsDetailsActionType.FETCH_ASSETS_DETAILS_ADDED, payload: response.data?.data });
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const addAssetsDetails = (data, id) => async (dispatch) => {
    // dispatch(setLoading(true))
    // try {
    //     const response = await apiConfig.post(apiBaseURL.PORTFOLIOS + '/' + id + apiBaseURL.ASSETS_DETAILS, data)
    //     dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
    //     dispatch(fetchAssetsDetails(null, id))
    // } catch (error) {
    //     dispatch(setLoading(false))
    //     dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    // }
}

export const addAssetsV2Details = (data, section) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIOS +apiBaseURL.ASSETS_DETAILS + '/add', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAssetsDetails(null, data.portfolio))
        dispatch(fetchAssetsPortfolio(null, data.portfolio))
    } catch (error) {
        dispatch(setLoading(false))

        const { response } = error
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: response?.data?.message }))
    }
}

export const updateAssetsDetails = (data, slug, allPortfolios) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.put(apiBaseURL.PORTFOLIOS +apiBaseURL.ASSETS_DETAILS + '/update', data)
        // const response = await apiConfig.put(apiBaseURL.PORTFOLIOS + '/' + updateId + apiBaseURL.ASSETS_DETAILS + '/' + id, data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAssetsDetails(null, slug))
        dispatch(fetchAssetsPortfolio(null, data.portfolio))
        allPortfolios && dispatch(fetchPortfolioGroupsAsset(allPortfolios[0]?.portfolioGroup?._id))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}



export const removeAssetsDetails = (data, id, allPortfolios) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIOS +apiBaseURL.ASSETS_DETAILS + '/remove', data)
        // const response = await apiConfig.post(apiBaseURL.PORTFOLIOS + '/' + id + apiBaseURL.ASSETS_DETAILS + '/remove', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchAssetsDetails(null, id))
        dispatch(fetchAssetsPortfolio(null, data.portfolio))
        allPortfolios && dispatch(fetchPortfolioGroupsAsset(allPortfolios[0]?.portfolioGroup?._id))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const fetchReportsDetails =
    (
        chartReport,
        setChartData,
        chartType,
        setIsLoading,
        isAutoChart,
        indicator,
        portfolioId,
        chartData,
        changeChartDate
    ) =>
        async (dispatch) => {
            try {
                const response = await apiConfig.post(apiBaseURL.REPORTS, chartReport)
                const { data } = response.data
                setIsLoading(true)
                if (changeChartDate) {
                    changeDate(data, chartData, setChartData, setIsLoading, dispatch)
                } else {
                    createChart(data, chartReport, chartData, setIsLoading, setChartData, chartType, isAutoChart, dispatch)
                }

            } catch (error) {
                dispatch(
                    addTost({
                        type: toastifyType.SHOW_ERROR_MESSAGE,
                        message: error?.data?.message,
                    })
                );
            }
        };


        // create chart
const createChart = (data, chartReport, chartData, setIsLoading, setChartData, chartType, isAutoChart, dispatch) => {
    if (data.length) {
        let updateData = [];
        for (let index = 0; index < data.length; index++) {
            updateData.push({
                analytics: data[index].analytics,
                type: chartType,
                data: [data[index]],
                isAutoChart: isAutoChart,
                portfolio: chartReport[0].portfolio,
                chartId: data[index].chartId
            });
        }
        setChartData([...data, ...chartData])
        // dispatch(getAllReports(setChartData));
        // const prepareData = [...updateData, ...chartData]
        // dispatch(saveReports(prepareData, setChartData));
        setIsLoading(false)
    } else {
        const updateData = [{
            analytics: chartReport.reports[0].analytics,
            type: chartType,
            data: data,
            isAutoChart: isAutoChart,
            portfolio: chartReport.reports[0].portfolio,
            chartId: data.chartId
        }]
        setChartData([...data, ...chartData])
        // dispatch(getAllReports(setChartData));
        // const prepareData = [...updateData, ...chartData]
        // dispatch(saveReports(prepareData, setChartData))
        setIsLoading(false)
    }
}

const changeDate = (data, chartData, setChartData, setIsLoading, dispatch) => {
    const updateData =
        chartData &&
        chartData.length &&
        chartData.map((item, index) => {
            let oldIndex = index;
            item.data = data.filter((x, index) => index === oldIndex);
            return item;
        });
        setChartData([...data])
        // dispatch(getAllReports(setChartData));
    // dispatch(saveReports(updateData, setChartData));
    setIsLoading(false);
}