import apiConfig from '../../config/apiConfig';
import { apiBaseURL, byodActionType, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { metaDataAction } from './metaDataAction';
import { AddNoteContent } from './researchNoteContentAction';
import { addTost } from './toastAction';


export const fetchCustomFields = (filter) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        // Construct the query string based on the properties of the filter object
        const queryParams = new URLSearchParams();
        if (filter.page) queryParams.append('page', filter.page);
        if (filter.perPage) queryParams.append('perPage', filter.perPage);
        const queryString = queryParams.toString();
        const response = await apiConfig.get(apiBaseURL.BYOD + '?' + queryString)
        dispatch({ type: byodActionType.FETCH_BYOD_V2, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: filter.perPage, totalItems: response?.data?.data?.totalRecords }))
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const addCustomField = (Data, filter) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.BYOD+'/add', Data)
        dispatch(fetchCustomFields(filter))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const deleteCustomField = (id) => async (dispatch) => {
    try {
        const response = await apiConfig.delete(apiBaseURL.BYOD+'/'+id)
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const getCustomField = (id) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.BYOD+'/' + id)
        return response.data;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}


export const addManuallyData = (id, data, setKey, setIsUpload, group)  => async (dispatch) => {
    try {
        // +  group + '/'
        const response = await apiConfig.post(apiBaseURL.CUSTOM_FIELD_DATA+'/add-manually/' + id, data)
        // setKey && setKey("preview")
        setIsUpload && setIsUpload(true)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        return response.data;
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message || error?.data?.message }))
    }
}
export const addUploadData = (data, setKey)  => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.CUSTOM_FIELD_DATA+'/bulk-upload', data)
        setKey && setKey("preview")
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        return response.data;
    } catch (error) {
        dispatch(setLoading(false))
        if (error.response && error.response.status === 400) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error.response.data.message }))
        } else {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'An unexpected error occurred. Please try again later.' }))
        }
    }
}



export const fetchCustomFieldValues = (id, currentPage, perPage, setIsLoading) => async (dispatch) => {
    setIsLoading && setIsLoading(true)
    try {
        const response = await apiConfig.get(apiBaseURL.BYOD+'-data' +'?page=' + currentPage + '&perPage=' + perPage)
        // dispatch({ type: byodActionType.FETCH_BYOD_VALUE_V2, payload: response.data?.data });
        dispatch({ type: byodActionType.FETCH_BYOD_VALUE_V2, payload: response.data?.data });
        setIsLoading && setIsLoading(false)
    } catch (error) {
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchCustomFieldValue = (id, currentPage, perPage, setIsLoading) => async (dispatch) => {
    setIsLoading && setIsLoading(true)
    try {
        const response = await apiConfig.get(apiBaseURL.BYOD+'-data?fieldId='+id +'&page=' + currentPage + '&perPage=' + perPage)
        dispatch({ type: byodActionType.FETCH_BYOD_VALUE2_V2, payload: response.data?.data });
        setIsLoading && setIsLoading(false)
    } catch (error) {
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}