
import { apiBaseURL } from '../../../../constants';
import apiConfig from '../../../../config/apiConfig';
import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/ui/popover';
import { Button } from '../../../../components/ui/button';
import { Check, ChevronsUpDown } from 'lucide-react';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../../../../components/ui/command';
import { cn } from '../../../../lib/utils';

const ValueSelect = (props) => {
  const { onValueSelect, index, editData, id, row, isUpdate, readOnly, disable } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hseMore, setHseMore] = useState(true);
  const [selectedItem, setSelectedItem] = useState({ value: null, label: 'select an option' });
  const [searchVal, setSearchVal] = useState('');
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState("")

  useEffect(() => {
    fetchData(searchVal);
    setSelectedItem(null)
  }, [editData?._id]);

  useEffect(() => {
    if (searchVal !== '') {
      setPage(1);
      setHseMore(true);
      setData([]);
      fetchData(searchVal);
    }
  }, [searchVal]);

  //   useEffect(() => {
  //     if (data?.length >= 1) {
  //         // Check if `row.Value` has a label
  //         if (typeof row.Value === 'object' && row.Value.label) {
  //             // Do something if `row.Value` has a label
  //             const getObj = data.find((item) => item.label === row.Value.label)
  //             if(getObj.label){
  //               setSelectedItem(getObj)
  //             }
  //         }

  //         // Check if `row.Value` is an object and not equal to 0
  //         if (typeof row.Value !== 'object' && row.Value !== 0) {
  //             // Do something if `row.Value` is an object and not equal to 0
  //             const getObj = data.find((item) => item.label === row.Value)
  //             if(getObj.label){
  //               setSelectedItem(getObj)
  //             }
  //         }

  //         // Check if `editData.Value` is not null
  //         if (editData.Value !== null) {
  //             // Do something if `editData.Value` is not null
  //             const getObj = data.find((item) => item.label === editData.Value?.label)
  //             if(getObj.label){
  //               setSelectedItem(getObj)
  //             }
  //         }
  //     }
  // }, [data, row.Value, editData.Value]);

  const fetchData = async () => {
    try {
      if (hseMore) {
        setLoading(true);
        const id = editData?._id ? editData?._id : editData?.id ? editData?.id : ''
        const response = await apiConfig.get(apiBaseURL.CUSTOM_FIELD_VALUE + '/' + id);
        // const response = await apiConfig.get(apiBaseURL.CUSTOM_FIELD_VALUE + '/' + editData?._id + '?page=' + page + '&perPage=' + 6 + '&name=' + searchVal);
        const options = editData.subType === 'Numeric' ? response.data?.data?.records?.length >= 1
          ? response.data?.data?.records?.map((item) => {
            return { value: item._id, label: item.value, items: item };
          })
          : [] : response.data?.data?.records?.length >= 1
          ? response.data?.data?.records?.map((item) => {
            return { value: item._id, label: item.name, items: item };
          })
          : []
        setData(prevData => [...options]);
        // setData(prevData => [...prevData, ...options]);
        setHseMore(response.data?.data?.totalPage === 0 ? false : page === response.data?.data?.totalPage ? false : response.data?.data?.records?.length === 0 ? false : true)
        setPage(prevPage => prevPage + 1);
        // setPage(pageNum + 1); // Always increment page number when fetching more data
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    }
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    if (isUpdate) {
      onValueSelect(item)
    } else {
      onValueSelect(id, editData, index, item)
    }
    // You can perform any other action when an item is selected
  };
  // handleScroll
  // h-[113px] overflow-auto

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full p-2 justify-between border-gray-300 text-gray-600 hover:text-gray-600 hover:border-gray-300 "
        >
          {selectedItem !== null
            ? data.find((framework) => framework.value === selectedItem.value)?.label
            : row.Value !== 0 ? row.Value === undefined ? 'Select an option' : row.Value?.label ? row.Value?.label : row.Value : editData.Value !== null ? editData?.Value === 0 ? `Select an option` : editData?.Value?.label : `Select an option`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command className='bg-white'>
          <CommandInput placeholder={'Select Option...'} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {data.map((framework) => {
                return (
                  <CommandItem
                  className='cursor-pointer border'
                    key={framework.value}
                    value={framework}
                    onSelect={(currentValue) => {
                      handleSelectItem(framework)
                      setOpen(false)
                    }}
                  >
                    {framework.label}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default ValueSelect;

{/* <Select
className='react_selector byod_react_selector'
onValueChange={(e) => handleSelectItem(JSON.parse(e))}
defaultValue={selectedItem ? selectedItem.label : row.Value !== 0 ? row.Value?.label ? row.Value?.label : row.Value : editData.Value !== null ? editData?.Value?.label : `Select an option`}
isDisabled={readOnly || disable}
readOnly={readOnly}
>
<SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
  <SelectValue
    className='text-textV1'
    isDisabled={readOnly || disable}
    readOnly={readOnly}
    placeholder="Select an option"
  >
    {selectedItem ? selectedItem.label : row.Value !== 0 ? row.Value?.label ? row.Value?.label : row.Value : editData.Value !== null ? editData?.Value?.label : `Select an option`}
  </SelectValue>
</SelectTrigger>
<SelectContent>
  <SelectGroup>
    {
      data && data.length > 0 ? data.map((item, index) => {
        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
      }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
    }
  </SelectGroup>
</SelectContent>
</Select> */}

{/* <Select
options={data}
className="react_selector byod_react_selector"
onChange={handleSelectItem}
value={selectedItem}
// onInputChange={(value) => {
//   setSearchVal(value)
// }}
// isDisabled={isDisabled}
// isLoading={loading}
// onMenuScrollToBottom={() => fetchData(searchVal)}
// onScroll={() => fetchData(searchVal)}
// onMenuClose={() => {
//   setPage(1);
//   setHseMore(true);
//   setData([]);
// }}
// onMenuOpen={() => fetchData(searchVal)}
isDisabled={readOnly || disable}
readOnly={readOnly}
placeholder={row.Value !== 0 ? row.Value?.label ? row.Value?.label : row.Value : editData.Value !== null ? editData?.Value?.label : `Select an option`}
styles={{
  groupHeading: (base) => ({
    color: "black",
  }),
  menuList: (base) => ({
    fontSize: "0.75rem",
    overflow: "auto",
    maxHeight: "180px",
    lineHeight: '1rem',
    fontWeight: 500,
    borderRadius: '5px',

  }),
  container: (base, { isSelected, isFocused }) => ({
    ...base,
    ":focus": {
      fontSize: "0.75rem",

    }
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    cursor: "pointer",
    borderRadius: '5px',
    margin: "0px 5px",
    backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
    color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
    ":hover": {
      backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
    },
    ":focus": {
      fontSize: "0.75rem",
    }
  }),
}}
/> */}