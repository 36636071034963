import React, { useEffect, useState } from 'react'
import IconMoon from '../svg/IconMoon';
import IconSun from "../svg/IconSun"
import { cn } from '../../lib/utils';

const ThemeToogle = ({className}) => {
    const[theme,setTheme] = useState("")

    return (
      <button variant={""} className={cn("w-8 h-8 bg-themeV2 flex items-center justify-center  rounded-[6px]",className)} onClick={()=>setTheme(theme === "dark" ? "light" : "dark")}>
        {theme === "dark" ? <IconMoon className="w-5 h-5 text-white"/> : <IconSun className="w-5 h-5 text-white"/>}
      </button>
  )
}

export default ThemeToogle