export const getImageDimensions = async (file) => {
    return new Promise((resolved, rejected) => {
        var i = new Image();
        i.onload = function () {
            resolved({
                w: i.width,
                h: i.height,
                nw: i.naturalWidth,
                nh: i.naturalHeight
            });
        };
        i.src = file;
    });
};

export const getAdjustedDimenstions = (
    width,
    height,
    resultWidth
) => {
    const ratio = width / height;
    return { calcWidth: resultWidth, calcHeight: resultWidth / ratio };
};
