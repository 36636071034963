import React, { useState } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useDispatch } from 'react-redux';
import { addColumnsViews } from '../../../../store/actions/ColumnsViewsActine';

const AddListPopup = (props) => {
    const { show, onHide, sectionType, totalRecordsTow, perPageTwo, setTotalRecordsTwo, setTotalPageTow, setCurrentPageTwo,setAddColViewRow, setActiveList, setNewActiveList, setEditColumns, setDefaultActive, setAddListData } = props;
    const [name, setName] = useState('')
    const dispatch = useDispatch();
    const onSave = () => {
        const transformedData = {
            name: name,
            section: sectionType,
            columns: [],
        };
        let perTotalRecords;

        if (totalRecordsTow % perPageTwo === 0) {
            perTotalRecords = true;
        } else {
            perTotalRecords = false;
        }
        let page = perTotalRecords ? Math.ceil(totalRecordsTow / perPageTwo) + 1 : Math.ceil(totalRecordsTow / perPageTwo);
        dispatch(addColumnsViews(sectionType, transformedData, 'add', page, perPageTwo, setTotalRecordsTwo, setTotalPageTow))
        setCurrentPageTwo(page)
        setAddColViewRow({})
        setActiveList({})
        setNewActiveList({})
        setEditColumns({})
        setDefaultActive({})
        setAddListData({})
    }
    return (
        <Dialog open={show} onOpenChange={onHide}>
            <DialogContent className=" w-[95%] gap-6">
                <DialogHeader>
                    <DialogTitle className="text-start">Add Views</DialogTitle>
                </DialogHeader>
                <div className="dialog-body">
                    <div className="grid grid-cols-1 gap-5">
                        <Input type='text' placeholder={'Name'} className={'w-[100%]'} name='name' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <DialogFooter className={"!justify-center !gap-2 p-5"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={() => onSave()}
                        disabled={name.length === 0}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
                    </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default AddListPopup
