import React from 'react'

const IconAnalysis = (props) => {
    return (

        <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.0" fill="none" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" filter="url(#filter0_d_787_4028)">
                <path d="M702 5109 c-177 -31 -320 -107 -452 -239 -165 -165 -250 -365 -250 -590 0 -225 85 -425 250 -590 165 -165 365 -250 590 -250 225 0 425 85 590 250 236 236 309 551 201 867 -89 259 -300 456 -573 533 -84 24 -273 34 -356 19z m276 -165 c152 -31 307 -127 398 -247 190 -250 190 -584 0 -834 -119 -158 -334 -263 -536 -263 -202 0 -417 105 -536 263 -123 162 -168 360 -128 555 75 366 433 601 802 526z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M805 4775 c-20 -20 -25 -34 -25 -78 l0 -54 -51 -5 c-53 -5 -89 -26 -123 -72 -18 -24 -21 -42 -21 -145 0 -114 1 -119 29 -155 42 -55 76 -66 211 -66 l115 0 0 -60 0 -60 -155 0 c-152 0 -156 -1 -180 -25 -33 -32 -33 -78 0 -110 20 -21 34 -25 80 -25 l55 0 0 -55 c0 -67 29 -105 80 -105 51 0 80 38 80 103 l0 54 51 5 c53 5 89 26 123 72 17 23 22 45 24 130 4 121 -8 160 -64 202 -36 28 -42 29 -166 32 l-128 4 0 59 0 59 155 0 c152 0 156 1 180 25 16 15 25 36 25 55 0 51 -38 80 -105 80 l-55 0 0 55 c0 67 -29 105 -80 105 -19 0 -40 -9 -55 -25z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M1680 4826 c0 -3 18 -39 41 -80 l41 -76 109 0 109 0 0 -700 c0 -552 3 -701 13 -705 6 -2 42 -6 80 -8 l67 -5 0 767 0 768 -23 21 c-22 21 -32 22 -230 22 -114 0 -207 -2 -207 -4z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M3058 4816 c-10 -7 -22 -25 -28 -40 -12 -34 -14 -2204 -1 -2224 5 -8 10 26 13 86 4 84 10 109 35 164 17 36 47 83 67 105 l36 40 0 862 0 861 120 0 120 0 0 -815 0 -815 80 0 80 0 0 873 0 874 -23 21 c-23 21 -31 22 -253 22 -172 -1 -233 -4 -246 -14z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M2302 4273 l3 -78 278 -3 277 -2 0 80 0 80 -280 0 -281 0 3 -77z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M3742 4273 l3 -78 588 -5 589 -5 19 -24 c18 -22 19 -47 19 -577 0 -464 2 -554 14 -554 7 0 43 -15 80 -33 l66 -34 0 592 c0 659 1 650 -67 720 -75 78 -45 75 -716 75 l-598 0 3 -77z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M2332 4010 c-11 -11 -24 -35 -27 -52 -3 -18 -4 -192 -3 -386 l3 -353 73 -30 c40 -16 75 -29 78 -29 2 0 4 160 4 355 l0 355 120 0 120 0 0 -432 0 -432 80 -82 80 -82 0 572 0 573 -23 21 c-23 21 -31 22 -253 22 -216 0 -232 -1 -252 -20z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M1692 3758 c-24 -38 -59 -87 -78 -109 l-34 -41 0 -199 c0 -109 3 -199 8 -198 4 0 39 8 77 18 l70 19 3 146 c1 80 1 210 0 289 l-3 143 -43 -68z" />
                <path d="M3 2570 c2 -1115 3 -1166 21 -1200 26 -49 64 -88 111 -113 34 -18 60 -22 180 -25 l140 -3 80 80 80 81 -192 0 c-204 0 -233 5 -252 47 -8 17 -11 323 -11 1064 l0 1040 -62 72 c-35 40 -71 83 -80 97 -16 24 -17 -39 -15 -1140z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M892 3210 c-11 -11 -24 -35 -27 -52 -3 -18 -4 -172 -3 -343 3 -266 5 -306 16 -280 28 64 86 172 113 211 28 41 29 46 31 185 l3 144 118 3 c102 2 117 1 117 -13 0 -22 12 -18 92 30 55 33 68 45 68 66 0 14 -10 35 -23 47 -23 21 -31 22 -253 22 -216 0 -232 -1 -252 -20z" />
                <path d="M3778 3216 c-25 -19 -38 -60 -38 -122 l0 -54 80 0 c64 0 80 3 80 15 0 13 20 15 120 15 100 0 120 -2 120 -15 0 -12 16 -15 80 -15 l80 0 0 73 c0 64 -3 76 -23 95 -23 21 -31 22 -253 22 -172 -1 -233 -4 -246 -14z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M1750 3081 c-307 -67 -556 -260 -690 -536 -78 -159 -95 -238 -95 -440 0 -146 3 -182 23 -255 28 -105 95 -245 157 -329 l47 -64 -70 -70 -70 -70 -32 26 c-47 40 -101 53 -152 38 -37 -11 -99 -68 -431 -399 -214 -213 -395 -399 -403 -414 -18 -35 -18 -111 0 -146 20 -39 217 -229 250 -242 42 -16 98 -12 132 9 37 23 792 779 810 812 27 50 13 123 -34 172 l-26 28 70 70 71 71 64 -47 c325 -239 787 -252 1127 -32 198 128 346 328 419 564 25 81 27 102 27 268 1 158 -2 190 -22 265 -48 174 -135 325 -261 451 -127 127 -280 215 -451 260 -120 31 -341 36 -460 10z m415 -160 c276 -71 490 -271 587 -551 32 -92 33 -99 33 -260 0 -161 -1 -168 -33 -260 -75 -216 -209 -376 -403 -479 -245 -130 -515 -134 -763 -12 -196 96 -337 249 -411 445 -120 320 -42 675 201 907 120 115 264 192 409 218 33 6 71 13 85 15 52 9 218 -4 295 -23z m-1452 -2223 l-368 -368 -82 82 -83 83 367 367 368 368 82 -82 83 -83 -367 -367z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M1987 2783 c-4 -3 -7 -154 -7 -335 l0 -328 -120 0 -120 0 0 316 0 315 -22 -7 c-13 -3 -49 -20 -80 -35 l-58 -29 0 -280 0 -280 -80 0 -80 0 -2 201 -3 202 -42 -74 c-77 -137 -110 -313 -84 -441 l12 -58 659 0 658 0 6 23 c3 13 8 48 12 80 l7 56 -89 3 -89 3 -5 230 c-3 127 -8 235 -13 242 -10 16 -127 103 -138 103 -5 0 -9 -123 -9 -285 l0 -285 -80 0 -80 0 0 324 0 324 -63 11 c-74 13 -81 13 -90 4z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M3360 2867 c-49 -16 -133 -102 -148 -153 -17 -60 -17 -2488 0 -2548 16 -55 99 -138 154 -154 59 -17 1529 -17 1588 0 55 16 138 99 154 154 17 60 17 2488 0 2548 -16 55 -99 138 -154 154 -56 16 -1542 15 -1594 -1z m1575 -172 l25 -24 0 -1231 0 -1231 -25 -24 -24 -25 -751 0 -751 0 -24 25 -25 24 0 1231 0 1231 25 24 24 25 751 0 751 0 24 -25z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M3615 2546 c-41 -18 -83 -69 -90 -109 -3 -18 -5 -136 -3 -263 l3 -231 29 -37 c52 -68 38 -67 621 -64 511 3 524 3 551 24 69 51 69 51 72 319 3 275 0 293 -64 341 l-37 29 -526 2 c-422 2 -532 0 -556 -11z m1025 -346 l0 -200 -480 0 -480 0 0 200 0 200 480 0 480 0 0 -200z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M3615 1666 c-41 -18 -83 -69 -90 -109 -3 -18 -5 -280 -3 -583 l3 -551 29 -37 c52 -68 38 -67 621 -64 511 3 524 3 551 24 15 11 37 33 48 48 21 27 21 40 24 591 3 628 5 609 -64 661 l-37 29 -526 2 c-422 2 -532 0 -556 -11z m465 -266 l0 -120 -200 0 -200 0 0 120 0 120 200 0 200 0 0 -120z m560 0 l0 -120 -200 0 -200 0 0 120 0 120 200 0 200 0 0 -120z m-560 -400 l0 -120 -200 0 -200 0 0 120 0 120 200 0 200 0 0 -120z m560 0 l0 -120 -200 0 -200 0 0 120 0 120 200 0 200 0 0 -120z m-560 -400 l0 -120 -200 0 -200 0 0 120 0 120 200 0 200 0 0 -120z m560 0 l0 -120 -200 0 -200 0 0 120 0 120 200 0 200 0 0 -120z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M578 2096 c-26 -19 -39 -62 -28 -90 17 -46 46 -56 158 -56 91 0 103 2 98 16 -3 9 -6 45 -6 80 l0 64 -102 0 c-69 -1 -109 -5 -120 -14z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M2802 1313 c-39 -42 -72 -78 -72 -80 0 -1 70 -3 155 -3 l155 0 0 80 0 80 -83 0 -82 0 -73 -77z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
                <path d="M1372 937 c-13 -30 -32 -63 -43 -72 -10 -10 -19 -22 -19 -27 0 -4 389 -8 865 -8 l865 0 0 80 0 80 -383 0 c-308 0 -395 -3 -452 -16 -114 -25 -313 -29 -425 -8 -52 9 -160 19 -240 21 l-146 4 -22 -54z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"/>
            </g>
        </svg>

    )
}

export default IconAnalysis











{/* <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" fill="none"{...props} version="1.1" viewBox="0 0 286.439 286.439" >
<g  filter="url(#filter0_d_787_4028)">
    <path d="m45.422,135.326c-3.313,0-6,2.687-6,6v22.064c0,3.313 2.687,6 6,6s6-2.687 6-6v-22.064c0-3.314-2.687-6-6-6z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"  />
    <path d="m69.883,120.619c-3.313,0-6,2.687-6,6v36.771c0,3.313 2.687,6 6,6s6-2.687 6-6v-36.771c0-3.313-2.687-6-6-6z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"  />
    <path d="m94.344,98.817c-3.313,0-6,2.687-6,6v58.573c0,3.313 2.687,6 6,6s6-2.687 6-6v-58.573c0-3.314-2.687-6-6-6z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"  />
    <path d="m118.805,83.849c-3.313,0-6,2.687-6,6v73.542c0,3.313 2.687,6 6,6s6-2.687 6-6v-73.542c0-3.314-2.687-6-6-6z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"  />
    <path d="m281.728,196.948l-43.009-43.009c4.473-10.953 4.097-23.43-1.11-34.131v-47.09c0-8.808-7.166-15.974-15.974-15.974h-205.661c-8.808-0.001-15.974,7.165-15.974,15.973v114.683c0,8.808 7.166,15.974 15.974,15.974h65.068v14.322h-17.278c-3.313,0-6,2.686-6,6s2.687,6 6,6h110.081c3.313,0 6-2.686 6-6s-2.687-6-6-6h-17.278v-14.322h65.068c5.674,0 10.653-2.982 13.487-7.453l23.816,23.816c3.142,3.142 7.268,4.712 11.395,4.712s8.253-1.571 11.395-4.712h0.001c6.281-6.283 6.281-16.507-0.001-22.789zm-8.486,14.304c-1.604,1.604-4.214,1.604-5.819,0l-40.719-40.719 5.819-5.819 40.719,40.719c1.605,1.604 1.605,4.214 0,5.819zm-51.607-19.878h-205.661c-2.191,0-3.974-1.783-3.974-3.974v-114.683c0-2.191 1.782-3.974 3.974-3.974h205.661c2.191,0 3.974,1.783 3.974,3.974v32.357c-16.362-12.656-40.032-11.492-55.035,3.51-16.276,16.278-16.276,42.763 0,59.04 7.886,7.885 18.369,12.228 29.521,12.228 5.526,0 10.888-1.07 15.852-3.106l9.663,9.662v0.993c-0.002,2.19-1.784,3.973-3.975,3.973zm-128.593,26.322v-14.322h51.524v14.322h-51.524zm128.087-58.558c-5.619,5.619-13.09,8.713-21.035,8.713-7.946,0-15.416-3.094-21.035-8.713-11.598-11.598-11.598-30.47 0-42.069 5.8-5.799 13.417-8.699 21.035-8.699 7.617,0 15.235,2.9 21.034,8.699 11.599,11.598 11.599,30.47 0.001,42.069z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"  />
    <path d="m199.306,151.167c-7.204-0.435-12.711-6.648-12.276-13.852 0.199-3.308-2.32-6.151-5.628-6.351-3.309-0.197-6.151,2.32-6.351,5.628-0.833,13.809 9.724,25.72 23.532,26.553 0.123,0.007 0.245,0.011 0.367,0.011 3.148,0 5.791-2.454 5.983-5.639 0.2-3.307-2.32-6.15-5.627-6.35z" fill="currentcolor" stroke="currentcolor" strokeWidth="5" strokeMiterlimit="100"  />
</g>
</svg> */}




