import React, { useContext, useEffect, useState } from 'react'
import { Card } from '../../../../components/ui/Card'
import PriceDetailCard from '../../../../components/common/PriceDetailCard'
import { DatePicker } from '../../../../components/ui/datepicker'
import { Button } from '../../../../components/ui/button'
import { DataTableNew } from '../../../../shared/common/table-components/tasks/components/data-table'
import IconExport from '../../../../components/svg/IconExport'
import { useParams } from 'react-router-dom';
import { dateConvert } from '../../../../shared/common/dateFormate'
import { PricesContext } from '../details-page/PricesDetailsPage'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { exportFinancePrice, getFinancePrice } from '../../../../store/actions/PricesAction'
import moment from 'moment-js'
// import { FromDatePicker } from '../../../../components/ui/fromDatePicker'
// import { ToDatePicker } from '../../../../components/ui/toDatePicker'
import { ThreeDots } from 'react-loader-spinner'
import { FromDatePicker } from '../../../../components/ui/DatePickerNew'
import { DatePickerCustomInput } from '../../../../components/ui/DatePickerCustomInput'
const HistoricalTable = () => {
    const { setIsLoading } = useContext(PricesContext);
    const dispatch = useDispatch()
    const { financePrice, metaData } = useSelector(state => state)
    const [records, setRecords] = useState([]);
    const { slug } = useParams();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState({})


    // useEffect(() => {
    //     setIsLoading(true)
    //     if (financePrice.isCurrentPriceInUpdate) {
    //         const refreshTimeInMinutes = financePrice.refreshTime;
    //         const refreshTimeInSeconds = refreshTimeInMinutes * 1000;
    //         // Using setTimeout
    //         setTimeout(() => {
    //             dispatch(getFinancePrice(slug, currentPage, perPage, startDate !== null ? moment(startDate).format('YYYY-MM-DD') : '', endDate !== null ?  moment(endDate).format('YYYY-MM-DD') : '', ''));
    //             setIsLoading(false)
    //         }, refreshTimeInSeconds);

    //         // Cleanup the timeout when the component unmounts
    //         // return () => clearTimeout(timeoutId);
    //     } else {
    //         if (slug && !financePrice.isCurrentPriceInUpdate) {
    //             dispatch(getFinancePrice(slug, currentPage, perPage, startDate !== null ? moment(startDate).format('YYYY-MM-DD') : '', endDate !== null ?  moment(endDate).format('YYYY-MM-DD') : '', ''));
    //             setIsLoading(false)
    //         }
    //     }
    // }, [dispatch, slug, perPage, currentPage, financePrice.isCurrentPriceInUpdate]);

    // dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: perPage, totalItems: response?.data?.data?.totalRecords }))
    useEffect(() => {
        financePrice.isCurrentPriceInUpdate === true && onChange({ page: metaData.pageNumber, perPage: metaData.pageSize })
    }, [dispatch, slug, financePrice.isCurrentPriceInUpdate]);

    const onChange = (filter) => {
        setIsLoading(true)
        if (financePrice.isCurrentPriceInUpdate) {
            const refreshTimeInMinutes = financePrice.refreshTime;
            const refreshTimeInSeconds = refreshTimeInMinutes * 1000;
            // Using setTimeout
            setTimeout(() => {
                dispatch(getFinancePrice(slug, filter.page, filter.perPage, startDate !== null ? moment(startDate).format('YYYY-MM-DD') : '', endDate !== null ? moment(endDate).format('YYYY-MM-DD') : '', ''));
                setIsLoading(false)
            }, refreshTimeInSeconds);

            // Cleanup the timeout when the component unmounts
            // return () => clearTimeout(timeoutId);
        } else {
            if (slug && !financePrice.isCurrentPriceInUpdate) {
                dispatch(getFinancePrice(slug, filter.page, filter.perPage, startDate !== null ? moment(startDate).format('YYYY-MM-DD') : '', endDate !== null ? moment(endDate).format('YYYY-MM-DD') : '', ''));
                setIsLoading(false)
            }
        }
    }


    useEffect(() => {
        if (financePrice?.records) {
            setRecords(financePrice?.records);
        }
    }, [financePrice]);

    const handleDateRangeClick = () => {
        if (startDate !== null && endDate !== null) {
            dispatch(getFinancePrice(slug, metaData.pageNumber, metaData.pageSize, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), ''));
            setError({})
        } else {
            setError({ dataError: 'Please select date range!' })
        }
    }

    const handleExportClick = () => {
        if (startDate !== null && endDate !== null) {
            dispatch(exportFinancePrice(slug, metaData.pageNumber, metaData.pageSize, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), 'csv'));
            setError({})
        } else {
            setError({ dataError: 'Please select date range!' })
        }
    }

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        setError({})
        dispatch(getFinancePrice(slug, metaData.pageNumber, metaData.pageSize, '', '', ''));
    }


    const data = records && records.length ? records.map((data) => {
        return {
            id: data._id,
            date: data.date,
            open: data.open,
            high: data.high,
            low: data.low,
            close: data.close,
            adjClose: data.adjClose,
            volume: data.volume,
        }
    }) : []

    const columns = [
        {
            accessorKey: "date",
            header: "Date",
            cell: ({ row }) => (
                <div className="capitalize">{dateConvert(row.original.date)}</div>
            ),
        },
        {
            accessorKey: "open",
            header: "Open",
            cell: ({ row }) => (
                <div className="capitalize">{row.original.open}</div>
            ),
        },
        {
            accessorKey: "high",
            header: "High",
            cell: ({ row }) => (
                <div className="capitalize">{row.original.high}</div>
            ),
        },
        {
            accessorKey: "low",
            header: "Low",
            cell: ({ row }) => (
                <div className="capitalize">{row.original.low}</div>
            ),
        },
        {
            accessorKey: "close",
            header: "Close",
            cell: ({ row }) => (
                <div className="capitalize">{row.original.close}</div>
            ),
        },
        {
            accessorKey: "adjClose",
            header: "Adj Close",
            cell: ({ row }) => (
                <div className="capitalize">{row.original.adjClose === 'Fetching' ?
                    <ThreeDots
                        visible={true}
                        height="25"
                        width="25"
                        color="#001F3D"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                    : row.original.adjClose}</div>
            ),
        },
        {
            accessorKey: "Volume",
            header: "Volume",
            cell: ({ row }) => (
                <div className="capitalize">{row.original.volume}</div>
            ),
        },
    ]




    return (

        <section className='p-2 md:px-5'>
            <div className="grid  grid-cols-1 md:grid-cols-2 gap-6">
                <PriceDetailCard />
            </div>
            <Card className="bg-white p-3 md:p-5 ">
                <div className="card-head flex items-center gap-2 justify-between mb-4">
                    <h4 className='text-base font-600 text-textV2'>Historical Data</h4>
                    <p className="text-sm font-300 text-[#909090]">Frequency: <span className='text-textV2'>Daily</span> </p>
                </div>
                <div className="table-wrapper">
                    <div className="table-toolbar flex items-center justify-between gap-4 mb-6">
                        <div className="flex items-center gap-4">
                            <label htmlFor="" className='text-textV1 text-xs font-500'>
                                Time Period:
                            </label>
                            <div className="flex items-center gap-4 flex-wrap">
                                <div className="flex items-center gap-4">
                                    <label htmlFor="" className='text-textV1 text-xs font-500'>From</label>
                                    <DatePickerCustomInput onDateSelect={(date) => setStartDate(date)}
                                        placeholderText='YYYY-MM-DD'
                                        selectedFromDate={startDate}
                                        value={startDate}
                                    />
                                    {/* <FromDatePicker
                                        className="md:w-[180px]"
                                        onDateSelect={(date) => setStartDate(date)}
                                        selected={startDate}
                                        selectsStart
                                        dateFormat={'yyyy-MM-dd'}
                                        placeholderText='YYYY-MM-DD'
                                        startDate={startDate}
                                        endDate={endDate}
                                        isFrom={true}
                                    /> */}
                                </div>
                                <div className="flex items-center gap-4">
                                    <label htmlFor="" className='text-textV1 text-xs font-500'>To</label>
                                    <DatePickerCustomInput onDateSelect={(date) => setEndDate(date)}
                                        placeholderText='YYYY-MM-DD'
                                        minDate={startDate}
                                        selectedFromDate={startDate}
                                        value={endDate}
                                    />
                                    {/* <ToDatePicker
                                        className="md:w-[180px]"
                                        onDateSelect={(date) => setEndDate(date)}
                                        selected={endDate}
                                        selectsEnd
                                        dateFormat={'yyyy-MM-dd'}
                                        placeholderText='YYYY-MM-DD'
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                        isTo={true}
                                    /> */}

                                </div>
                                <Button size="sm" className="min-w-[98px] hover:bg-[#F5F5F5]" type="submit" variant="outline" disabled={startDate === null || endDate === null} onClick={handleReset}>
                                    Reset
                                </Button>
                                <Button size="sm" className="min-w-[98px] hover:bg-[#F5F5F5]" type="submit" variant="outline" disabled={startDate === null || endDate === null} onClick={() => handleDateRangeClick()}>
                                    Apply
                                </Button>
                            </div>
                        </div>
                        <Button size="sm" className="h-[34px]" disabled={startDate === null || endDate === null} onClick={() => handleExportClick()}>
                            <span><IconExport className="w-4 h-4" /></span>   Export
                        </Button>
                    </div>
                    <div className="table-wrapper">
                        <DataTableNew data={data} columns={columns} onChange={onChange} isSearchHide={true} isCollViewHide={true} filterName={'Name'} isRight={true} />
                    </div>
                </div>
            </Card>
        </section>
    )
}

export default HistoricalTable
