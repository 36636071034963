import { analyticsDataActionType, indicatorActionType, indicatorSourceActionType, indicatorSourceCategoryActionType, seriesActionType } from '../../constants';

const analyticsReducer = (state = {}, action) => {
    switch (action.type) {
        // indicator Source
        case indicatorSourceActionType.FETCH_INDICATOR_SOURCES:
            return { ...state, indicatorSource: action.payload };
        case indicatorSourceActionType.FETCH_INDICATOR_SOURCE:
            return { ...state, indicatorSource: action.payload };
        case indicatorSourceActionType.ADD_INDICATOR_SOURCE:
            return { ...state, indicatorSource: action.payload };
        case indicatorSourceActionType.UPDATE_INDICATOR_SOURCE:
            return { ...state, indicatorSource: action.payload };
        case indicatorSourceActionType.DELETE_INDICATOR_SOURCE:
            return { ...state, indicatorSource: action.payload };


        // indicator Source Category
        case indicatorSourceCategoryActionType.FETCH_INDICATOR_SOURCES_CATEGORIES:
            return { ...state, indicatorSourceCategory: action.payload };
        case indicatorSourceCategoryActionType.FETCH_INDICATOR_SOURCE_CATEGORIE:
            return { ...state, indicatorSourceCategory: action.payload };
        case indicatorSourceCategoryActionType.ADD_INDICATOR_SOURCE_CATEGORIE:
            return { ...state, indicatorSourceCategory: action.payload };
        case indicatorSourceCategoryActionType.UPDATE_INDICATOR_SOURCE_CATEGORIE:
            return { ...state, indicatorSourceCategory: action.payload };
        case indicatorSourceCategoryActionType.DELETE_INDICATOR_SOURCE_CATEGORIE:
            return { ...state, indicatorSourceCategory: action.payload };


        // indicator
        case indicatorActionType.FETCH_INDICATORS:
            return { ...state, indicator: action.payload };
        case indicatorActionType.FETCH_INDICATOR:
            return { ...state, indicator: action.payload };
        case indicatorActionType.ADD_INDICATOR:
            return { ...state, indicator: action.payload };
        case indicatorActionType.UPDATE_INDICATOR:
            return { ...state, indicator: action.payload };
        case indicatorActionType.DELETE_INDICATOR:
            return { ...state, indicator: action.payload };

        // goals
        case indicatorSourceCategoryActionType.FETCH_INDICATOR_GOALS:
            return { ...state, goals: action.payload };

        // Target
        case indicatorSourceCategoryActionType.FETCH_INDICATOR_TARGET:
            return { ...state, targets: action.payload };

        // Target indicator
        case indicatorSourceCategoryActionType.FETCH_TARGET_INDICATORS:
            return { ...state, targetIndicators: action.payload };




        // indicator
        case analyticsDataActionType.FETCH_ANALYTICS_DATAS:
            return { ...state, analyticsData: action.payload };


        // series
        case seriesActionType.FETCH_SERIES:
            return { ...state, series: action.payload };

        default:
            return state;
    }
};
export default analyticsReducer