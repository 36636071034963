import { byodActionType } from '../../constants';

const BYODReducer = (state = [], action) => {
    switch (action.type) {
        case byodActionType.FETCH_BYOD_V2:
            return action.payload;
        case byodActionType.ADD_BYOD_V2:
            return action.payload;
        default:
            return state;
    }
};
export default BYODReducer