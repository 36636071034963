
import { apiBaseURL, adminSubClassActionType, toastifyType } from "../../../constants";
import { setLoading } from "../loadingAction";
import apiConfig from '../../../config/apiConfig';
import { addTost } from "../toastAction";
import { metaDataAction } from "../metaDataAction";

export const fetchAdminSubClasses = (filter) => async (dispatch) => {
  

  try {
    dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.ADMIN_SUB_CLASS  + `?page=${filter?.page}&perPage=${filter?.perPage}`);
    dispatch({ type: adminSubClassActionType.FETCH_SUB_CLASSES, payload: response.data?.data });
    dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: filter.perPage, totalItems: response.data?.data?.totalRecords }))
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const addAdminSubClass = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.ADMIN_SUB_CLASS, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchAdminSubClasses({page:1,perPage:10}));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const deleteAdminSubClass = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.delete(apiBaseURL.ADMIN_SUB_CLASS + "/" + id);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchAdminSubClasses({page:1,perPage:10}));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const fetchAdminSubClass = (id) => async (dispatch) => {
  try {
    // dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.ADMIN_CLASS + "/" + id + '/sub-class');
    dispatch({ type: adminSubClassActionType.FETCH_SUB_CLASS, payload: response.data?.data });
    // dispatch(fetchSubCategories())
    // dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const updateAdminSubClass = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.put(apiBaseURL.ADMIN_SUB_CLASS + '/' + id, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchAdminSubClasses({page:1,perPage:10}));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};