import { tenorActionType } from '../../constants';

const tenorReducer = (state = {}, action) => {
    switch (action.type) {
        case tenorActionType.FETCH_TENOR:
            return action.payload;
        default:
            return state;
    }
};
export default tenorReducer