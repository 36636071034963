import React, { useEffect, useRef, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { ScatterChart } from 'echarts/charts';
import { LineChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers';
import Card from '@/components/common/Card';
import moment from 'moment-js';
import Loading from '../../../../shared/common/Loading';
import * as htmlToImage from 'html-to-image';
import { Button } from '../../../../components/ui/button';
import { DownloadIcon } from 'lucide-react'
// Import the theme JSON
import themeJson from './purple-passion.json';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, ScatterChart, LineChart, ToolboxComponent, SVGRenderer]);
// Apply the theme
echarts.registerTheme('purple-passion', themeJson.theme);
const ScatterChartData = (props) => {
    const { data, analysisType, name, getKeyName, isChecked } = props;
    const [isLoading, setIsLoading] = useState(false)
    // {
    //     "Date": "2023-02-03T00:00:00",
    //     "outlier_Key": 5.5956233806,
    //     "Outlier_Score": "0.02904302790000024",
    //     "Outlier_Classification": "1"
    // }
    // const timeSeriesData = data.map(item => ({
    //     value: [moment(item.Date).format('YYYY-MM-DD'), item.Outlier_Score],
    //     symbolSize: item.Outlier_Classification === '1' ? 10 : 8,
    //     itemStyle: {
    //         color: item.Outlier_Classification === '1' ? 'red' : 'green',
    //         borderColor: '#555'
    //     },
    //     symbol: 'circle',
    //     smooth: true,
    //     lineStyle: {
    //         width: 3,
    //         shadowColor: 'rgba(0,0,0,0.3)',
    //         shadowBlur: 10,
    //         shadowOffsetY: 8
    //     },
    // }));

    const [scatterChartOp, setScatterChartOp] = useState({})
    const [timeSeriesDataOp, setTimeSeriesDataOp] = useState({})
    let colorCode = ['red', 'green'];

    useEffect(() => {
        if (data?.length >= 1) {
            const getFirstObj = data.find((item, index) => index === 0);
            colorCode = getFirstObj.Outlier_Classification === '0' ? ['green', 'red'] : ['red', 'green'];
        }
    }, [data])

    useEffect(() => {
        setIsLoading(true)
        const option = {
            title: {
                text: 'Scatter Chart:',
                // text: 'Scatter Chart:' + ' ' + name,
                textStyle: {
                    fontSize: '0.875rem', // Change the font size as needed
                    fontWeight: 600,
                    fontFamily: 'Inter'

                }
            },
            tooltip: {
                trigger: 'item',
                axis: 'auto',
                axisPointer: { type: 'shadow' },
                displayMode: 'single',
                renderMode: 'auto',
                // Confining tooltip to the body
                confine: true,
                align: 'center',
                backgroundColor: '#001F3D',
                appendToBody: true,
                textStyle: {
                    fontSize: 12, // Adjust the font size as needed
                    color: '#FFFFFF',
                }
            },
            legend: {
                top: 0, // Position the legend at the top
                show: true, // Show
                formatter: function (name) {
                    const parts = name.split(' ');
                    const classification = parts.length >= 3 ? parts[2] : ''; // Extracting classification from legend name
                    const dotType = classification === '1' ? 'Outliers' : 'inliers'; // Setting dot color based on classification
                    return `${dotType}`; // Adding colored symbol before the legend label
                }
            },
            xAxis: {
                type: 'category',
                axisTick: {
                    alignWithLabel: true
                },
                splitLine: {
                    show: false,
                },
            },
            color: isChecked ? ['red'] : colorCode,
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            yAxis: {
                type: 'value',
            },
            // Extracting data for scatter chart
            series: data.map(item => {
                return ({
                    type: 'scatter',
                    name: 'Outlier Classification' + ' ' + item.Outlier_Classification,
                    data: [{
                        value: [item[getKeyName], item.Outlier_Score],
                        symbolSize: item.Outlier_Classification === '1' ? 10 : 8,
                        itemStyle: {
                            color: item.Outlier_Classification === '1' ? 'red' : 'green',
                            borderColor: '#555'
                        },
                    },
                    ],
                })
            }),
            theme: themeJson.theme
        };

        const timeSeriesOption = {
            title: {
                text: 'Time Series Chart:',
                // text: 'Time Series Chart:' + ' ' + name,
                textStyle: {
                    fontSize: '0.875rem', // Change the font size as needed
                    fontWeight: 600,
                    fontFamily: 'Inter'
                }
            },
            color: isChecked ? ['red'] : colorCode,
            tooltip: {
                trigger: 'axis',
                axis: 'auto',
                axisPointer: { type: 'shadow' },
                displayMode: 'single',
                renderMode: 'auto',
                // Confining tooltip to the body
                confine: true,
                align: 'center',
                backgroundColor: '#001F3D',
                appendToBody: true,
                textStyle: {
                    fontSize: 12, // Adjust the font size as needed
                    color: '#FFFFFF',
                }
            },
            legend: {
                top: 0, // Position the legend at the top
                show: true, // Show
                formatter: function (name) {
                    const parts = name.split(' ');
                    const classification = parts.length >= 3 ? parts[2] : ''; // Extracting classification from legend name
                    const dotType = classification === '1' ? 'Outliers' : 'inliers'; // Setting dot color based on classification
                    return `${dotType}`; // Adding colored symbol before the legend label
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'time',
                axisTick: {
                    alignWithLabel: true
                },
                splitLine: {
                    show: false,
                },
            },
            yAxis: {
                type: 'value',
            },
            // Extracting data for time series chart
            series: data.map(item => ({
                type: 'scatter',
                name: 'Outlier Classification' + ' ' + item.Outlier_Classification,
                data: [{
                    value: [moment(item.Date).format('YYYY-MM-DD'), item[getKeyName]],
                    symbolSize: item.Outlier_Classification === '1' ? 10 : 8,
                    itemStyle: {
                        color: item.Outlier_Classification === '1' ? 'red' : 'green',
                        borderColor: '#555'
                    },
                    symbol: 'circle',
                    smooth: true,
                    lineStyle: {
                        width: 3,
                        shadowColor: 'rgba(0,0,0,0.3)',
                        shadowBlur: 10,
                        shadowOffsetY: 8
                    },
                }]
            })),
            theme: themeJson.theme
        };

        setTimeout(() => {
            setScatterChartOp(option)
            setTimeSeriesDataOp(timeSeriesOption)
            setIsLoading(false)
        }, 200);
    }, [data])

    function generateSixDigitNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }
    const downloadChartAsJPEG = (chartId) => {
        const nodes = document.getElementsByClassName(chartId);
        if (!nodes || nodes.length === 0) {
            console.error('Chart node not found with class name:', chartId);
            return;
        }
        const node = nodes[0]; // Access the first element
        htmlToImage.toJpeg(node, {
            backgroundColor: '#FFFFFF'
        })
            .then(function (dataUrl) {
                // download(dataUrl, 'chart.jpeg');
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');

                    // Set canvas dimensions based on the image size
                    canvas.width = image.width;
                    canvas.height = image.height;

                    // Fill the canvas with a white background
                    context.fillStyle = 'white';
                    context.fillRect(0, 0, canvas.width, canvas.height);

                    // Draw the image on the canvas
                    context.drawImage(image, 0, 0);

                    // Convert canvas to JPEG data URL
                    const newImageDataUrl = canvas.toDataURL('image/jpeg');

                    // Trigger download
                    const link = document.createElement('a');
                    link.href = newImageDataUrl;
                    const randomNumber = generateSixDigitNumber();
                    link.download = `${randomNumber}-chart.jpeg`;
                    link.click();
                };

                // Set the source of the image to the data URL
                image.src = dataUrl;
            })
            .catch(function (error) {
                console.error('Error generating chart image:', error);
            });
    };

    return (
        <>
            {data?.length && analysisType?.scatterChart ? <Card className="bg-white p-3 md:p-5 my-3">
                <div className='flex justify-end'>
                    <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => downloadChartAsJPEG('echarts-chart1')}>
                        <span><DownloadIcon className='w-4 h-4' /></span>
                    </Button>
                </div>
                <div className="table-wrapper">
                    {isLoading ? <Loading /> : <ReactEChartsCore
                        key="scatterChart" // Add key prop here
                        className={"echarts-chart1"}
                        echarts={echarts}
                        style={{ width: '100%', height: '500px' }}
                        option={scatterChartOp}
                    />}
                </div>
            </Card> : ''}
            {data?.length && analysisType?.timeSeriesChart ? <Card className="bg-white p-3 md:p-5 ">
                <div className='flex justify-end'>
                    <Button variant="outline" size="sm" className="w-8 h-8 p-0 hover:bg-[#F5F5F5]" onClick={() => downloadChartAsJPEG('echarts-chart2')}>
                        <span><DownloadIcon className='w-4 h-4' /></span>
                    </Button>
                </div>
                <div className="table-wrapper">
                    {isLoading ? <Loading /> : <ReactEChartsCore
                        key="timeSeriesChart" // Add key prop here
                        className={"echarts-chart2"}
                        echarts={echarts}
                        style={{ width: '100%', height: '500px' }}
                        option={timeSeriesDataOp}
                    />}
                </div>
            </Card> : ''}
        </>
    );
}

export default ScatterChartData;
// top: 0, // Position the legend at the top
// formatter: function (name) {
//     const parts = name.split(' ');
//     const classification = parts.length >= 3 ? parts[2] : ''; // Extracting classification from legend name
//     const dotType = classification === '1' ? 'Outliers' : 'inliers'; // Setting dot color based on classification
//     return `${dotType}`; // Adding colored symbol before the legend label
// },