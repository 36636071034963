import { portfoliosGroupActionType } from "../../constants";


const PortfolioGroupReducer = (state = [], action) => {
    switch (action.type) {
        case portfoliosGroupActionType.FETCH_PORTFOLIOS_GROUPS:
            return action.payload;
        case portfoliosGroupActionType.ADD_PORTFOLIO_GROUP:
            return action.payload;
        case portfoliosGroupActionType.UPDATE_PORTFOLIO_GROUP:
            return action.payload;
        case portfoliosGroupActionType.DELETE_PORTFOLIO_GROUP:
            return action.payload;
        default:
            return state;
    }
};
export default PortfolioGroupReducer