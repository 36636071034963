import { customFieldsDataType } from '../../constants';

const updateCustomFieldDataReducer = (state = [], action) => {
    switch (action.type) {
        case customFieldsDataType.FETCH_CUSTOM_DATA:
            return action.payload;
        default:
            return state;
    }
};
export default updateCustomFieldDataReducer