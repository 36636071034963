import { setLoading } from "./loadingAction";
import apiConfig from "../../config/apiConfig"
import { Filters, apiBaseURL, portfoliosActionType, toastifyType } from "../../constants";
import { metaDataAction } from "./metaDataAction";
import { addTost } from "./toastAction";
import { fetchPortfolioGroup } from "./portfolioGroupAction";
import { setUpdateSate } from "./updateStateAction";


export const fetchPortfolios = (page, perPage) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '/all?page=' + page + "&perPage=" + perPage);
    dispatch({ type: portfoliosActionType.FETCH_PORTFOLIOS, payload: response.data?.data?.data });
    dispatch(metaDataAction({ pageNumber: response.data?.data?.pageNumber, pageSize: response.data?.data?.pageSize, totalItems: response.data?.data?.totalItems }))
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const fetchAllPortfolios = (page) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.PORTFOLIOS);
    dispatch({ type: portfoliosActionType.FETCH_PORTFOLIOS_ALL, payload: response.data?.data });
    // dispatch(metaDataAction({ pageNumber: response.data?.data?.pageNumber, pageSize: response.data?.data?.pageSize, totalItems: response.data?.data?.totalItems }))
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const fetchAllPortfolios2 = (setTotalRecords, setTotalPages,  currentPage, perPage) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '?page=' + currentPage + "&perPage=" + perPage);
    dispatch({ type: portfoliosActionType.FETCH_PORTFOLIOS_ALL, payload: response.data?.data });
    setTotalRecords(response.data?.data?.totalRecords || response.data.totalRecords)
    setTotalPages(Math.ceil((response.data?.data?.totalItems || response.data?.data?.totalRecords) / (perPage || 10)))
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const fetchPortfoliosAll = (page) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.PORTFOLIOS + '/all');
    dispatch({ type: portfoliosActionType.FETCH_PORTFOLIOS, payload: response.data?.data?.data });
    dispatch(metaDataAction({ pageNumber: response.data?.data?.pageNumber, pageSize: response.data?.data?.pageSize, totalItems: response.data?.data?.totalItems }))
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const addPortfolio = (data, perPage, slug) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.PORTFOLIOS, data);
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    !slug && dispatch(fetchPortfolios(1, perPage));
    slug && dispatch(fetchPortfolioGroup(slug))
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const deletePortfolio = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.delete(apiBaseURL.PORTFOLIOS + '/' + id);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchPortfolios(1));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const updatePortfolio = (id, data, perPage, slug) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.put(apiBaseURL.PORTFOLIOS + '/' + id, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    !slug && dispatch(fetchPortfolios(Filters.OBJ.page, Filters.OBJ.perPage));
    slug && dispatch(fetchPortfolioGroup(slug, Filters.OBJ))
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const updatePortfolioFromGroup = (id, data, currentPage, slug) => async (dispatch) => {
  try {
    dispatch(setUpdateSate(true))
    dispatch(setLoading(true));
    const response = await apiConfig.put(apiBaseURL.PORTFOLIOS + '/' + id, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    !slug && dispatch(fetchPortfolios(1));
    slug && dispatch(fetchPortfolioGroup(slug))
    dispatch(setUpdateSate(false))
  } catch (error) {
    dispatch(setUpdateSate(false))
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};