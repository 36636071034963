import React, { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
// import MultipleViewListModal from './model/MultipleViewListModal';
import { Button } from "@/components/ui/button";
import IconSetting from '../../../components/svg/IconSetting';
import { fetchColumnsViews } from '../../../store/actions/ColumnsViewsActine';
import { fetchCustomColumnsViews } from '../../../store/actions/CustomColumnsViewsAction';
import { fetchAllocationColumn, fetchPortfolioColumn, fetchPortfolioGroupAllocationColumn, fetchPortfolioGroupInvestedAssetColumn } from '../../../store/actions/portfolioscolumnAction';
import MultipleViewListModal from './model/MultipleViewListModal';
import { Settings } from 'lucide-react';
export const SettingBtnContextNew = createContext(null)
const MultipleViewSetting = (props) => {
    const dispatch = useDispatch()
    const { sectionType, isGroup, isAsset, isAssetMonitor, isGroupAssetMonitor } = props
    const [showViewModal, setShowViewModal] = useState(false);
    const [showViewDetailModal, setShowViewDetailModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0)
    const [perPage, setPerPage] = useState(10); // Default per page value
    const [totalPage, setTotalPage] = useState(10); // Default per page value
    const [searchValue, setSearchValue] = useState('')

    const [currentPageTwo, setCurrentPageTwo] = useState(1);
    const [totalRecordsTow, setTotalRecordsTwo] = useState(0)
    const [perPageTwo, setPerPageTwo] = useState(5); // Default per page value
    const [totalPageTwo, setTotalPageTow] = useState(10); // Default per page value

    const [isAdd, setIsAdd] = useState(false)
    const [state, setState] = useState({
        primary: [],
        activeFilled: [],
        activeName: ''
    });

    useEffect(() => {
        // isAsset && dispatch(fetchPortfolioColumn('investedAsset'));
        isAssetMonitor === 'allocationMonitor' && dispatch(fetchAllocationColumn('allocationMonitor'));
        isGroup && dispatch(fetchPortfolioGroupInvestedAssetColumn('portfolioGroupInvestedAsset'))
        isAssetMonitor === 'portfolioGroupAllocationMonitor' && dispatch(fetchPortfolioGroupAllocationColumn('portfolioGroupAllocationMonitor'))
    }, [])

    const handleOpenModal = () => {
        setShowViewModal(true);
        // dispatch(fetchColumnsViews(sectionType))
        // dispatch(fetchCustomColumnsViews(sectionType, currentPage, perPage, searchValue, setTotalRecords))
    };

    useEffect(() => {
        if (showViewModal) {
            dispatch(fetchColumnsViews(sectionType, currentPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow))
        }
    }, [showViewModal, currentPageTwo, perPageTwo])

    useEffect(() => {
        if (showViewModal) {
            const getObjId = state.activeFilled.map((item) => item._id)
            dispatch(fetchCustomColumnsViews(sectionType, currentPage, perPage, searchValue, setTotalRecords, setTotalPage, getObjId))
        } else {
            setCurrentPageTwo(1)
            setTotalRecordsTwo(0)
            setTotalPageTow(10)
            setTotalPage(10)
            setPerPage(10)
            setTotalRecords(0)
            setCurrentPage(1)
        }
    }, [showViewModal, currentPage])

    const handleCloseModal = () => {
        setShowViewModal(false);
        setShowViewDetailModal(false);
        isAsset && dispatch(fetchPortfolioColumn('investedAsset'));
        isAssetMonitor === 'allocationMonitor' && dispatch(fetchAllocationColumn('allocationMonitor'));
        isGroup && dispatch(fetchPortfolioGroupInvestedAssetColumn('portfolioGroupInvestedAsset'))
        isAssetMonitor === 'portfolioGroupAllocationMonitor' && dispatch(fetchPortfolioGroupAllocationColumn('portfolioGroupAllocationMonitor'))
    };
    const handleOpenDetailModal = () => {
        setShowViewDetailModal(true);
        setShowViewModal(false);
    };
    const handleCloseDetailModal = () => {
        setShowViewDetailModal(false);
        setShowViewModal(true);
    };

    const handleSaveModal = () => {
        // console.log('%c Save :> ! ', 'background: #222; color: #bada55; font-size: 25px');
    }
    return (
        <>
            <Button variant="outline" size="sm" className="h-8 hover:bg-[#F5F5F5]"  onClick={() => {
                // setSettingModalOpen(true); 
                handleOpenModal()
            }}>
                <span><Settings strokeWidth={"2.2"} className="w-4 h-4" /></span>  Setting
            </Button>
            {/* <button type="button" className="btn btn-info shadow-sm ms-0 m-2 setting_btn" onClick={handleOpenModal}><span className='px-1'><FontAwesomeIcon icon={faGear} /></span>Setting</button> */}
            <SettingBtnContextNew.Provider value={{ state, setState, setPerPageTwo, sectionType, showViewModal, totalPageTwo, currentPageTwo, setCurrentPageTwo, totalRecordsTow, perPageTwo, handleCloseModal, handleOpenDetailModal, handleSaveModal, showViewDetailModal, setTotalPage, handleCloseDetailModal, setShowViewModal, currentPage, setCurrentPage, totalRecords, setTotalRecords, perPage, setPerPage, searchValue, setSearchValue, totalPage, setTotalRecordsTwo, setTotalPageTow, setIsAdd }}>
                <MultipleViewListModal />
            </SettingBtnContextNew.Provider>

        </>
    )
}

export default MultipleViewSetting