import React, { useContext, useEffect, useState } from 'react'
import { AssentContext } from '../Asset';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { fetchAuthorize2 } from '../../../../store/actions/AssetAction';
import { dateConvert } from '../../../../shared/common/dateFormate';
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header';
import { DataTableWithCustomPagination } from '../../../../shared/common/table-components/tasks/components/data-table-without-pagination';
import { Filters } from '../../../../constants';

const AssetsAuthoriseContent = () => {
    const { updateId, fetchAssetData } = useContext(AssentContext);
    const {  assetsAuthorize } = useSelector(state => state)
    const [fetchAnalytics, setFetchAnalytics] = useState([])
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false)
    
    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)

    useEffect(() => {
        if(!_.isEmpty(fetchAssetData)){
            dispatch(fetchAuthorize2(fetchAssetData._id, setIsLoading, currentPage, perPage, setTotalRecords, setTotalPages))
        } else {
            setFetchAnalytics([])
        }
    }, [fetchAssetData, currentPage, perPage])


    // useEffect(() => {
    //     setTotalRecords(metaData.totalItems)
    //     setTotalPages(Math.ceil(metaData.totalItems / metaData.pageSize))
    //   }, [metaData]);


    useEffect(() => {
        if(!_.isEmpty(fetchAssetData)){
            setFetchAnalytics(assetsAuthorize?.records ?? [])
        } else {
            setFetchAnalytics([])
        }
        
        // dispatch(fetchAssetsDetails())
    }, [assetsAuthorize])

    useEffect(() => {
        if (updateId !== null) {
            setIsLoading(true)
        }
    }, [updateId])
 

    // const handleSort = (column) => {
    //     if (column === sortColumn) {
    //         // Toggle sort order if the same column is clicked
    //         setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    //     } else {
    //         // Sort by the clicked column in ascending order by default
    //         setSortColumn(column);
    //         setSortOrder('asc');
    //     }
    // };





    // // Sorting logic
    // const sortedItems = Array.isArray(fetchAnalytics) ? [...fetchAnalytics].sort((a, b) => {
    //     const columnA = (a[sortColumn] || '').toLowerCase();
    //     const columnB = (b[sortColumn] || '').toLowerCase();

    //     // Check if columnA and columnB are not undefined
    //     if (columnA && columnB) {
    //         return sortOrder === 'asc' ? columnA.localeCompare(columnB) : columnB.localeCompare(columnA);
    //     } else {
    //         // Handle the case where either columnA or columnB is undefined
    //         return 0; // No change in order
    //     }
    // }) : [];

    // Pagination functions
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;


    const data =  Array.isArray(fetchAnalytics) ? fetchAnalytics.map((item, index) => {
        return {id:indexOfFirstItem + index + 1,  ...item}
    }) : []

    const columns = [
        {
            accessorKey: "Id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.id}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.name ? row?.original?.name : '-'}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Created Date",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Created Date" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.createdAt ? dateConvert(row?.original?.createdAt) : '-'}</span>
            },
            enableSorting: true,
        },
    ]
   
  return (
    <DataTableWithCustomPagination data={data} columns={columns} filterName={'Name'} loadData={isLoading}
                setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}
            />
  )
}

export default AssetsAuthoriseContent