import React from 'react'

const IconExchange = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_2006_461)">
<path d="M38.5304 13.1138L29.7258 2.57874C29.5724 2.39526 29.3904 2.24971 29.1901 2.15041C28.9896 2.05111 28.7749 2 28.5581 2C28.3412 2 28.1265 2.05111 27.9261 2.15041C27.7258 2.24971 27.5437 2.39526 27.3904 2.57874C27.237 2.76223 27.1154 2.98006 27.0324 3.21979C26.9495 3.45952 26.9067 3.71647 26.9067 3.97596C26.9067 4.23544 26.9495 4.49239 27.0324 4.73212C27.1154 4.97185 27.237 5.18969 27.3904 5.37317L33.3775 12.537H2.14548C1.70765 12.537 1.28775 12.7451 0.978153 13.1156C0.668558 13.486 0.494629 13.9884 0.494629 14.5123C0.494629 15.0362 0.668558 15.5386 0.978153 15.9091C1.28775 16.2795 1.70765 16.4876 2.14548 16.4876H37.3637C37.6901 16.4873 38.0089 16.3713 38.2801 16.1542C38.5514 15.9371 38.7628 15.6287 38.8875 15.268C39.0124 14.9073 39.045 14.5104 38.9815 14.1274C38.9179 13.7445 38.7609 13.39 38.5304 13.1138Z" fill="currentcolor"/>
<path d="M37.3637 23.0692H2.1455C1.8192 23.0696 1.5003 23.1856 1.22909 23.4027C0.957877 23.6198 0.746507 23.9281 0.621677 24.2889C0.496848 24.6496 0.464157 25.0465 0.527735 25.4295C0.591314 25.8124 0.74831 26.1643 0.978894 26.4404L9.78346 36.9755C9.93643 37.1592 10.1182 37.3052 10.3184 37.4046C10.5186 37.5041 10.7333 37.5554 10.9501 37.5554C11.1668 37.5554 11.3815 37.5041 11.5817 37.4046C11.7819 37.3052 11.9637 37.1592 12.1167 36.9755C12.2701 36.7921 12.3918 36.5742 12.4748 36.3345C12.5578 36.0948 12.6006 35.8378 12.6006 35.5784C12.6006 35.3188 12.5578 35.0618 12.4748 34.8221C12.3918 34.5824 12.2701 34.3646 12.1167 34.181L6.12956 27.0173H37.3615C37.7994 27.0173 38.2193 26.8092 38.5289 26.4386C38.8385 26.0683 39.0124 25.5658 39.0124 25.0419C39.0124 24.5181 38.8385 24.0155 38.5289 23.6452C38.2193 23.2747 37.7994 23.0667 37.3615 23.0667L37.3637 23.0692Z" fill="currentcolor"/>
</g>
<defs>
<clipPath id="clip0_2006_461">
<rect width="40" height="35.5556" fill="white" transform="translate(0 2)"/>
</clipPath>
</defs>
</svg>
  )
}

export default IconExchange