

import { setLoading } from "../loadingAction";
import apiConfig from '../../../config/apiConfig';
import { addTost } from "../toastAction";
import { adminClassActionType, apiBaseURL, toastifyType, } from "../../../constants";
import { metaDataAction } from "../metaDataAction";


export const fetchAdminClasses = (filter) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const response = await apiConfig.get(apiBaseURL.ADMIN_CLASS + `?page=${filter.page}&perPage=${filter.perPage}`)
    dispatch({ type: adminClassActionType.FETCH_CLASSES, payload: response.data?.data });
    dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: filter.perPage, totalItems: response.data?.data?.totalRecords }))
    dispatch(setLoading(false))
  } catch (error) {
    dispatch(setLoading(false))
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
  }
}


export const addAdminClass = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.ADMIN_CLASS, data);
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchAdminClasses({page:1,perPage:10}));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const deleteAdminClass = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.delete(apiBaseURL.ADMIN_CLASS + "/" + id);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchAdminClasses({page:1,perPage:10}));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const updateAdminClass = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.put(apiBaseURL.ADMIN_CLASS + '/' + id, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchAdminClasses({page:1,perPage:10}));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};