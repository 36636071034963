import { assetsDetailsV2ActionType } from '../../constants';

const assetByIssuerReducer = (state = [], action) => {
    switch (action.type) {
        case assetsDetailsV2ActionType.FETCH_ASSETS_BY_ISSUER_V2:
            return action.payload;
    
        default:
            return state;
    }
};
export default assetByIssuerReducer