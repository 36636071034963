
import { apiBaseURL, researchNoteContentTagType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { addTost } from "./toastAction";


export const getAllReSearchNotesTag = ({ name }) => async (dispatch) => {
    try {
        // dispatch(setLoading(true));
        const response = await apiConfig.get(apiBaseURL.RESEARCH_NOTES + "-content/tag?name=" + name)
        dispatch({
            type: researchNoteContentTagType.FETCH_RESEARCH_NOTE_TAGS,
            payload: response.data?.data,
        });
        dispatch(setLoading(false));
        // return response?.data
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const addReSearchNotesTag = ({ name }) => async (dispatch) => {
    try {
        // dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.RESEARCH_NOTES + "-content/tag", {name: name})
        // dispatch({
        //     type: researchNoteContentTagType.ADD_RESEARCH_NOTE_TAGS,
        //     payload: response.data?.data,
        // });
        dispatch(setLoading(false));
        return response.data?.data;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const removeReSearchNotesTag = (id) => async (dispatch) => {
    try {
        // dispatch(setLoading(true));
        const response = await apiConfig.delete(apiBaseURL.RESEARCH_NOTES + "-content/tag/" + id)
        // dispatch({
        //     type: researchNoteContentTagType.ADD_RESEARCH_NOTE_TAGS,
        //     payload: response.data?.data,
        // });
        dispatch(setLoading(false));
        return response.data?.data;
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};