
import { apiBaseURL, adminSubTypeActionType, toastifyType } from "../../../constants";
import { setLoading } from "../loadingAction";
import apiConfig from '../../../config/apiConfig';
import { addTost } from"../toastAction";
import { metaDataAction } from "../metaDataAction";


export const fetchAdminSubTypes = (filter) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.get(apiBaseURL.ADMIN_SUB_TYPE + `?page=${filter.page}&perPage=${filter.perPage}`);
        dispatch({ type: adminSubTypeActionType.FETCH_SUBTYPES, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: filter.perPage, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const addAdminSubTypes = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.ADMIN_SUB_TYPE, data);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchAdminSubTypes({page:1,perPage:10}));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const deleteAdminSubTypes = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.delete(apiBaseURL.ADMIN_SUB_TYPE + '/' + id);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchAdminSubTypes({page:1,perPage:10}));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const fetchAdminSubType = (id) => async (dispatch) => {
    try {
        // dispatch(setLoading(true));
        const response = await apiConfig.get(apiBaseURL.ADMIN_TYPE + "/" + id + "/" + "sub-types");
        dispatch({ type: adminSubTypeActionType.FETCH_SUBTYPE, payload: response.data?.data });
        // dispatch(fetchSubCategories())
        // dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const updateAdminSubTypes = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.put(apiBaseURL.ADMIN_SUB_TYPE, data);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchAdminSubTypes({page:1,perPage:10}));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};