import React, { useState } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog'
import { Button } from '../ui/button'
import SettingTable from '../common/SettingTable'
import IconExchange from '../svg/IconExchange'
import { Input } from '../ui/input'
import { Search } from 'lucide-react'
import FieldItem from '../common/FieldItem'
// import { useDrop } from 'react-dnd'

const SettingModal = ({ settingModalOpen, setSettingModalOpen }) => {
    const [fields, setFields] = useState([
        { value: "Id" },
        { value: "Name" },
        { value: "Class" },
        { value: "SubClass" },
    ])
   
    return (
        <Dialog open={settingModalOpen} onOpenChange={() => setSettingModalOpen(false)}>
            <DialogContent className="sm:max-w-[1200px]  w-[95%] gap-6">
                <DialogHeader>
                    <DialogTitle className="text-start">Setting</DialogTitle>
                </DialogHeader>
                <div className="dialog-body">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
                        <div className="add-view-content">
                            <div className="header"></div>
                            <div className="">
                                <SettingTable />
                            </div>
                        </div>
                        <div className="select-fields-drag-and-drop h-full">
                            <div className="flex items-center h-full gap-2 justify-between">
                                <div className="filds-card h-[320px] overflow-hidden w-[calc(47.5%-6px)] border border-borderV1">
                                    <div className="card-head py-1.5 h-[30px] flex items-center justify-center border-b border-borderV1 px-3">
                                        <h6 className='text-center text-xs text-textV1'>Fields</h6>
                                    </div>
                                    <div className="border-b border-borderV1 flex items-center justify-center  px-3 h-[30px]">
                                        <div className="relative w-full">
                                            <span className='absolute top-1/2 start-0 -translate-y-1/2'><Search className='w-4 h-4 text-textV1' /></span>
                                            <Input className="px-3 !ps-6 border-none h-full py-0 text-xs" placeholder="Search Fields" />
                                        </div>
                                    </div>
                                    <div className="card-body h-[calc(100%-60px)] overflow-hidden">
                                        <ul className='h-full scroll-sm overflow-y-auto'>
                                            {
                                                fields.map((obj,index) => (
                                                    <FieldItem obj={obj} key={index}/>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <span className='w-[calc(5%-6px)] flex items-center justify-center'>
                                    <IconExchange className="w-6 h-6 text-textV2" />
                                </span>
                                <div className="filds-card h-[320px] overflow-hidden border  w-[calc(47.5%-6px)] border-borderV1">
                                    <div className="card-head py-1.5 border-b h-[30px] flex items-center justify-center px-3 border-borderV1">
                                        <h6 className='text-center text-xs text-textV1'>View Fields</h6>
                                    </div>
                                    <div className="card-body h-[calc(100%-30px)] overflow-hidden">
                                        <ul className='h-full scroll-sm overflow-y-auto'>
                                            <li className='h-[30px] px-3 flex items-center cursor-grab hover:bg-theme hover:border-theme group justify-start border-b border-borderV1'>
                                                <span className='text-xs text-textV1 group-hover:text-white'>Id</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DialogFooter className={"!justify-end !gap-2"}>
                    <Button type="submit" className="min-w-[100px]">Save</Button>
                    <Button type="submit" className="min-w-[100px]" onClick={() => setSettingModalOpen(false)} variant={"outline"}>Close</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

export default SettingModal