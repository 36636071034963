import React from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import Card from '../../../components/common/Card'
import AllocationInitialTable from '../../../components/common/AllocationInitialTable'

const Allocation = () => {
  return (
    <DashboardMaster>
      <section className='p-2 md:p-5'>
           <Card>
               <div className="">
                     <AllocationInitialTable/>
               </div>
           </Card>
      </section>
    </DashboardMaster>
  )
}

export default Allocation