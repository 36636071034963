import React from 'react'

const IconAdd = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2004_3459)">
    <path d="M20 0C8.97133 0 0 8.97133 0 20C0 31.0287 8.97133 40 20 40C31.0287 40 40 31.0271 40 20C40 8.97289 31.0287 0 20 0ZM20 36.9016C10.6816 36.9016 3.09836 29.3199 3.09836 20C3.09836 10.6801 10.6816 3.09836 20 3.09836C29.3184 3.09836 36.9016 10.6801 36.9016 20C36.9016 29.3199 29.3199 36.9016 20 36.9016Z" fill="currentcolor"/>
    <path d="M27.7462 18.3114H21.5495V12.1146C21.5495 11.2595 20.857 10.5654 20.0002 10.5654C19.1435 10.5654 18.451 11.2595 18.451 12.1146V18.3114H12.2543C11.3976 18.3114 10.7051 19.0054 10.7051 19.8606C10.7051 20.7157 11.3976 21.4098 12.2543 21.4098H18.451V27.6065C18.451 28.4617 19.1435 29.1557 20.0002 29.1557C20.857 29.1557 21.5495 28.4617 21.5495 27.6065V21.4098H27.7462C28.6029 21.4098 29.2954 20.7157 29.2954 19.8606C29.2954 19.0054 28.6029 18.3114 27.7462 18.3114Z" fill="currentcolor"/>
    </g>
    <defs>
    <clipPath id="clip0_2004_3459">
    <rect width="40" height="40" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default IconAdd