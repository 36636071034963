import React, { useContext, useEffect, useState } from 'react';
import chroma from 'chroma-js';
import Select, { components } from 'react-select';
import { colorArray } from '../../../../static/static';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addReSearchNotesTag, getAllReSearchNotesTag, removeReSearchNotesTag } from '../../../../store/actions/researchNotesContentTag';
import { AddNoteContent } from '../../../../store/actions/researchNoteContentAction';
import { X } from 'lucide-react';
import { tagContext } from '../ResearchNotes';
const CustomFieldTagName = (props) => {
    const {editData} = props;
    const {addUpdateTags} = useContext(tagContext)
    const {researchNotesTag} = useSelector(state => state)
    const dispatch = useDispatch();
    const [isShow, setIsShow] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOption] = useState([]);
    const [selectOptions, setSelectOption] = useState([]);
    const [isClearState, setIsClearState] = useState(false)

    // console.log('editData',editData)
    useEffect(() => {
        dispatch(getAllReSearchNotesTag({name: inputValue}))
    }, [])

    useEffect(() => {
        if(editData?._id && researchNotesTag.length){
            const prepareOption = editData?.tags?.length ? editData?.tags?.map((item) => {
                const getDefaultOption = researchNotesTag?.find((items) => (items.name === item || items._id === item))
                return getDefaultOption !== undefined ?{
                    value: getDefaultOption?.name,
                    label: getDefaultOption?.name,
                    color: 'black',
                } : {
                    value: item,
                    label: item,
                    color: 'black',
                }
            }) : []
            setSelectOption(prepareOption)
        }
    }, [editData])

    useEffect(() => {
        if(researchNotesTag.length){
            const prepareOption = researchNotesTag.map((item) => {
                return {
                    value: item.name,
                    label: item.name,
                    color: 'black',
                }
            })
            setOption(prepareOption)
        }
    }, [researchNotesTag])

    // Function to generate a random color
    const generateRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * colorArray.length);
        return colorArray[randomIndex];
    };

    // Handler for creating new tags
    const handleCreateTag = () => {
        if (inputValue.trim() !== '') {
            // const newTag = { label: inputValue, value: inputValue.toLowerCase().replace(/\W/g, ''), color: generateRandomColor() };
            // Add the new tag to the existing options
            // const newOptions = [...options, newTag];
            // const newSelectOptions = [...selectOptions, newTag];
            // setOption((prev) => [...prev, newTag]);
            //     setSelectOption((prev) => [...prev, newTag]);
            //     setInputValue(''); // Reset the input value
            dispatch(addReSearchNotesTag({name: inputValue})).then((res) => {
                setOption((prev) => [...prev, {
                    value: res.name,
                    label: res.name,
                    color: 'black',
                }]);
                setSelectOption((prev) => [...prev, {
                    value: res.name,
                    label: res.name,
                    color: 'black',
                }]);
                setInputValue(''); // Reset the input value
                const  prepareSelectOptions = selectOptions.length ? selectOptions.map((item) => item.value) : [];
                // const  prepareEventOptions = res._id;
                const  prepareEventOptions = res.name;
                let prepareAddNoteData = {
                    researchNoteId: editData._id,
                    tags: [...prepareSelectOptions, prepareEventOptions]
      
                }
                addUpdateTags(prepareAddNoteData)
                // dispatch(AddNoteContent(prepareAddNoteData, null))
            })
           
        }
    };
    const handleSelectTag = (selectedOption, action) => {
        // if(isClearState){
        //     setIsClearState(false)
        // } else {
            if(action.action === 'remove-value'){
                const  prepareSelectOptions = selectOptions.length ? selectOptions.filter((item) => item.value !== action.removedValue.value)?.map((item) => item.value) : [];
                let prepareAddNoteData = {
                    researchNoteId: editData._id,
                    tags: [...prepareSelectOptions]
              
                }
                // dispatch(AddNoteContent(prepareAddNoteData, null))
                addUpdateTags(prepareAddNoteData)
                setSelectOption((prev) => {
                    const updateSelectedValue = prev.length ? prev.filter((item) => item.value !== action.removedValue.value) : [];
                    return updateSelectedValue
                });
                setInputValue('');
            } else if(action.action === "clear"){
                let prepareAddNoteData = {
                    researchNoteId: editData._id,
                    tags: []
              
                }
                // dispatch(AddNoteContent(prepareAddNoteData, null))
                addUpdateTags(prepareAddNoteData)
                setSelectOption((prev) => []);
                setInputValue('');
            } else if(action === 'isRemove'){
                dispatch(removeReSearchNotesTag(selectedOption.value)).then((res) => {
                const  prepareSelectOptions = selectOptions.length ? selectOptions.filter((item) => item.value !== selectedOption.value)?.map((item) => item.value) : [];
                let prepareAddNoteData = {
                    researchNoteId: editData._id,
                    tags: prepareSelectOptions.length ? [...prepareSelectOptions] : []
              
                }
                setSelectOption((prev) => {
                    const updateSelectedValue = prev.length ? prev.filter((item) => item.value !== selectedOption.value) : [];
                    return updateSelectedValue
                });
                // dispatch(AddNoteContent(prepareAddNoteData, null))
                addUpdateTags(prepareAddNoteData)
                dispatch(getAllReSearchNotesTag({name: inputValue}))
                if(prepareSelectOptions.length === 0){
                    setIsClearState(true)
                    setOption([])
                }
                })
            }  else {
            if(action.option?.value){
                const  prepareSelectOptions = selectOptions.length ? selectOptions.map((item) => item.value) : [];
            const  prepareEventOptions = action.option?.value
            // event.length ? event.map((item) => item.value) : [];
            let prepareAddNoteData = {
                researchNoteId: editData?._id,
                tags: [...prepareSelectOptions, prepareEventOptions]
          
            }
            // dispatch(AddNoteContent(prepareAddNoteData, null))
            addUpdateTags(prepareAddNoteData)
            setSelectOption((prev) => [...prev, action.option]);
            setInputValue('');
            }
            }
        // }
       
    }

    const removeTag = async (data, e) => {
        e.preventDefault();
        await handleSelectTag(data, 'isRemove')
    }

    // Custom option component with close button
    const Option = (props) => {
        return (
            <components.Option {...props}>
                <div className='flex justify-between align-middle'>
                <div>{props.data.label}</div>
                    <button
                    type="button"
                    className='px-5 bg-gray-100 rounded-full'
                    onClick={async (e) => {
                        e.preventDefault();
                        await removeTag(props.data, e);
                    }}
                >
                   <X className='text-red w-5 h-5 text-sm' />
                </button>
                </div>
            </components.Option>
        );
    };

    return (
        <div className={`pb-5 ${isShow ? 'min-h-[300px]' : ''} `}>
            <h4 class="text-sm font-600 text-textV2 mb-3">Tag:</h4>
            <div>
                <Select
                    placeholder='Select Tags..'
                    closeMenuOnSelect={false}
                    onMenuOpen={() => setIsShow(true)}
                    onMenuClose={() => setIsShow(false)}
                    defaultValue={selectOptions} // Set the default value to include the newly created tag
                    value={selectOptions}
                    isMulti
                    options={options}
                    onChange={(selectedOption, action) => handleSelectTag(selectedOption, action)}
                    components={{ Option }}
                    styles={{
                        control: (styles) => ({ ...styles, backgroundColor: 'white' }),
                         // Override the default Option component with the custom one
                        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
                            const color = chroma(data.color);
                            return {
                                ...styles,
                                backgroundColor: isDisabled
                                    ? undefined
                                    : isSelected
                                        ? data.color
                                        : isFocused
                                            ? color.alpha(0.1).css()
                                            : undefined,
                                color: isDisabled
                                    ? '#ccc'
                                    : isSelected
                                        ? chroma.contrast(color, 'white') > 2
                                            ? 'white'
                                            : 'black'
                                        : data.color,
                                cursor: isDisabled ? 'not-allowed' : 'default',

                                ':active': {
                                    ...styles[':active'],
                                    backgroundColor: !isDisabled
                                        ? isSelected
                                            ? data.color
                                            : color.alpha(0.3).css()
                                        : undefined,
                                },
                            };
                        },
                        multiValue: (styles, { data }) => {
                            const color = chroma(data.color);
                            return {
                                ...styles,
                                backgroundColor: color.alpha(0.1).css(),
                                borderRadius: '10px',
                            };
                        },
                        multiValueLabel: (styles, { data }) => ({
                            ...styles,
                            color: data.color,

                        }),
                        multiValueRemove: (styles, { data }) => ({
                            ...styles,
                            color: data.color,
                            borderRadius: '0px 10px 10px 0px',
                            ':hover': {
                                backgroundColor: data.color,
                                color: 'white',
                            },
                        }),
                        menuList: (base) => ({
                            fontSize: "0.75rem",
                            overflow: "auto",
                            maxHeight: "200px",
                            lineHeight: '1rem',
                            fontWeight: 500,
                            borderRadius: '5px',
                        }),

                    }}
                    className="w-full react_selector"
                    inputValue={inputValue}
                    onInputChange={(newValue, actionMeta) => {
                        if (actionMeta.action === 'input-change') {
                            if(newValue.includes(',')){
                                handleCreateTag();
                            } else {
                                setInputValue(newValue);
                            }
                        }
                    }}
                    onKeyDown={(event) => {
                        // Create new option on Enter key press
                        const validTag = options.find(item => (item.value === inputValue || item.label === inputValue));
                        if (event.key === 'Enter' && inputValue.trim() !== '' && !validTag) {
                            event.preventDefault();
                            handleCreateTag();
                        } 
                    }}
                    onCreateOption={handleCreateTag}
                />
            </div>
        </div>
    )
}

export default CustomFieldTagName;
 // {
    //     "action": "select-option",
    //     "option": {
    //         "value": "66234a7e6111813ada9090ae",
    //         "label": "research one",
    //         "color": "#6666FF"
    //     }
    // }
    // {
    //     "action": "remove-value",
    //     "removedValue": {
    //         "value": "66234a7e6111813ada9090ae",
    //         "label": "research one",
    //         "color": "#E6331A"
    //     }
    // }
  {/* Display selected tags */}
            {/* <div className="flex flex-wrap mb-2">
                {options.map((option, index) => (
                    <div key={index} className="bg-gray-300 rounded-full px-3 py-1 m-1" onClick={() => setSelectOption((prev) => [...prev, option])}>
                        {option.label}
                    </div>
                ))}
            </div>
            <div> */}
                {/* Input for adding new tags */}
                {/* <input
                    type="text"
                    placeholder="Add New Tag..."
                    value={inputValue}
                    className='w-full border border-slate-500'
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleCreateTag();
                        }
                    }}
                />
            </div> */}
// styles={{
//     control: (styles) => ({ ...styles, backgroundColor: 'white' }),
//     option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
//         ...styles,
//         backgroundColor: isSelected ? '#007bff' : isFocused ? '#e9ecef' : null,
//         color: data.color,
//         cursor: isDisabled ? 'not-allowed' : 'default',
//         ':hover': {
//             background: data.color,
//             color: 'white',
//         },
//     }),
//     multiValue: (styles, {data}) => ({
//         ...styles,
//         backgroundColor: data.color,
//         color: 'white',
//         borderRadius: '10px',
//     }),
//     multiValueLabel: (styles, { data }) => ({
//         ...styles,
//         backgroundColor: data.color,
//         borderRadius: '10px',
//         color: 'white',
//     }),
//     multiValueRemove: (styles, { data }) => ({
//         ...styles,
//         backgroundColor: data.color,
//         color: 'white',
//         borderRadius: '0 10px 10px 0',
//         ':hover': {
//             background: 'black',
//             color: 'white',
//         },
       
//     }),
//     menuList: (styles) => ({
//         ...styles,
//         fontSize: '0.75rem',
//         overflow: 'auto',
//         maxHeight: '200px',
//         lineHeight: '1rem',
//         fontWeight: 500,
//         borderRadius: '5px',
//         ':hover': {
//             color: 'white',
//         },
//     }),
// }}