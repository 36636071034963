import { assetsDetailsV2ActionType } from '../../constants';

const assetsHistoryReducer = (state = [], action) => {
    switch (action.type) {
        case assetsDetailsV2ActionType.FETCH_ASSETS_HISTORY_V2:
            return action.payload;
        default:
            return state;
    }
};
export default assetsHistoryReducer