import React from 'react'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from '../../components/ui/alert-dialog'

const Alert = ({ title, description, handleClickContinue, handleClickCancel, showAlertDialog, setShowAlertDialog }) => {
    return (
        <AlertDialog open={showAlertDialog} onOpenChange={() => setShowAlertDialog(false)} >
            <AlertDialogContent className="bg-slate-50 rounded-sm">
                <AlertDialogHeader>
                    <AlertDialogTitle>{title}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {description}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel onClick={handleClickCancel}>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handleClickContinue}>Continue</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default Alert