import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment-js'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { Textarea } from '@/components/ui/textarea'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { removeInvestedAsset } from '../../../../store/actions/portfolioTransactionAction'
import { FromDatePicker } from '../../../../components/ui/DatePickerNew'
const AssetDeleteModel = (props) => {
    const { PortfolioName, fetchInvestData, fetchData, fetchAssetData, setIsLoading } = props
    const dispatch = useDispatch()
    const { slug } = useParams();
    const [updateId, setUpdateId] = useState(null)
    const [error, setError] = useState({});
    const [formData, setFormData] = useState({
        removalPrice: '',
        removalQuantity: '',
        // removalDate: moment(new Date).format('YYYY-MM-DD'),
        removalDate: '',
        reason: '',
        notes: ''
    })

    useEffect(() => {
        setFormData({
            removalPrice: '',
            removalQuantity: '',
            // removalDate: moment(new Date).format('YYYY-MM-DD'),
            removalDate: '',
            reason: '',
            notes: ''
        })
        setUpdateId(null)
    }, [slug])
    useEffect(() => {
        if (!_.isEmpty(fetchData)) {
            setFormData({
                removalPrice: fetchData?.purchasePrice,
                removalQuantity: fetchData?.quantity,
                removalDate: new Date,
                // removalDate: moment(new Date).format('YYYY-MM-DD'),
                // removalDate: moment(fetchAssetData?.purchaseDate).format('YYYY-MM-DD'),
                reason: '',
                notes: ''
            })
            setUpdateId(fetchAssetData?.id)
            setError({})
        }
    }, [fetchData])

    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
        setError({})
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.removalPrice === '') {
            errors['removalPrice'] = 'Please enter Removal Price';
            isValid = false;
        }
        if (formData.removalQuantity === '') {
            errors['removalQuantity'] = 'Please select Removal Quantity';
            isValid = false;
        }
        if (formData.removalDate === '') {
            errors['removalDate'] = 'Please select Removal Date';
            isValid = false;
        }
        if (formData.reason === '') {
            errors['reason'] = 'Please enter Reason';
            isValid = false;
        }
        if (formData.notes === '') {
            errors['notes'] = 'Please enter Notes';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };
    const handleEmtyState = () => {
        setFormData({
            removalPrice: '',
            removalQuantity: '',
            removalDate: moment(new Date).format('YYYY-MM-DD'),
            reason: '',
            notes: ''
        })
    }

    const prepareData = (formData) => {
        const data = {
            // investedAssetId: fetchInvestData?._id,
            // transactionId: fetchInvestData?._id,
            // asset: fetchAssetData.asset._id,
            // portfolio: slug,
            investedAssetId: fetchInvestData._id,
            transactionId: fetchData?.id ? fetchData?.id : fetchData._id, // transaction Id
            removePrice: formData.removalPrice,
            removeDate: moment(formData.removeDate).format('MM/DD/YYYY'),
            removeReason: formData.reason,
            removeNote: formData.notes,
            removeQuantity: formData.removalQuantity
        }

        return data
    }

    const onSave = (e) => {
        if (handleValidation()) {
            if (!_.isEmpty(fetchAssetData)) {
                dispatch(removeInvestedAsset(prepareData(formData), PortfolioName?.portfolio?._id, fetchInvestData._id, 'investedAsset', setIsLoading))
                handleEmtyState();
                props.onHide()
            } else {
            }
        }
    }
    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[50%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">Delete Asset</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-2 gap-5">
                            <div className="grid grid-cols-1 gap-5">

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Removal Price:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Input
                                        className={`form-control shadow-none ${error['removalPrice'] && 'is-invalid'}`}
                                        id="inputEmail3" name='removalPrice' value={formData.removalPrice} type="number" onChange={(e) => onChangeHandel(e)} />

                                    <div className={`${error['removalPrice'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['removalPrice'] ? error['removalPrice'] : null}</div>
                                </div>
                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Removal Quantity:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Input
                                        className={`form-control shadow-none ${error['removalQuantity'] && 'is-invalid'}`}
                                        id="inputEmail3" disabled name='removalQuantity' type="number" value={formData.removalQuantity} onChange={(e) => onChangeHandel(e)} />

                                    <div className={`${error['removalQuantity'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['removalQuantity'] ? error['removalQuantity'] : null}</div>
                                </div>

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Removal Date:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <FromDatePicker onDateSelect={(date) => {
                                        setFormData({ ...formData, ['removalDate']: date });
                                        setError({})
                                    }}
                                        value={formData.removalDate}
                                        placeholderText='YYYY-MM-DD'
                                        selectedFromDate={formData.removalDate}
                                    />
                                    {/* <Input
                                        className={`form-control shadow-none ${error['removalDate'] && 'is-invalid'}`}
                                        id="basic-form-dob" name='removalDate' type="date" value={formData.removalDate} onChange={(e) => onChangeHandel(e)} /> */}

                                    <div className={`${error['removalDate'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['removalDate'] ? error['removalDate'] : null}</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 gap-5">

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Reason:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Textarea
                                        className={`form-control shadow-none ${error['reason'] && 'is-invalid'}`}
                                        id="basic-form-textarea" name='reason' rows="3" value={formData.reason} placeholder="Description" onChange={(e) => onChangeHandel(e)} />

                                    <div className={`${error['reason'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['reason'] ? error['reason'] : null}</div>
                                </div>

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Notes:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Textarea
                                        className={`form-control shadow-none ${error['notes'] && 'is-invalid'}`}
                                        id="basic-form-textarea" name='notes' rows="3" value={formData.notes} placeholder="Description" onChange={(e) => onChangeHandel(e)} />

                                    <div className={`${error['notes'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['notes'] ? error['notes'] : null}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default AssetDeleteModel
