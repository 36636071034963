import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva } from "class-variance-authority"
import { cn } from "../../lib/utils"


const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap outline-none group/btn rounded-sm text-sm font-medium transition-colors  disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:"bg-themeV2 text-white hover:bg-[#2487EE]",
        gray:"bg-backgroundV1 border border-borderV1 hover:bg-theme hover:text-white ",
        outline:"bg-transparent border border-themeV2 hover:border-[#2487EE] text-themeV2 hover:text-[#2487EE]",
        ghost: "p-0 !bg-none",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "px-4 py-2.5",
        sm: "px-3 py-2 text-xs",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)


const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
