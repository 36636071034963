


import apiConfig from '../../config/apiConfig';
import { apiBaseURL, assetsAuthorizeActionType, issuerActionType, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { metaDataAction } from './metaDataAction';
import { addTost } from './toastAction';


export const fetchIssuer = (setIsLoading, setAssetsData, page, perPage, search) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.ISSUERS + '?page='+page+ '&perPage='+perPage+ '&search='+search)
        dispatch({ type: issuerActionType.FETCH_ISSUER, payload: response.data?.data });
        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: perPage, totalItems: response.data?.data?.totalRecords }))
        setAssetsData && setAssetsData(response.data?.data?.records)
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const assetByFetchIssuer = (setIsLoadIssuer, setIssuerData, search) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.ISSUERS + '?search='+search+ '&filter=true')
        dispatch({ type: issuerActionType.FETCH_ISSUER, payload: response.data?.data });
        setIssuerData && setIssuerData(response.data?.data)
        setIsLoadIssuer && setIsLoadIssuer(false)
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const addIssuer = (data, setAssetsData, currentPage, setCurrentPage) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ISSUERS + '/add-update', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchIssuer(null, setAssetsData, currentPage, 10, ''))
        setCurrentPage && setCurrentPage(currentPage)
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message ? error?.data?.message : error?.response?.data?.message }))
    }
}


export const removeIssuer = (data, id, setAssetsData) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.post(apiBaseURL.ISSUERS + '/remove', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
       dispatch(fetchIssuer(null, setAssetsData, 1, 10, ''))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}

export const updateIssuer = (data) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.put(apiBaseURL.ISSUERS + '/update', data)
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
        dispatch(fetchIssuer(null, null, 1, 10, ''))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }))
    }
}