import React from 'react'

const IconFile = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" fill="white" />
      <path d="M7.01039 20.211C7.01039 15.4572 7.01039 10.704 7.01039 5.95129C6.98927 5.023 7.29927 4.11815 7.88344 3.40296C8.46761 2.68776 9.28673 2.21025 10.1903 2.05813C10.4889 2.01219 10.791 1.99341 11.0929 2.00204C16.8879 2.00204 22.6824 2.00204 28.4762 2.00204C30.9437 2.00204 32.5484 3.63398 32.5484 6.1371C32.5484 15.5711 32.5484 25.0058 32.5484 34.441C32.5484 35.8047 31.7651 36.7495 30.5149 36.8775C30.1775 36.9128 29.8365 36.8678 29.5192 36.7461C29.2019 36.6244 28.917 36.4293 28.6872 36.1763C28.0751 35.5576 27.4681 34.937 26.856 34.32C26.275 33.7363 25.8341 33.7135 25.196 34.2289C23.9718 35.221 22.7486 36.2143 21.5267 37.2088C20.2437 38.2605 19.3324 38.2605 18.058 37.2281L14.4562 34.3025C13.6867 33.6785 13.3132 33.703 12.6042 34.4217C12.0544 34.9774 11.5079 35.5383 10.9563 36.0922C10.1696 36.881 9.24792 37.1159 8.36259 36.711C7.40636 36.2727 6.99828 35.4839 7.00001 34.4322C7.01384 29.6889 7.01731 24.9485 7.01039 20.211Z" stroke="currentcolor" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M12.0254 14.8999H27.4149" stroke="currentcolor" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M14.6191 9.70068H24.9941" stroke="currentcolor" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M12.1992 20.1006H27.4159" stroke="currentcolor" strokeWidth="2" strokeMiterlimit="10" />
      <path d="M12.1992 25.3013H27.4159" stroke="currentcolor" strokeWidth="2" strokeMiterlimit="10" />
    </svg>
  )
}

export default IconFile