import { adminSubTypeActionType } from '../../../constants';

const adminSubTypeReducer = (state = [], action) => {
    switch (action.type) {
        case adminSubTypeActionType.FETCH_SUBTYPES:
            return action.payload;
        case adminSubTypeActionType.ADD_SUBTYPES:
            return action.payload;
        case adminSubTypeActionType.DELETE_SUBTYPES:
            return action.payload;
        case adminSubTypeActionType.UPDATE_SUBTYPES:
            return action.payload;
        case adminSubTypeActionType.FETCH_SUBTYPE:
            return action.payload;
        default:
            return state;
    }
};
export default adminSubTypeReducer