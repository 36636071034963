
import { apiBaseURL, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { addTost } from "./toastAction";


export const validateShortCode = (data) => async (dispatch) => {
  try {
    const response = await apiConfig.post(apiBaseURL.CUSTOM_FIELD + '/validate-short-code', data)
    if(response?.data?.success === true){
        return response?.data?.message
    }
    // return response
  } catch (error) {
    if(error?.response?.data?.success === false){
        return error?.response?.data?.message
    } else {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
    
  }
}
