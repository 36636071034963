import React from 'react'

const IconResearchNote = (props) => {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        {...props} viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">
      
       <g filter="url(#filter0_d_787_4028)" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       >
       <path d="M3606 5109 c-116 -27 -244 -133 -298 -247 -31 -66 -34 -69 -80 -80
       -161 -38 -305 -171 -362 -335 l-22 -64 -684 184 c-377 101 -703 183 -726 183
       -82 0 -45 119 -543 -1740 -248 -927 -451 -1698 -451 -1713 0 -15 12 -40 28
       -57 25 -28 55 -38 357 -119 l330 -88 5 -492 5 -493 28 -24 28 -24 1667 0 1666
       0 28 27 28 27 0 1739 0 1739 35 129 c40 150 42 177 15 212 -25 31 -8 26 -439
       141 -194 52 -355 96 -357 98 -3 4 13 79 18 81 1 1 33 17 69 35 118 58 209 173
       244 307 61 237 -81 493 -316 566 -66 21 -202 24 -273 8z m260 -234 c77 -45
       134 -141 134 -225 0 -109 -88 -224 -194 -255 -126 -37 -253 20 -312 140 -22
       47 -26 64 -22 122 2 38 12 84 22 104 21 44 79 105 120 126 71 37 177 32 252
       -12z m-587 -371 c27 -84 75 -158 136 -212 53 -47 149 -97 207 -107 l37 -7 -20
       -40 c-54 -105 -162 -172 -280 -172 -126 0 -244 82 -289 201 -54 144 3 300 140
       382 19 11 38 21 41 21 3 0 16 -30 28 -66z m-1109 -158 c344 -92 636 -171 650
       -175 19 -6 30 -22 47 -69 12 -34 30 -73 39 -87 17 -26 17 -26 -45 -89 -70 -71
       -84 -111 -57 -164 20 -39 44 -52 96 -52 38 0 49 6 108 64 l67 63 30 -18 c43
       -27 145 -57 218 -65 123 -12 284 42 380 127 l47 41 348 -94 348 -93 -337
       -1255 c-185 -690 -341 -1254 -345 -1253 -5 0 -119 30 -254 66 -135 36 -256 67
       -270 68 -97 9 -103 -1 -189 -323 l-73 -274 -572 153 c-314 84 -826 221 -1138
       305 -312 83 -573 155 -580 159 -19 12 822 3141 842 3136 8 -2 296 -79 640
       -171z m2230 -2881 l0 -1265 -1515 0 -1515 0 0 385 c0 268 3 385 11 385 6 0
       380 -99 832 -221 466 -125 835 -219 852 -217 38 4 852 474 870 502 7 12 113
       398 236 858 123 461 225 838 227 838 1 0 2 -569 2 -1265z m-942 -377 c62 -17
       111 -32 110 -33 -12 -11 -340 -196 -342 -193 -2 1 13 63 33 136 35 131 36 133
       61 127 14 -3 76 -20 138 -37z" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" strokeMiterlimit="100"/>
       <path d="M1643 3415 c-35 -25 -48 -83 -29 -128 8 -20 25 -42 38 -47 53 -24
       2004 -540 2040 -540 92 0 133 126 58 181 -32 24 -1989 549 -2045 549 -22 0
       -50 -7 -62 -15z" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" strokeMiterlimit="100"/>
       <path d="M1564 3041 c-62 -26 -84 -116 -41 -166 20 -23 126 -54 1027 -296 552
       -148 1020 -269 1038 -269 101 0 129 161 35 198 -15 6 -291 82 -613 167 -322
       86 -758 203 -970 260 -422 113 -445 118 -476 106z" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" strokeMiterlimit="100"/>
       <path d="M1448 2644 c-35 -19 -48 -43 -48 -93 0 -77 10 -82 345 -172 209 -56
       309 -79 331 -75 48 10 79 49 79 102 0 79 -13 86 -347 175 -161 43 -302 79
       -313 78 -11 0 -32 -6 -47 -15z" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" strokeMiterlimit="100"/>
       <path d="M2423 2375 c-32 -23 -47 -77 -33 -120 15 -45 49 -68 102 -68 34 0 47
       6 70 32 62 70 19 171 -74 171 -24 0 -53 -7 -65 -15z" fill="currentcolor" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="5" strokeMiterlimit="100"/>
       </g>
       </svg>
       
    )
}

export default IconResearchNote