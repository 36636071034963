import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toastifyType } from '../../constants';
import { Toaster, toast } from 'sonner'

const ToastHandler = () => {
  const dispatch = useDispatch();
  const { toastType, toastMessage } = useSelector(state => state.toast);
   
  useEffect(() => {
    if (toastType && toastMessage) {
      if (toastType === toastifyType.SHOW_SUCCESS_MESSAGE) {
        // toast.success(toastMessage);
        toast.success(toastMessage)
      } else if (toastType === toastifyType.SHOW_ERROR_MESSAGE) {
        // toast.error(toastMessage);
        toast.error(toastMessage)
      }

      // Clear toast
      dispatch({ type: toastifyType.SHOW_TOAST, payload: { type: null, message: '' } });
    }
  }, [toastType, toastMessage, dispatch]);

  return <Toaster position="bottom-right" richColors className='p-4' />;
};

export default ToastHandler;
