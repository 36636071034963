import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AssentContext } from '../Asset';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Checkbox } from "@/components/ui/checkbox"
import { dateConvert } from '../../../../shared/common/dateFormate';
import { fetchAllPortfolios2 } from '../../../../store/actions/portfolioAction';
import { DataTableWithCustomPagination } from '../../../../shared/common/table-components/tasks/components/data-table-without-pagination';
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header';
import { Filters } from '../../../../constants';
import Divider from '../../../../components/common/Divider';



const AssetAuthorizeModel = (props) => {
    const { save } = props
    const { fetchAssetData, setFetchAssetData } = useContext(AssentContext);
    const { portfolios, allPortfolio, isLoading } = useSelector((state) => state);
    const dispatch = useDispatch()
    const [assets, setAssets] = useState([]);
    const [fetchAssets, setFetchAssets] = useState({});
    const [singlePortfolio, setSinglePortfolio] = useState([])
    const [allPortfolios, setAllPortfolios] = useState([]);

    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)

    useEffect(() => {
        setAssets([fetchAssetData])
        setFetchAssets(fetchAssetData)
    }, [fetchAssetData])


    useEffect(() => {
        props.show && dispatch(fetchAllPortfolios2(setTotalRecords, setTotalPages, currentPage, perPage))
    }, [props.show, currentPage, perPage]);


    useEffect(() => {
        // let filteredArray = portfolios.filter((item) => item.portfolio !== null);
        setAllPortfolios(allPortfolio);
    }, [allPortfolio]);


    // Pagination functions
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

    const data = allPortfolios?.records?.length ? allPortfolios?.records.map((item, index) => {
        return { ...item, idNumber: indexOfFirstItem + index + 1 }
    }) : [];

    const columns = [
        {
            id: "select",
            header: ({ table }) => (
                <>
                    <Checkbox
                        checked={
                            table.getIsAllPageRowsSelected() ||
                            (table.getIsSomePageRowsSelected() && "indeterminate")
                        }
                        onCheckedChange={(value) => { table.toggleAllPageRowsSelected(!!value); onCheckBtn(null, table.options.data, value, true) }}
                        aria-label="Select all"
                        className="translate-y-[2px] border-black rounded-[4px]"
                    />
                </>
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => { row.toggleSelected(!!value); onCheckBtn(null, row.original, value, false) }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "Id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.idNumber}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Created Date",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Created Date" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{dateConvert(row?.original?.createdAt)}</span>
            },
            enableSorting: true,
        },
    ]
    //   const onCheckBtn = (e, item) => {
    //     const itemIndex = singlePortfolio.findIndex((checkedItem) => checkedItem.id === item.id);

    //     if (itemIndex !== -1) {
    //       // If the item is already in singlePortfolio, remove it
    //       const updatedPortfolio = [...singlePortfolio];
    //       updatedPortfolio.splice(itemIndex, 1);
    //       setSinglePortfolio(updatedPortfolio);
    //     } else {
    //       // If the item is not in singlePortfolio, add it
    //       setSinglePortfolio([...singlePortfolio, item]);
    //     }
    //   };

    const onCheckBtn = (e, item, value, isAll) => {
        if (!isAll) {
            const itemIndex = singlePortfolio.findIndex((checkedItem) => checkedItem.id === item.id);

            if (itemIndex !== -1) {
                // If the item is already in singlePortfolio, remove it
                const updatedPortfolio = [...singlePortfolio];
                updatedPortfolio.splice(itemIndex, 1);
                setSinglePortfolio(updatedPortfolio);
            } else {
                // If the item is not in singlePortfolio, add it
                setSinglePortfolio([...singlePortfolio, item]);
            }

        } else {
            setSinglePortfolio(item)
        }
    }




    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[70%] gap-0 p-0">
                    <DialogHeader className="p-5 border-b border-borderV1">
                        <DialogTitle className="text-start">{'Authorise Asset'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body p-5">
                        <div className="mb-10">
                            <div className='mb-4'>
                                <h4 className='text-sm text-textV2 font-600'>Asset</h4>
                            </div>
                            <div className="grid grid-cols-2 gap-5">
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4">
                                        <Label htmlFor="existingPortfolio" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Id
                                        </Label>
                                    </div>
                                    <div className='field-wrapper col-span-8'>
                                        <Input className="w-full h-10 text-[13px] rounded-[12px]" id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssets && fetchAssets?.assetId} />
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4">
                                        <Label htmlFor="existingPortfolio" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Name
                                        </Label>
                                    </div>
                                    <div className='field-wrapper col-span-8'>
                                        <Input className="w-full h-10 text-[13px] rounded-[12px]" id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssets && fetchAssets?.assetName} />
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4">
                                        <Label htmlFor="existingPortfolio" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Class
                                        </Label>
                                    </div>
                                    <div className='field-wrapper col-span-8'>
                                        <Input className="w-full h-10 text-[13px] rounded-[12px]"
                                            id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssets && fetchAssets?.class?.name} />
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4">
                                        <Label htmlFor="existingPortfolio" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Sub class
                                        </Label>
                                    </div>
                                    <div className='field-wrapper col-span-8'>
                                        <Input className="w-full h-10 text-[13px] rounded-[12px]" id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssets && fetchAssets?.subClass?.name} />
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4">
                                        <Label htmlFor="existingPortfolio" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Description
                                        </Label>
                                    </div>
                                    <div className='field-wrapper col-span-8'>
                                        <Input className="w-full h-10 text-[13px] rounded-[12px]" id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssets && fetchAssets?.description} />
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4">
                                        <Label htmlFor="existingPortfolio" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Ccountry
                                        </Label>
                                    </div>
                                    <div className='field-wrapper col-span-8'>
                                        <Input className="w-full h-10 text-[13px] rounded-[12px]" id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchAssets && fetchAssets?.country?.countryName} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='mb-0'>
                                <h4 className='text-sm text-textV2 font-600'>  Portfolios</h4>
                            </div>
                            <DataTableWithCustomPagination data={data} columns={columns} filterName={'Name'} isSearchHide={true} isCollViewHide={true}
                                setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap- p-5"}>
                        <Button type="submit"  className="min-w-[100px] h-10" onClick={() => save(singlePortfolio)}>Authorise</Button>
                        <Button type="submit" className="min-w-[100px] h-10"  variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default AssetAuthorizeModel
