import apiConfig from '../../config/apiConfig';
import {  allocationsMonitorActionType, apiBaseURL, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { addTost } from './toastAction';


export const fetchAllocationMonitor = (id, setIsLoading, section) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.ALLOCATION_MONITOR+'/'+id+'?section='+section)
        // setAllocationData && setAllocationData(response.data?.data);
        dispatch({ type: allocationsMonitorActionType.FETCH_ALLOCATIONS_MONITOR, payload: response.data?.data });
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        // setDataFetched && setDataFetched(true)
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const fetchAllocationMonitorAsGroup = (id, setIsLoading, section) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.ALLOCATION_MONITOR+'/'+id+'?section='+section)
        // setAllocationData && setAllocationData(response.data?.data);
        dispatch({ type: allocationsMonitorActionType.FETCH_ALLOCATIONS_MONITOR, payload: response.data?.data });
        setIsLoading && setIsLoading(false)
        dispatch(setLoading(false))
        // setDataFetched && setDataFetched(true)
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}
