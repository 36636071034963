import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button } from '@/components/ui/button.jsx';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { addStrategy, updateStrategy } from '../../../../store/actions/strategyAction';

const StrategyModel = (props) => {
    const { show, onHide, fetchData, fetchId } = props
    const dispatch = useDispatch()
    const [ctName, setCtName] = useState({
        name: ''
    })
    const [error, setError] = useState({});

    useEffect(() => {
        if (show === false) {
            setCtName({
                name: ''
            })
        }
    }, [show])

    useEffect(() => {
        if (fetchId !== null) {
            setCtName({
                name: fetchData && fetchData?.name,
            })
        }
    }, [fetchData, fetchId])

    const onChangeHandel = (e) => {
        setCtName({ ...ctName, name: e.target.value })
        setError({})
    }
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (ctName.name === '') {
            errors['strategy'] = 'Please enter strategy name';
            isValid = false;
        }

        setError(errors);
        return isValid;
    };
    const onSave = () => {
        if (handleValidation()) {
            if (fetchId !== null) {
                dispatch(updateStrategy(fetchId, ctName))
            } else {
                dispatch(addStrategy(ctName))
            }
            onHide()
        }
    }


    return (
        <Dialog open={show} onOpenChange={onHide}>
        <DialogContent className="sm:max-w-[460px] gap-10">
            <DialogHeader>
                <DialogTitle className="text-center">{fetchId ? 'Edit strategy' : 'Add strategy'}</DialogTitle>
            </DialogHeader>
            <div className="dialog-body">
                <div className="grid grid-cols-1 gap-5">
                    <div className="form-group">
                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                        Strategy Name:<span style={{ color: 'red' }}>*</span>
                        </Label>
                        <Input
                            className={`form-control shadow-none ${error['strategy'] && 'is-invalid'}`}
                            id="name" type="text" name="strategy" placeholder="Name" onChange={(e) => onChangeHandel(e)} value={ctName.name} />
                        <div className={` ${error['strategy'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['strategy'] ? error['strategy'] : null}</div>
                    </div>
                </div>
            </div>
            <DialogFooter className={"!justify-center !gap-2"}>
                <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
            </DialogFooter>
        </DialogContent>
    </Dialog>
    )
}

export default StrategyModel