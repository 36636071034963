
import { byodActionType } from '../../constants';

const BYODValuesReducer = (state = { BYODVALUE: null, UPLOADVALUE: null }, action) => {
    switch (action.type) {
      case byodActionType.FETCH_BYOD_VALUE_V2:
        return { ...state, BYODVALUE: action.payload };
      case byodActionType.FETCH_BYOD_VALUE2_V2:
        return { ...state, UPLOADVALUE: action.payload };
      default:
        return state;
    }
  };
  
  export default BYODValuesReducer;