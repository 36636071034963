import { portfoliosActionType } from '../../constants';

const allPortfolioReducer = (state = [], action) => {
    switch (action.type) {
        case portfoliosActionType.FETCH_PORTFOLIOS_ALL:
            return action.payload;
        default:
            return state;
    }
};
export default allPortfolioReducer