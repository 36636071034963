export const numberWithOutDecimal = (number) => {
    return parseFloat(number);
}

export const numberWithOneDecimal = (number) => {
    return parseFloat(number).toFixed(1);
}

export const numberWithTowDecimal = (number) => {
    return parseFloat(number).toFixed(2);
}