import React, { useContext, useEffect, useState } from 'react'
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header'
import { useSelector } from 'react-redux'
import { ByodContext } from '../BYOD'
import { useDispatch } from 'react-redux'
import { addManuallyData, fetchCustomFieldValue, fetchCustomFieldValues } from '../../../../store/actions/BYODAction'
import { dateConvert } from '../../../../shared/common/dateFormate'
import { DataTableWithCustomPagination } from '../../../../shared/common/table-components/tasks/components/data-table-without-pagination'
import { Filters } from '../../../../constants'


const data = [
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
    {
        assetId: "AC78dbc462",
        value: 2.2,
        startDate: "Nov 25, 2023",
        endDate: "Nov 25, 2023",
        comment: "Test Comment",
        description: "Test Descriptiopn",
    },
]


export const columns = [
    {
        accessorKey: "assetId",
        header: ({ column }) => (
            <DataTableColumnHeader column={column} title="Asset Id" />
        ),
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("assetId")}</div>
        ),
        enableSorting: true,
    },
    {
        accessorKey: "value",
        header: "Value",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("value")}</div>
        ),
    },
    {
        accessorKey: "startDate",
        header: "Start Date",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("startDate")}</div>
        ),
    },
    {
        accessorKey: "endDate",
        header: "End Date",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("endDate")}</div>
        ),
    },
    {
        accessorKey: "comment",
        header: "Comment",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("comment")}</div>
        ),
    },
    {
        accessorKey: "description",
        header: "Description",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("description")}</div>
        ),
    },
]

const UploadPreviewContent = (props) => {
    const { keyName, isUpload, setIsUpload } = props;
    const { BYODDataValues } = useSelector(state => state)
    const content = useContext(ByodContext)
    const getField = content?.getField
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)
    // console.log("get", getField)
    useEffect(() => {
        if (keyName === "preview" && isUpload === true) {
            // dispatch(fetchCustomFieldValue(getField.id, currentPage, perPage, setIsLoading))
            dispatch(fetchCustomFieldValues(null, currentPage, perPage, setIsLoading))
            setIsUpload(true)
        }
    }, [currentPage, perPage, keyName, getField])


    useEffect(() => {
        setTotalRecords(BYODDataValues?.BYODVALUE?.totalRecords)
        setTotalPages(Math.ceil(BYODDataValues?.BYODVALUE?.totalRecords / perPage))
    }, [BYODDataValues?.BYODVALUE]);

    useEffect(() => {
        if (BYODDataValues?.BYODVALUE?.records?.length) {
            const prepareData = BYODDataValues?.BYODVALUE?.records && BYODDataValues?.BYODVALUE?.records?.length ?
                BYODDataValues?.BYODVALUE?.records.map((item, index) => {
                    return {
                        _id: item._id,
                        assetId: item.assetId ? item.assetId : '-',
                        endDate: item.endDate !== null ? dateConvert(item.endDate) : '-',
                        startDate: item.startDate !== null ? dateConvert(item.startDate) : '-',
                        value: item.value ? item.value : '-',
                        comment: item.comment ? item.comment : '-',
                        description: item.description ? item.description : '-',
                        issuerId: item.issuerId ? item.issuerId : '-'
                    }
                }) : []
            setData(prepareData)
        } else {
            setData([])
        }
    }, [BYODDataValues?.BYODVALUE])

    const columns = [
        {
            accessorKey: "assetId",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Asset Id" />
            ),
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("assetId")}</div>
            ),
            enableSorting: true,
        },
        {
            accessorKey: "issuerId",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Issuer Id" />
            ),
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("issuerId")}</div>
            ),
            enableSorting: true,
        },
        {
            accessorKey: "value",
            header: "Value",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("value")}</div>
            ),
        },
        {
            accessorKey: "startDate",
            header: "Start Date",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("startDate")}</div>
            ),
        },
        {
            accessorKey: "endDate",
            header: "End Date",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("endDate")}</div>
            ),
        },
        {
            accessorKey: "comment",
            header: "Comment",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("comment")}</div>
            ),
        },
        {
            accessorKey: "description",
            header: "Description",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("description")}</div>
            ),
        },  
    ]

   


    return (
        <div className="">
            <DataTableWithCustomPagination data={data} columns={columns} isCollViewHide={true} filterName={'assetId'} isRight={true} loadData={isLoading}

                setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}
            />
           
        </div>
    )
}

export default UploadPreviewContent