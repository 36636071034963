
import { apiBaseURL, toastifyType, userAnalyticsActionType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";


export const fetchUserAnalytics = (filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage) => async (dispatch) => {
  try {
      // /analytics?sourceId=651eb17fe6b11822ec85ab53&page=5&perPage=1
    // dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.USER_ANALYTICS);
    dispatch({ type: userAnalyticsActionType.FETCH_USER_ANALYTICS, payload: response?.data?.data });
    setTotalRecords && setTotalRecords(response.data?.data?.totalRecords)
    setTotalPages && setTotalPages(response.data?.data?.totalPage)
    setCurrentPage && setCurrentPage(response.data?.data?.currentPage)
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    // console.error('error', error);
  }
};
export const fetchUserAnalyticsWithPagination = (filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage) => async (dispatch) => {
  try {
      // /analytics?sourceId=651eb17fe6b11822ec85ab53&page=5&perPage=1
    dispatch(setLoading(true));
    const response = await apiConfig.get(apiBaseURL.USER_ANALYTICS + `?page=${filter.page}&perPage=${filter.perPage}&sourceId=${dataId}`);
    dispatch({ type: userAnalyticsActionType.FETCH_USER_ANALYTICS, payload: response?.data?.data });
    setTotalRecords && setTotalRecords(response.data?.data?.totalRecords)
    setTotalPages && setTotalPages(response.data?.data?.totalPage)
    setCurrentPage && setCurrentPage(response.data?.data?.currentPage)
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    // console.error('error', error);
  }
};

export const addUserAnalytics = (data, isAnalytics, filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // + '/world-bank'
    const response = await apiConfig.post(apiBaseURL.USER_ANALYTICS, data);
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    if(isAnalytics){
      dataId && dispatch(fetchUserAnalyticsWithPagination(filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
    } else {
      dispatch(fetchUserAnalytics());
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const addUserUnitedNationsAnalytics = (data, isAnalytics, filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // + '/united-nations/sdg'
    const response = await apiConfig.post(apiBaseURL.USER_ANALYTICS, data);
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    if(isAnalytics){
      dispatch(fetchUserAnalyticsWithPagination(filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
    } else {
      dispatch(fetchUserAnalytics());
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const deleteUserAnalytics = (deleteData, isAnalytics, filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // const response = await apiConfig.delete(apiBaseURL.USER_ANALYTICS + '/' + id);
    const response = await apiConfig.delete(apiBaseURL.USER_ANALYTICS, { data: deleteData });
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    if(isAnalytics){
      dispatch(fetchUserAnalyticsWithPagination(filter, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
    } else {
      dispatch(fetchUserAnalytics());
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};