import { userAnalyticsActionType } from '../../constants';

const userAnalyticsReducer = (state = [], action) => {
    switch (action.type) {
        case userAnalyticsActionType.FETCH_USER_ANALYTICS:
            return action.payload;
        case userAnalyticsActionType.FETCH_USER_ANALYTIC:
            return action.payload;
        case userAnalyticsActionType.ADD_USER_ANALYTIC:
            return action.payload;
        case userAnalyticsActionType.UPDATE_USER_ANALYTIC:
            return action.payload;
        case userAnalyticsActionType.DELETE_USER_ANALYTIC:
            return action.payload;
        default:
            return state;
    }
};
export default userAnalyticsReducer