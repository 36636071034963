
import {  apiBaseURL, authActionType, toastifyType } from '../../../constants';
import { setLoading } from '../loadingAction';
import { addTost } from '../toastAction';
import apiConfig from '../../../config/apiConfig';


export const register = (user) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.ADMIN_REGISTER, user);
    dispatch({ type: authActionType.REGISTER, payload: response.data.data });
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    // Dispatch navigation action or use window.location to redirect
    // Example if using window.location:
    // window.location.href = environment.BASE_URL + "/login";
    // If using a navigation library, dispatch the navigation action here.
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};