import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { dateConvert } from '../../dateFormate';
import DataTableColumnHeader from '../../table-components/tasks/components/data-table-column-header';
import { fetchGroupAnalyticsData, fetchIndicatorSource } from '../../../../store/actions/analyticsAction';
import { Filters } from '../../../../constants';
import { DataTableWithCustomPagination } from '../../table-components/tasks/components/data-table-without-pagination';
import { assetAnalyticOp } from '../../../../static/static';
import { numberWithTowDecimal } from '../../numberFormate';

const AssetAnalyticContent = (props) => {
    const { fetchAssetData, keyValue, selectedId } = props;
    const { analytics } = useSelector(state => state)
    const { id } = useParams();
    const dispatch = useDispatch();
    const [fetchAnalytics, setFetchAnalytics] = useState([])
    const [isLoadingData, setIsLoadingData] = useState(false)
    const [selectedOp, setSelectedOp] = useState(null)

    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
    const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)

    useEffect(() => {
        setFetchAnalytics([])
    }, [id])

    useEffect(() => {
        if (keyValue === 'Asset-Analytic' && !_.isEmpty(fetchAssetData)) {
            setIsLoadingData(true)
            dispatch(fetchGroupAnalyticsData(fetchAssetData?._id, setIsLoadingData, { page: currentPage, perPage: perPage }, selectedOp?.value ? selectedOp?.value : '',
            setTotalRecords, setTotalPages, setPerPage, setCurrentPage
            ))
        }
    }, [keyValue, fetchAssetData, selectedOp, currentPage, perPage])


    useEffect(() => {
        if(selectedId?.length === 0){
            setFetchAnalytics([])
        } else {
            dispatch(fetchIndicatorSource({hideLoading: true}))
        }
    }, [selectedId])
    // useEffect(() => {
    //     setTotalRecords(investedAsset.totalRecords)
    //     setTotalPages(Math.ceil(investedAsset.totalRecords / perPage))
    //   }, [investedAsset]);

    // const onChange = (filter) => {
    //     if (keyValue === 'Asset-Analytic' && !_.isEmpty(fetchAssetData)) {
    //         setIsLoadingData(true)
    //         dispatch(fetchGroupAnalyticsData(fetchAssetData?._id, setIsLoadingData, filter))
    //     }
    // }

    useEffect(() => {
        if (!_.isEmpty(fetchAssetData)) {
            setFetchAnalytics([])
        }
    }, [fetchAssetData])

    // useEffect(() => {
    //     if (!_.isEmpty(fetchAssetData) && analytics) {
    //         const unitedNation = Array.isArray(analytics.analyticsData) ? analytics.analyticsData.find((item) => item.sourceName === 'World Bank') : null
    //         const unitedNationIndicators = unitedNation ? unitedNation.indicatorValue : []
    //         setFetchAnalytics(unitedNationIndicators)
    //     }
    // }, [analytics, fetchAssetData])

    useEffect(() => {
        if (!_.isEmpty(fetchAssetData) && analytics && selectedOp?.value) {
            // analytics.analyticsData.find((item) => item.sourceName === 'United Nations (SDGs)') : null
            const unitedNation = Array.isArray(analytics.analyticsData) ? analytics.analyticsData.length ? analytics.analyticsData[0] : null : null
            const unitedNationIndicators = unitedNation ? unitedNation.indicatorValue : [];
            setFetchAnalytics(unitedNationIndicators)
        }
    }, [analytics, fetchAssetData])


    // Pagination functions
    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;


   
    const assetAnalyticOptionOption = analytics?.indicatorSource ? analytics?.indicatorSource.map((item) => {
        return { value: item._id, label: item.sourceName }
    }) : []

    const data = Array.isArray(fetchAnalytics) ? [...fetchAnalytics].map((item, index) => {
        return { indexNum: indexOfFirstItem + index + 1, ...item }
    }) : []

    const handleSelect = (event) => {
        const assetAnalyticOp = JSON.parse(event)
        setSelectedOp(assetAnalyticOp);
    }

    const columns = [
        {
            accessorKey: "Id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.indexNum}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "Name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.indicator?.indicatorName || row?.original?.description} {row?.original?.series ? '|' : ''} {row?.original?.series?.description} {row?.original?.dimension ? '(' + row?.original?.dimension.description + ')' : ''}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "Value",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Value" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.value === "" ? 'N/A' : numberWithTowDecimal(row?.original?.value)}</span>
            },
            enableSorting: true,

        },
        {
            accessorKey: "Date",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Date" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.date ? dateConvert(row?.original?.date) : row?.original?.year === "" ? 'N/A' : row?.original?.value}</span>
            },
            enableSorting: true,

        },
    ]

    return (
        <section className='p-3 md:p-5 !pt-0'>
            {<DataTableWithCustomPagination data={data} columns={columns} filterName={'Name'} loadData={isLoadingData} isSearchHide={true}
                isSelector={true} selectorOp={assetAnalyticOptionOption} selectorValue={selectedOp} selectorOnChange={handleSelect} selectorName={'Select Source'}
                isSelectorDisable={_.isEmpty(fetchAssetData)}
                setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={selectedOp?.value ? totalPages : 1}
            /> }
        </section>
    )
}

export default AssetAnalyticContent
