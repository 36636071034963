import React, { createContext, useEffect, useState } from 'react'
import DashboardMaster from '../../../../layout/dashboard/DashboardMaster'
import { useSelector } from 'react-redux'
import HistoricalTable from '../tables/HistoricalTable'

export const PricesContext = createContext(null);
const PricesDetailsPage = () => {
  const { financePrice } = useSelector(state => state)
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (financePrice?.records) {
      setRecords(financePrice?.records);
    }
  }, [financePrice?.records]);
  return (
    <DashboardMaster>
      <PricesContext.Provider value={{ records, financePrice, isLoading, setIsLoading }} >
        <HistoricalTable />
      </PricesContext.Provider>
    </DashboardMaster>
  )
}

export default PricesDetailsPage
