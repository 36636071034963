import axios from "axios";
import { environment } from "../../config/environment";
const pdfDownloadURL = environment.SERVER_URL + '/api/reports/download-pdf';

async function downloadPDF (payload) {
    try {
        let config = {
            headers: {
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('auth_token'))
            }
          }
        const response = await axios.post(pdfDownloadURL, payload, config, {
            responseType: 'blob'
        })
        
        return response
    } catch (error) {
        console.error('Error:', error);
        return false
    }
}

export default downloadPDF