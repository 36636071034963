import { AdminUserActionType, apiBaseURL, toastifyType } from "../../../constants";
import { setLoading } from "../loadingAction";
import apiConfig from '../../../config/apiConfig';
import { addTost } from "../toastAction";

export const fetchUsers = (currentPage, setAllUsers) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.get(apiBaseURL.ADMIN_USER + "?page=" + currentPage);
        dispatch({ type: AdminUserActionType.FETCH_USERS, payload: response.data?.data});
        setAllUsers && setAllUsers(response.data?.data)
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const changeUserStatus = (status, checkedData) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.ADMIN_USER + "/change-users-status?status=" + status, checkedData);
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchUsers());
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};