export const getInitHTMLDimension = (data) => {
  const canvaHtml = document.getElementById('canvaHtml');
  const containerS = document.getElementById('containerS');
  containerS.innerHTML = data.data;
  const mainWidth = canvaHtml.offsetWidth;
  const width = (mainWidth <= containerS.offsetWidth) ? mainWidth : containerS.offsetWidth;
  const height = containerS.offsetHeight;

  // console.log("Width:", width);
  // console.log("Height:", height);
  return {
    width: String(width),
    height: String(height)
  }
}

export const getInitialData = (canvasData, type, data) => {
  return {
    type: type,
    id: `${type}__${Date.now()}__${canvasData?.length}`,
    position: {
      top: type === "CONTENT" ? 150 : 90,
      left: 0
    },
    dimension: (type === "TEXT" || type === "CONTENT") ? getInitHTMLDimension({ data: (data.title || data.content), type: type }) : {
      width: type === "TEXT" ? "50" : "150",
      height: type === "TEXT" ? "50" : "150"
    },
    content: (type === "TEXT" || type === "CONTENT") ? `<div style="padding: 3px 0px">${data.title || data.content || 'hello'}</div>` : ''
  };
};


export const getInitialAttachmentsData = (canvasData, type, data) => {
  return {
    type: type,
    id: `${type}__${Date.now()}__${canvasData?.length}`,
    position: data?.position ? data?.position : {
      top: 510,
      left: 30
    },
    dimension: {
      width: data.width,
      height: data.height
    },
    content: data.label,
    attachmentSavingData: { ...data }
  };
};

export const getInitialAttachmentsImage = (canvasData, type, data) => {
  if (data.isImage === true) {
    return {
      type: type,
      id: `${type}__${Date.now()}__${canvasData?.length}`,
      position: data?.position ? data?.position : {
        top: 510,
        left: 30
      },
      dimension: data?.dimension ? data?.dimension : {
        width: data.width,
        height: data.height
      },
      content: data.content,
      isImage: true,
      attachmentSavingData: { ...data }
    };
  }
  if (data.isImage === false) {
    return {
      type: type,
      id: `${type}__${Date.now()}__${canvasData?.length}`,
      position: data?.position ? data?.position : {
        top: 510,
        left: 30
      },
      dimension: {
        width: data.width,
        height: data.height
      },
      isImage: false,
      content: data.label,
      attachmentSavingData: { ...data }
    };
  }
};


export const getInitialCustomFiledData = (canvasData, type, data) => {
  return {
    type: type,
    id: `${type}__${Date.now()}__${canvasData?.length}`,
    position: data?.position ? data?.position : {
      top: 510,
      left: 1000
    },
    dimension: {
      width: data.width,
      height: data.height
    },
    content: data.label,
    customFiledSavingData: { ...data }
  };
};

export const getInitialTagData = (canvasData, type, data) => {
  return {
    type: type,
    id: `${type}__${Date.now()}__${canvasData?.length}`,
    position: data?.position ? data?.position : {
      top: 510,
      left: 1000
    },
    dimension: {
      width: data.width,
      height: data.height
    },
    content: data.label,
    customTagSavingData: { ...data }
  };
};


export const getInitialDividerData = (canvasData, type, IsHorizontal, data) => {
  return {
    type: type,
    id: `${type}__${Date.now()}__${canvasData?.length}`,
    position: data?.position ? data?.position : {
      top: 510,
      left: 1000
    },
    dimension: IsHorizontal ? {
      width: 500,
      height: 6
    } : {
      width: 6,
      height: 500
    },
    content: `<div style="width:2px;height:100%;background:#8f8f8f"></div>`,
    customDividerSavingData: { ...data }
  };
};