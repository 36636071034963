
import { apiBaseURL } from '../../../../constants';
import apiConfig from '../../../../config/apiConfig';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const SelectDataSelect = (props) => {
  const { onSelectIData, id, isDisabled, group, value } = props;
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hseMore, setHseMore] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    fetchData(searchVal);
  }, []);

  useEffect(() => {
    if (searchVal !== '') {
      setPage(1);
      setHseMore(true);
      setData([]);
      fetchData(searchVal);
    }
  }, [searchVal]);

  const fetchData = async () => {
    try {
      if (hseMore && id) {
        setLoading(true);
        const response = await apiConfig.get(
          apiBaseURL.SENTRY +
          "/data-set?page=" +
          page +
          "&perPage=" +
          5 +
          "&name=" +
          searchVal +
          "&group=" +
          group +
          "&id=" +
          id
        );
      //   {
      //     "title": "Price - Open",
      //     "value": "open",
      //     "group": "Assets",
      //     "fieldId": null
      // },
        const options = response.data?.data?.records?.length >= 1
          ? response.data?.data?.records?.map((item) => {
            return { value: item.value, label: item.title, slug: item.value, group: item.group, fieldId: item.fieldId };
          })
          : [];
        setData(prevData => [...prevData, ...options]);
        setHseMore(response.data?.data?.totalPage === 0 ? false : page === response.data?.data?.totalPage ? false : response.data?.data?.records?.length === 0 ? false : true)
        setPage(prevPage => prevPage + 1);
        // setPage(pageNum + 1); // Always increment page number when fetching more data
        response.data?.data?.records && setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching assets:", error);
    }

  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    onSelectIData(item)
    // You can perform any other action when an item is selected
  };
  // handleScroll
  // h-[113px] overflow-auto
  return (
    <div className="custom-dropdown " onScroll={null}>
      <Select
        options={data}
        onChange={handleSelectItem}
        className="react_selector"
        value={value}
        onInputChange={(value) => {
          setSearchVal(value)
        }}
        isLoading={loading}
        onMenuScrollToBottom={() => fetchData(searchVal)}
        onScroll={() => fetchData(searchVal)}
        isDisabled={isDisabled}
        onMenuClose={() => {
          setPage(1);
          setHseMore(true);
          setData([]);
        }}
        onMenuOpen={() => fetchData(searchVal)}
        placeholder="Select an option"
        styles={{
          groupHeading: (base) => ({
            color: "black",
          }),
          menuList: (base) => ({
            fontSize: "0.75rem",
            overflow: "auto",
            maxHeight: "180px",
            lineHeight: '1rem',
            fontWeight: 500,
            borderRadius: '5px',
          }),
          container: (base, { isSelected, isFocused }) => ({
            ...base,
            ":focus": {
              fontSize: "0.75rem",
            }
          }),
          option: (base, { isSelected, isFocused }) => ({
            ...base,
            cursor: "pointer",
            borderRadius: '5px',
            margin: "0px 5px",
            backgroundColor: isSelected ? "rgb(215 235 255 / 1)" : "transparent", // Change background color of the active item
            color: isSelected ? "hsla(210, 100%, 12%, 1)" : "hsla(210, 100%, 12%, 1)", // Change color of the active item
            ":hover": {
              backgroundColor: isFocused ? "#d7ebff" : "transparent", // Change background color when hovered
            },
            ":focus": {
              fontSize: "0.75rem",
            }
          }),
        }}
      />
    </div>
  );
};

export default SelectDataSelect;

