import { financePriceActionType } from '../../constants';

const financePriceReducer = (state = [], action) => {
    switch (action.type) {
        case financePriceActionType.FETCH_FINANCE_PRICE_V2:
            return action.payload;
        case financePriceActionType.GET_FINANCE_PRICE_V2:
            return action.payload;
        default:
            return state;
    }
};
export default financePriceReducer