import { apiBaseURL, portfolioColumnActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { setUpdateSate } from "./updateStateAction";
import { addTost } from "./toastAction";



export const fetchPortfolioColumn = (section) => async (dispatch) => {
    dispatch(setUpdateSate(true))
    try {
        const response = await apiConfig.get(apiBaseURL.VIEWS+ '/' + section + '/active');
        dispatch({ type: portfolioColumnActionType.FETCH_PORTFOLIOS_COLUMN, payload: response.data });
        dispatch(setLoading(false));
        dispatch(setUpdateSate(false))
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(setUpdateSate(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const addPortfolioColumn = (data) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.PORTFOLIOS_COLUMN, data);
        //   dispatch(fetchPortfolioColumn())
        dispatch({ type: portfolioColumnActionType.FETCH_PORTFOLIOS_COLUMN, payload: response.data?.data });
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};


export const fetchAllocationColumn = (section) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.VIEWS+ '/' + section + '/active');
        dispatch({ type: portfolioColumnActionType.FETCH_ALLOCATION_COLUMN, payload: response.data });
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};


export const fetchPortfolioGroupInvestedAssetColumn = (section) => async (dispatch) => {
    // dispatch(setUpdateSate(true))
    try {
        const response = await apiConfig.get(apiBaseURL.VIEWS+ '/' + section + '/active');
        dispatch({ type: portfolioColumnActionType.FETCH_PORTFOLIOS_GROUP_COLUMN, payload: response.data });
        dispatch(setLoading(false));
        dispatch(setUpdateSate(false))
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(setUpdateSate(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};


export const fetchPortfolioGroupAllocationColumn = (section) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.VIEWS+ '/' + section + '/active');
        dispatch({ type: portfolioColumnActionType.FETCH_ALLOCATION_GROUP_COLUMN, payload: response.data });
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};