import React, { useEffect } from 'react'
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import IconMaintanance from '../../../../components/svg/IconMaintanance';
import IconAsset from '../../../../components/svg/IconAsset';
import IconReportingNew from '../../../../components/svg/IconReportingNew';
import AssetContent from '../tabs-content/AssetContent';
import AssetsAuthoriseContent from '../tabs-content/AssetsAuthoriseContent';
import AssetRemovalContent from '../tabs-content/AssetRemovalContent';
import AssetActionsContent from '../tabs-content/AssetActionsContent';
import { useDispatch } from 'react-redux';
import { fetchAllPortfolios } from '../../../../store/actions/portfolioAction';

const AssetTabs = (props) => {
    const { keyValue, handleTabChange,currentPage,setCurrentPage } = props;
    const dispatch = useDispatch();
    useEffect(() => {
        if(keyValue === 'Authorise Assets'){
            // dispatch(fetchAllPortfolios())
        }
    }, [])
    return (
        <div className="">
            <div className="sub-tabs-wrapper">
            <Tabs defaultValue={keyValue} value={keyValue} className="w-[full]" onValueChange={handleTabChange}>
                {/* <Tabs defaultValue={'Asset Details'} className="w-[full]" > */}
                    <div className="mb-8">
                        <TabsList className="inline-flex items-center hidden-scroll rounded-none justify-start bg-[unset] shadow-none p-0 gap-8 overflow-y-auto border-b border-[#C9C9C9]">
                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset Details"><span><IconMaintanance className="w-4 h-4" /></span> Asset Details</TabsTrigger>
                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Authorise Assets" ><span><IconAsset className="w-4 h-4" /></span> Authorise Assets</TabsTrigger>
                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset Removal Details"><span><IconReportingNew className="w-4 h-4" /></span> Asset Removal Details</TabsTrigger>
                            <TabsTrigger className="!bg-transparent pt-0 !text-[#6C7B90] data-[state=active]:!text-theme text-sm relative before:absolute before:bottom-0 before:bg-theme before:h-[1.5px] px-2 data-[state=active]:before:w-full data-[state=active]:before:start-0  before:w-0 before:start-1/2  before:transition-all before:duration-300 gap-2" value="Asset Actions"><span><IconReportingNew className="w-4 h-4" /></span> Asset Actions</TabsTrigger>
                        </TabsList>
                    </div>
                    <TabsContent value="Asset Details" className="m-0">
                        <AssetContent currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </TabsContent>
                    <TabsContent value="Authorise Assets">
                        <AssetsAuthoriseContent />
                    </TabsContent>
                    <TabsContent value="Asset Removal Details">
                        <AssetRemovalContent />
                    </TabsContent>
                    <TabsContent value="Asset Actions">
                        <AssetActionsContent />
                    </TabsContent>
                </Tabs> 
            </div>
        </div>
    )
}

export default AssetTabs