
import { Filters, apiBaseURL, strategyActionType, toastifyType } from "../../constants";
import apiConfig from '../../config/apiConfig';
import { setLoading } from "./loadingAction";
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const fetchStrategy = (include, filter) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const response = await apiConfig.get(apiBaseURL.STRATEGY + `?type=${include ? include : ''}&page=${filter.page}&perPage=${filter.perPage}`)
    dispatch({ type: strategyActionType.FETCH_STRATEGY, payload: response.data?.data });
    dispatch(metaDataAction({ pageNumber: response.data?.data?.pageNumber || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
    dispatch(setLoading(false))
  } catch (error) {
    dispatch(setLoading(false))
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
  }
}

export const fetchStrategyAll = (include) => async (dispatch) => {
  dispatch(setLoading(true))
  try {
    const response = await apiConfig.get(apiBaseURL.STRATEGY + `?type=${include ? include : ''}`)
    dispatch({ type: strategyActionType.FETCH_STRATEGY, payload: response.data?.data });
    dispatch(setLoading(false))
  } catch (error) {
    dispatch(setLoading(false))
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
  }
}


export const addStrategy = (data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.post(apiBaseURL.STRATEGY, data);
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchStrategy('', Filters.OBJ));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const deleteStrategy = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.delete(apiBaseURL.STRATEGY + "/" + id);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchStrategy('', Filters.OBJ));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};

export const updateStrategy = (id, data) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await apiConfig.put(apiBaseURL.STRATEGY + '/' + id, data);
    dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
    dispatch(fetchStrategy('', Filters.OBJ));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
    console.error('error', error);
  }
};