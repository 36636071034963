import { Filters, apiBaseURL, portfoliosGroupActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import { fetchPortfolios } from "./portfolioAction";
import { addTost } from "./toastAction";
import apiConfig from "../../config/apiConfig"
import { metaDataAction } from "./metaDataAction";

export const fetchPortfolioGroups = () => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConfig.get(apiBaseURL.PORTFOLIOS_GROUP);
      // const response = await apiConfig.get(apiBaseURL.PORTFOLIOS_GROUP);
      dispatch({ type: portfoliosGroupActionType.FETCH_PORTFOLIOS_GROUPS, payload: response.data?.data });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };

  export const fetchPortfolioGroup = (slug, filter) => async (dispatch) => {
    try {
      const paginationData = filter ? `?page=${filter.page}&perPage=${filter.perPage}` : ''
      const response = await apiConfig.get(apiBaseURL.PORTFOLIOS_GROUP+ '/' + slug + '/portfolios' +  paginationData);
      dispatch({ type: portfoliosGroupActionType.FETCH_PORTFOLIO_GROUP, payload: response.data?.data });
      dispatch(metaDataAction({ pageNumber: response.data?.data?.pageNumber || 1, pageSize: response.data?.data?.pageSize || 10, totalItems: response.data?.totalRecords }))
      dispatch(setLoading(false));
      // dispatch(fetchPortfolioGroupsAsset(response.data?.data[0]?.portfolioGroup?._id))
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };

export const addPortfolioGroup = (data, currentPage, slug) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConfig.post(apiBaseURL.PORTFOLIOS_GROUP, data);
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
      dispatch(fetchPortfolios(currentPage))
      dispatch(fetchPortfolioGroups());
      slug && dispatch(fetchPortfolioGroup(slug))
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };

  export const updatePortfolioGroup = (id, data, currentPage, slug) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConfig.put(apiBaseURL.PORTFOLIOS_GROUP, data);
      dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
      dispatch(fetchPortfolios(1));
      dispatch(fetchPortfolioGroups());
      slug && dispatch(fetchPortfolioGroup(slug))
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };

  export const deletePortfolioGroup = (id) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConfig.delete(apiBaseURL.PORTFOLIOS_GROUP + '/' + id);
      dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
      dispatch(fetchPortfolios(1))
      dispatch(fetchPortfolioGroups());
    } catch (error) {
      dispatch(setLoading(false));
     
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };


  export const fetchPortfolioGroupsAsset = (id) => async (dispatch) => {
    try {
      // dispatch(setLoading(true));
      const response = await apiConfig.get(apiBaseURL.PORTFOLIOS_GROUP+ '/'+id+'/assets');
      // const response = await apiConfig.get(apiBaseURL.PORTFOLIOS_GROUP);
      dispatch({ type: portfoliosGroupActionType.FETCH_PORTFOLIOS_GROUP_Asset, payload: response.data?.data });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };


  export const addGroupPortfolio = (data, currentPage, slug) => async (dispatch) => {
    try { 
      dispatch(setLoading(true));
      const response = await apiConfig.post(apiBaseURL.PORTFOLIOS_GROUP+'/add-portfolio', data);
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
      !slug && dispatch(fetchPortfolios(1));
      slug && dispatch(fetchPortfolioGroup(slug, Filters.OBJ))
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };