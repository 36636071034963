import apiConfig from '../../config/apiConfig';
import { apiBaseURL, toastifyType, viewsActionType } from '../../constants';
import { addTost } from './toastAction';


export const fetchCustomColumnsViews = (sectionName, currentPage, perPage, searchValue, setTotalRecords, setTotalPage, getObjId) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.VIEWS + '/fields', {
            section: sectionName,
            page: currentPage,
            perPage: perPage,
            name: searchValue,
            nin: getObjId
        })
        setTotalRecords && setTotalRecords(response.data?.data?.totalRecords)
        setTotalPage && setTotalPage(response.data?.data?.totalPage)
        dispatch({ type: viewsActionType.CUSTOM_FIELD, payload: response.data?.data });
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

