import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash'
import { fetchInvestedAssetById } from '../../../../store/actions/portfolioTransactionHistoryAction';
import { dateConvert } from '../../dateFormate';
import DataTableColumnHeader from '../../table-components/tasks/components/data-table-column-header';
import { DataTableWithCustomPagination } from '../../table-components/tasks/components/data-table-without-pagination';
import { Filters } from '../../../../constants';
import { Checkbox } from '../../../../components/ui/checkbox';

const SellAssetTransactionsTableContent = (props) => {
  const { transactionId, onCheckTransaction, fetchAssetData } = props;
  const { investedAssetHistory } = useSelector(state => state)
  const { id } = useParams();
  const [FetchInvestedAsset, setFetchInvestedAsset] = useState([])
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)

  // pagination
  const [currentPage, setCurrentPage] = useState(Filters.PAGE);
  const [perPage, setPerPage] = useState(5); // Default per page value
  const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
  const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)

  useEffect(() => {
    setFetchInvestedAsset([])
  }, [id])


  useEffect(() => {
    if (!_.isEmpty(fetchAssetData) ) {
      setFetchInvestedAsset([])
      setIsLoading(true)
      dispatch(fetchInvestedAssetById(setIsLoading, fetchAssetData?._id, {page: currentPage, perPage: perPage}))
    }
  }, [fetchAssetData, currentPage, perPage])

  useEffect(() => {
    setTotalRecords(investedAssetHistory?.totalRecords)
    setTotalPages(Math.ceil(investedAssetHistory?.totalRecords / perPage))
  }, [investedAssetHistory]);

  // const onChange = (filter) => {
    // if (!_.isEmpty(fetchAssetData)) {
    //   setFetchInvestedAsset([])
    //   setIsLoading(true)
    //   dispatch(fetchInvestedAssetById(setIsLoading, fetchAssetData?._id, filter))
    // }
  // }

  useEffect(() => {
    if (!_.isEmpty(fetchAssetData) && investedAssetHistory) {
      setFetchInvestedAsset(investedAssetHistory?.records)
      // setTotalRecords(investedAssetHistory?.totalRecords)
    }
  }, [investedAssetHistory])

  // Pagination functions
  const indexOfLastItem = currentPage * perPage;
  const indexOfFirstItem = indexOfLastItem - perPage;


  const data = Array.isArray(FetchInvestedAsset) ? FetchInvestedAsset.map((item, index) => {
    return {
      index: indexOfFirstItem + index + 1,
      id: item._id ? item._id : '',
      PurchaseDate: item?.purchaseDate ? dateConvert(item?.purchaseDate) : 'N/A',
      purchasePrice: item?.purchasePrice ? item?.purchasePrice : 0,
      quantity: item?.quantity ? item?.quantity : 0,
      ledgerId: item?.ledgerId ? item?.ledgerId : '-',
      Value: item?.quantity && item?.purchasePrice ? item?.purchasePrice * item?.quantity : 0,
    }
  }) : []


  const columns = [
    {
      id: "select",
      header: ({ table }) => (
          <>
              <Checkbox
                  checked={
                      table.getIsAllPageRowsSelected() ||
                      (table.getIsSomePageRowsSelected() && "indeterminate")
                  }
                  onCheckedChange={(value) => { table.toggleAllPageRowsSelected(!!value); onCheckTransaction(table.options, value, true, data) }}
                  aria-label="Select all"
                  className="translate-y-[2px] border-black rounded-[4px]"
              />
          </>
      ),
      cell: ({ row }) => (
          <Checkbox
              // checked={row.getIsSelected()}
              checked={transactionId.includes(row.original?.id)}
              // disabled={true}
              onCheckedChange={(value) => { row.toggleSelected(!!value); onCheckTransaction(row.original, value, false, data) }}
              aria-label="Select row"
              className="translate-y-[2px] border-black rounded-[4px]"
          />
      ),
      enableSorting: false,
      enableHiding: false,
  },
    {
      accessorKey: "Id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Id" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.index}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "ledgerId",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="ledger Id" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.ledgerId}</span>
      },
      enableSorting: true,
      
    },
    {
      accessorKey: "Purchase Date",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Purchase Date" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.PurchaseDate}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Purchase Price",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Purchase Price" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.purchasePrice}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Quantity",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Quantity" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.quantity}</span>
      },
      enableSorting: true,

    }, {
      accessorKey: "Value",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Value" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.Value}</span>
      },
      enableSorting: true,

    },
  ]
  return (
    <div className=''>
    <DataTableWithCustomPagination data={data} columns={columns} filterName={'PurchaseDate'} loadData={isLoading} isCollViewHide={true} isSearchHide={true}
    setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}
    />
    </div>
  )
}

export default SellAssetTransactionsTableContent