import React from 'react'

const IconMoon = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2007_2123)">
    <path d="M39.0266 22.7817C38.6174 22.6794 38.2083 22.7817 37.8502 23.0374C36.5203 24.1627 34.9859 25.0834 33.298 25.6972C31.7123 26.311 29.9733 26.6178 28.1319 26.6178C23.9888 26.6178 20.2038 24.9299 17.4929 22.219C14.782 19.5081 13.0941 15.7231 13.0941 11.5801C13.0941 9.84099 13.401 8.15308 13.9125 6.61861C14.4751 4.98185 15.2935 3.49853 16.3677 2.2198C16.828 1.65716 16.7257 0.838782 16.1631 0.378442C15.805 0.122697 15.3958 0.0203991 14.9866 0.122697C10.639 1.29912 6.85396 3.90772 4.14306 7.38585C1.53447 10.8128 0 15.0582 0 19.6616C0 25.2368 2.25055 30.3006 5.93328 33.9833C9.616 37.666 14.6286 39.9166 20.255 39.9166C24.9607 39.9166 29.3083 38.2798 32.7865 35.5689C36.3157 32.8069 38.8732 28.8684 39.9473 24.3673C40.1519 23.6512 39.7427 22.9351 39.0266 22.7817Z" fill="currentcolor"/>
    </g>
    <defs>
    <clipPath id="clip0_2007_2123">
    <rect width="40" height="40" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default IconMoon