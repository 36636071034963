
import React from 'react'
// import AdminUserTable from './AdminUserTable'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import AdminUserData from './AdminUserData'

const AdminUser = () => {
    return (
        <DashboardMaster>
            {/* <AdminUserTable /> */}
            <AdminUserData />
        </DashboardMaster>
    )
}

export default AdminUser
