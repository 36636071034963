import React, { useEffect } from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import { useNavigate } from 'react-router-dom';

const Data = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/data/asset');
  },[])
  return (
    <DashboardMaster>Data</DashboardMaster>
  )
}

export default Data