import { adminClassActionType } from '../../../constants';

const adminClassReducer = (state = [], action) => {
    switch (action.type) {
        case adminClassActionType.FETCH_CLASSES:
            return action.payload;
        case adminClassActionType.FETCH_CLASS:
            return action.payload;
        case adminClassActionType.ADD_CLASS:
            return action.payload;
        case adminClassActionType.UPDATE_CLASS:
            return action.payload;
        case adminClassActionType.DELETE_CLASS:
            return action.payload;
        default:
            return state;
    }
};
export default adminClassReducer