import {
  faDiagramNext,
  faEdit,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { SettingBtnContextNew } from "../MultipleViewSetting";
import MultipleViewFilterColWithPackage from "./MultipleViewFilterColWithPackage";
import createPortfolioColumnsOp from "../../../columnsOption/createPortfolioColumnsOp";
import createAlloctionColumnsOp from "../../../columnsOption/createAlloctionColumnsOp";
import {
  activeColumnsViews,
  addColumnsViews,
  deleteColumnsViews,
  fetchColumnsView,
} from "../../../../store/actions/ColumnsViewsActine";
import { fetchCustomColumnsViews } from "../../../../store/actions/CustomColumnsViewsAction";
import { addTost } from "../../../../store/actions/toastAction";
import { toastifyType } from "../../../../constants";
import { DataTableWithCustomPagination } from "../../table-components/tasks/components/data-table-without-pagination";
import DataTableColumnHeader from "../../table-components/tasks/components/data-table-column-header";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown";
import {
  DotsHorizontalIcon,
  Pencil2Icon,
  TrashIcon,
} from "@radix-ui/react-icons";
import IconAdd from "../../../../components/svg/IconAdd";
import { Input } from "@/components/ui/input";
import AddListPopup from "./AddListPopup";
import { CheckboxNew } from "../../../../components/ui/CheckboxNew";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Label } from "../../../../components/ui/label";

const MultipleViewListModal = () => {
  const {
    showViewModal,
    setShowViewModal,
    handleCloseModal,
    sectionType,
    setCurrentPage,
    setSearchValue,
    setTotalRecords,
    setTotalPage,
    totalPageTwo,
    currentPageTwo,
    setCurrentPageTwo,
    totalRecordsTow,
    perPageTwo,
    setTotalRecordsTwo,
    setTotalPageTow,
    setPerPageTwo,
    setIsAdd,
  } = useContext(SettingBtnContextNew);
  const { columnsViews, columnsViewDetails } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeList, setActiveList] = useState([]);
  const [newActiveList, setNewActiveList] = useState([]);
  const [defaultActive, setDefaultActive] = useState({});
  const [data, setData] = useState([]);
  const [addListData, setAddListData] = useState({});
  const [editData, setEditData] = useState({});
  const [editColumns, setEditColumns] = useState({});
  const [addColViewRow, setAddColViewRow] = React.useState({});
  const [isUpdateSate, setIsUpdateSate] = useState(false);
  const [isResat, setIsResat] = useState(false);

  useEffect(() => {
    if (showViewModal === false) {
      setData([]);
      setActiveList([]);
      setEditData({});
      setEditColumns({});
      setDefaultActive({});
      setSearchValue("");
      setAddListData({});
      setAddColViewRow({});
      setNewActiveList({});
    }
  }, [!showViewModal]);

  useEffect(() => {
    setActiveList([]);
    setEditData({});
    setEditColumns({});
    setDefaultActive({});
    setSearchValue("");
    setAddListData({});
    setAddColViewRow({});
    setNewActiveList({});
  }, [currentPageTwo]);

  useEffect(() => {
    if (columnsViews?.records) {
      setData(columnsViews.records);
      setActiveList([]);
      // if (isRender) {
      //     console.log('sdf',)
      //     const getObj = columnsViews?.records?.filter((item) => item.active === true)[0]
      //     dispatch(fetchColumnsView(getObj._id, setLoading, setTotalRecords, setTotalPage))
      //     setIsRender(false)
      // }
    }
  }, [columnsViews?.records]);

  useEffect(() => {
    // setData(portfolioCmOp)
    // const getObj = portfolioCmOp.filter((item) => item.isActive === true)[0]
    if (data.length && columnsViewDetails) {
      // Find the index of the object in data1 with the matching _id
      const index = data.findIndex(
        (item) => item._id === columnsViewDetails._id
      );
      // If a matching object is found, replace it with the object from data2
      if (index !== -1) {
        data[index] = columnsViewDetails;
      }
      setData([...data]);
      setDefaultActive(columnsViewDetails);
      setActiveList(columnsViewDetails);
    }
  }, [columnsViewDetails]);

  const onCheckBtn = (e, data) => {
    setEditData({});
    setEditColumns({});
    setDefaultActive(data);
    setActiveList(data);
    setNewActiveList(data);
    setLoading(true);
    dispatch(
      fetchColumnsView(data._id, setLoading, setTotalRecords, setTotalPage)
    );
  };

  const handleAddList = async () => {
    // setIsAdd(true)
    // setCurrentPageTwo(totalPageTwo)
    // await dispatch(fetchColumnsViews(sectionType, totalPageTwo, perPageTwo, setTotalRecordsTwo, setTotalPageTow))
    // await dispatch(fetchCustomColumnsViews(sectionType, 1, 10, '', setTotalRecords, setTotalPage, []))
    // if (!addListData.length) {
    setEditData({});
    setActiveList({});
    setDefaultActive({});
    setEditColumns({});

    if (
      sectionType === "investedAsset" ||
      sectionType === "portfolioGroupInvestedAsset"
    ) {
      setData((prevState) => [
        ...prevState,
        {
          ...createPortfolioColumnsOp,
          _id:
            createPortfolioColumnsOp._id *
            Math.floor(Math.random() * 100000000000000),
          columns: [],
        },
      ]);
    } else {
      setData((prevState) => [
        ...prevState,
        {
          ...createAlloctionColumnsOp,
          _id:
            createAlloctionColumnsOp._id *
            Math.floor(Math.random() * 100000000000000),
          columns: [],
        },
      ]);
    }

    // }
  };
  const [addListPopup, setAddListPopup] = useState(false);
  const handleAddListPopup = () => {
    setEditData({});
    setActiveList({});
    setDefaultActive({});
    setEditColumns({});
    setAddListPopup(true);
  };

  useEffect(() => {
    if (data.length) {
      const getAddObj = data.find((item) => item.isAdd === true);
      if (getAddObj && getAddObj.isAdd) {
        setNewActiveList({ ...getAddObj, columns: [] });
        setEditColumns({ ...getAddObj, columns: [] });
        setActiveList({ ...getAddObj, columns: [] });
        setDefaultActive({ ...getAddObj, columns: [] });
        setAddListData({ ...getAddObj, columns: [] });
        setAddColViewRow({ ...getAddObj, columns: [] });
        dispatch(
          fetchCustomColumnsViews(
            sectionType,
            1,
            10,
            "",
            setTotalRecords,
            setTotalPage,
            []
          )
        );
      }
    }
  }, [data.length]);

  const handleSaveList = (changeData) => {
    if (!_.isEmpty(changeData)) {
      const getObj = data.filter((item) => item._id === changeData._id)[0];
      const configData = data.map((item) => {
        if (item.isAdd && item.name !== "") {
          return { ...item, isAdd: null };
        } else {
          return { ...item };
        }
      });
      setData(configData);
      // Perform the transformation
      const transformedData = {
        name: getObj.name,
        section: sectionType,
        columns: getObj.columns.map((column, index) => {
          const { _id, name, description, code, type, active } = column;
          return { field: _id, index: index };
        }),
      };
      if (getObj.name === "" || getObj?.columns?.length === 0) {
        getObj.name === "" &&
          dispatch(
            addTost({
              type: toastifyType.SHOW_ERROR_MESSAGE,
              message: "Please Enter Field Name",
            })
          );
        getObj?.columns?.length === 0 &&
          dispatch(
            addTost({
              type: toastifyType.SHOW_ERROR_MESSAGE,
              message: "Please Select Columns",
            })
          );
      } else {
        let perTotalRecords;

        if (totalRecordsTow % perPageTwo === 0) {
          perTotalRecords = true;
        } else {
          perTotalRecords = false;
        }

        let page = perTotalRecords
          ? Math.ceil(totalRecordsTow / perPageTwo) + 1
          : Math.ceil(totalRecordsTow / perPageTwo);
        dispatch(
          addColumnsViews(
            sectionType,
            transformedData,
            "add",
            page,
            perPageTwo,
            setTotalRecordsTwo,
            setTotalPageTow
          )
        );
        setCurrentPageTwo(page);
        setAddColViewRow({});
        setActiveList({});
        setNewActiveList({});
        setEditColumns({});
        setDefaultActive({});
        setAddListData({});
      }
    }
  };

  const onChangeName = (e, changeData) => {
    const updateDataObj = data.map((item) => {
      if (item._id === changeData._id) {
        return {
          ...item,
          name: e.target.value,
        };
      } else {
        return { ...item };
      }
    });
    const objNameData = updateDataObj.find((val) => val.isAdd === true);
    setData(updateDataObj);

    if (objNameData && objNameData.isAdd) {
      e.target.value === ""
        ? setAddColViewRow({})
        : setAddColViewRow(objNameData);
      setNewActiveList({ ...objNameData });
      setEditColumns({ ...objNameData });
      setActiveList({ ...objNameData });
      setDefaultActive({ ...objNameData });
      setAddListData({ ...objNameData });
    } else {
      e.target.value === "" ? setAddColViewRow({}) : setAddColViewRow([]);
    }
  };

  const onChangeEditName = (e, changeData) => {
    const updateObj = data.map((item) => {
      if (item._id === changeData._id) {
        return {
          ...item,
          name: e.target.value,
        };
      } else {
        return { ...item };
      }
    });
    setEditData(updateObj.find((item) => item._id === editData._id));
    setData(updateObj);
  };

  const handleUpdateList = (editData, editColumns) => {
    if (_.isEmpty(addColViewRow)) {
      let changeData = !_.isEmpty(editData) ? editData : editColumns;
      // Perform the transformation
      const transformedData = {
        viewId: changeData._id,
        name: changeData.name,
        section: sectionType,
        columns: changeData.columns.map((column, index) => {
          const { _id, name, code, description, type, selected } = column;
          return { field: _id, index: index };
        }),
      };
      dispatch(
        addColumnsViews(
          sectionType,
          transformedData,
          "update",
          currentPageTwo,
          perPageTwo,
          setTotalRecordsTwo,
          setTotalPageTow
        )
      );
      setData(data);
      setEditData({});
      setEditColumns({});
    }
  };

  const deleteCreatedList = (changeData) => {
    const deleteObjData = data.filter((item) => item._id !== changeData._id);
    setData(deleteObjData);
    setAddColViewRow({});
    setNewActiveList({});
    setEditColumns({});
    setActiveList({});
    setDefaultActive({});
    setAddListData({});
    setIsResat(!isResat);
  };
  // const resatDeleteCreatedList = (changeData) => {
  //     setLoading(true)
  //     setActiveList(saveColumns)
  //     setDefaultActive(saveColumns)
  //     setEditData({})
  //     setEditColumns({})
  //     setLoading(false)
  // }

  const handleAction = (item, type) => {
    if (type === "Edit") {
      setEditData(item);
      setDefaultActive(item);
      setActiveList(item);
      setLoading(true);
      dispatch(
        fetchColumnsView(item._id, setLoading, setTotalRecords, setTotalPage)
      );
    } else if (type === "Delete") {
      const transformedData = {
        action: "delete",
        viewId: item._id,
      };
      const deleteObj = data.filter((items) => items._id !== item._id);
      setData(deleteObj);
      let page = data.length === 1 ? totalPageTwo - 1 : currentPageTwo;
      dispatch(
        deleteColumnsViews(
          sectionType,
          transformedData,
          page,
          perPageTwo,
          setTotalRecordsTwo,
          setTotalPageTow
        )
      );
      setCurrentPageTwo(page);
    } else if (type === "active") {
      const transformedData = {
        action: "active",
        viewId: item._id,
      };
      setActiveList([]);
      setDefaultActive({});
      dispatch(
        activeColumnsViews(
          sectionType,
          transformedData,
          currentPageTwo,
          perPageTwo,
          setTotalRecordsTwo,
          setTotalPageTow
        )
      );
      setAddColViewRow({});
    }
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => <></>,
      cell: ({ row }) => (
        <CheckboxNew
          className="w-4 h-4 rounded-[4px] bg-white"
          id="flexRadioDefault1"
          type="checkbox"
          name="flexRadioDefault"
          onCheckedChange={(e) => {
            onCheckBtn(e, row?.original);
            setIsUpdateSate((prevState) => false);
            setCurrentPage((prevState) => 1);
            setSearchValue((prevState) => "");
          }}
          checked={row?.original._id === defaultActive?._id}
          disabled={
            !_.isEmpty(editData) || addListData.isAdd !== row?.original.isAdd
          }
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "name",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return editData?._id === row?.original._id ? (
          <div className="mt-2">
            <Input
              type="text"
              className={"h-[30px] w-[50%]"}
              name="name"
              value={row?.original.name}
              onChange={(e) => onChangeEditName(e, row?.original)}
            />
          </div>
        ) : row?.original.isAdd ? (
          <div className="mt-2">
            <Input
              type="text"
              className={"h-[30px] w-[50%]"}
              name="name"
              value={row?.original.name}
              onChange={(e) => onChangeName(e, row?.original)}
            />
          </div>
        ) : (
          <div className="">{row?.original.name}</div>
        );
      },
      enableSorting: true,
    },
    {
      accessorKey: "active",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        return row?.original.active ? (
          <span class="badge bg-success">Active</span>
        ) : (
          "-"
        );
      },
      enableSorting: true,
    },
    {
      id: "actions",
      enableHiding: false,
      header: <span className="flex items-center justify-end">Action</span>,
      cell: ({ row }) => {
        return row?.original.isAdd ? (
          <div className="flex justify-end items-end  mt-2 me-3">
            {/* <button className='btn btn-soft-primary' disabled={!item.name.length} onClick={() => handleSaveList(item)} >Save</button> */}
            {/* <FontAwesomeIcon icon={faEdit} className='text-warning' /> */}
            <FontAwesomeIcon
              icon={faXmark}
              className="text-red ms-2 cursor-pointer"
              onClick={() => deleteCreatedList(row?.original)}
            />
          </div>
        ) : (
          <div className="flex items-center justify-end">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon
                    strokeWidth={"2.2"}
                    className="h-5 w-5 text-textV2"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className={`dropdown-item cursor-pointer ${row?.original.name === "Default"
                      ? "text-stone-400"
                      : "text-orange-400"
                    }`}
                  disabled={row?.original.name === "Default"}
                  onClick={() => handleAction(row?.original, "Edit")}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className={`ms-2 me-2  ${row?.original.name === "Default"
                        ? "text-stone-400"
                        : "text-orange-400"
                      }`}
                  />{" "}
                  Edit
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={`dropdown-item cursor-pointer ${row?.original.name === "Default"
                      ? "text-stone-400"
                      : "text-red"
                    }`}
                  disabled={row?.original.name === "Default"}
                  onClick={() => handleAction(row?.original, "Delete")}
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    className={`ms-2 me-2 ${row?.original.name === "Default"
                        ? "text-stone-400"
                        : "text-red"
                      }`}
                  />{" "}
                  Delete
                </DropdownMenuItem>
                {row?.original.active ? (
                  <DropdownMenuItem
                    className={`dropdown-item cursor-pointer text-green-400 `}
                    disabled={true}
                  >
                    <FontAwesomeIcon
                      icon={faDiagramNext}
                      className={`text-green-400 ms-2 me-2 `}
                    />{" "}
                    Active
                  </DropdownMenuItem>
                ) : (
                  <DropdownMenuItem
                    className={`dropdown-item cursor-pointer text-green-500`}
                    onClick={() => handleAction(row?.original, "active")}
                  >
                    <FontAwesomeIcon
                      icon={faDiagramNext}
                      className={`text-green-500 ms-2 me-2 `}
                    />{" "}
                    Active
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  // Pagination functions
  const indexOfLastItem = currentPageTwo * 5;
  const indexOfFirstItem = indexOfLastItem - 5;

  return (
    <>
      <Dialog
        open={showViewModal}
        onOpenChange={() => {
          handleCloseModal();
          setActiveList([]);
        }}
      >
        <DialogContent className="sm:max-w-[1200px]  w-[95%] gap-6">
          <DialogHeader>
            <DialogTitle className="text-start">Views</DialogTitle>
          </DialogHeader>
          <div className="dialog-body">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
              <div className="add-view-content">
                <div className="header"></div>
                <div className="">
                  <div className="w-full">
                    <div className="flex items-end pb-0 gap-4 justify-end">
                      <div className="flex items-center gap-2 hidden">
                        <Label htmlFor="name" className="block text-textV1 font-500 ">
                          Name:
                        </Label>
                        <Select>
                          <SelectTrigger className="w-[250px] text-xs h-[34px]">
                            <SelectValue placeholder="Select a source" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectItem value="apple" className="text-xs">source1</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <Button
                      className='hover:bg-[#F5F5F5]'
                        disabled={addListData?.isAdd}
                        onClick={handleAddListPopup}
                        size="sm"
                        variant="outline"
                      >
                        <IconAdd className="w-4 h-4" />
                        Add View
                      </Button>
                    </div>
                    <div className="rounded-sm">
                      <DataTableWithCustomPagination
                        data={data}
                        columns={columns}
                        isSearchHide={true}
                        isCollViewHide={true}
                        isPerPageHide={true}
                        setPerPage={setPerPageTwo}
                        perPage={perPageTwo}
                        totalRecords={totalRecordsTow}
                        currentPage={currentPageTwo}
                        setCurrentPage={setCurrentPageTwo}
                        totalPages={totalPageTwo}
                      />
                    </div>
                    <div className="">
                      <span className="text-[12px] mt-1">
                        <b>Note</b> : You can update the view list only when you
                        select one of the lists!
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="select-fields-drag-and-drop ">
                <div className="flex items-center  gap-2 justify-between draggable_main">
                  <MultipleViewFilterColWithPackage
                    data={data}
                    addColViewRow={addColViewRow}
                    isResat={isResat}
                    setAddColViewRow={setAddColViewRow}
                    isUpdateSate={isUpdateSate}
                    setIsUpdateSate={setIsUpdateSate}
                    setData={setData}
                    activeList={activeList}
                    defaultActive={defaultActive}
                    newActiveList={newActiveList}
                    setNewActiveList={setNewActiveList}
                    editData={editData}
                    setEditData={setEditData}
                    editColumns={editColumns}
                    setEditColumns={setEditColumns}
                  />
                </div>
              </div>
            </div>
          </div>
          <DialogFooter className={"!justify-end !gap-2"}>
            <div>
              <Button
                type="submit"
                size="sm"
                className="min-w-[100px] me-2"
                onClick={() => {
                  handleUpdateList(editData, editColumns);
                  handleSaveList(addColViewRow);
                }}
                disabled={
                  ((_.isEmpty(editData) && _.isEmpty(editColumns)) ||
                    _.isEmpty(activeList)) &&
                  _.isEmpty(addColViewRow)
                }
              >
                Save
              </Button>
              <Button
                type="submit"
                size="sm"
                className="min-w-[100px] hover:bg-[#F5F5F5]"
                variant="outline"
                onClick={() => {
                  handleCloseModal();
                  setActiveList([]);
                }}
              >
                Close
              </Button>
              {/* <Button variant="primary" className='ms-2' onClick={handleSaveModal}>
                        Save View
                    </Button> */}
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <AddListPopup
        show={addListPopup}
        onHide={() => {
          setAddListPopup(false);
          setShowViewModal(true);
        }}
        sectionType={sectionType}
        totalRecordsTow={totalRecordsTow}
        perPageTwo={perPageTwo}
        setTotalRecordsTwo={setTotalRecordsTwo}
        setTotalPageTow={setTotalPageTow}
        setCurrentPageTwo={setCurrentPageTwo}
        setAddColViewRow={setAddColViewRow}
        setActiveList={setActiveList}
        setNewActiveList={setNewActiveList}
        setEditColumns={setEditColumns}
        setDefaultActive={setDefaultActive}
        setAddListData={setAddListData}
      />
    </>
  );
};

export default MultipleViewListModal;
// {addListData.length ? addListData.map((item, index) => {
//     return (
//         <tr>
//             <td className="fs--1 align-middle">
//                 <div className="form-check mb-0 fs-0 ms-2">
//                     <input
//                         className="form-check-input"
//                         id="flexRadioDefault1" type="checkbox"
//                         name="flexRadioDefault"
//                         disabled
//                         // onChange={(e) => onCheckBtn(e, item)}
//                         checked={item._id === defaultActive._id}
//                     />
//                 </div>
//             </td>
//             <td >
//                 <div className='mt-2'>
//                     <input type='text' name='name' value={item.name} onChange={(e) => onChangeName(e, item)} />
//                 </div>
//             </td>
//             <td></td>
//             <td>
//                 <div className='d-flex justify-content-center align-items-center mt-2'>
//                     {/* <button className='btn btn-soft-primary' disabled={!item.name.length} onClick={() => handleSaveList(item)} >Save</button> */}
//                     {/* <FontAwesomeIcon icon={faEdit} className='text-warning' /> */}
//                     <FontAwesomeIcon icon={faXmark} className='text-danger ms-2 cursor-pointer' onClick={() => deleteCreatedList(item)} />
//                 </div>
//             </td>
//         </tr>
//     )
// }) : ''}

// <Modal show={showViewModal} onHide={() => { handleCloseModal(); setActiveList([]) }}
// size="xl"
// aria-labelledby="contained-modal-title-vcenter"
// centered>
// <Modal.Header closeButton>
//     <Modal.Title>
//         <h4 class="card-title mb-0">Views</h4>
//         {/* <div className='text-start mb-2'>
//         <Button variant="primary" onClick={handleAddList}>
//             Add List
//         </Button>
//     </div> */}
//     </Modal.Title>
// </Modal.Header>
// <Modal.Body>
//     <div className="list_view_table">
//         <div className='mb-3'>
//             {/* <span className='fs-2 fw-bold'>View Lists :</span> */}
//         </div>
//         <div className='row'>
//             <div className='col-12'>
//                 <table className='table table-sm fs--1 mb-0'>
//                     <thead>
//                         <tr>
//                             <th
//                                 className="white-space-nowrap  align-middle p-0"
//                             // style={{ maxWidth: 20, width: 20 }}
//                             >
//                                 <div className=" mb-0  p-0">
//                                     {/* Active */}
//                                 </div>
//                             </th>
//                             <th>Name</th>
//                             <th></th>
//                             <th className='text-end pe-4'>Action</th>
//                             {/* <th>Columns</th>
//                 <th>Action</th> */}
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {/* <tr> */}
//                         {data.map((item) => {
//                             return (
//                                 <tr key={item._id}>
//                                     <td className="fs--1 align-middle">
//                                         <div className="form-check mb-0 fs-0 ms-2">
//                                             <input
//                                                 className="form-check-input"
//                                                 id="flexRadioDefault1" type="checkbox"
//                                                 name="flexRadioDefault"
//                                                 onChange={(e) => {
//                                                     onCheckBtn(e, item); setIsUpdateSate((prevState) => false);
//                                                     setCurrentPage((prevState) => 1); setSearchValue((prevState) => '')
//                                                 }}
//                                                 checked={item._id === defaultActive?._id}
//                                                 disabled={!_.isEmpty(editData) || addListData.isAdd !== item.isAdd}
//                                             />
//                                         </div>
//                                     </td>
//                                     <td>
//                                         {editData?._id === item._id ?
//                                             <div className='mt-2'>
//                                                 <input type='text' name='name' value={item.name} onChange={(e) => onChangeEditName(e, item)} />
//                                             </div>
//                                             : item.isAdd ? <div className='mt-2'>
//                                                 <input type='text' name='name' value={item.name} onChange={(e) => onChangeName(e, item)} />
//                                             </div> : <div className='mt-2  text-1000'>{item.name}</div>
//                                         }

//                                     </td>
//                                     <td className='text-center'>
//                                         {item.active ? (
//                                             <span class="badge bg-success">Active</span>
//                                         ) :
//                                             (
//                                                 ''
//                                                 // <span class="badge bg-danger">Deactivate</span>
//                                             )}
//                                     </td>
//                                     {item.isAdd ? (
//                                         <td>
//                                             <div className='d-flex justify-content-end align-items-end  mt-2 me-3'>
//                                                 {/* <button className='btn btn-soft-primary' disabled={!item.name.length} onClick={() => handleSaveList(item)} >Save</button> */}
//                                                 {/* <FontAwesomeIcon icon={faEdit} className='text-warning' /> */}
//                                                 <FontAwesomeIcon icon={faXmark} className='text-danger ms-2 cursor-pointer' onClick={() => deleteCreatedList(item)} />
//                                             </div>
//                                         </td>
//                                     ) :
//                                         <td>
//                                             <div className='d-flex justify-content-end align-items-end me-4'>
//                                                 <div className="font-sans-serif btn-reveal-trigger text-start position-static">
//                                                     <button
//                                                         className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs--2"
//                                                         type="button"
//                                                         data-bs-toggle="dropdown"
//                                                         data-boundary="window"
//                                                         aria-haspopup="true"
//                                                         aria-expanded="false"
//                                                         data-bs-reference="parent"
//                                                     >
//                                                         <svg
//                                                             className="svg-inline--fa fa-ellipsis fs--2"
//                                                             aria-hidden="true"
//                                                             focusable="false"
//                                                             data-prefix="fas"
//                                                             data-icon="ellipsis"
//                                                             role="img"
//                                                             xmlns="http://www.w3.org/2000/svg"
//                                                             viewBox="0 0 448 512"
//                                                             data-fa-i2svg=""
//                                                         >
//                                                             <path
//                                                                 fill="currentColor"
//                                                                 d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"
//                                                             />
//                                                         </svg>
//                                                     </button>
//                                                     <div className="dropdown-menu dropdown-menu-end py-2">
//                                                         <button className={`dropdown-item  ${item.name === 'Default' ? "text-soft" : 'text-warning'}`} disabled={item.name === 'Default'} onClick={() => handleAction(item, 'Edit')} >
//                                                             <FontAwesomeIcon icon={faEdit} className={`ms-2 me-2  ${item.name === 'Default' ? "text-soft" : 'text-warning'}`} /> Edit
//                                                         </button>
//                                                         <button className={`dropdown-item ${item.name === 'Default' ? "text-soft" : 'text-danger'}`} disabled={item.name === 'Default'} onClick={() => handleAction(item, 'Delete')} >
//                                                             <FontAwesomeIcon icon={faTrash} className={`ms-2 me-2 ${item.name === 'Default' ? "text-soft" : 'text-danger'}`} /> Delete
//                                                         </button>
//                                                         {item.active ? (
//                                                             <button className={`dropdown-item text-soft-success `} disabled={true} >
//                                                                 <FontAwesomeIcon icon={faDiagramNext} className={`text-soft-success ms-2 me-2 `} /> Active
//                                                             </button>
//                                                         ) : (
//                                                             <button className={`dropdown-item text-success`} onClick={() => handleAction(item, 'active')}>
//                                                                 <FontAwesomeIcon icon={faDiagramNext} className={`text-success ms-2 me-2 `} /> Active
//                                                             </button>
//                                                         )}
//                                                         {/* <button className={`dropdown-item ${item.active === defaultActive?.active ? 'text-soft-success' : 'text-success'}  `} disabled={item.active === defaultActive?.active} >
//                                             <FontAwesomeIcon icon={faDiagramNext} className={`${item._id === defaultActive?._id ? 'text-soft-success' : 'text-success '} ms-2 me-2 `} /> Active
//                                         </button> */}
//                                                     </div>
//                                                 </div>
//                                                 {/* {(editData?._id === item._id || editColumns?._id === item._id) && (<div className='font-sans-serif text-start cursor-pointer' > */}
//                                                 {/* <FontAwesomeIcon icon={faCheck} className='text-success fs-1' /> */}
//                                                 {/* <Button className='btn btn-soft-primary p-2' onClick={() => handleUpdateList(item)}> */}
//                                                 {/* Save */}
//                                                 {/* </Button> */}
//                                                 {/* <FontAwesomeIcon icon={faXmark} className='text-danger ms-2 cursor-pointer' onClick={() => resatDeleteCreatedList(item)} /> */}
//                                                 {/* </div>)} */}
//                                             </div>
//                                         </td>
//                                     }

//                                 </tr>
//                             )
//                         })}
//                     </tbody>
//                 </table>
//                 <div>
//                     <div id='tableExample3'>
//                         <div className="d-flex justify-content-between mt-1">
//                             <span className="d-none d-sm-inline-block mt-2" style={{ fontSize: '14px' }}>
//                                 {/* Display current and total records */}
//                                 {indexOfFirstItem + 1} <span className='text-600'>to</span> {Math.min(indexOfLastItem, totalRecordsTow)} <span className='text-600'>of</span> {totalRecordsTow} <span className='text-600'>Items</span>
//                             </span>
//                             <div className="d-flex">
//                                 {/* Pagination */}
//                                 <button className="bg-transparent border-0" onClick={() => {
//                                     setCurrentPageTwo(prev => Math.max(prev - 1, 1));
//                                     setDefaultActive({})
//                                 }}
//                                     // disabled={currentPageTwo === 1}
//                                     disabled={totalRecordsTow <= 5 || currentPageTwo === 1}
//                                 >
//                                     <span className="fas fa-chevron-left"></span>
//                                 </button>
//                                 <ul className="mb-0 pagination">
//                                     <li className={`page-item ${'active'}`}>
//                                         <button className="page-link" style={{ fontSize: '12px' }}>
//                                             {currentPageTwo}
//                                         </button>
//                                     </li>
//                                 </ul>
//                                 <button
//                                     className="bg-transparent border-0"
//                                     onClick={() => {
//                                         setCurrentPageTwo(prev => Math.min(prev + 1, Math.ceil(totalRecordsTow / perPageTwo))); setDefaultActive({})
//                                     }}
//                                     // disabled={currentPageTwo === Math.ceil(totalRecordsTow / 5)}
//                                     disabled={totalRecordsTow <= 5 || currentPageTwo === totalPageTwo}
//                                 >
//                                     <span className="fas fa-chevron-right"></span>
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className='d-flex justify-content-between align-items-start'>
//                     <div className='text-start mb-2 mt-2'>
//                         <Button className='btn btn-soft-primary' disabled={addListData?.isAdd} onClick={handleAddList}>
//                             + Add View
//                         </Button>
//                         <span className='ms-2 fs--1'><b>Note</b> : You can update the view list only when you select one of the lists!</span>
//                     </div>
//                     {/* <div className="text-center mt-3 me-5 pe-4">
//                     <button className="me-2 bg-transparent border-0 pg_btn" disabled={totalRecordsTow <= 5 || currentPageTwo === 1} onClick={() => {
//                         setCurrentPageTwo(prev => Math.max(prev - 1, 1));
//                         setDefaultActive({})
//                     }}><FontAwesomeIcon icon={faArrowLeft} /></button>
//                     <span className="bg-primary text-white px-2 py-1 rounded-5" style={{ fontSize: '12px' }} >{currentPageTwo}</span>
//                     <button className="ms-2 bg-transparent border-0 pg_btn" disabled={totalRecordsTow <= 5 || currentPageTwo === totalPageTwo}
//                         onClick={() => {
//                             setCurrentPageTwo(prev => Math.min(prev + 1, Math.ceil(totalRecordsTow / perPageTwo))); setDefaultActive({})
//                         }}><FontAwesomeIcon icon={faArrowRight} /></button>
//                 </div> */}
//                 </div>
//             </div>
//         </div>
//         <div className='mt-2'>
//             <MultipleViewFilterColWithPackage data={data} addColViewRow={addColViewRow} isResat={isResat} setAddColViewRow={setAddColViewRow} isUpdateSate={isUpdateSate} setIsUpdateSate={setIsUpdateSate} setData={setData} activeList={activeList} defaultActive={defaultActive} newActiveList={newActiveList} setNewActiveList={setNewActiveList} editData={editData} setEditData={setEditData} editColumns={editColumns} setEditColumns={setEditColumns} />
//         </div>

//     </div>
// </Modal.Body>
// <Modal.Footer className='d-flex justify-content-end align-items-center'>
//     <div>
//         <Button
//             className='btn btn-primary btn btn-primary me-3 '
//             onClick={() => { handleUpdateList(editData, editColumns); handleSaveList(addColViewRow) }}
//             disabled={(_.isEmpty(editData) && _.isEmpty(editColumns) || _.isEmpty(activeList)) && _.isEmpty(addColViewRow)}
//         >
//             Save
//         </Button>
//         <Button variant="secondary" onClick={() => { handleCloseModal(); setActiveList([]) }}>
//             Close
//         </Button>
//         {/* <Button variant="primary" className='ms-2' onClick={handleSaveModal}>
//         Save View
//     </Button> */}
//     </div>
// </Modal.Footer>
// </Modal>
