export const resizeHandleClasses = {
    bottom: "handle long-handle-horizontal bottom-handle",
    bottomLeft: "handle left-handle bottom-handle",
    bottomMiddle: "handle middle-handle middle-handle",
    bottomRight: "handle right-handle bottom-handle",
    left: "handle long-handle left-handle",
    right: "handle long-handle right-handle",
    top: "handle long-handle-horizontal top-handle",
    topLeft: "handle left-handle top-handle",
    topRight: "handle right-handle top-handle"
  };
  