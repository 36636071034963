import React, { useState } from 'react'
import FileUploader from '@/components/ui/FileUploader'
import { Button } from '@/components/ui/button'
import IconExport from '@/components/svg/IconExport'
import { useDispatch } from 'react-redux'
import { addUploadData } from '../../../../store/actions/BYODAction'
import { environment } from '../../../../config/environment'
const UploadManualData = () => {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch()

  const handleFileInputChange = (files) => {
    const selectedFiles = files && Array.from(files);
    files && handleFiles(selectedFiles);
  };

  const handleFiles = (fileList) => {
    const allowedFileTypes = ['.csv', '.svg', '.xlsx'];

    const invalidFiles = fileList.filter((file) => {
      const fileType = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
      return !allowedFileTypes.includes(`.${fileType.toLowerCase()}`);
    });

    if (invalidFiles.length > 0) {
      setErrorMessage('Invalid file type. Please upload only .CSV and .SVG files.');
      setFiles([]);
    } else {
      setErrorMessage('');
      setFiles(fileList);
    }
  };

  const onUpload = () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });

    dispatch(addUploadData(formData));
    setFiles([])

    // Reset the files directly after dispatching the action
    // setFiles((prevFiles) => {
    //   if (prevFiles.length > 0) {
    //     // Clear the file input value to allow selecting the same file again
    //     document.getElementById('fileInput').value = '';
    //   }
    //   return [];
    // });


  }

  const downloadEXP = async () => {
    // Create a temporary URL for the blob
    const blobUrl = environment.SERVER_URL + '/api/download/sample-template?name=custom_field_data_sample.xlsx';

    // Create a hidden anchor element
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = 'custom_field_data_sample';  // You can set the desired filename here
    document.body.appendChild(a);

    // Trigger a click on the anchor to start the download
    a.click();

    // Remove the anchor element
    document.body.removeChild(a);

    // Release the object URL
    URL.revokeObjectURL(blobUrl);
  }
  return (
    <div>
      <FileUploader onChange={(acceptedFiles) => handleFileInputChange(acceptedFiles)} supportedFileTypes={['.csv', '.svg']} labelText="Browse Documents" acceptedFiles={files} />
      {errorMessage && (
        <div className="text-red text-center mt-2 fs--1">{errorMessage}</div>
      )}
      <div className="flex items-center justify-center mt-4 gap-3">
        <Button className="underline w-[100px] h-[34px] hover:bg-[#F5F5F5]" size="sm" variant="outline" onClick={() => downloadEXP()}><span><IconExport className="w-4 h-4" /></span> Sample </Button>
        <Button className="w-[100px] h-[34px]" size="sm"  onClick={() => onUpload()}>Upload</Button>
      </div>
      <div className='text-[12px] text-center mt-3 text-yellow-600' >
        <span className='font-bold'>Note: </span><span className=''>Add Bulk Data At Your Own Risk.</span>
      </div>
    </div>
  )
}

export default UploadManualData
