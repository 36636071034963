import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import ReactQuill, { Quill } from "react-quill";
import { CanvasContext } from "../CanvasContainer";
import { fontList, sizeList } from "./Toolbar";
import "react-quill/dist/quill.snow.css";

const Size = Quill.import("attributors/style/size");
Size.whitelist = sizeList;

const Font = Quill.import("attributors/style/font");
Font.whitelist = fontList;

Quill.register(Font, true);
Quill.register(Size, true);

const AttachmentImageElement = (props) => {
    const { content, id, isImage, isReadOnly } = props;
    const { actions } = useContext(CanvasContext);
    const editorRef = React.useRef(null);

    const updateEditorValue = (value) => {
        actions?.updateCanvasData({ id, content: value });
    };

    const modules = {
        toolbar: "#toolbar"
    };
    const renderImage = () => {
        function encodeUrl(url) {
            return url
                .replace(/ /g, '%20')
                .replace(/\(/g, '%28')
                .replace(/\)/g, '%29');
        }
        return (
            <div className="image-container" key={id} style={{
                backgroundImage: `url(${encodeUrl(content)})`,
                backgroundSize: "contain",
                width: "100%",
                height: "100%",
                backgroundRepeat: "no-repeat"
            }}></div>


        );
    };

    // <img key={id} src={content} alt="image" style={{ height: '100%', width: '100%' }} />

    return <>{isImage ? renderImage() : <>
        <div>
            {isReadOnly ? (
                <div
                    className="ql-editor"
                    style={{
                        fontFamily: "Arial",
                        fontSize: "13px",
                        padding: 0,
                    }}
                >
                    {ReactHtmlParser(content || "")}
                </div>
            ) : (
                <ReactQuill
                    ref={editorRef}
                    readOnly={isReadOnly}
                    theme="snow"
                    className="quill-container"
                    modules={modules}
                    value={content}
                    onChange={updateEditorValue}
                />
            )}
        </div>
    </>}</>;
};

export default AttachmentImageElement;

{/* <img src={content} alt="image" style={{height: '100%', width: '100%'}} /> */ }
{/* <div
style={{
    backgroundImage: `url(${content})`,
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat"
}}
/> */}