import Router from './routes';
import { Routes, Route, Navigate } from "react-router-dom"
import "./assets/css/App.css"
import "./assets/css/Loading.css"
import "./assets/css/date_picker.css"
import "./assets/css/droppable.css"
import "./assets/css/react_selector.css"
import "./assets/css/text.css"
import "./assets/scss/index.scss"
import 'react-quill/dist/quill.snow.css';
import { Tokens } from './constants';
import PortfolioMain from "./pages/dashboard/portfolioMain/portfolioMain"
import Login from './pages/auth/Login';
import Register from './pages/auth/register';
import AdminUser from './pages/admin/User/AdminUser';

function App() {
  const token = localStorage.getItem(Tokens.ADMIN_TOKEN)
  const user = JSON.parse(localStorage.getItem(Tokens.ADMIN))
  const isToken = localStorage.getItem(Tokens.ADMIN_TOKEN);
  
  return (
    <>
      {/* <Routes>
        <Route path='/login' element={isToken !== null ? <Navigate replace to={'/'} /> : <Login />} />
        <Route path='/register' element={isToken !== null ? <Navigate replace to={'/'} /> : <Register />} />
        <Route path='/*' element={<Router />} />
        <Route path='*' element={<Navigate replace to={"/"} />} />
        <Route path='/' element={isToken !== null ? <PortfolioMain /> : <Navigate replace to={'/login'} />} />
      </Routes> */}
      <Routes>
        <Route path='/login' element={isToken !== null ? user === 'admin' ? <Navigate replace to={'/admin'} /> : <Navigate replace to={'/'} /> : <Login />} />
        <Route path='/register' element={token !== null ? <Navigate replace to={'/'} /> : <Register />} />
        <Route path='/forgot-password' element={token !== null ? <Navigate replace to={'/'} /> : <Register />} />

        {user?.role === 'user' ? <Route path='/' element={token !== null ? <PortfolioMain /> : <Navigate replace to={'/login'} />} /> :
          <Route path='/admin' element={token !== null ? <AdminUser /> : <Navigate replace to={'/login'} />} />}
        <Route path='/*' element={<Router />} />
        <Route path='*' element={<Navigate replace to={"/"} />} />
      </Routes>
    </>
  );
}

export default App;