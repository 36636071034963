
import { apiBaseURL, curveActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig'
// import apiConfigForIR from '../../config/apiConfigForIR'
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const getCurveByCountryCode = (countryId) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.FINANCE + "/curves?country=" + countryId)
        dispatch({ type: curveActionType.FETCH_CURVES, payload: response.data?.data });
        // dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage || 1, pageSize: 10, totalItems: response?.data?.data?.totalRecords }))
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}
