import { PortfolioTransactionActionType } from '../../constants';

const InvestedAssetReducer = (state = [], action) => {
    switch (action.type) {
        case PortfolioTransactionActionType.FETCH_INVESTED_ASSETS:
            return action.payload;
        default:
            return state;
    }
};
export default InvestedAssetReducer