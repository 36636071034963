import apiConfig from '../../config/apiConfig';
import { apiBaseURL, assetsFinanceSearchActionType, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { addTost } from './toastAction';

export const financeSearch = (data) => async (dispatch) => {
    try {
        const instrument = data.instrument === 'all' ? '' : data.instrument
        const response = await apiConfig.get(apiBaseURL.FINANCE_SEARCH + '?name='+ data.name + '&country='+ data.country + '&instrument=' + instrument + '&exchange=' + data.exchange )
        dispatch({ type: assetsFinanceSearchActionType.FETCH_FINANCE_SEARCH_V2, payload: response.data?.data?.records });
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const searchAsset = (value) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.ASSETS_DETAILS + '/search?name='+ value )
        return response.data?.data
        // dispatch({ type: assetsFinanceSearchActionType.FETCH_FINANCE_SEARCH_V2, payload: response.data?.data });
        // dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const searchAssetIssuer = (value ,group) => async (dispatch) => {
    try {
        const response = await apiConfig.get('/custom-field-data/search-asset-issuer?name=' + value + '&group=' + group )
        return response.data?.data
        // dispatch({ type: assetsFinanceSearchActionType.FETCH_FINANCE_SEARCH_V2, payload: response.data?.data });
        // dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}