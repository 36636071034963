import {
    apiBaseURL,
    chartDataActionType,
    reportsActionType,
    toastifyType,
} from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from "./../../config/apiConfig";
import { addTost } from "./toastAction";


// get All reports
export const getAllReports = (setChartData, setIsLoading, setIsLoadings) => async (dispatch) => {
    try {
        const response = await apiConfig.get(apiBaseURL.REPORTS)
        setChartData(response.data?.data)
        dispatch({
            type: chartDataActionType.FETCH_CHART_DATA,
            payload: response.data?.data,
        });
        setIsLoadings && setIsLoadings(false)
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(
            addTost({
                type: toastifyType.SHOW_ERROR_MESSAGE,
                message: error?.data?.message,
            })
        );
        setIsLoadings && setIsLoadings(false)
        setIsLoading && setIsLoading(false)
    }
    
   
};

// save reports
export const deleteReports = (id, setChartData) => async (dispatch) => {
    try {
        const response = await apiConfig.delete(apiBaseURL.REPORTS + "/" + id)
        // const { data: saveCharts } = response.data
        dispatch(getAllReports(setChartData))
        // setChartData(saveCharts)
    } catch (error) {
        dispatch(
            addTost({
                type: toastifyType.SHOW_ERROR_MESSAGE,
                message: error?.data?.message,
            })
        );
    }
};

export const fetchReports =
    (
        chartReport,
        setChartData,
        chartType,
        setIsLoading,
        isAutoChart,
        indicator,
        portfolioId,
        chartData,
        changeChartDate,
        isSaveData
    ) =>
        async (dispatch) => {
            try {
                const response = await apiConfig.post(apiBaseURL.REPORTS, chartReport)
                const { data } = response.data
                setIsLoading(true)
                if (changeChartDate) {
                    changeDate(data, chartData, setChartData, setIsLoading, dispatch, isSaveData)
                } else {
                    createChart(data, chartReport, chartData, setIsLoading, setChartData, chartType, isAutoChart, dispatch, isSaveData)
                }

            } catch (error) {
                dispatch(
                    addTost({
                        type: toastifyType.SHOW_ERROR_MESSAGE,
                        message: error?.data?.message,
                    })
                );
            }
        };


// create chart
const createChart = (data, chartReport, chartData, setIsLoading, setChartData, chartType, isAutoChart, dispatch, isSaveData) => {
    if (data.length) {
        let updateData = [];
        for (let index = 0; index < data.length; index++) {
            updateData.push({
                analytics: data[index].analytics,
                type: chartType,
                data: [data[index]],
                isAutoChart: isAutoChart,
                portfolio: chartReport[0].portfolio,
                chartId: data[index].chartId
            });
        }
        if (isSaveData) {
            setChartData([...chartData, ...data])
            setIsLoading && setIsLoading(false)
        } else {
            dispatch(getAllReports(setChartData, setIsLoading));
        }

        // const prepareData = [...updateData, ...chartData]
        // dispatch(saveReports(prepareData, setChartData));
    } else {
        const updateData = [{
            analytics: chartReport.reports[0].analytics,
            type: chartType,
            data: data,
            isAutoChart: isAutoChart,
            portfolio: chartReport.reports[0].portfolio,
            chartId: data.chartId
        }]
        if (isSaveData) {
            setChartData([...chartData, ...data])
            setIsLoading && setIsLoading(false)
        } else {
            dispatch(getAllReports(setChartData, setIsLoading));
        }

        // dispatch(getAllReports(setChartData, setIsLoading));
        // const prepareData = [...updateData, ...chartData]
        // dispatch(saveReports(prepareData, setChartData))
    }
}

const changeDate = (data, chartData, setChartData, setIsLoading, dispatch, isSaveData) => {
    const updateData =
        chartData &&
        chartData.length &&
        chartData.map((item, index) => {
            let oldIndex = index;
            item.data = data.filter((x, index) => index === oldIndex);
            return item;
        });

    if (isSaveData) {
        setChartData([...chartData, ...data])
        setIsLoading && setIsLoading(false)
    } else {
        dispatch(getAllReports(setChartData));
        // dispatch(saveReports(updateData, setChartData));
        setIsLoading && setIsLoading(false)
    }


    // dispatch(getAllReports(setChartData));
    // // dispatch(saveReports(updateData, setChartData));
    // setIsLoading(false);
}