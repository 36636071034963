import { portfoliosGroupActionType } from "../../constants";



const PortfolioGroupDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case portfoliosGroupActionType.FETCH_PORTFOLIO_GROUP:
            return action.payload;
        default:
            return state;
    }
};
export default PortfolioGroupDetailsReducer