import React, { createContext, useEffect, useState } from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import { Card } from '../../../components/ui/Card'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../components/ui/accordian'
import { Button } from '../../../components/ui/button'
import IconAdd from '../../../components/svg/IconAdd'
import WorldBankAnalyticsTable from './tables/WorldBankAnalyticsTable'
import { useDispatch } from 'react-redux'
import { addUserAnalytics, addUserUnitedNationsAnalytics } from '../../../store/actions/userAnalyticsAction'
import AnalyticsModel from './model/AnalyticsModel'
import { fetchGoals, fetchIndicatorSource } from '../../../store/actions/analyticsAction'
import { useSelector } from 'react-redux'
import { Filters } from '../../../constants'

export const AnalyticsContext = createContext(null);
const Analytics = () => {
  const isAnalytics = true;
  const dispatch = useDispatch()
  const { analytics } = useSelector(state => state)
  const [isModalShow, setIsModalShow] = useState(false);
  const [dataId, setDataId] = useState(null);

  // pagination
  const [currentPage, setCurrentPage] = useState(Filters.PAGE);
  const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value
  const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
  const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)


  useEffect(() => {
    // dispatch(fetchUserAnalytics())
    dispatch(fetchIndicatorSource());
    dispatch(fetchGoals())
  }, [])
  const isClick = (value) => {
    setDataId(value)
  }

  const saveData = (data, sourceName) => {
    if (sourceName === "World Bank") {
      dispatch(addUserAnalytics(data, isAnalytics, { page: currentPage, perPage: perPage }, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
    } else {
      dispatch(addUserUnitedNationsAnalytics(data, isAnalytics, { page: currentPage, perPage: perPage }, dataId, setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
    }
  }

  return (
    <DashboardMaster>
      <section className='p-2 md:px-5'>
        <Card className="bg-white p-3 md:p-5 ">
          <div className="content-head mb-6">
            <Button size="sm" className="h-[34px]" onClick={() => setIsModalShow(true)}>
              <span><IconAdd className="w-4 h-4" /></span> Add Analytics
            </Button>
            <AnalyticsModel show={isModalShow} onHide={() => setIsModalShow(false)} saveData={saveData} />
          </div>
          <div className="analytics-content-wrapper">
            <Accordion type="single" collapsible className="w-full rounded-none">
              {analytics?.indicatorSource?.length >= 1 ? analytics?.indicatorSource.map((items) => {
                return (
                  <AccordionItem value={`${items.sourceName}`} key={items._id} className="rounded-none border-none mb-4">
                    <AccordionTrigger className="text-[13px] text-themeV2 font-500 capitalize px-4 py-3 shadow-accordian relative z-10 bg-backgroundV2" onClick={() => isClick(items._id)}  >{items.sourceName}</AccordionTrigger>
                    <AccordionContent className="border-none px-2 py-0 ">
                      <div className="bg-[#FBFBFB] shadow-common p-4">
                        <AnalyticsContext.Provider value={{ currentPage, setCurrentPage, perPage, setPerPage, totalRecords, setTotalRecords, totalPages, setTotalPages }}>
                          <WorldBankAnalyticsTable dataId={dataId} isModalShow={isModalShow} setIsModalShow={setIsModalShow} />
                        </AnalyticsContext.Provider>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                )
              }) : ''}
              {/* <AccordionItem value="item-1" className="rounded-none border-none mb-4">
                <AccordionTrigger className="text-[13px] text-themeV2 font-500 capitalize px-4 py-3 shadow-accordian relative z-10 bg-backgroundV2">World Bank</AccordionTrigger>
                <AccordionContent className="border-none px-2 py-0 ">
                  <div className="bg-[#FBFBFB] shadow-common p-4">
                    <WorldBankAnalyticsTable />
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="item-2" className="rounded-none border-none mb-4">
                <AccordionTrigger className="text-[13px] text-themeV2 font-500 capitalize px-4 py-3 shadow-accordian relative z-10 bg-backgroundV2">United Nations(SDGs)</AccordionTrigger>
                <AccordionContent className="border-none px-2 py-0 ">
                  <div className="bg-[#FBFBFB] shadow-common p-4">
                    <UnitedNationAnalyticsTable />
                  </div>
                </AccordionContent>
              </AccordionItem> */}
            </Accordion>
          </div>
        </Card>
      </section>
    </DashboardMaster>
  )
}

export default Analytics
