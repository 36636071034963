import React from 'react'
import { Button } from '../../components/ui/button'
import { Cross2Icon, HamburgerMenuIcon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'
import Logo from "../../assets/images/logo-dark.svg";
import ThemeToogle from '../../components/common/ThemeToogle';

const DashboardHeader = ({ toogleMiniSidebar, toogleResponsiveSidebar, setToogleResponsiveSidebar, }) => {
  return (
    <header className={`px-5 py-2 fixed top-0 transition-all duration-300 ${toogleMiniSidebar ? "lg:start-[80px] lg:w-[calc(100%-80px)]" : "lg:start-[260px] lg:w-[calc(100%-260px)]"}  w-full bg-backgroundV2 z-50 `}>
      <div className="flex items-center lg:justify-end justify-between">
        <div className="logo-part lg:hidden block">
          <Link to={"/"}>
            <img src={Logo} alt='logo' className='' />
          </Link>
        </div>
        <div className="flex items-center gap-2">
          <Button className="w-8 h-8 p-0 lg:hidden flex" onClick={() => setToogleResponsiveSidebar(!toogleResponsiveSidebar)}>
            {
              toogleResponsiveSidebar ? <Cross2Icon /> : <HamburgerMenuIcon />
            }
          </Button>
          <div className="">
              <ThemeToogle/>
          </div>
        </div>
      </div>
    </header>
  )
}

export default DashboardHeader