import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../../../components/ui/button'
import IconAdd from '../../../../components/svg/IconAdd'
import { DatePicker } from '../../../../components/ui/datepicker'
import Card from '../../../../components/common/Card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/ui/table'
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Download, DownloadIcon, Trash } from 'lucide-react'
import LineChart from '../../../../components/chart/LineCharts'
import PieChartCustom from '../../../../components/chart/PieChart'
import BarChartCustom from '../../../../components/chart/BarChart'
import StackedAreaChart from '../../../../components/chart/AreaChart'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { addTost } from '../../../../store/actions/toastAction'
import { toastifyType } from '../../../../constants'
import moment from 'moment-js'
import { fetchAnalyticReports, fetchAnalyticReportsTow } from '../../../../store/actions/analyticReportsAction'
import { fetchReports } from '../../../../store/actions/reportingAction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { fetchReportsDetails } from '../../../../store/actions/AssetDetailsAction'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import downloadPDF from '../../fetchPdfData'
import ReportingModel from '../../reporting/model/ReportingModel'
import Loading from '../../Loading'
import BasicScatter from '../../../../components/chart/ScatterChart'
import VerticalExposureTable from '../../reporting/tables/VerticalExposureTable'
import HorizontalExposureTable from '../../reporting/tables/HorizontalExposureTable'
import { FromDatePicker } from '../../../../components/ui/DatePickerNew'
import DoughnutChart from '../../../../components/chart/DoughnutChart'
import RadarCharts from '../../../../components/chart/RadarChart'
// import { FromDatePicker } from '../../../../components/ui/fromDatePicker'
// import { ToDatePicker } from '../../../../components/ui/toDatePicker'

const data = [
    {
        asset: "1",
        "1960": "5.6",
        "1961": "5.6",
        "1962": "5.6",
        "1963": "5.6",
        "1964": "5.6",
    },
    {
        asset: "2",
        "1960": "5.6",
        "1961": "5.6",
        "1962": "5.6",
        "1963": "5.6",
        "1964": "5.6",
    },
    {
        asset: "3",
        "1960": "5.6",
        "1961": "5.6",
        "1962": "5.6",
        "1963": "5.6",
        "1964": "5.6",
    },
]



export const columns = [
    {
        accessorKey: "asset",
        header: "Asset",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("asset")}</div>
        ),
    },
    {
        accessorKey: "1960",
        header: "1960 - Income share held...",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("1960")}</div>
        ),
    },
    {
        accessorKey: "1961",
        header: "1961 - Income share held...",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("1961")}</div>
        ),
    },
    {
        accessorKey: "1962",
        header: "1962 - Income share held...",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("1962")}</div>
        ),
    },
    {
        accessorKey: "1963",
        header: "1963 - Income share held...",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("1963")}</div>
        ),
    },
    {
        accessorKey: "1964",
        header: "1964 - Income share held...",
        cell: ({ row }) => (
            <div className="capitalize">{row.getValue("1964")}</div>
        ),
    },
]

const AssetReportingContent = (props) => {
    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState(
        []
    )
    const [columnVisibility, setColumnVisibility] = useState({})
    const [rowSelection, setRowSelection] = useState({})

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
        },
    })
    const chartss = [
        { chartComponent: <LineChart /> },
        { chartComponent: <PieChartCustom /> },
        { chartComponent: <BarChartCustom /> },
        { chartComponent: <StackedAreaChart /> },
        { chartComponent: <BasicScatter /> }
    ]

    // my changes 

    const { allAnalytics, updateId, fetchAssetData, isPortfolio } = props;
    const { assetsDetails, analyticReports, portfolios } = useSelector(state => state)
    const { slug } = useParams();
    const dispatch = useDispatch();
    const [charts, setCharts] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [chartData, setChartData] = useState([])
    const [oldData, setOldData] = useState([])
    const [startFormDate, setStartFormDate] = useState(null);
    const [startToDate, setStartToDate] = useState(null);
    const [checkbox2Checked, setCheckbox2Checked] = useState(false);
    const [allUserAnalytics, setAllUserAnalytics] = useState([])
    const [unitedNations, setUnitedNations] = useState([])
    const [worldBankId, setWorldBankId] = useState('')
    const [unitedNationId, setUnitedNationId] = useState('')
    const [portfolioSlug, setPortfolioSlug] = useState(null)
    const [formData, setFormData] = useState({
        indicators: null,
        indicatorsTow: null,
        from: '',
        to: '',
        chartType: '',
        isAutoChart: true
    });


    useEffect(() => {
        if (allAnalytics) {
            const worldBank = Array.isArray(allAnalytics) ? allAnalytics.find((item) => item.source === 'World Bank') : null
            const worldBankIndicators = worldBank ? worldBank.indicators : [];
            const getSourceId = worldBank ? worldBank.sourceId : '';
            setWorldBankId(getSourceId)
            setAllUserAnalytics(worldBankIndicators)
        }
    }, [allAnalytics])

    useEffect(() => {
        if (allAnalytics) {
            const unitedNation = Array.isArray(allAnalytics) ? allAnalytics.find((item) => item.source === 'United Nations (SDGs)') : null
            const unitedNationIndicators = unitedNation ? unitedNation.indicators : [];
            const getSourceId = unitedNation ? unitedNation.sourceId : '';
            setUnitedNationId(getSourceId)
            setUnitedNations(unitedNationIndicators)
        }
    }, [allAnalytics])


    useEffect(() => {
        setChartData([])
        setFormData({
            indicators: null,
            indicatorsTow: null,
            from: '',
            to: '',
            chartType: '',
            isAutoChart: true
        })
    }, [slug, updateId])
    const indicatorOptions = allAnalytics && allAnalytics?.length >= 1 ? allAnalytics?.map((item) => {
        return { value: item?.indicator?._id, label: item?.indicator?.indicatorName }
    }) : []

    const onChangeIndicatorOption = (event) => {
        if (_.isEmpty(fetchAssetData)) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
            setFormData({ ...formData, indicators: event })
        } else {
            setFormData({ ...formData, indicators: event })
            const data = {
                analytics: event.value,
                asset: updateId,
                from: formData.from === "" ? "" : moment(formData.from).format('DD-MM-YYYY'),
                to: formData.to === "" ? "" : moment(formData.to).format('DD-MM-YYYY'),
            }
            dispatch(fetchAnalyticReports(data))
        }
    }
    const onChangeIndicatorTowOption = (event) => {
        if (_.isEmpty(fetchAssetData)) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
            setFormData({ ...formData, indicatorsTow: event })
        } else {
            setFormData({ ...formData, indicatorsTow: event })
            const data = {
                analytics: event.value,
                asset: updateId,
                from: formData.from === "" ? "" : moment(formData.from).format('DD-MM-YYYY'),
                to: formData.to === "" ? "" : moment(formData.to).format('DD-MM-YYYY'),
            }
            dispatch(fetchAnalyticReportsTow(data))

        }
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const save = (charts, setCheckbox1Checked, setCheckbox2Checked, analytics, assetOptions) => {
        setIsLoading(true)
        if (_.isEmpty(fetchAssetData)) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
            setCheckbox1Checked(true)
            setCheckbox2Checked(false)
            setIsLoading(false)
        } else {
            const getPortfolio = portfolios && portfolios.length ? portfolios.find(item => item.slug === slug)?.id : '';
            const data = [{
                // portfolio: getPortfolio,
                portfolio: fetchAssetData.portfolio,
                // analytics: [formData.indicators.value],
                analytics: analytics,
                assets: [updateId],
                from: formData.from === "" ? "" : moment(formData.from).format('YYYY'),
                to: formData.to === "" ? "" : moment(formData.to).format('YYYY'),
                isAutoChart: formData.isAutoChart,
                save: false,
                type: charts,
                isSave: false
            }]
            setFormData({ ...formData, chartType: charts })
            // dispatch(fetchReportsDetails(data, setChartData, charts.label, setIsLoading, formData.isAutoChart, null, null, chartData))
            // dispatch(fetchAnalyticReports(data, setChartData, chartData, charts, setIsLoading))
            dispatch(fetchReports(data, setChartData, charts, setIsLoading, formData.isAutoChart, [], getPortfolio, chartData, false, true))
            setFormData({
                indicators: null,
                indicatorsTow: null,
                from: '',
                to: '',
                chartType: '',
                isAutoChart: true
            })
            setCheckbox1Checked(true)
            setCheckbox2Checked(false)
            handleClose()
        }
    }
    const deleteChart = (id) => {
        const updateData = chartData.filter(item => item.chartId !== id)
        setChartData(updateData)
    }

    const minChart = () => {
        setChartData(chartData.slice(0, -1))
    }

    function CustomInputFormData({ value, onClick }) {
        return (
            <div className="input-group data_picker rounded-2">
                <input
                    type="text"
                    className="form-control rounded-2"
                    value={value}
                    placeholder='YYYY'
                    onClick={onClick}
                    readOnly
                />
                <div className="input-group-append icon rounded-2">
                    <span className="input-group-text rounded-2">
                        <FontAwesomeIcon icon={faCalendar} className='rounded-2' />
                    </span>
                </div>
            </div>
        );
    }
    function CustomInputToData({ value, onClick }) {
        return (
            <div className="input-group data_picker rounded-2">
                <input
                    type="text"
                    className="form-control rounded-2"
                    value={value}
                    onClick={onClick}
                    placeholder='YYYY'
                    readOnly
                />
                <div className="input-group-append icon rounded-2">
                    <span className="input-group-text rounded-2">
                        <FontAwesomeIcon icon={faCalendar} className='rounded-2' />
                    </span>
                </div>
            </div>
        );
    }

    const onApplyData = () => {
        if (updateId && _.isEmpty(fetchAssetData)) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
        } else {
            if (formData.from !== '' && formData.to !== '') {
                setOldData(chartData)
                // setChartData([])
                let getData = chartData.map((item, index) => {
                    let data = {
                        chartId: item.chartId,
                        portfolio: item?.portfolioId ? item?.portfolioId : '',
                        analytics: item.analytics,
                        assets: item.assets,
                        fromDate: formData.from === "" ? "" : moment(formData.from).format('YYYY'),
                        toDate: formData.to === "" ? "" : moment(formData.to).format('YYYY'),
                        save: false,
                        type: item.type,
                        isAutoChart: item.isAutoChart,
                        index,
                    }
                    return data
                })
                setFormData(input => ({ ...input, from: moment(formData.from).format('YYYY'), to: moment(formData.to).format('YYYY') }))
                dispatch(fetchReportsDetails(getData, setChartData, 'Line Chart', setIsLoading, formData.isAutoChart, [], null, chartData, true))
                setStartFormDate(null)
                setStartToDate(null)
                // const upData = chartData.splice(chartLt -1, 1)
                // setChartData(chartData.splice(chartLt -1, 1))
                // dispatch(fetchAnalyticReports(data, setChartData, chartData, charts))
            }
        }
    }


    const lineChartRef = useRef();
    const barChartRef = useRef();
    const bubbleChartRef = useRef();
    const doughnutChartRef = useRef();
    const pieChartRef = useRef();
    const polarAreaChartRef = useRef();
    const redarChartRef = useRef();
    const scatterChartRef = useRef();
    const mainChartRef = useRef()
    const [base64Images, setBase64Images] = useState([]);
    const handleDownload = async (isImages, isPdf) => {
        const gap = 10; // Adjust the gap size as needed
        const mainChartContainer = mainChartRef.current;
        const chartContainers = mainChartContainer.getElementsByTagName('canvas');

        // Calculate the number of rows and columns based on the number of charts
        const numRows = 2; // You can adjust this as needed
        const numCols = Math.ceil(chartContainers.length / numRows);

        // Calculate the width and height of the individual chart including the gap
        const chartWidth = (mainChartRef.current.offsetWidth - gap * (numCols - 1)) / numCols;
        const chartHeight = (mainChartRef.current.offsetHeight - gap * (numRows - 1)) / numRows;

        // Create a combined canvas
        const combinedCanvas = document.createElement('canvas');
        const ctx = combinedCanvas.getContext('2d');
        combinedCanvas.width = chartWidth * numCols + gap * (numCols - 1);
        combinedCanvas.height = chartHeight * numRows + gap * (numRows - 1);

        const base64ImagesArray = [];

        for (let i = 0; i < chartContainers.length; i++) {
            const chartRef = chartContainers[i];
            if (chartRef) {
                // Capture a screenshot of the chart using html2canvas
                const chartImage = await html2canvas(chartRef);

                // Calculate the row and column for positioning the chart in the combined canvas
                const row = Math.floor(i / numCols);
                const col = i % numCols;

                // Calculate the position for the chart with gap
                const x = col * (chartWidth + gap);
                const y = row * (chartHeight + gap);

                // Draw the chart onto the combined canvas at the appropriate position
                ctx.drawImage(chartImage, x, y, chartWidth, chartHeight);

                // Store the base64 data for individual images if needed
                base64ImagesArray.push({ base64Data: chartImage.toDataURL('image/png') });
            }
        }

        if (isImages) {
            setIsLoading(true);
            // Convert the combined canvas to a base64 data URL
            const combinedBase64 = combinedCanvas.toDataURL('image/png');

            // Create a download link for the combined image
            const link = document.createElement('a');
            link.href = combinedBase64;
            link.download = 'combined-charts.png';
            link.click();
            setIsLoading(false);
        }

        // if (isPdf) {
        //   setIsLoading(true);
        //   const response = await downloadPDF(base64ImagesArray);

        //   if (response) {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const a = document.createElement('a');
        //     a.href = url;
        //     a.download = `${generateSixDigitNumber()}.pdf`;  // You can also get this from the 'Content-Disposition' header in the response
        //     document.body.appendChild(a);
        //     a.click();
        //     a.remove();
        //     window.URL.revokeObjectURL(url);
        //     setIsLoading(false);
        //   }

        if (isPdf) {
            setIsLoading(true);

            // Create a new jsPDF instance
            const pdf = new jsPDF({
                orientation: 'l',
                unit: 'mm',
                format: 'a4',
            });


            // Calculate the width and height for a single chart image
            // const chartWidth = pdf.internal.pageSize.getWidth() / 1.5;
            // const chartHeight = pdf.internal.pageSize.getHeight() / 1.5;
            const chartWidth = 150; // Set the desired width
            const chartHeight = 150; // Set the desired height


            // Calculate the position to center the chart on the page
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();

            const x = (pageWidth - chartWidth) / 2;
            const y = (pageHeight - chartHeight) / 2;

            // Add the chart to the center of the page

            for (let i = 0; i < base64ImagesArray.length; i++) {
                const imageData = base64ImagesArray[i].base64Data;
                if (i > 0) {
                    pdf.addPage();
                }
                // Calculate the position and dimensions for each image on the page
                pdf.addImage(imageData, 'PNG', x, y, chartWidth, chartHeight);
            }

            pdf.save(`${generateSixDigitNumber()}.pdf`);
            setIsLoading(false);

        }
        // }

        setBase64Images(base64ImagesArray);
    };

    const handleDownloads = async (isImages, isPdf) => {
        const mainChartContainer = mainChartRef.current;
        const chartContainers = mainChartContainer.getElementsByTagName('CANVAS'); // Assuming 'chart_main' is the class name of chart container
        const base64ImagesArray = [];
        for (let i = 0; i < chartContainers.length; i++) {
            const chartRef = chartContainers[i];
            if (chartRef) {
                // const chartImage = await ChartJsImage.toDataUrl(chartRef.current.chartInstance);
                // const chartCanvas = chartRef.current.canvas;
                const chartCanvas = chartRef;
                const chartImage = await html2canvas(chartCanvas);

                // Convert the canvas image to a base64 data URL
                const base64Image = chartImage.toDataURL('image/png');

                // Now you have the base64 image that you can use or download

                base64ImagesArray.push({ base64Data: base64Image });
            }
        }

        if (isImages) {
            base64ImagesArray.forEach((data) => {
                const link = document.createElement('a');
                link.href = data.base64Data;
                const randomNumber = generateSixDigitNumber()
                link.download = `${randomNumber}-chart.png`;
                link.click();
            })
        }
        if (isPdf) {
            const response = await downloadPDF(base64ImagesArray)

            if (response) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${generateSixDigitNumber()}.pdf`;  // You can also get this from the 'Content-Disposition' header in the response
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }
        }

        setBase64Images(base64ImagesArray);
    };

    function generateSixDigitNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }

    return (
        <>
            <div className="content-main-wrapper p-3 md:p-5 !pt-0">
                <div className="content-head mb-6">
                    <div action="">
                        <div className="flex items-center gap-4 flex-wrap">
                            <Button size="sm" className="min-w-[98px]" variant="" onClick={handleShow}>
                                <span><IconAdd className="w-4 h-4" /></span>  Chart
                            </Button>
                            <div className="flex items-center gap-4">
                                <label htmlFor="" className='text-textV1 text-xs font-500'>From</label>
                                <FromDatePicker onDateSelect={(date) => { setStartFormDate(date); setFormData({ ...formData, from: date }) }}
                                    placeholderText='YYYY-MM-DD'
                                    selectedFromDate={startFormDate}
                                />

                                {/* <FromDatePicker
                                    onDateSelect={(date) => { setStartFormDate(date); setFormData({ ...formData, from: date }) }}
                                    selected={startFormDate}
                                    selectsStart
                                    dateFormat={'yyyy-MM-dd'}
                                    placeholderText='YYYY-MM-DD'
                                    startDate={startFormDate}
                                    endDate={startToDate}
                                    isFrom={true}
                                /> */}

                                {/* <DatePicker  onDateSelect={(date) => { setStartFormDate(date); setFormData({ ...formData, from: date }) }}/> */}
                            </div>
                            <div className="flex items-center gap-4">
                                <label htmlFor="" className='text-textV1 text-xs font-500'>To</label>
                                <FromDatePicker onDateSelect={(date) => { setStartToDate(date); setFormData({ ...formData, to: date }) }}
                                    placeholderText='YYYY-MM-DD'
                                    minDate={startFormDate}
                                    selectedFromDate={startFormDate}
                                />
                                {/* <ToDatePicker
                                    onDateSelect={(date) => { setStartToDate(date); setFormData({ ...formData, to: date }) }}
                                    selected={startToDate}
                                    selectsEnd
                                    dateFormat={'yyyy-MM-dd'}
                                    placeholderText='YYYY-MM-DD'
                                    startDate={startFormDate}
                                    endDate={startToDate}
                                    minDate={startFormDate}
                                    isTo={true}
                                /> */}

                                {/* <DatePicker onDateSelect={(date) => { setStartToDate(date); setFormData({ ...formData, to: date }) }} /> */}
                            </div>
                            <Button size="sm" className="min-w-[98px] hover:bg-[#F5F5F5]" type="submit" variant="outline" onClick={() => onApplyData()}>
                                Apply
                            </Button>
                        </div>
                    </div>
                </div>
                {/* <div className="table-content mb-6">
                    <Card className={"border border-borderV1 p-3 md:p-3 mb-6"}>
                        <div className="heading mb-4 flex items-center justify-between gap-4">
                            <div className="">
                                <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>Test</span></h5>
                                <p className='text-xs text-textV1 font-500'>Horizontal Exposure Table</p>
                            </div>
                            <div className="items-center gap-2 md:flex hidden">
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Download className='w-4 h-4' /></span> Download
                                </Button>
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Trash className='w-4 h-4' /></span>  Delete
                                </Button>
                            </div>
                            <div className="items-center gap-2  md:hidden flex">
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Download className='w-4 h-4' /></span>
                                </Button>
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Trash className='w-4 h-4' /></span>
                                </Button>
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <div className="rounded-sm border  overflow-hidden">
                                <Table className="min-w-[1000px]">
                                    <TableHeader>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id} className="">
                                                {headerGroup.headers.map((header) => {
                                                    return (
                                                        <TableHead className="bg-backgroundV1" key={header.id}>
                                                            {header.isPlaceholder
                                                                ? null
                                                                : flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                        </TableHead>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableHeader>
                                    <TableBody>
                                        {table.getRowModel().rows?.length ? (
                                            table.getRowModel().rows.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    data-state={row.getIsSelected() && "selected"}
                                                >
                                                    {row.getVisibleCells().map((cell) => (
                                                        <TableCell className="text-xs" key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={columns.length}
                                                    className="h-24 text-center"
                                                >
                                                    No results.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </Card>
                    <Card className={"border border-borderV1 p-3 md:p-3"}>
                        <div className="heading mb-4 flex items-center justify-between gap-4">
                            <div className="">
                                <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>Test</span></h5>
                                <p className='text-xs text-textV1 font-500'>Horizontal Exposure Table</p>
                            </div>
                            <div className="items-center gap-2 md:flex hidden">
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Download className='w-4 h-4' /></span> Download
                                </Button>
                                <Button variant="outline" size="sm" className="min-w-[100px]">
                                    <span><Trash className='w-4 h-4' /></span> Delete
                                </Button>
                            </div>
                            <div className="items-center gap-2  md:hidden flex">
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Download className='w-4 h-4' /></span>
                                </Button>
                                <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                    <span><Trash className='w-4 h-4' /></span>
                                </Button>
                            </div>
                        </div>
                        <div className="table-wrapper">
                            <div className="rounded-sm border  overflow-hidden">
                                <Table className="min-w-[1000px]">
                                    <TableHeader>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id} className="">
                                                {headerGroup.headers.map((header) => {
                                                    return (
                                                        <TableHead className="bg-backgroundV1" key={header.id}>
                                                            {header.isPlaceholder
                                                                ? null
                                                                : flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                        </TableHead>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableHeader>
                                    <TableBody>
                                        {table.getRowModel().rows?.length ? (
                                            table.getRowModel().rows.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    data-state={row.getIsSelected() && "selected"}
                                                >
                                                    {row.getVisibleCells().map((cell) => (
                                                        <TableCell className="text-xs" key={cell.id}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell
                                                    colSpan={columns.length}
                                                    className="h-24 text-center"
                                                >
                                                    No results.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </Card>
                </div> */}
                <div className="chart-content" id='reportTable' ref={mainChartRef}>
                    {chartData.length >= 1 ? <div className="">
                        {/* {
                            chartss.map(({ chartComponent }) => (
                                <Card className={"border border-borderV1 p-3 md:p-3 relative"}>
                                    <div className="heading mb-4 flex items-center justify-between gap-4">
                                        <div className="">
                                            <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>Test</span></h5>
                                            <p className='text-xs text-textV1 font-500'>Horizontal Exposure Table</p>
                                        </div>
                                        <div className="items-center gap-2 md:flex hidden">
                                            <Button variant="outline" size="sm" className="min-w-[100px]">
                                                <span><Download className='w-4 h-4' /></span> Download
                                            </Button>
                                            <Button variant="outline" size="sm" className="min-w-[100px]">
                                                <span><Trash className='w-4 h-4' /></span> Delete
                                            </Button>
                                        </div>
                                        <div className="items-center gap-2  md:hidden flex">
                                            <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                                <span><Download className='w-4 h-4' /></span>
                                            </Button>
                                            <Button variant="outline" size="sm" className="w-8 h-8 p-0">
                                                <span><Trash className='w-4 h-4' /></span>
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="chart-wrapper w-full h-[340px] flex justify-center items-center">
                                        {chartComponent}
                                    </div>
                                </Card>
                            ))
                        } */}
                        {isLoading ? <div className='grid grid-cols-1 lg:grid-cols-1 gap-6'><Loading /></div> : chartData.length >= 1 ?
                            <>
                                {chartData.map((item, Index) => {
                                    if (item.type === 'Vertical Exposure Table') {
                                        return <VerticalExposureTable item={item} deleteChart={deleteChart} handleDownloads={handleDownloads} isPortfolio={isPortfolio} />
                                    } else if (item.type === 'Horizontal Exposure Table') {
                                        return <HorizontalExposureTable item={item} deleteChart={deleteChart} handleDownloads={handleDownloads} isPortfolio={isPortfolio} />
                                    }
                                })}
                                <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                                    {
                                        chartData.map((item, Index) => {
                                            if (item.type !== 'Vertical Exposure Table' && item.type !== 'Horizontal Exposure Table') {
                                                return (

                                                    <Card className={"border border-borderV1 p-3 md:p-3 relative"} key={item.id}>
                                                        <div className="heading mb-4 flex items-center justify-between gap-4">
                                                            <div className="">
                                                                <h5 className='text-sm text-textV2 font-500 mb-1'>Portfolio: <span>{item?.portfolioName}</span></h5>
                                                                <p className='text-xs text-textV1 font-500'>{item?.type}</p>
                                                            </div>
                                                            <div className="flex items-center gap-2">
                                                                {/* <Button variant="outline" size="sm" className="w-8 h-8 p-0" onClick={() => handleDownloads(item.chartId)}>
                                                                    <span><DownloadIcon className='w-4 h-4' /></span>
                                                                </Button> */}
                                                                <Button variant="outline" size="sm" className="w-8 h-8 p-0 !border-red hover:bg-[#F5F5F5]" onClick={() => deleteChart(item.chartId)}>
                                                                    <span><Trash className='w-4 h-4 !text-red' /></span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="chart-wrapper w-full h-[340px] flex justify-center items-center">
                                                            {item.type === "Line Chart" && <LineChart analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={Index} refEl={lineChartRef} />}
                                                            {item.type === 'Bar Chart' && <BarChartCustom analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={Index} refEl={barChartRef} />}
                                                            {item.type === 'Pie Chart' && <PieChartCustom analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={Index} refEl={pieChartRef} />}
                                                            {item.type === 'Doughnut Chart' && <DoughnutChart analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={Index} refEl={doughnutChartRef} />}
                                                            {item.type === 'PolarArea Chart' && <StackedAreaChart analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={Index} refEl={polarAreaChartRef} />}
                                                            {item.type === 'Radar Chart' && <RadarCharts analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={Index} refEl={redarChartRef} />}
                                                            {item.type === 'Scatter Chart' && <BasicScatter analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} index={Index} refEl={scatterChartRef} />}
                                                            {/* {item.type === 'Bubble Chart' && <BubbleChart analyticReports={item} isAutoChart={item.isAutoChart} updateId={updateId} isReports={true} refEl={bubbleChartRef} />} */}
                                                        </div>
                                                    </Card>

                                                )
                                            }
                                        })
                                    }
                                </div>
                            </>
                            :
                            ''}
                    </div> : <div className=''>
                        <div className=''>
                            <div className='mt-4' >
                                <div className={'rounded-sm border border-borderV1 grid grid-cols-1 gap-5 text-center p-5'}>
                                    <div>No Data Available</div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
            <ReportingModel show={show} onHide={handleClose} save={save} formData={formData} setFormData={setFormData} setIsLoading={setIsLoading} setPortfolioSlug={setPortfolioSlug} isReports={false} />
        </>
    )
}

export default AssetReportingContent