import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updatePortfolio } from '../../../../store/actions/portfolioAction';
import { addGroupPortfolio } from '../../../../store/actions/portfolioGroupAction';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { Checkbox } from "@/components/ui/checkbox"

const CreatePortfolioModel = (props) => {
    const { show, onHide, fetchData, fetchId, PortfolioGroup, currentPage, slug, group, setCurrentPage } = props
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [existingPortfolioId, setExistingPortfolioId] = useState(null);
    const [existingPortfolio, setExistingPortfolio] = useState(true);
    const [createNewPortfolio, setCreateNewPortfolio] = useState(false);
    const { isLoading, allPortfolio } = useSelector(state => state)
    const [ctName, setCtName] = useState({
        name: '',
        group: null
    })
    const [error, setError] = useState({});


    useEffect(() => {
        if (show === false) {
            setCtName({
                name: '',
                group: null
            })
            setError({})
        }
    }, [show])

    useEffect(() => {
        if (fetchId !== null) {
            setCtName({
                id: fetchData && fetchData?._id ? fetchData?._id : null,
                name: fetchData && fetchData?.portfolios ? Object.keys(fetchData?.portfolios).length !== 0 ? fetchData?.portfolios?.name : '' : fetchData?.portfolio ? Object.keys(fetchData?.portfolio).length !== 0 ? fetchData?.portfolio?.name : '' : '',
                group: fetchData && fetchData?.portfolioGroup ? Object.keys(fetchData?.portfolioGroup).length !== 0 ? { value: fetchData?.portfolioGroup?._id, label: fetchData?.portfolioGroup?.name } : null : null,
            })
        }
        if (group !== null && fetchId !== null) {
            setCtName({
                id: fetchData && fetchData?._id ? fetchData?._id : null,
                name: fetchData && fetchData?.portfolios ? Object.keys(fetchData?.portfolios).length !== 0 ? fetchData?.portfolios?.name : '' : fetchData?.portfolio ? Object.keys(fetchData?.portfolio).length !== 0 ? fetchData?.portfolio?.name : '' : '',
                group: group ? { value: group?._id, label: group?.name } : null,
            })
        } else if (group !== null) {
            setCtName({
                name: '',
                group: group ? { value: group?._id, label: group?.name } : null,
            })
        }
    }, [fetchData, group, fetchId])


    const onChangeHandel = (e) => {
        setCtName({ ...ctName, name: e.target.value })
        setError({})
    }
    const handleValidation = () => {
        let errors = {};
        let isValid = true;

        if (ctName.name === '') {
            errors['name'] = 'Please enter name';
            isValid = false;
        }
        // portfolio alredy exists
        // if (ctName.group === null) {
        //     errors['group'] = 'Please select group';
        //     isValid = false;
        // }

        setError(errors);
        return isValid;
    };

    const onSave = () => {
        if (handleValidation()) {
            if (fetchId !== null) {
                setCurrentPage(1)
                const data = {
                    portfolioGroupMappingId: ctName.id,
                    name: ctName.name,
                    portfolioGroupId: ctName !== null ? ctName.group?.value : null
                }
                dispatch(updatePortfolio(fetchId, data, 1, slug))
            } else {
                let data
                if (existingPortfolio) {
                    data = {
                        action: 'update',
                        portfolioId: existingPortfolioId,
                        name: ctName.name,
                        portfolioGroupId: ctName !== null ? ctName.group?.value : null
                    }
                } else {
                    data = {
                        action: 'add',
                        // portfolioGroupMappingId: ctName.id,
                        name: ctName.name,
                        portfolioGroupId: ctName !== null ? ctName.group?.value : null
                    }
                }

                setCurrentPage(1)
                // dispatch(addPortfolio(data, currentPage, slug))
                dispatch(addGroupPortfolio(data, 1, slug))
            }
            onHide()
        }
    }

    const PortfolioGroupOptions = PortfolioGroup && PortfolioGroup?.length >= 1 ? PortfolioGroup?.map((item) => {
        return { value: item?._id, label: item?.name }
    }) : []

    const PortfolioOptions = allPortfolio && allPortfolio?.length >= 1 ? allPortfolio?.map((item) => {
        return { value: item?.id, label: item?.name }
    }) : []

    const onChangePortfolioGroupOptions = (event) => {
        setCtName({ ...ctName, group: event })
    }
    const onChangePortfolioOptions = (event) => {
        const PortfolioOp = JSON.parse(event)
        setExistingPortfolioId(PortfolioOp.value)
        setCtName({ ...ctName, name: PortfolioOp.label })
    }

    const handleExistingPortfolioChange = () => {
        setCreateNewPortfolio(false);
        setExistingPortfolio(!existingPortfolio);
        setCtName({ ...ctName, name: '' });
    };

    const handleCreateNewPortfolioChange = () => {
        setExistingPortfolio(false);
        setCreateNewPortfolio(!createNewPortfolio);
        setCtName({ ...ctName, name: '' });
    };

    return (
        <>
            <Dialog open={show} onOpenChange={onHide}>
                <DialogContent className="sm:max-w-[460px] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{fetchId && fetchData ? "Edit Portfolio" : "Add Portfolio"}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-1 gap-5">
                            <div className="form-group">
                                <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                    Select Portfolio Group
                                </Label>
                                <Select
                                    // onValueChange={(e) => handleSelect(e)}
                                    defaultValue={fetchId !== null ? ctName.group : ctName.group}
                                    disabled={true}
                                // value={fetchId !== null ? SubName.classOption?.id ? SubName.classOption?.id : "" : SubName.classOption?.id}
                                >
                                    <SelectTrigger className="w-full">
                                        <SelectValue
                                            className='text-textV1'
                                            placeholder="Select Portfolio Group"
                                            disabled={true}
                                        >
                                            {fetchId !== null ? ctName.group?.label : ctName.group?.label ? ctName.group?.label : "Select Portfolio Group"}
                                            {/* {fetchId !== null? SubName.classOption?.label ? SubName.classOption?.label : "" : SubName.classOption?.label} */}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            {
                                                PortfolioGroupOptions && PortfolioGroupOptions.length > 0 ? PortfolioGroupOptions.map((item, index) => {
                                                    return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                            }
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>

                                <div className={` ${error['class'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['class'] ? error['class'] : null}</div>
                            </div>
                            {fetchId === null ? <div className='flex'>
                                <div className="flex items-center space-x-2">
                                    <Checkbox id="existingPortfolio" className='border-black' checked={existingPortfolio} onCheckedChange={handleExistingPortfolioChange} />
                                    <Label htmlFor="existingPortfolio" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                        Existing Portfolio
                                    </Label>
                                </div>
                                <div className="flex items-center space-x-2 ml-2">
                                    <Checkbox id="createNewPortfolio" className='border-black' checked={createNewPortfolio} onCheckedChange={handleCreateNewPortfolioChange} />
                                    <Label htmlFor="createNewPortfolio" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                        Create New Portfolio
                                    </Label>
                                </div>
                            </div> : ''}

                            {(createNewPortfolio || fetchId !== null) ?
                                <><div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Portfolio Name:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Input
                                        className={`form-control shadow-none ${error['name'] && 'is-invalid'}`}
                                        id="name" type="text" name="name" placeholder="name" onChange={(e) => onChangeHandel(e)} value={ctName.name} />

                                    <div className={`${error['name'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['name'] ? error['name'] : null}</div>
                                </div></> : ''}
                            {existingPortfolio && fetchId === null ?
                                <>
                                    <div className="form-group">
                                        <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                            Select Portfolio:
                                        </Label>
                                        <Select
                                            onValueChange={(e) => onChangePortfolioOptions(e)}
                                        // defaultValue={fetchId !== null ? SubName.classOption : SubName.classOption}
                                        // value={fetchId !== null ? SubName.classOption?.id ? SubName.classOption?.id : "" : SubName.classOption?.id}
                                        >
                                            <SelectTrigger className="w-full">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Portfolio:"
                                                >
                                                    {/* {fetchId !== null ? ctName.name.classOption?.label : SubName.classOption?.label ? SubName.classOption?.label : "Select Portfolio:"} */}
                                                    {/* {fetchId !== null? SubName.classOption?.label ? SubName.classOption?.label : "" : SubName.classOption?.label} */}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {
                                                        PortfolioOptions && PortfolioOptions.length > 0 ? PortfolioOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>

                                        <div className={` ${error['name'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['name'] ? error['name'] : null}</div>
                                    </div> </> : ''}
                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreatePortfolioModel