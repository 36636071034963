import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/ui/tabs"
import ClassContent from "../setting-tabs-Contents/ClassContent"
import SubClassContent from "../setting-tabs-Contents/SubClassContent"
import TypeContent from "../setting-tabs-Contents/TypeContent"
import SubTypeContent from "../setting-tabs-Contents/SubTypeContent"
import StrategyContent from "../setting-tabs-Contents/StrategyContent"

const SettingTabs = () => {
  return (
      <Tabs defaultValue="Class" className="w-[full]">
        <div className="px-3 md:px-5">
          <TabsList className="flex items-center rounded-[12px] w-fit bg-[#DEDEDE] p-1 max-w-[600px] w-full">
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Class">Class</TabsTrigger>
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Sub-Class">Sub Class</TabsTrigger>
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Type">Type</TabsTrigger>
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Sub-Type">Sub Type</TabsTrigger>
            <TabsTrigger className="flex-grow w-full rounded-[12px] mb-0" value="Strategy">Strategy</TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="Class">
          <ClassContent />
        </TabsContent>
        <TabsContent value="Sub-Class">
          <SubClassContent />
        </TabsContent>
        <TabsContent value="Type">
          <TypeContent />
        </TabsContent>
        <TabsContent value="Sub-Type">
          <SubTypeContent />
        </TabsContent>
        <TabsContent value="Strategy">
          <StrategyContent />
        </TabsContent>
      </Tabs>
  )
}


export default SettingTabs