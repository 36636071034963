import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Progress } from "@/components/ui/progress"
import { Button } from "@/components/ui/button"
import { Download, DownloadIcon, Trash } from 'lucide-react'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown"
import downloadPDF from './common/fetchPdfData';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';

const DownloadChart = ({ chartRef, chartData, chartId, echarts }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [progress, setProgress] = useState(0); // State for progress
    const [downloading, setDownloading] = useState(false);

    const handleMouseEnter = () => {
        setShowDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowDropdown(false);
    };

    const downloadChartAsJPEG = () => {
        if (!chartRef.current) return;
        const nodes = document.getElementsByClassName(chartId);
        if (!nodes || nodes.length === 0) {
            console.error('Chart node not found with class name:', chartId);
            return;
        }
        const node = nodes[0]; // Access the first element
        htmlToImage.toJpeg(node, {
            backgroundColor: '#FFFFFF'
        })
            .then(function (dataUrl) {
                // download(dataUrl, 'chart.jpeg');
                const image = new Image();
                image.onload = () => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');

                    // Set canvas dimensions based on the image size
                    canvas.width = image.width;
                    canvas.height = image.height;

                    // Fill the canvas with a white background
                    context.fillStyle = 'white';
                    context.fillRect(0, 0, canvas.width, canvas.height);

                    // Draw the image on the canvas
                    context.drawImage(image, 0, 0);

                    // Convert canvas to JPEG data URL
                    const newImageDataUrl = canvas.toDataURL('image/jpeg');

                    // Trigger download
                    const link = document.createElement('a');
                    link.href = newImageDataUrl;
                    const randomNumber = generateSixDigitNumber();
                    link.download = `${randomNumber}-chart.jpeg`;
                    link.click();
                };

                // Set the source of the image to the data URL
                image.src = dataUrl;
            })
            .catch(function (error) {
                console.error('Error generating chart image:', error);
            });

        // setDownloading(true); // Start downloading
        // setProgress(0); // Reset progress

        // const chartContainer = node; // Assuming the chartRef contains an SVG container
        // const canvas = document.createElement('canvas');
        // const context = canvas.getContext('2d');
        // // Get SVG container dimensions
        // const { width, height } = chartContainer.getBoundingClientRect();
        // // Set canvas dimensions based on the SVG size
        // const svgString = new XMLSerializer().serializeToString(chartContainer);
        // const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
        // const url = URL.createObjectURL(svgBlob);

        // const image = new Image();
        // debugger
        // image.onload = () => {

        //     canvas.width = width;
        //     canvas.height = height;

        //     // Fill the canvas with a background color
        //     context.fillStyle = 'white'; // Change 'white' to your desired background color
        //     context.fillRect(0, 0, canvas.width, canvas.height);

        //     // Draw the SVG on the canvas
        //     context.drawImage(image, 0, 0);

        //     // Simulate progress with a timeout
        //     setTimeout(() => {
        //         setDownloading(false); // Finish downloading
        //         setProgress(100); // Set progress to 100%
        //     }, 500); // You can adjust the timeout as needed

        //     // Convert canvas to PNG and trigger download
        //     const link = document.createElement('a');
        //     link.href = canvas.toDataURL('image/png');
        //     const randomNumber = generateSixDigitNumber();
        //     link.download = `${randomNumber}-chart.png`;
        //     link.click();

        //     // Clean up
        //     URL.revokeObjectURL(url);
        // };

        // image.src = url;
        // htmlToImage.toJpeg(node)
        //     .then(function (dataUrl) {

        //     })
        //     .catch(function (error) {
        //         console.error('Error generating chart image:', error);
        //     });
    };


    // const handleDownloadImage = (selectedFormat) => {
    //     const canvas = chartRef.current.canvas;

    //     // Desired width for the image
    //     const desiredWidth = 1080;

    //     // Calculate the scale factor based on desired width
    //     const scaleFactor = desiredWidth / (canvas.width + 25);  // 20 is for the total margin (10 pixels each side)

    //     // Compute the new height while maintaining aspect ratio, and considering the margins
    //     const newHeight = (canvas.height + 25) * scaleFactor; // 20 is for the total margin (10 pixels each side)

    //     // Create a temporary canvas with the new dimensions
    //     const tempCanvas = document.createElement('canvas');
    //     tempCanvas.width = desiredWidth;
    //     tempCanvas.height = newHeight;

    //     const ctx = tempCanvas.getContext('2d');

    //     // Set the background color
    //     ctx.fillStyle = 'white';
    //     ctx.fillRect(0, 0, desiredWidth, newHeight);

    //     // Draw the image scaled to the new dimensions, and position it inside the margins
    //     ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 10 * scaleFactor, 10 * scaleFactor, canvas.width * scaleFactor, canvas.height * scaleFactor);

    //     const link = document.createElement('a');
    //     link.href = tempCanvas.toDataURL('image/jpeg');
    //     const randomNumber = generateSixDigitNumber();
    //     link.download = `${randomNumber}-chart.jpg`;
    //     link.click();
    // };

    const handleDownloadImage = async (selectedFormat) => {
        // setDownloading(true); // Start downloading
        // setProgress(0); // Reset progress
        // const canvas = chartRef.current.canvas;

        // // Set the desired dimensions for the image (including extra space)
        // const imageWidth = canvas.width + 20; // Increase the width by 20 pixels
        // const imageHeight = canvas.height + 20; // Increase the height by 20 pixels

        // const tempCanvas = document.createElement('canvas');
        // tempCanvas.width = imageWidth;
        // tempCanvas.height = imageHeight;

        // const ctx = tempCanvas.getContext('2d');

        // // Set the background color for the extra space outside the chart
        // ctx.fillStyle = 'white';
        // ctx.fillRect(0, 0, imageWidth, imageHeight);

        // // Position the chart inside the extra space
        // const chartX = 10; // Leave a 10-pixel margin on the left
        // const chartY = 10; // Leave a 10-pixel margin on the top
        // ctx.drawImage(canvas, chartX, chartY);



        // // Simulate progress with a timeout
        // setTimeout(() => {
        //     setDownloading(false); // Finish downloading
        //     setProgress(100); // Set progress to 100%
        // }, 500); // You can adjust the timeout as needed

        // const link = document.createElement('a');
        // link.href = tempCanvas.toDataURL('image/png');
        // const randomNumber = generateSixDigitNumber()
        // link.download = `${randomNumber}-chart.png`;
        // link.click();

        if (chartRef.current) {
            setDownloading(true); // Start downloading
            setProgress(0); // Reset progress

            const chartContainer = chartRef.current?.ele; // Assuming the chartRef contains an SVG container
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            // Get SVG container dimensions
            const { width, height } = chartContainer.getBoundingClientRect();
            // Set canvas dimensions based on the SVG size
            const svgString = new XMLSerializer().serializeToString(chartContainer);
            const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(svgBlob);

            const image = new Image();
            
            image.onload = () => {

                canvas.width = width;
                canvas.height = height;

                // Fill the canvas with a background color
                context.fillStyle = 'white'; // Change 'white' to your desired background color
                context.fillRect(0, 0, canvas.width, canvas.height);

                // Draw the SVG on the canvas
                context.drawImage(image, 0, 0);

                // Simulate progress with a timeout
                setTimeout(() => {
                    setDownloading(false); // Finish downloading
                    setProgress(100); // Set progress to 100%
                }, 500); // You can adjust the timeout as needed

                // Convert canvas to PNG and trigger download
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                const randomNumber = generateSixDigitNumber();
                link.download = `${randomNumber}-chart.png`;
                link.click();

                // Clean up
                URL.revokeObjectURL(url);
            };

            image.src = url;
        }
    };

    const handleDownloadPDF = async () => {
        setDownloading(true); // Start downloading
        setProgress(0); // Reset progress
        const canvas = chartRef.current.canvas;

        const imgData = getImageBase64Data(canvas)

        let payload = [
            {
                base64Data: imgData
            }
        ]

        const response = await downloadPDF(payload)

        if (response) {
            // Simulate progress with a timeout
            setTimeout(async () => {
                setDownloading(false); // Finish downloading
                setProgress(100); // Set progress to 100%
            }, 500); // You can adjust the timeout as needed


            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${generateSixDigitNumber()}.pdf`;  // You can also get this from the 'Content-Disposition' header in the response
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
        }
    }

    function getImageBase64Data(canvas) {
        const scaleFactor = 2; // Increase or decrease this factor as required

        // Create a scaled temporary canvas
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width * scaleFactor;
        tempCanvas.height = canvas.height * scaleFactor;

        const ctx = tempCanvas.getContext('2d');
        ctx.scale(scaleFactor, scaleFactor);  // Apply the scaling
        ctx.drawImage(canvas, 0, 0);  // Draw the original canvas onto the scaled canvas

        // Convert the scaled canvas to a data URL
        let imgData = tempCanvas.toDataURL('image/png', 1.0);

        return imgData
    }

    useEffect(() => {
        if (downloading) {
            const interval = setInterval(() => {
                // Simulate progress increase
                setProgress((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
            }, 500);

            return () => clearInterval(interval);
        }
    }, [downloading]);

    function generateSixDigitNumber() {
        return Math.floor(100000 + Math.random() * 900000);
    }
    // open={!downloading && showDropdown}
    return (
        <>
            {/* // <div className='chart_download ' > */}
            <Button variant="outline" size="sm" className="w-8 h-8 p-0 absolute z-[9] top-4 right-[50px] hover:bg-[#F5F5F5]" onClick={() => downloadChartAsJPEG('jpeg')}>
                <span><DownloadIcon className='w-4 h-4' /></span>
            </Button>
            {downloading && (<Progress animated value={progress} className=' absolute z-[9] top-14 right-[10px] h-[5px] w-[75px]' />)}
            {/* <DropdownMenu
            
            >
                <DropdownMenuTrigger >
                    <Button variant="outline" size="sm" className="min-w-[100px]">
                        <span><Download className='w-4 h-4' /></span> Download
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem onClick={() => handleDownloadImage('jpeg')} className='justify-between'>
                        <span>Image</span>
                        <span><FontAwesomeIcon icon={faDownload} size='lg' /></span>

                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => handleDownloadPDF('pdf')} className='justify-between'>
                        <span>PDF </span> <span><FontAwesomeIcon icon={faDownload} size='lg' /></span>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu> */}

        </>
        // </div>
    );
};

export default DownloadChart;

