import { apiBaseURL,  toastifyType } from "../../constants";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";


export const uploadFiles = (data) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.FILE_UPLOAD, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: 'file Upload Successful' }))
        return response
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const removeFiles = (data) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.FILE_UPLOAD, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: 'file Remove Successful' }))
        return response
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}

export const viewDocFiles = (name, type) => async (dispatch) => {
    try {
        const response = await apiConfig.post(apiBaseURL.FILE + `/view?filename=${name}&type=${type}`);
        
        return response
    } catch (error) {
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}