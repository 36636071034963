import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { addInvestedAsset, addInvestedAssetSell, fetchSellAsset } from '../../../../store/actions/portfolioTransactionAction';
import { fetchAnalyticsData } from '../../../../store/actions/analyticsAction';
import { dateConvert } from '../../dateFormate';
import { Checkbox } from '../../../../components/ui/checkbox';
import DataTableColumnHeader from '../../table-components/tasks/components/data-table-column-header';
import { Filters, toastifyType } from '../../../../constants';
import { DataTableWithCustomPagination } from '../../table-components/tasks/components/data-table-without-pagination';
import SellAssetTransactionsTableContent from '../tabs-content/SellAssetTransactionsTableContent';
import { Label } from '../../../../components/ui/label';
import { Input } from '../../../../components/ui/input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '../../../../components/ui/select';
import { Button } from '../../../../components/ui/button';
import { addTost } from '../../../../store/actions/toastAction';
import moment from 'moment-js';
import { DatePickerCustomInput } from '../../../../components/ui/DatePickerCustomInput';
import { Badge } from '../../../../components/ui/badge';
const SellAsset = (props) => {
    const { hideModel,
        currentPage, setCurrentPage,
        perPage, setPerPage,
        totalRecords, setTotalRecords,
        totalPages, setTotalPages,
        portfolioGroupId, setPortfolioGroupId
    } = props
    const dispatch = useDispatch()
    const location = useLocation();
    const { portfolios, PortfolioGroup, portfoliosColumn, saleTransaction, strategy, investedAssetHistory } = useSelector(state => state)
    const [assets, setAssets] = useState([]);
    // const [portfolioGroupId, setPortfolioGroupId] = useState([]);
    const resultArray = location.pathname.split('/').filter(Boolean);
    const [portfolioName, setPortfolioName] = useState(null);
    const [codeName, setCodeName] = useState([])

    // // pagination
    // const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    // const [perPage, setPerPage] = useState(5); // Default per page value
    // const [totalRecords, setTotalRecords] = useState(Filters.OBJ.totalRecords)
    // const [totalPages, setTotalPages] = useState(Filters.OBJ.totalPages)
    // ***
    const [fetchAssetData, setFetchAssetData] = useState({})
    const [updateId, setUpdateId] = useState(null)

    // ***
    const [error, setError] = useState({});


    // ***
    const [transactionId, setTransactionId] = useState([])
    const [fetchTransactions, setFetchTransactions] = useState([])

    const onCheckBtn = (data) => {
        setFetchAssetData(data)
        setUpdateId(data.asset_id)
        setTransactionId([])
        setFetchTransactions([])
        setError({})
    }

    // useEffect(() => {
    //     portfolioGroupId.length && dispatch(fetchSellAsset(null, portfolioGroupId, currentPage, perPage, 'investedAsset', setTotalRecords, setTotalPages, setPerPage, setCurrentPage))
    // }, [currentPage, perPage, portfolioGroupId])

    useEffect(() => {
        setAssets(saleTransaction)
        setTotalRecords(saleTransaction.totalRecords)
    }, [saleTransaction])

    useEffect(() => {
        if (portfoliosColumn?.data) {
            setCodeName(portfoliosColumn?.data.map((item) => { return { code: item.code, name: item.name } }))
        }
    }, [portfoliosColumn?.data])

    useEffect(() => {
        if (PortfolioGroup) {
            if (resultArray.length === 3) {
                const findPortfolio = portfolios.find((item) => item?.portfolio?.slug === resultArray[2]);
                setPortfolioName(findPortfolio);
            } else {
                const findPortfolio = portfolios.find((item) => item?.portfolio?.slug === resultArray[1]);
                setPortfolioName(findPortfolio);
            }
        }
    }, [resultArray]);

    useEffect(() => {
        if (portfolioName?.portfolio?._id) {
            setPortfolioGroupId([portfolioName?.portfolio?._id]);
        }
    }, [portfolioName])


    const [isLoadingData, setIsLoadingData] = useState(false)
    useEffect(() => {
        if (!_.isEmpty(fetchAssetData)) {
            // setIsLoadingData(true)
            // dispatch(fetchAnalyticsData(fetchAssetData?._id, setIsLoadingData))
        }
    }, [fetchAssetData]);

    const data = codeName?.length && assets?.records?.length ?
        assets?.records?.map((item) => {
            return {
                ...item,
                data: item,
                id: item?.assetId ? item?.assetId : 'N/A',
                name: item?.assetName ? item?.assetName : '-',
                class: item?.class ? item?.class : '',
                subClass: item?.subClass ? item?.subClass : '',
                Strategy: item?.strategy ? item?.strategy : 'N/A',
                description: item?.description ? item?.description : '',
                countryName: item?.country?.countryName ? item?.country?.countryName : '',
                adjClose: item?.adjClose ? item?.adjClose?.toFixed(2) : 0,
                purchasePrice: item?.averagePurchasePrice ? item?.averagePurchasePrice?.toFixed(2) : '',
                purchaseDate: item?.purchaseDate ? dateConvert(item?.purchaseDate) : '',
                quantity: item?.totalQuantity ? item?.totalQuantity : '',
                Value: (item?.averagePurchasePrice * item?.totalQuantity) ? (item?.averagePurchasePrice * item?.totalQuantity)?.toFixed(2) : '',
            }
        })
        : []


    // Function to map myCodeName array to column objects
    const mapCodeNameToColumns = () => {
        // Define the 'select' column
        const selectColumn = {
            id: 'select',
            header: ({ table }) => (
                ''
            ),
            cell: ({ row }) => (
                // Cell content for the 'select' column
                <Checkbox
                    checked={row.original?.data?._id === fetchAssetData?._id}
                    onCheckedChange={(value) => { row.toggleSelected(!!value); handleRowSelected(row?.original) }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        };

        // Map the codeName array to columns
        const columns = codeName.map((code) => {
            // Define a regular data column
            return {
                accessorKey: code.name, // Ensure that the column ID matches 'code.name'
                header: ({ column }) => (
                    // Header content for the regular data column
                    <DataTableColumnHeader column={column} title={code.name} />
                ),
                cell: ({ row }) => (
                    // Cell content for the regular data column
                    <span className='flex items-center'>{row?.original[code.code] === 0 ? 0 : row?.original[code.code] ? row?.original[code.code] : '-'}</span>
                ),
                enableSorting: true,
            };
        });

        // Return an array containing the 'select' column and other mapped columns
        return [selectColumn, ...columns];
    };
    const columns = codeName?.length && data?.length ? mapCodeNameToColumns() : [];



    const handleRowSelected = async (rows) => {
        // Handle row selection
        await rows?.data && onCheckBtn(rows?.data)
    };


    // ***
    const [formData, setFormData] = useState({
        purchasePrice: '',
        quantity: '',
        purchaseDate: null
    })

    // strategy 
    const [strategyOp, setStrategyOp] = useState({ value: null, label: 'None' })
    const strategyOptions = strategy && strategy?.records?.length ? [{ value: null, label: 'None' }, ...strategy?.records?.map((items) => {
        return { value: items._id, label: items.name }
    })] : []


    useEffect(() => {
        if (!_.isEmpty(fetchAssetData)) {
            setFormData({
                purchasePrice: fetchAssetData.purchasePrice,
                purchaseDate: new Date(),
                quantity: fetchAssetData.quantity,
            })
        }
    }, [fetchAssetData])

    const selectedlabel = strategyOptions?.filter((so) => so?.label === fetchAssetData?.strategy)

    const onChangeStrategyOp = (obj) => {
        const getObj = JSON.parse(obj)
        setStrategyOp(getObj)
        setError({ ...error, ['strategy']: '' });
    }


    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        // Check if the entered value is negative
        if (name === 'purchasePrice' && parseFloat(value) < 0) {
            setError({ ...error, [name]: 'Value cannot be negative' });
            return;
        }
        if (name === 'quantity' && parseFloat(value) < 0) {
            setError({ ...error, [name]: 'Value cannot be negative' });
            return;
        }
        // Clear the error if the value is non-negative
        setError({ ...error, [name]: '' });

        if (name === 'purchaseDate') {
            // Use setFormData to update the date value
            setFormData({ ...formData, [name]: value });
        } else {
            // Use setFormData to update other fields
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.purchasePrice === '' || formData.purchasePrice === 0 || formData.purchasePrice === '0') {
            errors['purchasePrice'] = 'Please enter a valid price';
            isValid = false;
        }
        if (formData.purchaseDate === null) {
            errors['purchaseDate'] = 'Please select purchase Date';
            isValid = false;
        }
        if (formData.quantity === '' || parseFloat(formData.quantity) <= 0) {
            errors['quantity'] = 'Please enter quantity';
            isValid = false;
        }

        if (_.isEmpty(fetchAssetData)) {
            errors['saleAsset'] = 'Please select one Sale Asset';
            isValid = false;
        }

        if (!_.isEmpty(fetchAssetData) && !investedAssetHistory?.records?.length) {
            errors['transactionNot'] = 'Transaction Not Found';
            isValid = false;
        }

        if (!_.isEmpty(fetchAssetData) && investedAssetHistory?.records?.length && transactionId.length === 0) {
            errors['transactionNotSelect'] = 'Please select Transaction';
            isValid = false;
        }

        // total  quantity value 
        if (fetchTransactions.length) {
            let totalQuantity = fetchTransactions.reduce((acc, item) => acc + item.quantity, 0);
            if (totalQuantity < formData.quantity) {
                errors['quantity'] = 'Your entered quantity more then selected quantity';
                isValid = false;
            }
        }

        // total purchase price 
        // if (fetchTransactions.length) {
        //     let totalQuantity = fetchTransactions.reduce((acc, item) => acc + item.purchasePrice, 0);
        //     if (totalQuantity < formData.purchasePrice){
        //         errors['purchaseDate'] = 'Your entered price more then selected purchase price';
        //         isValid = false;
        //     }
        // }

        // if (strategyOp === null) {
        //     errors['strategy'] = 'Please select strategy';
        //     isValid = false;
        // }
        setError(errors);
        return isValid;
    };

    const onSave = (e) => {
        if (handleValidation()) {
            if (_.isEmpty(fetchAssetData)) {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select one Asset' }))
            } else {
                const prepareData = {
                    asset_id: fetchAssetData && fetchAssetData?.asset_id,
                    price: Number(formData.purchasePrice),
                    quantity: Number(formData.quantity),
                    date: moment(formData.purchaseDate).format('YYYY-MM-DD'),
                    linkedTransactions: transactionId
                }
                dispatch(addInvestedAssetSell(prepareData))
            }
            setFormData({
                purchasePrice: '',
                quantity: '',
                purchaseDate: ""
            })
            setStrategyOp(null)
            hideModel();

        }
    }


    const onCheckTransaction = (item, value, isAll, allData) => {
        if (isAll) {
            if (value) {
                setTransactionId((prev) => {
                    return allData.map((i) => i.id)
                })
                setFetchTransactions((prev) => {
                    return allData.map((i) => i)
                })
            } else {
                setTransactionId([])
                setFetchTransactions([])
            }
        } else {
            if (value) {
                setFetchTransactions([...fetchTransactions, item])
                setTransactionId([...transactionId, item.id])
            } else {
                setFetchTransactions((prev) => {
                    return prev.filter((i) => i.id !== item.id)
                })
                setTransactionId((prev) => {
                    return prev.filter((i) => i !== item.id)
                })
            }
        }
    }


    return (
        <div>
            <div className="grid grid-cols-1 gap-5 mb-5">
                <DataTableWithCustomPagination data={data} columns={columns} filterName={'Name'} loadData={isLoadingData} isSearchHide={true}
                    isCollViewHide={true}
                    setPerPage={setPerPage} perPage={perPage} totalRecords={totalRecords} currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages}
                />
                <div className={error['saleAsset'] ? "text-red text-[12px]" : ''}>{error['saleAsset'] ? error['saleAsset'] : null}</div>
            </div>
            {
                error['transactionNot'] ? <div className='grid grid-cols-1 gap-5 mb-5'>
                    <Badge variant={'destructive'} className={"px-[46.5%] py-[5px] whitespace-nowrap"}>{error['transactionNot']}</Badge>
                </div> : ''
            }
            <div className="grid grid-cols-1 gap-1 mb-5">
                <div>
                    {updateId !== null && <h3 className='m-0 font-semibold'>Transaction:</h3>}
                </div>
                {updateId !== null && <SellAssetTransactionsTableContent
                    transactionId={transactionId}
                    onCheckTransaction={onCheckTransaction}
                    fetchAssetData={fetchAssetData} />}
                <div className={error['transactionNotSelect'] ? "text-red text-[12px]" : ''}>{error['transactionNotSelect'] ? error['transactionNotSelect'] : null}</div>
            </div>

            <div className="grid grid-cols-2 gap-9 ">
                <div className="form-group">
                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                    Sell Price:<span style={{ color: 'red' }}>*</span>
                    </Label>
                    {false ? <Input className={`form-control py-2 h-[34px] shadow-none ${error['purchasePrice'] && 'is-invalid'}`} min={0} name='purchasePrice' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="number" value={formData.purchasePrice} /> :
                        formData.purchasePrice === "Fetching" ?
                            {/* <Lottie animationData={animationData} style={{
                    height: '50px', width: '100px', position: "absolute",
                    top: "-14px",
                    left: "0px"
                }} /> */}
                            : <Input className={`form-control py-2 h-[34px] shadow-none ${error['purchasePrice'] && 'is-invalid'}`} min={0} name='purchasePrice' onChange={(e) => onChangeHandel(e)} id="inputEmail3" type="number" value={formData.purchasePrice} />
                    }
                    <div className={error['purchasePrice'] ? "text-red text-[12px]" : ''}>{error['purchasePrice'] ? error['purchasePrice'] : null}</div>
                </div>
                <div className="form-group ">
                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                    Sell Date:<span style={{ color: 'red' }}>*</span>
                    </Label>
                    <DatePickerCustomInput onDateSelect={(date) => onChangeHandel({ target: { name: 'purchaseDate', value: date } })}
                        placeholderText='YYYY-MM-DD'
                        selectedFromDate={formData.purchaseDate}
                        value={formData.purchaseDate}
                        disabled={true}
                    />
                    <div className={error['purchaseDate'] ? "text-red text-[12px]" : ''}>{error['purchaseDate'] ? error['purchaseDate'] : null}</div>
                </div>
                <div className='form-group'>
                    <Label htmlFor="PurchaseQuantity" className="block mb-2.5 text-textV1 font-500">Sell Quantity:<span style={{ color: 'red' }}>*</span></Label>
                    <Input className={`form-control py-2 h-[34px] shadow-none ${error['quantity'] && 'is-invalid'}`} id="inputEmail3" name='quantity' onChange={(e) => onChangeHandel(e)} type="number" value={formData.quantity} />
                    <div className={error['quantity'] ? "text-red text-[12px]" : ''}>{error['quantity'] ? error['quantity'] : null}</div>

                </div>
                {!false && <div className="form-group">
                    <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                        Select strategy
                    </Label>
                    <Select
                        onValueChange={(e) => onChangeStrategyOp(e)}
                        defaultValue={strategyOp}
                        disabled
                    // value={fetchId !== null ? SubName.classOption?.id ? SubName.classOption?.id : "" : SubName.classOption?.id}
                    >
                        <SelectTrigger className="w-full text-sm h-[34px] shadow-none border-borderV1">
                            <SelectValue
                                className='text-textV1'
                                placeholder="Select strategy"
                            >
                                {selectedlabel[0]?.label ? selectedlabel[0]?.label : "Select strategy"}
                                {/* {fetchId !== null? SubName.classOption?.label ? SubName.classOption?.label : "" : SubName.classOption?.label} */}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {
                                    strategyOptions && strategyOptions.length > 0 ? strategyOptions.map((item, index) => {
                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                }
                            </SelectGroup>
                        </SelectContent>
                    </Select>

                    <div className={` ${error['strategy'] ? "text-red text-[12px]" : ''} px-2 py-1 text-sm`}>{error['strategy'] ? error['strategy'] : null}</div>
                </div>}
            </div>

            <div className='flex justify-end items-center space-x-3 pt-3'>
                <div className='col-auto'>
                    <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                </div>
                <div className='col-auto'>
                    <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={hideModel} >Cancel</Button>
                </div>
            </div>
        </div>
    )
}

export default SellAsset

