import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { fetchAssetsHistory } from '../../../../store/actions/assetHistoryAction';
import {
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { Badge, generateBadgeVariant } from '../../../../components/ui/badge';
import { dateOrTimeConvert } from '../../../../shared/common/dateFormate';
import Loading from '../../../../shared/common/Loading';
import { IssuerContext } from '../Issuer';
import { fetchIssuerHistory } from '../../../../store/actions/issuerHistoryAction';

const IssuerActionsContent = () => {
    // const { isLoading } = useSelector(state => state)
    const { fetchAssetData } = useContext(IssuerContext);
    const dispatch = useDispatch();
    const [isLoading, seIsLoading] = useState(false)
    const [history, setHistory] = useState([])
    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState(
        []
    )
    // console.log('history', history)
    const [columnVisibility, setColumnVisibility] =
        useState({})
    const [rowSelection, setRowSelection] = useState({})

    const [fetchData, setFetchData] = useState(false)
    useEffect(() => {
        if (!_.isEmpty(fetchAssetData) && fetchData === false) {
            dispatch(fetchIssuerHistory(fetchAssetData._id, setHistory, seIsLoading))
        } else {
            setHistory([])
        }
    }, [fetchAssetData])

    useEffect(() => {
        if (!_.isEmpty(fetchAssetData) && fetchData) {
            setFetchData(false)
            dispatch(fetchIssuerHistory(fetchAssetData._id, setHistory))
        } else {
            setHistory([])
        }
    }, [fetchData])

    function formatDate(dateString) {
        const date = new Date(dateString);
        const formattedTime = date.toLocaleString('en-GB', { timeZone: 'UTC' });
        return `${formattedTime}`;
    }

    function renderDetails(details) {
        return (

            <div className="capitalize">
                {details?.name && <h6 className='mb-1'><span className='text-textV1'>Name :</span> {details.name}</h6>}
                {details?.description && <h6 className='mb-1'><span className='text-textV1'>Description :</span> {details.description}</h6>}
                {details?.purchaseDate && <h6 className='mb-1'><span className='text-textV1'>Purchase Date :</span> {formatDate(details.purchaseDate)}</h6>}
                {details?.removeDate && <h6 className='mb-1'><span className='text-textV1'>Remove Date :</span> {formatDate(details.removeDate)}</h6>}
                {details?.reason && <h6 className='mb-1'><span className='text-textV1'>Reason :</span> {details.reason}</h6>}
                {details?.note && <h6 className='mb-1'><span className='text-textV1'>Note :</span> {details.note}</h6>}
                {details?.removePrice && <h6 className='mb-1'><span className='text-textV1'>Remove Price :</span> {details.removePrice}</h6>}
                {details?.purchasePrice && <h6 className='mb-1'><span className='text-textV1'>Purchase Price :</span> {details.purchasePrice}</h6>}
                {details?.quantity && <h6 className='mb-1'><span className='text-textV1'>Quantity :</span> {details.quantity}</h6>}
            </div>
        );
    }

    const data = history.length ? history.map((event, i) => {
        return ({
            id: i + 1,
            date: dateOrTimeConvert(event.date),
            action: event.action,
            details: event.details
        })
    }) : []

    const columns = [
        {
            accessorKey: "id",
            header: "Id",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("id")}</div>
            ),
        },
        {
            accessorKey: "date",
            header: "Date",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("date")}</div>
            ),
        },
        {
            accessorKey: "action",
            header: "Action",
            cell: ({ row }) => {
                const variant = generateBadgeVariant(row.getValue("action"))
                return <Badge variant={variant} className={""}>{row.getValue("action")}</Badge>
            },
        },
        {
            accessorKey: "details",
            header: "Details",
            cell: ({ row }) => {
                return renderDetails(row.getValue("details"))
            },
        },
    ]

    const table = useReactTable({
        data,
        columns,
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        onRowSelectionChange: setRowSelection,
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            sorting,
            columnFilters,
            columnVisibility,
            rowSelection,
            pagination: {
                pageIndex: 0,
                pageSize: data.length,
            }
        },
    })

    return (
        <div className="w-full max-h-[100%] " >
            <div className="flex items-center pb-4 gap-4 justify-between">
                <h3 className='text-lg font-semibold leading-none tracking-tight'>History</h3>
                <Input
                    placeholder="Search filter..."
                    value={(table.getColumn("action")?.getFilterValue() || "")}
                    onChange={(event) =>
                        table.getColumn("action")?.setFilterValue(event.target.value)
                    }
                    className="h-8 w-full sm:w-[150px] lg:w-[250px]"
                />
            </div>
            <div className="rounded-sm border max-h-[700px] overflow-y-auto">
                <Table className="min-w-[800px] ">
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id || headerGroup._id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <TableHead key={header.id}>
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                        </TableHead>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody className=''>
                        {isLoading ?
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className="h-24 text-center"
                                >
                                    <Loading />
                                </TableCell>
                            </TableRow>
                            : table.getRowModel().rows?.length ? (
                                table.getRowModel().rows.map((row) => (
                                    <TableRow
                                        key={row.id || row._id}
                                        data-state={row.getIsSelected() && "selected"}
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <TableCell className="text-xs" key={cell.id || cell._id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={columns.length}
                                        className="h-24 text-center"
                                    >
                                        No results.
                                    </TableCell>
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

export default IssuerActionsContent