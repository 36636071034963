import React, { useContext, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import { CanvasContext } from "../CanvasContainer.jsx"
import ImageElement from "./ImageElement.jsx";
import TextElement from "./TextElement.jsx";
import { resizeHandleClasses } from "../helper/canvasUtils.js";
import DividerElement from "./DividerElement.jsx";
import AttachmentImageElement from "./AttachmentImageElement.jsx";
// const componentMap = {
//   TEXT: <TextElement/>,
//   CONTENT: <TextElement/>,
//   IMAGE: <ImageElement/>
// };

const getEnableResize = (type) => {
  return {
    bottom: true,
    bottomLeft: true,
    bottomRight: true,
    top: true,
    topLeft: true,
    topRight: true,
    left: true,
    right: true
  };
};

const CanvasComponent = (props) => {
  const { state, actions } = useContext(CanvasContext);
  const { dimension, position, content, id, type, isImage } = props;
  const [showGrids, setShowGrids] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const isDragged = useRef(false);
  const targetRef = useRef(null)
  const activeSelection = state?.activeSelection;

  const onBlur = (event) => {
    const toolbarElement = document.querySelector("#toolbar");
    if (
      event.currentTarget.contains(event?.relatedTarget) ||
      toolbarElement?.contains(event?.relatedTarget)
    ) {
      return;
    }
    setIsReadOnly(true);
    actions?.setEnableQuillToolbar(false);
    if (id && activeSelection) {
      activeSelection.delete(id);
      actions?.setActiveSelection(new Set(activeSelection));
    }
  };

  const componentMap = {
    TEXT: TextElement,
    CONTENT: TextElement,
    ATTACHMENT: TextElement,
    CUSTOMFIELD: TextElement,
    TAG: TextElement,
    IMAGE: ImageElement,
    DIVIDER: DividerElement,
    ATTACHMENTIMAGE: AttachmentImageElement
  };
  const getComponent = () => {
    const Component = type && componentMap[type];
    if (!Component || !id) return null;
    return (
      <Component
        key={id}
        id={id}
        type={type}
        position={position}
        dimension={dimension}
        content={content}
        isReadOnly={isReadOnly}
        isImage={isImage}
      />
    );
  };

  const style = {
    outline: "none",
    border: `2px solid ${(id && state?.activeSelection.has(id)) || showGrids || isDragged.current
      ? "#21DEE5"
      : "transparent"
      }`,
  };

  const vsStyle = {
    position: 'absolute',
    top: '-30px',
    right: '50%',
    zIndex: 999,
  }

  const onMouseEnter = () => {
    setShowGrids(true);
  };

  const onMouseLeave = () => {
    setShowGrids(false);
  };

  const onFocus = () => {
    if (id) {
      actions?.setActiveSelection(new Set(state?.activeSelection.add(id)));
    }
  };

  const onKeyDown = (event) => {
    if (!isReadOnly) event.stopPropagation();
    // if (event.ctrlKey && event.key === 'Control') {
    //   // If Ctrl key is pressed and mouse left button is clicked, select the element by its ID
    //   window.addEventListener('mousedown', onMouseDown);
    // }
  };



  const handleClass =
    id && state?.activeSelection.has(id) && state?.activeSelection.size === 1
      ? "showHandles"
      : "";

  const onDoubleClick = () => {
    // if (!isReadOnly) return;
    // setIsReadOnly(false);
    // actions?.setEnableQuillToolbar(true);
  };

  // console.log('dimension', dimension)
  // console.log('position', position)

  return (
    <Rnd
      bounds="parent"
      style={style}
      size={{ width: dimension?.width || 0, height: dimension?.height || 0 }}
      position={{ x: position?.left || 0, y: position?.top || 0 }}
      onDragStart={() => {
        isDragged.current = true;
      }}
      onDragStop={(e, d) => {
        isDragged.current = false;
        actions?.updateCanvasData({ id, position: { left: d.x, top: d.y } });
      }}
      resizeHandleWrapperClass={handleClass}
      resizeHandleClasses={resizeHandleClasses}
      onResize={(e, direction, ref, delta, position) => {
        actions?.updateCanvasData({
          id,
          dimension: { width: ref.style.width, height: ref.style.height },
          position: { top: position.y, left: position.x }
        });
      }}
      enableResizing={getEnableResize(type)}
      minWidth={6}
      minHeight={6}
      disableDragging={!isReadOnly}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDoubleClick={onDoubleClick}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={0}
      lockAspectRatio={type === "IMAGE" || type === 'ATTACHMENTIMAGE'}
      dragGrid={[30, 30]} // Add snap to grid functionality
    >
      <div className="item-container" id={'item-' + id} ref={targetRef}>{getComponent()}</div>
    </Rnd>
  );
};

export default CanvasComponent;

// useEffect(() => {
//   if (rotation !== 0) {
//     const getElement = document.getElementById('item-' + id);
//     if (getElement) {
//       getElement.parentElement.style.transform = rotation
//     }
//   }
// }, [rotation])
{/* <Moveable
        target={targetRef.current}
        rotatable={true}
        throttleRotate={0}
        rotationPosition={"top"}
        onRotate={e => {
          e.target.style.transform = e.drag.transform;
        }}
      /> */}


// const onMouseDown = (event) => {
//   if (event.button === 0 && event.ctrlKey) { // Check if left mouse button and Ctrl key is pressed
//     console.log('dimension, position, content, id, type', dimension, position, content, id, type)
//     console.log('state', state)
//     // debugger
//     // actions?.setActiveSelection(new Set(state?.activeSelection.add(id)));
//     // if (id) {
//     //   if (activeSelection.has(id)) {
//     //     // If the element is already selected, deselect it
//     //     activeSelection.delete(id);
//     //   } else {
//     //     // If the element is not selected, add it to the selection
//     //     actions?.setActiveSelection(new Set(state?.activeSelection.add(id)));
//     //   }
//     //   // actions?.setActiveSelection(new Set(activeSelection));
//     // }
//   }
//   // Remove event listener after mouse click
//   window.removeEventListener('mousedown', onMouseDown);
// };

// useEffect(() => {
//   // Add event listener for keyboard events
//   document.addEventListener('keydown', onKeyDown);
//   return () => {
//     // Remove event listener when component unmounts
//     document.removeEventListener('keydown', onKeyDown);
//   };
// }, []); // Run only once when component mounts