import { financeExchangesActionType } from '../../constants';

const financeExchangesReducer = (state = [], action) => {
    switch (action.type) {
        case financeExchangesActionType.FETCH_FINANCE_EXCHANGES_V2:
            return action.payload;
        default:
            return state;
    }
};
export default financeExchangesReducer