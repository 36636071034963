import { countriesActionType } from '../../constants';

const countriesReducer = (state = {}, action) => {
    switch (action.type) {
        case countriesActionType.FETCH_COUNTRIES:
            return action.payload;
        case countriesActionType.FETCH_COUNTRIE:
            return action.payload;
        case countriesActionType.ADD_COUNTRIES:
            return action.payload;
        case countriesActionType.UPDATE_COUNTRIES:
            return action.payload;
        case countriesActionType.DELETE_COUNTRIES:
            return action.payload;
        default:
            return state;
    }
};
export default countriesReducer