import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Button } from '../../../../components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/ui/table';
import { Input } from '../../../../components/ui/input';
import { FromDatePicker } from '../../../../components/ui/DatePickerNew';
import Loading from '../../../../shared/common/Loading';
import { addManuallyData } from '../../../../store/actions/BYODAction';
import ValueSelect from '../../byod/selector/ValueSelect';
import { AddNoteContent } from '../../../../store/actions/researchNoteContentAction';
import { addTost } from '../../../../store/actions/toastAction';
import { toastifyType } from '../../../../constants';
import { DatePickerCustomInput } from '../../../../components/ui/DatePickerCustomInput';
import moment from 'moment-js';


const CustomFieldTable = (props) => {
  const { editData, contentLoad, updatedCustomData, setAllCustomRecode, setEditData, setUpdatedCustomData, } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [dateError, setDateError] = useState({})
  const [activeField, setActiveField] = useState(null);
  const [validation, setValidation] = useState({
    AssetID: true,
    ShortName: true,
    Value: true,
  });
  useEffect(() => {
    setData([]);
    if (editData && editData?.customFields?.length) {
      // console.log('editData', editData, updatedCustomData)
      const getDate = editData?.customFields.map((item) => {
        // OLD 
        // const getObj = updatedCustomData !== null && updatedCustomData.length ? updatedCustomData.find((field) => field.customField === item._id) : null
        // NEW 
        // debugger
        const getObj = editData?.customFieldRecordData !== null && editData?.customFieldRecordData?.length ? editData?.customFieldRecordData.find((field) => field.customField?._id === item._id) : null
        if (getObj !== null && getObj !== undefined) {
          return {
            id: item._id,
            CustomFieldID: item.name,
            AssetID: editData?.asset?.assetId ? editData?.asset?.assetId : editData?.asset?.assetId ? editData?.asset?.assetId : '',
            // ShortName: editData?.asset?.symbol ? editData?.asset?.symbol : '',
            code: item.code,
            group: item.group,
            type: item.type,
            subType: item.subType,
            Value: (item.type === "Ordinal" || item.type === "Categorical") ? { value: getObj.value, label: getObj.value, items: getObj.value } : getObj.value,
            StartDate: moment(getObj.startDate)?._date,
            EndDate: moment(getObj.endDate)?._date,
            Comment: getObj.comment,
            Description: getObj.description,
            isSave: false,
            searchData: []
          }
        } else {
          return {
            id: item._id,
            CustomFieldID: item.name,
            AssetID: editData?.asset?.assetId ? editData?.asset?.assetId : editData?.asset?.assetId ? editData?.asset?.assetId : '',
            // ShortName: editData?.asset?.symbol ? editData?.asset?.symbol : '',
            code: item.code,
            group: item.group,
            type: item.type,
            subType: item.subType,
            Value: 0,
            StartDate: null,
            EndDate: null,
            Comment: '',
            Description: '',
            isSave: false,
            searchData: []
          }
        }

      })
      // console.log('getDategetDategetDategetDategetDate', getDate)
      setData(getDate);
      setAllCustomRecode(getDate);
    }
  }, [editData?._id, editData?.customFieldRecordData]);


  const handleSaveRow = (info, index) => {
    setDateError({})
    if (info.StartDate === null || info.EndDate === null) {
      if (info.StartDate === null) {
        const errorMs = {
          index,
          errorType: 'StartDate',
          message: 'Please select start date!'
        }
        setDateError((prev) => {
          return {
            ...prev, StartDateError: errorMs

          }
        })
      } else {
        setDateError((prev) => {
          return {}
        })
      }
      if (info.EndDate === null) {
        const errorMs = {
          index,
          errorType: 'EndDate',
          message: 'Please select end date!'
        }
        setDateError((prev) => {
          return {
            ...prev, EndDateError: errorMs

          }
        })
      }

    } else {
      setDateError({})
      const prepareData = {
        AssetID: info.AssetID,
        // ShortName: data.ShortName,
        Value: (info.type === "Ordinal" || info.type === "Categorical") ? info.Value.label ? info.Value.label : info.Value : info.Value,
        StartDate: info.StartDate !== null ? new Date(info.StartDate) : new Date,
        EndDate: info.EndDate !== null ? new Date(info.EndDate) : new Date,
        Comment: info.Comment,
        Description: info.Description
      }
      let prepareAddNoteData = {
        researchNoteId: editData?._id,
        // old 
        // customFieldRecordData: editData?.customFields?.filter((item) => data.id !== item._id)?.map((item) => item._id)
        // new 
        customFieldRecordData: editData?.customFieldRecordData?.filter((item) => info.id !== item?.customField?._id)?.map((item) => item._id)

      }
      const updateData = [...data]
      updateData[index]['StartDate'] = info.StartDate !== null ? info.StartDate : new Date;
      updateData[index]['EndDate'] = info.EndDate !== null ? info.EndDate : new Date;
      setData(updateData)
      dispatch(addManuallyData(info.id, prepareData, null, null, info.group)).then((res) => {
        if (res?.data) {
          // let getIds = res?.data?.upserted.map((item) => item?._id)
          prepareAddNoteData = { ...prepareAddNoteData, customFieldRecordData: [...prepareAddNoteData.customFieldRecordData, res?.data?._id] }
          dispatch(AddNoteContent(prepareAddNoteData, null)).then((res) => {
            if (res?.data?.data?.researchNote) {
              let updateData = {}
              setEditData((prev) => {

                updateData = {
                  ...prev, tags: res?.data?.data?.tags,
                  contentId: res?.data?.data?._id,
                  attachmentsData: res?.data?.data?.attachments,
                  customFieldRecordData: res?.data?.data?.customFieldRecordData,
                  // content: res?.data?.data?.content,
                  // title: res?.data?.data?.title,
                  customFields: res?.data?.data?.customFields,
                };
                return updateData
              })
            }
            if (res?.data?.data?.customFieldRecordData) {
              setUpdatedCustomData((prev) => {
                let updateData = prev !== null ? [...prev, res?.data?.data?.customFieldRecordData] : res.data?.data?.customFieldRecordData
                return updateData
              })
            } else {
              setUpdatedCustomData(null)
            }
            if (data.length) {
              const newData = data.map((item) => {
                if (item.id === info.id) {
                  return { ...item, isSave: false };
                } else {
                  return { ...item };
                }

              });
              setData(newData);
            }
          })
        } else {
          dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Add Manually Data Then Updated Id Not Found' }))
        }
      })
    }


  }

  const handleDateChange = (date, id, field) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        const isSaveDone = (item.Value === '0' || item.Value === 0 || item.Value === '') && item.StartDate === null && item.EndDate === null ? false : true
        return { ...item, [field]: date, isSave: true };
      } else {
        return { ...item };
        // return {
        //   ...item,
        //   ShortName: '',
        //   Value: 0,
        //   StartDate: null,
        //   EndDate: null,
        //   Comment: '',
        //   Description: '',
        //   isSave: false,
        //   searchData: []
        // };
      }

    });
    setData(newData);
  }

  const handleInputChange = async (e, id, field, isSelector) => {
    let searchData = [];
    let values = isSelector ? e : e?.target?.value;
    const newData = data.map((item) => {
      if (item.id === id) {
        const isSaveDone = (item.Value === '0' || item.Value === 0 || item.Value === '') && item.StartDate === null && item.EndDate === null ? false : true
        return { ...item, [field]: values, searchData: values === '' ? [] : searchData, isSave: true };
      } else {
        return { ...item };
        // isSave: false 
        // return {
        //   ...item,
        //   ShortName: '',
        //   Value: 0,
        //   StartDate: null,
        //   EndDate: null,
        //   Comment: '',
        //   Description: '',
        //   isSave: false,
        //   searchData: []
        // };
      }

    });

    // Reset validation only for the current field and id
    setValidation((prevValidation) => ({
      ...prevValidation,
      [`${field}-${id}`]: true,
    }));

    setData(newData);
  };

  const onValueSelect = (id, editData, index, item) => {
    handleInputChange(item.label, id, 'Value', true)
  }

  const renderValueUI = (row, index) => {
    if (row.type === "Ordinal" || row.type === "Categorical") {
      return (<ValueSelect editData={row} row={row} index={index} id={row.id} isUpdate={false} onValueSelect={onValueSelect} />)
    } else {
      return (<Input
        className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
        value={row.Value}
        onChange={(e) => handleInputChange(e, row.id, 'Value')}
      />)
    }
  }


  return (
    <div>
      {contentLoad ? <Loading /> : <div className="table-wrapper">
        <div className="rounded-sm border my-4">
          {/* tableContainerClassName="!overflow-visible" */}
          <Table className="min-w-[1000px] !overflow-visible" tableContainerClassName="!overflow-visible" >
            <TableHeader>
              <TableRow className="bg-[#F9F9F9]">
                <TableHead >
                  Custom Field
                </TableHead>
                <TableHead >
                  Asset ID
                </TableHead>
                <TableHead >
                  Value
                </TableHead>
                <TableHead >
                  Start Date
                </TableHead>
                <TableHead >
                  End Date
                </TableHead>
                <TableHead >
                  Comment
                </TableHead>
                <TableHead >
                  Description
                </TableHead>
                <TableHead >
                  Action
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.map((row, index) => {
                const isError = !validation['AssetID'] && !row['AssetID'].trim();
                const errorMessage = `${'AssetID'} is required.`;
                return (
                  <TableRow key={index}>
                    <TableCell className="p-2">
                      {row.CustomFieldID}
                    </TableCell>
                    <TableCell className="p-2">
                      {row.AssetID}
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="">
                        {renderValueUI(row, index)}
                        {!validation.Value && !row.value && (
                          <div className="text-red text-[10px]">
                            Value is required.
                          </div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell className="p-2 relative">
                      <div className="relative">
                        <DatePickerCustomInput
                          onDateSelect={(date) => handleDateChange(date, row.id, 'StartDate')}
                          placeholderText='YYYY-MM-DD'
                          selectedFromDate={row.StartDate}
                          mainClassName={'body-table'}
                          value={row.StartDate}
                        />
                        {dateError?.StartDateError ? dateError?.StartDateError.index === index ? <div className='text-red text-[10px]'>{dateError?.StartDateError.message}</div> : ' ' : ''}
                      </div>
                    </TableCell>
                    <TableCell className="p-2 relative">
                      <div className="relative">
                        <DatePickerCustomInput
                          onDateSelect={(date) => handleDateChange(date, row.id, 'EndDate')}
                          placeholderText='YYYY-MM-DD'
                          minDate={row.StartDate}
                          selectedFromDate={row.StartDate}
                          value={row.EndDate}
                        />
                        {dateError?.EndDateError ? dateError?.EndDateError.index === index ? <div className='text-red text-[10px]'>{dateError?.EndDateError.message}</div> : ' ' : ''}
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="">
                        <Input
                          className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
                          placeholder="Enter Comment"
                          value={row.Comment}
                          onChange={(e) => handleInputChange(e, row.id, 'Comment')}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="">
                        <Input
                          className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
                          placeholder="Enter Description"
                          value={row.Description}
                          onChange={(e) => handleInputChange(e, row.id, 'Description')}
                        />
                      </div>
                    </TableCell>
                    <TableCell className="">
                      <div className="flex items-center justify-end">

                        <Button className="w-[80px] hover:bg-[#F5F5F5]" disabled={!row.isSave} variant="outline" size="sm" onClick={() => handleSaveRow(row, index)}>
                          Save
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
        <div className="table-toolbar flex items-center justify-end gap-2 mb-4">
          {/* <Button className="w-[80px]" size="sm" onClick={() => handleAddRow()}>
            <span><IconAdd className="w-4 h-4" /></span> Add
          </Button> */}
          {/* <Button className="w-[80px] hover:bg-[#F5F5F5]" variant="outline" size="sm" onClick={() => handleSaveRow()}>
            Save
          </Button> */}
        </div>
      </div>}
    </div>
  )
}

export default CustomFieldTable

{/* <Button
                          className="w-6 h-6 !bg-transparent !border-none !p-0"
                          onClick={() => handleDeleteRow(row)}
                        >
                          <Trash2 className='w-5 h-5 text-red' />
                        </Button> */}