import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers';
import DownloadChart from '../../shared/DownloadChart';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, BarChart, ToolboxComponent, SVGRenderer]);

export default function BasicLineChart(props) {
    const { analyticReports, updateId, isAutoChart, isReports, index, refEl } = props;
    const [chartData, setChartData] = React.useState([]);

    React.useEffect(() => {
        if (updateId === null && isReports === null) {
            setChartData([]);
        } else {
            setChartData(analyticReports);
        }
    }, [analyticReports]);

    let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
    let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];

    const labels = dateLabels ? dateLabels : '';
    let data;

    if (isReports) {
        data = {
            labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
            datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
                return {
                    ...item,
                    borderColor: item.backgroundColor,
                    borderWidth: 1,
                };
            }) : [],
        };
    } else {
        data = {
            labels,
            datasets: [
                {
                    label: labels,
                    data: chartValues,
                    fill: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 159, 64, 0.5)',
                        'rgba(255, 205, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 0, 223, 0.5)',
                    ],
                    borderColor: [
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(255, 0, 223)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
    }

    const transformedData = data.datasets.map((dataset) => ({
        id: dataset.label,
        color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
        data: dataset.data.map((value, index) => ({
            x: data.labels[index],
            y: parseInt(Number(value)),
        })),
    }));

    const transformedChartData = transformedData.flatMap(entry =>
        entry.data.map(dataPoint => ({
            name: `${entry.id} - ${dataPoint.x}`,
            value: parseInt(Number(dataPoint.y))
        }))
    );

    const option = {
        tooltip: {
            trigger: 'axis',
            axis: 'auto',
            axisPointer: { type: 'shadow' },
            displayMode: 'single',
            renderMode: 'auto',
            displayMode: 'single',
            renderMode: 'auto',
            // Confining tooltip to the body
            confine: true,
            align: 'center',
            backgroundColor: '#001F3D',
            appendToBody: true,
            textStyle: {
                fontSize: 12, // Adjust the font size as needed
                color: '#FFFFFF',
            }
        },
        legend: {
            type: 'scroll',
            // orient: 'vertical',
            right: 0,
            left: 0,
            top: 0,
            // bottom: 20,
        },
        xAxis: [{
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            data: data.labels
        }],
        yAxis: [{
            type: 'value',
            // name: 'Value',
            position: 'left'
        }],
        // toolbox: {
        //     feature: {
        //         saveAsImage: { show: true, },
        //         type: 'png'
        //         // magicType: { show: true, type: ['line', 'bar'] },
        //         // dataView: { show: true, readOnly: false },
        //         // restore: { show: true }
        //     },
        //     top: 20
        // },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        series: transformedData.map(dataset => ({
            name: dataset.id,
            type: 'bar',
            smooth: true,
            data: dataset.data.map(dataPoint => dataPoint.y)
        }))
    };

    return (
        <>
        {data.datasets.length && data.labels.length ?
          <>
            <DownloadChart chartRef={refEl} chartData={data} chartId={"echarts-chart" + index} echarts={echarts} />
            <ReactEChartsCore
              className={"echarts-chart" + index  + ' ' + 'css-13aj3tc-MuiChartsSurface-root'}
              ref={refEl}
              echarts={echarts}
              style={{ width: '100%', height: '100%' }}
              option={option}
            />
  
          </> : <> <div>No Data Available</div>  </>}
  
      </>
    );
}

// =====================================================

// import * as React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';
// import DownloadChart from '../../shared/DownloadChart';

// export default function BarChartCustom(props) {
//   const { analyticReports, updateId, isAutoChart, isReports, refEl } = props;
//   const [chartData, setChartData] = React.useState([])
//   React.useEffect(() => {
//     if (updateId === null && isReports === null) {
//       setChartData([])
//     } else {
//       setChartData(analyticReports)
//     }
//   }, [analyticReports])
//   const chartRef = React.useRef(null)



//   let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
//   let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];
//   const labels = dateLabels ? dateLabels : '';
//   let data = {
//     labels: [],
//     datasets: []
//   }
//   if (isReports) {
//     data = {
//       labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
//       datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
//         return {
//           ...item, borderColor: item.backgroundColor,
//           borderWidth: 1
//         }
//       }) : []
//     }
//   } else {
//     data = {
//       labels,
//       datasets: [
//         {
//           label: labels,
//           data: chartValues,
//           fill: true,
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.5)',
//             'rgba(255, 159, 64, 0.5)',
//             'rgba(255, 205, 86, 0.5)',
//             'rgba(75, 192, 192, 0.5)',
//             'rgba(54, 162, 235, 0.5)',
//             'rgba(153, 102, 255, 0.5)',
//             'rgba(255, 0, 223, 0.5)'
//           ],
//           borderColor: [
//             'rgb(255, 99, 132)',
//             'rgb(255, 159, 64)',
//             'rgb(255, 205, 86)',
//             'rgb(75, 192, 192)',
//             'rgb(54, 162, 235)',
//             'rgb(153, 102, 255)',
//             'rgb(255, 0, 223)'
//           ],
//           borderWidth: 1
//         },
//       ],
//     };
//   }

//     const transformedData = data.datasets.map((dataset) => ({
//         id: dataset.label,
//         color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
//         data: dataset.data.map((value, index) => ({
//             x: data.labels[index],
//             y: Number(value),
//         })),
//     }));

//     console.log(transformedData);

//   return (
//     <>
//       {data.datasets.length && data.labels.length ?
//         <>
//           <DownloadChart chartData={data} chartRef={refEl} />
          
//           <BarChart
//           ref={refEl}
//             xAxis={[{ scaleType: 'band', data: data?.labels?.length ? data?.labels.map(Number) : [] }]}
//             series={data.datasets?.length
//               ? data?.datasets?.map(dataset => ({
//                 data: dataset.data?.length ? dataset?.data?.map(Number) : [4.5, 3.2, 5.6], // Convert string values to numbers
//                 // label: dataset?.label ? dataset?.label : ''
//               }))
//               : []}
//             width={500}
//             height={300}
//           /> 
//           </> : <div>No Data Available</div> }
//     </>
//   );
// }

// =====================================================

// import React from 'react';
// import { ResponsiveBar } from '@nivo/bar';
// import DownloadChart from '../../shared/DownloadChart';

// export default function BarChartCustom(props) {
//   const { analyticReports, updateId, isAutoChart, isReports, refEl } = props;
//   const [chartData, setChartData] = React.useState([]);
//   React.useEffect(() => {
//     if (updateId === null && isReports === null) {
//       setChartData([]);
//     } else {
//       setChartData(analyticReports);
//     }
//   }, [analyticReports]);
//   const chartRef = React.useRef(null);

//   let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
//   let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];
//   const labels = dateLabels ? dateLabels : '';
//   let data = {
//     labels: [],
//     datasets: [],
//   };
//   if (isReports) {
//     data = {
//       labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
//       datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map((item) => {
//         return {
//           ...item,
//           borderColor: item.backgroundColor,
//           borderWidth: 1,
//         };
//       }) : [],
//     };
//   } else {
//     data = {
//       labels,
//       datasets: [
//         {
//           label: labels,
//           data: chartValues,
//           fill: true,
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.5)',
//             'rgba(255, 159, 64, 0.5)',
//             'rgba(255, 205, 86, 0.5)',
//             'rgba(75, 192, 192, 0.5)',
//             'rgba(54, 162, 235, 0.5)',
//             'rgba(153, 102, 255, 0.5)',
//             'rgba(255, 0, 223, 0.5)',
//           ],
//           borderColor: [
//             'rgb(255, 99, 132)',
//             'rgb(255, 159, 64)',
//             'rgb(255, 205, 86)',
//             'rgb(75, 192, 192)',
//             'rgb(54, 162, 235)',
//             'rgb(153, 102, 255)',
//             'rgb(255, 0, 223)',
//           ],
//           borderWidth: 1,
//         },
//       ],
//     };
//   }

//   const transformedData = data.labels.map((label, index) => ({
//     id: label,
//     ...Object.fromEntries(data.datasets.map((dataset, datasetIndex) => [dataset.label, Number(dataset.data[index])]))
//   }));

//   const transformedLabel = data.datasets.map((dataset, datasetIndex) => dataset.label);
 
//   // console.log(transformedData)
//   // console.log(transformedLabel)
//   return (
//     <>
//       {data.datasets.length && data.labels.length ? (
//         <>
//           <DownloadChart chartData={data} chartRef={refEl} />
//           <ResponsiveBar
//             ref={refEl}
//             data={transformedData}
//             keys={transformedLabel}
//             groupMode="grouped"
//             layout="vertical"
//             margin={{ top: 10, right: 10, bottom: 50, left: 50 }}
//             axisTop={null}
//             axisRight={null}
//             axisBottom={{
//               orient: 'bottom',
//               tickSize: 5,
//               tickPadding: 5,
//               tickRotation: 0,
//               legend: 'Date',
//               legendOffset: 36,
//               legendPosition: 'middle',
//             }}
//             axisLeft={{
//               orient: 'left',
//               tickSize: 5,
//               tickPadding: 5,
//               tickRotation: 0,
//               legend: 'Value',
//               legendOffset: -40,
//               legendPosition: 'middle',
//             }}
//             colors={{ scheme: 'nivo' }}
//             borderRadius={4}
//             borderWidth={1}
//             enableLabel={false}
//           />
//         </>
//       ) : (
//         <div>No Data Available</div>
//       )}
//     </>
//   );
// }


{/* <BarChart
          ref={refEl}
          xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
          series={[{ data: [4.5, 3.2, 5.6] }]}
          width={500}
          height={300}
        /> */}