import React, { useEffect, useState } from 'react';
import DashboardMaster from '../../../layout/dashboard/DashboardMaster';
import Card from '@/components/common/Card';
import IconAdd from '../../../components/svg/IconAdd';
import { Button } from '@/components/ui/button';
import CreateSentry from './models/CreateSentry';
import { useSelector } from 'react-redux';
import Loading from '../../../shared/common/Loading';
import { dateConvert } from '../../../shared/common/dateFormate';
import { Filters, sentryActionType } from '../../../constants';
import _ from 'lodash'
import DataTableColumnHeader from '../../../shared/common/table-components/tasks/components/data-table-column-header';
import { DataTableWithCustomPagination } from '../../../shared/common/table-components/tasks/components/data-table-without-pagination';
import { useDispatch } from 'react-redux';
import { Switch } from '../../../components/ui/switch';
import { Label } from '../../../components/ui/label';
import IconExport from '../../../components/svg/IconExport';
import ScatterChartData from './charts/ScatterChart';
import { CardContent, CardHeader } from '../../../components/ui/Card';
import { dateSetPrice } from '../../../static/static';
import { setLoading } from '../../../store/actions/loadingAction';

const Sentry = () => {
  const dispatch = useDispatch()
  const { isLoading, sentryData } = useSelector((state) => state);
  const [showModel, setShowModel] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckDisabled, setIsCheckDisabled] = useState(true);
  const [analysisType, setAnalysisType] = useState(null);

  // pagination
  const [currentPage, setCurrentPage] = useState(Filters.PAGE);
  const [perPage, setPerPage] = useState(Filters.OBJ.perPage); // Default per page value

  // data heading 
  const [data, setData] = useState([])
  const [allData, setAllData] = useState([])
  const [sortBy, setSortBy] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  // const getKeyName = dateSetPrice.find((item) => item.symbol === sentryData.outlier_key)?.label.replace(/[\s-]/g, '_');
  const getKeyName = sentryData?.dataset_title ? sentryData?.dataset_title.replace(/[\s-]/g, '_') : '';

  // dataset_title
  // Pagination calculations heading
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = Math.min(startIndex + perPage, sentryData?.tData?.length);
  const paginatedData = data?.slice(startIndex, endIndex) || [];

  useEffect(() => {
    const runAnalysis = JSON.parse(localStorage.getItem('runAnalysis'))
    if (runAnalysis !== undefined && runAnalysis !== null) {
      setAnalysisType(runAnalysis)
    }
  }, [sentryData])


  useEffect(() => {
    dispatch({ type: sentryActionType.FETCH_SENTRYS, payload: { asset: {}, tData: [], outlier_key: '' } })
  }, [])

  useEffect(() => {
    const getData = sentryData && sentryData?.outlier_key?.length && sentryData?.tData?.length ? sentryData?.tData.map((data, index) => {
      return {
        index: startIndex + index + 1,
        Date: data.date,
        [getKeyName]: sentryData?.tData[index][sentryData.outlier_key],
        Outlier_Score: String(data.OutlierScore),
        Outlier_Classification: String(data.OutlierClassification),
      }
    }) : [];
    setAllData(getData)
    setData(getData)
  }, [sentryData?.tData])


  useEffect(() => {
    if (sortBy !== null) {
      const sortedData = _.orderBy(data, [sortBy], [sortAsc ? 'asc' : 'desc']);
      setData(sortedData);
    }
  }, [sortBy, sortAsc]);


  const handleSort = (columnName) => {
    if (sortBy === columnName) {
      setSortAsc(!sortAsc);
    } else {
      setSortBy(columnName);
      setSortAsc(true);
    }
  };


  const handleShow = () => {
    setIsCheckDisabled(true)
    setShowModel(!showModel)
  }


  const columns = paginatedData.length ? [
    {
      accessorKey: "index",
      header: ({ column }) => (
        <DataTableColumnHeader accessorKey={"index"} isCustom={true} handleSort={handleSort} column={column} title="Id" />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row?.original?.index}</span>
      },
      enableSorting: true,

    },
    {
      accessorKey: "Date",
      header: ({ column }) => (
        <DataTableColumnHeader accessorKey={"Date"} column={column} title="Date" isCustom={true} handleSort={handleSort} />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{dateConvert(row.original.Date)}</span>
      },
      enableSorting: true,
    },
    {
      accessorKey: getKeyName,
      header: ({ column }) => (
        <DataTableColumnHeader accessorKey={getKeyName} column={column} title={getKeyName?.replace(/___/g, ' - ')?.replace(/_/g, ' ')} isCustom={true} handleSort={handleSort} />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row.original[getKeyName]}</span>
      },
      enableSorting: true,
    },
    {
      accessorKey: "Outlier_Score",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} accessorKey={"Outlier_Score"} title="Outlier Score" isCustom={true} handleSort={handleSort} />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row.original.Outlier_Score}</span>
      },
      enableSorting: true,
    },
    {
      accessorKey: "Outlier_Classification",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} accessorKey={"Outlier_Classification"} title="Outlier Classification" isCustom={true} handleSort={handleSort} />
      ),
      cell: ({ row }) => {
        return <span className='flex items-center'>{row.original.Outlier_Classification}</span>
      },
      enableSorting: true,
    },
  ] : []

  const onChangeHandel = (value) => {
    const runAnalysisData = JSON.parse(localStorage.getItem('runAnalysis'))
    setCurrentPage(1)
    setPerPage(10)
    if (value === true && runAnalysisData !== null) {
      dispatch(setLoading(true))
      // dispatch(addSentry({ ...runAnalysisData, display: 'outliers' }))
      localStorage.setItem('runAnalysis', JSON.stringify({ ...runAnalysisData, display: 'outliers' }))
      const getOutliersData = sentryData?.tData?.length ? sentryData?.tData.filter((item) => item.OutlierClassification === 1) : [];
      const getData = sentryData && sentryData?.outlier_key?.length && getOutliersData?.length ? getOutliersData.map((data, index) => {
        return {
          index: 0 + index + 1,
          Date: data.date,
          [getKeyName]: sentryData?.tData[index][sentryData.outlier_key],
          Outlier_Score: String(data.OutlierScore),
          Outlier_Classification: String(data.OutlierClassification),
        }
      }) : [];
      setTimeout(() => {
        dispatch(setLoading(false))
        setAllData(getData)
        setData(getData)
      }, 200);
    } else {
      // runAnalysisData !== null && dispatch(addSentry({ ...runAnalysisData, display: 'all' }))
      dispatch(setLoading(true))
      localStorage.setItem('runAnalysis', JSON.stringify({ ...runAnalysisData, display: 'all' }))
      const getData = sentryData && sentryData?.outlier_key?.length && sentryData?.tData?.length ? sentryData?.tData.map((data, index) => {
        return {
          index: 0 + index + 1,
          Date: data.date,
          [getKeyName]: sentryData?.tData[index][sentryData.outlier_key],
          Outlier_Score: String(data.OutlierScore),
          Outlier_Classification: String(data.OutlierClassification),
        }
      }) : [];
      setTimeout(() => {
        dispatch(setLoading(false))
        setAllData(getData)
        setData(getData)
      }, 200);
    }
    setIsChecked(value)
    setCurrentPage(1)
  }
  // Function to convert data to CSV format
  const convertToCSV = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));
    data.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });
    return csvRows.join('\n');
  };
  const onExportCsv = (data) => {
    if (data.length >= 1) {
      const csvData = convertToCSV(data);
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', 'sentry_data.csv');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  return (
    <DashboardMaster>
      <section className='p-2 md:px-5'>
        <div className="flex gap-4 justify-start flex-wrap place-items-start mb-3"  >
          <Button size="sm" className="min-w-[98px]" variant="" onClick={handleShow}>
            <span><IconAdd className="w-4 h-4" /></span>  Run Analysis
          </Button>
        </div>
        <div className="grid  grid-cols-1 md:grid-cols-2 gap-6">
          {!_.isEmpty(sentryData?.asset) ? <Card className='p-4 mb-4'>
            {isLoading ? <Loading /> :
              <>
                <div>
                  <div className="">
                    <h4 className='text-sm text-textV2 font-500 mb-1'>{`${sentryData?.asset?.assetName ? sentryData?.asset?.assetName : ''} (${sentryData?.asset?.assetSymbol ? sentryData?.asset?.assetSymbol : ''})`}</h4>
                    <p className='text-xs font-300 text-[#909090]'>{`${sentryData?.asset?.exchange?.name ? sentryData?.asset?.exchange?.name : ''} EOD Prices. Currency in  ${sentryData?.asset?.country?.countryName ? sentryData?.asset?.country?.countryName : ''}`}</p>
                  </div>
                </div>
              </>}
          </Card> : ''}
        </div>
        <Card className="bg-white   ">
          <CardHeader className='p-0 py-2'>
            <div className="card-header flex items-center gap-2 justify-between  ">  
            {/* me-28 */}
              <h4 className='text-sm font-600 text-textV2'>Anomaly: {getKeyName ? getKeyName?.replace(/___/g, ' - ')?.replace(/_/g, ' ') : ''}</h4>
              <div className='flex'>
                <div className="flex items-center space-x-2 me-3">
                  <Label htmlFor="Asset" className="text-[12px] text-textV2 font-500">All</Label>
                  <Switch checked={isChecked} disabled={isCheckDisabled} onCheckedChange={(value) => onChangeHandel(value)} />
                  <Label htmlFor="BYOD" className="text-[12px] text-textV2 font-500" >Outliers</Label>
                </div>
                <div>
                  <Button size="sm" className="h-[34px]" disabled={data?.length === 0} onClick={() => onExportCsv(data)}>
                    <span><IconExport className="w-4 h-4" /></span>   Export
                  </Button>
                </div>
              </div>
              {/* <p className="text-sm font-300 text-[#909090]">Frequency: <span className='text-textV2'>Daily</span> </p> */}
            </div>
          </CardHeader>
          <CardContent className='p-0'>
            {isLoading ? <section>
              <Card>
                <CardContent className='p-0 text-center mt-8 text-sm text-[#c2c2c2]'>
                  <Loading />
                </CardContent>
              </Card>
            </section> : <div className="table-wrapper">
              {data?.length && analysisType?.table ? <DataTableWithCustomPagination data={paginatedData} columns={columns} isSearchHide={true}  filterName={'Date'} isOldHeaderHide={true} isRightNew={true} setPerPage={setPerPage}
                perPage={perPage}
                totalRecords={data?.length || 0}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={Math.ceil((data?.length || 0) / perPage)} /> :
                ''
              }
              {!data?.length &&
                <section>
                  <Card>
                    <CardContent className='p-0 text-center mt-8 text-sm text-[#c2c2c2]'>
                      No Data Found
                    </CardContent>
                  </Card>
                </section>}
            </div>}
          </CardContent>
        </Card>
        <ScatterChartData data={allData} analysisType={analysisType} isLoading={isLoading} name={sentryData?.asset?.name} getKeyName={getKeyName} isChecked={isChecked} />
        <CreateSentry show={showModel} onHide={() => setShowModel(false)} setIsChecked={setIsChecked} setIsCheckDisabled={setIsCheckDisabled} />
      </section>
    </DashboardMaster>
  )
}

export default Sentry
// useEffect(() => {
//   const getOutliersData = sentryData?.tData?.length ? sentryData?.tData.filter((item) => item.OutlierClassification === 1) : []
//   const getData = sentryData && sentryData?.outlier_key?.length && getOutliersData?.length ? getOutliersData.map((data, index) => {
//     return {
//       Date: data.date,
//       getKeyName: sentryData?.tData[index][sentryData.outlier_key],
//       Outlier_Score: String(data.OutlierScore),
//       Outlier_Classification: String(data.OutlierClassification),
//     }
//   }) : [];
//   setData(getData)
// }, [sentryData?.tData])

{/* <Divider className={"my-3 border-borderV1"} />
                  <div className="flex items-end justify-between">
                    <div className="px-5 py-2.5 bg-themeV2/10 rounded-sm">
                      {/* {financePrice?.publicAsset?.adjClose === 'Fetching' ?
                      <>
                        <span className='text-themeV2 text-2xl font-600'>
                          <ThreeDots
                            visible={true}
                            height="35"
                            width="35"
                            color="#001F3D"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </span>
                      </> :  */}
{/* <span className='text-themeV2 text-2xl font-600'>{sentryData?.asset?.adjClose ? sentryData?.asset?.adjClose : 0}</span> */ }
//   </div>
//   <span className='text-xs font-300 text-[#909090]'>{`At Date: ${sentryData?.asset?.priceUpdateDate ? new Date(sentryData?.asset?.priceUpdateDate).toLocaleDateString('en-US', {
//     month: 'short',
//     day: 'numeric',
//     year: 'numeric'
//   }) : ''} `}</span>
// </div> */}