import { assetsDetailsActionType } from '../../constants';

const assetsDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case assetsDetailsActionType.FETCH_ASSETS_DETAILS:
            return action.payload;
        case assetsDetailsActionType.FETCH_ASSETS_DETAIL:
            return action.payload;
        case assetsDetailsActionType.ADD_ASSETS_DETAIL:
            return action.payload;
        case assetsDetailsActionType.UPDATE_ASSETS_DETAIL:
            return action.payload;
        case assetsDetailsActionType.DELETE_ASSETS_DETAIL:
            return action.payload;
        default:
            return state;
    }
};
export default assetsDetailsReducer