import { toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { PortfolioTransactionActionType, apiBaseURL } from "../../constants";
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";

export const fetchInvestedAssetById = (setIsLoading, id, filter) => async (dispatch) => {
    try {
        const filterData = filter ? `?page=${filter.page}&perPage=${filter.perPage}` : ''
        const response = await apiConfig.get(apiBaseURL.PORTFOLIO_TRANSACTION + '/'+ id + filterData)
        dispatch({ type: PortfolioTransactionActionType.FETCH_INVESTED_ASSET, payload: response.data?.data });
        // dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage, pageSize: response.data?.data?.pageSize || 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
    } catch (error) {
        dispatch(setLoading(false))
        setIsLoading && setIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}
