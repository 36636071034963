import React, { useEffect, useState } from 'react'
import Card from '../../../../components/common/Card'
// import IconAdd from '../../../../components/svg/IconAdd'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, SelectGroup } from '../../../../components/ui/select'
// import { cn } from '../../../../lib/utils'
// import IconSetting from '../../../../components/svg/IconSetting'
import Divider from '../../../../components/common/Divider'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../../components/ui/accordian'
import IconUnassignedAssets from '../../../../components/svg/IconUnassignedAssets'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useLocation, } from 'react-router-dom';
import { fetchAllocation, fetchAllocationsById } from '../../../../store/actions/AllocationAction'
import { fetchAllocationMonitor, fetchAllocationMonitorAsGroup } from '../../../../store/actions/AllocationMonitorAction'
import MultipleViewSetting from '../../ViewSetting/MultipleViewSetting'
import AllocationMonitoringDataTable from './AllocationMonitoringDataTable'
import UnassignedAssetsTable from './UnassignedAssetsTable'

const AllocationMonitoringTable = (props) => {
    //   my code 
    const { PortfolioName, isClick, unAllocations, setUnAllocations, isGroupAssetMonitor, getPortfolio, groupSelectedId, sectionType, isGroup } = props;
    const dispatch = useDispatch()
    const location = useLocation();
    const { allocation, portfolios, PortfolioGroup, AllocationsMonitor, allocationColumnViews, PortfolioGroupAllocationColumnsViews } = useSelector(state => state)
    const resultArray = location.pathname.split('/').filter(Boolean);
    const [Portfolio, setPortfolio] = useState(null)
    const [selectedAllocation, setSelectedAllocation] = useState(null)
    const [allocations, setAllocations] = useState(null)
    const [codeName, setCodeName] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (groupSelectedId?.length && isGroup) {
            // old 
            // dispatch(fetchAllocationsById(groupSelectedId))
            // new 
            dispatch(fetchAllocationsById({
                // portfolioGroup: groupSelectedId?.length ? groupSelectedId[0] : ''
                portfolio:getPortfolio?.portfolio?._id ?? "",
                portfolioGroup: getPortfolio?.portfolioGroup?._id ? getPortfolio?.portfolioGroup?._id : ''
            }))
            setAllocations(null)
            setSelectedAllocation(null)
        } 
    }, [groupSelectedId])

    useEffect(() => {
            if(Portfolio !== null && Portfolio?.portfolio?._id && !isGroup){
                // old 
            // dispatch(fetchAllocationsById([Portfolio.portfolio._id]))
            // new 
            dispatch(fetchAllocationsById({
                portfolio: Portfolio.portfolio._id
            }))
            setAllocations(null)
            setSelectedAllocation(null)
        }
        
    }, [Portfolio])

    useEffect(() => {
        if (PortfolioGroup) {
            if (resultArray.length === 3) {
                const findPortfolio = portfolios.find((item) => item?.portfolio?.slug === resultArray[2])
                setPortfolio(findPortfolio)
            } else {
                const findPortfolio = portfolios.find((item) => item?.portfolio?.slug === resultArray[0])
                setPortfolio(findPortfolio)
            }
        }
    }, [resultArray])

    useEffect(() => {
        if (allocationColumnViews?.data) {
            setCodeName(allocationColumnViews?.data.map((item) => { return { code: item.code, name: item.name, description: item.description } }))
        }
    }, [allocationColumnViews?.data])
    
    useEffect(() => {
        if (Portfolio !== null && !isGroupAssetMonitor) {
            dispatch(fetchAllocation(Portfolio.portfolio._id))
        }
    }, [isClick])

    // useEffect(() => {
    //     if (selectedAllocation !== null) {
    //         dispatch(fetchAllocationMonitor(selectedAllocation.value))
    //     }
    // }, [selectedAllocation])

    useEffect(() => {
        if (selectedAllocation !== null) {
            setIsLoading(true)
            if (isGroupAssetMonitor) {
                dispatch(fetchAllocationMonitorAsGroup(selectedAllocation.value, setIsLoading, 'portfolioGroupAllocationMonitor'))
            } else {
                dispatch(fetchAllocationMonitor(selectedAllocation.value, setIsLoading, 'allocationMonitor'))
            }

        } else {
            setAllocations([])
            setUnAllocations([])
        }
    }, [selectedAllocation, PortfolioGroupAllocationColumnsViews])

    useEffect(() => {
        if (PortfolioGroupAllocationColumnsViews?.data) {
            setCodeName(PortfolioGroupAllocationColumnsViews?.data.map((item) => { return { code: item.code, name: item.name, description: item.description } }))
        }
    }, [PortfolioGroupAllocationColumnsViews?.data])

    useEffect(() => {
        if (AllocationsMonitor !== null && selectedAllocation !== null) {
            setAllocations(AllocationsMonitor.assetMonitor)
            setUnAllocations(AllocationsMonitor.unAssignedAsset)
        } else {
            setAllocations([])
            setUnAllocations([])
        }
    }, [AllocationsMonitor])

    const onChangeAllocation = (event) => {
        const getObj = JSON.parse(event)
        setSelectedAllocation(getObj)
    }


    const allocationData = isGroupAssetMonitor ? getPortfolio !== null && allocation && allocation.records?.length >= 1 ? allocation.records?.map((item) => {
        return { value: item._id, label: item.name }
    }) : [] : allocation && allocation.records?.length >= 1 ? allocation.records?.map((item) => {
        return { value: item._id, label: item.name }
    }) : []

 
    const [allocationsData, setAllocationsData] = useState([])
    useEffect(() => {
        const prData = isGroupAssetMonitor ? allocations !== null && PortfolioGroupAllocationColumnsViews?.data?.length ? allocations?.allocatedAssets
            ? allocations?.allocatedAssets.map((item) => {
                return {
                    ...item,
                    id: item?._id,
                    portfolio: Portfolio.portfolio.name,
                    assetName: item?.asset?.assetName ? item?.asset?.assetName : '-',
                    subClass: item?.subClass,
                    class: item?.class,
                    strategy: item.allocatedSubAssets?.length ? '-' : item?.strategy ? item?.strategy : '-',
                    AMV: item?.AMV ? item?.AMV : 0,
                    AMVP: item?.AMVP ? item?.AMVP : 0,
                    MV: item?.MV ? item?.MV : 0,
                    MVP: item?.MVP ? item?.MVP  : 0,
                    SAA: item?.SAA ? item?.SAA  : 0,
                    SAALT: item?.SAALT ? item?.SAALT  : 0,
                    SAALTMV: item?.SAALTMV ? item?.SAALTMV : 0,
                    SAAMV: item?.SAAMV ? item?.SAAMV : 0,
                    SAAUT: item?.SAAUT ? item?.SAAUT  : 0,
                    SAAUTMV: item?.SAAUTMV ? item?.SAAUTMV : 0,
                    TA: item?.TA ? item?.TA  : 0,
                    TAA: item?.TAA ? item?.TAA  : 0,
                    TALT: item?.TALT ? item?.TALT  : 0,
                    TAMV: item?.TAMV ? item?.TAMV : 0,
                    TAAMV: item?.TAAMV ? item?.TAAMV : 0,
                    TAUT: item?.TAUT ? item?.TAUT  : 0,
                    defaultExpanded: true,
                    allocatedSubAssets: item.allocatedSubAssets ? item.allocatedSubAssets : [],
                }
            })
            : [] : [] : allocations !== null && allocationColumnViews?.data?.length ? allocations?.allocatedAssets
                ? allocations?.allocatedAssets.map((item) => {
                    return {
                        ...item,
                        id: item?._id,
                        portfolio: Portfolio.portfolio.name,
                        assetName: item?.asset?.assetName ? item?.asset?.assetName : '-',
                        subClass: item?.subClass,
                        class: item?.class,
                        strategy: item.allocatedSubAssets?.length ? '-' : item?.strategy ? item?.strategy : '-',
                        AMV: item?.AMV ? item?.AMV : 0,
                        AMVP: item?.AMVP ? item?.AMVP  : 0,
                        MV: item?.MV ? item?.MV : 0,
                        MVP: item?.MVP ? item?.MVP  : 0,
                        SAA: item?.SAA ? item?.SAA  : 0,
                        SAALT: item?.SAALT ? item?.SAALT  : 0,
                        SAALTMV: item?.SAALTMV ? item?.SAALTMV : 0,
                        SAAMV: item?.SAAMV ? item?.SAAMV : 0,
                        SAAUT: item?.SAAUT ? item?.SAAUT  : 0,
                        SAAUTMV: item?.SAAUTMV ? item?.SAAUTMV : 0,
                        TA: item?.TA ? item?.TA  : 0,
                        TAA: item?.TAA ? item?.TAA  : 0,
                        TALT: item?.TALT ? item?.TALT  : 0,
                        TAMV: item?.TAMV ? item?.TAMV : 0,
                        TAAMV: item?.TAAMV ? item?.TAAMV : 0,
                        TAUT: item?.TAUT ? item?.TAUT  : 0,
                        defaultExpanded: true,
                        allocatedSubAssets: item.allocatedSubAssets ? item.allocatedSubAssets : [],
                    }
                }) : [] : [];
        setAllocationsData(prData)
    }, [allocations?.allocatedAssets, allocationColumnViews, PortfolioGroupAllocationColumnsViews])
     
   

    const data = unAllocations.length !== 0 ? unAllocations.map((item) => {
        return {
            _id: item?._id,
            asset: item?.asset?.assetName,
            id: item?.asset?.assetId,
            user: item?.user,
            portfolio: item?.portfolio,
            averagePurchasePrice: item?.averageprice,
            totalValue: item?.totalValue,
            totalQuantity: item?.totalQuantity,
            strategy: item?.strategy,
            status: item?.status
        }
    }) : []

    const columns = [
        {
            accessorKey: "Id",
            header: "Id",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.id}</div>
            ),
        },
        {
            accessorKey: "Name",
            header: "Name",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.asset}</div>
            ),
        },
        {
            accessorKey: "Strategy",
            header: "Strategy",
            cell: ({ row }) => (
                <div className="capitalize">{row.original?.strategy === null ? 'N/A' : row.original?.strategy?.name}</div>
            ),
        },
        {
            accessorKey: "Purchase Price",
            header: "Purchase Price",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.averagePurchasePrice}</div>
            ),
        },
        {
            accessorKey: "Quantity",
            header: "Quantity",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.totalQuantity}</div>
            ),
        },
        {
            accessorKey: "Value",
            header: "Value",
            cell: ({ row }) => (
                <div className="capitalize">{row?.original?.totalValue}</div>
            ),
        },
    ]

    return (
        <section className=''>
            <Card className='bg-white m-2 md:mx-0 p-0 md:p-0'>
                <div className="p-3 md:p-5">
                    <div className="table-wrapper">
                        <div className="w-full">
                            <div className="flex items-center justify-between pb-4 gap-4">
                                <div className="flex items-center">
                                    <div>
                                    <Select
                                        defaultValue={selectedAllocation}
                                        onValueChange={(e) => onChangeAllocation(e)}
                                    >
                                        <SelectTrigger className="w-[150px] lg:w-[250px] text-xs py-2">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select Allocation"
                                            >
                                                {selectedAllocation !== null ? selectedAllocation?.label : "Select Allocation"}
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent side="bottom" className="">
                                            <SelectGroup>
                                                {
                                                    allocationData && allocationData.length > 0 ? allocationData.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    </div>
                                    <div className='ms-2 text-orange-500 text-[10px]'>
                                    {allocationData.length === 0 ? 'Authorise allocation not found' : ''}
                                </div>
                                </div>
                                
                                {/* <Button variant="outline" size="sm" onClick={() => setSettingModalOpen(true)}>
                                    <Settings strokeWidth={"2.2"} className="w-3 h-3" />
                                    Setting
                                </Button> */}
                                <MultipleViewSetting sectionType={sectionType} isAssetMonitor={sectionType} />
                            </div>
                            <AllocationMonitoringDataTable  isLoading={isLoading} allocationsData={allocationsData} codeName={codeName}/>
                        </div>
                    </div>
                </div>
                <Divider className={"border-borderV1"} />
                <div className="p-3 md:p-5">
                    <div className="">
                        <Accordion type="single" collapsible className="w-full">
                            <AccordionItem value="item-1" className="border-none rounded-none">
                                <AccordionTrigger className="px-0">
                                    <div className="flex items-center gap-2 border-b border-theme pb-1.5">
                                        <span><IconUnassignedAssets className="w-4 h-5 text-theme" /></span>
                                        Unassigned Assets
                                    </div>
                                </AccordionTrigger>
                                <AccordionContent className="px-0 border-t-0 pt-0">
                                    <UnassignedAssetsTable data={data} columns={columns} />
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </Card>
        </section >
    )
}

export default AllocationMonitoringTable



{/* <Table className="min-w-[1000px]">
                                <TableHeader>
                                    <TableRow>

                                        <TableHead className="border-r border-borderV1 text-xs">Class</TableHead>
                                        <TableHead className="text-xs">Sub Class</TableHead>
                                        <TableHead className="text-xs">Asset</TableHead>
                                        <TableHead className="text-xs">Strategy</TableHead>
                                        <TableHead className="text-xs">MV%</TableHead>
                                        <TableHead className="text-xs">MV</TableHead>
                                        <TableHead className="text-xs">Active MV%</TableHead>
                                        <TableHead className="text-xs">Active MV</TableHead>
                                        <TableHead className="text-xs">SAA%</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {data2d.map((obj) => {
                                        return (
                                            <>
                                                {obj.subData.map((subObj, index) => {
                                                    const isLastRow = index === obj.subData.length - 1;
                                                    return (
                                                        <TableRow className={isLastRow ? 'border-b border-theme' : ''} key={`${obj.class}-${index}`}>
                                                            {index === 0 && (
                                                                <TableCell className={cn(getColorCode(getAverageSaaPercentage(obj.subData)), "border-r border-borderV1")} rowSpan={obj.subData.length}>
                                                                    {obj.class}
                                                                </TableCell>
                                                            )}
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.subclass}</TableCell>
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.asset}</TableCell>
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.strategy}</TableCell>
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.mvPercentage}</TableCell>
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.mv}</TableCell>
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.activeMVPercentage}</TableCell>
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.activeMV}</TableCell>
                                                            <TableCell className={cn(getColorCode(parseInt(subObj.saaPercentage)), "text-xs")}>{subObj.saaPercentage}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table> */}



                            // const allocationsData = isGroupAssetMonitor ? allocations !== null && PortfolioGroupAllocationColumnsViews?.data?.length ? allocations?.allocatedAssets
                            // ? allocations?.allocatedAssets.map((item) => {
                            //     return {
                            //         ...item,
                            //         id: item?._id,
                            //         assetName: item?.asset?.assetName ? item?.asset?.assetName : '-',
                            //         subClass: item?.subClass,
                            //         class: item?.class,
                            //         strategy: item.allocatedSubAssets?.length ? '-' : item?.strategy ? item?.strategy : '-',
                            //         AMV: item?.AMV ? item?.AMV : 0,
                            //         AMVP: item?.AMVP ? item?.AMVP + '%' : '0%',
                            //         MV: item?.MV ? item?.MV : 0,
                            //         MVP: item?.MVP ? item?.MVP + '%' : '0%',
                            //         SAA: item?.SAA ? item?.SAA + '%' : '0%',
                            //         SAALT: item?.SAALT ? item?.SAALT + '%' : '0%',
                            //         SAALTMV: item?.SAALTMV ? item?.SAALTMV : 0,
                            //         SAAMV: item?.SAAMV ? item?.SAAMV : 0,
                            //         SAAUT: item?.SAAUT ? item?.SAAUT + '%' : '0%',
                            //         SAAUTMV: item?.SAAUTMV ? item?.SAAUTMV : 0,
                            //         TA: item?.TA ? item?.TA + '%' : '0%',
                            //         TAA: item?.TAA ? item?.TAA + '%' : '0%',
                            //         TALT: item?.TALT ? item?.TALT + '%' : '0%',
                            //         TAMV: item?.TAMV ? item?.TAMV : 0,
                            //         TAAMV: item?.TAAMV ? item?.TAAMV : 0,
                            //         TAUT: item?.TAUT ? item?.TAUT + '%' : '0%',
                            //         defaultExpanded: true,
                            //         allocatedSubAssets: item.allocatedSubAssets ? item.allocatedSubAssets : [],
                            //     }
                            // })
                            // : [] : [] : allocations !== null && allocationColumnViews?.data?.length ? allocations?.allocatedAssets
                            //     ? allocations?.allocatedAssets.map((item) => {
                            //         return {
                            //             ...item,
                            //             id: item?._id,
                            //             assetName: item?.asset?.assetName ? item?.asset?.assetName : '-',
                            //             subClass: item?.subClass,
                            //             class: item?.class,
                            //             strategy: item.allocatedSubAssets?.length ? '-' : item?.strategy ? item?.strategy : '-',
                            //             AMV: item?.AMV ? item?.AMV : 0,
                            //             AMVP: item?.AMVP ? item?.AMVP + '%' : '0%',
                            //             MV: item?.MV ? item?.MV : 0,
                            //             MVP: item?.MVP ? item?.MVP + '%' : '0%',
                            //             SAA: item?.SAA ? item?.SAA + '%' : '0%',
                            //             SAALT: item?.SAALT ? item?.SAALT + '%' : '0%',
                            //             SAALTMV: item?.SAALTMV ? item?.SAALTMV : 0,
                            //             SAAMV: item?.SAAMV ? item?.SAAMV : 0,
                            //             SAAUT: item?.SAAUT ? item?.SAAUT + '%' : '0%',
                            //             SAAUTMV: item?.SAAUTMV ? item?.SAAUTMV : 0,
                            //             TA: item?.TA ? item?.TA + '%' : '0%',
                            //             TAA: item?.TAA ? item?.TAA + '%' : '0%',
                            //             TALT: item?.TALT ? item?.TALT + '%' : '0%',
                            //             TAMV: item?.TAMV ? item?.TAMV : 0,
                            //             TAAMV: item?.TAAMV ? item?.TAAMV : 0,
                            //             TAUT: item?.TAUT ? item?.TAUT + '%' : '0%',
                            //             defaultExpanded: true,
                            //             allocatedSubAssets: item.allocatedSubAssets ? item.allocatedSubAssets : [],
                            //         }
                            //     })
                            //     : [] : [];



                            // const datas = [
                            //     {
                            //         name: "Portfolio-1",
                            //         status: "Active",
                            //     },
                            //     {
                            //         name: "Portfolio-3",
                            //         status: "Active",
                            
                            //     },
                            //     {
                            //         name: "Portfolio-3",
                            //         status: "Active",
                            
                            //     },
                            //     {
                            //         name: "Portfolio-4",
                            //         status: "Active",
                            
                            //     },
                            //     {
                            //         name: "Portfolio-5",
                            //         status: "Active",
                            
                            //     },
                            // ]
                            
                            
                            
                            // export const columnss = [
                            //     {
                            //         accessorKey: "name",
                            //         header: "Name",
                            //         cell: ({ row }) => (
                            //             <div className="capitalize">{row.getValue("name")}</div>
                            //         ),
                            //     },
                            //     {
                            //         accessorKey: "status",
                            //         header: "Status",
                            //         cell: ({ row }) => (
                            //             <div className="capitalize">{row.getValue("status")}</div>
                            //         ),
                            //     },
                            //     {
                            //         id: "actions",
                            //         enableHiding: false,
                            //         header: <span className="flex items-center justify-end">Action</span>,
                            //         cell: ({ row }) => {
                            //             return (
                            //                 <div className="flex items-center justify-end">
                            //                     <DropdownMenu>
                            //                         <DropdownMenuTrigger asChild>
                            //                             <Button variant="ghost" className="p-0">
                            //                                 <span className="sr-only">Open menu</span>
                            //                                 <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                            //                             </Button>
                            //                         </DropdownMenuTrigger>
                            //                         <DropdownMenuContent align="end">
                            //                             <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"><PlusIcon strokeWidth={"2.2"} className='text-green-500 w-4 h-4' /> Add Quantity</DropdownMenuItem>
                            
                            //                             <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`}><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Edit Strategy</DropdownMenuItem>
                            //                         </DropdownMenuContent>
                            //                     </DropdownMenu>
                            //                 </div>
                            //             )
                            //         },
                            //     },
                            // ]
                            
                            
                            // const data2d = [
                            //     {
                            //         class: "Test Upadate 1",
                            //         subData: [
                            //             {
                            //                 subclass: 'Portfolio Group One-1',
                            //                 asset: 'Asset 1',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: '0.31517861204437...',
                            //                 mv: '4.85',
                            //                 activeMVPercentage: 'Jan 2, 2024',
                            //                 activeMV: 'Jan 2, 2024',
                            //                 saaPercentage: 40,
                            //             },
                            //             {
                            //                 subclass: 'Portfolio Group One-2',
                            //                 asset: 'Asset 2',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: '0.31517861204437...',
                            //                 mv: '4.85',
                            //                 activeMVPercentage: 'Jan 2, 2024',
                            //                 activeMV: 'Jan 2, 2024',
                            //                 saaPercentage: 50,
                            //             },
                            //             {
                            //                 subclass: 'Portfolio Group One-3',
                            //                 asset: 'Asset 3',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: '0.31517861204437...',
                            //                 mv: '4.85',
                            //                 activeMVPercentage: 'Jan 2, 2024',
                            //                 activeMV: 'Jan 2, 2024',
                            //                 saaPercentage: 20,
                            //             },
                            //             {
                            //                 subclass: 'Portfolio Group One-4',
                            //                 asset: 'Asset 4',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: '0.31517861204437...',
                            //                 mv: '4.85',
                            //                 activeMVPercentage: 'Jan 2, 2024',
                            //                 activeMV: 'Jan 2, 2024',
                            //                 saaPercentage: 60,
                            //             },
                            //             {
                            //                 subclass: 'Portfolio Group One-5',
                            //                 asset: 'Asset 5',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: '0.31517861204437...',
                            //                 mv: '4.85',
                            //                 activeMVPercentage: 'Jan 2, 2024',
                            //                 activeMV: 'Jan 2, 2024',
                            //                 saaPercentage: 80,
                            //             },
                            //             {
                            //                 subclass: 'Portfolio Group One-6',
                            //                 asset: 'Asset 6',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: '0.31517861204437...',
                            //                 mv: '4.85',
                            //                 activeMVPercentage: 'Jan 2, 2024',
                            //                 activeMV: 'Jan 2, 2024',
                            //                 saaPercentage: 50,
                            //             },
                            //         ],
                            //     },
                            //     {
                            //         class: "Test Upadate 2",
                            //         subData: [
                            //             {
                            //                 subclass: 'Subclass 1',
                            //                 asset: 'Asset 1',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: 'MV% 1',
                            //                 mv: 'MV 1',
                            //                 activeMVPercentage: 'Active MV% 1',
                            //                 activeMV: 'Active MV 1',
                            //                 saaPercentage: 20,
                            //             },
                            //             {
                            //                 subclass: 'Subclass 2',
                            //                 asset: 'Asset 2',
                            //                 strategy: 'Strategy 2',
                            //                 mvPercentage: 'MV% 2',
                            //                 mv: 'MV 2',
                            //                 activeMVPercentage: 'Active MV% 2',
                            //                 activeMV: 'Active MV 2',
                            //                 saaPercentage: 10,
                            //             },
                            //         ],
                            //     },
                            //     {
                            //         class: "Test Upadate 3",
                            //         subData: [
                            //             {
                            //                 subclass: 'Subclass 1',
                            //                 asset: 'Asset 1',
                            //                 strategy: 'Strategy 1',
                            //                 mvPercentage: 'MV% 1',
                            //                 mv: 'MV 1',
                            //                 activeMVPercentage: 'Active MV% 1',
                            //                 activeMV: 'Active MV 1',
                            //                 saaPercentage: 80,
                            //             },
                            //             {
                            //                 subclass: 'Subclass 2',
                            //                 asset: 'Asset 2',
                            //                 strategy: 'Strategy 2',
                            //                 mvPercentage: 'MV% 2',
                            //                 mv: 'MV 2',
                            //                 activeMVPercentage: 'Active MV% 2',
                            //                 activeMV: 'Active MV 2',
                            //                 saaPercentage: 80,
                            //             },
                            //         ],
                            //     },
                            // ]
                            