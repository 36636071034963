import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../../../components/ui/button'
import IconAdd from '../../../../components/svg/IconAdd'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/ui/table'
import { Input } from '../../../../components/ui/input'
import { Trash2 } from 'lucide-react'
import { ByodContext } from '../BYOD'
import { useDispatch } from 'react-redux'
import { addTost } from '../../../../store/actions/toastAction'
import { addManuallyData } from '../../../../store/actions/BYODAction'
import { toastifyType } from '../../../../constants'
import { searchAssetIssuer } from '../../../../store/actions/SearchFinancialAssetAction'
import { FromDatePicker } from '../../../../components/ui/DatePickerNew'
import ValueSelect from '../selector/ValueSelect'
import { Pencil2Icon } from '@radix-ui/react-icons'
import { AddNoteContent } from '../../../../store/actions/researchNoteContentAction'
import PreviewModel from '../models/update/updatePreviewData/PreviewModel'
import { DatePickerCustomInput } from '../../../../components/ui/DatePickerCustomInput'

const AddManulDataTable = (props) => {
    const { isUpload, setKey, setIsUpload } = props;
    const dispatch = useDispatch();
    const [isAddNew, setIsAddNew] = useState(true)
    const { getField } = useContext(ByodContext);
    const [data, setData] = useState([]);
    const [activeField, setActiveField] = useState(null)
    const [validation, setValidation] = useState({
        assetID: true,
        ShortName: true,
        value: true,
    });

    // action 
    const [modalShow, setModalShow] = useState(false);
    const [fetchData, setFetchData] = useState(null);

    useEffect(() => {
        setData([{
            id: 1,
            assetID: '',
            ShortName: '',
            value: 0,
            startDate: null,
            endDate: null,
            comment: '',
            description: '',
            searchData: [],
            readOnly: false
        }]);
        setIsAddNew(true)
    }, [getField?._id]);

    const handleSaveRow = (getObj) => {
        if (getField === null) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select field.' }))
        }
        if (getField !== null && validateFields()) {
            const prepareData = {
                AssetID: getObj.assetID,
                // ShortName: getObj.ShortName,
                Value: getObj.value,
                StartDate: getObj.startDate,
                EndDate: getObj.endDate,
                Comment: getObj.comment,
                Description: getObj.description,
            }
            setIsUpload(true)
            // getField = {
            //     "_id": "65bb24e534748dbb6d23e178",
            //     "user": "64ec7e8317a4f1ee6e27c867",
            //     "name": "xyz test",
            //     "code": "XYZ",
            //     "description": "test xyz",
            //     "type": "Quantitative",
            //     "section": [
            //         "investedAsset",
            //         "portfolioGroupInvestedAsset",
            //         "allocationMonitor",
            //         "portfolioGroupAllocationMonitor"
            //     ],
            //     "group": "Assets"
            // }
            let prepareAddNoteData = {
                researchNoteId: getField?._id,
                customFieldRecordData: []

            }

            dispatch(addManuallyData(getField.id, prepareData, setKey, setIsUpload, getField.group)).then((res) => {
                if (res?.data) {
                    // let getIds = res?.data?.upserted.map((item) => item?._id)
                    // prepareAddNoteData = { ...prepareAddNoteData, customFieldRecordData: [...prepareAddNoteData.customFieldRecordData, ...getIds] }
                    // dispatch(AddNoteContent(prepareAddNoteData, null))

                    if (isAddNew === false) {
                        setIsAddNew(true)
                        const filed = getField?.group === 'Assets' ? 'assetId' : 'issuerId'
                        const upDateData = data.map((item) => {
                            if (item.id === getObj.id) {
                                return {
                                    ...item,
                                    id: res?.data?._id,
                                    _id: res?.data?._id,
                                    readOnly: true,
                                    assetId: getField?.group === 'Assets' ? item.assetID : '-',
                                    issuerId: getField?.group === 'Assets' ? '-' : item.assetID,
                                    // value:  item.value,
                                    // comment: item.comment,
                                    // description: item.description,
                                    // endDate: item.endDate,
                                    // startDate: item.startDate,

                                }
                            } else {
                                return { ...item, readOnly: true }
                            }
                        })
                        const newId = data.length + 1;
                        const newRow = {
                            id: newId,
                            assetID: '',
                            ShortName: '',
                            value: 0,
                            startDate: null,
                            endDate: null,
                            comment: '',
                            description: '',
                            searchData: [],
                            readOnly: false

                        };
                        setData([...upDateData, newRow]);
                        setValidation({
                            assetID: true,
                            ShortName: true,
                            value: true,
                        })
                    }
                }
            }).catch((err) => {
                setIsAddNew(false)
            })
        }
    };
    // {
    //     "id": 1,
    //     "assetID": "",
    //     "ShortName": "",
    //     "value": 0,
    //     "startDate": null,
    //     "endDate": null,
    //     "comment": "",
    //     "description": "",
    //     "searchData": []
    // }
    const handleAddRow = () => {
        const getObj = data[data.length - 1];
        if (getObj !== null) {
            if (getObj.assetID === '') {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your assetID Is Empty' }))
            } else if (getObj.value === '' || getObj.value === '0' || getObj.value === 0) {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your value Is Empty Or 0' }))
            } else if (getObj.startDate === null) {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your Start Date Is Empty' }))
            } else if (getObj.endDate === null) {
                dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your End Date Is Empty' }))
            } else {
                handleSaveRow(getObj)
            }
        }
    };
    const validateFields = () => {
        const newValidation = {
            assetID: true,
            ShortName: true,
            value: true,
        };

        // Check validation for each field
        data.forEach((row) => {
            if (!row.assetID.trim()) {
                newValidation.assetID = false;
            }

            if (!row.value) {
                newValidation.value = false;
            }
        });

        setValidation(newValidation);

        // Return true if all fields are valid
        return Object.values(newValidation).every((isValid) => isValid);
    };

    const handleDateChange = (date, id, field) => {
        const newData = data.map((item) => {
            if (item.id === id) {
                return { ...item, [field]: date };
            }
            return item;
        });
        setData(newData);
        const getObj = newData[newData.length - 1];
        if (getObj !== null && getObj !== undefined && isAddNew) {
            if (getObj.assetID === '') {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your assetID Is Empty' }))
                setIsAddNew(true)
            } else if (getObj.value === '' || getObj.value === '0' || getObj.value === 0) {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your value Is Empty Or 0' }))
                setIsAddNew(true)
            } else if (getObj.startDate === null) {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your Start Date Is Empty' }))
                setIsAddNew(true)
            } else if (getObj.endDate === null) {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your End Date Is Empty' }))
                setIsAddNew(true)
            } else {
                setIsAddNew(false)
            }
        }
    }

    const handleInputChange = async (e, id, field, isSelector) => {
        let searchData = [];
        let values = isSelector ? e : e?.target?.value;

        if (field === 'assetID') {
            setActiveField(id)

            const searchResult = await dispatch(searchAssetIssuer(values, getField?.group));
            searchData = searchResult?.records
        }

        const newData = data.map((item) => {
            if (item.id === id) {
                return { ...item, [field]: values, searchData: values === '' ? [] : searchData };
            }
            return item;
        });

        // Reset validation only for the current field and id
        setValidation((prevValidation) => ({
            ...prevValidation,
            [`${field}-${id}`]: true,
        }));

        setData(newData);

        const getObj = newData[newData.length - 1];
        if (getObj !== null && getObj !== undefined && isAddNew) {
            if (getObj.assetID === '') {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your assetID Is Empty' }))
                setIsAddNew(true)
            } else if (getObj.value === '' || getObj.value === '0' || getObj.value === 0) {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your value Is Empty Or 0' }))
                setIsAddNew(true)
            } else if (getObj.startDate === null) {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your Start Date Is Empty' }))
                setIsAddNew(true)
            } else if (getObj.endDate === null) {
                // dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Your End Date Is Empty' }))
                setIsAddNew(true)
            } else {
                setIsAddNew(false)
            }
        }
    };

    const onValueSelect = (id, editData, index, item) => {
        handleInputChange(item.label, id, 'value', true)
    }

    const handleDeleteRow = (row) => {
        const newData = data.filter(item => item.id !== row.id);
        setData(newData);
    };

    const getSuggestedData = (values, id, field) => {
        const newData = data.map((item) => {
            if (item.id === id) {
                return { ...item, [field]: values, searchData: [] };
            }
            return item;
        });

        // Reset validation only for the current field and id
        setValidation((prevValidation) => ({
            ...prevValidation,
            [`${field}-${id}`]: true,
        }));

        setData(newData);
    }


    const handelEdit = (data) => {
        setModalShow(true);
        setFetchData(data);
    };

    // console.log('fetchData', fetchData)

    const handleSaveData = (UpData) => {
        let prepareData = {
            _id: fetchData._id,
            Value: UpData.value,
            StartDate: UpData.startDate,
            EndDate: UpData.endDate,
            Comment: UpData.comment,
            Description: UpData.description,
        }
        if (fetchData && fetchData?.assetId !== '-') {
            prepareData = { ...prepareData, AssetID: fetchData?.assetId }
        } else {
            prepareData = { ...prepareData, IssuerID: fetchData?.issuerId }
        }
        dispatch(addManuallyData(getField.id, prepareData, null, null, null)).then((res) => {
            if (res !== undefined) {
                setModalShow(false)
                const upDateData = data.map((item, index) => {
                    if (item.id === fetchData.id) {
                        return {
                            ...item,
                            _id: fetchData._id,
                            value: UpData.value,
                            startDate: new Date(UpData.startDate),
                            endDate: new Date(UpData.endDate),
                            comment: UpData.comment,
                            description: UpData.description,
                            AssetID: fetchData?.assetId,
                            assetId: fetchData?.assetId,
                            readOnly: true 
                        }
                    } else if (index === data.length - 1) {
                        return {
                            ...item,
                            readOnly: false, // Setting readOnly to false for the last object
                        };
                    } else {
                        return { ...item, readOnly: true }
                    }
                })
                setData(upDateData)
            }
        })
    }

    return (
        <div className='table-wrapper'>
            <div className="table-wrapper">
                <div className="rounded-sm border my-4">
                    <Table className="min-w-[1000px]" tableContainerClassName="!overflow-visible">
                        <TableHeader>
                            <TableRow className="bg-[#F9F9F9]">
                                <TableHead >
                                    {getField?.group} ID
                                </TableHead>
                                <TableHead >
                                    value
                                </TableHead>
                                <TableHead >
                                    Start Date
                                </TableHead>
                                <TableHead >
                                    End Date
                                </TableHead>
                                <TableHead >
                                    comment
                                </TableHead>
                                <TableHead >
                                    description
                                </TableHead>
                                <TableHead >
                                    Action
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {data.map((row, index) => {
                                const isError = !validation['assetID'] && !row['assetID'].trim();
                                const errorMessage = `${'assetID'} is required.`;
                                return (
                                    <TableRow key={index}>
                                        <TableCell className="p-2">
                                            <div className="relative">
                                                <Input
                                                    className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
                                                    placeholder={`Enter ${getField?.group} ID`}
                                                    value={row.assetID}
                                                    onFocus={() => setActiveField(row.id)} // Set activeField on focus
                                                    onChange={(e) => handleInputChange(e, row.id, 'assetID')}
                                                    readOnly={row.readOnly}
                                                />
                                                {isError && (
                                                    <div className="text-red text-[10px]">
                                                        {errorMessage}
                                                    </div>
                                                )}
                                                {getField?.group === 'Assets' && row.assetID.length >= 1 && row.id === activeField && row.searchData?.length >= 1 ? (
                                                    <ul className='suggested_list bg-white z-[99] absolute shadow'>
                                                        {row.searchData.map((item) => {
                                                            if (item.assetId) {
                                                                return (
                                                                    <li className='fs-13 fw-semi-bold' key={item.assetId} onClick={() => getSuggestedData(item.assetId, row.id, 'assetID')}>
                                                                        {item.assetId + " || " + (item.assetName ? item.assetName : item.symbol)}
                                                                    </li>
                                                                )
                                                            } else { }
                                                        })}
                                                    </ul>
                                                ) : <div></div>}
                                                {getField?.group === 'Issuers' && row.assetID.length >= 1 && row.id === activeField && row.searchData?.length >= 1 ? (
                                                    <ul className='suggested_list bg-white z-[99] absolute shadow'>
                                                        {row.searchData.map((item) => {
                                                            if (item.issuerId) {
                                                                return (
                                                                    <li className='fs-13 fw-semi-bold' key={item.issuerId} onClick={() => getSuggestedData(item.issuerId, row.id, 'assetID')}>
                                                                        {item.issuerId + " || " + (item.name ? item.name : item.code)}
                                                                    </li>
                                                                )
                                                            } else { }
                                                        })}
                                                    </ul>
                                                ) : <div></div>}
                                            </div>
                                        </TableCell>
                                        <TableCell className="p-2">
                                            <div className="">
                                                {(getField.type === "Ordinal" || getField.type === "Categorical") ? row.readOnly ? <Input
                                                    className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
                                                    value={row.value}
                                                    // onChange={(e) => handleInputChange(e, row.id, 'value')}
                                                    readOnly={row.readOnly}
                                                /> : <ValueSelect readOnly={row.readOnly} editData={getField} row={row} index={index} id={row.id} onValueSelect={onValueSelect} /> : <Input
                                                    className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
                                                    value={row.value}
                                                    onChange={(e) => handleInputChange(e, row.id, 'value')}
                                                    readOnly={row.readOnly}
                                                />}
                                                {!validation.value && !row.value && (
                                                    <div className="text-red text-[10px]">
                                                        value is required.
                                                    </div>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell className="p-2 relative">
                                            <div className="relative">
                                                <DatePickerCustomInput onDateSelect={(date) => handleDateChange(date, row.id, 'startDate')}
                                                    placeholderText='YYYY-MM-DD'
                                                    selectedFromDate={row.startDate} 
                                                    mainClassName={'body-table'}
                                                    readOnly={row.readOnly}
                                                    value={row.startDate}
                                                    upDateData={getField}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className="p-2 relative">
                                            <div className="relative">
                                                <DatePickerCustomInput onDateSelect={(date) => handleDateChange(date, row.id, 'endDate')}
                                                    placeholderText='YYYY-MM-DD'
                                                    minDate={row.startDate}
                                                    selectedFromDate={row.startDate}
                                                    value={row.endDate}
                                                    readOnly={row.readOnly}
                                                    upDateData={getField}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className="p-2">
                                            <div className="">
                                                <Input
                                                    className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
                                                    placeholder="Enter comment"
                                                    value={row.comment}
                                                    onChange={(e) => handleInputChange(e, row.id, 'comment')}
                                                    readOnly={row.readOnly}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className="p-2">
                                            <div className="">
                                                <Input
                                                    className="!py-1.5 h-[34px] placeholder:text-xs text-xs"
                                                    placeholder="Enter description"
                                                    value={row.description}
                                                    onChange={(e) => handleInputChange(e, row.id, 'description')}
                                                    readOnly={row.readOnly}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell className="">
                                            <div className="flex items-center justify-end">
                                                {row.readOnly ? <Button className="w-6 h-6 !bg-transparent !border-none !p-0"
                                                    onClick={() => handelEdit(row)}>
                                                    <Pencil2Icon  className='text-themeV2 w-5 h-5' />
                                                </Button> : ''}
                                                <Button
                                                    className="w-6 h-6 !bg-transparent !border-none !p-0"
                                                    onClick={() => handleDeleteRow(row)}
                                                >
                                                    <Trash2 className='w-5 h-5 text-red' />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div className="table-toolbar flex items-center justify-end gap-2 mb-4">
                    <Button className="w-[80px]" size="sm" onClick={() => handleAddRow()} disabled={isAddNew}>
                        <span><IconAdd className="w-4 h-4" /></span> Add
                    </Button>
                </div>
                <PreviewModel
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    fetchData={fetchData}
                    handleSaveData={handleSaveData}
                    getField={getField}
                    noValidate
                />
            </div>
        </div>
    )
}

export default AddManulDataTable