export const dateConvert = (date) => {
    let formattedDate = new Date(date).toLocaleDateString('en-US', {
        month: 'short', // Short month name (e.g., Jan)
        day: 'numeric', // Day of the month
        year: 'numeric' // Full year
    })

    return formattedDate
}

export const dateOrTimeConvert = (date) => {
    const formattedDate = new Date(date).toLocaleString('en-US', {
        month: 'short', // Short month name (e.g., Jan)
        day: 'numeric', // Day of the month
        year: 'numeric', // Full year
        hour: 'numeric', // Hour (e.g., 11)
        minute: 'numeric', // Minute (e.g., 39)
        hour12: true, // Use 12-hour clock
      });

      return formattedDate
}


export const formatCreatedAt = (createdAt) => {
    // Convert createdAt to a Date object
    if (!createdAt) {
        return "-"
    }
    const date = new Date(createdAt);

    // Get the individual components of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the timestamp as desired (e.g., YYYY-MM-DD HH:MM:SS)
    const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedTimestamp;
}