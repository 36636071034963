import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-js'
import { addInvestedAsset, updateInvestedAsset } from '../../../../store/actions/portfolioTransactionAction';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import Divider from '../../../../components/common/Divider';
import { FromDatePicker } from '../../../../components/ui/DatePickerNew';

const InvestAssetActionModel = (props) => {
    const { fetchInvestData, PortfolioName, isInvestQuantity, fetchData, groupSelectedId, isGroup, setIsLoading } = props;
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    const { slug } = useParams();
    const { strategy } = useSelector(state => state)
    const [error, setError] = useState({});
    const [updateId, setUpdateId] = useState(null)

    useEffect(() => {
        if (fetchInvestData) {
            setData(fetchInvestData)
        }
    }, [fetchInvestData])

    const [formData, setFormData] = useState({
        purchasePrice: '',
        quantity: '',
        purchaseDate: null
    })

    useEffect(() => {
        setFormData({
            purchasePrice: '',
            quantity: '',
            purchaseDate: null
        })
    }, [slug])

    // strategy 
    const [strategyOp, setStrategyOp] = useState(null)
    const strategyOptions = strategy && strategy.length ? [{ value: null, label: 'None' }, ...strategy.map((items) => {
        return { value: items._id, label: items.name }
    })] : []
    const onChangeStrategyOp = (obj) => {
        const getObj = JSON.parse(obj)
        setStrategyOp(getObj)
        setError({ ...error, ['strategy']: '' });
    }

    useEffect(() => {
        if (!_.isEmpty(fetchData) && !_.isEmpty(fetchInvestData)) {
            setFormData({
                purchasePrice: fetchData?.purchasePrice,
                quantity: fetchData?.quantity,
                // purchaseDate: fetchData ? moment(fetchData?.purchaseDate).format('YYYY-MM-DD') : new Date
                purchaseDate: fetchData ? fetchData?.purchaseDate : new Date()
            })
            setUpdateId(fetchData?._id)
            if (fetchInvestData?.strategy) {
                const getStrategy = strategyOptions.filter((items) => items.label === fetchInvestData.strategy)
                setStrategyOp(getStrategy.length ? getStrategy[0] : null)
            } else {
                setStrategyOp(null)
            }
            setError({})
        } else {
            if (!_.isEmpty(fetchInvestData)) {
                setFormData({
                    // purchasePrice: fetchInvestData?.averagePurchasePrice,
                    // quantity: fetchInvestData?.totalQuantity,
                    purchasePrice: 0,
                    quantity: 1,
                    // purchaseDate: moment(new Date).format('YYYY-MM-DD')
                    purchaseDate: new Date
                })
                setUpdateId(fetchInvestData?._id)
                if (fetchInvestData?.strategy) {
                    const getStrategy = strategyOptions.filter((items) => items.label === fetchInvestData.strategy)
                    setStrategyOp(getStrategy.length ? getStrategy[0] : null)
                } else {
                    setStrategyOp(null)
                }
                setError({})
            }
        }

    }, [fetchInvestData, fetchData])


    const onChangeHandel = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError({})
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;



        if (formData.purchasePrice === '') {
            errors['purchasePrice'] = 'Please enter price';
            isValid = false;
        }
        if (formData.purchaseDate === '') {
            errors['purchaseDate'] = 'Please select purchaseDate';
            isValid = false;
        }
        // if (formData.quantity === '' ) {
        //     errors['quantity'] = 'Please enter quantity';
        //     isValid = false;
        // }
        if (formData.quantity === '' || parseFloat(formData.quantity) <= 0) {
            errors['quantity'] = 'Please enter quantity';
            isValid = false;
        }
        // if (strategyOp === null) {
        //     errors['strategy'] = 'Please select strategy';
        //     isValid = false;
        // }
        setError(errors);
        return isValid;
    };

    const handleEmptyState = () => {
        setFormData({
            purchasePrice: '',
            quantity: '',
            purchaseDate: null
        })
    }


    const onSave = (e) => {
        if (handleValidation()) {
            if (!_.isEmpty(fetchInvestData)) {
                if (isInvestQuantity) {
                    dispatch(addInvestedAsset({
                        action: "add", // invest add
                        // operationType: 'addToPortfolio',
                        strategyId: strategyOp?.value ? strategyOp?.value : null,
                        //     // transactionId: fetchAssetItem && fetchAssetItem?._id,
                        assetId: fetchInvestData && fetchInvestData?.asset_id,
                        //     // investedAssetId : fetchAssetItem && fetchAssetItem?.asset?._id,
                        //     // investedAssetId : fetchAssetItem && fetchAssetItem?._id,
                        //     // portfolio: slug,
                        portfolioId: fetchInvestData.portfolio,
                        price: Number(formData.purchasePrice),
                        quantity: Number(formData.quantity),
                        date: moment(formData.purchaseDate).format('MM/DD/YYYY'),
                    }, isGroup ? groupSelectedId : [PortfolioName?.portfolio?._id], fetchInvestData._id, isGroup ? 'portfolioGroupInvestedAsset' : 'investedAsset', setIsLoading))
                    props.onHide()
                    handleEmptyState();
                } else {
                    setIsLoading(true)
                    const data = {
                        // asset: fetchInvestData && fetchInvestData.asset._id,
                        // asset: fetchInvestData && fetchInvestData._id,
                        // transactionId: fetchInvestData && fetchInvestData?._id,
                        // investedAssetId : fetchInvestData && fetchInvestData?._id,
                        // portfolio: fetchInvestData && fetchInvestData.portfolio,
                        // strategy: strategyOp?.value,
                        // purchaseDate: moment(formData.purchaseDate).format('MM/DD/YYYY'),
                        price: Number(formData.purchasePrice),
                        quantity: Number(formData.quantity),
                        date: formData.purchaseDate,
                        // investedAssetId: fetchInvestData?._id,
                        transactionId: fetchData?.id ? fetchData?.id : fetchData?._id, // transaction Id
                    }
                    dispatch(updateInvestedAsset(data, isGroup ? groupSelectedId : [PortfolioName?.portfolio?._id], fetchInvestData._id, 'investedAsset', setIsLoading))
                    props.onHide()
                    handleEmptyState();
                }
            }
        }
    }


    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[50%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{isInvestQuantity ? 'Add Quantity' : 'Edit Transaction'}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div>
                            <h5 className='mb-2'>Asset:</h5>
                            <div className="grid grid-cols-2 gap-5">
                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        ID:
                                    </Label>
                                    <Input
                                        className={`form-control shadow-none `} disabled={true}
                                        id="Id"
                                        type="text"
                                        name="Id"
                                        placeholder="Id"
                                        value={fetchInvestData?.assetId ? fetchInvestData?.assetId : 'N/A'} />
                                </div>
                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Name:
                                    </Label>
                                    <Input
                                        className={`form-control  shadow-none `}
                                        id="basic-form-dob"
                                        disabled={true}
                                        name='removalDate'
                                        type="text"
                                        value={fetchInvestData && fetchInvestData?.assetName} />
                                </div>

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Class:
                                    </Label>
                                    <Input
                                        className={`form-control shadow-none `} id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchInvestData && fetchInvestData?.class} />
                                </div>

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Sub Class:
                                    </Label>
                                    <Input
                                        className={`form-control  shadow-none `} id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchInvestData && fetchInvestData?.subClass} />
                                </div>

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Description:
                                    </Label>
                                    <Input
                                        className={`form-control  shadow-none `} id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchInvestData && fetchInvestData?.description} />
                                </div>

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Country:
                                    </Label>
                                    <Input
                                        className={`form-control  shadow-none `} id="basic-form-dob" disabled={true} name='removalDate' type="text" value={fetchInvestData && fetchInvestData?.country?.countryName} />
                                </div>


                                <div className="form-group">
                                    <Label htmlFor="name" className="block mb-2.5 text-textV1 font-500">
                                        Select strategy
                                    </Label>
                                    <Select
                                        onValueChange={(e) => onChangeStrategyOp(e)}
                                        defaultValue={strategyOp}
                                        disabled={true}
                                    >
                                        <SelectTrigger className="w-full">
                                            <SelectValue
                                                className='text-textV1'
                                                placeholder="Select strategy"
                                                disabled={true}
                                            >
                                                {strategyOp?.label ? strategyOp?.label : "Select strategy"}
                                            </SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectGroup>
                                                {
                                                    strategyOptions && strategyOptions.length > 0 ? strategyOptions.map((item, index) => {
                                                        return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                    }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                }
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>

                                    <div className={` ${error['strategy'] ? "text-red" : ''} px-2 py-1 text-sm`}>{error['strategy'] ? error['strategy'] : null}</div>
                                </div>

                            </div>
                        </div>
                        <Divider />
                        <div>
                            <h5 className='mb-2'>Asset Detail:</h5>
                            <div className="grid grid-cols-2 gap-5">
                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Purchase Price:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Input
                                        className={`form-control shadow-none ${error['purchasePrice'] && 'is-invalid'}`}
                                        name='purchasePrice'
                                        onChange={(e) => onChangeHandel(e)}
                                        id="inputEmail3"
                                        type="number"
                                        value={formData.purchasePrice} />

                                    <div className={`${error['purchasePrice'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['purchasePrice'] ? error['subClass'] : null}</div>
                                </div>

                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Purchase Quantity:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <Input
                                        className={`form-control shadow-none ${error['quantity'] && 'is-invalid'}`} id="inputEmail3" name='quantity' onChange={(e) => onChangeHandel(e)} type="number" value={formData.quantity} />

                                    <div className={`${error['quantity'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['quantity'] ? error['subClass'] : null}</div>
                                </div>


                                <div className="form-group">
                                    <Label htmlFor="username" className="block mb-2.5 text-textV1 font-500">
                                        Purchase Date:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                    <FromDatePicker onDateSelect={(date) => {
                                        setFormData({ ...formData, ['purchaseDate']: date });
                                        setError({})
                                    }}
                                        value={formData.purchaseDate}
                                        placeholderText='YYYY-MM-DD'
                                        selectedFromDate={formData.purchaseDate}
                                    />
                                    {/* <Input
                                        className={`form-control shadow-none ${error['purchaseDate'] && 'is-invalid'}`} name='purchaseDate' onChange={(e) => onChangeHandel(e)} id="basic-form-dob" type="date" value={formData.purchaseDate} /> */}

                                    <div className={`${error['purchaseDate'] ? "text-red" : ''}  px-2 py-1 text-sm`}>{error['purchaseDate'] ? error['subClass'] : null}</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </>
    )
}

export default InvestAssetActionModel
