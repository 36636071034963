import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { useDispatch } from 'react-redux'
import { addCustomFieldValue } from '../../../../../../store/actions/customFieldValue'

const CreateCategoricalModel = (props) => {
    const { updatedId, shortCodeName, data, setData, addDataIds, setAddDataIds, fetchData } = props;
    const dispatch = useDispatch()
    const [error, setError] = useState({});

    const [formData, setFormData] = useState({
        name: "",
    })

    useEffect(() => {
        if (fetchData !== null) {
            setFormData({
                name: fetchData.name,
            })
        }
    }, [fetchData])

    useEffect(() => {
        if (props.show === false) {
            setFormData({
                name: "",
                Value: "",
            })
        }
    }, [props.show])

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.name === '') {
            errors['Name'] = 'Please enter name';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const onSave = () => {
        if (handleValidation()) {
            if (fetchData === null) {
                let newShortCode = generateShortCode();
                while (isShortCodeExists(newShortCode)) {
                    newShortCode = generateShortCode();
                }
                const dataS = {
                    // _id: Math.floor(Math.random() * 50), // IF NOT REQUIRED
                    name: formData.name,
                    code: newShortCode
                }
                // const newData = [...data, dataS];
                // setData(newData);
                dispatch(addCustomFieldValue(dataS)).then((item) => {
                    if (item?.data) {
                        setAddDataIds([...addDataIds, item.data._id])
                        const newData = [...data, {
                            _id: item.data._id,
                            name: item.data.name,
                            code: item.data.code
                        }];
                        setData(newData);
                    } else {
                        // _id IF NOT REQUIRED Please Remove _id with else condition
                        const newData = [...data, { ...dataS, _id: Math.floor(Math.random() * 50) }];
                        setData(newData);
                    }

                })
            } else {
                const dataS = {
                    _id: fetchData._id,
                    name: formData.name,
                    code: fetchData.code
                }
                // const updateData = data.map((item) => {
                //     if (item._id === fetchData._id) {
                //         return {
                //             ...item,
                //             _id: fetchData._id,
                //             name: formData.name,
                //             code: fetchData.code
                //         }
                //     } else {
                //         return item;
                //     }
                // })
                // setData(updateData);
                dispatch(addCustomFieldValue(dataS)).then((items) => {
                    if (items?.data) {
                        const updateData = data.map((item) => {
                            if (item._id === fetchData._id) {
                                return {
                                    ...item,
                                    _id: items.data._id,
                                    name: items.data.name,
                                    code: items.data.code
                                }
                            } else {
                                return item;
                            }
                        })
                        setData(updateData);
                    } else {
                         // IF THIS NOT REQUIRED Please Remove else condition
                        const updateData = data.map((item) => {
                            if (item._id === fetchData._id) {
                                return {
                                    ...item,
                                    _id: fetchData._id,
                                    name: formData.name,
                                    code: fetchData.code
                                }
                            } else {
                                return item;
                            }
                        })
                        setData(updateData);
                    }

                })
            }
            // const newData = [...data, dataS];
            // setData(newData);
            props.onHide();
        }
    }

    // Function to generate a random shortcode
    const generateShortCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let shortCode = shortCodeName;

        // Generate three random characters and numbers
        for (let i = 0; i < 3; i++) {
            shortCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return shortCode;
    };


    // Function to check if the shortcode already exists in data
    const isShortCodeExists = (shortcode) => {
        return data.some(item => item.shortCode === shortcode);
    }

    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[40%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{"Add value"}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1">
                            <div className="form-group grid grid-cols-12 items-center gap-1">
                                <div className="lable-wrapper col-span-4lable-wrapper col-span-2">
                                    <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                        Name:<span style={{ color: 'red' }}>*</span>
                                    </Label>
                                </div>
                                <div className="field-wrapper col-span-10">
                                    <Input className={`form-control shadow-none h-[34px] text-sm px-2  ${error['Name'] && 'is-invalid'}`} placeholder="name" autoComplete={'off'} name='Name' onChange={(e) => setFormData({ ...formData, name: e.target.value })} id="inputEmail3" type="text" value={formData.name} />
                                    <div className={error['Name'] ? "text-red text-[10px]" : ''}>{error['Name'] ? error['Name'] : null}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={props.onHide}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateCategoricalModel
