import { analyticReportsActionType } from '../../constants';

const analyticReportsReducer = (state = [], action) => {
    switch (action.type) {
        case analyticReportsActionType.FETCH_ANALYTIC_REPORTS:
            return action.payload;
        default:
            return state;
    }
};
export default analyticReportsReducer