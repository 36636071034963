import * as React from "react"
import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils"
const generateBadgeVariant = (action) => {
  let variant;

  // 'inactive' 'authorised', 'unAuthorised', 'archived'
  switch (action.toLowerCase()) {
    case "created":
    case "allocation authorised":
    case "asset allocated":
    case "sub asset allocated":
    case "authorised":
    case "authorise":
    case "assigned":
    case "active":
    case "added":
    case "restored":
      variant = "success";
      break;
    case "allocation unauthorised":
    case "unauthorised":
    case "unauthorise":
    case "removed":
    case "transaction removed":
    case "sell":
      variant = "destructive";
      break;
    case "updated":
    case "allocated asset updated":
    case "transaction updated":
    case "buy":
      variant = "secondary";
      break;
    case "inactive":
    case "n/a":
      variant = "warning";
      break;
    case "invested":
      variant = "darkSecondary";
      break;
    default:
      variant = "other"; // You can change this to whatever default variant you prefer
      break;
  }

  return variant;
};

const badgeVariants = cva(
  "inline-flex items-center rounded-[5px] min-w-[70px] capitalize inline-flex items-center justify-center border px-2 py-0 text-[11px] font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        darkSecondary:
          "border-[#141824]/30 bg-[#e3e6ed] text-[#141824] hover:bg-[#e3e6ed]/80",
        secondary:
          "border-[#003cc7]/30 bg-[#e5edff] text-[#003cc7] hover:bg-[#e5edff]/80",
        destructive:
          "border-[#B81800]/30 bg-[#FFE0DB] text-[#B81800] hover:bg-[#FFE0DB]/80",
        success:
          "border-[#1C6C09]/30 bg-[#D9FBD0] text-[#1C6C09] hover:bg-[#D9FBD0]/80",
        warning:
          "border-[#bc3803]/30 bg-[#ffefca] text-[#bc3803] hover:bg-[#ffefca]/80",
        outline: "text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

function Badge({
  className,
  variant,
  ...props
}) {
  return (<div className={cn(badgeVariants({ variant }), className)} {...props} />);
}

export { Badge, badgeVariants, generateBadgeVariant }
