import * as React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { ScatterChart } from 'echarts/charts';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent, ToolboxComponent } from 'echarts/components';
import { SVGRenderer } from 'echarts/renderers';
import DownloadChart from '../../shared/DownloadChart';

echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, ScatterChart, ToolboxComponent, SVGRenderer]);

export default function BasicScatter(props) {
    const { analyticReports, updateId, isAutoChart, isReports, index, refEl } = props;
    const [chartData, setChartData] = React.useState([])

    React.useEffect(() => {
        if (updateId === null && isReports === null) {
            setChartData([])
        } else {
            setChartData(analyticReports)
        }
    }, [analyticReports])



    let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
    let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];
    const labels = dateLabels ? dateLabels : '';
    let data
    if (isReports) {
        data = {
            labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
            datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
                return {
                    ...item, borderColor: item.backgroundColor,
                    borderWidth: 1
                }
            }) : []
        }
    } else {
        data = {
            labels,
            datasets: [
                {
                    label: labels,
                    data: chartValues,
                    fill: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(255, 159, 64, 0.5)',
                        'rgba(255, 205, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 0, 223, 0.5)'
                    ],
                    borderColor: [
                        'rgb(255, 99, 132)',
                        'rgb(255, 159, 64)',
                        'rgb(255, 205, 86)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)',
                        'rgb(255, 0, 223)'
                    ],
                    borderWidth: 1
                },
            ],
        };
    }


    // Extracting x and y data from the datasets
    const seriesData = data.datasets[0]?.data.map((value, index) => ({
        x: data.labels[index],
        y: parseFloat(value),
        id: value,
    }));

    const transformedData = data.datasets.map((dataset) => ({
        id: dataset.label,
        color: `hsl(${Math.floor(Math.random() * 360)}, 70%, 50%)`,
        data: dataset.data.map((value, index) => ({
            x: data.labels[index],
            y: parseInt(Number(value)),
        })),
    }));

    const transformedChartData = transformedData.flatMap(entry =>
        entry.data.map(dataPoint => ({
            name: `${entry.id} - ${dataPoint.x}`,
            value: parseInt(Number(dataPoint.y))
        }))
    );
    const option = {
        tooltip: {
            trigger: 'axis',
            axis: 'auto',
            axisPointer: { type: 'shadow' },
            displayMode: 'single',
            renderMode: 'auto',
            // Confining tooltip to the body
            confine: true,
            align: 'center',
            backgroundColor: '#001F3D',
            appendToBody: true,
            textStyle: {
                fontSize: 12, // Adjust the font size as needed
                color: '#FFFFFF',
            }
        },
        legend: {
            type: 'scroll',
            right: 0,
            top: 0,
            left: 0,
        },
        xAxis: [{
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            data: data.labels
        }],
        yAxis: [{
            type: 'value',
            position: 'left'
        }],
        // toolbox: {
        //     feature: {
        //         saveAsImage: { show: true, title: 'save' },
        //         type: 'png'
        //     },
        //     top: 20
        // },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        series: transformedData.map(dataset => ({
            name: dataset.id,
            type: 'scatter',
            symbolSize: 10, // Symbol size
            data: dataset.data.map(dataPoint => [dataPoint.x, dataPoint.y]) // Transforming data format
        }))
    };
   

    return (
        <>
        {data.datasets.length && data.labels.length ?
          <>
            <DownloadChart chartRef={refEl} chartData={data} chartId={"echarts-chart" + index} echarts={echarts} />
            <ReactEChartsCore
              className={"echarts-chart" + index  + ' ' + 'css-13aj3tc-MuiChartsSurface-root'}
              ref={refEl}
              echarts={echarts}
              style={{ width: '100%', height: '100%' }}
              option={option}
            />
    
          </> : <> <div>No Data Available</div>  </>}
    
      </>
    );
}


// =====================================================

// import * as React from 'react';
// import { ScatterChart } from '@mui/x-charts/ScatterChart';
// import DownloadChart from '../../shared/DownloadChart';

// const dataS = [
//     {
//         id: 'data-0',
//         x1: 329.39,
//         x2: 391.29,
//         y1: 443.28,
//         y2: 153.9,
//     },
//     {
//         id: 'data-1',
//         x1: 96.94,
//         x2: 139.6,
//         y1: 110.5,
//         y2: 217.8,
//     },
//     {
//         id: 'data-2',
//         x1: 336.35,
//         x2: 282.34,
//         y1: 175.23,
//         y2: 286.32,
//     },
//     {
//         id: 'data-3',
//         x1: 159.44,
//         x2: 384.85,
//         y1: 195.97,
//         y2: 325.12,
//     },
//     {
//         id: 'data-4',
//         x1: 188.86,
//         x2: 182.27,
//         y1: 351.77,
//         y2: 144.58,
//     },
//     {
//         id: 'data-5',
//         x1: 143.86,
//         x2: 360.22,
//         y1: 43.253,
//         y2: 146.51,
//     },
//     {
//         id: 'data-6',
//         x1: 202.02,
//         x2: 209.5,
//         y1: 376.34,
//         y2: 309.69,
//     },
//     {
//         id: 'data-7',
//         x1: 384.41,
//         x2: 258.93,
//         y1: 31.514,
//         y2: 236.38,
//     },
//     {
//         id: 'data-8',
//         x1: 256.76,
//         x2: 70.571,
//         y1: 231.31,
//         y2: 440.72,
//     },
//     {
//         id: 'data-9',
//         x1: 143.79,
//         x2: 419.02,
//         y1: 108.04,
//         y2: 20.29,
//     },
//     {
//         id: 'data-10',
//         x1: 103.48,
//         x2: 15.886,
//         y1: 321.77,
//         y2: 484.17,
//     },
//     {
//         id: 'data-11',
//         x1: 272.39,
//         x2: 189.03,
//         y1: 120.18,
//         y2: 54.962,
//     },
//     {
//         id: 'data-12',
//         x1: 23.57,
//         x2: 456.4,
//         y1: 366.2,
//         y2: 418.5,
//     },
//     {
//         id: 'data-13',
//         x1: 219.73,
//         x2: 235.96,
//         y1: 451.45,
//         y2: 181.32,
//     },
//     {
//         id: 'data-14',
//         x1: 54.99,
//         x2: 434.5,
//         y1: 294.8,
//         y2: 440.9,
//     },
//     {
//         id: 'data-15',
//         x1: 134.13,
//         x2: 383.8,
//         y1: 121.83,
//         y2: 273.52,
//     },
//     {
//         id: 'data-16',
//         x1: 12.7,
//         x2: 270.8,
//         y1: 287.7,
//         y2: 346.7,
//     },
//     {
//         id: 'data-17',
//         x1: 176.51,
//         x2: 119.17,
//         y1: 134.06,
//         y2: 74.528,
//     },
//     {
//         id: 'data-18',
//         x1: 65.05,
//         x2: 78.93,
//         y1: 104.5,
//         y2: 150.9,
//     },
//     {
//         id: 'data-19',
//         x1: 162.25,
//         x2: 63.707,
//         y1: 413.07,
//         y2: 26.483,
//     },
//     {
//         id: 'data-20',
//         x1: 68.88,
//         x2: 150.8,
//         y1: 74.68,
//         y2: 333.2,
//     },
//     {
//         id: 'data-21',
//         x1: 95.29,
//         x2: 329.1,
//         y1: 360.6,
//         y2: 422.0,
//     },
//     {
//         id: 'data-22',
//         x1: 390.62,
//         x2: 10.01,
//         y1: 330.72,
//         y2: 488.06,
//     },
// ];

// export default function BasicScatter(props) {
//     const { analyticReports, updateId, isAutoChart, isReports, refEl } = props;
//     const [chartData, setChartData] = React.useState([])

//     React.useEffect(() => {
//         if (updateId === null && isReports === null) {
//             setChartData([])
//         } else {
//             setChartData(analyticReports)
//         }
//     }, [analyticReports])



//     let dateLabels = chartData && chartData?.length >= 1 ? chartData[0].data?.dates : [];
//     let chartValues = chartData && chartData?.length >= 1 ? chartData[0].data?.value : [];
//     const labels = dateLabels ? dateLabels : '';
//     let data
//     if (isReports) {
//         data = {
//             labels: chartData && chartData?.labels?.length >= 1 ? chartData.labels : [],
//             datasets: chartData && chartData?.datasets?.length >= 1 ? chartData?.datasets?.map(item => {
//                 return {
//                     ...item, borderColor: item.backgroundColor,
//                     borderWidth: 1
//                 }
//             }) : []
//         }
//     } else {
//         data = {
//             labels,
//             datasets: [
//                 {
//                     label: labels,
//                     data: chartValues,
//                     fill: true,
//                     backgroundColor: [
//                         'rgba(255, 99, 132, 0.5)',
//                         'rgba(255, 159, 64, 0.5)',
//                         'rgba(255, 205, 86, 0.5)',
//                         'rgba(75, 192, 192, 0.5)',
//                         'rgba(54, 162, 235, 0.5)',
//                         'rgba(153, 102, 255, 0.5)',
//                         'rgba(255, 0, 223, 0.5)'
//                     ],
//                     borderColor: [
//                         'rgb(255, 99, 132)',
//                         'rgb(255, 159, 64)',
//                         'rgb(255, 205, 86)',
//                         'rgb(75, 192, 192)',
//                         'rgb(54, 162, 235)',
//                         'rgb(153, 102, 255)',
//                         'rgb(255, 0, 223)'
//                     ],
//                     borderWidth: 1
//                 },
//             ],
//         };
//     }


//     // Extracting x and y data from the datasets
//     const seriesData = data.datasets[0]?.data.map((value, index) => ({
//         x: data.labels[index],
//         y: parseFloat(value),
//         id: value,
//     }));

//     return (
//         <>
//             {data.datasets.length && data.labels.length ?
//                 <>
//                     <DownloadChart chartData={data} chartRef={refEl} />
//                     <ScatterChart
//                     ref={refEl}
//                         width={600}
//                         height={300}
//                         series={[
//                             {
//                                 label: data.datasets[0].label,
//                                 data: seriesData,
//                             },
//                         ]}
//                     /> </> : <div>No Data Available</div> }
//         </>
//     );
// }

// =====================================================


// <ScatterChart
//                     ref={refEl}
//                     width={600}
//                     height={300}
//                     series={[
//                         {
//                             label: 'Series A',
//                             data: dataS.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
//                         },
//                         {
//                             label: 'Series B',
//                             data: dataS.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
//                         },
//                     ]}

//                 />