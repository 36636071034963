import React from 'react'
import IconDrag from '../svg/IconDrag'
// import { useDrag } from 'react-dnd'

const FieldItem = ({obj}) => {
    return (
        <li className='h-[30px] px-3 flex items-center cursor-grab gap-3 hover:bg-theme hover:border-theme group justify-start border-b border-borderV1'>
           <span><IconDrag className="text-textV1 w-3 h-3"/></span> <span className='text-xs text-textV1 group-hover:text-white'>{obj.value}</span>
        </li>
    )
}

export default FieldItem