import React from 'react'

const IconExport = (props) => {
  return (
    <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.2959 13.8008H25.3187V17.3474H31.2959C33.0733 17.3474 34.4523 18.2553 34.4523 19.0367V34.7634C34.4523 35.5448 33.0733 36.4527 31.2959 36.4527H8.70302C6.9256 36.4527 5.54657 35.5448 5.54657 34.7634V19.0373C5.54657 18.2559 6.9256 17.3479 8.70302 17.3479H14.679V13.8014H8.70302C4.94424 13.8014 2 16.1013 2 19.0373V34.764C2 37.7005 4.94424 39.9999 8.70302 39.9999H31.2964C35.0546 39.9999 37.9995 37.6999 37.9995 34.764V19.0373C37.9989 16.1007 35.0546 13.8008 31.2959 13.8008Z" fill="currentcolor"/>
    <path d="M14.7517 8.83983C14.9846 8.8401 15.2153 8.79434 15.4305 8.70517C15.6456 8.61601 15.841 8.4852 16.0055 8.32026L18.2256 6.1001V24.1415C18.2256 24.6118 18.4124 25.0629 18.745 25.3954C19.0775 25.728 19.5286 25.9148 19.9989 25.9148C20.4692 25.9148 20.9202 25.728 21.2528 25.3954C21.5853 25.0629 21.7722 24.6118 21.7722 24.1415V5.98011L24.1123 8.32026C24.4587 8.66664 24.9127 8.83983 25.366 8.83983C25.8194 8.83983 26.2734 8.66664 26.6197 8.32026C26.7845 8.15568 26.9152 7.96024 27.0044 7.74512C27.0935 7.53 27.1394 7.29941 27.1394 7.06655C27.1394 6.83368 27.0935 6.60309 27.0044 6.38797C26.9152 6.17285 26.7845 5.97741 26.6197 5.81283L21.3253 0.518394C21.1609 0.35366 20.9656 0.223055 20.7505 0.13409C20.5354 0.0451253 20.3049 -0.000444029 20.0722 3.26111e-06H20.045C19.5916 3.26111e-06 19.1383 0.172012 18.7919 0.518394L13.4974 5.81283C13.2489 6.06064 13.0795 6.37667 13.0108 6.72086C12.9421 7.06504 12.9772 7.42187 13.1115 7.74611C13.2459 8.07034 13.4735 8.34738 13.7655 8.5421C14.0575 8.73682 14.4008 8.84044 14.7517 8.83983Z" fill="currentcolor"/>
    </svg>
  )
}

export default IconExport