
import { apiBaseURL, countriesActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";



export const fetchCountries = (isLoading) => async (dispatch) => {
    try {
      isLoading !== false && dispatch(setLoading(true));
      const response = await apiConfig.get(apiBaseURL.COUNTRIES);
      dispatch({ type: countriesActionType.FETCH_COUNTRIES, payload: response.data?.data });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };


