import apiConfig from '../../config/apiConfig';
import { apiBaseURL, financeExchangesActionType, financePriceActionType, toastifyType } from '../../constants';
import { setLoading } from './loadingAction';
import { addTost } from './toastAction';

export const fetchFinanceExchanges = (country) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const response = await apiConfig.get(apiBaseURL.FINANCE + '/exchanges' + '?country='+ country)
        dispatch({ type: financeExchangesActionType.FETCH_FINANCE_EXCHANGES_V2, payload: response.data?.data });
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}