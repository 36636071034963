import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { addCustomField } from '../../../../../../store/actions/BYODAction'
import { Textarea } from '@/components/ui/textarea'
import { groupBYODOp, subTypeBYODOp, typeBYODOp } from '../../../../../../static/static'
import DataTableColumnHeader from '../../../../../../shared/common/table-components/tasks/components/data-table-column-header'
import CreateValueModel from './CreateValueModel'
import { validateShortCode } from '../../../../../../store/actions/customFieldValidate'
import { addTost } from '../../../../../../store/actions/toastAction'
import { Filters, toastifyType } from '../../../../../../constants'
import { DataTableWithCustomPagination } from '../../../../../../shared/common/table-components/tasks/components/data-table-without-pagination'
import { deleteCustomFieldValue } from '../../../../../../store/actions/customFieldValue'
import { DotsHorizontalIcon, Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown.jsx';
import CreateCategoricalModel from '../createCategorical/createCategoricalModel'


const CreateBYODModel = (props) => {
    const { editData, setEditData } = props;
    const dispatch = useDispatch();
    const [fetchData, setFetchData] = useState(null)
    const [addDataIds, setAddDataIds] = useState([])
    const [updatedId, setUpdatedId] = useState(null)
    const { metaData } = useSelector(state => state)
    const [error, setError] = useState({});
    const [showModel, setShowModel] = useState(false)
    const [data, setData] = useState([])
    const [isValid, setIsValid] = useState(false)
    const [formData, setFormData] = useState({
        shortName: "",
        longName: "",
        description: "",
        type: typeBYODOp[0] || null,
        subType: subTypeBYODOp[0] || null,
        group: groupBYODOp[0] || null
    })


    // pagination
    const [currentPage, setCurrentPage] = useState(Filters.PAGE);
    const [perPage, setPerPage] = useState(Filters.OBJ.perPage - 5); // Default per page value


    // Pagination calculations heading
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = Math.min(startIndex + perPage, data?.length);
    const paginatedData = data?.slice(startIndex, endIndex) || [];

    // groupBYODOp
    useEffect(() => {
        if (editData?._id) {
            const getGroupBYODOp = groupBYODOp.find((item) => item.label === editData?.group)
            const getBYODOType = typeBYODOp.find((item) => item.label === editData?.type)
            setFormData({
                id: editData?._id,
                shortName: editData.code,
                longName: editData.name,
                description: editData.description,
                type: getBYODOType || null,
                subType: subTypeBYODOp[0] || null,
                group: getGroupBYODOp
            })
            if (getBYODOType.label === 'Ordinal') {
                // dispatch()
            }
        }
    }, [editData])

    useEffect(() => {
        if (props.show === false) {
            setFormData({
                shortName: "",
                longName: "",
                description: "",
                type: typeBYODOp[0] || null,
                subType: subTypeBYODOp[0] || null,
                group: groupBYODOp[0] || null
            })
            setData([])
            setError({})
            setAddDataIds([])
            setFetchData(null)
            setUpdatedId(null)
            setEditData(null)
        }
    }, [props.show])

    // useEffect(() => {
    //     updatedId !== null && dispatch(fetchCustomFieldValue({ page: currentPage, perPage: perPage }, setPerPage, setCurrentPage))
    // }, [currentPage, perPage])

    const validateCode = () => {
        if (formData.shortName && formData.type !== null) {
            const getMessage = dispatch(validateShortCode({
                code: formData.shortName,
                fieldType: formData.type.label, // 'Quantitative', 'Ordinal', 'Categorical'
                codeType: "fieldCode"
            })).then(message => {
                if (message === 'Valid short code.') {
                    setIsValid(false);
                } else if (message === 'inValid short code.' || message === 'Field already exist with code.') {
                    setError({
                        shortName: 'inValid short code.'
                    });
                    setIsValid(true);
                } else {
                    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: message }));
                }
            }).catch(error => {
                console.error('Error:', error);
                // Handle error if needed
            });
        }

        if (data.length && formData.shortName) {
            const updatedData = data.map(item => {
                let newShortCode = generateShortCode(formData.shortName);
                while (isShortCodeExists(newShortCode)) {
                    newShortCode = generateShortCode(formData.shortName);
                }
                return {
                    ...item,
                    code: newShortCode
                };
            });
            setData(updatedData);
        }
    }

    // Function to generate a random shortcode
    const generateShortCode = (shortName) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let shortCode = shortName;

        // Generate three random characters and numbers
        for (let i = 0; i < 3; i++) {
            shortCode += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return shortCode;
    };

    // Function to check if the shortcode already exists in data
    const isShortCodeExists = (shortcode) => {
        return data.some(item => item.code === shortcode);
    }
    const BYODTypeOptions = typeBYODOp
    const BYODSubTypeOptions = subTypeBYODOp
    const onChangeBYODOption = (event) => {
        setFormData({ ...formData, type: event })
        if (addDataIds.length) {
            const payload = addDataIds
            dispatch(deleteCustomFieldValue(payload))
        }
        setData([])
        setError({})
        setAddDataIds([])
        setFetchData(null)
        setUpdatedId(null)
        setEditData(null)
        setShowModel(false)
    }
    const onChangeSubBYODOption = (event) => {
        setFormData({ ...formData, subType: event })
    }
    const onChangeData = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
        setError({})
    }

    const handleValidation = () => {
        let errors = {};
        let isValid = true;
        if (formData.shortName === '') {
            errors['shortName'] = 'Please enter short name';
            isValid = false;
        }
        if (formData.longName === '') {
            errors['longName'] = 'Please enter long name';
            isValid = false;
        }
        // if (formData.description === '') {
        //     errors['description'] = 'Please enter description';
        //     isValid = false;
        // }
        if (formData.type === null) {
            errors['type'] = 'Please select type';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const onSave = (e) => {
        if (handleValidation()) {
            if (formData.type.label === 'Ordinal') {
                if (formData.id) {
                    dispatch(addCustomField({
                        id: formData.id,
                        code: formData.shortName,
                        name: formData.longName,
                        description: formData.description,
                        type: formData.type.label,
                        group: formData.group.label,
                        subType: formData.subType.label,
                        values: addDataIds
                    }, { page: metaData.pageNumber, perPage: metaData.pageSize }))
                } else {
                    dispatch(addCustomField({
                        code: formData.shortName,
                        name: formData.longName,
                        description: formData.description,
                        type: formData.type.label,
                        group: formData.group.label,
                        subType: formData.subType.label,
                        values: addDataIds
                    }, { page: metaData.pageNumber, perPage: metaData.pageSize }))
                }
            } else if (formData.type.label === 'Categorical') {
                if (formData.id) {
                    dispatch(addCustomField({
                        id: formData.id,
                        code: formData.shortName,
                        name: formData.longName,
                        description: formData.description,
                        type: formData.type.label,
                        group: formData.group.label,
                        subType: "Text",
                        values: addDataIds
                    }, { page: metaData.pageNumber, perPage: metaData.pageSize }))
                } else {
                    dispatch(addCustomField({
                        code: formData.shortName,
                        name: formData.longName,
                        description: formData.description,
                        type: formData.type.label,
                        group: formData.group.label,
                        subType: "Text",
                        values: addDataIds
                    }, { page: metaData.pageNumber, perPage: metaData.pageSize }))
                }
            } else {
                if (formData.id) {
                    dispatch(addCustomField({
                        id: formData.id,
                        code: formData.shortName,
                        name: formData.longName,
                        description: formData.description,
                        type: formData.type.label,
                        group: formData.group.label,
                        subType: 'Numeric',
                        // subType: formData.subType.label,
                    }, { page: metaData.pageNumber, perPage: metaData.pageSize }))
                } else {
                    dispatch(addCustomField({
                        code: formData.shortName,
                        name: formData.longName,
                        description: formData.description,
                        type: formData.type.label,
                        group: formData.group.label,
                        subType: 'Numeric',
                        // subType: formData.subType.label,
                    }, { page: metaData.pageNumber, perPage: metaData.pageSize }))
                }
            }
            setFormData({
                shortName: "",
                longName: "",
                description: "",
                type: { label: 'Quantitative', value: 1 },
                subType: {
                    value: 1,
                    label: 'Numeric'
                },
                group: {
                    Value: 1,
                    label: 'Assets'
                },
            })
            setEditData(null)
            props.onHide();
        }
    }

    const onChangGroupOptions = (value) => {
        setFormData({ ...formData, group: value });
        setError({});
    };

    const groupOptions =
        groupBYODOp && groupBYODOp?.length >= 1
            ? groupBYODOp?.map((item) => {
                return { value: item.id, label: item.label };
            }) : [];


    const onEdit = (value) => {
        setFetchData(value);
        setShowModel(!showModel)
    }

    const onRemove = (value) => {
        const updateData = data.filter((item) => item._id !== value?._id);
        const saveDataId = addDataIds.filter(itemId => itemId !== value?._id);
        const payload = [value?._id]
        dispatch(deleteCustomFieldValue(payload))
        setAddDataIds(saveDataId);
        setData(updateData);
    }

    const columns = [
        {
            accessorKey: "code",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Code" />
            ),
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("code")}</div>
            ),
            enableSorting: true,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("name")}</div>
            ),
            enableSorting: true,
        },
        {
            accessorKey: "value",
            header: "Value",
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("value")}</div>
            ),
        },
        {
            id: "actions",
            enableHiding: false,
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `} onClick={() => onEdit(row?.original)}> <span className='w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]'><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> </span> Edit</DropdownMenuItem>
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `} onClick={() => onRemove(row?.original)}> <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /></span> Remove</DropdownMenuItem>

                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        }
    ]

    const columnsCategorical = [
        {
            accessorKey: "code",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Code" />
            ),
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("code")}</div>
            ),
            enableSorting: true,
        },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => (
                <div className="capitalize">{row.getValue("name")}</div>
            ),
            enableSorting: true,
        },
        {
            id: "actions",
            enableHiding: false,
            header: <span className="flex items-center justify-end">Action</span>,
            cell: ({ row }) => {
                return (
                    <div className="flex items-center justify-end">
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="ghost" className="p-0">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon strokeWidth={"2.2"} className="h-5 w-5 text-textV2" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `} onClick={() => onEdit(row?.original)}> <span className='w-5 h-5 bg-themeV2/10 flex items-center justify-center rounded-[4px]'><Pencil2Icon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> </span> Edit</DropdownMenuItem>
                                <DropdownMenuItem className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 `} onClick={() => onRemove(row?.original)}> <span className='w-5 h-5 bg-red/10 flex items-center justify-center rounded-[4px]'><TrashIcon strokeWidth={"2.2"} className='text-red w-4 h-4' /></span> Remove</DropdownMenuItem>

                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                )
            },
        }
    ]

    // console.log('addDataIds==========', addDataIds)
    // console.log('data==========', data)
    // console.log('updatedId==========', updatedId)

    const onCancel = () => {
        if (addDataIds.length) {
            const payload = addDataIds
            dispatch(deleteCustomFieldValue(payload))
        }
        props.onHide();
    }

    return (
        <>
            <Dialog open={props.show} onOpenChange={props.onHide}>
                <DialogContent className="sm:max-w-[60%] gap-10">
                    <DialogHeader>
                        <DialogTitle className="text-center">{editData?._id ? "Edit Field" : "Define New Field"}</DialogTitle>
                    </DialogHeader>
                    <div className="dialog-body">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-5">
                            <div className="flex flex-col gap-6">
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="label-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label
                                            htmlFor="name"
                                            className="text-[13px] text-textV2 font-500 cursor-pointer"
                                        >
                                            Group:
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) =>
                                                onChangGroupOptions(JSON.parse(e))
                                            }
                                            defaultValue={formData?.group}
                                        >
                                            <SelectTrigger className="w-full h-[34px] text-[13px] rounded-[12px]">
                                                <SelectValue
                                                    className="text-textV1"
                                                    placeholder="Select Group"
                                                >
                                                    {formData?.group !== null
                                                        ? formData?.group?.label
                                                        : "Select Group"}
                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>
                                                    {groupOptions && groupOptions.length > 0 ? (
                                                        groupOptions.map((item, index) => {
                                                            return (
                                                                <SelectItem
                                                                    key={index}
                                                                    className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2"
                                                                    value={JSON.stringify(item)}
                                                                >
                                                                    {item?.label}
                                                                </SelectItem>
                                                            );
                                                        })
                                                    ) : (
                                                        <SelectItem
                                                            disabled
                                                            className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center"
                                                            value={"No option"}
                                                        >
                                                            {"No option"}
                                                        </SelectItem>
                                                    )}
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Long Name:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input className={`form-control shadow-none h-[34px] text-sm px-2  ${error['longName'] && 'is-invalid'}`} placeholder="name" autoComplete={'off'} name='longName' onChange={(e) => onChangeData(e)} id="inputEmail3" type="text" value={formData.longName} />
                                        <div className={error['longName'] ? "text-red text-[10px]" : ''}>{error['longName'] ? error['longName'] : null}</div>
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Description:
                                            {/* <span style={{ color: 'red' }}>*</span> */}
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Textarea className={`form-control shadow-none h-[34px] text-sm px-2 ${error['description'] && 'is-invalid'}`} placeholder="description" name='description' onChange={(e) => onChangeData(e)} id="basic-form-textarea" rows="4" value={formData.description} />
                                        {/* <div className={error['description'] ? "text-red text-[10px]" : ''}>{error['description'] ? error['description'] : null}</div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-6">
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="username" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Short Name:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Input className={`form-control shadow-none h-[34px] text-sm px-2  ${error['shortName'] && 'is-invalid'}`} placeholder="code" autoComplete={'off'} name='shortName' onChange={(e) => onChangeData(e)} onBlur={(e) => validateCode(e)} id="inputEmail3" type="text" value={formData.shortName} />
                                        <div className={error['shortName'] ? "text-red text-[10px]" : ''}>{error['shortName'] ? error['shortName'] : null}</div>
                                    </div>
                                </div>
                                <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Type:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeBYODOption(JSON.parse(e))}
                                            // disabled={true}
                                            defaultValue={formData.type}

                                        >
                                            <SelectTrigger className="w-full h-[34px] text-sm">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Type"
                                                    disabled={true}
                                                >
                                                    {formData.type.label}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>

                                                    {
                                                        BYODTypeOptions && BYODTypeOptions.length > 0 ? BYODTypeOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['type'] ? "text-red text-[10px]" : ''}>{error['type'] ? error['type'] : null}</div>
                                    </div>
                                </div>
                                {formData.type.label === 'Ordinal' && <div className="form-group grid grid-cols-12 items-center gap-1">
                                    <div className="lable-wrapper col-span-4lable-wrapper col-span-4">
                                        <Label htmlFor="name" className="text-[13px] text-textV2 font-500 cursor-pointer">
                                            Sub Type:<span style={{ color: 'red' }}>*</span>
                                        </Label>
                                    </div>
                                    <div className="field-wrapper col-span-8">
                                        <Select
                                            onValueChange={(e) => onChangeSubBYODOption(JSON.parse(e))}
                                            // disabled={true}
                                            defaultValue={formData.subType}

                                        >
                                            <SelectTrigger className="w-full h-[34px] text-sm">
                                                <SelectValue
                                                    className='text-textV1'
                                                    placeholder="Select Type"
                                                // disabled={true}
                                                >
                                                    {formData.subType.label}

                                                </SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectGroup>

                                                    {
                                                        BYODSubTypeOptions && BYODSubTypeOptions.length > 0 ? BYODSubTypeOptions.map((item, index) => {
                                                            return <SelectItem key={index} className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" value={JSON.stringify(item)}>{item?.label}</SelectItem>
                                                        }) : <SelectItem disabled className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2 text-center" value={'No option'}>{'No option'}</SelectItem>
                                                    }
                                                </SelectGroup>
                                            </SelectContent>
                                        </Select>
                                        <div className={error['type'] ? "text-red text-[10px]" : ''}>{error['type'] ? error['type'] : null}</div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        {formData.type.label === 'Ordinal' && <div className="grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1">
                            <div className="my-2 gap-0">
                                <Button type="submit" size="sm" variant='outline' className="min-w-[100px]" disabled={formData.shortName === '' || formData.type === null || isValid} onClick={() => setShowModel(!showModel)}>Add Value</Button>
                            </div>
                            <div className="flex flex-col gap-0">
                                <DataTableWithCustomPagination
                                    data={paginatedData}
                                    columns={columns}
                                    isSearchHide={true}
                                    hidePagination={true}
                                    isOldHeaderHide={true}
                                    isRightNew={true}
                                    isCollViewHide={true}
                                    setPerPage={setPerPage}
                                    perPage={perPage}
                                    totalRecords={data?.length || 0}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPages={Math.ceil((data?.length || 0) / perPage)} />
                            </div>
                        </div>}
                        {formData.type.label === 'Ordinal' && <CreateValueModel
                            show={showModel}
                            onHide={() => setShowModel(false)}
                            updatedId={updatedId}
                            shortCodeName={formData.shortName}
                            data={data}
                            setData={setData}
                            addDataIds={addDataIds}
                            setAddDataIds={setAddDataIds}
                            fetchData={fetchData} />}
                        {formData.type.label === 'Categorical' && <div className="grid grid-cols-1 md:grid-cols-1 gap-1 md:gap-1">
                            <div className="my-2 gap-0">
                                <Button type="submit" size="sm" variant='outline' className="min-w-[100px]" disabled={formData.shortName === '' || formData.type === null || isValid} onClick={() => setShowModel(!showModel)}>Add Value</Button>
                            </div>
                            <div className="flex flex-col gap-0">
                                <DataTableWithCustomPagination
                                    data={paginatedData}
                                    columns={columnsCategorical}
                                    isSearchHide={true}
                                    hidePagination={true}
                                    isOldHeaderHide={true}
                                    isRightNew={true}
                                    isCollViewHide={true}
                                    setPerPage={setPerPage}
                                    perPage={perPage}
                                    totalRecords={data?.length || 0}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    totalPages={Math.ceil((data?.length || 0) / perPage)} />
                            </div>
                        </div>}
                        {formData.type.label === 'Categorical' && <CreateCategoricalModel
                            show={showModel}
                            onHide={() => setShowModel(false)}
                            updatedId={updatedId}
                            shortCodeName={formData.shortName}
                            data={data}
                            setData={setData}
                            addDataIds={addDataIds}
                            setAddDataIds={setAddDataIds}
                            fetchData={fetchData} />}
                    </div>
                    <DialogFooter className={"!justify-center !gap-2"}>
                        <Button type="submit" size="sm" className="min-w-[100px]" onClick={onSave}>Save</Button>
                        <Button type="submit" className="min-w-[100px]" size="sm" variant={"outline"} onClick={onCancel}>Cancel</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default CreateBYODModel
