import React from 'react'
import DashboardMaster from '../../../layout/dashboard/DashboardMaster'
import NewsContentCard from '../../../components/common/NewsContentCard'

const News = () => {
  return (
    <DashboardMaster>
        <section className='p-2 md:p-5 !pt-0'>
                <div className="news-content-wrapper">
                     <NewsContentCard/>
                </div>
        </section>
    </DashboardMaster>
  )
}

export default News