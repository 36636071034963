import { adminSubClassActionType } from '../../../constants';

const adminSubClassReducer = (state = [], action) => {
    switch (action.type) {
        case adminSubClassActionType.FETCH_SUB_CLASSES:
            return action.payload;
        case adminSubClassActionType.FETCH_SUB_CLASS:
            return action.payload;
        case adminSubClassActionType.ADD_SUB_CLASS:
            return action.payload;
        case adminSubClassActionType.UPDATE_SUB_CLASS:
            return action.payload;
        case adminSubClassActionType.DELETE_SUB_CLASS:
            return action.payload;
        default:
            return state;
    }
};
export default adminSubClassReducer