import { apiBaseURL, assetsDetailsV2ActionType, issuerActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from '../../config/apiConfig';
import { addTost } from "./toastAction";



export const fetchIssuerHistory = (id, setHistory, seIsLoading) => async (dispatch) => {
    // dispatch(setLoading(true))]
    seIsLoading && seIsLoading(true)
    try {
        const response = await apiConfig.get(apiBaseURL.ISSUERS + '/' + id + '/actions')
        dispatch({ type: issuerActionType.FETCH_ISSUER_HISTORY, payload: response.data?.data });
        setHistory && setHistory(response.data?.data?.records)
        seIsLoading && seIsLoading(false)
        dispatch(setLoading(false))
    } catch (error) {
        dispatch(setLoading(false))
        seIsLoading && seIsLoading(false)
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.data?.message }))
    }
}