import React, { useContext, useEffect, useState } from 'react'
import { AssentContext } from '../Asset';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { addTost } from '../../../../store/actions/toastAction';
import { Filters, toastifyType } from '../../../../constants';
import _ from 'lodash'
import DataTableColumnHeader from '../../../../shared/common/table-components/tasks/components/data-table-column-header';
import { Badge, generateBadgeVariant } from '../../../../components/ui/badge';
import { DataTableNew } from '../../../../shared/common/table-components/tasks/components/data-table';
import IconAdd from '../../../../components/svg/IconAdd';
import { Checkbox } from "@/components/ui/checkbox";
import AssetModel from '../models/AssetModel';
import AsseUnAuthorizetModel from '../models/AsseUnAuthorizetModel';
import AssetAuthorizeModel from '../models/AssetAuthorizeModel';
import { TrashIcon } from 'lucide-react'
import Divider from '../../../../components/common/Divider';
import AssetTabs from '../tabs/AssetTabs';
import { IconAuthorise } from '../../../../components/svg/IconAuthorise';
import IconUnauthorise from '../../../../components/svg/IconUnauthorise';
import { ThreeDots } from 'react-loader-spinner';
import { authorize, fetchAssetsDetails, unAuthorizeAssetsDetails } from '../../../../store/actions/AssetAction';
import { fetchAllPortfolios } from '../../../../store/actions/portfolioAction';
import DataTableColumn from '../../../../shared/common/table-components/tasks/components/data-table-column';
import { fetchCountries } from '../../../../store/actions/countriesAction';
import { fetchClass } from '../../../../store/actions/classAction';
import { fetchTypes } from '../../../../store/actions/typeAction';
const AssetTable = () => {
    const { onCheckBtn, fetchAssetData, onShowAssetsRemovalAccordion, showAssetsRemoval, setFetchAssetData, setUpdateId } = useContext(AssentContext);
    const dispatch = useDispatch();
    const { assetsV2, metaData } = useSelector(state => state);
    const [modalShow, setModalShow] = React.useState(false);
    const [assetsData, setAssetsData] = useState([]);
    const [assets, setAssets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [addAssetsModel, setAddAssetsModel] = useState(false);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [checkRowId, setCheckRowId] = useState(null)

    // useEffect(() => {
    //     dispatch(fetchAssetsDetails(null, setAssetsData, currentItems))
    // }, [])


    // console.log('fetchAssetData', fetchAssetData)

    // useEffect(() => {
    //     setIsLoading(true)
    //     dispatch(fetchAssetsDetails(setIsLoading, setAssetsData, currentPage, perPage))
    // }, [currentPage, perPage])

    const columnKey = {
        'Id': true,
        'Name': true,
        'Symbol': true,
        'Class': true,
        'Sub Class': true,
        'Type': true,
        'Sub Type': true,
        'Status': true,
        'Description': true,
        'Country': true,
        'Adj Close': false,
        'Issuer': true,
    }

    const onChange = (filter) => {
        setIsLoading(true)
        setFetchAssetData(null);
        setCurrentPage(filter?.page)
        setPerPage(filter?.perPage)
        dispatch(fetchAssetsDetails(setIsLoading, setAssetsData, filter.page, filter.perPage))
    }

    useEffect(() => {
        if (assetsData.records) {
            setAssets(assetsData.records)
        }
    }, [assetsData])

    useEffect(() => {
        setAssetsData(assetsV2)
    }, [assetsV2])

    useEffect(() => {
        const isAdjClose = JSON.parse(localStorage?.getItem("columnVisibility"));
        if (assetsData.isCurrentPriceInUpdate && isAdjClose['Adj Close']) {
            const refreshTimeInMinutes = assetsData.refreshTime;
            const refreshTimeInSeconds = refreshTimeInMinutes * 1000;
            // Using setTimeout
            const fetchData = setTimeout(() => {
                // Your code to be executed after 5 minutes
                dispatch(fetchAssetsDetails(null, setAssetsData, metaData?.pageNumber, metaData.pageSize));
            }, refreshTimeInSeconds); // Multiply by 1000 to convert seconds to milliseconds

            // return () => clearTimeout(fetchData)
        }
    }, [assetsData])

    useEffect(() => {
        console.log("assets", assets)
        console.log("checkId", checkRowId)

        const filterAsset = assets?.filter((asset) => asset?._id === checkRowId)
        if (filterAsset.length > 0) {
            const filteredData = filterAsset[0];
            const { _id, ...rest } = filteredData
            const prepareData = {
                ...rest,
                id: _id,
                _id
            }
            onCheckBtn(prepareData)
        }

    }, [assets])

    const save = (singlePortfolio) => {
        const data = {
            assetId: fetchAssetData._id,
            portfoliosIds: singlePortfolio.map((item) => item.id)
        }
        if (fetchAssetData && fetchAssetData._id) {
            // setAssets([])
            setAssetsData([])
            setFetchAssetData({})
            setUpdateId(null)
            dispatch(authorize(data, data.assetId, setAssetsData, Filters.OBJ.page, Filters.OBJ.perPage))
            setModalShow(false)
            dispatch(fetchAssetsDetails(null, setAssetsData, Filters.OBJ.page, Filters.OBJ.perPage))
        }

    }

    const onShowAssets = () => {
        setAddAssetsModel(true)
    }

    const onHideAssets = () => {
        setAddAssetsModel(false)
    }

    // const handelDelete = (e, data) => {
    //     // let payloadData = { categories: [] };
    //     // payloadData.categories.push(data.slug);
    //     // dispatch(deletePortfolio(data.slug));
    // };

    const handelDelete = (e, data) => {
        // let payloadData = { categories: [] };
        // payloadData.categories.push(data.slug);
        // dispatch(deletePortfolio(data.slug));
    };

    const handelEdit = (e, data, id) => {
        // setModalShow(true);
        // setFetchData(data);
        // setFetchId(id);
    };
    const [unAuthorizeModalShow, setUnAuthorizeModalShow] = React.useState(false);
    const onUnAuthorize = (singlePortfolio) => {
        const data = {
            assetId: fetchAssetData._id,
            portfoliosIds: singlePortfolio.map((item) => item._id || item.id)
        }
        if (fetchAssetData && fetchAssetData._id) {
            setAssetsData([])
            setFetchAssetData({})
            setUpdateId(null)
            dispatch(unAuthorizeAssetsDetails(data, data.assetId, setAssetsData, Filters.OBJ.page, Filters.OBJ.perPage))
            setUnAuthorizeModalShow(false)
            // dispatch(fetchAssetsDetails(null, setAssetsData))
        }

    }

    const data = assets && assets.length >= 1 ? assets.map((item, index) => {
        return { id: item._id, Name: item.assetName ? item.assetName : '-', adjClose: item?.adjClose ?? 0, ...item }
    }) : []

    // 'removed'
    // 'inactive'
    // 'active'

    const columns = [
        {
            id: 'select',
            header: ({ table }) => (
                // Header content for the 'select' column
                <>
                    {/* Add any header content if needed */}
                </>
            ),
            cell: ({ row }) => (
                // Cell content for the 'select' column
                <Checkbox
                    checked={row.original?._id === fetchAssetData?._id}
                    onCheckedChange={(value) => { row.toggleSelected(!!value); handleRowSelected(row?.original) }}
                    aria-label="Select row"
                    className="translate-y-[2px] border-black rounded-[4px]"
                />
            ),
            enableSorting: false,
            enableHiding: false,
        },
        {
            accessorKey: "Id",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Id" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.assetId}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Name",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Name" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.Name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Symbol",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Symbol" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.symbol ? row?.original?.symbol : '-'}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Class",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Class" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.class?.name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Sub Class",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Sub Class" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.subClass?.name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Type",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Type" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.type?.name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Sub Type",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Sub Type" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.subType?.name}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Status",
            header: "Status",
            cell: ({ row }) => {
                const variant = generateBadgeVariant(row?.original.status)
                return <Badge variant={variant} className={""}>{row?.original.status}</Badge>
            },
        },
        {
            accessorKey: "Description",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Description" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.description}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Country",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Country" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.country.countryName}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Adj Close",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Adj Close" />
            ),
            cell: ({ row }) => {
                return <span className='flex items-center'>{row?.original?.adjClose === 'Fetching' ? <ThreeDots
                    visible={true}
                    height="25"
                    width="25"
                    color="#001F3D"
                    radius="9"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                /> : row.original?.adjClose !== 0 ? row.original?.adjClose?.toFixed(2) : 0}</span>
            },
            enableSorting: true,
        },
        {
            accessorKey: "Issuer",
            header: ({ column }) => (
                <DataTableColumnHeader column={column} title="Issuer" />
            ),
            cell: ({ row }) => {
                return <DataTableColumn title={row?.original?.issuer?.name ? row?.original?.issuer?.name : '-'} name={row?.original?.issuer?.name ? row?.original?.issuer?.name : '-'} />
            },
            enableSorting: true,
        },

    ]

    const handleRowSelected = async (rows) => {
        // Handle row selection
        // await onCheckBtn(rows[0])
        setCheckRowId(rows?.id)
        await rows && onCheckBtn(rows)
    };

    //     <Button size="sm" onClick={() => setAddAssetsModel(true)}>
    //     <span><IconAdd className="w-4 h-4" /></span>  Add Assets
    // </Button>
    // <Button size="sm" variant="outline" onClick={() => setModalShow(true)}>
    //     <span><IconAuthorise className="w-4 h-4" /></span>  Authorise Asset
    // </Button>
    // <Button size="sm" variant="outline" className="!border-red !text-red" onClick={() => setUnAuthorizeModalShow(true)}>
    //     <span><IconUnauthorise className="w-4 h-4" /></span>  Unauthorise Asset
    // </Button>
    const headerButtons = [
        {
            icon: <IconAdd className="w-4 h-4 " />,
            text: 'Add Asset',
            onClick: () => { onShowAssets(setAssetsData); setFetchAssetData({}) },
            variant: '',
            additionalClass: ""
        },
        {
            icon: <IconAuthorise className='w-4 h-4' />,
            text: 'Authorise Asset',
            onClick: () => {
                if (_.isEmpty(fetchAssetData)) {
                    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
                } else {
                    if (fetchAssetData.status === 'removed') {
                        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Selected Asset Removed Please Select Another Asset' }))
                    } else {
                        setModalShow(true)
                        // dispatch(fetchAllPortfolios())
                    }
                }
            },
            variant: "outline",
            additionalClass: "hover:bg-[#F5F5F5]"
        },
        {
            icon: <IconUnauthorise className='w-4 h-4' />,
            text: 'UnAuthorise Asset',
            onClick: () => {
                if (_.isEmpty(fetchAssetData)) {
                    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
                } else {
                    if (fetchAssetData.status === 'removed') {
                        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Selected Asset unAuthorised Please Select Another Asset' }))
                    } else {
                        setUnAuthorizeModalShow(true)
                        // dispatch(fetchAllPortfolios())
                    }
                }
            },
            variant: "outline",
            additionalClass: "!border-red !text-red"
        },
        {
            icon: <TrashIcon className="w-4 h-4 text-red transition duration 200" />,
            text: 'Remove Selected',
            onClick: () => { onShowAssetsRemovalAccordion(); setKeyValue('Asset Removal Details') },
            variant: "outline",
            additionalClass: "rounded-[6px] h-8 lg:flex group !border-red !text-red"
        },
        // Add more button configurations as needed
    ];

    const [keyValue, setKeyValue] = useState('Asset Details')
    const handleTabChange = (value) => {
        if (_.isEmpty(fetchAssetData)) {
            dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please Select Asset!' }))
        } else {
            setKeyValue(value)
        }
    }


    const rightHeaderButtons = [
        {
            icon: <IconAdd className="w-4 h-4 " />,
            text: 'Add Asset',
            onClick: () => {
                onShowAssets(setAssetsData); setFetchAssetData({}); dispatch(fetchCountries());
                dispatch(fetchClass('all'));
                dispatch(fetchTypes('all'))
            },
            variant: '',
            additionalClass: ""
        },
        {
            icon: <IconAuthorise className='w-4 h-4' />,
            text: 'Authorise Asset',
            onClick: () => {
                if (_.isEmpty(fetchAssetData)) {
                    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
                } else {
                    if (fetchAssetData.status === 'removed') {
                        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Selected Asset Removed Please Select Another Asset' }))
                    } else {
                        setModalShow(true)
                        // dispatch(fetchAllPortfolios())
                    }
                }
            },
            variant: "outline",
            additionalClass: "hover:bg-[#F5F5F5]"
        },
        {
            icon: <IconUnauthorise className='w-4 h-4' />,
            text: 'Unauthorise Asset',
            onClick: () => {
                if (_.isEmpty(fetchAssetData)) {
                    dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Please select any one Asset' }))
                } else {
                    if (fetchAssetData.status === 'removed') {
                        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: 'Selected Asset unAuthorised Please Select Another Asset' }))
                    } else {
                        setUnAuthorizeModalShow(true)
                        // dispatch(fetchAllPortfolios())
                    }
                }
            },
            variant: "outline",
            additionalClass: "!border-red !text-red hover:bg-[#F5F5F5]"
        },
    ]


    const leftHeaderButtons = [

        // {
        //     icon: <TrashIcon className="w-4 h-4 text-red transition duration 200" />,
        //     text: 'Remove Selected',
        //     onClick: () => {onShowAssetsRemovalAccordion(); setKeyValue('Asset Removal Details')},
        //     variant: "outline",
        //     additionalClass: "rounded-[6px] h-8 lg:flex group !border-red !text-red"
        // },

    ]


    return (
        <div className="">
            <div className={`p-3 md:p-5 ${fetchAssetData?._id ? '' : ''} `}>
                <DataTableNew data={data} columns={columns} loadData={isLoading} onChange={onChange} filterName={'Name'}
                    isRightNew={true}
                    isOldHeaderHide={true}
                    columnKey={columnKey}
                    isRightSearch={true}
                    isRightHeaderButtons={true}
                    rightHeaderButtons={rightHeaderButtons}
                    isLeftHeaderButtons={true}
                    leftHeaderButtons={leftHeaderButtons}

                    isRight={true} />
                <AssetModel
                    show={addAssetsModel}
                    onHide={() => onHideAssets(false)}
                    setAssetsData={setAssetsData}
                />
                <AsseUnAuthorizetModel
                    show={unAuthorizeModalShow}
                    onHide={() => setUnAuthorizeModalShow(false)}
                    onUnAuthorize={onUnAuthorize}
                />
                <AssetAuthorizeModel
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    save={save}
                />
            </div>
            <div className="sub-tabs-content-wrapper">
                {fetchAssetData?._id ?
                    <div className="">
                        <Divider className={"border-borderV1"} />
                        <div className='p-3 md:p-5'>
                            <AssetTabs keyValue={keyValue} handleTabChange={handleTabChange} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        </div>
                    </div>
                    : ''}
            </div>
        </div>
    )
}

export default AssetTable
