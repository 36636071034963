import { viewsActionType } from "../../constants";


const ColumnsViewsReducer = (state = [], action) => {
    switch (action.type) {
        case viewsActionType.FETCH_VIEWS:
            return action.payload;
        case viewsActionType.ADD_VIEW:
            return action.payload;
        case viewsActionType.UPDATE_VIEW:
            return action.payload;
        case viewsActionType.DELETE_VIEW:
            return action.payload;
        default:
            return state;
    }
};
export default ColumnsViewsReducer