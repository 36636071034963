import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { fetchPortfolioColumn } from "../../../../store/actions/portfolioscolumnAction";
import { fetchInvestedAsset } from "../../../../store/actions/portfolioTransactionAction";
import _ from "lodash";
import { fetchAnalyticsData } from "../../../../store/actions/analyticsAction";
import { dateConvert } from "../../../../shared/common/dateFormate";
import { Button } from "@/components/ui/button";
import { DataTableNew } from "../../../../shared/common/table-components/tasks/components/data-table";
import {
  DotsHorizontalIcon,
  Pencil2Icon,
  PlusIcon,
} from "@radix-ui/react-icons";
import DataTableColumnHeader from "../../../../shared/common/table-components/tasks/components/data-table-column-header";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown.jsx";
import { Checkbox } from "@/components/ui/checkbox";
import PortfolioSubTabs from "../portfolio-sub-tebs/PortfolioSubTabs";
import InvestAssetActionModel from "../../../../shared/common/portfolio/models/InvestAssetActionModel";
import AssetUpdateModel from "../../../../shared/common/portfolio/models/AssetUpdateModel";
import { ThreeDots } from "react-loader-spinner";
const PortfolioContent = (props) => {
  const {
    fetchAssetData,
    allAnalytics,
    updateId,
    onShowAssets,
    onCheckBtn,
    onShowAssetsRemovalAccordion,
    isLoading,
    setIsLoading,
    addAssetsAccordion,
    isGroup,
    portfolioGroupId,
    setPortfolioGroupId,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    portfolios,
    PortfolioGroup,
    portfoliosColumn,
    investedAsset,
    updateState,
  } = useSelector((state) => state);
  const [assets, setAssets] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [portfolioGroupId, setPortfolioGroupId] = useState([]);
  const resultArray = location.pathname.split("/").filter(Boolean);
  const navigate = useNavigate();
  const [group, setGroup] = useState(null);
  const [portfolioName, setPortfolioName] = useState(null);

  const [codeName, setCodeName] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [perPage, setPerPage] = useState(10); // Default per page value

  useEffect(() => {
    dispatch(fetchPortfolioColumn("investedAsset"));
  }, [dispatch]);

  useEffect(() => {
    isGroup !== false && onChange({ page: currentPage, perPage: perPage });
  }, [portfolioGroupId.length, updateState]);

  const onChange = (filter) => {
    setIsLoading(true);
    portfolioGroupId.length &&
      dispatch(
        fetchInvestedAsset(
          setIsLoading,
          portfolioGroupId,
          filter.page,
          filter.perPage,
          "investedAsset"
        )
      );
  };

  useEffect(() => {
    setAssets(investedAsset);
    setTotalRecords(investedAsset.totalRecords);
  }, [investedAsset]);

  useEffect(() => {
    if (portfoliosColumn?.data) {
      setCodeName(
        portfoliosColumn?.data.map((item) => {
          return { code: item.code, name: item.name };
        })
      );
    }
  }, [portfoliosColumn?.data]);

  useEffect(() => {
    if (PortfolioGroup) {
      if (resultArray.length === 3) {
        const findGroup = PortfolioGroup.find(
          (item) => item?.slug === resultArray[1]
        );
        const findPortfolio = portfolios.find(
          (item) => item?.portfolio?.slug === resultArray[2]
        );
        setGroup(findGroup);
        setPortfolioName(findPortfolio);
      } else {
        const findPortfolio = portfolios.find(
          (item) => item?.portfolio?.slug === resultArray[1]
        );
        setGroup(null);
        setPortfolioName(findPortfolio);
      }
    }
  }, [resultArray]);

  useEffect(() => {
    if (portfolioName?.portfolio?._id) {
      setPortfolioGroupId([portfolioName?.portfolio?._id]);
    }
  }, [portfolioName]);

  // useEffect(() => {
  //     setIsLoading(true)
  //     portfolioGroupId.length && updateState && dispatch(fetchInvestedAsset(setIsLoading, portfolioGroupId, currentPage, perPage, 'investedAsset'))
  // }, [portfolioGroupId, currentPage, updateState])

  const onClickNavigate = (e, group) => {
    e.preventDefault();
    if (group) {
      navigate("/portfolio/" + group.slug);
    }
  };

  const [isShowModel, setIsShowModel] = useState(false);
  const [isAssetShowModel, setIsAssetShowModel] = useState(false);
  const [fetchInvestData, setFetchInvestData] = useState(null);
  const [fetchAssetDatas, setFetchAssetDatas] = useState(null);
  const showModal = (items) => {
    setIsShowModel(true);
    setFetchInvestData(items);
  };

  const showAssetModal = (items) => {
    setIsAssetShowModel(true);
    setFetchAssetDatas(items);
    setFetchInvestData(items);
  };

  // useEffect(() => {
  //     if (!isShowModel || !isAssetShowModel) {
  //         setFetchInvestData(null)
  //         // setFetchAssetDatas(null)
  //     }
  // }, [isShowModel, isAssetShowModel])

  const [clickTransactionsTable, setClickTransactionsTable] = useState(false);
  const [clickAnalyticTable, setClickAnalyticTable] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  useEffect(() => {
    if (clickAnalyticTable && !_.isEmpty(fetchAssetData)) {
      setIsLoadingData(true);
      dispatch(fetchAnalyticsData(fetchAssetData?._id, setIsLoadingData));
    }
  }, [fetchAssetData, clickAnalyticTable]);

  const data =
    codeName?.length && assets?.records?.length
      ? assets?.records?.map((item) => {
          const {
            averageprice,
            totalQuantity,
            purchaseDate,
            date,
            ...newItem
          } = item;
          const totalValue = averageprice * totalQuantity;
          return {
            ...item,
            data: item,
            id: item?.assetId ? item?.assetId : "N/A",
            name: item?.assetName ? item?.assetName : "-",
            class: item?.class ? item?.class : "",
            subClass: item?.subClass ? item?.subClass : "",
            Strategy: item?.strategy ? item?.strategy : "N/A",
            description: item?.description ? item?.description : "",
            countryName: item?.country?.countryName
              ? item?.country?.countryName
              : "",
            adjClose: item?.adjClose ? item?.adjClose : 0,
            // price:
            purchasePrice: item?.averageprice ? item?.averageprice : "",
            price: item?.averageprice ? item?.averageprice : "",
            purchaseDate: date ? dateConvert(date) : "",
            date: date ? dateConvert(date) : "",
            // quantity: item?.totalQuantity ? item?.totalQuantity : '',
            Value: totalValue ? totalValue : "",
          };
        })
      : [];

  // Function to map myCodeName array to column objects
  const mapCodeNameToColumns = () => {
    // Define the 'select' column
    const selectColumn = {
      id: "select",
      header: ({ table }) => "",
      cell: ({ row }) => (
        // Cell content for the 'select' column
        <Checkbox
          checked={row.original?.data?._id === fetchAssetData?._id}
          onCheckedChange={(value) => {
            row.toggleSelected(!!value);
            handleRowSelected(row?.original);
          }}
          aria-label="Select row"
          className="translate-y-[2px] border-black rounded-[4px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    };

    // Map the codeName array to columns
    const columns = codeName.map((code) => {
      if (code.code === "action") {
        // Define the 'actions' column
        return {
          accessorKey: "actions",
          enableHiding: false,
          header: <span className="flex items-center justify-end">Action</span>,
          cell: ({ row }) => {
            return (
              <div
                className="flex items-center justify-end"
                key={row.original.id || row.original._id}
              >
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="p-0">
                      <span className="sr-only">Open menu</span>
                      <DotsHorizontalIcon
                        strokeWidth={"2.2"}
                        className="h-5 w-5 text-textV2"
                      />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    {/* <DropdownMenuItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" onClick={() => onAdd(row?.original)}><PlusIcon strokeWidth={"2.2"} className='text-themeV2 w-4 h-4' /> Add Quantity</DropdownMenuItem> */}
                    <DropdownMenuItem
                      className={`text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2`}
                      onClick={() => onEdit(row?.original)}
                    >
                      <Pencil2Icon
                        strokeWidth={"2.2"}
                        className="text-red w-4 h-4"
                      />{" "}
                      Edit Strategy
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            );
          },
        };
      } else {
        // Define a regular data column
        if (code.code === "adjClose") {
          return {
            accessorKey: code.name, // Ensure that the column ID matches 'code.name'
            header: ({ column }) => (
              // Header content for the regular data column
              <DataTableColumnHeader column={column} title={code.name} />
            ),
            cell: ({ row }) => (
              // Cell content for the regular data column
              <div>
                {row?.original?.adjClose === "Fetching" ? (
                  <>
                    <span className="text-themeV2 text-2xl font-600">
                      <ThreeDots
                        visible={true}
                        height="35"
                        width="35"
                        color="#001F3D"
                        radius="9"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </span>
                  </>
                ) : (
                  <span className="">{row?.original?.adjClose}</span>
                )}
              </div>
            ),
            enableSorting: true,
          };
        } else {
          return {
            accessorKey: code.name, // Ensure that the column ID matches 'code.name'
            header: ({ column }) => (
              // Header content for the regular data column
              <DataTableColumnHeader column={column} title={code.name} />
            ),
            cell: ({ row }) => (
              // Cell content for the regular data column
              <span className="flex items-center">
                {typeof row?.original[code.code] === "number"
                  ? parseFloat(row.original[code.code])?.toFixed(2)
                  : row?.original[code.code] === 0
                  ? 0
                  : row?.original[code.code]
                  ? row.original[code.code]
                  : "-"}
              </span>
            ),
            enableSorting: true,
          };
        }
      }
    });

    // Return an array containing the 'select' column and other mapped columns
    return [selectColumn, ...columns];
  };
  const columns =
    codeName?.length && data?.length ? mapCodeNameToColumns() : [];

  const onAdd = (row) => {
    showModal(row.data);
  };
  const onEdit = (row) => {
    showAssetModal(row.data);
  };

  const handleRowSelected = async (rows) => {
    // Handle row selection
    (await rows?.data) && onCheckBtn(rows?.data);
  };
  const rightHeaderButtons = [
    {
      icon: "",
      text: "Asset Actions",
      onClick: () => onShowAssets(true, portfolioName?.portfolio?._id),
      variant: "",
      additionalClass: "",
    },
  ];
  return (
    <>
      <DataTableNew
        data={data}
        columns={columns}
        onChange={onChange}
        filterName={"Name"}
        loadData={isLoading}
        isOldHeaderHide={true}
        rightHeaderButtons={rightHeaderButtons}
        isRightSearch={true}
        isRightHeaderButtons={true}
        isRightNew={true}
        // isInvestRemove
        // onIsInvestRemove={onShowAssetsRemovalAccordion}
        // isInvestRemoveName={'Remove Selected'}
        hasSettingBtn={true}
        sectionType={"investedAsset"}
        isAsset={true}
      />
      <InvestAssetActionModel
        // portfolio
        show={isShowModel}
        onHide={() => setIsShowModel(false)}
        fetchInvestData={fetchInvestData}
        PortfolioName={portfolioName}
        isInvestQuantity={true}
        fetchData={null}
        groupSelectedId={null}
        isGroup={false}
      />

      <AssetUpdateModel
        show={isAssetShowModel}
        onHide={() => setIsAssetShowModel(false)}
        fetchAssetDatas={fetchAssetDatas}
        PortfolioName={portfolioName}
        isInvestQuantity={true}
      />
    </>
  );
};

export default PortfolioContent;
