
import { apiBaseURL, newsActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";


export const fetchNews = (slug) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await apiConfig.get(apiBaseURL.NEWS + '?portfolio=' + slug);
      dispatch({ type: newsActionType.FETCH_NEWS, payload: response.data?.data });
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
      console.error('error', error);
    }
  };