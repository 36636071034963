import React from 'react'
import CanvasContainer from './editor/CanvasContainer.jsx'

const PreviewEditor = () => {
  return (<CanvasContainer />
  )
}

export default PreviewEditor

