
import { Filters, apiBaseURL, subTypeActionType, toastifyType } from "../../constants";
import { setLoading } from "./loadingAction";
import apiConfig from './../../config/apiConfig';
import { addTost } from "./toastAction";
import { metaDataAction } from "./metaDataAction";


export const fetchSubTypes = (filter) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.get(apiBaseURL.SUB_TYPES + `?page=${filter.page}&perPage=${filter.perPage}`);
        dispatch({ type: subTypeActionType.FETCH_SUBTYPES, payload: response.data?.data });

        dispatch(metaDataAction({ pageNumber: response.data?.data?.currentPage || 1, pageSize: 10, totalItems: response.data?.data?.totalRecords }))
        dispatch(setLoading(false));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const addSubTypes = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.post(apiBaseURL.SUB_TYPES, data);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchSubTypes(Filters.OBJ));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const deleteSubTypes = (id) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.delete(apiBaseURL.SUB_TYPES + '/' + id);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchSubTypes(Filters.OBJ));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const fetchSubType = (id, include) => async (dispatch) => {
    try {
        // dispatch(setLoading(true));
        let subTypeUrl = apiBaseURL.TYPES + "/" + id + "/" + "sub-types"
        if (include) {
            subTypeUrl += `?type=${include}`
        }
        const response = await apiConfig.get(subTypeUrl);
        dispatch({ type: subTypeActionType.FETCH_SUBTYPE, payload: response.data?.data });
        // dispatch(fetchSubCategories())
        // dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }))
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};

export const updateSubTypes = (data) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const response = await apiConfig.put(apiBaseURL.SUB_TYPES, data);
        dispatch(addTost({ type: toastifyType.SHOW_SUCCESS_MESSAGE, message: response?.data?.message }));
        dispatch(fetchSubTypes(Filters.OBJ));
    } catch (error) {
        dispatch(setLoading(false));
        dispatch(addTost({ type: toastifyType.SHOW_ERROR_MESSAGE, message: error?.response?.data?.message }));
        console.error('error', error);
    }
};