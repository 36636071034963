import React from 'react'
import { CheckboxNew } from '../ui/CheckboxNew'
import { Input } from '../ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select'
import ReactSelect, {
    components,
} from 'react-select'
import { Checkbox } from '../ui/checkbox'

const AllocationSubTableClonedUiRow = (props) => {
    const { item, index, selectedRows, handleToggleRow, error, onChangeAssetOption, data, handleUpdateRow, totalTaa, totalTAA, totalTa, subClassAssetAllocation, subSomStrategyError, onChangeStrategySubOption, strategyOptions, subAssetAllocation, handleUpdateSubRows, allocatedAssetsData, totalAssetAllocation, totalAssetSubAllocation, onCheckedSubPortfoliosBox, SingleOptionPortfoliosSub } = props
//    multi portfolio 
    const OptionPortfolios = ({ children, ...props }) => {
        const { onCheckedSubPortfoliosBox, selectedOp, indexNumber, subRowIndex, options } = props.selectProps;
        return (
            <components.Control {...props}>
                <div className="flex p-2 align-middle justify-start items-center text-sm w-full" onClick={() => onCheckedSubPortfoliosBox(props, selectedOp, options, indexNumber, subRowIndex)}>
                    <span className="me-2"><Checkbox
                        checked={selectedOp?.length ? selectedOp?.includes(props?.data?._id) : false}
                        aria-label="Select row"
                        // 
                        className="translate-y-[2px] border-black rounded-[4px]" /></span>
                    {/* {children} */}
                    {props?.data?.name}
                </div>
            </components.Control>
        );
    }

    return (
        <div className="">
            {item.allocatedSubAssets.length && item.allocatedSubAssets.map((items, subRowIndex) => (
                <div className={`${selectedRows.filter((item) => item.sub === subRowIndex && item.parents === index).length ? "bg-backgroundV1" : ""} flex items-center gap-2 overflow-hidden mb-2 last:mb-0`}>

                    <div className="w-[4%]  flex items-center justify-center">
                        <CheckboxNew checked={selectedRows.filter((item) => item.sub === subRowIndex && item.parents === index).length} onCheckedChange={() => handleToggleRow(index, subRowIndex)} className="w-5 h-5 rounded-[6px] bg-white" />
                    </div>

                    {/* portfolio portions  */}
                    <div className={`w-[10%] `}>
                        <>
                        <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" readOnly />
                            {/* <ReactSelect
                                components={{ Option: OptionPortfolios }}
                                isSearchable={false}
                                selectedOp={item?.portfolioOpSelected}
                                indexNumber={index}
                                subRowIndex={subRowIndex}
                                inputValue=""
                                value={null}
                                closeMenuOnSelect={false}
                                // hideSelectedOptions={true}
                                onCheckedSubPortfoliosBox={onCheckedSubPortfoliosBox}
                                placeholder='Select Portfolio'
                                className='Portfolio_multi_select'
                                name="Portfolio"
                                options={item?.portfolioOptions}
                                styles={{
                                    menuList: (base) => ({
                                        ...base,
                                        fontSize: "10px",
                                        overflow: "auto",
                                        maxHeight: "180px",
                                        lineHeight: '1rem',
                                        fontWeight: 500,
                                        borderRadius: '0',
                                        backgroundColor: 'transparent'

                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        overflow: "auto",
                                        margin: 0,
                                        padding: 0,
                                        width: '200px',
                                        borderRadius: '0',

                                    }),
                                    input: (base) => ({
                                        ...base,
                                        width: '40%',
                                    }),
                                    container: (base) => ({
                                        ...base,
                                        width: '150px',
                                    }),
                                }}
                            /> */}
                           
                            {/* <Select
                                onValueChange={(e) => SingleOptionPortfoliosSub(JSON.parse(e), { action: 'select-option' }, index, subRowIndex)}
                                defaultValue={items?.portfolioOpSelected !== null ? items.portfolioOpSelected : null}
                            >
                                <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white text-start" >
                                    <SelectValue className='truncate text-start' >
                                        {items?.portfolioOpSelected !== null ? items?.portfolioOpSelected?.label : 'Select Portfolio....'}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent side="bottom" className="min-w-[100px]">
                                    {items?.portfolioOptions?.map((info) => (
                                        <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={info?._id} value={JSON.stringify(info)}>
                                            {info?.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select> */}
                            {/* <div className={error['allAsset'] || item.errorMessage !== null ? "text-red text-[10px]" : ''}>{error['allAsset'] || item.errorMessage !== null ? error['allAsset'] || item.errorMessage : null}</div> */}
                        </>
                    </div>

                    <div className="w-[10%]">
                        <Input readOnly name='class' className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" placeholder='class' value={items?.class ? items?.class : ''} onChange={(e) => {
                            //  const updatedAssetsData = [...allocatedAssetsData];
                            //  if (updatedAssetsData[index].subRows[subRowIndex]) {
                            //     updatedAssetsData[index].subRows[subRowIndex].asset.data.class.name = e.target.value;
                            //  }
                            handleUpdateSubRows(index, subRowIndex, 'class', items?.class);
                            // handleUpdateSubRows(index, subRowIndex, 'asset', {
                            //     value: item.asset.value,
                            //     label: item.asset.label,
                            //     data:  updatedAssetsData[index].subRows[subRowIndex].asset.data,
                            // });
                        }} />
                    </div>
                    <div className="w-[10%]">
                        <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" name='subClass' placeholder='subClass' value={items?.subClass ? items?.subClass : ''} onChange={(e) => {
                            // const updatedAssetData = { ...item.asset.data };
                            // updatedAssetData.subClass.name = e.target.value;
                            handleUpdateSubRows(index, subRowIndex, 'subClass', e.target.value);
                            // handleUpdateSubRows(index, subRowIndex, 'asset', {
                            //     value: item.asset.value,
                            //     label: item.asset.label,
                            //     data: updatedAssetData,
                            // });
                        }} />
                        {subClassAssetAllocation.length && subClassAssetAllocation.filter(item => (item.parent === index && item.sub === subRowIndex)).length ? <div className="text-red text-[10px]">Sub Class Name Is Empty</div> : ''}
                    </div>
                    <div className="w-[10%]">
                        <>
                            {/* <Select
                                defaultValue={items.asset !== null ? items.asset : null}
                                onValueChange={(value) => onChangeAssetOption(JSON.parse(value), { action: 'select-option' }, index, subRowIndex)}
                            >
                                <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
                                    <SelectValue className='truncate' >
                                        {items.asset !== null ? items.asset?.label : 'Select....'}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent side="bottom" className="min-w-[100px]">
                                    {data.allAsset.map((items) => (
                                        <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items.value} value={JSON.stringify(items)}>
                                            {items?.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select> */}
                           {items?.getAssetByPortfolio?.length ? <Select
                                defaultValue={items.asset !== null ? items.asset : null}
                                onValueChange={(value) => onChangeAssetOption(JSON.parse(value), { action: 'select-option' }, index, subRowIndex)}
                            >
                                <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
                                    <SelectValue className='truncate' >
                                        {items.asset !== null ? items.asset?.label : 'Select....'}
                                    </SelectValue>
                                </SelectTrigger>
                                <SelectContent side="bottom" className="min-w-[100px]">
                                    {items.getAssetByPortfolio.map((items) => (
                                        <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items.value} value={JSON.stringify(items)}>
                                            {items?.label}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select> : <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" readOnly placeholder={items.getAssetByPortfolio?.length ? ' ' : 'Not Option'} />}
                        </>
                        {subAssetAllocation.length && subAssetAllocation.filter(item => (item.parent === index && item.sub === subRowIndex)).length ? <div className="text-red text-[10px]">Please Select Asset</div> : ''}
                    </div>
                    <div className="w-[10%]">
                        <Select
                            defaultValue={items.strategy}
                            onValueChange={(value) => onChangeStrategySubOption(JSON.parse(value), { action: 'select-option' }, index, subRowIndex)}
                        >
                            <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
                                <SelectValue className='truncate' >
                                    {items.strategy.label}
                                </SelectValue>
                            </SelectTrigger>
                            <SelectContent side="bottom" className="">
                                {strategyOptions.map((items) => (
                                    <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={items.value} value={JSON.stringify(items)}>
                                        {items.label}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                        {subSomStrategyError.length && subSomStrategyError.filter(item => (item.parent === index && item.sub === subRowIndex)).length ? <div className="text-red text-[10px]">The selected asset and strategy already exist.</div> : ''}
                    </div>
                    <div className="w-[12%]">
                        <Input readOnly className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate"
                            name='allocationType' placeholder='allocationType' value={'Fixed Weight'} onChange={(e) => {
                                // const updatedAssetData = { ...item.asset.data };
                                // updatedAssetData.subCategory.subCategoryName = e.target.value;
                                handleUpdateSubRows(index, subRowIndex, 'allocationType', e.target.value);
                                // handleUpdateRow(index, 'asset', {
                                //     value: item.asset.value,
                                //     label: item.asset.label,
                                //     data: updatedAssetData,
                                // });
                            }}
                        />
                    </div>
                    {/*Allocation / SAA % DONE*/}
                    <div className="w-[6.50%]">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="SAA"
                            placeholder="SAA"
                            aria-label="SAA"
                            value={items.SAA}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                let newValue = parseInt(e.target.value, 10);
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    newValue = Math.min(100, Math.max(0, newValue));
                                }
                                handleUpdateSubRows(index, subRowIndex, 'SAA', Number(newValue));
                                handleUpdateSubRows(index, subRowIndex, 'TA', Number(newValue) + Number(items.TAA));
                                handleUpdateSubRows(index, subRowIndex, 'actualAllocation', Number(newValue));
                            }}
                        />
                        {error['SAA'] ? (<div className={error['SAA'] ? "text-red text-[10px]" : ''}>{error['SAA'] ? error['SAA'] : null}</div>) : totalAssetAllocation.includes(index) ? (
                            <div className="text-red text-[10px]">{'Total SAA must be 100%'}</div>
                        ) : null}
                    </div>
                    {/* lowerTolerance / SAA LT DONE% */}
                    <div className="w-[7%]">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="SAALT"
                            placeholder="SAALT"
                            aria-label="SAALT"
                            value={items.SAALT}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                let newValue = parseInt(e.target.value, 10);
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    newValue = Math.min(100, Math.max(0, newValue));
                                }
                                // handleUpdateRow(index, 'SAALT', newValue);
                                handleUpdateSubRows(index, subRowIndex, 'SAALT', Number(newValue));
                                // handleUpdateRow(index, 'errorLoverMessage', null);
                                // handleUpdateRow(index, 'validateLoverTolerance', []);
                            }}
                        />
                        <div className={items.validateLoverTolerance.includes(subRowIndex) ? "text-red text-[10px]" : ''}>{items.validateLoverTolerance.includes(subRowIndex) ? items.errorLoverMessage : null}</div>
                    </div>
                    {/* upperTolerance / SAA UT%  DONE*/}
                    <div className="w-[7%]">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="SAAUT"
                            placeholder="SAAUT"
                            aria-label="SAAUT"
                            value={items.SAAUT}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                let newValue = parseInt(e.target.value, 10);
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    newValue = Math.min(100, Math.max(0, newValue));
                                }
                                // handleUpdateRow(index, 'SAAUT', newValue);
                                handleUpdateSubRows(index, subRowIndex, 'SAAUT', Number(newValue));
                                // handleUpdateRow(index, 'errorUpperMessage', null);
                                // handleUpdateRow(index, 'validateUpperTolerance', []);
                            }}
                        />
                        <div className={items.validateUpperTolerance.includes(subRowIndex) ? "text-red text-[10px]" : ''}>{items.validateUpperTolerance.includes(subRowIndex) ? items.errorUpperMessage : null}</div>
                    </div>
                    {/* TAA % */}
                    <div className="w-[7%]">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="TAA"
                            placeholder="TAA"
                            aria-label="TAA"
                            value={items.TAA}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                // let newValue = parseInt(e.target.value, 10);
                                let newValue = e.target.value;
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    // newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    // newValue = Math.min(100, Math.max(0, newValue));
                                }
                                handleUpdateSubRows(index, subRowIndex, 'TAA', newValue);
                                handleUpdateSubRows(index, subRowIndex, 'TA', Number(items.SAA) + Number(newValue));
                                // handleUpdateSubRows(index, subRowIndex, 'actualAllocation', newValue);
                            }}
                        />
                        {error['TAA'] ? (<div className={error['TAA'] ? "text-red text-[10px]" : ''}>{error['TAA'] ? error['TAA'] : null}</div>) : totalTaa.includes(index) ? (
                            <div className="text-red text-[10px]">{'Total TAA must be 0%'}</div>
                        ) : null}
                    </div>
                    {/* TA % */}
                    <div className="w-[6.50%]">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="TA"
                            placeholder="TA"
                            aria-label="TA"
                            value={items.TA}
                            readOnly={true}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                let newValue = parseInt(e.target.value, 10);
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    newValue = Math.min(100, Math.max(0, newValue));
                                }
                                handleUpdateSubRows(index, subRowIndex, 'TA', Number(newValue));
                                handleUpdateSubRows(index, subRowIndex, 'actualAllocation', Number(newValue));
                            }}
                        />
                        {error['TA'] ? (<div className={error['TA'] ? "text-red text-[10px]" : ''}>{error['TA'] ? error['TA'] : null}</div>) : totalTa.includes(index) ? (
                            <div className="text-red text-[10px]">{'Total TA must be 100%'}</div>
                        ) : null}
                    </div>
                    {/* TA LT%  */}
                    <div className="w-[6.50%]">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="TALT"
                            // readOnly={item.isLock}
                            placeholder="TALT"
                            aria-label="TALT"
                            value={items.TALT}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                let newValue = parseInt(e.target.value, 10);
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    newValue = Math.min(100, Math.max(0, newValue));
                                }
                                // handleUpdateRow(index, 'lowerTolerance', newValue);
                                handleUpdateSubRows(index, subRowIndex, 'TALT', Number(newValue));
                                // handleUpdateRow(index, 'errorLoverMessage', null);
                                // handleUpdateRow(index, 'validateLoverTolerance', []);
                            }}
                        />
                        <div className={items.validateTALoverTolerance.includes(subRowIndex) ? "text-red text-[10px]" : ''}>{items.validateTALoverTolerance.includes(subRowIndex) ? items.errorTALoverMessage : null}</div>
                    </div>
                    {/* TA UT%  */}
                    <div className="w-[6.50%]">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="TAUT"
                            // readOnly={item.isLock}
                            placeholder="TAUT"
                            aria-label="TAUT"
                            value={items.TAUT}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                let newValue = parseInt(e.target.value, 10);
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    newValue = Math.min(100, Math.max(0, newValue));
                                }
                                // handleUpdateRow(index, 'TAUT', newValue);
                                handleUpdateSubRows(index, subRowIndex, 'TAUT', Number(newValue));
                                // handleUpdateRow(index, 'errorLoverMessage', null);
                                // handleUpdateRow(index, 'validateLoverTolerance', []);
                            }}
                        />
                        <div className={items.validateTAUpperTolerance.includes(subRowIndex) ? "text-red text-[10px]" : ''}>{items.validateTAUpperTolerance.includes(subRowIndex) ? items.errorTAUpperMessage : null}</div>
                    </div>
                    {/* actualAllocation */}
                    <div className="w-[6.50%] px-1.5 flex items-center justify-end">
                        <Input type="number" className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center"
                            name="actualAllocation"
                            readOnly
                            placeholder="actualAllocation"
                            aria-label="actualAllocation"
                            value={items.actualAllocation}
                            onChange={(e) => {
                                // Validate the input value to be within the range of 0 to 100
                                let newValue = parseInt(e.target.value, 10);
                                if (isNaN(newValue)) {
                                    // If the value is not a number, set it to 0
                                    newValue = 0;
                                } else {
                                    // Ensure the value is within the range of 0 to 100
                                    newValue = Math.min(100, Math.max(0, newValue));
                                }
                                handleUpdateSubRows(index, subRowIndex, 'actualAllocation', newValue);
                            }}
                        />
                    </div>
                </div>
            ))}

        </div>
    )
}

export default AllocationSubTableClonedUiRow


{/* <div className={`${false ? "bg-backgroundV1" : ""} flex items-center gap-2 overflow-hidden`}>
<div className="w-[4%]  flex items-center justify-center">
    <CheckboxNew checked={true} onCheckedChange={null} className="w-5 h-5 rounded-[6px] bg-white" />
</div>
<div className="w-[10%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" value="office building Data123" />
</div>
<div className="w-[10%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2  h-[34px] truncate" value="office building Data123" />
</div>
<div className="w-[10%]">
    <Select
        value={``}
        onValueChange={(value) => { "" }}
    >
        <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
            <SelectValue placeholder={"PortFolio Name"} className='truncate' />
        </SelectTrigger>
        <SelectContent side="bottom" className="min-w-[100px]">
            {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                </SelectItem>
            ))}
        </SelectContent>
    </Select>
</div>
<div className="w-[10%]">
    <Select
        value={``}
        onValueChange={(value) => { "" }}
    >
        <SelectTrigger className="w-full text-xs py-2 whitespace-normal px-1.5 bg-white">
            <SelectValue placeholder={"PortFolio Name"} className='truncate' />
        </SelectTrigger>
        <SelectContent side="bottom" className="">
            {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem className="text-xs flex items-center gap-2 cursor-pointer text-theme font-500 hover:bg-[#d7ebff] rounded-[4px] py-2" key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                </SelectItem>
            ))}
        </SelectContent>
    </Select>
</div>
<div className="w-[12%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate" value="office building Data123" />
</div>
<div className="w-[6.50%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
<div className="w-[7%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
<div className="w-[7%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
<div className="w-[7%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
<div className="w-[6.50%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
<div className="w-[6.50%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
<div className="w-[6.50%]">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
<div className="w-[6.50%] px-1.5 flex items-center justify-end">
    <Input className="flex items-center justify-between px-1.5 bg-white rounded-sm border border-input shadow-sm ring-offset-background placeholder:text-muted-foreground outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 w-full text-xs py-2 whitespace-normal h-[34px] truncate text-center" value="110" />
</div>
</div> */}