import { newsActionType } from '../../constants';

const newsReducer = (state = {}, action) => {
    switch (action.type) {
        case newsActionType.FETCH_NEWS:
            return action.payload;
        default:
            return state;
    }
};
export default newsReducer